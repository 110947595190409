import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const RadioLabel = ({ direction = 'row', primary, secondary }) => {
  const isRow = direction === 'row';
  const sharedProps = {
    component: 'span',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...(isRow ? { lineHeight: 1.25 } : {}),
  };
  const SecondaryText = (props) =>
    secondary?.length > 0 ? (
      <Typography
        {...sharedProps}
        {...props}
        color='text.secondary'
        variant='overline'
      >
        {secondary}
      </Typography>
    ) : null;
  return (
    <>
      <Box
        {...sharedProps}
        alignItems='center'
        display='inline-flex'
        flexDirection='row'
        sx={{ maxWidth: '100%' }}
      >
        {primary}{' '}
        {secondary?.length > 0 && SecondaryText ? (
          isRow ? (
            <>
              &bull;{' '}
              <SecondaryText
                display='inline-block'
                ml={0.75}
                sx={{ pointerEvents: 'none', zIndex: 0 }}
              />
            </>
          ) : null
        ) : null}
      </Box>
      {secondary?.length > 0 && SecondaryText ? (
        !isRow ? (
          <SecondaryText display='block' />
        ) : null
      ) : null}
    </>
  );
};

RadioLabel.propTypes = {
  direction: PropTypes.string,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
};

export default RadioLabel;
