import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  CommunicationMethodId,
  CommunicationTypeId,
  InvitationFormCalendar,
  InvitationFormData,
  InvitationRecipientsData,
  InvitationSelections,
  MessageFormData,
  MessageMethodId,
} from 'components/Events/Controls/Communications/communication.types';
import SendCommunicationDialog from 'components/Events/Controls/Communications/SendCommunicationDialog';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { useEventInvitation } from 'hooks/useEventInvitation';
import { useEventInvitationSave } from 'hooks/useEventInvitationSave';
import { useEventMessageFormValue } from 'hooks/useEventMessageFormValue';
import { useEventMessageSave } from 'hooks/useEventMessageSave';
import { getPersonalCalendar } from 'utils/calendar';

const CommunicationManager = () => {
  const {
    actions: { reloadEvent },
    state: { savedEvent },
  } = useContext(ManagerContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;

  const navigate = useNavigate();
  // const match = useMatch()

  // eslint-disable-next-line prefer-const
  let { commId, id, method, type } = useParams<{
    commId?: string;
    id?: string;
    method?: CommunicationMethodId;
    type?: CommunicationTypeId;
  }>();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(method != null && type != null);

  useEffect(() => {
    if (method !== null && type !== null) {
      setOpen(true);
    } else {
      setOpen(false);
      // TODO: Make transition quicker & more fluid
    }
  }, [method, type]);

  const {
    retrieveDefaultValues: { data },
  } = useEventInvitation({
    eventId: Number(id),
    invitationId: Number(commId),
    method: method || null,
  });

  const messageFormValues = useEventMessageFormValue({
    commId: commId ? Number(commId) : null,
    savedEvent: savedEvent || null,
  });

  const messageForm = useForm<MessageFormData>({
    criteriaMode: 'all',
    defaultValues: commId
      ? savedEvent.event_messages.find((m: any) => m.id === Number(commId))
      : {
          id: undefined,
          invitee_statuses: [],
          is_scheduled: false,
          message_body: '',
          message_type: type,
          scheduled_for: null,
          show_event_details_button: true,
          show_rsvp_buttons: true,
          slack_channel_ids: [],
        },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    values: messageFormValues as MessageFormData,
  });

  const invitationNewInitialValues = useMemo(
    () => ({
      calendars: [
        ...(user ? [{ ...getPersonalCalendar(user), is_deleted: false }] : []),
      ] as InvitationFormCalendar[],
      has_calendar_invite: true,
      id: commId ? Number(commId) : null,
      invite_body: '',
      invite_subject:
        method === 'email' ? `You are invited to ${savedEvent?.name}` : '',
      is_scheduled: false,
      notifies: false,
      recipients: {
        channel_ids: [],
        employee_ids: [],
        group_ids: [],
        list_ids: [],
        office_ids: [],
      },
      rowSelection: {
        channel: {},
        contact: {},
        group: {},
        list: {},
        office: {},
      },
      scheduled_for: null,
      selectedGuestIds: new Set<number>(),
      selections: {
        channel: [],
        contact: [],
        group: [],
        list: [],
        office: [],
      },
      uniqueGuestCount: 0,
    }),
    [commId, method, savedEvent?.name, user]
  );

  const invitationExistingInitialValues = useMemo<
    InvitationFormData | undefined
  >(() => {
    if (data) {
      const initialSelections = groupBy(
        data?.invitation?.recipients,
        (r) => `${r.type.toLowerCase()}`
      );
      const initialRecipients = Object.fromEntries(
        Object.entries(initialSelections).map(
          ([type, vals]: [type: string, vals: any[]]) => [
            `${type}_ids`,
            vals.map((v) => v.id),
          ]
        )
      );
      const memberIds = data?.invitation?.recipients
        ? data?.invitation?.recipients?.flatMap((s: any) =>
            s?.members && s?.members?.length > 0
              ? s?.members?.map((m: any) => m.id)
              : []
          )
        : [];
      const contactIds =
        initialSelections?.contact && initialSelections?.contact?.length > 0
          ? initialSelections?.contact?.map((c) => c.id)
          : [];

      const uniqueSelectedGuestIds = new Set<number>([
        ...memberIds,
        ...contactIds,
      ]);
      return {
        calendars: data?.invitation?.calendars as InvitationFormCalendar[],
        changePending: {},
        communicationHistoryId: data?.invitation?.communicationHistoryId,
        has_calendar_invite:
          method === 'slack' ? data?.invitation?.extraMethod : false,
        id: commId ? Number(commId) : null,
        invite_body: data?.invitation?.message?.body,
        invite_subject:
          data?.invitation?.message?.subject ||
          (method === 'email' ? `You are invited to ${savedEvent?.name}` : ''),
        is_scheduled: data?.invitation?.scheduledFor?.length > 0,
        notifies: method === 'calendar' ? data?.invitation?.extraMethod : false,
        recipients: initialRecipients as InvitationRecipientsData,
        rowSelection: {
          channel:
            initialSelections?.channel?.length > 0
              ? Object.fromEntries(
                  initialSelections?.channel?.map((c) => [
                    String(c.id),
                    true,
                  ]) || []
                )
              : {},
          contact:
            initialSelections?.contact?.length > 0
              ? Object.fromEntries(
                  initialSelections?.contact?.map((c) => [
                    String(c.id),
                    true,
                  ]) || []
                )
              : {},
          group:
            initialSelections?.group?.length > 0
              ? Object.fromEntries(
                  initialSelections?.group?.map((c) => [String(c.id), true]) ||
                    []
                )
              : {},
          list:
            initialSelections?.list?.length > 0
              ? Object.fromEntries(
                  initialSelections?.list?.map((c) => [String(c.id), true]) ||
                    []
                )
              : {},
          office:
            initialSelections?.office?.length > 0
              ? Object.fromEntries(
                  initialSelections?.office?.map((c) => [String(c.id), true]) ||
                    []
                )
              : {},
        },
        scheduled_for: data?.invitation?.scheduledFor || null,
        selectedGuestIds: uniqueSelectedGuestIds,
        selections: initialSelections as InvitationSelections,
        uniqueGuestCount: 0,
      };
    } else {
      return undefined;
    }
  }, [commId, data, method, savedEvent?.name]);

  const invitationForm = useForm<InvitationFormData>({
    criteriaMode: 'all',
    defaultValues:
      commId && data
        ? invitationExistingInitialValues
        : invitationNewInitialValues,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    values: commId ? invitationExistingInitialValues : undefined,
  });

  const form: UseFormReturn<any> = useMemo(
    () => (type === 'invitation' ? invitationForm : messageForm),
    [invitationForm, messageForm, type]
  );

  const isScheduled = form.watch('is_scheduled');
  const scheduledFor = form.watch('scheduled_for');

  const closeDialog = useCallback(() => {
    reloadEvent();
    navigate(`../../${commId ? '../' : ''}`, { relative: 'path' });
  }, [commId, navigate, reloadEvent]);

  const dataHandler = useCallback(() => {
    const responseCopy = isScheduled
      ? `Your ${type} is scheduled to send on ${dayjs(scheduledFor).format(
          'M/D/YY'
        )} at ${dayjs(scheduledFor).format('h:mma z')}`
      : `Your ${type} is on its way!`;
    enqueueSnackbar(responseCopy, { variant: 'success' });
    closeDialog();
  }, [closeDialog, enqueueSnackbar, isScheduled, scheduledFor, type]);

  const sharedParams = {
    commId: commId ? Number(commId) : null,
    dataHandler,
    eventId: Number(id || ''),
    method: method as MessageMethodId,
  };

  const { isPending: isMessageLoading, mutate: sendMessage } =
    useEventMessageSave(sharedParams);

  const { isPending: isInvitationLoading, mutate: sendInvite } =
    useEventInvitationSave({
      ...sharedParams,
      pauseActive: savedEvent?.rsvp_pause_active || false,
      pauseAllowed: savedEvent?.rsvp_pause_allowed || false,
    });

  return (
    <FormProvider {...form}>
      <SendCommunicationDialog
        isLoading={isMessageLoading || isInvitationLoading}
        isScheduled={isScheduled || false}
        onClose={closeDialog}
        onSave={() => {
          form.handleSubmit((d) =>
            type === 'invitation' ? sendInvite(d) : sendMessage(d)
          )();
        }}
        open={open}
        savedEvent={savedEvent}
        scheduledFor={scheduledFor || null}
      />
    </FormProvider>
  );
};

export default CommunicationManager;
