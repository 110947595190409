import React, { useContext } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { ConditionalLoader } from 'components/Core/Loader';
import StandardLogin from 'components/Onboard/StandardLogin';
import { PublicOrgContext } from 'contexts/PublicOrgContext';

const Login = () => {
  const [org, isLoading] = useContext(PublicOrgContext);
  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <ConditionalLoader conditions={[!org, isLoading]}>
        <StandardLogin />
      </ConditionalLoader>
    </GoogleOAuthProvider>
  );
};
export default Login;
