import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import StandaloneSurvey from 'components/Survey/StandaloneSurvey';
import { SurveyResponseContext } from 'components/Survey/SurveyResponseContext';
import { AuthContext } from 'contexts/AuthContext';

const SurveyWrapper = ({ type }) => {
  const {
    state: { event },
  } = useContext(SurveyResponseContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const displayResponders = useMemo(() => {
    return event?.[type]?.display_responders;
  }, [event, type]);

  const handleLoginRedirect = () => {
    const pathname = window.location.href;
    sessionStorage.setItem('fromPageUrl', JSON.stringify(pathname));
    window.location.replace('/login');
  };

  const needLoginFirst = useMemo(
    () => event.privacy === 'invite_only' && !user,
    [event, user]
  );

  useEffect(() => needLoginFirst && handleLoginRedirect, [needLoginFirst]);

  return <StandaloneSurvey displayResponders={displayResponders} type={type} />;
};

SurveyWrapper.propTypes = {
  type: PropTypes.oneOf(['pre_event_survey', 'post_event_survey']),
};
export default SurveyWrapper;
