import React from 'react';
import { Button, Stack } from '@mui/material';
import { PropTypes } from 'prop-types';

const WelcomeMessageButton = ({ advanceStep }) => (
  <Stack direction='row' justifyContent='flex-end' mt={1}>
    <Button
      color='primary'
      data-testid='start-now!'
      onClick={advanceStep}
      variant='contained'
    >
      Start now!
    </Button>
  </Stack>
);

export default WelcomeMessageButton;

WelcomeMessageButton.propTypes = {
  advanceStep: PropTypes.any,
};
