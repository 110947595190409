import React from 'react';
import { Controller } from 'react-hook-form';

import RichTextEditor from 'components/shared/RichTextEditor';
import { CollectionFormControl } from 'types/CollectionForm';

interface CollectionDescriptionFieldProps {
  control: CollectionFormControl;
  disableFields: boolean;
  isLoading?: boolean;
}

const CollectionDescriptionField = ({
  control,
  disableFields,
  isLoading = false,
}: CollectionDescriptionFieldProps) => (
  <Controller
    control={control}
    name='description'
    render={({ field }) => (
      <RichTextEditor
        isAutofocus={false}
        onChange={(v) => field.onChange(v.trim())}
        value={field.value}
      />
    )}
  />
);
export default CollectionDescriptionField;
