import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { getRsvpStatusDetails } from 'components/Events/Controls/Rsvp/Rsvp.constants';
import {
  useHandleRSVP,
  useSendSurveyResponses,
} from 'components/Events/Hooks/Actions';
import {
  usePendingSurveys,
  useSurveyResponses,
} from 'components/Events/Hooks/Event';
import { usePendingData } from 'components/Events/Hooks/Generic';
import { AuthContext } from 'contexts/AuthContext';
import { transformEvent } from 'utils/transform';
import { useDelayedLoading } from 'utils/utils';

export const SurveyResponseContext = createContext();

export const SurveyResponseProvider = ({ children }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const { id } = useParams();
  const [savedEvent, setSavedEvent] = useState({});
  const [rsvpStatus, setRsvpStatus] = useState(null);
  const [surveyJustSubmitted, setSurveyJustSubmitted] = useState(false);

  const { applyMulti, applySingle, pendingData } = usePendingData({
    surveys: {
      post_event_survey: {},
    },
  });
  const { endTask, isWorking, startTask } = useDelayedLoading();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isWorking && _.isEqual(savedEvent, {})) {
      startTask();

      axios(`${import.meta.env.VITE_BE_URL}/api/events/${id}/survey_questions`)
        .then((res) => {
          endTask();
          setSavedEvent(
            transformEvent(res.data, !window.localStorage.getItem('token'))
          );
        })
        .catch(() => {
          endTask();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, startTask, endTask, isWorking, savedEvent]);

  const pendingSurveys = usePendingSurveys(savedEvent, pendingData);

  const surveyResponses = useSurveyResponses(pendingData, pendingSurveys);

  const _afterSurveySuccess = useCallback(
    (res) => {
      enqueueSnackbar('Your responses were submitted', {
        variant: 'success',
      });
      setSavedEvent(
        transformEvent(res.data, !window.localStorage.getItem('token'))
      );
      setSurveyJustSubmitted(true);
    },
    [enqueueSnackbar]
  );

  const _afterSurveyPreSuccess = useCallback(
    (res, action) => {
      setSavedEvent(
        transformEvent(res.data, !window.localStorage.getItem('token'))
      );
      if (Object.keys(surveyResponses?.pre).length > 1 && action === 'rsvp') {
        enqueueSnackbar('Your responses were submitted!', {
          variant: 'success',
        });
        setSurveyJustSubmitted(true);
      } else {
        enqueueSnackbar('Your RSVP was submitted!', {
          variant: 'success',
        });
        setSurveyJustSubmitted(false);
      }
    },
    [enqueueSnackbar, surveyResponses?.pre]
  );

  const _afterSaveFail = useCallback(
    (err) => {
      // TODO: Story for better error handling
      enqueueSnackbar(
        _.chain(err?.response?.data?.errors)
          .map((m, k) => `${k}: ${m}`)
          .join(', ')
          .value(),
        { variant: 'error' }
      );
    },
    [enqueueSnackbar]
  );

  const sendPostSurvey = useSendSurveyResponses(
    startTask,
    endTask,
    _afterSurveySuccess,
    _afterSaveFail,
    surveyResponses
  );

  const rsvpType = useMemo(
    () => (user?.email ? 'manager' : 'public'),
    [user?.email]
  );

  const handleRSVP = useHandleRSVP(
    startTask,
    endTask,
    savedEvent?.id,
    rsvpType,
    _afterSurveyPreSuccess,
    _afterSaveFail,
    surveyResponses
  );

  const handleRSVPWithoutSurvey = useHandleRSVP(
    startTask,
    endTask,
    savedEvent?.id,
    rsvpType,
    _afterSurveyPreSuccess,
    _afterSaveFail,
    {
      pre: { email: surveyResponses?.pre?.email || user?.email },
    }
  );

  const handleRsvpClick = (type) => {
    const typeDetails = getRsvpStatusDetails(type);
    setRsvpStatus(typeDetails.id);
    if (typeDetails.id !== 'rsvp') {
      // if the user has filled out survey and then decide to change rsvp to maybe, we don't want to register their survey responses.
      handleRSVPWithoutSurvey(typeDetails.id, false);
    } else {
      handleRSVP(typeDetails.id, false);
    }
  };

  return (
    <SurveyResponseContext.Provider
      value={{
        actions: {
          applyMulti,
          applySingle,
          handleRSVP,
          handleRsvpClick,
          sendPostSurvey,
          setRsvpStatus,
        },
        state: {
          event: savedEvent,
          inSurveyResponseContext: true,
          rsvpStatus,
          surveyJustSubmitted,
          surveyResponses,
          surveys: pendingSurveys,
        },
      }}
    >
      {children}
    </SurveyResponseContext.Provider>
  );
};

SurveyResponseProvider.propTypes = {
  children: PropTypes.node,
};
