import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { transformEventAnalytics } from 'utils/transform';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = (props) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drillDownEvent, setDrillDownEvent] = useState();
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);

  const requestEvents = useCallback(
    ({ end, start }) => {
      if (start && end && isAuthenticated && !isLoading) {
        setIsLoading(true);
        axios(
          { url: `/api/analytics?start_date=${start}&end_date=${end}` },
          (res) => {
            setEvents(res.data);
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
          }
        );
      }
    },
    [isAuthenticated, isLoading]
  );

  const requestDrilldown = useCallback((eventId) => {
    if (eventId) {
      setIsLoading(true);
      axios(
        { url: `/api/events/${eventId}/analytics` },
        (res) => {
          setDrillDownEvent(transformEventAnalytics(res.data));
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{
        drillDownEvent,
        events,
        isLoading,
        requestDrilldown,
        requestEvents,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.any,
};
