import React, { useState } from 'react';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

import {
  ActionButtonMenuItem,
  ActionButtonMenuItemGroup,
} from 'components/shared/dataGrid/FtnDataGridToolbar';

const UpdateAttendanceButton = ({
  disabled,
  icon,
  menuItems,
  onClick,
  title,
}: {
  disabled?: boolean;
  icon?: React.JSX.Element;
  menuItems: ActionButtonMenuItemGroup[];
  onClick?: (field: string, option: ActionButtonMenuItem) => void;
  title?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const idPrefix = `bulk-update`;

  const handleButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBulkAction = (field: string, option: any) => {
    handleClose();
    onClick && onClick(field, option);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        color='primary'
        disabled={disabled}
        id={`${idPrefix}-button`}
        key={title}
        onClick={(e) => handleButtonClick(e)}
        startIcon={icon}
        sx={{ whiteSpace: 'nowrap' }}
        variant='contained'
      >
        {title || 'Set values'}
      </Button>
      {menuItems && menuItems?.length > 0 ? (
        <Menu
          anchorEl={anchorEl}
          id={`${idPrefix}-menu`}
          MenuListProps={{
            'aria-labelledby': `${idPrefix}-button`,
          }}
          onClose={handleClose}
          open={open}
        >
          {menuItems.map((group: ActionButtonMenuItemGroup) => {
            const opts = group?.options;
            const groupedOptions = [{ id: null, name: group?.name }, ...opts];
            return groupedOptions?.map((opt: ActionButtonMenuItem) => {
              const { id, name } = opt;
              const Icon = opt?.Icon ? opt?.Icon : null;
              const colorName = opt?.colorName ? opt?.colorName : null;
              if (id === null) {
                return null;
              } else {
                return (
                  <MenuItem
                    key={id}
                    onClick={() => handleBulkAction(group?.id, opt)}
                  >
                    {Icon ? (
                      <ListItemIcon
                        sx={{
                          '&.MuiListItemIcon-root': {
                            color: `${colorName}.main`,
                            height: 20,
                            minWidth: 28,
                            width: 20,
                          },
                        }}
                      >
                        <Icon size={20} />
                      </ListItemIcon>
                    ) : null}
                    <ListItemText primary={name} />
                  </MenuItem>
                );
              }
            });
          })}
        </Menu>
      ) : null}
    </>
  );
};

export default UpdateAttendanceButton;
