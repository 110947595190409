import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const OfficeActions = ({ office, onUpdate }) => {
  const [, setOrganization] = useContext(OrganizationContext);
  const { enqueueSnackbar } = useSnackbar();
  const [officeName, setOfficeName] = useState(office?.name || '');

  const clearOfficeName = () => {
    setOfficeName('');
  };

  useEffect(() => {
    if (office && office.name) {
      setOfficeName(office.name);
    } else {
      clearOfficeName();
    }
  }, [office]);

  const updateOffice = (name, id) => {
    axios(
      {
        data: { office: { name } },
        method: 'patch',
        url: `/api/offices/${id}`,
      },
      (res) => {
        setOrganization(res.data);
        clearOfficeName();
        enqueueSnackbar('Your office was updated.', {
          variant: 'success',
        });
        onUpdate && onUpdate();
      }
    );
  };

  const createOffice = (name) => {
    axios({ data: { name }, method: 'post', url: '/api/offices' }, (res) => {
      setOrganization(res.data);
      enqueueSnackbar('Your office was created.', {
        variant: 'success',
      });
      onUpdate();
      clearOfficeName();
    });
  };

  const officeNameValid = (officeName && officeName.trim().length > 0) || false;

  const title = `${office ? 'Update' : 'Add a new'} office`;

  return (
    <>
      <CardHeader title={title} />
      <CardContent>
        <TextField
          fullWidth
          id='settings-add-office'
          label='Office name'
          onChange={(e) => setOfficeName(e.target.value)}
          placeholder={office ? 'Update office name' : 'Add an office name'}
          required
          value={officeName}
        />
      </CardContent>
      <CardActions>
        <Button
          color='primary'
          data-testid='TODO:DATA-BUTTON-67039'
          disabled={!officeNameValid}
          onClick={() =>
            officeNameValid &&
            (office
              ? updateOffice(officeName, office.id)
              : createOffice(officeName))
          }
          variant='contained'
        >
          {office ? 'Update' : 'Save'}
        </Button>
      </CardActions>
    </>
  );
};

OfficeActions.propTypes = {
  office: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
};

export default OfficeActions;
