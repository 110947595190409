export const unavailableOptions = (
  isPreEvent,
  type,
  isOrgLevelEvent = false
) => {
  switch (type) {
    case 'surveyJustSubmitted':
      return {
        body: `${
          isPreEvent
            ? 'Thank you for responding to the survey for this event. We look forward to seeing you soon!'
            : 'Thank you for taking the time to provide your feedback. We appreciate your input and will use it to improve future events!'
        }`,
        header: `${
          isPreEvent
            ? 'Your survey was submitted!'
            : 'Thank you for your feedback!'
        }`,
      };
    case 'hasAnsweredSurvey':
      return {
        body: `It appears that your ${
          isPreEvent ? 'survey' : 'feedback'
        } for this event has already been submitted. If you have any questions or concerns, please contact your event host. Thank you!`,
        header: `${isPreEvent ? 'Survey' : 'Feedback'} already submitted`,
      };
    case 'genericUnavailable':
      return {
        body: `It appears that you do not have access to submit this ${
          isPreEvent ? 'survey' : 'feedback'
        } ${
          isOrgLevelEvent ? 'based on the email provided.' : '.'
        } If you have any questions or concerns, please reach out to your event host for assistance.`,
        header: `${isPreEvent ? 'Survey' : 'Feedback'} submission unavailable`,
      };
    default:
      return null;
  }
};
