import React, { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import * as _ from 'lodash';

import DeleteModal from 'components/Core/DeleteModal';
import SendEmailUpdatesModal from 'components/Events/Controls/Shared/SendEmailUpdatesModal';
import { usePendingSurveys } from 'components/Events/Hooks/Event';
import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated } from 'utils/axios';
import { DefaultSurveys } from 'utils/survey';
import {
  transformEventToFormData,
  transformPublicSurveyResponsesToFormData,
  transformSurveyResponses,
  transformSurveyResponsesToFormData,
  transformSurveysToFormData,
} from 'utils/transform';

export const useSaveEvent = (
  startTask,
  endTask,
  pendingData,
  savedEvent,
  onSuccess,
  onFailure,
  redirectToZoomSetUp = false,
  send_email_updates = false
) =>
  useCallback(
    (thenAdvance = false, fullStep = false) => {
      if (
        !_.isEqual(pendingData.event || {}, {}) ||
        pendingData?.cover_image !== null
      ) {
        startTask();
        const eventToSave = _.assign(
          {},
          savedEvent,
          { send_email_updates },
          pendingData?.event || {}
        );

        const requestData = transformEventToFormData(
          eventToSave,
          pendingData?.cover_image
        );

        const method = eventToSave.id ? 'put' : 'post';
        const url = eventToSave.id
          ? `/api/events/${eventToSave.id}`
          : '/api/events';

        axiosAuthenticated(
          {
            data: requestData,
            headers: {
              'Content-Type': `multipart/form-data; boundary=${requestData._boundary}`,
            },
            method,
            url,
          },
          (res) => {
            onSuccess &&
              onSuccess(res, thenAdvance, fullStep, redirectToZoomSetUp);
            endTask();
          },
          (err) => {
            onFailure && onFailure(err);
            endTask();
          }
        );
        return true;
      }
      return false;
    },
    [
      endTask,
      onFailure,
      onSuccess,
      pendingData?.cover_image,
      pendingData.event,
      redirectToZoomSetUp,
      savedEvent,
      startTask,
      send_email_updates,
    ]
  );

export const useSaveEventWithModal = (saveEventAction, saveEventActionSend) => {
  const [isOpen, setIsOpen] = useState(false);

  const saveEvent = () => {
    setIsOpen(true);
  };

  const saveEventModal = (
    <SendEmailUpdatesModal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      onDontSend={() => {
        saveEventAction();
        setIsOpen(false);
      }}
      onSend={() => {
        saveEventActionSend();
        setIsOpen(false);
      }}
    />
  );

  return {
    saveEvent,
    saveEventModal,
  };
};

export const useSaveSurvey = (
  startTask,
  endTask,
  pendingData,
  savedEvent,
  onSuccess,
  onFailure,
  type
) => {
  const pendingSurveys = usePendingSurveys(savedEvent, pendingData);

  return useCallback(
    (thenAdvance = false, fullStep = false) => {
      if (pendingData.surveys)
        if (!_.isEqual(pendingData.surveys, DefaultSurveys)) {
          startTask();

          let surveysToSave = {};

          if (type === 'both') {
            surveysToSave = _.assign({}, pendingData.surveys, pendingSurveys);
          } else {
            const pendingSurvey = _.get(pendingSurveys, `${type}`);
            const pendingDataSurvey = _.get(pendingData.surveys, `${type}`);

            surveysToSave = {
              [type]: _.assign({}, pendingDataSurvey, pendingSurvey),
            };
          }

          const requestData = transformSurveysToFormData(surveysToSave);

          const method = 'post';

          const url = `/api/events/${savedEvent.id}/surveys`;

          axiosAuthenticated(
            {
              data: requestData,
              method,
              url,
            },
            (res) => {
              if (res.data) {
                onSuccess && onSuccess(res, thenAdvance, fullStep);
                endTask();
              }
            },
            (err) => {
              onFailure && onFailure(err);
              endTask();
            }
          );
          return true;
        }
      return false;
    },
    [
      savedEvent,
      pendingData,
      pendingSurveys,
      startTask,
      endTask,
      onSuccess,
      onFailure,
      type,
    ]
  );
};

export const useDeleteSurvey = (
  startTask,
  endTask,
  pendingData,
  savedEvent,
  onSuccess,
  onFailure
) => {
  const pendingSurveys = usePendingSurveys(savedEvent, pendingData);

  return useCallback(
    (type) => {
      startTask();

      const saveData = _.cloneDeep(pendingSurveys[type]);
      saveData._destroy = 1;

      const requestData = transformSurveysToFormData({ [type]: saveData });
      axiosAuthenticated(
        {
          data: requestData,
          method: 'post',
          url: `/api/events/${savedEvent.id}/surveys`,
        },
        (res) => {
          if (res.data) {
            onSuccess && onSuccess(res);
            endTask();
          }
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
    },
    [savedEvent, pendingSurveys, startTask, endTask, onSuccess, onFailure]
  );
};

export const useSendInvitationOrMessageNow = (
  startTask,
  endTask,
  onSuccess,
  onFailure
) =>
  useCallback(
    (commId, commType) => {
      startTask();
      axiosAuthenticated(
        {
          data: { id: commId },
          method: 'post',
          timeout: 30000,
          url: `/api/communication_histories/send_now`,
        },
        (res) => {
          onSuccess && onSuccess(res, commType);
          endTask();
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
    },
    [endTask, onFailure, onSuccess, startTask]
  );

export const useHandleRSVP = (
  startTask,
  endTask,
  eventId,
  type,
  onSuccess,
  onFailure,
  surveyResponses
) =>
  useCallback(
    (action, surveySubmitted, submittingPreEventSurvey = false) => {
      startTask();
      const requestData =
        type === 'manager'
          ? transformSurveyResponsesToFormData(
              action,
              surveyResponses.pre,
              submittingPreEventSurvey
            )
          : transformPublicSurveyResponsesToFormData(
              eventId,
              action,
              surveyResponses.pre,
              submittingPreEventSurvey
            );

      type === 'manager'
        ? axiosAuthenticated(
            {
              data: requestData,
              headers: {
                'Content-Type': `multipart/form-data; boundary=${requestData._boundary}`,
              },
              method: 'post',
              url: `/api/events/${eventId}/rsvp`,
            },
            (res) => {
              if (res.data) {
                onSuccess && onSuccess(res, action, surveySubmitted);
                endTask();
              }
            },
            (err) => {
              onFailure && onFailure(err);
              endTask();
            }
          )
        : axios({
            data: requestData,
            headers: {
              'Content-Type': `multipart/form-data; boundary=${requestData._boundary}`,
            },
            method: 'post',
            url: `${import.meta.env.VITE_BE_URL}/api/invitees/public_rsvp`,
          })
            .then((res) => {
              if (res.data) {
                onSuccess && onSuccess(res, action, surveySubmitted);
                endTask();
              }
            })
            .catch((err) => {
              onFailure && onFailure(err);
              endTask();
            });
    },
    [
      startTask,
      endTask,
      type,
      onSuccess,
      onFailure,
      eventId,
      surveyResponses.pre,
    ]
  );

export const useSendSurveyResponses = (
  startTask,
  endTask,
  onSuccess,
  onFailure,
  surveyResponses
) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  return useCallback(
    (event, type) => {
      const surveyData = transformSurveyResponses(
        surveyResponses[type],
        event,
        `${type}_event_survey`
      );
      startTask("We're sending your responses now!");
      axios({
        data: {
          event: {
            email: user?.email || surveyResponses.post.email.toLowerCase(),
            responses: surveyData,
          },
        },
        method: 'post',
        url: `${import.meta.env.VITE_BE_URL}/api/events/${
          event.id
        }/survey_questions`,
      })
        .then((res) => {
          onSuccess && onSuccess(res);
          endTask();
        })
        .catch((err) => {
          onFailure && onFailure(err);
          endTask();
        });
    },
    [endTask, onFailure, onSuccess, startTask, surveyResponses, user]
  );
};

export const useRemoveCalendar = (startTask, endTask, onSuccess, onFailure) =>
  useCallback(
    (cal_id, event_id) => {
      startTask("We're removing that calendar for you!");

      axiosAuthenticated(
        {
          data: { event_id },
          method: 'DELETE',
          url: `/api/calendars/${cal_id}`,
        },
        (res) => {
          if (res.data && res.data.error) {
            onFailure && onFailure();
            endTask();
          } else {
            res['successMessage'] =
              'Your calendar invitation has been successfully deleted';
            onSuccess && onSuccess(res);
            endTask();
          }
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
    },
    [endTask, onFailure, onSuccess, startTask]
  );

export const useSaveGoogleAuth = (startTask, endTask, onSuccess, onFailure) => {
  const {
    userProvider: { setUser },
  } = useContext(AuthContext);

  return useCallback(
    (response) => {
      startTask();
      axiosAuthenticated(
        {
          data: {
            code: response.code,
            redirect_uri: window.location.origin,
          },
          method: 'POST',
          mode: 'CORS',
          url: '/api/auth_details/gcal',
        },
        (res) => {
          setUser(res.data);
          endTask();
          onSuccess && onSuccess();
        },
        (e) => {
          endTask();
          onFailure && onFailure(e);
        }
      );
    },
    [endTask, onFailure, onSuccess, setUser, startTask]
  );
};

export const useSaveMicrosoftAuth = (
  startTask,
  endTask,
  onSuccess,
  onFailure
) => {
  const {
    userProvider: { setUser },
  } = useContext(AuthContext);

  return useCallback(
    (response) => {
      startTask();
      axiosAuthenticated(
        {
          data: {
            code: response,
            redirect_uri: window.location.origin + '/auth/outlook',
          },
          method: 'POST',
          mode: 'CORS',
          url: '/api/auth_details/outlook',
        },
        (res) => {
          setUser(res.data);
          endTask();
          onSuccess && onSuccess();
        },
        () => {
          endTask();
          onFailure && onFailure();
        }
      );
    },
    [endTask, onFailure, onSuccess, setUser, startTask]
  );
};

export const useCloneEvent = (startTask, endTask, onSuccess, onFailure) =>
  useCallback(
    (id) => {
      startTask();
      axiosAuthenticated(
        { url: `/api/events/${id}/clone` },
        (res) => {
          if (res.data && res.data.error) {
            onFailure && onFailure(res.data.error);
            endTask();
          } else {
            onSuccess && onSuccess(res.data);
            endTask();
          }
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
    },
    [onFailure, onSuccess, startTask, endTask]
  );

export const useDeleteEvent = (
  startTask,
  endTask,
  event,
  onSuccess,
  onFailure
) => {
  const [org] = useContext(OrganizationContext);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const actDelete = useCallback(
    (id) => {
      startTask();
      axiosAuthenticated(
        {
          method: 'delete',
          url: `/api/events/${id}`,
        },
        (res) => {
          if (res.data && res.data.error) {
            onFailure && onFailure(res.data.error);
            endTask();
          } else {
            onSuccess && onSuccess(res.data);
            endTask();
          }
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
    },
    [endTask, onFailure, onSuccess, startTask]
  );

  const deleteModal = (
    <DeleteModal
      noun='Event'
      onCancel={() => setIsConfirmingDelete(false)}
      onDelete={() => {
        actDelete(event.id);
        setIsConfirmingDelete(false);
      }}
      owner={org?.display_name}
      show={isConfirmingDelete}
      subject={event?.name}
    />
  );

  const showDeleteModal = () => setIsConfirmingDelete(true);

  return { deleteModal, showDeleteModal };
};

export const useRemoveZoomMeeting = (
  startTask,
  endTask,
  onSuccess,
  onFailure
) =>
  useCallback(
    (id) => {
      startTask();
      axiosAuthenticated(
        {
          method: 'delete',
          url: `/api/zoom/${id}`,
        },
        (res) => {
          if (res.data && res.data.error) {
            onFailure && onFailure();
            endTask();
          } else {
            onSuccess && onSuccess(res);
            endTask();
          }
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
    },
    [startTask, endTask, onSuccess, onFailure]
  );

export const useDeleteCommunicationHistory = (
  startTask,
  endTask,
  onSuccess,
  onFailure
) =>
  useCallback(
    (commId, commType) => {
      startTask();
      axiosAuthenticated(
        {
          method: 'delete',
          url: `/api/communication_histories/${commId}`,
        },
        (res) => {
          if (res.data && res.data.error) {
            onFailure && onFailure();
            endTask();
          } else {
            onSuccess && onSuccess(res, commType);
            endTask();
          }
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
    },
    [startTask, endTask, onSuccess, onFailure]
  );
