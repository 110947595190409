import React, { useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
// import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import GuestCheckList from 'components/Core/GuestCheckList';
import InvitationSelectContactsFilter from 'components/Events/Controls/Invites/InvitationSelectContactsFilter';
import InvitationSelectSearch from 'components/Events/Controls/Invites/InvitationSelectSearch';
import SlackChannelLabel from 'components/shared/SlackChannelLabel';
import { fullName, searchFilter } from 'utils/employee';

const ContactsOrChannelsTab = ({
  allInviteeEmployeesWithStatus,
  disable,
  fixedValues,
  hideFilters = false,
  inviteMethod,
  isSelectable,
  isSlackChannels,
  nounPlural,
  onChange,
  options,
  searchOnly,
  showInvitedAsFixed,
  showStatusChip,
  value,
}) => {
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [autoSelect, setAutoSelect] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [logicalOperator, setLogicalOperator] = useState('all');
  const [matchedContacts, setMatchedContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder] = useState('asc');

  const isSlack = useMemo(() => inviteMethod === 'slack', [inviteMethod]);

  // all employees, filtered
  const filteredOptions = useMemo(() => {
    const contacts = matchedContacts?.length > 0 ? matchedContacts : options;
    const searchableTerm = isSlack ? searchTerm?.replace('#', '') : searchTerm;

    const opts = contacts?.filter(
      (emp) => emp && searchFilter(searchableTerm, emp, isSlack)
    );

    return opts?.sort((a, b) => {
      const nameA = fullName(a) || a.name;
      const nameB = fullName(b) || b.name;
      if (nameA < nameB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      // names must be equal
      return 0;
    });
  }, [isSlack, matchedContacts, options, searchTerm, sortOrder]);

  useEffect(() => {
    if (autoSelect) {
      if (matchedContacts.length > 0) {
        onChange(
          matchedContacts.map((c) => c.id),
          true
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelect, matchedContacts]);

  const isFixed = (opt) => fixedValues?.includes(opt?.id);

  const isScheduledOrInvited = (opt) => {
    if (showStatusChip) {
      return (
        allInviteeEmployeesWithStatus?.find(
          (inviteeEmployee) => inviteeEmployee.employee_id === opt.id
        )?.status || null
      );
    } else {
      return null;
    }
  };

  // const tooltipText = (opt) => {
  //   const inviteeEmployee = allInviteeEmployeesWithStatus?.find(
  //     (inviteeEmployee) => inviteeEmployee.employee_id === opt.id
  //   );

  //   const time = inviteeEmployee?.time;
  //   console.log(time);

  //   return `${
  //     inviteeEmployee?.status === 'invited'
  //       ? 'Invitation was sent'
  //       : 'Scheduled to receive invitation'
  //   } on ${dayjs(time).format('MM/DD/YY')} at ${dayjs(time).format('HH:mm a')}`;
  // };

  const slackChannelProps = {
    optionRender: ({ isPrivate, name, status }) => (
      <SlackChannelLabel
        isBold={false}
        isPrivate={isPrivate}
        name={name}
        status={status}
      />
    ),
  };

  const SearchBar = useMemo(
    () => (
      <InvitationSelectSearch
        filter={searchTerm}
        fullWidth
        isSlack={isSlack}
        placeholder={`Search ${nounPlural}`}
        setFilter={setSearchTerm}
      />
    ),
    [isSlack, nounPlural, searchTerm]
  );

  return (
    <>
      {hideFilters === false && searchOnly === false && (
        <Stack
          alignItems='center'
          direction='row'
          gap={2}
          justifyContent='space-between'
          my={1}
        >
          {SearchBar}
          <InvitationSelectContactsFilter
            activeFilterCount={activeFilterCount}
            logicalOperator={logicalOperator}
            onClose={() => setIsFiltering(false)}
            onLogicalOperatorChange={setLogicalOperator}
            options={options}
            setActiveFilterCount={setActiveFilterCount}
            setAutoSelect={setAutoSelect}
            setMatchedContacts={(matchedIds) =>
              setMatchedContacts(
                activeFilterCount === 0
                  ? options
                  : options?.filter((o) => matchedIds?.includes(o.id))
              )
            }
            setOpen={setIsFiltering}
            show={isFiltering}
          />
        </Stack>
      )}
      {searchOnly ? <>{SearchBar}</> : null}
      {isSelectable && hideFilters === false ? (
        <GuestCheckList
          {...(isSlackChannels ? slackChannelProps : {})}
          disabled={!isSlack && disable}
          height='unset'
          isSelectable
          noOptionsMessage={`No ${nounPlural}`}
          nounPlural={nounPlural}
          onChange={onChange}
          options={filteredOptions?.map((opt) => ({
            email: opt.email || null,
            fixed: isFixed(opt),
            id: opt.id,
            isPrivate: opt?.private,
            name: fullName(opt) || opt.name || opt.label,
            status: isScheduledOrInvited(opt),
            // text: showStatusChip && tooltipText(opt),
          }))}
          rowHeight={56}
          showFixedAsDisabled={showInvitedAsFixed}
          sx={{
            '& .MuiDataGrid-row': {
              '&.Mui-selected': {
                backgroundColor: 'white',
              },
              '&:hover, &.Mui-selected:hover': {
                backgroundColor: 'grey.50',
              },
              cursor: 'pointer',
            },
          }}
          value={value}
        />
      ) : null}
    </>
  );
};

ContactsOrChannelsTab.propTypes = {
  allInviteeEmployeesWithStatus: PropTypes.array,
  disable: PropTypes.bool,
  fixedValues: PropTypes.array,
  hideFilters: PropTypes.bool,
  inviteMethod: PropTypes.string,
  isSelectable: PropTypes.bool,
  isSlackChannels: PropTypes.bool,
  nounPlural: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  searchOnly: PropTypes.bool,
  showInvitedAsFixed: PropTypes.bool,
  showStatusChip: PropTypes.bool,
  value: PropTypes.array,
};

export default ContactsOrChannelsTab;
