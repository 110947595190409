import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import Dropdown from 'components/Core/Functional/Dropdown';
import RadioSelect from 'components/Core/RadioSelect';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';

const path = 'surveys.post_event_survey.delay';

const delayUnits = ['minute', 'hour', 'day'];

const delayOptions = [...delayUnits].map((unit) => ({
  label: `${unit}(s)`,
  value: unit,
}));

const ScheduleSurvey = ({ delaySendSurvey, setDelaySendSurvey }) => {
  const {
    actions: { applySingle },
    state: { event, surveys },
  } = useContext(ManagerContext);

  const [delayTime, setDelayTime] = useState('0');
  const [delayUnit, setDelayUnit] = useState(delayOptions[0]);

  useEffect(() => {
    if (
      surveys?.post_event_survey?.survey_scheduled_time &&
      surveys?.post_event_survey?.survey_scheduled_time !== event.ends
    ) {
      setDelaySendSurvey(true);
      setDelayTime(event?.post_event_survey?.delay?.split('.')[0]);
      setDelayUnit(
        delayOptions?.find((opt) =>
          event?.post_event_survey?.delay?.split('.')[1]?.includes(opt.value)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveys?.post_event_survey?.survey_scheduled_time]);

  useEffect(() => {
    if (delaySendSurvey) {
      if (delayTime === '1') {
        applySingle(`${delayTime}.${delayUnit?.value}`, path);
      }
      applySingle(`${delayTime}.${delayUnit?.value}s`, path);
    } else {
      applySingle('0', path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delaySendSurvey, delayTime, delayUnit]);

  return (
    <Box>
      <RadioSelect
        label='Schedule survey'
        labelSx={{ m: 0 }}
        labelTooltipText="Select when you would like your attendees to receive the post-event survey. Consider the
          time you might need to update your guest list after the event to maximize survey
          engagement. You can also send out surveys to attendees who haven't filled it out from the event analytics page."
        onChange={() => setDelaySendSurvey(!delaySendSurvey)}
        options={[
          {
            label: 'Immediately send survey after event ends',
            value: false,
          },
          {
            component: (
              <Stack
                alignItems='flex-end'
                data-testid='delay-options'
                direction='row'
                gap={1}
                justifyContent='flex-start'
              >
                <TextField
                  disabled={!delaySendSurvey}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label='Delay time'
                  onChange={(e) => setDelayTime(e.target.value)}
                  placeholder='0'
                  required={delaySendSurvey}
                  sx={{ maxWidth: 100 }}
                  type='number'
                  value={delayTime}
                />
                <Box sx={{ minWidth: 200 }}>
                  <Dropdown
                    disableClearable
                    disabled={!delaySendSurvey}
                    onChange={(e, val) => setDelayUnit(val)}
                    options={delayOptions}
                    value={delayUnit}
                  />
                </Box>
              </Stack>
            ),
            label: 'Delay sending survey out after the event ends',
            value: true,
          },
        ]}
        sx={{ m: 0 }}
        value={delaySendSurvey}
      />
    </Box>
  );
};

ScheduleSurvey.propTypes = {
  delaySendSurvey: PropTypes.bool,
  setDelaySendSurvey: PropTypes.func,
};

export default ScheduleSurvey;
