import { useGoogleLogin as useGoogleLoginInternal } from '@react-oauth/google';
import { useSnackbar } from 'notistack';

export const GoogleSSOScopes =
  'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid';
export const GoogleCalendarScopes =
  'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events';

/**
 * Hook to help simplify the **Google Calendar** login process by packaging the whole thing up as a single function call.
 * Do not attempt to use this hook for a Google SSO login, it will not work.
 * @param {Function} onSuccess
 * @param {Function | undefined} onFailure
 * @param {Object} organization
 * @returns A function that will start the Google Calendar login process when called.
 */
export const useGoogleCalendarLogin = (onSuccess, onFailure, organization) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleFailure = (response) => {
    if (response.error !== 'idpiframe_initialization_failed') {
      enqueueSnackbar(`Something went wrong! ${response?.error}`, {
        variant: 'error',
      });
    } else if (organization?.calendar_enabled === 'google') {
      enqueueSnackbar(
        'Cookies must be enabled to login with Google. Please enable cookies or use a different login option.',
        { variant: 'error' }
      );
    }
  };

  const hook = useGoogleLoginInternal({
    flow: 'auth-code',
    onFailure: onFailure || handleFailure,
    onSuccess,
    prompt: 'select_account',
    scope: GoogleCalendarScopes,
  });
  return hook;
};
