import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import FtnEvent from 'types/FtnEvent';
import { reactQueryAxios } from 'utils/axios';

const cloneEvent = async (eventId: number, apiClient: any) =>
  apiClient({
    url: `/api/events/${eventId}/clone`,
  });

export const useCloneEvent = ({
  dataHandler = () => undefined,
}: {
  dataHandler?: (d: FtnEvent) => unknown;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (eventId: number) => cloneEvent(eventId, reactQueryAxios),

    onError: (error: Error) => {
      enqueueSnackbar('Something went wrong when cloning the event', {
        variant: 'error',
      });
      Sentry.captureException(error);
    },

    onSuccess: (response: any) => {
      const ftnEvent = response.data;
      queryClient.setQueryData(['event', ftnEvent.id], ftnEvent);
      dataHandler(ftnEvent);
    },
  });
};
