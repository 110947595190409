import React, { useContext } from 'react';
import { Stack, Toolbar, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import PreviewHeader from 'components/Events/Controls/Shared/PreviewHeader';
import DetailsActions from 'components/Events/Header/DetailsActions';
import ManageNavbar from 'components/Events/Header/ManageNavbar';
import ViewEventAction from 'components/Events/Header/ViewEventAction';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import { AuthContext } from 'contexts/AuthContext';
import { useContextWithFallback } from 'utils/utils';

const Header = () => {
  const theme = useTheme();
  const {
    state: { event },
  } = useContextWithFallback(ManagerContext, PublicEventContext);
  const {
    userProvider: { isOrganizerOrAdmin },
  } = useContext(AuthContext);

  const isEditor = event?.published === false;

  return (
    <Stack
      alignItems='stretch'
      direction='column'
      pt={isEditor ? 0 : 1.5}
      spacing={0}
      width='100%'
    >
      <Toolbar
        data-testid='event-header'
        disableGutters
        sx={{
          alignItems: 'flex-start',
          bgcolor: 'unset',
          flexDirection: { md: 'row', xs: 'column' },
          gap: theme.spacing(1),
          justifyContent: { md: 'space-between', xs: 'flex-start' },
          px: 0,
          py: 1.5,
        }}
      >
        <PreviewHeader inToolbar />
        <Stack
          alignItems={{
            lg: 'center',
            md: 'flex-end',
            sm: 'center',
            xs: 'center',
          }}
          columnGap={2}
          flexDirection={{
            lg: 'row',
            md: 'column-reverse',
            sm: 'row-reverse',
            xs: 'row',
          }}
          rowGap={1}
        >
          {isOrganizerOrAdmin && <DetailsActions />}
          <ViewEventAction />
        </Stack>
      </Toolbar>
      {isOrganizerOrAdmin && (
        <Toolbar
          data-testid={`${isEditor ? 'edit' : 'manage'}-tab-wrapper`}
          disableGutters
          sx={{
            alignItems: isEditor ? 'center' : 'flex-end',
            minHeight: isEditor ? 64 : '36px !important',
            px: 0,
            py: isEditor ? 1 : 0,
          }}
        >
          <ManageNavbar />
        </Toolbar>
      )}
    </Stack>
  );
};

Header.propTypes = {
  actionsComponent: PropTypes.any,
  isManager: PropTypes.bool,
};
export default Header;
