import * as _ from 'lodash';

export const UPDATE_ORGANIZATION_BEGIN = 'UPDATE_ORGANIZATION_BEGIN';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';

const processAttributeField = (field, property, processedOrg, organization) => {
  const fieldKey = `${field}_attributes`;
  processedOrg[fieldKey] = _.chain(organization[fieldKey])
    .filter((ext) => +ext.id < 0)
    .map((ext) => ({ [property]: ext[property] }))
    .value();

  const itemsToDestroy = _.chain(organization[fieldKey])
    .filter((ext) => !organization[field + '_ids'].includes(ext.id))
    .map((ext) => ({ ...ext, destroy: true }))
    .value();

  processedOrg[fieldKey] = _.concat(processedOrg[fieldKey], itemsToDestroy);
};

const processOrganization = (organization) => {
  const processedOrg = _.cloneDeep(organization);
  // email_extensions
  processAttributeField(
    'email_extensions',
    'domain',
    processedOrg,
    organization
  );
  // event types
  processAttributeField('event_types', 'name', processedOrg, organization);
  // group types
  processAttributeField('group_types', 'name', processedOrg, organization);
  return processedOrg;
};

export const postOrganization = (
  currentOrg,
  organization,
  favicon,
  logo,
  succCallback,
  failCallback,
  axios
) => {
  const processedOrg = processOrganization(organization);

  const fd = new FormData();
  Object.keys(processedOrg).forEach((key) => {
    if (
      key === 'email_extensions_attributes' ||
      key === 'event_types_attributes' ||
      key === 'group_types_attributes'
    ) {
      processedOrg[key].forEach((r, i) => {
        Object.keys(r).forEach((attr) => {
          const keyPrefix = `organization[${key}][${i}]`;
          if (attr === 'destroy') {
            fd.append(`${keyPrefix}[_destroy]`, r[attr]);
          } else {
            fd.append(`${keyPrefix}[${attr}]`, r[attr]);
          }
        });
      });
    } else {
      fd.append(`organization[${key}]`, processedOrg[key]);
    }
  });

  if (favicon) {
    fd.append('organization[favicon]', favicon);
  }

  if (logo) {
    fd.append('organization[logo]', logo);
  }

  axios(
    {
      data: fd,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
      },
      method: 'PUT',
      url: `/api/organizations/${currentOrg.id}`,
    },
    (res) => {
      if (res.data && res.data.error) {
        failCallback(res.data);
      } else {
        succCallback(res.data);
      }
    },
    (err) => {
      failCallback(err);
    }
  );
};
