import {
  CalendarCheck,
  CalendarX,
  CheckCircle,
  Circle,
  ClockCountdown,
  Icon,
  MapPin,
  Question,
  SpinnerGap,
  VideoCamera,
  XCircle,
} from '@phosphor-icons/react';
import { keyBy } from 'lodash';

import MuiColor from 'types/MuiColor';

// Table-level

export const RsvpColumnTitle = 'Invitation status';
export const AttendedColumnTitle = 'Attended';
export const AttendanceTypeColumnTitle = 'Attendance type';

export const GuestListFiltersOrder = [
  RsvpColumnTitle,
  AttendedColumnTitle,
  AttendanceTypeColumnTitle,
];

// Column-level
interface Status<T> {
  colorName?: MuiColor;
  Icon: Icon;
  id: T;
  name: string;
  order: number;
  otherId?: string;
}

// RSVP

type RsvpStatusId = 'rsvp' | 'maybe_attending' | 'not_attending' | 'invited';

export const RsvpStatuses: Status<RsvpStatusId>[] = [
  {
    colorName: 'success',
    Icon: CheckCircle,
    id: 'rsvp',
    name: 'Yes',
    order: 1,
  },
  {
    colorName: 'warning',
    Icon: Question,
    id: 'maybe_attending',
    name: 'Maybe',
    order: 2,
  },
  {
    colorName: 'error',
    Icon: XCircle,
    id: 'not_attending',
    name: 'No',
    order: 3,
  },
  {
    colorName: 'info',
    Icon: Circle,
    id: 'invited',
    name: 'No response',
    order: 4,
    otherId: 'guest_list',
  },
];

export const RsvpStatusMap = new Map<RsvpStatusId, Status<RsvpStatusId>>(
  [...RsvpStatuses].map((status) => [status.id, status])
);

// Status column - chip

export type InvitationStatusId = RsvpStatusId | 'guest_list';
// INFO: `guest_list` is used for guests that are scheduled to be invited in the future

const InvitationStatuses: Status<InvitationStatusId>[] = [
  ...RsvpStatuses,
  {
    Icon: ClockCountdown,
    id: 'guest_list',
    name: 'Scheduled',
    order: 5,
  },
];

export const InvitationStatusMap = new Map<
  InvitationStatusId,
  Status<InvitationStatusId>
>([...InvitationStatuses].map((status) => [status.id, status]));

// Attended column

type AttendedStatusId = 'yes' | 'no' | '';

export const AttendedStatuses: Status<AttendedStatusId>[] = [
  {
    colorName: 'success',
    Icon: CalendarCheck,
    id: 'yes',
    name: 'Attended',
    order: 1,
  },
  {
    colorName: 'error',
    Icon: CalendarX,
    id: 'no',
    name: 'Did not attend',
    order: 2,
  },
  {
    colorName: 'default',
    Icon: SpinnerGap,
    id: '',
    name: 'Not recorded',
    order: 3,
  },
];

export const attendedStatusDetails = keyBy(AttendedStatuses, 'id');

export const AttendedStatusMap = new Map<
  AttendedStatusId,
  Status<AttendedStatusId>
>([...AttendedStatuses].map((status) => [status.id, status]));

// Attendance type column

export const AttendanceTypes = [
  {
    colorName: 'success',
    Icon: VideoCamera,
    id: 'virtual',
    name: 'Virtual',
    order: 1,
  },
  {
    colorName: 'success',
    Icon: MapPin,
    id: 'in_person',
    name: 'In-person',
    order: 2,
  },
];

export const AttendedOptions = [...AttendedStatuses].filter((s) => s.id !== '');

export const AttendanceTypeOptions = [...AttendanceTypes].filter(
  (s) => s.id !== ''
);

export const GuestBulkUpdateOptionGroups = [
  {
    id: 'attendance_type',
    name: 'Attendance type',
    options: [...AttendanceTypeOptions].map((opt) => ({
      ...opt,
      name: `Attended ${opt.name.toLowerCase()}${
        opt.name === 'Virtual' ? 'ly' : ''
      }`,
    })),
  },
  { id: 'attended', name: 'Attended', options: [...AttendedOptions] },
];
