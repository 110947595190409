import React from 'react';
import { Tooltip } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { Seal } from '@phosphor-icons/react';
import { Dayjs } from 'dayjs';

const EventDayIndicator = (
  props: PickersDayProps<Dayjs> & { highlightedDays?: Dayjs[] }
) => {
  const { day, highlightedDays = [], outsideCurrentMonth, ...other } = props;

  const isSelected =
    !outsideCurrentMonth &&
    highlightedDays?.some((highlightedDay) =>
      day?.isSame(highlightedDay, 'day')
    );

  const DayComponent = (
    <PickersDay
      {...other}
      className={isSelected ? 'Mui-selected' : ''}
      day={day}
      key={day?.toString()}
      outsideCurrentMonth={outsideCurrentMonth}
      sx={
        isSelected
          ? {
              '&.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
                bgcolor: 'info.light',
                color: 'info.dark',
                maskImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iI2UzZjJmZCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yNDAsMTI4YzAsMTAuNDQtNy41MSwxOC4yNy0xNC4xNCwyNS4xOC0zLjc3LDMuOTQtNy42Nyw4LTkuMTQsMTEuNTctMS4zNiwzLjI3LTEuNDQsOC42OS0xLjUyLDEzLjk0LS4xNSw5Ljc2LS4zMSwyMC44Mi04LDI4LjUxcy0xOC43NSw3Ljg1LTI4LjUxLDhjLTUuMjUuMDgtMTAuNjcuMTYtMTMuOTQsMS41Mi0zLjU3LDEuNDctNy42Myw1LjM3LTExLjU3LDkuMTRDMTQ2LjI3LDIzMi40OSwxMzguNDQsMjQwLDEyOCwyNDBzLTE4LjI3LTcuNTEtMjUuMTgtMTQuMTRjLTMuOTQtMy43Ny04LTcuNjctMTEuNTctOS4xNC0zLjI3LTEuMzYtOC42OS0xLjQ0LTEzLjk0LTEuNTItOS43Ni0uMTUtMjAuODItLjMxLTI4LjUxLThzLTcuODUtMTguNzUtOC0yOC41MWMtLjA4LTUuMjUtLjE2LTEwLjY3LTEuNTItMTMuOTQtMS40Ny0zLjU3LTUuMzctNy42My05LjE0LTExLjU3QzIzLjUxLDE0Ni4yNywxNiwxMzguNDQsMTYsMTI4czcuNTEtMTguMjcsMTQuMTQtMjUuMThjMy43Ny0zLjk0LDcuNjctOCw5LjE0LTExLjU3LDEuMzYtMy4yNywxLjQ0LTguNjksMS41Mi0xMy45NC4xNS05Ljc2LjMxLTIwLjgyLDgtMjguNTFzMTguNzUtNy44NSwyOC41MS04YzUuMjUtLjA4LDEwLjY3LS4xNiwxMy45NC0xLjUyLDMuNTctMS40Nyw3LjYzLTUuMzcsMTEuNTctOS4xNEMxMDkuNzMsMjMuNTEsMTE3LjU2LDE2LDEyOCwxNnMxOC4yNyw3LjUxLDI1LjE4LDE0LjE0YzMuOTQsMy43Nyw4LDcuNjcsMTEuNTcsOS4xNCwzLjI3LDEuMzYsOC42OSwxLjQ0LDEzLjk0LDEuNTIsOS43Ni4xNSwyMC44Mi4zMSwyOC41MSw4czcuODUsMTguNzUsOCwyOC41MWMuMDgsNS4yNS4xNiwxMC42NywxLjUyLDEzLjk0LDEuNDcsMy41Nyw1LjM3LDcuNjMsOS4xNCwxMS41N0MyMzIuNDksMTA5LjczLDI0MCwxMTcuNTYsMjQwLDEyOFoiPjwvcGF0aD48L3N2Zz4=")`,
                maskPosition: 'center',
                maskSize: 42,
              },
            }
          : {}
      }
    />
  );
  return isSelected ? (
    <Tooltip title='Your event starts on this date'>{DayComponent}</Tooltip>
  ) : (
    <>{DayComponent}</>
  );
};

export default EventDayIndicator;
