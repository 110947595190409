import React, { Fragment, useState } from 'react';
import {
  AutocompleteRenderGroupParams,
  Button,
  Collapse,
  IconButton,
  List,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';

import TabLabelWithCount from 'components/shared/TabLabelWithCount';

interface AutocompleteGroupProps {
  clearSelected: (group: string) => void;
  params: AutocompleteRenderGroupParams;
  selectionCount: number;
}

const AutocompleteGroup = ({
  clearSelected,
  params,
  selectionCount,
}: AutocompleteGroupProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Fragment key={params.group}>
      <ListSubheader
        disableGutters
        sx={{ bgcolor: 'grey.100', my: 0, px: 1.5 }}
      >
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          <Typography variant='overline'>
            <TabLabelWithCount
              color='neutral'
              count={selectionCount}
              label={params.group}
              variant='light'
            />
          </Typography>
          <Button
            data-testid='clear'
            onClick={() => clearSelected(params.group)}
            size='small'
            sx={{
              typography: 'overline',
              visibility: selectionCount > 0 ? 'visible' : 'hidden',
            }}
            variant='text'
          >
            Clear
          </Button>
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              transform: isExpanded ? 'rotate(0.5turn)' : 'none',
              transition: '300ms ease-in-out',
            }}
          >
            <CaretDown />
          </IconButton>
        </Stack>
      </ListSubheader>
      <Collapse in={isExpanded}>
        <List data-testid='group-list' sx={{ mb: 0.5 }}>
          {params.children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default AutocompleteGroup;
