import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { useSnackbar } from 'notistack';

export const ImageContext = createContext<any>({});

type ImageState = 'uploading' | 'editing' | 'deleting' | 'pending' | null;

type Dimension = number | null;

export interface Dimensions {
  height: Dimension;
  width: Dimension;
  x: Dimension;
  y: Dimension;
}

interface Img {
  cloudinary?: any;
  dimensions?: Dimensions;
  file?: any;
  key?: string;
  name?: string;
  url?: string | null;
  urlFull?: string;
}

interface PendingImg extends Img {
  preview?: string;
}

interface ImageProviderProps {
  children?: any;
  dimensions?: Dimensions;
  image?: any;
  url?: string;
}

export const ImageProvider = (props: ImageProviderProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [action, setAction] = useState<ImageState>(null);
  const [pendingImage, setPendingImage] = useState<PendingImg>({});
  const [savedImage, setSavedImage] = useState<Img>(props?.image);

  const isDeleting = useMemo(() => action === 'deleting', [action]);
  const isEditing = useMemo(() => action === 'editing', [action]);
  const isUploading = useMemo(() => action === 'uploading', [action]);

  const ftnCloud = useMemo(
    () =>
      new Cloudinary({
        cloud: {
          cloudName: 'five-to-nine',
        },
      }),
    []
  );

  const hasImage = useMemo(
    () =>
      Boolean(pendingImage?.url || (savedImage?.url && action !== 'deleting')),
    [action, pendingImage?.url, savedImage?.url]
  );

  const clearPreview = useCallback(() => {
    if (pendingImage?.url && pendingImage?.url?.indexOf('data:image') > -1) {
      const previewUrl = `${pendingImage?.url}`;
      setPendingImage({});
      URL.revokeObjectURL(previewUrl);
    }
  }, [pendingImage?.url]);

  useEffect(() => {
    const hasPendingDataPreview =
      pendingImage?.url && pendingImage?.url?.indexOf('data:image') > -1;
    if (
      props?.url &&
      props?.url?.length > 0 &&
      (hasPendingDataPreview ||
        !savedImage?.url ||
        savedImage?.url !== props?.url)
    ) {
      const saved: Img = {};
      // INFO: Set savedImage.url to the Cloudinary URL once we receive it from the BE
      // (after saving the event). Then, clear the pendingImage, and revoke the URL
      // that was generated for the preview of the cropped image (before it was saved to the BE)
      if (props?.image?.key) {
        const cldImg = ftnCloud.image(props?.image?.key);
        saved.key = props?.image?.key;
        saved.cloudinary = cldImg;
        saved.urlFull = cldImg?.toURL();
      }
      if (props?.url && (!savedImage?.url || savedImage?.url !== props?.url)) {
        saved.url = props?.url;
      }
      if (
        props?.dimensions &&
        (!savedImage?.dimensions ||
          savedImage?.dimensions !== props?.dimensions)
      ) {
        saved.dimensions = props?.dimensions;
      }
      if (
        saved?.url ||
        saved?.dimensions ||
        saved?.cloudinary ||
        saved?.urlFull ||
        saved?.key
      ) {
        setSavedImage(saved);
      }
      clearPreview();
      setAction(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.url, clearPreview]);

  useEffect(() => {
    if (
      action === 'deleting' &&
      savedImage?.url &&
      savedImage?.url?.length > 0 &&
      !props?.url?.length
    ) {
      setSavedImage(new Image());
      setAction(null);
    }
  }, [action, props?.url?.length, savedImage?.url]);

  const handleDrop = (files: any[]) => {
    const file: any = files[0];
    const maxSize = 20 * 1024 * 1024;

    if (file.size > maxSize) {
      enqueueSnackbar(
        'The image you selected exceeds the maximum allowable size for upload (20 MB). Please select a smaller image and try again.',
        {
          variant: 'error',
        }
      );
      return;
    }

    if (pendingImage?.urlFull) {
      URL.revokeObjectURL(pendingImage?.urlFull);
    }

    setAction('uploading');
    const urlFull = URL.createObjectURL(file);
    setPendingImage({
      file,
      url: null,
      urlFull,
    });
    setAction('editing');
  };

  const handleDelete = () => {
    if (pendingImage?.url) {
      URL.revokeObjectURL(pendingImage?.url);
      setPendingImage({});
    }
    setAction('deleting');
  };

  const setIsDeleting = useCallback(
    (val: boolean) => setAction(val ? 'deleting' : null),
    []
  );
  const setIsEditing = useCallback(
    (val: boolean) => setAction(val ? 'editing' : null),
    []
  );
  const setIsPending = useCallback(
    (val: boolean) => setAction(val ? 'pending' : null),
    []
  );
  const setIsUploading = useCallback(
    (val: boolean) => setAction(val ? 'uploading' : null),
    []
  );

  return (
    <ImageContext.Provider
      value={{
        action,
        handleDelete,
        handleDrop,
        hasImage,
        isDeleting,
        isEditing,
        isUploading,
        pendingImage,
        savedImage,
        setAction,
        setIsDeleting,
        setIsEditing,
        setIsPending,
        setIsUploading,
        setPendingImage,
        setSavedImage,
      }}
    >
      {props?.children}
    </ImageContext.Provider>
  );
};
