import { EventResource } from 'components/Event/EventResourcesField';
import { EventDetailsFormData } from 'types/EventDetailsForm';
import { handleSlackEmojiConversion } from 'utils/text';

const formDataResourceBuilder = (
  resource: EventResource,
  i: number,
  fd: FormData
) => {
  resource?.id &&
    fd.set(`event[resources_attributes][${i}][id]`, resource.id.toString());
  fd.set(`event[resources_attributes][${i}][title]`, resource.title || '');
  fd.set(`event[resources_attributes][${i}][link]`, resource.link || '');
  resource?.destroy === true &&
    fd.set(`event[resources_attributes][${i}][_destroy]`, 'true');
};

const formDataDisplayableTypeBuilder = (
  type: 'Groups' | 'Offices' | 'Organizers',
  ids: number[],
  i: number,
  fd: FormData
) => {
  fd.set(`event[displayable_attributes][${i}][displayable_type]`, type);
  fd.set(
    `event[displayable_attributes][${i}][displayable_ids]`,
    JSON.stringify(ids)
  );
};

const EventDetailsFormDataBuilder = (
  eventDetails: EventDetailsFormData,
  publish: boolean
): FormData => {
  const fd = new FormData();

  // Id (Needed for publishing a saved draft)
  if (eventDetails.id) {
    fd.set('event[id]', eventDetails.id.toString());
  }

  // Name
  fd.set('event[name]', eventDetails.name || '');

  // Description
  fd.set(
    'event[description]',
    handleSlackEmojiConversion(eventDetails.description) || ''
  );

  // Resources
  eventDetails?.resources?.forEach((resource, i) => {
    formDataResourceBuilder(resource, i, fd);
  });

  // (Displayed) Hosts
  eventDetails?.hosts?.hosts?.forEach(
    (
      {
        ids,
        type,
      }: { ids: number[]; type: 'Groups' | 'Offices' | 'Organizers' },
      i
    ) => {
      formDataDisplayableTypeBuilder(type, ids, i, fd);
    }
  );
  fd.set(
    'event[config][display_organizers_as_hosts]',
    eventDetails.hosts?.displayOrganizersAsHosts ? 'true' : 'false'
  );

  // Event Type
  fd.set(
    'event[event_type_ids]',
    JSON.stringify(eventDetails?.eventTypeId ? [eventDetails.eventTypeId] : [])
  );

  // Start & End
  fd.set('event[start]', eventDetails.start.toString());
  fd.set('event[ends]', eventDetails.ends.toString());

  // Location
  fd.set('event[location]', eventDetails.location || '');

  // Privacy
  fd.set('event[privacy]', eventDetails.privacy || 'open');

  // Hosts (Organizers)
  fd.set('event[host_ids]', JSON.stringify(eventDetails.hostIds || []));

  // Meeting
  if (eventDetails.meeting?.service != null) {
    fd.set('event[meeting_attributes][service]', eventDetails.meeting.service);
    fd.set('event[meeting_attributes][link]', eventDetails.meeting.link || '');
    fd.set(
      'event[meeting_attributes][config][attendance_tracking]',
      eventDetails.meeting.attendanceTracking ? 'true' : 'false'
    );
    eventDetails.meeting?.serviceUid != null &&
      fd.set(
        'event[meeting_attributes][service_uid]',
        eventDetails.meeting.serviceUid
      );
  }

  // RSVP Pausing
  fd.set(
    'event[rsvp_pause_allowed]',
    eventDetails.rsvpPauseAllowed ? 'true' : 'false'
  );

  // Groups
  fd.set('event[group_ids]', JSON.stringify(eventDetails.groupIds || []));

  // Offices
  fd.set('event[office_ids]', JSON.stringify(eventDetails.officeIds || []));

  // Cover Image
  if (eventDetails?.coverImage?.file || eventDetails?.coverImage?.deleteImage) {
    fd.set('event[coverImageChanged]', 'true');
    fd.set('event[cover_image]', eventDetails.coverImage.file || '');
  } else {
    fd.set('event[coverImageChanged]', 'false');
  }
  eventDetails?.coverImage?.dimensions != null &&
    !eventDetails.coverImage?.deleteImage &&
    fd.set(
      'event[config][cover_image_dimensions]',
      JSON.stringify(eventDetails.coverImage.dimensions)
    );

  // Published
  fd.set('event[published]', publish ? 'true' : 'false');

  return fd;
};

export default EventDetailsFormDataBuilder;
