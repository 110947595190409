/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { alpha, Theme } from '@mui/material';
import { ArrowsDownUp, ArrowUp, MagnifyingGlass } from '@phosphor-icons/react';
import {
  type MRT_RowData,
  type MRT_TableOptions,
  getMRT_RowSelectionHandler,
  MRT_Row,
  MRT_TableBodyRowProps,
  MRT_TableInstance,
} from 'material-react-table';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

export const getDefaultMRTOptions = <TData extends MRT_RowData>(
  height?: number | string,
  withBorders = false
): Partial<MRT_TableOptions<TData>> => ({
  displayColumnDefOptions: {
    'mrt-row-actions': {
      grow: false,
      size: 80,
    },
    'mrt-row-select': {
      grow: false,
      size: 30,
    },
  },
  enableBottomToolbar: true,
  enableColumnActions: false,
  getRowId: (row) => row.id,
  icons: {
    // INFO: There's a bug within MRT that causes the arrows to render in the opposite direction.
    ArrowDownwardIcon: (props: any) => <ArrowUp {...props} />,
    SearchIcon: () => <MagnifyingGlass size={20} />,
    SortIcon: (props: any) => <ArrowsDownUp {...props} />,
  },
  initialState: {
    showColumnFilters: false,
    showGlobalFilter: true,
  },
  layoutMode: 'grid',
  localization: {
    ...MRT_Localization_EN,
    selectedCountOfRowCountRowsSelected: `{selectedCount} selected`,
  },
  muiBottomToolbarProps: {
    sx: {
      '& .MuiCollapse-root + .MuiBox-root': {
        '& .MuiBox-root': {
          justifyContent: 'flex-end',
          position: 'relative',
          right: 'unset',
          top: 'unset',
          width: 'auto',
        },
        flex: 1,
        padding: 0,
        width: 'auto',
      },
      '& .MuiTablePagination-root': {
        '& .MuiFormLabel-root': {
          paddingBottom: '0 !important',
        },
        '& .MuiInputBase-inputSizeSmall': {
          py: 0.75,
        },
        '& .MuiInputBase-sizeSmall': {
          my: 0.5,
        },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        px: 2,
        py: 1,
      },
      alignItems: 'stretch',
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      borderColor: 'grey.300',
      borderStyle: 'solid',
      borderTopColor: 'transparent !important',
      borderWidth: 1,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      // maxHeight: 40,
      overflow: 'hidden',
    },
  },
  muiEditTextFieldProps: { variant: 'outlined' },
  muiFilterTextFieldProps: { size: 'medium', variant: 'outlined' },
  muiPaginationProps: {
    SelectProps: {
      size: 'small',
      variant: 'outlined',
    },
    sx: {
      padding: 0,
    },
  },
  muiTableBodyCellProps: {
    sx: {
      '& .MuiCheckbox-root.Mui-disabled': {
        '& svg': {
          // INFO: Hide checkbox icon
          display: 'none',
        },
        backgroundImage:
          // INFO: Show static check icon (from https://phosphoricons.com/?q=%22check%22&size=24)
          'url( "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iIzAwMDAwMCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMjkuNjYsNzcuNjZsLTEyOCwxMjhhOCw4LDAsMCwxLTExLjMyLDBsLTU2LTU2YTgsOCwwLDAsMSwxMS4zMi0xMS4zMkw5NiwxODguNjksMjE4LjM0LDY2LjM0YTgsOCwwLDAsMSwxMS4zMiwxMS4zMloiPjwvcGF0aD48L3N2Zz4=" )',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
      bgcolor: 'white',
      ...(withBorders
        ? {}
        : {
            '&:hover': {
              border: 'none',
            },
            border: 'none',
          }),
    },
  },
  muiTableBodyRowProps: {
    sx: {
      '&:NOT(.Mui-selected)&:hover > td::after': { bgcolor: 'grey.50' },
      bgcolor: 'white',
    },
  },
  muiTableContainerProps: ({ table }) => ({
    sx: {
      backgroundColor: 'white',
      borderBottomLeftRadius: table.getRowCount() > 0 ? 0 : 6,
      borderBottomRightRadius: table.getRowCount() > 0 ? 0 : 6,
      borderBottomWidth: table.getRowCount() > 0 ? '0 !important' : '1',
      borderColor: 'grey.300',
      borderStyle: 'solid',
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderWidth: 1,
      width: '100%',
      ...(height ? { height } : {}),
    },
  }),
  muiTableFooterProps: {
    sx: {
      outline: 'none',
    },
  },
  muiTableHeadCellProps: {
    sx: (theme) => ({
      '& [aria-label^="Filtering by"]:active': {
        pointerEvents: 'none',
        // INFO: Prevent click action on filter icon since it expands the header
      },
      fontSize: theme.typography.overline.fontSize,
      fontWeight: 500,
    }),
  },
  muiTableHeadRowProps: {
    sx: {
      bgcolor: 'grey.100',
    },
  },
  muiTablePaperProps: {
    elevation: 0,
    sx: { bgcolor: 'transparent', width: '100%' },
  },
  muiToolbarAlertBannerProps: {
    sx: {
      '& .MuiAlert-message': {
        '& > .MuiStack-root': {
          px: 2,
          py: 1.5,
        },
        padding: 0,
      },
      backgroundColor: 'transparent !important',
      borderLeft: 'none',
    },
    variant: 'outlined',
  },
  muiTopToolbarProps: {
    sx: {
      '& > .MuiBox-root': {
        padding: 0,
      },
      alignItems: 'center',
      bgcolor: 'transparent',
    },
  },
  positionGlobalFilter: 'left',
  positionToolbarAlertBanner: 'bottom',
});

export const getServerSideMRTOptions = <TData extends MRT_RowData>(
  height?: number | string,
  withBorders = false
): Partial<MRT_TableOptions<TData>> => {
  const defaultMRTOptions = getDefaultMRTOptions<TData>(height, withBorders);
  return {
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    ...defaultMRTOptions,
  };
};

export const getSelectableMRTRowProps = <TData extends MRT_RowData>({
  row,
  staticRowIndex,
  table,
}: {
  row: MRT_Row<TData>;
  staticRowIndex: number;
  table: MRT_TableInstance<TData>;
}): Partial<MRT_TableBodyRowProps<TData>> => ({
  onClick: (event: any) =>
    getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event),
  sx: (theme: Theme) => ({
    '& .MuiTableCell-root, &.Mui-selected .MuiTableCell-root': {
      backgroundColor: 'white',
    },
    '&.Mui-selected td:after': {
      backgroundColor: alpha(theme.palette.primary.light, 0.5),
    },
    '&:not(.Mui-selected):hover td:after': {
      backgroundColor: alpha(theme.palette.primary.light, 0.25),
    },
    bgcolor: 'white',
    cursor: 'pointer',
  }),
});

export const getInitialSorting = (id = 'name', desc = false) => [
  {
    desc,
    id,
  },
];

export const getInitialPagination = ({ pageIndex = 0, pageSize = 10 }) => ({
  pageIndex,
  pageSize,
});
