import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { Eye } from '@phosphor-icons/react';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import { AuthContext } from 'contexts/AuthContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { useContextWithFallback } from 'utils/utils';

const ViewEventAction = () => {
  const {
    userProvider: { isOrganizerOrAdmin },
  } = useContext(AuthContext);
  const { isMobile } = useContext(LayoutContext);
  const {
    state: { event },
  } = useContextWithFallback(PublicEventContext, ManagerContext);
  const navigate = useNavigate();
  const location = useLocation();

  const viewEvent = {
    action: () => navigate(`/event/${event.id}/view`),
    icon: <Eye />,
  };

  return (
    <>
      {!location.pathname.includes('public') && isOrganizerOrAdmin && (
        <>
          {isMobile ? (
            <IconButton
              data-testid='TODO:DATA-ICONBUTTON-39797'
              onClick={viewEvent.action}
            >
              {viewEvent.icon}
            </IconButton>
          ) : (
            <Button
              color='primary'
              data-testid='view-event'
              onClick={viewEvent.action}
              startIcon={viewEvent.icon}
              sx={{ whiteSpace: 'nowrap' }}
              variant='outlined'
            >
              View event
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default ViewEventAction;
