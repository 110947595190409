import React from 'react';
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

interface Props {
  handleToggle: (value: boolean) => any;
  key: any;
  label: string;
  value: boolean;
}

export const ListItemWithCheckbox = ({
  handleToggle,
  key,
  label,
  value,
}: Props) => {
  const labelId = `checkbox-list-label-${key}`;
  return (
    <ListItem key={key}>
      <ListItemButton
        component='div'
        dense
        onClick={() => handleToggle(value)}
        role={undefined}
      >
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Checkbox
            checked={value}
            disableRipple
            edge='start'
            inputProps={{ 'aria-labelledby': labelId }}
            tabIndex={-1}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={label} />
      </ListItemButton>
    </ListItem>
  );
};
