import React, { useEffect, useState } from 'react';
import {
  FormState,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormSetError,
  UseFormWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { RocketLaunch, Trash } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import DeleteModal from 'components/Core/DeleteModal';
import { useEventsCollectionCreate } from 'hooks/useEventsCollectionCreate';
import { useEventsCollectionDelete } from 'hooks/useEventsCollectionDelete';
import { useEventsCollectionUpdate } from 'hooks/useEventsCollectionUpdate';
import { CollectionFormData } from 'types/CollectionForm';

interface CollectionToolbarProps {
  clearErrors: UseFormClearErrors<CollectionFormData>;
  collectionId: number | null;
  formState: FormState<CollectionFormData>;
  handleSubmit: UseFormHandleSubmit<CollectionFormData>;
  isPublished: boolean;
  setError: UseFormSetError<CollectionFormData>;
  watch: UseFormWatch<CollectionFormData>;
}

const CollectionToolbar: React.FC<CollectionToolbarProps> = ({
  clearErrors,
  collectionId,
  formState,
  handleSubmit,
  isPublished,
  setError,
  watch,
}) => {
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const { errors, isDirty: hasChanges } = formState;
  const { enqueueSnackbar } = useSnackbar();

  const selectedEvents = watch('events');
  const collectionTitle = watch('name');

  const buttonSize = 'medium';

  const handleCloseDialog = () => setIsClosing(false);

  const queryClient = useQueryClient();

  const createCollection = useEventsCollectionCreate();

  // const { isPending: isCreating, isSuccess: isCreated } = createCollection;

  const deleteCollection = useEventsCollectionDelete();

  const updateCollection = useEventsCollectionUpdate();

  const validateForm = (action: string) => {
    clearErrors();
    let valid = true;

    if (action === 'publish' || (action === 'update' && isPublished)) {
      if (!selectedEvents.length) {
        setError('events', {
          message: 'Add at least one event to publish your collection.',
        });
        valid = false;
      }

      if (!collectionTitle.length) {
        setError('name', {
          message: 'This field is required.',
        });
        valid = false;
      }

      if (errors?.events?.message || errors?.name) {
        enqueueSnackbar('Please resolve errors before saving your changes', {
          variant: 'error',
        });
        return false;
      }
    }

    // Clear specific errors when saving as draft
    if (action === 'draft' && errors?.events?.message) {
      clearErrors('events');
    }

    return valid;
  };

  const saveDraftOrPublish = (formData: any, action: string) => {
    createCollection.mutate(
      { action, data: formData },
      {
        onError: (error: Error) => {
          enqueueSnackbar(`Error saving collection: ${error.message}`, {
            variant: 'error',
          });
        },
        onSuccess: () => {
          // const collectionId =
          //   response?.eventsCollectionCreate?.eventsCollection?.id;
          // if (collectionId) {
          navigate(
            `/collections/${action === 'publish' ? 'upcoming' : 'drafts'}`
          );
          // }
        },
      }
    );
  };

  const saveUpdates = (formData: any) => {
    updateCollection.mutate(
      { data: formData },
      {
        onError: (error: Error) => {
          enqueueSnackbar(`Error saving collection: ${error.message}`, {
            variant: 'error',
          });
        },
        onSuccess: () => {
          navigate(`/collections/${collectionId}/details`);
        },
      }
    );
  };

  const handleSaveChanges = async (action: string) => {
    if (!validateForm(action)) {
      return;
    }

    handleSubmit(() => {
      const formData = {
        collectionImage: watch('collection_image')?.file,
        description: watch('description'),
        eventIds: watch('events'),
        name: watch('name'),
        organizerEmployeeIds: watch('organizer_employee_ids'),
        ...(watch('collection_image')?.dimensions
          ? {
              config: {
                cover_image_dimensions: watch('collection_image')?.dimensions,
              },
            }
          : {}),
        ...(action === 'update' ? { id: collectionId } : {}),
      };

      if (action === 'update') {
        saveUpdates(formData);
      } else {
        saveDraftOrPublish(formData, action);
      }
    })();
  };

  const handleDeleteCollection = async (collectionId: string) => {
    await deleteCollection.mutate({ id: collectionId });

    queryClient.invalidateQueries({
      queryKey: ['collections', null, 'upcoming'],
    });

    setTimeout(() => {
      navigate('/collections/upcoming');
    }, 500);
  };

  useEffect(() => {
    if (selectedEvents.length > 0 && errors?.events?.message) {
      clearErrors('events');
    }
  }, [selectedEvents, errors, clearErrors]);

  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        gap={1.5}
        justifyContent='flex-end'
      >
        <Button
          data-testid='close'
          onClick={() => {
            if (hasChanges) {
              setIsClosing(true);
            } else if (isPublished) {
              navigate(`/collections/${collectionId}/details`);
            } else {
              navigate('/collections');
            }
          }}
          size={buttonSize}
          sx={{ px: 2 }}
          variant='text'
        >
          Cancel
        </Button>
        <LoadingButton
          data-testid={
            isPublished ? 'delete-collection' : 'save-draft-collection'
          }
          disabled={!isPublished && !hasChanges}
          onClick={() =>
            isPublished
              ? setIsConfirmingDelete(true)
              : handleSaveChanges(collectionId ? 'update' : 'draft')
          }
          size={buttonSize}
          startIcon={isPublished ? <Trash /> : <RocketLaunch />}
          sx={{ px: 2 }}
          variant='outlined'
        >
          {isPublished ? 'Delete collection' : 'Save draft'}
        </LoadingButton>

        <LoadingButton
          data-testid={isPublished ? 'save-collection' : 'publish-collection'}
          disabled={isPublished && !hasChanges}
          onClick={() => {
            isPublished
              ? handleSaveChanges('update')
              : handleSaveChanges('publish');
          }}
          size={buttonSize}
          startIcon={<RocketLaunch />}
          sx={{ px: 2 }}
          variant='contained'
        >
          {isPublished ? 'Save changes' : 'Publish collection'}
        </LoadingButton>
      </Stack>
      <Dialog
        data-testid='unsaved-collection-changes'
        onClose={handleCloseDialog}
        open={isClosing}
      >
        <DialogTitle>You have unsaved changes!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to save them before closing?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='cancel'
            onClick={handleCloseDialog}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            data-testid='discard-changes'
            onClick={() => {
              handleCloseDialog();
              if (isPublished) {
                navigate(`/collections/${collectionId}/details`);
              } else {
                navigate('/collections');
              }
            }}
            variant='outlined'
          >
            No, discard
          </Button>
          <Button
            data-testid='save-changes'
            onClick={() => handleSaveChanges(collectionId ? 'update' : 'draft')}
            variant='contained'
          >
            Yes, save changes
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteModal
        custom={{
          byline:
            'Are you sure you want to delete this collection? This action cannot be undone and you will be unable to add any past events to a new collection. Collection announcements will also be removed from Slack channels. This will not impact the current guest list of your events. ',
          delete: 'Yes, remove',
        }}
        noun='Collection'
        onCancel={() => setIsConfirmingDelete(false)}
        onDelete={() => {
          if (collectionId) {
            handleDeleteCollection(String(collectionId));
            setIsConfirmingDelete(false);
          }
        }}
        show={isConfirmingDelete}
        subject='collection'
      />
    </>
  );
};

export default CollectionToolbar;
