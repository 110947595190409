import React from 'react';

import { EventWrapper } from 'components/Events/EventWrapper';
import EventManagerRoutes from 'components/Events/Manager/EventManagerRoutes';
import { ManagerProvider } from 'components/Events/Manager/ManagerContext';
import { SlackChannelsProvider } from 'contexts/SlackChannelsContext';
import { SlackEmployeesProvider } from 'contexts/SlackEmployeesContext';
import { SyncedSlackChannelProvider } from 'contexts/SyncedSlackChannelContext';

const EventManager = () => (
  <SlackEmployeesProvider>
    <SlackChannelsProvider>
      <SyncedSlackChannelProvider>
        <ManagerProvider>
          <EventWrapper>
            <EventManagerRoutes />
          </EventWrapper>
        </ManagerProvider>
      </SyncedSlackChannelProvider>
    </SlackChannelsProvider>
  </SlackEmployeesProvider>
);
export default EventManager;
