import React, { FC, useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Pagination,
} from '@mui/material';
import { ArrowDown, ArrowUp } from '@phosphor-icons/react';

import GuestCheckList from 'components/Core/GuestCheckList';
import RadioLabel from 'components/shared/RadioLabel';
import { fullName } from 'utils/employee';

interface UploaderTableProps {
  disable: boolean;
  fixedValues?: number[];
  isSelectable: boolean;
  nounPlural?: string;
  onChange?: (selected: number[] | string[], value: any) => void;
  options: any[];
  showInvitedAsFixed: boolean;
  value: any;
}
const UploaderTable: FC<UploaderTableProps> = ({
  disable = false,
  fixedValues,
  isSelectable = true,
  nounPlural = 'contacts',
  onChange = null,
  options,
  value,
}) => {
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('asc');

  const handlePageChange = (
    event: any,
    value: React.SetStateAction<number>
  ) => {
    setPage(value);
  };

  const toggleSortOrder = () => {
    if (sortOrder === 'asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
  };

  const handleSelections = (event: number[] | string[], value: any) => {
    if (onChange) {
      onChange(event, value);
    }
  };

  const isFixed = (opt: { id: number }) => fixedValues?.includes(opt?.id);

  return (
    <>
      {isSelectable ? (
        <GuestCheckList
          disabled={disable}
          height={300}
          isSelectable
          noOptionsMessage={`No ${nounPlural}`}
          nounPlural={nounPlural}
          onChange={handleSelections}
          options={options?.map((e) => ({
            email: e.email,
            fixed: isFixed(e),
            id: e.id,
            name: fullName(e),
          }))}
          showSelectedCountInHeader
          sx={{ minHeight: 300 }}
          value={value}
        />
      ) : (
        <Card data-testid='TODO:DATA-CARD-24036' variant='outlined'>
          <CardHeader
            action={
              options?.length > 0 ? (
                <IconButton
                  data-testid='TODO:DATA-ICONBUTTON-31588'
                  onClick={toggleSortOrder}
                  size='small'
                >
                  {sortOrder === 'asc' ? <ArrowUp /> : <ArrowDown />}
                </IconButton>
              ) : null
            }
            sx={{
              '& .MuiCardHeader-action': {
                marginBottom: '-2px',
                marginTop: '-2px',
              },
              '& .MuiCardHeader-content': { flex: '0 0 auto' },
              alignItems: 'center',
              bgcolor: 'grey.100',
              borderBottomColor: 'grey.300',
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              flexDirection: 'row',
              py: 1,
            }}
            title='Contacts'
            titleTypographyProps={{ variant: 'body2' }}
          />
          {options?.length > 0 ? (
            <>
              <List data-testid='TODO:DATA-LIST-54856' dense disablePadding>
                {options?.map((o) => (
                  <ListItem divider key={o.id}>
                    <ListItemText>
                      <RadioLabel primary={fullName(o)} secondary={o.email} />
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              {options?.length > 15 && (
                <Pagination
                  count={Math.ceil(options?.length / 15)}
                  data-testid='TODO:DATA-PAGINATION-77864'
                  onChange={handlePageChange}
                  page={page}
                  sx={{
                    '.MuiPagination-ul': { justifyContent: 'center' },
                    mt: 2,
                  }}
                />
              )}
            </>
          ) : (
            <Box color='text.secondary' p={2}>
              No selected contacts
            </Box>
          )}
        </Card>
      )}
    </>
  );
};

export default UploaderTable;
