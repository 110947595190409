import React, { useContext, useState } from 'react';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Circle, MinusCircle, Square } from '@phosphor-icons/react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { useContextWithFallback } from 'utils/utils';

const EditQuestionOption = ({
  isLast,
  onDeleteOption,
  option,
  optionIndex,
  optionResponseIndex,
  question,
  questionIndex,
  surveys,
  surveyType,
}) => {
  const {
    actions: { applySingle },
    state: { showErrors, surveyFieldIdsToDisplayErrorsOn },
  } = useContextWithFallback(ManagerContext, SurveyTemplatesContext);
  const { isMobile } = useContext(LayoutContext);

  const [hasContent, setHasContent] = useState(option?.text?.length > 0);

  const updateOption = (value, field, index, type) => {
    const update = _.cloneDeep(_.get(surveys, `${type}.survey_options`));
    update[index][field] = value;
    applySingle(update, `surveys.${type}.survey_options`);
  };

  const removeOption = (index, type) => {
    updateOption(1, '_destroy', index, type);
    onDeleteOption();
  };

  const identifier = `option-${questionIndex}-${optionResponseIndex}`;

  const iconProps = {
    size: 24,
    weight: 'light',
  };

  return (
    <Stack
      alignItems='start'
      data-testid='survey-option'
      direction='row'
      flexGrow={1}
      key={identifier}
      spacing={1}
      sx={{ width: '100%' }}
    >
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              {question?.question_type === 'multi_select' ? (
                <Square {...iconProps} />
              ) : (
                <Circle {...iconProps} />
              )}
            </InputAdornment>
          ),
        }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={isLast && option?.text?.length === 0}
        error={
          showErrors &&
          surveyFieldIdsToDisplayErrorsOn?.includes(
            option?.pending_id || option?.id
          ) &&
          !hasContent
        }
        helperText={
          showErrors &&
          surveyFieldIdsToDisplayErrorsOn?.includes(
            option?.pending_id || option?.id
          ) &&
          !hasContent
            ? 'This field is required'
            : null
        }
        id={identifier}
        key={identifier}
        name='text'
        onChange={(e) => {
          updateOption(e.target.value, 'text', optionResponseIndex, surveyType);
          setHasContent(e.target.value.length > 0);
        }}
        placeholder={`Option ${optionIndex + 1}`}
        required
        sx={{ m: 0, width: isMobile ? 'calc(100% - 48px)' : '50%' }}
        value={option?.text}
      />
      <IconButton
        color='error'
        data-testid='TODO:DATA-ICONBUTTON-21974'
        onClick={() => removeOption(optionResponseIndex, surveyType)}
      >
        <MinusCircle />
      </IconButton>
    </Stack>
  );
};

EditQuestionOption.propTypes = {
  applySingle: PropTypes.func,
  error: PropTypes.any,
  isLast: PropTypes.bool,
  option: PropTypes.shape({
    text: PropTypes.string,
  }),
  optionResponseIndex: PropTypes.number,
  question: PropTypes.shape({
    question_type: PropTypes.string,
  }),
  questionIndex: PropTypes.number,
  surveys: PropTypes.any,
  surveyType: PropTypes.any,
};

export default EditQuestionOption;
