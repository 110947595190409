import React, { useMemo } from 'react';
import { createCloudinaryLegacyURL } from '@cloudinary/url-gen';
import { Avatar, SxProps, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import { reactQueryAxios } from 'utils/axios';
import { toInitials } from 'utils/text';

interface Props {
  employeeId: number;
  fullName?: any;
  hasTooltip?: boolean;
  size?: number;
  sx?: SxProps;
}

interface AvatarResponse {
  avatar: {
    key: string | null;
  } | null;
}

const EmployeeAvatar = ({
  employeeId,
  fullName,
  hasTooltip = false,
  size = 24,
  sx,
}: Props) => {
  const getAvatar = async (
    employeeId: number
  ): Promise<AvatarResponse | null> => {
    if (employeeId) {
      const { data } = await reactQueryAxios<AvatarResponse, any>({
        method: 'get',
        url: `/api/employees/${employeeId}/avatar`,
      });
      return data;
    } else {
      return null;
    }
  };

  const { data } = useQuery({
    queryFn: () => getAvatar(employeeId),
    queryKey: ['avatar', employeeId],
    staleTime: 10000,
  });

  const migrateUrl = useMemo(
    () =>
      data?.avatar?.key
        ? createCloudinaryLegacyURL(data?.avatar?.key, {
            aspect_ratio: '1.0',
            cloud_name: 'five-to-nine',
            crop: 'fit',
            height: size * 3,
            public_id: data?.avatar?.key,
            secure: process.env.NODE_ENV !== 'development',
          })
        : '',
    [data?.avatar?.key, size]
  );

  const fontSize = useMemo(() => 0.4 * size, [size]);

  return (
    <ConditionalWrapper
      condition={hasTooltip}
      wrapper={(children) => <Tooltip title={fullName}>{children}</Tooltip>}
    >
      <Avatar
        {...(migrateUrl?.length > 0 ? { src: migrateUrl } : {})}
        alt={fullName}
        sx={{
          bgcolor: 'secondary.main',
          fontSize,
          height: size,
          width: size,
          ...(sx || {}),
        }}
      >
        {toInitials(fullName)}
      </Avatar>
    </ConditionalWrapper>
  );
};

export default EmployeeAvatar;
