import React from 'react';
import { ListItemIcon, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CaretDown, PlusCircle } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import { SurveyQuestionTypes } from 'constants/survey.constants';

const AddQuestionMenu = ({ handleAddQuestion, hasNps, surveyType }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl) || false;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (questionType) => {
    handleAddQuestion(questionType);
    handleClose();
  };

  return (
    <>
      <Button
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        endIcon={<CaretDown />}
        id='add-question-button'
        onClick={handleClick}
        startIcon={<PlusCircle size={20} weight='fill' />}
        variant='outlined'
      >
        Add question
      </Button>
      <Menu
        anchorEl={anchorEl}
        id='add-question-menu'
        MenuListProps={{
          'aria-labelledby': 'add-question-button',
        }}
        onClose={handleClose}
        open={isOpen}
      >
        {SurveyQuestionTypes.filter(
          (t) =>
            t.value !== 'nps' || (surveyType === 'post_event_survey' && !hasNps)
        ).map(({ label, surveyIcon, value }) => (
          <MenuItem key={value} onClick={() => handleSelect(value)}>
            <ListItemIcon>{surveyIcon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

AddQuestionMenu.propTypes = {
  handleAddQuestion: PropTypes.func,
  hasNps: PropTypes.any,
  surveyType: PropTypes.string,
};

export default AddQuestionMenu;
