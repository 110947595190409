import React, { useContext, useMemo } from 'react';
import { Box, Chip, FormLabel, Stack } from '@mui/material';

import EventVirtualLocationCopyLink from 'components/Event/EventVirtualLocationCopyLink';
import EventVirtualMeetingField from 'components/Event/EventVirtualMeetingField';
import EventVirtualMeetingMenu from 'components/Event/EventVirtualMeetingMenu';
import InfoTooltip from 'components/shared/InfoTooltip';
import { allMeetingServices } from 'constants/integration.constants';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import {
  EventDetailsFormControl,
  EventDetailsFormMeetingData,
  EventDetailsFormRegister,
  EventDetailsFormSetValue,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';

interface EventVirtualLocationFieldProps {
  control: EventDetailsFormControl;
  creatorId?: number;
  creatorName?: string;
  disableFields: boolean;
  eventId: number | null;
  hasSavedMeeting: boolean;
  register: EventDetailsFormRegister;
  setValue: EventDetailsFormSetValue;
  watch: EventDetailsFormWatch;
}

const EventVirtualLocationField = ({
  control,
  creatorId,
  creatorName,
  disableFields,
  eventId,
  hasSavedMeeting,
  register,
  setValue,
  watch,
}: EventVirtualLocationFieldProps) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;

  const { copy } = useCopyToClipboard();
  const meeting: EventDetailsFormMeetingData | undefined = watch('meeting');

  const selectedMeetingService = useMemo(
    () => allMeetingServices.find((type) => type?.id === meeting?.service),
    [meeting]
  );

  const isAutoGenerated = useMemo(
    () => meeting?.service && meeting?.service !== 'unknown',
    [meeting]
  );

  const canCopyLinkOnly = useMemo(
    () =>
      isAutoGenerated && creatorId && String(user?.id) !== String(creatorId), // TODO: Check if user.id is a number or string
    [isAutoGenerated, creatorId, user?.id]
  );

  const copyMeetingLink = () => {
    const link = meeting?.link;
    if (link) {
      copy(link, 'virtual link');
    }
  };

  const isZoomService = useMemo(
    () =>
      meeting?.service === 'zoom_meeting' ||
      meeting?.service === 'zoom_webinar',
    [meeting]
  );

  return (
    <Box data-testid='virtual-location-wrapper'>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        mb={1}
      >
        <FormLabel
          htmlFor='virtual_location'
          sx={{ display: 'block', mb: -0.75 }}
        >
          {canCopyLinkOnly ? (
            <InfoTooltip
              content={`This Zoom meeting can only be managed by ${creatorName} since they generated it.`}
              text='Virtual location'
            />
          ) : (
            'Virtual location'
          )}
        </FormLabel>
        {isZoomService && meeting?.attendanceTracking && (
          /* savedEvent?.meeting?.link === event?.meeting?.link && */ <Chip
            color='success'
            label='Tracking attendance'
            size='small'
            variant='light'
          />
        )}
      </Stack>
      {canCopyLinkOnly ? (
        <EventVirtualLocationCopyLink
          copyLink={copyMeetingLink}
          isTrackingAttendance={meeting?.attendanceTracking || false}
          meetingLink={meeting?.link || ''}
          meetingType={selectedMeetingService}
        />
      ) : eventId != null && meeting?.link && hasSavedMeeting ? (
        <EventVirtualMeetingMenu
          copyLink={copyMeetingLink}
          creatorId={creatorId}
          eventId={eventId}
          meeting={meeting}
          meetingType={selectedMeetingService}
          setValue={setValue}
          watch={watch}
        />
      ) : (
        <EventVirtualMeetingField
          control={control}
          disableFields={disableFields}
          register={register}
          watch={watch}
        />
      )}
    </Box>
  );
};

export default EventVirtualLocationField;
