import React, { useEffect, useRef, useState } from 'react';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import CollectionEventFilters from 'components/Collections/CollectionEventFilters';
import CollectionEventsListItem from 'components/Collections/CollectionEventsListItem';
import { useFetchEvents } from 'hooks/useEvents';
import { useInfiniteScrollQuery } from 'hooks/useInfiniteScrollQuery';
import { Event } from 'types/CollectionForm';

interface CollectionEventsListProps {
  initialSelectedEvents: Event[];
  onSaveSelection: (selectedEvents: Event[]) => void;
}

interface EventsResponse {
  currentPage: number;
  events: Event[];
  totalCount: number;
  totalPages: number;
}

const CollectionEventsList = ({
  initialSelectedEvents,
  onSaveSelection,
}: CollectionEventsListProps) => {
  const observerTarget = useRef<HTMLDivElement | null>(null);

  const [selectedEvents, setSelectedEvents] = useState<Event[]>(
    initialSelectedEvents
  );
  const [searchValue, setSearchValue] = useState('');
  const [columnFilters, setColumnFilters] = useState<any[]>([]);

  const { data, hasNextPage, isError, isFetchingNextPage } =
    useInfiniteScrollQuery<EventsResponse>({
      key: ['events', columnFilters],
      observerTarget,
      useQuery: async ({ pageParam = 1 }) => {
        const response = await useFetchEvents({
          columnFilters: columnFilters?.length ? columnFilters : null,
          page: pageParam,
          perPage: 12,
          search: searchValue,
          status: 'upcoming',
        });

        const hasNextPage = response.currentPage < response.totalPages;

        return { ...response, hasNextPage };
      },
    });

  useEffect(() => {
    setSelectedEvents(initialSelectedEvents);
  }, [initialSelectedEvents]);

  const handleEventToggle = (event: Event) => {
    setSelectedEvents((prev) =>
      prev.some((e) => e.id === event.id)
        ? prev.filter((e) => e.id !== event.id)
        : [...prev, event]
    );
  };

  const handleFilterChange = (filterId: string, value: string) => {
    setColumnFilters((prevFilters) => {
      if (value === '') {
        return prevFilters.filter((filter) => filter.id !== filterId);
      }
      // Check if filter already exists
      const existingFilterIndex = prevFilters.findIndex(
        (filter) => filter.id === filterId
      );

      if (existingFilterIndex > -1) {
        // Update the existing filter
        const updatedFilters = [...prevFilters];
        updatedFilters[existingFilterIndex].value = value.split(',');
        return updatedFilters;
      } else {
        // Add a new filter
        return [...prevFilters, { id: filterId, value: [value] }];
      }
    });
  };

  const clearFilters = () => {
    setColumnFilters([]);
  };

  useEffect(() => {
    onSaveSelection(selectedEvents);
  }, [selectedEvents, onSaveSelection]);

  return isError ? (
    <Typography color='error'>Something went wrong.</Typography>
  ) : (
    <Box display='flex' flexDirection='column' height='60vh'>
      <Box>
        <Box
          alignContent='center'
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          mb={2}
        >
          <CollectionEventFilters
            clearFilters={clearFilters}
            onFilterChange={handleFilterChange}
            onSearchChange={setSearchValue}
            searchValue={searchValue}
          />
          <Chip
            color='primary'
            label={`${selectedEvents.length} of ${data?.pages[0]?.totalCount} selected`}
            variant='light'
          />
        </Box>
      </Box>
      <Box flexGrow={1} overflow='auto'>
        <Stack direction='column' gap={1}>
          {data?.pages.map((page, pageIndex) => (
            <React.Fragment key={`page-${pageIndex}`}>
              {page?.events.map((event, i) => (
                <React.Fragment key={i}>
                  <CollectionEventsListItem
                    event={event}
                    isSelected={selectedEvents.some((e) => e.id === event.id)}
                    key={event.id}
                    onToggleSelect={() => handleEventToggle(event)}
                    removable={false}
                    selectable={event.eventsCollectionId === null}
                  />
                  <div
                    ref={
                      data.pages.length - 1 === pageIndex &&
                      page.events.length - 2 === i &&
                      hasNextPage
                        ? observerTarget
                        : null
                    }
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          {isFetchingNextPage && hasNextPage && <CircularProgress />}
        </Stack>
      </Box>
    </Box>
  );
};

export default CollectionEventsList;
