import React from 'react';
import {
  Check,
  Gauge,
  Icon,
  IconProps,
  ListChecks,
  TextT,
} from '@phosphor-icons/react';

import { SurveyQuestionType } from 'types/SurveyQuestionType';
import { OtherBlue, OtherGreen, OtherPink, OtherPurple } from 'utils/colors';

export const SurveyQuestionTypes: SurveyQuestionType[] = [
  {
    colors: OtherPurple,
    label: 'NPS',
    placeholder: 'How likely are you to...?',
    TypeIcon: Gauge as Icon,
    value: 'nps',
  },
  {
    colors: OtherPink,
    label: 'Short answer',
    placeholder: 'Explain why...',
    TypeIcon: TextT as Icon,
    value: 'short',
  },
  {
    colors: OtherBlue,
    label: 'Single select',
    placeholder: 'Select the option that...',
    TypeIcon: Check as Icon,
    typeIconProps: {
      weight: 'fill',
    } as IconProps,
    value: 'single_select',
  },
  {
    colors: OtherGreen,
    label: 'Multiple select',
    placeholder: 'Check all options that...',
    TypeIcon: ListChecks as Icon,
    typeIconProps: {
      weight: 'fill',
    } as IconProps,
    value: 'multi_select',
  },
].map((type) => ({
  ...type,
  analyticsIcon: <type.TypeIcon {...(type.typeIconProps || {})} size={24} />,
  surveyIcon: (
    <type.TypeIcon
      {...(type.typeIconProps || {})}
      color={type.colors.main}
      size={20}
    />
  ),
}));

export const SurveyQuestionTypeMap = new Map(
  [...SurveyQuestionTypes].map((type) => [type.value, type])
);

export const NpsOptions = [
  {
    colorName: 'error',
    label: 'Very unlikely',
    value: 0,
  },
  {
    colorName: 'error',
    label: 'Unlikely',
    value: 4,
  },
  {
    colorName: 'warning',
    label: 'Neutral',
    value: 7,
  },
  {
    colorName: 'success',
    label: 'Likely',
    value: 9,
  },
  {
    colorName: 'success',
    label: 'Very likely',
    value: 10,
  },
];

export const NpsOptionValueLabelMap = new Map(
  NpsOptions.map((opt) => [opt.value, opt.label])
);
