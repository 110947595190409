import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const OktaSamlSettings = ({ samlURL, setSamlURL }) => {
  return (
    (<TextField
      fullWidth
      helperText="Example:
        'https://dev.okta.com/app/ex749acs/sso/saml/metadata'"
      id='okta-issuer'
      label='Okta SAML Metadata URL'
      onChange={(e) => setSamlURL(e.target.value)}
      placeholder='Add your Okta SAML Metadata URL'
      value={samlURL}
    />)
  );
};

OktaSamlSettings.propTypes = {
  samlURL: PropTypes.string,
  setSamlURL: PropTypes.func,
};

export default OktaSamlSettings;
