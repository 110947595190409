import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import { ConditionalLoader, FullscreenLoader } from 'components/Core/Loader';
import StandaloneSurveyHeader from 'components/Events/Controls/Shared/StandaloneSurveyHeader';
import SurveyQuestionList from 'components/Events/Controls/Shared/SurveyQuestionList';
import { useAllRequiredSubmitted } from 'components/Events/Hooks/Generic';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import StandaloneSurveyImage from 'components/Survey/StandaloneSurveyImage';
import { SurveyResponseContext } from 'components/Survey/SurveyResponseContext';
import { AuthContext } from 'contexts/AuthContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { hasStarted } from 'utils/event';
import { useContextWithFallback, ValidateEmail } from 'utils/utils';

const StandaloneSurvey = ({ displayResponders, inDialog, type }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const {
    actions: { handleRSVP, sendPostSurvey, setRsvpStatus },
    state: {
      event,
      inSurveyResponseContext,
      rsvpStatus,
      surveyJustSubmitted,
      surveys,
    },
  } = useContextWithFallback(ManagerContext, SurveyResponseContext);
  const { isMobile } = useContext(LayoutContext);

  const [hasValidEmail, setHasValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leftEmailField, setLeftEmailField] = useState(false);
  const [onGuestlist, setOnGuestList] = useState(true);

  const isPreEvent = useMemo(() => type === 'pre_event_survey', [type]);

  const allAnswersSubmitted = useAllRequiredSubmitted(
    _.get(surveys, `${type}.responses`, []),
    _.get(surveys, `${type}.survey_questions`)
  );

  const showRSVP = useMemo(
    () => inSurveyResponseContext && isPreEvent,
    [inSurveyResponseContext, isPreEvent]
  );

  const sendSurvey = (ev) => {
    ev.preventDefault();
    if (event?.published) {
      type === 'post_event_survey'
        ? sendPostSurvey && sendPostSurvey(event, 'post')
        : showRSVP && handleRSVP
        ? handleRSVP('rsvp', true, true)
        : handleRSVP && handleRSVP('rsvp');
    }
  };

  const survey = useMemo(() => _.get(surveys, type, null), [surveys, type]);

  const checkValidEmail = useCallback(
    (email) => {
      const isValid = ValidateEmail(email);
      if (isValid) {
        if (event.privacy === 'organization') {
          if (
            event?.email_extensions?.includes(email.toLowerCase().split('@')[1])
          ) {
            setHasValidEmail(true);
          } else {
            setHasValidEmail(false);
          }
        } else {
          setHasValidEmail(true);
        }
      } else {
        setHasValidEmail(false);
      }
    },
    [event]
  );

  const existingInvitee = useMemo(
    () =>
      event?.published
        ? event?.invitees?.find(
            (invitee) =>
              invitee?.email?.toLowerCase() ===
                survey?.responses?.email?.toLowerCase() ||
              invitee?.email?.toLowerCase() === user?.email?.toLowerCase()
          )
        : false,
    [event?.invitees, event?.published, survey?.responses?.email, user?.email]
  );

  const hasAnsweredSurvey = useMemo(
    () =>
      event?.published
        ? !!survey?.survey_responses?.find(
            (response) => response?.invitee_id === existingInvitee?.id
          )
        : false,
    [event?.published, existingInvitee?.id, survey?.survey_responses]
  );

  const eventAvailable = useMemo(
    () => !hasStarted(event) && !event?.rsvp_pause_active,
    [event]
  );

  const canSeePreSurvey = useMemo(
    () =>
      event?.published &&
      showRSVP &&
      !hasAnsweredSurvey &&
      eventAvailable &&
      onGuestlist &&
      (rsvpStatus === 'rsvp' ||
        rsvpStatus === 'guest_list' ||
        rsvpStatus === 'invited' ||
        !rsvpStatus),
    [
      event?.published,
      showRSVP,
      hasAnsweredSurvey,
      eventAvailable,
      onGuestlist,
      rsvpStatus,
    ]
  );

  useEffect(() => {
    const surveyEmailLength = survey?.responses?.email?.length || 0;

    if (event?.published) {
      if (!user && surveyEmailLength > 0) {
        checkValidEmail(survey?.responses?.email);
      } else if (user) {
        checkValidEmail(user?.email);
      }

      const condition =
        (!user && surveyEmailLength > 0 && leftEmailField) || user;
      if (condition) {
        if (existingInvitee && !rsvpStatus && setRsvpStatus) {
          setRsvpStatus(existingInvitee?.status);
          setOnGuestList(true);
          if (user) {
            setHasValidEmail(true);
          }
        } else if (event?.privacy === 'invite_only' && !existingInvitee) {
          setOnGuestList(false);
        } else if (!user) {
          checkValidEmail(survey?.responses?.email);
          setOnGuestList(hasValidEmail);
        }
      }
    }
    const loadingTimeout = setTimeout(() => setIsLoading(false), 1200);
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [
    checkValidEmail,
    existingInvitee,
    event,
    hasValidEmail,
    leftEmailField,
    rsvpStatus,
    setRsvpStatus,
    survey,
    user,
  ]);

  const isImageless = useMemo(
    () => inDialog || !event?.config?.cover_image_url,
    [inDialog, event?.config?.cover_image_url]
  );

  return (
    <ConditionalLoader
      conditions={[isLoading]}
      loaderComponent={<FullscreenLoader show />}
    >
      <Box height={isImageless ? 'auto' : '100vh'}>
        <Grid
          alignItems='center'
          container
          direction={isImageless ? 'column' : 'row'}
          justifyContent='stretch'
          maxHeight={isImageless ? null : { lg: '100vh' }}
          overflow={isImageless ? null : { lg: 'hidden' }}
        >
          {!inDialog && event?.config?.cover_image_url && (
            <StandaloneSurveyImage
              coverImageUrl={event?.config?.cover_image_url}
              event={event}
            />
          )}
          <Grid
            item
            lg={inDialog ? 12 : isImageless ? 8 : 6}
            maxHeight={isImageless ? null : { lg: '100vh' }}
            sx={isImageless ? null : { overflowY: 'auto' }}
            xs={12}
          >
            {isImageless && !inDialog && (
              <Typography mx={{ lg: 0, xs: 2 }} my={2} variant='h1'>
                {event?.name}
              </Typography>
            )}

            <Card
              data-testid='TODO:DATA-CARD-46405'
              elevation={0}
              sx={
                inDialog
                  ? null
                  : {
                      flex: '0 1 auto',
                      mb: [2, 0, 8],
                      ml: { lg: 0, xs: 2 },
                      mr: { lg: isImageless ? 0 : 2, xs: 2 },
                      mt: 2,
                    }
              }
              variant={inDialog ? 'elevated' : 'outlined'}
            >
              <form
                data-testid='survey-card-body-wrapper'
                onSubmit={sendSurvey}
              >
                {!showRSVP && !surveyJustSubmitted && (
                  <StandaloneSurveyHeader
                    displayResponders={displayResponders}
                    hasDescription={survey?.description?.length > 0}
                    inDialog={inDialog}
                    isPreEvent={isPreEvent}
                  />
                )}
                <CardContent sx={inDialog ? { px: 0 } : {}}>
                  <SurveyQuestionList
                    canSeePreSurvey={canSeePreSurvey}
                    eventAvailable={eventAvailable}
                    hasAnsweredSurvey={hasAnsweredSurvey}
                    hasValidEmail={hasValidEmail}
                    inDialog={inDialog}
                    isPreEvent={isPreEvent}
                    onGuestList={onGuestlist}
                    setLeftEmailField={setLeftEmailField}
                    showRSVP={showRSVP}
                    survey={survey}
                    type={type}
                  />
                </CardContent>
                {((!showRSVP && onGuestlist && !surveyJustSubmitted) ||
                  canSeePreSurvey) && (
                  <CardActions sx={inDialog ? { px: 0 } : {}}>
                    <Button
                      color='secondary'
                      data-testid='TODO:DATA-BUTTON-89735'
                      disabled={!allAnswersSubmitted}
                      fullWidth={isMobile}
                      sx={{ mb: [10, 0] }}
                      type='submit'
                      variant='contained'
                    >
                      {isPreEvent ? 'Submit survey' : `Send feedback`}
                    </Button>
                  </CardActions>
                )}
              </form>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </ConditionalLoader>
  );
};

StandaloneSurvey.propTypes = {
  displayResponders: PropTypes.bool,
  inDialog: PropTypes.bool,
  type: PropTypes.string,
};

export default StandaloneSurvey;
