import React, { useContext, useMemo, useState } from 'react';
import {
  Button,
  Chip,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { MinusCircle } from '@phosphor-icons/react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import { ConditionalLoader } from 'components/Core/Loader';
import Search from 'components/Core/Search';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { searchFilter } from 'utils/calendar';

const SharedCalendarSidebar = ({ provider, setShow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [org, setOrg] = useContext(OrganizationContext);
  const [searchVal, setSearchVal] = useState('');
  const [toDelete, setToDelete] = useState(null);
  const [showingDelete, setShowDelete] = useState(false);

  const filteredCalendars = useMemo(
    () => _.filter(org?.calendars, (cal) => searchFilter(searchVal, cal)),
    [org, searchVal]
  );

  const startDelete = (cal) => {
    setToDelete(cal);
    setShowDelete(true);
  };

  const doDelete = (cleanupFunc) => {
    if (toDelete && !isLoading) {
      setIsLoading(true);
      axios(
        {
          method: 'delete',
          url: `/api/shared_calendars/${toDelete.id}`,
        },
        (res) => {
          setOrg(res.data);
          setShowDelete(false);
          cleanupFunc();
          setIsLoading(false);
          enqueueSnackbar('Shared calendar was removed.', {
            variant: 'success',
          });
          setToDelete(null);
        },
        () => {
          cleanupFunc();
          setIsLoading(false);
          enqueueSnackbar(
            'Something went wrong removing the calendar, please try again.',
            { variant: 'error' }
          );
          setToDelete(null);
        }
      );
    }
  };

  return (
    <>
      <DialogTitle>
        Manage your selected {provider} Calendars{' '}
        <Chip label={org?.calendars?.length ?? 0} size='small' />
      </DialogTitle>
      <DialogContent>
        <Search
          className='search'
          label='Search shared calendar'
          onChange={(e) => setSearchVal(e.target.value)}
          placeholder='Search'
          value={searchVal}
        />
        <ConditionalLoader conditions={[isLoading]}>
          <List data-testid='TODO:DATA-LIST-52362' disableGutters>
            {_.map(filteredCalendars, (cal) => (
              <ListItem key={cal.id}>
                <ListItemText>{cal.name}</ListItemText>
                <ListItemSecondaryAction>
                  <Button
                    data-testid='remove'
                    onClick={() => startDelete(cal)}
                    startIcon={<MinusCircle />}
                    variant='text'
                  >
                    Remove
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {filteredCalendars?.length === 0 && <span>No calendars to show</span>}
        </ConditionalLoader>
      </DialogContent>
      <DeleteModal
        noun='calendar'
        onCancel={() => setShowDelete(false)}
        onDelete={doDelete}
        owner={org?.display_name}
        show={showingDelete}
        subject={toDelete?.name}
      />
    </>
  );
};

SharedCalendarSidebar.propTypes = {
  provider: PropTypes.any,
  setShow: PropTypes.func,
};
export default SharedCalendarSidebar;
