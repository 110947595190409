import React from 'react';
import { Stack, Typography } from '@mui/material';
import { GlobeSimple } from '@phosphor-icons/react';
import dayjs from 'dayjs';

const TimezoneLabel = () => {
  const timezone = dayjs.tz.guess();

  const offset = dayjs().tz(timezone).format('Z');
  const timezoneName = dayjs().tz(timezone).offsetName('long');
  const city = timezone.split('/').pop()?.replace(/_/g, ' ');

  return (
    <Stack alignItems='center' direction='row' gap={0.5}>
      <GlobeSimple size={16} />
      <Typography variant='overline'>{`${timezoneName} – ${city}`}</Typography>
      <Typography color='grey.600' variant='overline'>
        {`(GMT${offset})`}
      </Typography>
    </Stack>
  );
};

export default TimezoneLabel;
