import React, { useMemo } from 'react';
import { IconButton } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Bell, BellSimple } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import useUserInvitations from 'hooks/useUserInvitations';
import { searchInvite } from 'utils/settingsSearch';

const UsersInvitedTable = ({ organization, searchTerm }) => {
  const {
    create,
    retrieve: { data: openInvites },
  } = useUserInvitations();

  const inviteInfo = useMemo(
    () =>
      _.chain(openInvites)
        .filter((invite) => searchInvite(invite, searchTerm))
        .groupBy('invited_user_email')
        .map((group) => {
          const invite = _.chain(group).sortBy('created_at').last().value();
          return {
            date: invite.created_at,
            email: invite.invited_user_email,
            id: invite.id,
            name: invite.invited_user_name,
            role: invite.role_proposed,
          };
        })
        .value(),
    [openInvites, searchTerm]
  );

  const Columns = [
    {
      field: 'name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'email',
      flex: 2,
      minWidth: 150,
    },
    {
      field: 'date',
      flex: 1,
      headerName: 'Invited on',
      minWidth: 120,
      type: 'date',
      valueFormatter: ({ value }) => value && dayjs(value).format('L'),
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'role',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Bell size={24} />}
          key={`${params.id}-invite-button`}
          label='Resend invite'
          onClick={() => create(params.row, organization, true)}
        />,
      ],
      headerName: 'Resend invite',
      minWidth: 120,
      type: 'actions',
    },
  ];

  return (
    <FtnDataGrid
      columns={Columns}
      data-testid='invited-users-table'
      noun='user'
      rows={inviteInfo}
    />
  );
};

UsersInvitedTable.propTypes = {
  organization: PropTypes.any,
  searchTerm: PropTypes.string,
};

export default UsersInvitedTable;
