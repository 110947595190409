import { useMemo } from 'react';

import { CollectionFormData } from 'types/CollectionForm';

const useCollectionFormDefaultValues = (user: any) =>
  useMemo<CollectionFormData>(
    () => ({
      collection_image: undefined,
      description: '',
      events: [],
      name: '',
      organizer_employee_ids: [user?.employee_id],
    }),
    [user?.employee_id]
  );

export default useCollectionFormDefaultValues;
