import React, { useContext, useMemo } from 'react';

import Search from 'components/Core/Search';
import Filters from 'components/shared/Filters';
import { GroupsContext } from 'contexts/GroupsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useGetEmployeesForOptions from 'hooks/useGetEmployeesForOptions';
import { Employee } from 'types/Employee';
import { Group } from 'types/Group';
import { fullName } from 'utils/employee';

interface CollectionEventFiltersProps {
  clearFilters: () => void;
  onFilterChange: (filterId: string, value: any) => void;
  onSearchChange: (value: string) => void;
  searchValue: string;
}

const CollectionEventFilters = ({
  clearFilters,
  onFilterChange,
  onSearchChange,
  searchValue,
}: CollectionEventFiltersProps) => {
  const [groups] = useContext(GroupsContext);
  const [organization, , , , , eventTypeColors] =
    useContext(OrganizationContext);

  const {
    getEventHosts: { data: event_host_employees },
  } = useGetEmployeesForOptions();

  const groupOpts = useMemo(
    () => groups?.map((g: Group) => ({ id: g.id, name: g.name })),
    [groups]
  );

  const officeOpts = useMemo(
    () => organization?.offices,
    [organization?.offices]
  );

  const typeOpts = useMemo(
    () =>
      organization?.event_types?.map((type: any) => ({
        ...type,
        color: eventTypeColors !== null ? eventTypeColors[type?.id] : null,
      })),
    [eventTypeColors, organization?.event_types]
  );

  const hostOpts = useMemo(
    () =>
      event_host_employees
        ?.map((o: Employee) => ({
          id: o?.id,
          name: fullName(o),
          secondary: o?.email,
        }))
        ?.sort((a: Employee, b: Employee) => a?.name?.localeCompare(b?.name)),
    [event_host_employees]
  );

  return (
    <Filters
      filters={[
        {
          handleChange: (id) => onFilterChange('office', String(id)),
          label: 'Office',
          options: officeOpts || [],
        },
        {
          handleChange: (id) => onFilterChange('eventType', String(id)),
          label: 'Type',
          options: typeOpts || [],
        },
        {
          handleChange: (id) => onFilterChange('group', String(id)),
          label: 'Group',
          options: groupOpts || [],
        },
        {
          handleChange: (id) => onFilterChange('host', String(id)),
          label: 'Organizer',
          options: hostOpts || [],
        },
      ]}
      handleClearAll={clearFilters}
    >
      <Search
        label='Search events'
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder='Search events'
        value={searchValue}
      />
    </Filters>
  );
};

export default CollectionEventFilters;
