import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { reactQueryAxios } from 'utils/axios';

const defaultSuccessMessage = 'Organization updated!';

export const useOrganization = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);

  const queryKey = ['organization'];
  const url = `/api/organizations/current`;

  const getOrganization = async ({ apiClient }: { apiClient: any }) =>
    apiClient({
      method: 'get',
      url,
    });

  const retrieve = useQuery({
    queryFn: () =>
      getOrganization({ apiClient: reactQueryAxios }).then(
        (response) => response.data
      ),
    queryKey,
  });

  const update = useMutation({
    mutationFn: async (data: any) => {
      setSuccessMessage(data.successMessage || defaultSuccessMessage);
      delete data['successMessage'];
      await queryClient.cancelQueries({
        queryKey,
      });
      return reactQueryAxios({
        data: { organization: data },
        method: 'patch',
        url,
      });
    },

    onSuccess: (response) => {
      queryClient.setQueryData(queryKey, response.data);
      enqueueSnackbar(successMessage, { variant: 'success' });
    },
  });

  return { retrieve, update };
};
