import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import FtnEvent from 'types/FtnEvent';
import { reactQueryAxios } from 'utils/axios';

const apiDelete = async ({
  apiClient,
  commId,
}: {
  apiClient: any;
  commId: number;
}) =>
  apiClient({
    method: 'delete',
    url: `/api/communication_histories/${commId}`,
  });

export const useCommunicationHistoryDelete = ({
  dataHandler = () => undefined,
}: {
  dataHandler?: (e: FtnEvent) => unknown;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<number, Error, number>({
    mutationFn: (commId) => apiDelete({ apiClient: reactQueryAxios, commId }),
    onError: (error: Error) => {
      console.error(error);
      enqueueSnackbar(
        'An error occured while deleting your communication, please try again later',
        { variant: 'error' }
      );
      Sentry.captureException(error);
    },
    onSuccess: (ftnEvent: any) => {
      // TODO: If we do get an event back we can use setData
      queryClient.invalidateQueries({ queryKey: ['event', ftnEvent.id] });
      dataHandler(ftnEvent);
    },
  });
};
