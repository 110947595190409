import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  AppBar,
  Button,
  DialogTitle,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';

import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';

export const DialogFullscreenTitle = ({
  buttonProps,
  children,
  onClose,
  onSave,
  title,
}: {
  buttonProps?: LoadingButtonProps;
  children?: React.ReactElement;
  onClose: () => void;
  onSave: () => void;
  title: string;
}) => (
  <DialogTitle component='div' sx={{ p: 0 }}>
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar
        disableGutters
        sx={{
          bgcolor: 'white',
          borderBottomColor: 'grey.300',
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          minHeight: `83px !important`,
        }}
      >
        <ContentWidthContainer>
          <Stack alignItems='center' direction='row' gap={2}>
            <Typography color='text.primary' flex={1} variant='h4'>
              {title}
            </Typography>
            {children}
            <Button
              data-testid='dialog-cancel'
              onClick={onClose}
              variant='text'
            >
              Cancel
            </Button>
            <LoadingButton
              {...buttonProps}
              data-testid='dialog-save'
              onClick={onSave}
              variant='contained'
            >
              Save
            </LoadingButton>
          </Stack>
        </ContentWidthContainer>
      </Toolbar>
    </AppBar>
  </DialogTitle>
);

export default DialogFullscreenTitle;
