import React, { useCallback, useContext } from 'react';
import { CardContent, CircularProgress, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PropTypes from 'prop-types';

import ImageInput from 'components/Core/ImageInput';
import { ConditionalLoader } from 'components/Core/Loader';
import GroupAdmins from 'components/Groups/GroupAdmins';
import GroupPrivacy from 'components/Groups/GroupPrivacy';
import GroupType from 'components/Groups/GroupType';
import DescriptionButton from 'components/shared/DescriptionButton';
import OfficesAutocomplete from 'components/shared/OfficesAutocomplete';
import { GroupContext } from 'contexts/GroupContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useGetEmployeesForOptions from 'hooks/useGetEmployeesForOptions';

const GroupForm = ({ cover_image, onDrop, saveDescription }) => {
  const { dispatch, state: group } = useContext(GroupContext);
  const [org, , isLoading] = useContext(OrganizationContext);
  const {
    getGroupAdmins: { data: group_admin_employees, isPending: isEmpLoading },
  } = useGetEmployeesForOptions();

  const setField = useCallback(
    (key, value) => {
      dispatch({ field: key, type: 'SET_FIELD', value });
    },
    [dispatch]
  );

  return (
    <ConditionalLoader conditions={[isEmpLoading, isLoading]}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid md={6} xs={12}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment:
                  group.isGroupPopulating === true ? (
                    <CircularProgress size={16} />
                  ) : undefined,
              }}
              label='Group name'
              onChange={(e) => setField('name', e.target.value)}
              placeholder='Book club'
              required
              value={group?.name}
            />
            <TextField
              fullWidth
              InputProps={{
                startAdornment:
                  group.isGroupPopulating === true ? (
                    <CircularProgress size={16} />
                  ) : undefined,
              }}
              label='External link'
              onChange={(e) => setField('external_link', e.target.value)}
              placeholder='https://goodreads.com'
              type='url'
              value={group?.external_link}
            />
            <DescriptionButton
              data-testid='message-wrapper'
              handleChange={(v) => {
                setField('description', v);
                if (group?.id && saveDescription) {
                  saveDescription(v);
                }
              }}
              showPreview
              showTypeInLabel
              truncateLong
              type='group'
              value={group?.description || ''}
            />
            <ImageInput
              data-testid='group-cover-image'
              image={cover_image}
              imageKey={group?.cover_image_key}
              label='Cover image'
              onDrop={onDrop}
              previewMaxHeight={250}
              previewMaxWidth={250}
            />
          </Grid>
          <Grid md={6} xs={12}>
            <GroupType organization={org} setField={setField} />
            <GroupPrivacy setField={setField} />
            <OfficesAutocomplete
              onChange={(e, newValues) =>
                setField(
                  'office_ids',
                  newValues?.map((v) => v.value)
                )
              }
              value={group?.office_ids || []}
            />
            <GroupAdmins
              group_admin_employees={group_admin_employees}
              setField={setField}
            />
          </Grid>
        </Grid>
      </CardContent>
    </ConditionalLoader>
  );
};

GroupForm.propTypes = {
  cover_image: PropTypes.any,
  onDrop: PropTypes.func,
  saveDescription: PropTypes.func,
};

export default GroupForm;
