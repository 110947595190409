import React, { useCallback } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { TimePickerProps } from '@mui/lab';
import { DatePicker, DatePickerProps, TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

interface DateTimePickersProps {
  control?: any;
  dateProps: DatePickerProps<Dayjs>;
  disableFields?: boolean;
  getValues?: any;
  name: string;
  offsetDependent?: {
    name: string;
    validateImmediately?: boolean;
  };
  required?: boolean;
  setValue?: any;
  timeProps: TimePickerProps<Dayjs>;
}

const DateTimePickers = ({
  control,
  dateProps,
  getValues,
  name,
  offsetDependent,
  setValue,
  timeProps,
}: DateTimePickersProps) => {
  const handleChange = useCallback(
    (date: Dayjs | null, field: ControllerRenderProps) => {
      let newDependentValue = null;
      if (offsetDependent && date) {
        const dependentValue = getValues(offsetDependent.name);
        const offset = dayjs(dependentValue).diff(dayjs(field.value));
        newDependentValue = dayjs(date).add(offset);
      }
      field.onChange(date);
      if (newDependentValue && offsetDependent) {
        setValue(offsetDependent.name, newDependentValue, {
          shouldValidate: offsetDependent.validateImmediately,
        });
      }
    },
    [getValues, offsetDependent, setValue]
  );
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <DatePicker
            {...dateProps}
            inputRef={field.ref}
            onChange={(date) => handleChange(date, field)}
            timezone='system'
            value={field.value}
          />
          <TimePicker
            {...timeProps}
            inputRef={field.ref}
            onChange={(date) => {
              handleChange(date, field);
            }}
            timezone='system'
            value={field.value}
          />
        </>
      )}
      rules={{
        required: true,
        validate: {
          dateAndTimeInFuture: (v) => {
            if (dayjs(v).isBefore(dayjs())) {
              return 'Time must be in the future';
            }
          },
          dateInFutureOrToday: (v) => {
            if (dayjs(v).isBefore(dayjs(), 'day')) {
              return 'Date must be in the future';
            }
          },
          startBeforeEnd: (_, values) => {
            if (!dayjs(values.start).isBefore(dayjs(values.ends))) {
              return 'Event end time must be after start time';
            }
          },
          validDayJs: (v) => dayjs(v).isValid(),
        },
      }}
    />
  );
};

export default DateTimePickers;
