import React from 'react';
import { capitalize } from '@mui/material';
import { IdentificationCard, ShieldStar } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import * as _ from 'lodash';

import { isOver } from 'utils/event';

export const DefaultSurveys = {
  post_event_survey: {
    description: '',
    display_responders: false,
    enabled: false,
    event_id: '',
    style: 'post',
    survey_template_id: null,
  },
  pre_event_survey: {
    description: '',
    display_responders: false,
    enabled: false,
    event_id: '',
    style: 'pre',
    survey_template_id: null,
  },
};

export const DefaultEditorTemplate = {
  name: '',
  privacy: true,
  style: '',
};

export const DefaultSurveyTemplate = {
  post_event_survey: {
    editing: false,
  },
  pre_event_survey: {
    editing: false,
  },
};

export const PreEventSurveyOption = {
  label: 'Pre-event survey',
  value: 'pre_event_survey',
};

export const PostEventSurveyOption = {
  label: 'Post-event survey',
  value: 'post_event_survey',
};

export const SurveyTypes = [PreEventSurveyOption, PostEventSurveyOption].map(
  (type) => ({
    ...type,
    id: type.value.replace('_event_survey', ''),
    name: capitalize(type.label.toLowerCase()),
  })
);

export const convertTypeToReadable = (type) =>
  `${type?.split('_')[0]?.toLocaleLowerCase()}-event`;

export const oppositeType = (type) =>
  [PreEventSurveyOption.value, PostEventSurveyOption.value].find(
    (val) => val !== type
  );

export const DefaultSurveyType = (event) => {
  if (!_.isEqual(event, null)) {
    if (event?.pre_event_survey?.id && event?.post_event_survey?.id) {
      return isOver(event) ? PostEventSurveyOption : PreEventSurveyOption;
    } else {
      return event?.pre_event_survey?.id
        ? PreEventSurveyOption
        : PostEventSurveyOption;
    }
  }
};

export const AddSurvey = (type) => {
  const surveyToAdd = _.cloneDeep(DefaultSurveys[type]);
  surveyToAdd.enabled = true;
  surveyToAdd.editing = true;
  if (type === 'post_event_survey') {
    surveyToAdd.survey_questions = DefaultQuestion('post_survey', 0);
  }
  return surveyToAdd;
};

export const DefaultQuestion = (questionType, index) => {
  const timestamp = dayjs().unix();
  switch (questionType) {
    case 'nps':
      return {
        id: '',
        pending_id: `nps-${timestamp}`,
        position: index,
        question_type: 'nps',
        required: true,
        title: 'How likely are you to recommend this event to a colleague?',
      };
    case 'post_survey':
      return [
        {
          id: '',
          pending_id: `nps-${timestamp}`,
          position: index,
          question_type: 'nps',
          required: true,
          title: 'How likely are you to recommend this event to a colleague?',
        },
        {
          id: '',
          pending_id: `nps-short-${timestamp}`,
          position: index + 1,
          question_type: 'short',
          required: true,
          title: 'Any additional feedback you would like to share?',
        },
      ];
    case 'public_pre_survey':
      return [
        {
          autocomplete: 'given-name',
          id: 'first_name',
          noun: 'your first name',
          placeholder: 'Riley',
          position: -3,
          question_type: 'short',
          required: true,
          title: 'First name',
        },
        {
          autocomplete: 'family-name',
          id: 'last_name',
          noun: 'your last name',
          placeholder: 'Adams',
          position: -2,
          question_type: 'short',
          required: true,
          title: 'Last name',
        },
        {
          autocomplete: 'email',
          id: 'email',
          noun: 'your email',
          placeholder: 'riley.adams@mycompany.com',
          position: -1,
          question_type: 'short',
          required: true,
          title: 'Email address',
        },
      ];
    case 'standalone_pre_survey':
      return [
        {
          autocomplete: 'email',
          id: 'email',
          noun: 'your email',
          position: -1,
          question_type: 'short',
          required: true,
          title: 'Email address',
        },
      ];
    case 'public_post_survey':
      return [
        {
          id: 'email',
          position: -1,
          question_type: 'short',
          required: true,
          title: 'Email Address',
        },
      ];
    default:
      return {
        id: '',
        pending_id: `${questionType}-${timestamp}`,
        position: index,
        question_type: questionType,
        required: false,
        title: '',
      };
  }
};

export const DefaultOptions = (index, position, questionType) => {
  const timestamp = dayjs().unix();
  switch (questionType) {
    case 'nps':
      return [
        {
          id: '',
          pending_id: 'very_unlikely',
          position: position || 0,
          survey_question_id: index,
          text: 'Very unlikely',
        },
        {
          id: '',
          pending_id: 'unlikely',
          position: position ? position + 1 : 1,
          survey_question_id: index,
          text: 'Unlikely',
        },
        {
          id: '',
          pending_id: 'neutral',
          position: position ? position + 2 : 2,
          survey_question_id: index,
          text: 'Neutral',
        },
        {
          id: '',
          pending_id: 'likely',
          position: position ? position + 3 : 3,
          survey_question_id: index,
          text: 'Likely',
        },
        {
          id: '',
          pending_id: 'very_likely',
          position: position ? position + 4 : 4,
          survey_question_id: index,
          text: 'Very likely',
        },
      ];
    default:
      return [
        {
          id: '',
          pending_id: `${position || 0}-${timestamp}`,
          position: position || 0,
          survey_question_id: index,
          text: '',
        },
        {
          id: '',
          pending_id: `${position ? position + 1 : 1}-${timestamp}`,
          position: position ? position + 1 : 1,
          survey_question_id: index,
          text: '',
        },
      ];
  }
};

export const hasSurvey = (event, type) => {
  if (type) {
    return _.get(event, `${type}.id`, null) !== null;
  }
  return event?.post_event_survey?.id || event?.pre_event_survey?.id;
};

export const handleResponderDisplay = (opt) =>
  opt.responder?.name && opt.responder?.email
    ? `–  ${opt.responder?.name} (${opt.responder?.email})`
    : `–  ${opt.responder?.name || opt.responder?.email}`;

export const SurveysInitialState = {
  post_event_survey: {
    description: '',
    editing: false,
    enabled: false,
    event_id: '',
    style: 'post',
    survey_options: [],
    survey_options_responses: [],
    survey_questions: DefaultQuestion('post_survey', 0),
    survey_responses: [],
    survey_template_id: null,
  },
  pre_event_survey: {
    description: '',
    editing: false,
    enabled: false,
    event_id: '',
    style: 'pre',
    survey_options: [],
    survey_options_responses: [],
    survey_questions: [],
    survey_responses: [],
    survey_template_id: null,
  },
};

export const SurveyAnonymityOptions = [
  {
    colorName: 'info',
    displayRespondersValue: true,
    helperText: 'You will be able to see which guest submitted each response',
    Icon: (props) => <IdentificationCard {...props} />,
    id: 'display-responders',
    labelSuffix: 'guest names along with their responses',
    verb: 'capture',
    verbAsGerund: 'capturing',
  },
  {
    colorName: 'secondary',
    displayRespondersValue: false,
    helperText: 'Survey responses will be kept anonymous',
    Icon: (props) => <ShieldStar {...props} />,
    id: 'anonymous',
    labelSuffix: 'survey responses anonymously',
    lockedHelperText: 'You are not capturing guest names with survey responses',
    verb: 'collect',
  },
].map((option) => ({
  ...option,
  activeLabel: `${capitalize(option.verbAsGerund || option.verb + 'ing')} ${
    option.labelSuffix
  }`,
  switchLabel: `${capitalize(option.verb)} ${option.labelSuffix}`,
  value: option.displayRespondersValue,
}));
