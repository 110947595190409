import React from 'react';
import { TextField } from '@mui/material';

import { EventDetailsFormRegister } from 'types/EventDetailsForm';

interface EventVirtualMeetingLinkFieldProps {
  disableFields: boolean;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  register?: EventDetailsFormRegister;
  value?: string;
}

const EventVirtualMeetingLinkField = ({
  disableFields,
  onBlur,
  onChange,
  register,
  value,
}: EventVirtualMeetingLinkFieldProps) => {
  const inputProps = register
    ? { ...register('meeting.link', { required: true }) }
    : { onBlur, onChange, value };

  return (
    <TextField
      data-testid='custom-virtual-link'
      fullWidth
      {...inputProps}
      InputProps={{
        readOnly: disableFields,
        type: 'url',
      }}
      placeholder='Add a virtual meeting link'
      required
      sx={{
        '&.MuiFormControl-root.MuiTextField-root': { mt: 0.5 },
      }}
    />
  );
};

export default EventVirtualMeetingLinkField;
