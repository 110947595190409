import React, { useMemo, useState } from 'react';
import { Autocomplete, AvatarGroup, TextField, useTheme } from '@mui/material';
import { CaretDown, CaretUpDown } from '@phosphor-icons/react';

import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import AutocompleteGroup from 'components/shared/AutocompleteGroup';
import AutocompletePlaceholder from 'components/shared/AutocompletePlaceholder';
import EmployeeAvatar from 'components/shared/EmployeeAvatar';
import { toHtmlId } from 'utils/text';

interface Props {
  disabled?: boolean;
  hasGroups?: boolean;
  hasItemCountAsValue?: boolean;
  hasLabel?: boolean;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  label?: string;
  onChange: (event: any, value: any) => unknown;
  options: any[];
  placeholder?: string;
  value: any;
}

const AutocompleteWithAvatars = ({
  disabled,
  hasGroups,
  hasItemCountAsValue,
  hasLabel,
  isOptionEqualToValue,
  label,
  onChange,
  options,
  placeholder = 'None added',
  value,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const clearSelected = (type: any = null) => {
    const newValue = type ? value?.filter((v: any) => v.type !== type) : [];
    onChange(null, newValue);
  };

  const hasValue = useMemo(
    () => value && value !== '' && (value?.length > 0 || value?.id),
    [value]
  );

  return (
    <Autocomplete
      defaultValue={value}
      disableClearable
      disableCloseOnSelect
      disabled={disabled}
      groupBy={hasGroups ? (opt) => opt?.type || null : undefined}
      id={`select-${toHtmlId(label)}`}
      isOptionEqualToValue={isOptionEqualToValue || undefined}
      ListboxProps={{ sx: { p: 0 } }}
      multiple
      onChange={onChange}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      openOnFocus
      options={options}
      popupIcon={disabled ? null : <CaretDown />}
      renderGroup={(params) => (
        <AutocompleteGroup
          clearSelected={clearSelected}
          params={params}
          selectionCount={
            value?.filter((v: any) => v?.type === params?.group)?.length
          }
        />
      )}
      renderInput={(params) => {
        params.InputProps = {
          ...params.InputProps,
          endAdornment: disabled ? null : (
            <CaretUpDown
              color={theme.palette.grey[700]}
              size={20}
              style={{
                cursor: 'pointer',
                marginLeft: hasValue ? 4 : -2,
                marginRight: 4,
              }}
            />
          ),
        };
        return (
          <TextField
            {...params}
            disabled={disabled}
            label={hasLabel ? label : null}
            placeholder={hasValue ? '' : placeholder}
            sx={{
              '&.Mui-focused': {
                fontStyle: 'normal',
              },
              m: 0,
            }}
            variant='filled'
          />
        );
      }}
      renderOption={(props, option, state) => (
        <AutocompleteCheckboxOption
          key={option?.id}
          option={option}
          props={props}
          state={state}
        />
      )}
      renderTags={(tags, _getTagProps, ownerState) => {
        if (hasItemCountAsValue) {
          return <AutocompletePlaceholder items={tags} key='item-count' />;
        } else {
          return (
            <AvatarGroup
              max={ownerState?.focused ? 3 : disabled ? 8 : 6}
              sx={{
                '& .MuiAvatar-root': {
                  fontSize: 12.8,
                  height: 32,
                  width: 32,
                },
              }}
            >
              {tags?.map((item) => {
                const key = `${item?.id || item?.value}-${
                  item?.name || item?.label
                }`;
                return (
                  <EmployeeAvatar
                    employeeId={item?.id}
                    fullName={item?.name || item?.label}
                    hasTooltip
                    key={key}
                    size={32}
                  />
                );
              })}
            </AvatarGroup>
          );
        }
      }}
      slotProps={{
        popper: {
          sx: {
            minWidth: 260,
          },
        },
      }}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'flex-end',
          overflow: 'auto',
        },
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          minWidth: 100,
          transition: 'all 300ms ease-in-out',
        },
        '& .MuiAutocomplete-root .MuiAutocomplete-tag': {
          maxWidth: 'unset',
        },
        '& .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline': {
          bgcolor: 'transparent',
        },
        '&:hover': {
          bgcolor: disabled ? 'transparent' : 'grey.200',
        },
        ...(!hasValue
          ? {
              pr: 0,
            }
          : {
              '&:not(.Mui-focused) .MuiAutocomplete-inputRoot .MuiAutocomplete-input':
                {
                  flexGrow: 0,
                  minWidth: 0,
                  px: 0,
                },
            }),
        '&.Mui-focused': {
          fontStyle: 'normal',
        },
        '.MuiInputBase-root.MuiFilledInput-root': {
          gap: 0,
          padding: 0,
        },
        '.MuiInputBase-root.MuiFilledInput-underline.MuiAutocomplete-inputRoot::before':
          {
            borderColor: 'transparent',
          },
        borderRadius: 1.5,
        fontStyle: value?.length > 0 ? 'normal' : 'italic',
        maxHeight: 44,
        paddingLeft: 1,
        ...(disabled &&
          !value?.length && {
            '.MuiInputBase-input': {
              mr: -1,
              opacity: 1,
              WebkitTextFillColor: 'grey.900',
            },
          }),
      }}
      value={value}
    />
  );
};

export default AutocompleteWithAvatars;
