import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useSnackbar } from 'notistack';

import {
  MessageFormData,
  MessageMethodId,
} from 'components/Events/Controls/Communications/communication.types';
import FtnEvent from 'types/FtnEvent';
import { reactQueryAxios } from 'utils/axios';

dayjs.extend(timezone);

export type EventMessageSaveParams = {
  commId: number | null;
  eventId: number;
  method: MessageMethodId;
};

const apiSave = async ({
  apiClient,
  commId,
  communicationData,
  eventId,
  method,
}: EventMessageSaveParams & {
  apiClient: any;
  communicationData: MessageFormData;
}) => {
  const baseUrl = `/api/event_messages/`;
  const urlSendNowOrSchedule = `${baseUrl}/create_and_send`;
  const updateUrl = `${baseUrl}/${commId}`;

  const data = {
    event_id: eventId,
    invitee_statuses: communicationData.invitee_statuses,
    is_scheduled: communicationData.is_scheduled,
    message_body: communicationData.message_body,
    message_type: method,
    scheduled_for: communicationData.scheduled_for,
    show_event_details_button: communicationData.show_event_details_button,
    show_rsvp_buttons: communicationData.show_rsvp_buttons,
    slack_channel_ids: communicationData.slack_channel_ids,
    ...(commId ? { event_message_id: commId } : {}),
  };

  return apiClient({
    data,
    method: commId ? 'put' : 'post',
    url: commId ? updateUrl : urlSendNowOrSchedule,
  });
};

export const useEventMessageSave = ({
  commId,
  dataHandler = () => undefined,
  eventId,
  method,
}: EventMessageSaveParams & {
  dataHandler?: (e: FtnEvent) => unknown;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<MessageFormData, Error, MessageFormData>({
    mutationFn: (communicationData) =>
      apiSave({
        apiClient: reactQueryAxios,
        commId,
        communicationData,
        eventId,
        method,
      }),

    onError: (error: Error) => {
      console.error(error);
      enqueueSnackbar('There was an error saving your message', {
        variant: 'error',
      });
    },
    onSuccess: (ftnEvent: any) => {
      // TODO: If we get an event back then we can use setData
      queryClient.invalidateQueries({ queryKey: ['event', ftnEvent.id] });
      dataHandler(ftnEvent);
    },
  });
};
