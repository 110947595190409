import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { axiosAuthenticated as axios } from 'utils/axios';

const ProfileCardTwoFactor = ({ setUser, user }) => {
  const [isTwoFactor, setTwoFactor] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user) {
      setTwoFactor(user.mfa_required);
    }
  }, [user]);

  const handleTwoFactorCheckbox = () => {
    axios(
      {
        data: { enable: !isTwoFactor },
        method: 'post',
        url: '/api/users/update_2fa',
      },
      (res) => {
        setUser(res.data);
        enqueueSnackbar(
          'Your two factor authentication settings have changed.',
          { variant: 'success' }
        );
      },
      () => {
        enqueueSnackbar(
          "Couldn't update two factor authentication setting right now, try again later.",
          { variant: 'error' }
        );
      }
    );
  };
  const name = !isTwoFactor ? 'Enable' : 'Disable';
  const nameSnakeCase = name.replace(/\s/g, '-');

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isTwoFactor}
          data-testid='TODO:DATA-CHECKBOX-60459'
          onChange={handleTwoFactorCheckbox}
          sx={{ ml: 0.5 }}
        />
      }
      id={nameSnakeCase}
      label={
        <Typography variant='body'>{name} Two-Factor authentication</Typography>
      }
    />
  );
};

ProfileCardTwoFactor.propTypes = {
  setUser: PropTypes.func,
  user: PropTypes.shape({
    mfa_required: PropTypes.any,
  }),
};
export default ProfileCardTwoFactor;
