import { useEffect } from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';

const ICSDownloader = ({ id, name }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (name) {
      axios
        .get(`${import.meta.env.VITE_BE_URL}/api/events/${id}/ical`)
        .then((res) => {
          fileDownload(res.data, `${name}.ics`);
        })
        .catch(() => {
          enqueueSnackbar(
            'You are trying to view an event that is not part of your organization',
            { variant: 'error' }
          );
          window.location.replace('/login');
        });
    }
  });

  return null;
};

export default ICSDownloader;
