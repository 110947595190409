import React from 'react';
import { Controller } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { EventDetailsFormControl } from 'types/EventDetailsForm';

interface EventLocationTypeButtonProps {
  clearEventLocation: () => void;
  clearEventMeeting: () => void;
  control: EventDetailsFormControl;
  defaultValue?: string;
  disableFields?: boolean;
}

const locationTypes = ['Virtual', 'Hybrid', 'In-person'];

const EventLocationTypeButtons = ({
  clearEventLocation,
  clearEventMeeting,
  control,
  defaultValue,
  disableFields,
}: EventLocationTypeButtonProps) => (
    <Controller
      control={control}
      name='locationType'
      render={({ field }) => (
          <ToggleButtonGroup
            color='neutral'
            defaultValue={defaultValue}
            disabled={disableFields}
            exclusive
            fullWidth
            onChange={(e, newType) => {
              const newValue = newType || '';
              field.onChange(newValue);

              if (newValue === '') {
                clearEventLocation();
                clearEventMeeting();
              }
            }}
            sx={{ mb: 0 }}
            value={field?.value || ''}
            // variant='outlined'
          >
            {locationTypes.map((locationType) => {
              const id = locationType.toLowerCase().replace('-', '');
              return (
                <ToggleButton
                  data-testid={`location-type-${locationType}`}
                  disabled={disableFields}
                  key={id}
                  value={id}
                >
                  {locationType}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        )}
    />
  );

export default EventLocationTypeButtons;
