import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { useContextWithFallback } from 'utils/utils';

const ShortAnswer = ({
  error = false,
  noOrder = false,
  noun,
  onSupplyResponse,
  question,
  questionIndex,
  questionPosition,
  readonly,
  setLeftEmailField,
  value,
}) => {
  const {
    state: { showErrors },
  } = useContextWithFallback(ManagerContext, SurveyTemplatesContext);

  return (
    <TextField
      autoComplete={question?.autocomplete || null}
      data-testid='short-answer-textfield'
      error={showErrors ? error?.length > 0 : false}
      fullWidth
      helperText={showErrors && error?.length > 0 ? error : null}
      id={question?.title}
      InputProps={{
        readOnly: readonly,
      }}
      label={
        question?.title?.length > 0
          ? [noOrder ? null : `${questionPosition}.`, question.title].join(' ')
          : `Question`
      }
      onBlur={() =>
        question?.title?.toLowerCase().indexOf('email') !== -1 &&
        setLeftEmailField &&
        setLeftEmailField(true)
      }
      onChange={(e) => {
        onSupplyResponse(
          question?.id ? question?.id : -Math.abs(questionIndex),
          e.target.value
        );
        question?.title?.toLowerCase().indexOf('email') !== -1 &&
          setLeftEmailField &&
          setLeftEmailField(false);
      }}
      placeholder={
        question?.placeholder
          ? question?.placeholder
          : noun
          ? `Type ${noun} here`
          : 'Type here'
      }
      required={question?.required}
      type={
        question?.title?.toLowerCase().indexOf('email') === -1
          ? 'text'
          : 'email'
      }
      value={value || ''}
    />
  );
};

ShortAnswer.propTypes = {
  error: PropTypes.bool,
  noOrder: PropTypes.bool,
  noun: PropTypes.any,
  onSupplyResponse: PropTypes.func,
  question: PropTypes.shape({
    autocomplete: PropTypes.string,
    id: PropTypes.any,
    noun: PropTypes.string,
    placeholder: PropTypes.string,
    position: PropTypes.any,
    required: PropTypes.bool,
    title: PropTypes.string,
  }),
  questionIndex: PropTypes.number,
  questionPosition: PropTypes.number,
  readonly: PropTypes.any,
  setLeftEmailField: PropTypes.func,
  value: PropTypes.string,
};
export default ShortAnswer;
