import React, { forwardRef, ReactElement, Ref } from 'react';
import { useTheme } from '@mui/material';
import { IconBase, IconProps, IconWeight } from '@phosphor-icons/react';

interface ScheduleIconProps extends IconProps {
  isScheduled?: boolean;
}

const ScheduleIcon = forwardRef(
  (props: ScheduleIconProps, ref: Ref<SVGSVGElement> | undefined) => {
    const { color, isScheduled, ...otherProps }: ScheduleIconProps = props;
    const theme = useTheme();

    const iconPaths = (
      <>
        <path
          clipRule='evenodd'
          d='M14.375 3H16.25C16.5815 3 16.8995 3.1317 17.1339 3.36612C17.3683 3.60054 17.5 3.91848 17.5 4.25V9.14527C17.1066 8.92474 16.6882 8.74362 16.25 8.60727V8H3.75V16.75H8.02598C8.15739 17.1877 8.33333 17.6061 8.54855 18H3.75C3.41848 18 3.10054 17.8683 2.86612 17.6339C2.6317 17.3995 2.5 17.0815 2.5 16.75V4.25C2.5 3.91848 2.6317 3.60054 2.86612 3.36612C3.10054 3.1317 3.41848 3 3.75 3H5.625V2.375C5.625 2.20924 5.69085 2.05027 5.80806 1.93306C5.92527 1.81585 6.08424 1.75 6.25 1.75C6.41576 1.75 6.57473 1.81585 6.69194 1.93306C6.80915 2.05027 6.875 2.20924 6.875 2.375V3H13.125V2.375C13.125 2.20924 13.1908 2.05027 13.3081 1.93306C13.4253 1.81585 13.5842 1.75 13.75 1.75C13.9158 1.75 14.0747 1.81585 14.1919 1.93306C14.3092 2.05027 14.375 2.20924 14.375 2.375V3ZM5.625 4.875V4.25H3.75V6.75H16.25V4.25H14.375V4.875C14.375 5.04076 14.3092 5.19973 14.1919 5.31694C14.0747 5.43415 13.9158 5.5 13.75 5.5C13.5842 5.5 13.4253 5.43415 13.3081 5.31694C13.1908 5.19973 13.125 5.04076 13.125 4.875V4.25H6.875V4.875C6.875 5.04076 6.80915 5.19973 6.69194 5.31694C6.57473 5.43415 6.41576 5.5 6.25 5.5C6.08424 5.5 5.92527 5.43415 5.80806 5.31694C5.69085 5.19973 5.625 5.04076 5.625 4.875Z'
          fill={color || 'currentColor'}
          fillRule='evenodd'
        />

        {isScheduled ? (
          <path
            clipRule='evenodd'
            d='M18.8438 14.4219C18.8438 17.1402 16.6402 19.3438 13.9219 19.3438C11.2036 19.3438 9 17.1402 9 14.4219C9 11.7036 11.2036 9.5 13.9219 9.5C16.6402 9.5 18.8438 11.7036 18.8438 14.4219ZM16.6307 13.6623C16.893 13.4122 16.9027 12.9968 16.6526 12.7345C16.4024 12.4723 15.987 12.4625 15.7247 12.7127L13.1686 15.1512L12.1194 14.1486C11.8574 13.8982 11.442 13.9076 11.1916 14.1697C10.9412 14.4317 10.9506 14.8471 11.2126 15.0975L12.7148 16.533C12.9683 16.7753 13.3675 16.7755 13.6212 16.5334L16.6307 13.6623Z'
            fill={theme.palette.success.main}
            fillRule='evenodd'
          />
        ) : (
          <>
            <path
              d='M13.75 18.9375C16.3388 18.9375 18.4375 16.8388 18.4375 14.25C18.4375 11.6612 16.3388 9.5625 13.75 9.5625C11.1612 9.5625 9.0625 11.6612 9.0625 14.25C9.0625 16.8388 11.1612 18.9375 13.75 18.9375Z'
              fill='none'
              stroke={color || 'currentColor'}
              strokeMiterlimit='10'
              strokeWidth='1.25'
            />
            <path
              d='M13.75 11.5156V14.25H16.4844'
              fill='none'
              stroke={color || 'currentColor'}
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.25'
            />
          </>
        )}
      </>
    );

    const weights = new Map<IconWeight, ReactElement>([
      ['thin', iconPaths],
      ['light', iconPaths],
      ['regular', iconPaths],
      ['bold', iconPaths],
      ['fill', iconPaths],
      ['duotone', iconPaths],
    ]);

    return (
      <IconBase
        ref={ref}
        {...otherProps}
        viewBox='0 0 20 21'
        weights={weights}
      />
    );
  }
);

ScheduleIcon.displayName = 'ScheduleIcon';

export default ScheduleIcon;
