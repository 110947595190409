import React from 'react';
import {
  Button,
  ButtonProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Icon as IconType } from '@phosphor-icons/react';

export interface EmptyStateProps {
  button?: ButtonProps;
  buttonLabel?: string;
  Icon: IconType;
  iconColor?: string;
  maxContentWidth?: number;
  primary?: string;
  secondary?: string;
}

const EmptyState = ({
  button,
  buttonLabel,
  Icon: LargeIcon,
  iconColor,
  maxContentWidth,
  primary,
  secondary = '',
}: EmptyStateProps) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems='center'
      direction='column'
      height='100%'
      justifyContent='center'
      width='100%'
    >
      <Stack
        alignItems='center'
        direction='column'
        justifyContent='center'
        {...(maxContentWidth ? { maxWidth: maxContentWidth } : {})}
      >
        <LargeIcon
          color={iconColor || theme.palette.secondary.main}
          size={32}
        />
        <Typography mb={0.5} mt={1.5} textAlign='center' variant='body2'>
          {primary}
        </Typography>
        {secondary?.length > 0 ? (
          <Typography
            color='grey.700'
            mb={3}
            textAlign='center'
            variant='overline'
            {...(maxContentWidth ? { maxWidth: maxContentWidth - 100 } : {})}
          >
            {secondary}
          </Typography>
        ) : null}
        {button && (
          <Button {...button} color='primary' variant='contained'>
            {buttonLabel}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default EmptyState;
