import React, { useContext } from 'react';
import { Button, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { LayoutContext } from 'contexts/LayoutContext';

const Buttons = ({ justifyContent = 'flex-end', buttons, color, variant }) => {
  const { isMobile } = useContext(LayoutContext);

  return (
    (<Stack
      alignItems='center'
      direction='row'
      justifyContent={justifyContent}
      spacing={2}
    >
      {buttons.map(
        ({
          color: buttonColor,
          endIcon,
          isIconOnlyOnMobile = false,
          onClick,
          startIcon,
          text,
          variant: buttonVariant,
        }) => {
          const sharedProps = {
            color: buttonColor || color || 'primary',
            onClick,
          };
          const keyValue = text.replace(' ', '-').toLowerCase();
          if (isMobile && isIconOnlyOnMobile) {
            return (
              (<IconButton {...sharedProps} key={keyValue} data-testid="TODO:DATA-ICONBUTTON-77597">
                {startIcon || endIcon}
              </IconButton>)
            );
          } else {
            return (
              (<Button
                {...sharedProps}
                endIcon={endIcon}
                key={keyValue}
                startIcon={startIcon}
                variant={buttonVariant || variant || 'contained'}
                data-testid="TODO:DATA-BUTTON-89413">
                {text}
              </Button>)
            );
          }
        }
      )}
    </Stack>)
  );
};

Buttons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      endIcon: PropTypes.node,
      isIconOnlyOnMobile: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
      startIcon: PropTypes.node,
      text: PropTypes.string.isRequired,
      variant: PropTypes.string,
    })
  ),
  color: PropTypes.string,
  variant: PropTypes.string,
  justifyContent: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
};

export default Buttons;
