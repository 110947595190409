import React, { useMemo } from 'react';
import { Stack, useTheme } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';

import ChartWithLegend from 'components/shared/ChartWithLegend';
import CircularProgressWithLabel from 'components/shared/CircularProgressWithLabel';
import Currency from 'types/Currency';
import { Gradient } from 'typescript-color-gradient';

interface IEventBudgetCategoryTotal {
  id: string;
  label: string;
  percentage: number;
  value: number;
}

const EventBudgetSpendPerCategoryChart = ({
  currency,
  data,
}: {
  currency?: Currency;
  data: IEventBudgetCategoryTotal[];
}) => {
  const theme = useTheme();

  const total = useMemo(
    () =>
      data?.length > 0
        ? data?.map((d) => d.value).reduce((acc, val) => acc + val)
        : 1,
    [data]
  );

  const gradientArray = useMemo(
    () =>
      data?.length > 0
        ? new Gradient()
            .setGradient('#012c4e', '#307ebc', '#e0edf7')
            .setNumberOfColors(data?.length)
            .getColors()
        : [],
    [data?.length]
  );

  const dataWithColors = useMemo(
    () =>
      data?.length > 0
        ? data
            ?.filter((item) => item?.value > 0)
            ?.sort((a, b) =>
              a.label === 'Uncategorized'
                ? 1
                : b.label === 'Uncategorized'
                ? -1
                : a.label.localeCompare(b.label)
            )
            ?.map((d, index) => {
              const decimal = (d.value / total) * 100;
              return {
                ...d,
                color:
                  gradientArray?.length >= index - 1
                    ? gradientArray[index]
                    : theme.palette.primary.main,
                percent: `${decimal.toFixed(2)}%`,
              };
            })
        : [],
    [data, gradientArray, theme.palette.primary.main, total]
  );

  return (
    <>
      {dataWithColors?.length > 0 ? (
        <ChartWithLegend
          Chart={
            <ResponsivePie
              activeOuterRadiusOffset={8}
              animate
              borderWidth={0}
              colors={(d) => d?.data?.color}
              cornerRadius={3}
              data={dataWithColors}
              fit={false}
              innerRadius={0.5}
              layers={['arcs']}
              margin={{ bottom: 10, left: 10, right: 10, top: 10 }}
              padAngle={1}
              sortByValue
              theme={{
                text: {
                  fontFamily: `'Inter', sans-serif`,
                },
              }}
              valueFormat={(v: any) =>
                `${currency?.symbol}${Number(v).toLocaleString()} | ${(
                  (v / total) *
                  100
                ).toFixed(2)}%`
              }
            />
          }
          legendItems={dataWithColors}
        />
      ) : (
        <Stack
          alignItems='center'
          direction='column'
          height='100%'
          justifyContent='center'
        >
          <CircularProgressWithLabel
            isPlaceholder
            label='No data'
            size={180}
            thickness={10}
          />
        </Stack>
      )}
    </>
  );
};

export default EventBudgetSpendPerCategoryChart;
