/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';

import { useSaveGoogleAuth } from 'components/Events/Hooks/Actions';
import ProfileIntegrationCard from 'components/Profile/ProfileIntegrationCard';
import { integrationsByIdMap } from 'constants/integration.constants';
import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { useGoogleCalendarLogin } from 'utils/google';

const ProfileCardGoogleCalendar = () => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const [organization] = useContext(OrganizationContext);
  const { enqueueSnackbar } = useSnackbar();

  const saveGoogleAuth = useSaveGoogleAuth(
    () => {},
    () => {},
    () => {
      enqueueSnackbar(`Your Google account has been successfully integrated.`, {
        variant: 'success',
      });
    },
    (e) => {
      if (e?.response?.data?.errors === 'missing scopes') {
        enqueueSnackbar(
          'We were unable to connect to your calendar. Please try again and ensure calendar permissions are selected.',
          {
            variant: 'error',
          }
        );
      } else {
        enqueueSnackbar('Something went wrong logging in, please try again.', {
          variant: 'error',
        });
      }
    }
  );

  const startGoogleLogin = useGoogleCalendarLogin(
    saveGoogleAuth,
    undefined,
    organization
  );

  const hasGoogleIntegration = useMemo(
    () => user?.has_google_calendar_auth,
    [user]
  );

  return (
    <ProfileIntegrationCard
      isConnected={hasGoogleIntegration}
      onButtonClick={() => startGoogleLogin()}
      service={integrationsByIdMap.get('google_calendar')}
    />
  );
};
export default ProfileCardGoogleCalendar;
