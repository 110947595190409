import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FunnelSimple } from '@phosphor-icons/react';
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table';
import pluralize from 'pluralize';

import { CommunicationMethodId } from 'components/Events/Controls/Communications/communication.types';
import ContactListsAutocomplete from 'components/shared/ContactListsAutocomplete';
import GroupsAutocomplete from 'components/shared/GroupsAutocomplete';
import OfficesAutocomplete from 'components/shared/OfficesAutocomplete';
import TabLabelWithCount from 'components/shared/TabLabelWithCount';
import { ListsContext } from 'contexts/ListsContext';
import { useEventInvitationOptions } from 'hooks/useEventInvitationOptions';
import { EventInvitationGuestTypeId } from 'types/EventInvitationGuestType';
import { Office } from 'types/Organization';
import { flexColumn } from 'utils/styles';

interface Selection {
  employee_ids?: number[];
  label: string;
  value: number;
}

export type LogicalOperator = 'all' | 'any';

const InvitationSelectContactsFilter = ({
  columnFilters: appliedColumnFilters,
  globalFilter = '',
  logicalOperator: appliedLogicalOperator,
  pagination,
  setAutoSelect,
  setColumnFilters: applyColumnFilters,
  setLogicalOperator: applyLogicalOperator,
  sorting,
}: {
  columnFilters: MRT_ColumnFiltersState;
  globalFilter?: string;
  logicalOperator: LogicalOperator;
  pagination: MRT_PaginationState;
  setAutoSelect: (autoSelect: boolean) => void;
  setColumnFilters: any;
  setLogicalOperator: any;
  sorting: MRT_SortingState;
}) => {
  const { commId, id, method } = useParams<{
    commId?: string;
    id?: string;
    method?: CommunicationMethodId;
  }>();
  const eventId = Number(id);
  const invitationId = Number(commId) || 'new';

  const [listsFromContext] = useContext(ListsContext);
  const [logicalOperator, setLogicalOperator] = useState<LogicalOperator>(
    appliedLogicalOperator
  );
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    appliedColumnFilters || []
  );
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState<Selection[]>([]);
  const [lists, setLists] = useState<Selection[]>([]);
  const [offices, setOffices] = useState<Selection[]>([]);

  useEffect(() => {
    setColumnFilters(appliedColumnFilters);
  }, [appliedColumnFilters]);

  const {
    retrieve: { data },
  } = useEventInvitationOptions({
    columnFilters,
    eventId,
    globalFilter,
    invitationId,
    logicalOperator,
    method,
    optionType: 'contact' as EventInvitationGuestTypeId,
    pagination,
    sorting,
  });

  const clearFilters = useCallback(() => {
    setColumnFilters([]);
    setGroups([]);
    setLists([]);
    setOffices([]);
  }, [setColumnFilters, setGroups, setLists, setOffices]);

  const selected = useMemo(
    () => ({
      groups: groups?.map((group) => group.value) || [],
      lists: lists?.map((list) => list.value) || [],
      offices: offices?.map((office) => office.value) || [],
    }),
    [groups, lists, offices]
  );

  const handleClose = (clearSelections = false) => {
    if (clearSelections) {
      clearFilters();
    }
    setOpen(false);
  };

  const handleOnApply = (autoSelect = false) => {
    setAutoSelect(autoSelect);
    applyLogicalOperator(logicalOperator);
    applyColumnFilters(columnFilters || []);
    handleClose();
  };

  const logicalOperatorChip = useMemo(
    () => (
      <Chip
        color={logicalOperator === 'all' ? 'primary' : 'info'}
        label={logicalOperator === 'all' ? 'AND' : 'OR'}
        sx={{ alignSelf: 'center' }}
        variant='light'
      />
    ),
    [logicalOperator]
  );

  const handleFilterChange = (
    selections: Selection[],
    key: string,
    setter: any
  ) => {
    if (selections?.length > 0) {
      setter(selections);
      setColumnFilters([
        ...columnFilters.filter((colFilter) => colFilter.id !== key),
        {
          id: key,
          value: selections.map((s) => s.value).flat(),
        },
      ]);
    } else {
      setter([]);
      setColumnFilters([
        ...columnFilters.filter((colFilter) => colFilter.id !== key),
      ]);
    }
  };
  return (
    <>
      <Stack alignItems='center' direction='row' gap={1}>
        <Button
          id='filter-contacts-button'
          onClick={() => setOpen(true)}
          startIcon={<FunnelSimple size={20} />}
          sx={{ whiteSpace: 'nowrap' }}
          variant={appliedColumnFilters?.length > 0 ? 'contained' : 'bordered'}
          {...(appliedColumnFilters?.length > 0 ? { color: 'secondary' } : {})}
        >
          {appliedColumnFilters?.length > 0 ? (
            <TabLabelWithCount
              color='secondary'
              count={appliedColumnFilters?.length}
              label='Edit filters'
              variant='light'
            />
          ) : (
            'Filter contacts'
          )}
        </Button>
        {appliedColumnFilters?.length > 0 ? (
          <Button
            data-testid='clear-filters'
            onClick={() => {
              applyColumnFilters([]);
              clearFilters();
            }}
            size='small'
          >
            Clear filters
          </Button>
        ) : null}
      </Stack>
      <Dialog
        aria-label='Select guest filters'
        data-testid='select guest filters'
        fullWidth
        maxWidth='md'
        onClose={() => handleClose(true)}
        open={open}
      >
        <DialogTitle>Select filters</DialogTitle>
        <DialogContent sx={{ ...flexColumn, gap: 3 }}>
          <Stack alignItems='center' direction='row' gap={1}>
            <Typography>
              Contacts must match filter selections between
            </Typography>
            <Select
              onChange={(event) => {
                setLogicalOperator(event.target.value as LogicalOperator);
              }}
              size='small'
              value={logicalOperator}
            >
              <MenuItem value='all'>all</MenuItem>
              <MenuItem value='any'>any</MenuItem>
            </Select>
            <Typography>filter categories.</Typography>
          </Stack>
          <Stack direction='column' gap={1.5}>
            <OfficesAutocomplete
              fullWidth
              onChange={(_e: unknown, value: Selection[]) =>
                handleFilterChange(value, 'office_id', setOffices)
              }
              value={selected?.offices}
            />
            {logicalOperatorChip}
            <GroupsAutocomplete
              fullWidth
              onChange={(_e: unknown, value: Selection[]) =>
                handleFilterChange(value, 'groups', setGroups)
              }
              value={selected?.groups}
            />
            {listsFromContext &&
              listsFromContext?.length > 0 &&
              logicalOperatorChip}
            {listsFromContext && listsFromContext?.length > 0 && (
              <ContactListsAutocomplete
                fullWidth
                onChange={(_e: unknown, value: Selection[]) =>
                  handleFilterChange(value, 'contact_list', setLists)
                }
                value={selected?.lists}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Typography
            color='grey.700'
            flex={1}
            textAlign='left'
            variant='body2'
          >
            {pluralize(
              'matching contact',
              data?.meta?.total_count || undefined,
              true
            )}
          </Typography>
          <Button data-testid='cancel' onClick={() => handleClose(true)}>
            Cancel
          </Button>
          <Button
            color='primary'
            data-testid='filter'
            onClick={() => {
              handleOnApply(false);
            }}
            variant='contained'
          >
            Filter
          </Button>
          {/* TODO: Reimplement this functionality, if possible */}
          {/* <Button
            color='primary'
            data-testid='filter-and-select-contacts'
            onClick={() => {
              handleOnApply(true);
            }}
            variant='contained'
          >
            Filter and select contacts
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvitationSelectContactsFilter;
