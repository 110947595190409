import * as Sentry from '@sentry/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import sanitize from 'sanitize-filename';

import { reactQueryAxios } from 'utils/axios';

const exportGroupBudgetTracker = async ({
  apiClient,
  groupId,
}: {
  apiClient: any;
  groupId: number;
}) =>
  apiClient({
    method: 'get',
    responseType: 'blob',
    url: `/api/groups/${groupId}/budget/export`,
  });

export const useGroupBudgetTrackerExport = (
  groupId: number,
  groupName: string
) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: () =>
      exportGroupBudgetTracker({ apiClient: reactQueryAxios, groupId }),
    onError: (error: Error) => {
      enqueueSnackbar(
        'Something went wrong! Group budget export failed. Please try again',
        { variant: 'error' }
      );
      Sentry.captureException(error);
    },
    onSuccess: (data: { data: string }) => {
      fileDownload(
        data?.data,
        sanitize(`group_${groupName}_budget_tracker.csv`)
      );
      enqueueSnackbar('Group Budget Tracker has been exported to a CSV!', {
        variant: 'success',
      });
    },
    // queryKey: ['group', groupId, 'budgetTrackerExport'],
  });
};
