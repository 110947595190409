import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

import MicrosoftLogin from 'components/Onboard/MicrosoftLogin';
import OktaLoginButton from 'components/Onboard/OktaLoginButton';
import OnboardFrame from 'components/Onboard/OnboardFrame';
import PasswordTextField from 'components/shared/PasswordTextField';
import { AuthContext } from 'contexts/AuthContext';
import { PublicOrgContext } from 'contexts/PublicOrgContext';
import { GoogleSSOScopes } from 'utils/google';
import { useExternalSnackbarProps } from 'utils/snackbar';
import { ValidateEmail } from 'utils/utils';

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hasAgreedToTerms, setAgreedToTerms] = useState(false);
  const [org, isPublicOrgLoading] = useContext(PublicOrgContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (org?.force_okta_sign_in) {
      navigate('/login');
    }
  }, [navigate, org?.force_okta_sign_in]);

  const {
    onboard: { token: onboardToken },
    token: { setToken },
    userProvider: { setUser },
  } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();
  const snackbarProps = useExternalSnackbarProps();

  const showSnackbar = (text, variant) => {
    enqueueSnackbar(text, { ...snackbarProps, variant });
  };

  const validEmail = useMemo(() => ValidateEmail(username), [username]);

  const registerUser = (e) => {
    e.preventDefault();
    if (!validEmail) {
      showSnackbar('You must enter a valid email address', 'error');
    } else if (password !== confirmPassword) {
      showSnackbar(`Passwords don't match`, 'error');
    } else {
      setIsLoading(true);
      const user = {
        email: username,
        first_name: firstName,
        last_name: lastName,
        organization_id: org.id,
        password,
        password_confirmation: confirmPassword,
      };
      let url = `${import.meta.env.VITE_BE_URL}/api/users`;
      if (onboardToken) {
        user.onboard_code = onboardToken;
        url = `${import.meta.env.VITE_BE_URL}/api/admins`;
      }
      axios
        .post(url, {
          user,
        })
        .then((res) => {
          if (res?.data?.user?.is_admin) {
            setIsLoading(false);
            setUser(res.data.user);
            setToken(res.data.token);
            navigate('/settings');
          } else {
            showSnackbar(
              'Check your email! We sent you a link to verify your email',
              'success'
            );
            navigate('/login');
          }
        })
        .catch((err) => {
          const errors = err.response.data.errors;
          const statusCode = err.response.status;
          let message = '';
          if (_.has(errors, 'password_confirmation')) {
            message = "Password confirmation doesn't match password.";
          } else if (statusCode === 409) {
            message = 'This email address is already in use.';
          } else {
            message = 'Invalid Email or Password! Please try again.';
          }
          setIsLoading(false);
          showSnackbar(`${message}`, 'error');
          setPassword('');
          setConfirmPassword('');
        });
    }
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    include_granted_scopes: false,
    onFailure: (response) => {
      if (response.error !== 'idpiframe_initialization_failed') {
        showSnackbar('Something went wrong!', 'error');
      } else {
        showSnackbar(
          'Cookies must be enabled to sign up with Google. Please enable cookies or use a different sign up option.',
          'error'
        );
      }
    },
    onSuccess: (response) => {
      setIsLoading(true);
      const user = {
        code: response.code,
        organization_id: org.id,
        redirect_uri: window.location.origin,
      };
      let url = `${import.meta.env.VITE_BE_URL}/api/users`;
      if (onboardToken) {
        user.onboard_code = onboardToken;
        url = `${import.meta.env.VITE_BE_URL}/api/admins`;
      }

      axios
        .post(`${url}/google_oauth`, {
          user,
        })
        .then((res) => {
          if (setToken) {
            setUser(res.data.user);
            setToken(res.data.token);
            setIsLoading(false);
            navigate('/events');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
    prompt: 'select_account',
    scope: GoogleSSOScopes,
  });

  // if (org && org.force_okta_sign_in) {
  //   return <Redirect to='/login' />;
  // }

  const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <OnboardFrame
        conditions={[isPublicOrgLoading, isLoading]}
        data-testid='sign-up-container'
        subtitle='Please enter your details below to create an account.'
        title='Sign up'
      >
        <form
          data-testid='sign-up-form'
          onSubmit={registerUser}
          style={{ maxWidth: '100%' }}
        >
          <Grid columnSpacing={2} container direction='column' mb={1}>
            <Grid columnSpacing={2} container direction='row' item xs={12}>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete='given-name'
                  fullWidth
                  id='sign-up-first-name'
                  label='First name'
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder='Enter first name'
                  required
                  value={firstName}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete='family-name'
                  fullWidth
                  id='sign-up-last-name'
                  label='Last name'
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder='Enter last name'
                  required
                  value={lastName}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete='email'
                fullWidth
                id='sign-up-email'
                label='Your work email'
                onChange={(e) => setUsername(e.target.value)}
                placeholder='you@yourcompany.com'
                required
                type='email'
                value={username}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                autoComplete='new-password'
                handleChange={(e) => setPassword(e.target.value)}
                label='Password'
                placeholder='Enter password'
                showStrengthOnFocus
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                errors={[
                  {
                    hasError: password !== confirmPassword,
                    text: `The entered value doesn't match the Password field`,
                  },
                ]}
                handleChange={(e) => setConfirmPassword(e.target.value)}
                label='Confirm password'
                placeholder='Re-enter password'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid='TODO:DATA-CHECKBOX-45817'
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                    value={hasAgreedToTerms}
                  />
                }
                label={
                  <Typography variant='overline'>
                    I agree to the{' '}
                    <Link
                      component={RouterLink}
                      data-testid='terms-of-service'
                      rel='noreferrer'
                      target='_blank'
                      to='https://fivetonine.co/terms-of-use/'
                    >
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link
                      component={RouterLink}
                      data-testid='privacy-policy'
                      rel='noreferrer'
                      target='_blank'
                      to='https://fivetonine.co/privacy-policy/'
                    >
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                }
                sx={{
                  marginLeft: '-4px',
                  mb: 5,
                  mt: 3,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color='secondary'
                data-testid='create-account'
                disabled={!org?.id || !hasAgreedToTerms}
                fullWidth
                type='submit'
                variant='contained'
              >
                Create account
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container direction='column' justifyContent='center' spacing={1}>
          {org && (org.calendar_enabled !== 'none' || org.okta_enabled) && (
            <Grid item sx={{ mb: 2, mt: 1 }} xs>
              <Divider>
                <Typography color='text.secondary' variant='body1'>
                  or
                </Typography>
              </Divider>
            </Grid>
          )}
          {org && org.calendar_enabled === 'google' && (
            <Grid data-testid='sign-in' item xs>
              <Button
                color='secondary'
                data-testid='sign-up-with-google'
                fullWidth
                onClick={() => googleLogin()}
                startIcon={
                  <img
                    alt=''
                    className='google-logo'
                    src='/images/google-logo.svg'
                  />
                }
                variant='bordered'
              >
                Sign up with Google
              </Button>
            </Grid>
          )}
          {org && org.calendar_enabled === 'outlook' && (
            <Grid data-testid='sign-in' item xs>
              <MicrosoftLogin
                onSuccess={(token, user) => {
                  setToken(token);
                  setUser(user);
                  navigate('/events');
                }}
                org={org}
              />
            </Grid>
          )}
          {org && org.okta_enabled && (
            <Grid data-testid='sign-up' item xs>
              <OktaLoginButton />
            </Grid>
          )}

          <Grid item xs>
            <Typography mt={4} textAlign='center' variant='body1'>
              Already have an account?{' '}
              <Link component={RouterLink} data-testid='sign-in' to='/login'>
                Sign in
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </OnboardFrame>
    </GoogleOAuthProvider>
  );
};

export default SignUp;
