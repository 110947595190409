import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  InfiniteData,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from '@tanstack/react-query';

interface UseInfiniteScrollQueryProps<TData> {
  key: any[];
  observerTarget: React.RefObject<Element>;
  useQuery: ({
    pageParam,
  }: {
    pageParam?: number;
  }) => Promise<TData | undefined>;
}

interface Response {
  currentPage: number;
  totalCount: number;
  totalPages: number;
}

export const useInfiniteScrollQuery = <TData extends Response>({
  key,
  observerTarget,
  useQuery,
}: UseInfiniteScrollQueryProps<TData>): InfiniteQueryObserverResult<
  InfiniteData<TData | undefined, unknown>,
  Error
> => {
  const getThreshold = useCallback(() => {
    if (window.innerWidth < 480) {
      return 0.5;
    } else if (window.innerWidth < 768) {
      return 0.7;
    } else {
      return 0.8;
    }
  }, []);
  const [threshold, setThreshold] = useState(getThreshold());
  const observerRef = useRef<IntersectionObserver | null>(null);

  const query = useInfiniteQuery({
    getNextPageParam: (lastPage) => {
      if (!lastPage || lastPage.currentPage >= lastPage?.totalPages) {
        return undefined;
      }
      return lastPage.currentPage + 1;
    },
    initialPageParam: 1,
    queryFn: useQuery,
    queryKey: key,
  });

  const createObserver = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            query.hasNextPage &&
            !query.isFetchingNextPage
          ) {
            query.fetchNextPage();
          }
        });
      },
      { rootMargin: '100px', threshold }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observerRef.current.observe(currentTarget);
    }
  }, [observerTarget, query, threshold]);

  useEffect(() => {
    createObserver();

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [createObserver]);

  useEffect(() => {
    const handleResize = () => {
      const newThreshold = getThreshold();
      if (newThreshold !== threshold) {
        setThreshold(newThreshold);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [threshold, getThreshold]);

  return query;
};
