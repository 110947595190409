import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

const ProtectedRoute = ({
  children,
  isAllowed,
  minRoleRequired,
  redirectPath = '/events',
}: {
  children?: React.ReactElement;
  isAllowed?: boolean;
  minRoleRequired?: 'user' | 'organizer' | 'admin';
  redirectPath?: string;
}) => {
  const {
    isAuthenticated,
    userProvider: { isOrganizerOrAdmin, user },
  } = useAuth();

  const hasMinRoleRequired = useMemo(
    () =>
      minRoleRequired && user
        ? minRoleRequired === 'admin'
          ? user?.is_admin
          : minRoleRequired === 'organizer'
          ? isOrganizerOrAdmin
          : minRoleRequired === 'user'
          ? isAuthenticated
          : false
        : true,
    [isAuthenticated, isOrganizerOrAdmin, minRoleRequired, user]
  );

  if (isAllowed === false || hasMinRoleRequired === false) {
    return <Navigate replace to={redirectPath} />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
