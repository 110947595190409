import React from 'react';
import { CardContent, CardHeader, Stack, useTheme } from '@mui/material';
import { PiggyBank } from '@phosphor-icons/react';

import GroupBudgetBarChart from 'components/Groups/GroupBudgetBarChart';
import GroupBudgetSpent from 'components/Groups/GroupBudgetSpent';
import BudgetStatusChip from 'components/shared/BudgetStatusChip';
import ChartLegendItem from 'components/shared/ChartLegendItem';
import EmptyState from 'components/shared/EmptyState';
import InfoTooltip from 'components/shared/InfoTooltip';
import { Group } from 'types/Group';

interface GroupBudgetCardProps {
  group: Group | undefined;
  groupBudget: number;
  totalSpent: number;
}

const GroupBudgetCard = ({
  group,
  groupBudget,
  totalSpent,
}: GroupBudgetCardProps) => {
  const theme = useTheme();

  const currencySymbol = group?.currency_details?.symbol || '';
  const availableAmount = groupBudget - totalSpent;
  const overBudgetAmount = totalSpent - groupBudget;
  let budgetStatus: string | undefined;

  if (groupBudget > 0) {
    budgetStatus = totalSpent <= groupBudget ? 'On budget' : 'Over budget';
  } else {
    budgetStatus = 'Budget undefined';
  }

  const barGraphData = [
    {
      Available: availableAmount >= 0 ? availableAmount : 0,
      Budget: groupBudget,
      label: 'Budget',
      OverBudget: overBudgetAmount > 0 ? overBudgetAmount : null,
      Spent: totalSpent,
    },
  ];

  return (
    <>
      <CardHeader
        action={<BudgetStatusChip status={budgetStatus} />}
        title={
          <InfoTooltip
            content='Summary of group expenses in relation to the set group budget.'
            text='Group budget'
          />
        }
        titleTypographyProps={{ component: 'h4', variant: 'body2' }}
      />
      <CardContent sx={{ pt: 0 }}>
        {groupBudget > 0 ? (
          <Stack direction='column' gap={1}>
            <GroupBudgetSpent
              budgetStatus={budgetStatus}
              currencySymbol={currencySymbol}
              total={groupBudget}
              totalSpent={totalSpent}
            />
            <GroupBudgetBarChart
              currencySymbol={currencySymbol}
              data={barGraphData}
              isOverBudget={budgetStatus === 'Over budget'}
            />
            <Stack direction='column'>
              <ChartLegendItem
                borderColor={
                  budgetStatus === 'Over budget'
                    ? theme.palette.grey[500]
                    : undefined
                }
                color={
                  budgetStatus === 'Over budget'
                    ? theme.palette.grey[100]
                    : theme.palette.primary.main
                }
                label={`${currencySymbol}${
                  budgetStatus === 'Over budget'
                    ? groupBudget?.toFixed(0)
                    : totalSpent.toFixed(0)
                } ${budgetStatus === 'Over budget' ? 'budget' : 'spent'}`}
                textVariant='overline'
                value={
                  budgetStatus === 'Over budget' ? groupBudget : totalSpent
                }
              />
              {overBudgetAmount < 0 ? (
                <ChartLegendItem
                  borderColor={theme.palette.grey[500]}
                  color={theme.palette.grey[100]}
                  label={`${currencySymbol}${availableAmount.toFixed(
                    0
                  )} available`}
                  textVariant='overline'
                  value={availableAmount}
                />
              ) : (
                <ChartLegendItem
                  color={theme.palette.error.dark}
                  label={`${currencySymbol}${overBudgetAmount.toFixed(
                    0
                  )} over budget`}
                  textVariant='overline'
                  value={overBudgetAmount}
                />
              )}
            </Stack>
          </Stack>
        ) : (
          <EmptyState
            Icon={PiggyBank}
            iconColor='primary'
            maxContentWidth={350}
            secondary="Keep track of spending in relation to your group's set budget."
          />
        )}
      </CardContent>
    </>
  );
};

export default GroupBudgetCard;
