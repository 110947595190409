import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';

import SurveyAnonymitySubheader from 'components/Events/Controls/Shared/SurveyAnonymitySubheader';
import SurveyQuestionList from 'components/Events/Controls/Shared/SurveyQuestionList';
import { useAllRequiredSubmitted } from 'components/Events/Hooks/Generic';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import RichTextDisplay from 'components/shared/RichTextDisplay';
import { useContextWithFallback } from 'utils/utils';

const PreEventSurveyModal = ({
  handleClose,
  handleSubmit,
  show,
  surveyDescription,
}) => {
  const {
    state: { surveys },
  } = useContextWithFallback(ManagerContext, PublicEventContext);

  const allAnswersSubmitted = useAllRequiredSubmitted(
    _.get(surveys, `pre_event_survey.responses`, []),
    _.get(surveys, `pre_event_survey.survey_questions`)
  );

  return (
    <Dialog aria-label='Pre-event survey' onClose={handleClose} open={show}>
      <DialogTitle>
        <Typography variant='inherit'>
          Please submit your pre-event survey!
        </Typography>
        <SurveyAnonymitySubheader
          displayResponders={surveys?.pre_event_survey?.display_responders}
        />
        {surveyDescription && <RichTextDisplay text={surveyDescription} />}
      </DialogTitle>
      <DialogContent sx={{ py: 0, px: 3 }}>
        <SurveyQuestionList type='pre_event_survey' />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid='submit-survey'
          disabled={!allAnswersSubmitted}
          onClick={handleSubmit}
          type='submit'
          variant='contained'
        >
          Submit survey
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreEventSurveyModal;
