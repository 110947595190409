import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { PaperPlaneTilt } from '@phosphor-icons/react';

import { CommunicationTypeId } from 'components/Events/Controls/Communications/communication.types';

interface SendNowConfirmationProps {
  confirm?: boolean;
  isOpen?: boolean;
  isSending?: boolean;
  onCancel?(...args: unknown[]): unknown;
  onSendNow?(...args: unknown[]): unknown;
  type?: CommunicationTypeId;
}

const SendNowConfirmation = ({
  isOpen,
  isSending,
  onCancel,
  onSendNow,
  type,
}: SendNowConfirmationProps) => {
  const handleClose = () => {
    onCancel && onCancel();
  };

  return (
    <Dialog aria-label='delete' onClose={handleClose} open={Boolean(isOpen)}>
      <DialogTitle>{`Send ${type} now`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`You are about to send this ${type} immediately. Once sent, this action
          cannot be undone. Are you sure you want to proceed?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid='cancel' onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          aria-label='confirm send'
          color='primary'
          loading={isSending}
          onClick={onSendNow}
          startIcon={<PaperPlaneTilt />}
          variant='contained'
        >
          Send now
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SendNowConfirmation;
