import React from 'react';
import { Avatar, PaletteColor } from '@mui/material';
import { Icon } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

const AnalyticsAvatar = ({
  color,
  Icon,
}: {
  color: PaletteColor;
  Icon: Icon;
}) => {
  const avatarSize = 48;
  const iconSize = 32;
  return (
    <Avatar
      data-testid='TODO:DATA-AVATAR-40216'
      sx={{
        bgcolor: color?.light,
        color: color?.dark,
        height: avatarSize,
        width: avatarSize,
      }}
    >
      <Icon size={iconSize} />
    </Avatar>
  );
};

AnalyticsAvatar.propTypes = {
  color: PropTypes.object,
  Icon: PropTypes.any,
};

export default AnalyticsAvatar;
