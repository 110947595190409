import React from 'react';
import { Button, ButtonOwnProps, Collapse, Stack } from '@mui/material';

interface Props extends ButtonOwnProps {
  handleCancel?: () => unknown;
  handleEdit: () => unknown;
  handleSave: () => unknown;
  isEditing: boolean;
}

const EditSaveButton = ({
  handleCancel,
  handleEdit,
  handleSave,
  isEditing,
  ...props
}: Props) => (
  <Stack direction='row' gap={1.5}>
    <Collapse
      collapsedSize={0}
      in={isEditing && !!handleCancel}
      orientation='horizontal'
    >
      <Button onClick={handleCancel} variant='text' {...props}>
        Cancel
      </Button>
    </Collapse>
    <Button
      onClick={() => (isEditing ? handleSave() : handleEdit())}
      variant={isEditing ? 'contained' : 'text'}
      {...props}
    >
      {isEditing ? 'Save' : 'Edit'}
    </Button>
  </Stack>
);

export default EditSaveButton;
