import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { reactQueryAxios } from 'utils/axios';

interface CustomEmoji {
  id: string;
  imgUrl: string;
  names: string[];
}

export const useSlackCustomEmojis = () => {
  const queryKey = ['slackEmojis'];
  const url = `/api/organizations/slack_emojis`;

  const apiRetrieve = async ({ apiClient }: { apiClient: any }) =>
    apiClient({
      method: 'get',
      url,
    });

  const retrieve = useQuery<CustomEmoji[], Error>({
    queryFn: () =>
      apiRetrieve({ apiClient: reactQueryAxios }).then(
        (response: AxiosResponse) => response?.data
      ),
    queryKey,
  });

  return { retrieve };
};
