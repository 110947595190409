/* eslint-disable @typescript-eslint/no-unused-vars */
import * as _ from 'lodash';
import moment from 'moment-timezone';

import { DefaultEventTimes } from 'utils/event';
import { DefaultQuestion } from 'utils/survey';

export const transformEvent = (event, fromPublicView = false) => {
  if (!event.meeting) {
    const meeting = {
      config: {},
      link: '',
      service: null,
    };
    event.meeting = meeting;
  }

  if (event?.meeting?.service && event?.location?.length > 0) {
    event.location_type = 'hybrid';
  } else if (!event?.meeting?.service && event?.location?.length > 0) {
    event.location_type = 'inperson';
  } else if (event?.meeting?.service && event?.location?.length === 0) {
    event.location_type = 'virtual';
  } else {
    event.location_type = '';
  }

  if (fromPublicView) {
    event.pre_event_survey = {
      description: event.pre_event_survey?.description || '',
      display_responders: event.pre_event_survey?.display_responders || false,
      event_id: event.id,
      id: event.pre_event_survey?.id || '',
      style: 'pre',
      survey_options: event.pre_event_survey?.survey_options || [],
      survey_options_responses:
        event.pre_event_survey?.survey_options_responses || [],
      survey_questions: event.pre_event_survey?.survey_questions || [],
      survey_responses: event.pre_event_survey?.survey_responses || [],
    };

    _.set(
      event,
      'post_event_survey.survey_questions',
      _.concat(
        event.post_event_survey?.survey_questions,
        DefaultQuestion('public_post_survey')
      )
    );

    _.set(
      event,
      'pre_event_survey.survey_questions',
      _.concat(
        event.pre_event_survey?.survey_questions,
        DefaultQuestion('standalone_pre_survey')
      )
    );
  }

  return { ...event };
};

export const transformEventToFormData = (
  event,
  cover_image,
  isPublishing = false
) => {
  const ev = _.cloneDeep(event);
  ev.timezone = moment.tz.guess();

  const fd = new FormData();
  Object.keys(ev).forEach((key) => {
    if (key === 'office_ids' || key === 'group_ids' || key === 'host_ids') {
      fd.append(`event[${key}]`, JSON.stringify(ev[key]));
    } else if (key === 'resources') {
      ev[key].forEach((r, i) => {
        Object.keys(r).forEach((attr) => {
          if (attr === 'destroy') {
            fd.append(`event[resources_attributes][${i}][_destroy]`, r[attr]);
          } else if (attr === 'id' && r[attr] === '') {
            // do nothing
          } else {
            fd.append(`event[resources_attributes][${i}][${attr}]`, r[attr]);
          }
        });
      });
    } else if (key === 'event_type_id') {
      fd.append(
        `event[event_type_ids]`,
        event.event_type_id ? [event.event_type_id] : []
      );
    } else if (key === 'location') {
      if (event?.location_type !== 'virtual') {
        fd.append(`event[${key}]`, ev[key]);
      } else {
        fd.append(`event[${key}]`, '');
      }
    } else if (key === 'meeting' && event?.location_type !== 'inperson') {
      if (event?.meeting?.service) {
        Object.keys(event?.meeting).forEach((key) => {
          if (event?.meeting[key] !== null) {
            if (key === 'config') {
              Object.keys(event?.meeting?.config).forEach((attribute) => {
                fd.append(
                  `event[meeting_attributes][config][${attribute}]`,
                  event?.meeting?.config[attribute]
                );
              });
            } else {
              fd.append(
                `event[meeting_attributes][${key}]`,
                event?.meeting[key]
              );
            }
          }
        });
      }
    } else if (key === 'displayable_hosts') {
      Object.values(event?.displayable_hosts).forEach((object, objectIndex) => {
        Object.keys(object).forEach((key) => {
          if (key === 'type') {
            fd.append(
              `event[displayable_attributes][${objectIndex}][displayable_type]`,
              object[key]
            );
          } else if (key === 'ids') {
            fd.append(
              `event[displayable_attributes][${objectIndex}][displayable_ids]`,
              JSON.stringify(object[key])
            );
          }
        });
      });
    } else if (key === 'config') {
      Object.keys(event?.config).forEach((attribute) => {
        if (attribute === 'cover_image_dimensions') {
          fd.append(
            `event[config][${attribute}]`,
            JSON.stringify(event?.config?.cover_image_dimensions)
          );
        } else if (attribute !== 'cover_image_url') {
          fd.append(`event[config][${attribute}]`, event?.config[attribute]);
        }
      });
    } else if (
      key === 'cover_image' ||
      key === 'location_type' ||
      key === 'event_type_ids'
    ) {
      // Do nothing here
    } else if (ev[key] == null) {
      // Do nothing here
    } else {
      fd.append(`event[${key}]`, ev[key]);
    }
  });

  const timeKeys = Object.keys(DefaultEventTimes);
  timeKeys.forEach((key) => {
    if (!ev[key] || ev[key] === null) {
      fd.append(`event[${key}]`, DefaultEventTimes[key]);
    }
  });

  const isRemovingCoverImage = cover_image === false;

  if (
    (cover_image || isRemovingCoverImage) &&
    typeof cover_image !== 'string'
  ) {
    fd.append('event[coverImageChanged]', true);
    if (isRemovingCoverImage) {
      fd.append('event[config][cover_image_dimensions]', null);
    } else {
      fd.append('event[cover_image]', cover_image);
    }
  }

  if (isPublishing) {
    if (!ev.id) {
      // INFO: Event doesn't have an ID yet. This means the user is immediately publishing their newly created event.
      if (fd.has('event[published]')) {
        fd.set('event[published]', true);
      } else {
        fd.append('event[published]', true);
      }
    }
  }

  return fd;
};

export const transformSurveysToFormData = (surveys) => {
  const fd = new FormData();
  Object.keys(surveys).forEach((survey, surveyIndex) => {
    if (surveys[survey].enabled === true || surveys[survey].id) {
      const surveyId = surveys[survey].id;
      Object.keys(surveys[survey]).forEach((key) => {
        if (key === 'survey_questions') {
          surveys[survey][key].forEach((question) => {
            Object.keys(question).forEach((attr) => {
              if (attr === 'destroy') {
                fd.append(
                  `event[surveys_attributes][${
                    surveyId || surveyIndex
                  }][survey_questions_attributes][${
                    question.id && typeof question.id === 'number'
                      ? question.id
                      : question.position
                  }][_destroy]`,
                  question[attr]
                );
              } else {
                fd.append(
                  `event[surveys_attributes][${
                    surveyId || surveyIndex
                  }][survey_questions_attributes][${
                    question.id && typeof question.id === 'number'
                      ? question.id
                      : question.position
                  }][${attr}]`,
                  question[attr]
                );
              }
            });
          });
        } else if (key === 'survey_options') {
          const validOptions = _.filter(
            surveys[survey][key],
            (option) => option.text && option.text.trim() !== ''
          );
          validOptions.forEach((option, index) => {
            Object.keys(option).forEach((attr) => {
              if (attr === 'destroy') {
                fd.append(
                  `event[surveys_attributes][${
                    surveyId || surveyIndex
                  }][survey_questions_attributes][${
                    option.survey_question_id
                  }][survey_options_attributes][${
                    option.id ? option.id : index
                  }][_destroy]`,
                  option[attr]
                );
              } else {
                fd.append(
                  `event[surveys_attributes][${
                    surveyId || surveyIndex
                  }][survey_questions_attributes][${
                    option.survey_question_id
                  }][survey_options_attributes][${
                    option.id ? option.id : index
                  }][${attr}]`,
                  option[attr]
                );
              }
            });
          });
        } else if (surveys[survey][key] === null) {
          fd.append(
            `event[surveys_attributes][${surveyId || surveyIndex}][${key}]`,
            ''
          );
        } else if (key === 'display_responders') {
          fd.append(
            `event[surveys_attributes][${surveyId || surveyIndex}][${key}]`,
            surveys[survey][key]
          );
        } else {
          fd.append(
            `event[surveys_attributes][${surveyId || surveyIndex}][${key}]`,
            surveys[survey][key]
          );
        }
      });
    }
  });
  return fd;
};

export const transformSurveyTemplateToFormData = (surveyTemplate) => {
  const fd = new FormData();
  Object.keys(surveyTemplate).forEach((key) => {
    if (key === 'survey_questions') {
      surveyTemplate[key].forEach((question) => {
        Object.keys(question).forEach((attr) => {
          if (attr === 'destroy') {
            fd.append(
              `survey_template[survey_questions_attributes][${
                question.id && typeof question.id === 'number'
                  ? question.id
                  : question.position
              }][_destroy]`,
              question[attr]
            );
          } else {
            fd.append(
              `survey_template[survey_questions_attributes][${
                question.id && typeof question.id === 'number'
                  ? question.id
                  : question.position
              }][${attr}]`,
              question[attr]
            );
          }
        });
      });
    } else if (key === 'survey_options') {
      surveyTemplate[key].forEach((option, index) => {
        Object.keys(option).forEach((attr) => {
          if (attr === 'destroy') {
            fd.append(
              `survey_template[survey_questions_attributes][${
                option.survey_question_id
              }][survey_options_attributes][${
                option.id ? option.id : index
              }][_destroy]`,
              option[attr]
            );
          } else if (attr !== 'survey_question_id') {
            fd.append(
              `survey_template[survey_questions_attributes][${
                option.survey_question_id
              }][survey_options_attributes][${
                option.id ? option.id : index
              }][${attr}]`,
              option[attr]
            );
          }
        });
      });
    } else if (
      !['enabled', 'editing', 'id'].includes(key) &&
      surveyTemplate[key] !== null &&
      !key.includes('_responses')
    ) {
      fd.append(`survey_template[${key}]`, surveyTemplate[key]);
    }
  });

  return fd;
};

export const transformPublicSurveyResponsesToFormData = (
  eventId,
  action,
  responses,
  submittingPreEventSurvey
) => {
  const fd = new FormData();
  fd.append('invitee[event_id]', eventId);
  fd.append('invitee[action]', action);
  fd.append('submitting_pre_event_survey', submittingPreEventSurvey);

  let index = 0;
  _.forEach(responses, (resp, key) => {
    if (key === 'first_name' || key === 'last_name' || key === 'email') {
      fd.append(`invitee[${key}]`, resp);
    } else {
      const qId = key;
      const qResponse = resp;
      if (qId) {
        fd.append(`invitee[responses][${index}][question_id]`, qId);

        if (_.isArray(qResponse)) {
          _.forEach(qResponse, (optId) =>
            fd.append(`invitee[responses][${index}][response][]`, optId)
          );
        } else {
          fd.append(`invitee[responses][${index}][response]`, qResponse);
        }
        index++;
      }
    }
  });
  return fd;
};

export const transformSurveyResponsesToFormData = (
  action,
  responses,
  submittingPreEventSurvey
) => {
  const fd = new FormData();
  fd.append('status', action);
  fd.append('submitting_pre_event_survey', submittingPreEventSurvey);

  let index = 0;
  _.forEach(responses, (value, key) => {
    const qId = key;
    const qResponse = value;
    if (qId && qResponse) {
      fd.append(`event[responses][${index}][question_id]`, qId);

      if (_.isArray(qResponse)) {
        _.forEach(qResponse, (optId) =>
          fd.append(`event[responses][${index}][response][]`, optId)
        );
      } else {
        fd.append(`event[responses][${index}][response]`, qResponse);
      }
      index++;
    }
  });
  return fd;
};

export const transformSurveyResponses = (responses, event, type) => {
  const transResponses = {};
  let index = 0;
  _.forEach(responses, (response, questionId) => {
    const transResponse = {
      question_id: questionId,
    };

    const eventQuestion = _.find(
      event[type].survey_questions,
      // eslint-disable-next-line eqeqeq
      (q) => q.id == questionId
    );

    if (eventQuestion) {
      switch (eventQuestion.question_type) {
        case 'nps':
        case 'short':
          transResponse.content = response;
          break;
        case 'multi_select':
        case 'single_select':
          transResponse.survey_option_id = response;
          break;
        default:
      }
      transResponses[`${index++}`] = transResponse;
    }
  });
  return transResponses;
};

// TODO: Deprecate and remove with completion of new editor
export const transformEventAnalytics = (event) => {
  if (!event) {
    return null;
  }

  const postEventSurvey = event.post_event_survey;
  const preEventSurvey = event.pre_event_survey;

  // / Post Event

  const postEventQuestions = _.chain(postEventSurvey?.survey_questions)
    .map((q) => ({
      position: q.position,
      questionId: q.id,
      required: q.required,
      responses: 0,
      // calculated in a 2nd pass
      setting: 'post',
      title: q.title,
      type: q.question_type,
    }))
    .sort((a, b) => a.position - b.position)
    .keyBy('questionId')
    .toArray()
    .value();

  // Gather responses for short answer & NPS questions
  const freeOptions =
    _.chain(postEventSurvey?.survey_responses)
      .remove((r) => r.content && _.trim(r.content).length > 0)
      .map((r) => ({
        optionId: r.id,
        questionId: r.survey_question_id,
        responses: 1,
        title: r.content,
      }))
      .value() || [];

  // Collate choice responses (single & multi select)
  const choiceResponses = _.groupBy(
    postEventSurvey?.survey_options_responses,
    'survey_option_id'
  );
  const choiceOptions =
    _.chain(postEventSurvey?.survey_options)
      .map((o) => ({
        optionId: o.id,
        questionId: o.survey_question_id,
        responses: choiceResponses[o.id]?.length || 0,
        title: o.text,
      }))
      .value() || [];

  const postEventSurveyOptions = _.chain(freeOptions)
    .unionBy(choiceOptions, 'optionId')
    .value();

  // // Pre Event

  const preEventQuestions = _.chain(preEventSurvey?.survey_questions)
    .map((q) => ({
      position: q.position,
      questionId: q.id,
      required: q.required,
      responses: 0,
      // calculated in a 2nd pass
      setting: 'pre',
      title: q.title,
      type: q.question_type,
    }))
    .sort((a, b) => a.position - b.position)
    .keyBy('questionId')
    .toArray()
    .value();

  // Gather responses for short answer & NPS questions
  const preFreeOptions =
    _.chain(preEventSurvey?.survey_responses)
      .remove((r) => r.content && _.trim(r.content).length > 0)
      .map((r) => ({
        optionId: r.id,
        questionId: r.survey_question_id,
        responses: 1,
        title: r.content,
      }))
      .value() || [];

  // Collate choice responses (single & multi select)
  const preChoiceResponses = _.groupBy(
    preEventSurvey?.survey_options_responses,
    'survey_option_id'
  );
  const preChoiceOptions =
    _.chain(preEventSurvey?.survey_options)
      .map((o) => ({
        optionId: o.id,
        questionId: o.survey_question_id,
        responses: preChoiceResponses[o.id]?.length || 0,
        title: o.text,
      }))
      .value() || [];
  const preEventSurveyOptions = _.chain(preFreeOptions)
    .unionBy(preChoiceOptions, 'optionId')
    .value();

  const options = _.groupBy(
    [...postEventSurveyOptions, ...preEventSurveyOptions],
    'questionId'
  );
  const allQuestions = [...postEventQuestions, ...preEventQuestions];
  // 2nd pass calculations
  _.forEach(allQuestions, (q) => {
    q.responses = _.sumBy(options[q.questionId], 'responses') || 0;
  });

  return {
    options,
    questions: allQuestions,
    ...event,
  };
};
