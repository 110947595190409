export const NpsScoreDescriptions = ({ isSingleEvent = false }) => {
  const subject = isSingleEvent ? 'This event' : 'Your events';
  const hasReceived = `${isSingleEvent ? 'has' : 'have'} received`;
  const was = isSingleEvent ? 'was' : 'were';
  const suggestion = `Evaluate guests' comments for improvements.`;
  return [
    {
      describes: (score) => score <= 0,
      max: 0,
      primary: '<= 0',
      secondary: `${subject} ${hasReceived} mostly negative feedback. ${suggestion}`,
    },
    {
      describes: (score) => score > 0 && score <= 10,
      max: 10,
      min: 1,
      primary: '1 - 10',
      secondary: `${subject} ${hasReceived} mixed reviews. ${suggestion}`,
    },
    {
      describes: (score) => score > 10 && score <= 50,
      max: 50,
      min: 11,
      primary: '11 - 50',
      secondary: `${subject} ${hasReceived} mostly positive feedback. While there are some areas for improvement, ${subject?.toLowerCase()} ${was} a success.`,
    },
    {
      describes: (score) => score > 50,
      min: 51,
      primary: '51 +',
      secondary: `${subject} ${hasReceived} excellent feedback!`,
    },
  ];
};
