import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import FtnEvent from 'types/FtnEvent';
import { reactQueryAxios } from 'utils/axios';

const deleteZoomLink = async (id: string | number, apiClient: any) =>
  apiClient({
    method: 'delete',
    url: `/api/zoom/${id}`,
  });

export const useDeleteZoomLink = ({
  dataHandler = () => undefined,
}: {
  dataHandler?: (d?: FtnEvent) => unknown;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (id: string | number) => deleteZoomLink(id, reactQueryAxios),

    onError: (error: Error) => {
      console.error(error);
      enqueueSnackbar(
        "We couldn't delete the Zoom meeting for you. Please try again.",
        { variant: 'error' }
      );
    },

    onSuccess: (response: any) => {
      const ftnEvent = response.data;
      queryClient.setQueryData(['event', ftnEvent.id], ftnEvent);
      dataHandler(ftnEvent);
    },
  });
};
