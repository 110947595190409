import React from 'react';
import { Box } from '@mui/system';
import { PiggyBank, Plus } from '@phosphor-icons/react';

import EmptyState from 'components/shared/EmptyState';

const EventExpenseTrackerEmptyState = ({
  handleClick,
}: {
  handleClick: (open: boolean) => void;
}) => (
  <Box bgcolor='white' py={6}>
    <EmptyState
      button={{
        fullWidth: true,
        onClick: () => handleClick(true),
        startIcon: <Plus />,
      }}
      buttonLabel='Add expenses'
      Icon={PiggyBank}
      maxContentWidth={350}
      primary='Your expense tracker is currently empty.'
      secondary='Start using your expense tracker by adding an expense.'
    />
  </Box>
);

export default EventExpenseTrackerEmptyState;
