import React from 'react';

import Search from 'components/Core/Search';
import Filters from 'components/shared/Filters';

interface GroupBudgetTrackerFiltersProps {
  eventTypeOptions?: { id: number; name: string }[];
  search?: string;
  setEventTypeSelections?: (v: number[]) => void;
  setSearch: (v: string) => void;
}

const GroupBudgetTrackerFilters: React.FC<GroupBudgetTrackerFiltersProps> = ({
  eventTypeOptions = [],
  search,
  setEventTypeSelections,
  setSearch,
}) => {
  const handleFilterChange = (selectedEventTypes: number[]) => {
    setEventTypeSelections?.(selectedEventTypes);
  };

  const handleClearAll = () => {
    setEventTypeSelections?.([]);
  };

  return (
    <Filters
      filters={[
        {
          defaultValueId: null,
          handleChange: handleFilterChange,
          label: 'Event Type',
          options: eventTypeOptions,
        },
      ]}
      handleClearAll={handleClearAll}
    >
      <Search
        label='Search'
        onChange={(e) => setSearch(e.target.value)}
        placeholder='Search events'
        value={search || ''}
      />
    </Filters>
  );
};

export default GroupBudgetTrackerFilters;
