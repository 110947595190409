import React, { useContext } from 'react';
import {
  FormControl,
  FormLabel,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { NpsOptions } from 'constants/survey.constants';
import { LayoutContext } from 'contexts/LayoutContext';

interface NpsProps {
  onSupplyResponse: (id: any, val: any) => void;
  question: any;
  questionPosition: number;
  readonly: boolean;
  showLabel?: boolean;
  sx?: SxProps;
  value: any;
}

const QuestionNps = ({
  onSupplyResponse,
  question,
  questionPosition,
  readonly,
  showLabel = true,
  sx,
  value,
}: NpsProps) => {
  const { isMobile } = useContext(LayoutContext);
  return (
    <FormControl
      data-testid='nps-question'
      required={question?.required}
      sx={{ alignItems: 'flex-start', ...sx }}
    >
      {showLabel ? (
        <FormLabel data-testid='nps-question-label' id='nps-question-label'>
          {question?.title.length > 0
            ? `${questionPosition}. ${question?.title}`
            : `Please add a valid question title.`}
        </FormLabel>
      ) : null}
      <ToggleButtonGroup
        aria-labelledby='nps-question-label'
        color={readonly ? 'neutral' : undefined}
        data-testid='nps-question-options'
        exclusive
        fullWidth={isMobile}
        onChange={(e, val) => !readonly && onSupplyResponse(question?.id, val)}
        sx={{
          ...(readonly ? {} : { mt: 0.5 }),
          ...(isMobile ? { flexDirection: 'column', gap: 1.5 } : {}),
        }}
        value={value}
      >
        {NpsOptions.map(({ label, value }) => (
          <ToggleButton
            data-testid='TODO:DATA-TOGGLEBUTTON-79147'
            key={`nps-option-${label}`}
            sx={{
              flex: 1,
              lineHeight: 1.5,
              ...(isMobile ? { ml: '0 !important' } : {}),
              ...(readonly
                ? {
                    '&:hover:not(.Mui-selected)': {
                      bgcolor: 'transparent !important',
                    },
                  }
                : {}),
            }}
            value={value}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FormControl>
  );
};

export default QuestionNps;
