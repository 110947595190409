import React, { useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Stamp } from '@phosphor-icons/react';

import SummaryField from 'components/shared/SummaryField';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { EventDetailsFormControl } from 'types/EventDetailsForm';

interface EventTypeFieldProps {
  control: EventDetailsFormControl;
  disableFields: boolean;
  isLoading?: boolean;
}

const EventTypeField = ({
  control,
  disableFields,
  isLoading = false,
}: EventTypeFieldProps) => {
  const [organization] = useContext(OrganizationContext);

  const options = useMemo(
    () =>
      organization?.event_types
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
        .map((o: any) => ({
          id: o.id,
          label: o.name,
          name: o.name,
          value: o.id,
        })),
    [organization?.event_types]
  );

  return (
    <Controller
      control={control}
      name='eventTypeId'
      render={({ field }) => {
        const selectedValue = field?.value
          ? options?.find((opt: any) => opt.value === field.value)
          : null;

        return (
          <SummaryField
            Icon={Stamp}
            isEditable={!disableFields}
            isLoading={isLoading}
            menuItems={options}
            onSubmit={(v) => field.onChange(v ? v?.id : null)}
            primary='Event type'
            selectionLimits={{ max: 1 }}
            type='menu'
            value={selectedValue ? selectedValue?.name : undefined}
            valueType='text'
          />
        );
      }}
    />
  );
};

export default EventTypeField;
