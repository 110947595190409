import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { MinusCircle, Plus } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import { ConditionalLoader } from 'components/Core/Loader';
import Search from 'components/Core/Search';
import AddGroupMembers from 'components/Groups/AddGroupMembers';
import SlackSyncedGroupAlert from 'components/Groups/SlackSyncedGroupAlert';
import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import TabLabelWithCount from 'components/shared/TabLabelWithCount';
import { axiosAuthenticated as axios } from 'utils/axios';
import { fullName, searchFilter } from 'utils/employee';

const Members = ({
  canEdit = null,
  employees,
  group,
  setGroup,
  syncedChannel = null,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [showTray, setShowingTray] = useState(false);

  const members = useMemo(
    () =>
      group.member_employees
        ?.filter((e) => searchFilter(searchVal, e))
        .map((e) => ({ ...e, name: fullName(e) })),
    [group?.member_employees, searchVal]
  );

  const admins = useMemo(
    () => group?.admin_employees,
    [group?.admin_employees]
  );

  const handleRemove = (cleanup) => {
    const payload = { employee_ids: selectedRowIds };
    const subject =
      selectedRowIds?.length === 1
        ? employees?.find((e) => e.id === selectedRowIds[0])?.full_name
        : pluralize('member', selectedRowIds.length, true);

    axios(
      {
        data: payload,
        method: 'post',
        url: `/api/groups/${group.id}/mass_leave`,
      },
      (res) => {
        enqueueSnackbar(`${subject} removed from group!`, {
          variant: 'success',
        });
        setGroup(res.data);
        setIsConfirmingDelete(false);
        setSelectedRowIds([]);
        cleanup();
      },
      () => {
        enqueueSnackbar(
          `${subject} couldn't be removed from the group, try again later`,
          { variant: 'error' }
        );
        setSelectedRowIds([]);
        cleanup();
      }
    );
  };

  const columns = [
    {
      field: 'name',
      flex: 2,
    },
    {
      field: 'email',
      flex: 2,
    },
    {
      field: 'actions',
      formatExtraData: {
        selectedRowIds,
      },
      getActions: (params) => [
        <GridActionsCellItem
          icon={<MinusCircle size={24} />}
          key={`${params.id}-delete-button`}
          label='Delete'
          onClick={() => {
            setSelectedRowIds([params.id]);
            setIsConfirmingDelete(true);
          }}
        />,
      ],
      headerName: 'Remove',
      type: 'actions',
    },
  ];

  const isSlackSynced = syncedChannel != null;

  return (
    <>
      <Grid container direction={{ md: 'row', xs: 'column' }} spacing={3}>
        <Grid md={9} xs>
          <ConditionalLoader conditions={[!members, canEdit === null, !group]}>
            <Box>
              <Stack direction='column' gap={2}>
                <Box aria-label='Filter' role='region'>
                  <Search
                    label='Search members'
                    onChange={(e) => setSearchVal(e.target.value)}
                    placeholder='Search members'
                    value={searchVal}
                  />
                </Box>
                <FtnDataGrid
                  canEdit={canEdit && !isSlackSynced}
                  columns={columns}
                  noun='member'
                  rows={members}
                  {...(canEdit && !isSlackSynced
                    ? {
                        checkboxSelection: true,
                        onRowSelectionModelChange: setSelectedRowIds,
                        rowSelectionModel: selectedRowIds,
                        selectionToolbarProps: {
                          handleDelete: () => setIsConfirmingDelete(true),
                          lookupKey: 'name',
                          lookupList: members,
                          noun: 'Member',
                          nounForList: 'group',
                          owner: group.name,
                          selectedRowIds,
                          setSelectedRowIds,
                          showModal: false,
                          title: 'Members',
                          verb: 'Remove',
                        },
                      }
                    : {})}
                  data-testid='TODO:DATA-FTNDATAGRID-83946'
                />
              </Stack>
            </Box>
          </ConditionalLoader>
        </Grid>
        <Grid md={3} xs>
          <Stack direction='column' justifyContent='flex-start' spacing={2}>
            {canEdit && !isSlackSynced && (
              <Button
                color='primary'
                data-testid='add-new-members'
                fullWidth
                onClick={() => setShowingTray(true)}
                startIcon={<Plus />}
                variant='contained'
              >
                Add new members
              </Button>
            )}
            {isSlackSynced && (
              <SlackSyncedGroupAlert
                channelName={syncedChannel.slack_channel_name}
                isPrivate={syncedChannel?.is_private}
              />
            )}

            <Card data-testid='TODO:DATA-CARD-46277'>
              <CardHeader
                title={
                  <TabLabelWithCount count={admins?.length} label='Admins' />
                }
              />
              <List data-testid='TODO:DATA-LIST-64100' disablePadding>
                {admins.map((a) => (
                  <ListItem key={a.id}>
                    <ListItemText id={a.id} primary={fullName(a)} />
                  </ListItem>
                ))}
              </List>
            </Card>
          </Stack>
        </Grid>
      </Grid>
      <AddGroupMembers
        employees={employees}
        group={group}
        setGroup={setGroup}
        setShow={(newVal) => {
          if (newVal === false) {
            setSelectedRowIds([]);
          }
          setShowingTray(newVal);
        }}
        show={showTray}
      />
      <DeleteModal
        custom={{
          byline: `Are you sure you want to remove them from "${group.name}"?`,
        }}
        isPerson
        noun='member'
        onCancel={() => {
          setIsConfirmingDelete(false);
          if (selectedRowIds.length === 1) {
            setSelectedRowIds([]);
          }
        }}
        onDelete={handleRemove}
        owner={group.name}
        plural={selectedRowIds?.length > 1}
        show={isConfirmingDelete}
        subject={
          selectedRowIds.length === 1
            ? employees?.find((e) => e.id === selectedRowIds[0])?.full_name
            : 'selected members'
        }
        verb='Remove'
      />
    </>
  );
};

Members.propTypes = {
  canEdit: PropTypes.bool,
  employees: PropTypes.any,
  group: PropTypes.shape({
    admin_employees: PropTypes.array,
    id: PropTypes.any,
    member_employees: PropTypes.array,
    name: PropTypes.string,
  }),
  setGroup: PropTypes.func,
  syncedChannel: PropTypes.shape({
    is_private: PropTypes.bool,
    slack_channel_name: PropTypes.string,
  }),
};

export default Members;
