import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { useSnackbar } from 'notistack';

import DeleteModal from 'components/Core/DeleteModal';
import { ConditionalLoader } from 'components/Core/Loader';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const SlackSettings = () => {
  const [isConfirmingDelete, setConfirmDelete] = useState(false);
  const [, setOrg] = useContext(OrganizationContext);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const disableSlack = () => {
    setIsLoading(true);
    axios(
      {
        method: 'get',
        url: '/api/organizations/remove_slack',
      },
      (res) => {
        setOrg(res.data);
        setIsLoading(false);
        setConfirmDelete(false);
        enqueueSnackbar(
          'Slack integration removed! you can no longer invite guests via Slack channel',
          { variant: 'success' }
        );
        navigate('/settings');
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <ConditionalLoader conditions={[isLoading]}>
        <Card data-testid='TODO:DATA-CARD-45036'>
          <CardHeader
            title='Your Slack instance is connected to Five to Nine'
            titleTypographyProps={{ variant: 'body1' }}
          />
          <CardContent>
            <Button
              color='error'
              data-testid='disable-slack'
              onClick={() => setConfirmDelete(true)}
              variant='outlined'
            >
              Disable Slack
            </Button>
          </CardContent>
        </Card>
      </ConditionalLoader>
      <DeleteModal
        custom={{
          byline:
            'Disabling Slack integration will remove all synced Slack channels and remove your organization’s ability to send invitations and surveys via Slack.',
          confirm: 'Yes, disable Slack.',
          headline: 'Are you sure?',
          title: 'Disable Slack',
        }}
        onCancel={() => setConfirmDelete(false)}
        onDelete={disableSlack}
        show={isConfirmingDelete}
      />
    </>
  );
};

export default SlackSettings;
