import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Button, Collapse, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { Warning, X } from '@phosphor-icons/react';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';

interface Props {
  hasReviewButton?: boolean;
}

const EventTimeChangedCheckCommsAlert = ({ hasReviewButton = true }: Props) => {
  const {
    actions: { dismissRescheduleCommsAlert },
    state: { inManagerContext, isRescheduleCommsAlertVisible },
  } = useContext(ManagerContext);

  const navigate = useNavigate();

  return (
    <Collapse in={isRescheduleCommsAlertVisible && inManagerContext}>
      <Alert
        action={
          <Stack alignItems='center' direction='row' spacing={4}>
            {hasReviewButton && (
              <Button
                onClick={() => navigate(`../communications`)}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  color: 'warning.dark',
                }}
              >
                Review
              </Button>
            )}
            <IconButton
              onClick={() => {
                dismissRescheduleCommsAlert();
              }}
            >
              <X />
            </IconButton>
          </Stack>
        }
        icon={<Warning size={30} weight='fill' />}
        severity='warning'
        sx={{ mb: 2 }}
      >
        <AlertTitle>
          Event time changed, please review scheduled communications
        </AlertTitle>
        Ensure your scheduled communications match the new event time.
        Invitations scheduled after the event will be deleted.
      </Alert>
    </Collapse>
  );
};

export default EventTimeChangedCheckCommsAlert;
