import { useMediaQuery, useTheme } from '@mui/material';

export const useExternalSnackbarProps = () => {
  const theme = useTheme();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('lg'));
  return {
    anchorOrigin: {
      horizontal: smallScreen ? 'center' : 'left',
      vertical: 'bottom',
    },
    style: {
      left: smallScreen ? 'unset' : 'calc(50vw + 8px)',
      maxWidth: smallScreen ? 'unset' : '40vw',
    },
    TransitionProps: {
      direction: 'up',
    },
  };
};
