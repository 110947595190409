import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/Core/Dropdown';
import { useEditSettings } from 'contexts/SettingsContext';

const EmailDomainSettings = ({ organization, setField, newListItem }) => {
  const { state: editSettings } = useEditSettings();

  useEffect(() => {
    setField(
      'email_extensions_ids',
      organization.email_extensions.map((o) => o.id),
      true
    );

    setField(
      'email_extensions_attributes',
      organization.email_extensions.map((o) => ({
        id: o.id,
        domain: o.domain,
      }))
    );
  }, [organization, setField]);

  const opts = editSettings.email_extensions_attributes
    ? editSettings.email_extensions_attributes
        .sort((a, b) => a.domain.localeCompare(b.domain))
        .map((o) => ({ value: o.id, label: o.domain }))
    : [];

  const selOpts = opts?.filter((o) =>
    editSettings.email_extensions_ids?.includes(o.value)
  );

  return (
    <Dropdown
      allowCreate
      attributesField='email_extensions_attributes'
      className='multi'
      components={{ DropdownIndicator: null }}
      dataProp='domain'
      field='email_extensions_ids'
      helperText='Which email domains are allowed to sign up as new users?'
      isMulti
      label='Email domains'
      newer={newListItem}
      options={opts}
      placeholder={
        organization?.email_extensions?.length > 0
          ? null
          : 'Enter a valid domain (e.g. mycompany.com)'
      }
      requireOne
      selectedOption={selOpts}
      setter={setField}
      sx={{ '.MuiFormControl-root': { mt: 0 } }}
      tooltip='Email domains included here can access the Five to Nine platform and RSVP to “organization” events.'
    />
  );
};

EmailDomainSettings.propTypes = {
  organization: PropTypes.shape({
    email_extensions: PropTypes.array,
  }),
};

export default EmailDomainSettings;
