import React, { useEffect } from 'react';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { Avatar, Chip, Stack, Tooltip, Typography, useTheme, Box, Grid, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useFetchCollectionAnnouncements, Announcement, SentBy } from 'hooks/useFetchCollectionAnnouncements';
import { CloudinaryUrl } from 'utils/utils';
import { toInitials } from 'utils/text';
import { CheckCircle, Clock, XCircle, MegaphoneSimple } from '@phosphor-icons/react';
import { capitalize } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

interface CollectionAnnouncementsProps {
  eventsCollectionId: string;
  isCollectionEnded: boolean;
}

const statusDetails: Record<
  string,
  { label: string; color?: 'success' | 'info' | 'error' | 'warning'; Icon: React.ElementType | null }
> = {
  Sent: { label: 'Sent', color: 'success', Icon: CheckCircle },
  Scheduled: { label: 'Scheduled', color: 'warning', Icon: Clock },
  Failed: { label: 'Failed', color: 'error', Icon: XCircle },
  Sending: { label: 'Sending', color: 'info', Icon: Clock },
};

const CollectionAnnouncements: React.FC<CollectionAnnouncementsProps> = ({ eventsCollectionId, isCollectionEnded }) => {
  const { data: announcements = [], isLoading, error } = useFetchCollectionAnnouncements(eventsCollectionId);
  const theme = useTheme();
  const queryClient = useQueryClient();

  useEffect(() => {
    const hasSendingAnnouncements = announcements.some((announcement) => announcement.status === 'Sending');

    if (hasSendingAnnouncements) {
      const intervalId = setInterval(() => {
        queryClient.invalidateQueries({
          queryKey: ['fetchEventsCollectionAnnouncements', eventsCollectionId],
        });
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [announcements, queryClient, eventsCollectionId]);

  const columns: MRT_ColumnDef<Announcement>[] = [
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ cell }) => {
        const status = cell.getValue<string>();
        const statusDetail = statusDetails[status] || { label: status, Icon: null };

        const { label, color, Icon } = statusDetail;

        return (
          <Tooltip title={label === 'Scheduled' ? "This announcement is scheduled to be sent later." : ''}>
            <Chip
              icon={Icon ? <Icon size={18} /> : undefined}
              label={capitalize(label)}
              color={color || 'default'}
              size="small"
              sx={{
                height: 32,
                bgcolor: color ? theme.palette[color].light : undefined,
                color: color ? theme.palette[color].dark : undefined,
                display: 'inline-flex',
                justifyContent: 'flex-start',
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'recipientNames',
      header: 'Recipients',
      Cell: ({ cell }) => (
        <Box sx={{ maxWidth: 300 }}>
          <Grid container spacing={1}>
            {cell.getValue<string[]>().map((name, index) => (
              <Grid item key={index}>
                <Chip
                  label={`#${name}`}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    color: theme.palette.text.primary,
                    height: 24,
                  }}
                  size="small"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ),
    },
    {
      accessorKey: 'sentBy',
      header: 'Sent by',
      Cell: ({ row }) => {
        const { fullName, avatarKey } = row.original.sentBy as SentBy;
        const avatarUrl = avatarKey ? CloudinaryUrl(avatarKey, 30, 30) : undefined;
        return (
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar
              src={avatarUrl}
              sx={{
                bgcolor: theme.palette.secondary.main,
                fontSize: 12,
                height: 24,
                width: 24,
              }}
            >
              {!avatarUrl && toInitials(fullName)}
            </Avatar>
            <Typography variant="inherit" whiteSpace="nowrap">
              {fullName || 'Unknown'}
            </Typography>
          </Stack>
        );
      },
    },
    {
      accessorKey: 'sentAt',
      header: 'Send Time',
      Cell: ({ cell }) => dayjs(cell.getValue<string>()).format('MMM D, YYYY, h:mm A'),
    },
  ];

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <Typography variant="body2" color="error">
          Error loading announcements.
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      {announcements.length > 0 ? (
        <MaterialReactTable
          columns={columns}
          data={announcements}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          initialState={{
            sorting: [
              {
                id: 'sentAt',
                desc: true,
              },
            ],
          }}
          muiTableBodyRowProps={{
            sx: {
              '&:hover > td::after': { bgcolor: 'white' },
              bgcolor: 'white',
              height: 60,
            },
          }}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight={200}
          border="1px solid"
          borderColor="grey.300"
          borderRadius={1}
          bgcolor="white"
          p={5}
        >
          <MegaphoneSimple size={48} color={theme.palette.primary.main} />
          <Typography variant="h4" mt={2}>
            No announcements sent yet.
          </Typography>
          {isCollectionEnded ? (
            <Typography color="textSecondary" mt={2}>
              This collection is in the past and cannot be announced.
              <br/>Announcements are only available for current collections.
            </Typography>
          ) : (
            <Typography color="textSecondary" mt={2}>
              Let guests know about your collection by sending an announcement on Slack.
            </Typography>
          )}
        </Box>
      )}
    </div>
  );
};

export default CollectionAnnouncements;