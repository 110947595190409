import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar as MuiAvatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Stack,
} from '@mui/material';
import { MapPin } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import Avatar from 'components/Core/Avatar';
import Search from 'components/Core/Search';
import SlackSyncedGroupAlert from 'components/Groups/SlackSyncedGroupAlert';
import TabLabelWithCount from 'components/shared/TabLabelWithCount';
import { axiosAuthenticated as axios } from 'utils/axios';
import { fullName } from 'utils/employee';

const GroupSideMenu = ({
  group,
  handleJoinGroup,
  handleLeaveGroup,
  org,
  user,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const isAdmin =
    group && group.admin_employees?.some((emp) => emp.user_id === user.id);

  const canEdit =
    user.is_admin ||
    isAdmin ||
    (group.can_organizers_edit && user.is_organizer);

  const isMember =
    group && group.member_employees?.some((emp) => emp.user_id === user.id);

  const isPartOfGroup = isAdmin || isMember;

  const offices = useMemo(
    () => org.offices.filter((o) => group.office_ids.includes(o.id)),
    [group.office_ids, org.offices]
  );

  const filteredAdmins = useMemo(() => {
    const searchAdmins = group.admin_employees?.filter((e) =>
      fullName(e)?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    return searchAdmins?.map((e, i) => (
      <ListItem key={i} sx={{ px: 0 }}>
        <ListItemAvatar>
          <Avatar
            data-testid='TODO:DATA-AVATAR-37570'
            employeeId={+e?.id}
            height={32}
            width={32}
          >
            {e.initials}
          </Avatar>
        </ListItemAvatar>
        <ListItemText id={`admin-${i}`} primary={fullName(e)} />
      </ListItem>
    ));
  }, [searchTerm, group]);

  const filteredMembers = useMemo(() => {
    const searchMembers = group.member_employees?.filter((e) =>
      fullName(e)?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    return searchMembers?.map((e, i) => (
      <ListItem key={i} sx={{ px: 0 }}>
        <ListItemAvatar>
          <Avatar
            data-testid='TODO:DATA-AVATAR-37330'
            employeeId={+e?.id}
            height={32}
            width={32}
          >
            {e.initials}
          </Avatar>
        </ListItemAvatar>
        <ListItemText id={`member-${i}`} primary={fullName(e)} />
      </ListItem>
    ));
  }, [searchTerm, group]);

  const isSlackSynced = useMemo(
    () => group?.synced_slack_channel != null,
    [group]
  );

  const joinGroup = useCallback(() => {
    axios({ method: 'post', url: `/api/groups/${group.id}/join` }, (res) =>
      handleJoinGroup(res.data)
    );
  }, [group.id, handleJoinGroup]);

  const leaveGroup = useCallback(() => {
    axios({ method: 'post', url: `/api/groups/${group.id}/leave` }, (res) =>
      handleLeaveGroup(res.data)
    );
  }, [group.id, handleLeaveGroup]);

  const editButton = useMemo(
    () => (
      <Button
        color='primary'
        data-testid='edit-group'
        onClick={() => navigate(`/group/${group?.id}/edit`)}
        sx={{ flexGrow: 1 }}
        variant='bordered'
      >
        Edit group
      </Button>
    ),
    [group?.id, navigate]
  );

  const membershipButton = useMemo(
    () =>
      isPartOfGroup ? (
        <Button
          color='secondary'
          data-testid='leave-group'
          onClick={(e) => leaveGroup(e)}
          sx={{ flexGrow: 1 }}
          variant='bordered'
        >
          Leave group
        </Button>
      ) : (
        <Button
          color='primary'
          data-testid='join-group'
          onClick={(e) => joinGroup(e)}
          sx={{ flexGrow: 1 }}
          variant='contained'
        >
          Join group
        </Button>
      ),
    [isPartOfGroup, joinGroup, leaveGroup]
  );

  return (
    <Stack direction='column' gap={2}>
      <Stack direction='row' flexWrap='wrap' gap={2}>
        {isSlackSynced ? (
          <>
            <SlackSyncedGroupAlert
              channelName={group?.synced_slack_channel?.slack_channel_name}
              isPrivate={group?.synced_slack_channel?.is_private}
            />
            {canEdit && editButton}
          </>
        ) : (
          <>
            {canEdit && editButton}
            {membershipButton}
          </>
        )}

        {group.external_link && (
          <Button
            data-testid='learn-more'
            href={group.external_link}
            rel='noopener noreferrer'
            sx={{ flexGrow: 1 }}
            target='_blank'
            variant='outlined'
          >
            Learn more
          </Button>
        )}
      </Stack>
      <Card
        data-testid='group-members-card'
        sx={{ position: 'sticky', top: 0 }}
      >
        <CardHeader
          sx={{ pb: 0 }}
          title='Members'
          titleTypographyProps={{ variant: 'h4' }}
        />
        <CardContent sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Search
            fullWidth
            label='Search members'
            mb={2}
            onChange={handleChange}
            placeholder='Search Members'
            value={searchTerm}
          />
          <List disablePadding>
            <ListSubheader
              disableGutters
              sx={{ bgcolor: 'white', py: 1, top: -16 }}
            >
              <TabLabelWithCount
                count={group?.admin_employee_ids?.length}
                label='Admins'
                labelProps={{ variant: 'h5' }}
              />
            </ListSubheader>
            {filteredAdmins}
            <ListSubheader
              disableGutters
              sx={{ bgcolor: 'white', py: 1, top: -16 }}
            >
              <TabLabelWithCount
                count={group?.employee_ids?.length}
                label='Members'
                labelProps={{ variant: 'h5' }}
              />
            </ListSubheader>
            {filteredMembers}
            {offices?.length > 0 && (
              <>
                <ListSubheader
                  disableGutters
                  sx={{ bgcolor: 'white', py: 1, top: -16 }}
                >
                  <TabLabelWithCount
                    count={offices?.length}
                    label='Offices'
                    labelProps={{ variant: 'h5' }}
                  />
                </ListSubheader>
                {offices?.map((o, i) => (
                  <ListItem key={i} sx={{ px: 0 }}>
                    <ListItemAvatar>
                      <MuiAvatar sx={{ height: 32, width: 32 }}>
                        <MapPin />
                      </MuiAvatar>
                    </ListItemAvatar>
                    <ListItemText primary={o.name} />
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </CardContent>
      </Card>
    </Stack>
  );
};

GroupSideMenu.propTypes = {
  group: PropTypes.shape({
    admin_employee_ids: PropTypes.array,
    admin_employees: PropTypes.array,
    can_organizers_edit: PropTypes.any,
    employee_ids: PropTypes.array,
    external_link: PropTypes.string,
    id: PropTypes.any,
    member_employees: PropTypes.array,
    office_ids: PropTypes.array,
    synced_slack_channel: PropTypes.shape({
      is_private: PropTypes.bool,
      slack_channel_name: PropTypes.string,
    }),
  }),
  handleJoinGroup: PropTypes.func,
  handleLeaveGroup: PropTypes.func,
  org: PropTypes.shape({ offices: PropTypes.array }),
  setTab: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.any,
    is_admin: PropTypes.bool,
    is_organizer: PropTypes.bool,
  }),
};

export default GroupSideMenu;
