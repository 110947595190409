import React from 'react';
import { Stack } from '@mui/system';

import { ProfileIntegrationService } from 'components/Profile/ProfileIntegrationService';

interface Props {
  conditional: boolean;
  items: string[] | undefined;
}

const ProfileIntegrationServices = ({ conditional, items }: Props) =>
  conditional ? (
    <Stack direction='column' gap={0.5}>
      {items?.map((name, index) => (
        <ProfileIntegrationService key={index} text={name} />
      ))}
    </Stack>
  ) : null;

export default ProfileIntegrationServices;
