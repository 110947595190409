import React from 'react';
import { Alert, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { SlackLogo } from '@phosphor-icons/react';

import SlackChannelLabel from 'components/shared/SlackChannelLabel';

interface Props {
  channelName: string;
  isPrivate: boolean;
}

const SlackSyncedGroupAlert: React.FC<Props> = ({ channelName, isPrivate }) => {
  const theme = useTheme();
  return (
    <Alert icon={false} severity='info' sx={{ borderLeftStyle: 'none' }}>
      <Stack direction='row' spacing={1.5}>
        <Stack sx={{ paddingTop: '4px' }}>
          <SlackLogo color={theme.palette.info.dark} size={20} />
        </Stack>
        <Stack spacing={0.5}>
          <Typography color='info.dark' variant='h4'>
            Members synced from Slack
          </Typography>
          <Stack spacing={1.25}>
            <Typography color='grey.900' variant='body1'>
              Membership can be managed by joining or leaving the synced Slack
              channel.
            </Typography>
            <Stack direction='row'>
              <SlackChannelLabel
                chipColors={{
                  backgroundColor: 'rgba(30, 136, 229, 0.10)',
                  textColor: '#0D47A1',
                }}
                isChip
                isPrivate={isPrivate}
                name={channelName}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Alert>
  );
};

export default SlackSyncedGroupAlert;
