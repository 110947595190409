import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/Core/Dropdown';
import { ConditionalLoader } from 'components/Core/Loader';
import { GroupContext } from 'contexts/GroupContext';

const GroupType = ({ organization, setField }) => {
  const { dispatch, state: group } = useContext(GroupContext);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const opts = organization?.group_types
      .sort((a, b) => a?.name?.localeCompare(b?.name))
      .map((o) => ({ id: o?.id, label: o?.name, value: o?.id }));
    setOptions(opts?.length > 0 ? opts : []);
  }, [organization?.group_types]);

  useEffect(() => {
    if (group?.group_type_id > 0 && options?.length > 0) {
      const opt = options?.find((o) => o?.id === group?.group_type_id);
      setSelectedOption(opt?.id ? opt : null);
    } else if (!group?.group_type_id) {
      setSelectedOption(null);
    }
  }, [group?.group_type_id, options]);

  return (
    <ConditionalLoader
      conditions={[!group, options?.length < 1, options === undefined]}
    >
      <Dropdown
        data-testid='group-type'
        dispatch={dispatch}
        field='group_type_id'
        label='Type'
        options={options}
        placeholder='Select group type'
        selectedOption={selectedOption}
        setter={setField}
      />
    </ConditionalLoader>
  );
};

GroupType.propTypes = {
  organization: PropTypes.shape({
    group_types: PropTypes.array,
  }),
  setField: PropTypes.func,
};

export default GroupType;
