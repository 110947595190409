import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Alert, Switch } from '@mui/material';
import { Info, PauseCircle } from '@phosphor-icons/react';

import SummaryField from 'components/shared/SummaryField';
import { EventDetailsFormControl } from 'types/EventDetailsForm';

interface EventRsvpPausingFieldProps {
  control: EventDetailsFormControl;
  defaultValue: boolean;
  disableFields: boolean;
  isLoading?: boolean;
}

const EventRsvpPausingField = ({
  control,
  defaultValue,
  disableFields,
  isLoading = false,
}: EventRsvpPausingFieldProps) => {
  const [pendingValue, setPendingValue] = useState(defaultValue);

  return (
    <Controller
      control={control}
      name='rsvpPauseAllowed'
      render={({ field }) => (
          <SummaryField
            component={
              <Alert
                icon={<Info size={20} />}
                severity='info'
                sx={{
                  '& .MuiAlert-icon': { p: 0 },
                  '& .MuiAlert-message': { p: 0 },
                  '& svg': { fill: (theme) => theme.palette.info.dark },
                  alignItems: 'flex-start',
                  borderLeft: 'none',
                  color: 'info.dark',
                  p: 1.5,
                }}
              >
                If this option is enabled, invitations can only be sent via
                email. This cannot be changed once your event is published.
              </Alert>
            }
            hasChanges={field.value !== pendingValue}
            headerAction={
              <Switch
                checked={pendingValue}
                data-testid='rsvp-pausing'
                disabled={disableFields}
                onChange={(e, checked) => {
                  setPendingValue(checked);
                }}
              />
            }
            Icon={PauseCircle}
            isLoading={isLoading}
            onCancel={() => setPendingValue(field.value || false)}
            onSubmit={() => !disableFields && field.onChange(pendingValue)}
            primary='RSVP pausing'
            secondary='Have the ability to pause RSVPs and stop accepting new guests to your event after it is published'
            type='dialog'
            value={field.value ? 'On' : 'Off'}
            valueType='text'
          />
        )}
    />
  );
};

export default EventRsvpPausingField;
