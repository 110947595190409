import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';

import RadioSelect from 'components/Core/RadioSelect';
import EventVirtualMeetingLinkField from 'components/Event/EventVirtualMeetingLinkField';
import { useSaveGoogleAuth } from 'components/Events/Hooks/Actions';
import { InlineButton } from 'components/shared/InlineButton';
import { knownMeetingServices } from 'constants/integration.constants';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useZoomAuth from 'hooks/useZoomAuth';
import {
  EventDetailsFormControl,
  EventDetailsFormMeetingData,
  EventDetailsFormRegister,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';
import { axiosAuthenticated as axios } from 'utils/axios';
import { useGoogleCalendarLogin } from 'utils/google';
import { useDelayedLoading } from 'utils/utils';

interface EventVirtualMeetingFieldProps {
  control: EventDetailsFormControl;
  disableFields: boolean;
  register: EventDetailsFormRegister;
  watch: EventDetailsFormWatch;
}

const EventVirtualMeetingField = ({
  control,
  disableFields,
  watch,
}: EventVirtualMeetingFieldProps) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;
  const [organization] = useContext(OrganizationContext);

  const meeting: EventDetailsFormMeetingData | undefined = watch('meeting');

  const { endTask, startTask } = useDelayedLoading();
  const { enqueueSnackbar } = useSnackbar();

  const [isWorking, setIsWorking] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | null>(
    meeting?.service || null
  );

  useEffect(
    () => setSelectedValue(meeting?.service || null),
    [meeting?.service]
  );

  const { canEnableZoomWebinar, connectToZoom, isZoomWebinarConnected } =
    useZoomAuth({
      initialUrl: window.location.pathname,
    });

  const connectedServices = useMemo(
    () => [
      ...(user?.has_google_calendar_auth ? ['google_meet'] : []),
      ...(user?.has_zoom_meeting_auth ? ['zoom_meeting'] : []),
      ...(isZoomWebinarConnected ? ['zoom_webinar'] : []),
    ],
    [isZoomWebinarConnected, user]
  );

  const generateTempZoom = useCallback(
    (onChange: any) => {
      startTask();
      !isWorking && setIsWorking(true);
      axios(
        {
          method: 'post',
          url: '/api/zoom',
        },
        ({ data }: AxiosResponse) => {
          if (data.zoom_meeting) {
            onChange({
              attendanceTracking: true,
              link: data.zoom_meeting.url,
              service: 'zoom_meeting',
              serviceUid: data.zoom_meeting.id,
            });
          }
          setIsWorking(false);
          endTask();
        },
        (err: AxiosError) => {
          enqueueSnackbar(
            "We couldn't generate a Zoom meeting for you. Please try again.",
            { variant: 'error' }
          );
          setIsWorking(false);
          endTask();
          console.error(err);
        }
      );
    },
    [endTask, enqueueSnackbar, isWorking, startTask]
  );

  const changeMeetingService = useCallback(
    (newService: string | null, onChange: any) => {
      onChange({
        attendanceTracking: newService === 'zoom_webinar',
        link: newService === 'unknown' ? undefined : null,
        service: newService,
        serviceUid: null,
      });
    },
    []
  );

  const saveGoogleAuth = useSaveGoogleAuth(
    noop,
    noop,
    () => {
      enqueueSnackbar('Your Google account has been successfully connected.', {
        variant: 'success',
      });
    },
    (e: any) => {
      enqueueSnackbar(
        e?.response?.data?.errors === 'missing scopes'
          ? 'We were unable to connect to your calendar. Please try again and ensure calendar permissions are selected.'
          : 'We were unable to connect to your Google account. Please try again later.',
        {
          variant: 'error',
        }
      );
    }
  );

  const startGoogleLogin = useGoogleCalendarLogin(
    saveGoogleAuth,
    undefined,
    organization
  );

  const authorizeService = (id: string) => {
    switch (id) {
      case 'zoom_meeting':
      case 'zoom_webinar':
        connectToZoom();
        break;
      case 'google_meet':
        startGoogleLogin();
        break;
    }
  };
  const [customLink, setCustomLink] = useState('');

  useEffect(() => {
    if (meeting?.link && meeting.service === 'unknown') {
      setCustomLink(meeting.link);
    }
  }, [meeting]);

  const updateSelection = (v: string | null, field: any) => {
    setSelectedValue(v);
    switch (v) {
      case 'zoom_meeting':
        generateTempZoom(field.onChange);
        break;
      case 'unknown':
        setCustomLink(field.value.link || '');
        changeMeetingService(v, field.onChange);
        break;
      default:
        changeMeetingService(v, field.onChange);
        break;
    }
  };

  const handleCustomLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomLink(e.target.value);
  };

  const handleCustomLinkBlur = (onChange: any) => {
    onChange({
      ...meeting,
      link: customLink,
    });
  };

  const filteredKnownMeetingServices = canEnableZoomWebinar
    ? knownMeetingServices
    : knownMeetingServices.filter((service) => service.id !== 'zoom_webinar');

  return (
    <Controller
      control={control}
      name='meeting'
      render={({ field }) => (
        <RadioSelect
          disabled={disableFields}
          label={null}
          onChange={(v: string | null) => updateSelection(v, field)}
          options={[
            ...filteredKnownMeetingServices.map(
              ({ authService, id, logo, name }) => ({
                component: connectedServices?.includes(id) ? undefined : (
                  <Typography
                    color='text.primary'
                    component='div'
                    lineHeight={1.75}
                    mt={0.5}
                    variant='overline'
                    whiteSpace='normal'
                  >
                    Set up{' '}
                    <InlineButton onClick={() => authorizeService(id)}>
                      {authService}
                    </InlineButton>{' '}
                    to automatically create link
                  </Typography>
                ),
                disable: !connectedServices?.includes(id),
                iconProps: {
                  size: 20,
                  src: logo,
                  type: 'avatar',
                  variant: 'square',
                },
                id,
                label: `Generate ${name} link`,
                value: id,
              })
            ),
            {
              component: (
                <>
                  {selectedValue === 'unknown' ? (
                    <EventVirtualMeetingLinkField
                      disableFields={disableFields}
                      onBlur={() => handleCustomLinkBlur(field.onChange)}
                      onChange={handleCustomLinkChange}
                      value={customLink}
                    />
                  ) : null}
                </>
              ),
              id: 'unknown',
              label: 'Add your own meeting link',
              value: 'unknown',
            },
          ]}
          showComponentWhenDisabled
          sx={{ mb: 0 }}
          value={selectedValue}
          variant='boxed'
        />
      )}
    />
  );
};

export default EventVirtualMeetingField;
