import React from 'react';
import { CardHeader, CardHeaderProps } from '@mui/material';

import { flexColumn } from 'utils/styles';

const RsvpCardHeader = (props: CardHeaderProps) => (
  <CardHeader
    {...props}
    sx={{
      '& .MuiCardHeader-content': { ...flexColumn, gap: 2 },
      pb: 1,
    }}
    titleTypographyProps={{ variant: 'h4' }}
  />
);

export default RsvpCardHeader;
