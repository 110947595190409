import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import CSVUploadModal from 'components/Core/CSVUploadModal';
import Search from 'components/Core/Search';
import UserInvitationForm from 'components/Settings/UserInvitationForm';
import UsersInvitedTable from 'components/Settings/UsersInvitedTable';
import UsersTable from 'components/Settings/UsersTable';
import useUsers from 'hooks/useUsers';
import { searchUser } from 'utils/settingsSearch';

const ViewTypes = [
  {
    id: 0,
    name: 'Active',
    value: 'Active',
  },
  {
    id: 2,
    name: 'Invited',
    value: 'Invited',
  },
  {
    id: 1,
    name: 'Deactivated',
    value: 'Deactivated',
  },
];

const UserSettings = ({ organization }) => {
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isUploadingCsv, setIsUploadingCsv] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userStatusFilter, setUserStatusFilter] = useState(ViewTypes[0].value);

  const {
    retrieve: { data: userData, isPending, refetch },
    updateRole,
    updateStatus,
  } = useUsers();

  const officesById = useMemo(
    () => (organization ? _.keyBy(organization.offices, 'id') : []),
    [organization]
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.currentTarget?.value || '');
  };

  const users = useMemo(
    () =>
      userData
        ?.filter((user) => searchUser(user, searchTerm))
        ?.map(
          ({ email, full_name, id, office_id, permission_level, state }) => ({
            email,
            id,
            name: full_name,
            office: officesById[office_id]?.name || '',
            role: permission_level,
            status: state,
          })
        ),
    [userData, officesById, searchTerm]
  );

  const formatUploadData = (
    employeesToAdd,
    eventId,
    saveAsSegment,
    segmentName
  ) => ({
    invitation_mapping: {
      invites: employeesToAdd,
      save_as_segment: saveAsSegment,
      segment_name: _.trim(segmentName),
      sign_up_URL: `${window.location.origin}/sign-up`,
    },
  });

  const closeUserForm = () => {
    setIsAddingUser(false);
  };

  return (
    <>
      <Stack alignItems='stretch' direction='column' gap={2}>
        <Stack
          alignItems='center'
          direction={{ md: 'row', xs: 'column' }}
          gap={2}
          width='100%'
        >
          <Stack alignItems='center' direction='row' gap={2}>
            <Search
              label='Search users'
              onChange={handleSearchChange}
              placeholder='Search users'
              value={searchTerm}
            />
            <ToggleButtonGroup
              exclusive
              onChange={(e, val) => setUserStatusFilter(val)}
              value={userStatusFilter}
            >
              {ViewTypes.map((opt) => (
                <ToggleButton
                  data-testid={`user-status-${opt.name}`}
                  key={opt.id}
                  value={opt.value}
                >
                  {opt.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <Stack
            direction='row'
            flexGrow={1}
            flexShrink={0}
            gap={1.5}
            justifyContent={{ md: 'flex-end', xs: 'center' }}
          >
            <Button onClick={() => setIsUploadingCsv(true)} variant='contained'>
              Upload users
            </Button>
            <Button onClick={() => setIsAddingUser(true)} variant='contained'>
              Invite user
            </Button>
          </Stack>
          <CSVUploadModal
            dataFormatter={(
              employeesToAdd,
              eventId,
              saveAsSegment,
              segmentName
            ) =>
              formatUploadData(
                employeesToAdd,
                eventId,
                saveAsSegment,
                segmentName
              )
            }
            displayExtra
            file='/files/Sample CSV - User Upload.csv'
            instruction='Upload CSV containing invitee name, email, and role in separate columns.'
            onClose={() => setIsUploadingCsv(false)}
            onUpload={() => refetch()}
            show={isUploadingCsv}
            showRoleColumn
            toastMessage='Your invites were sent.'
            uploadType='user'
            url='/api/invitation_mappings/upload'
          />
        </Stack>
        {['Active', 'Deactivated'].includes(userStatusFilter) ? (
          <UsersTable
            handleUserStatus={updateStatus}
            handleUserUpdate={updateRole}
            isLoading={isPending}
            organization={organization}
            users={users || []}
            userStatus={
              userStatusFilter === 'Active' ? 'activated' : 'deactivated'
            }
          />
        ) : null}
        {userStatusFilter === 'Invited' && (
          <UsersInvitedTable
            isLoading={isPending}
            organization={organization}
            searchTerm={searchTerm}
          />
        )}
      </Stack>
      <Dialog
        data-testid='invite-user'
        fullWidth
        maxWidth='sm'
        onClose={closeUserForm}
        open={isAddingUser}
      >
        <UserInvitationForm
          closeDialog={closeUserForm}
          organization={organization}
        />
      </Dialog>
    </>
  );
};

UserSettings.propTypes = {
  organization: PropTypes.shape({
    offices: PropTypes.any,
  }),
};

export default UserSettings;
