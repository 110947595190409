import { useCallback, useContext, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { GroupsContext } from 'contexts/GroupsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useGetEmployeesForOptions from 'hooks/useGetEmployeesForOptions';
import { fullName } from 'utils/employee';

const useDisplayedHostSelections = (
  displayableHosts,
  inPublicEventContext = false
) => {
  const [groups] = useContext(GroupsContext);
  const [organization] = useContext(OrganizationContext);
  const queryClient = useQueryClient();

  const { getEventHosts } = useGetEmployeesForOptions();

  const event_host_employees = getEventHosts.data;

  const refreshEmployeeOptions = useCallback(() => {
    queryClient.invalidateQueries(['event_host_options']);
    getEventHosts.refetch();
  }, [queryClient, getEventHosts]);

  return useMemo(() => {
    const mapOptions = (options, optionDisplayableType) => {
      const officeType = optionDisplayableType === 'Offices';

      return options?.map((option) => {
        const officeName =
          officeType &&
          (!option.name.toLowerCase().includes('office') ||
            !option.name.toLowerCase().includes('headquarter'))
            ? option.name + ' Office'
            : option.name;

        return {
          label: officeType ? officeName : option.name,
          type: optionDisplayableType,
          value: option.id,
        };
      });
    };
    const employeeOptions =
      event_host_employees
        ?.map((o) => ({
          additional: o.initials,
          id: o.id,
          label: fullName(o),
          type: 'Organizers',
          value: o.id,
          withAvatar: true,
        }))
        ?.sort((a, b) =>
          `${a.label} ${a.secondary}`?.localeCompare(
            `${b.label} ${b.secondary}`
          )
        ) || [];

    const mappedGroups = mapOptions(groups, 'Groups') || [];

    const mappedOffices = mapOptions(organization?.offices, 'Offices') || [];

    const allOptionsToSelect = [
      ...mappedGroups,
      ...mappedOffices,
      ...employeeOptions,
    ];

    const displaySelections = inPublicEventContext
      ? displayableHosts?.flatMap(({ names }) => names)
      : allOptionsToSelect.filter((option) =>
          displayableHosts?.some(
            (displayable) =>
              displayable.ids.includes(option.value) &&
              displayable.type === option.type
          )
        ) || [];

    return {
      allDisplayedHostOptions: allOptionsToSelect,
      allDisplayedHostSelections: displaySelections,
      employeeOptions,
      refreshEmployeeOptions,
    };
  }, [
    event_host_employees,
    displayableHosts,
    groups,
    inPublicEventContext,
    organization?.offices,
    refreshEmployeeOptions,
  ]);
};

export default useDisplayedHostSelections;
