import React, { useContext, useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

import Dropdown from 'components/Core/Dropdown';
import { AuthContext } from 'contexts/AuthContext';
import { GroupContext } from 'contexts/GroupContext';

const GroupAdmins = ({ group_admin_employees, setField }) => {
  const { state: group } = useContext(GroupContext);

  const {
    userProvider: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    if (group.admin_employee_ids?.length > 0) {
      // INFO:
      // This only sets the field when editing a group.
      // When a new group is created, this field is set in the GroupCreationPage component.
      setField('group_admin_ids', group?.admin_employee_ids);
    }
    setField('organizer_id', user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setField, group?.admin_employee_ids]);

  const onChange = (event) => {
    setField('can_organizers_edit', event.target.checked);
  };

  if (group_admin_employees) {
    const options = group_admin_employees.map((o) => ({
      label: `${o.first_name} ${o.last_name}`,
      value: o.id,
    }));

    const selectedOptions = options.filter((o) =>
      group?.group_admin_ids?.includes(o.value)
    );

    return (
      <>
        <Dropdown
          className='multi'
          data-testid='group-admin'
          field='group_admin_ids'
          isMulti
          label='Admins'
          options={options}
          placeholder='Type to search'
          requireOne
          selectedOption={selectedOptions}
          setter={setField}
        />
        <FormControl component='fieldset' variant='standard'>
          <FormControlLabel
            control={
              <Checkbox
                checked={group.can_organizers_edit}
                data-testid='TODO:DATA-CHECKBOX-64254'
                name='organizer-edit-checkbox'
                onChange={onChange}
              />
            }
            label='Allow any organizer to edit this group'
          />
        </FormControl>
      </>
    );
  } else {
    return <div>No employees</div>;
  }
};

GroupAdmins.propTypes = {
  group_admin_employees: PropTypes.array,
  setField: PropTypes.func,
};

export default GroupAdmins;
