import React from 'react';
import { Chip, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { CheckCircle, WarningCircle } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import {
  PasswordDigitRegex,
  PasswordLengthRegex,
  PasswordLowerRegex,
  PasswordSpecialRegex,
  PasswordUpperRegex,
} from 'utils/utils';

const PasswordStrength = ({ password, show }) => {
  const requirements = [
    {
      label: '8 characters',
      regex: PasswordLengthRegex,
    },
    {
      label: '1 upper',
      regex: PasswordUpperRegex,
    },
    {
      label: '1 lower',
      regex: PasswordLowerRegex,
    },
    {
      label: '1 number',
      regex: PasswordDigitRegex,
    },
    {
      helpText: '!@#$%&*()+?/',
      label: '1 special',
      regex: PasswordSpecialRegex,
    },
  ];
  const theme = useTheme();

  return (
    <Stack
      data-testid='pw-requirements'
      direction='row'
      flexWrap='wrap'
      spacing={1}
      sx={{ flex: 'auto', maxWidth: '100%' }}
    >
      <Typography variant='overline'>Requirements:</Typography>
      {requirements.map(({ helpText, label, regex }) => {
        const pwMeetsRequirement = regex.test(password);
        const iconProps = {
          color: pwMeetsRequirement
            ? theme.palette.success.main
            : theme.palette.error.main,
          size: 18,
          weight: 'fill',
        };
        const RequirementChip = (params) => (
          <Chip
            icon={
              pwMeetsRequirement ? (
                <CheckCircle {...iconProps} />
              ) : (
                <WarningCircle {...iconProps} />
              )
            }
            key={label}
            label={label}
            size='small'
            {...params}
          />
        );
        if (helpText) {
          return (
            <Tooltip
              data-testid='requirement-tooltip'
              key={label}
              title={helpText}
            >
              <RequirementChip />
            </Tooltip>
          );
        } else {
          return <RequirementChip key={label} />;
        }
      })}
    </Stack>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string,
  show: PropTypes.bool,
};

export default PasswordStrength;
