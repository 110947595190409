import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

import EventForm from 'components/Event/EventForm';
import EventToolbar from 'components/Event/EventToolbar';
import useEventFormDefaultValues from 'components/Event/useEventFormDefaultValues';
import useEventFormValues from 'components/Event/useEventFormValues';
import { useNavigateFromGroup } from 'components/Events/Hooks/Generic';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { EventDetailsFormData } from 'types/EventDetailsForm';
import FtnEvent from 'types/FtnEvent';
import { hasStarted } from 'utils/event';

interface EventCreateProps {
  isLoading?: boolean;
  savedEventDetails?: FtnEvent;
}

const EventCreate = ({
  isLoading = false,
  savedEventDetails,
}: EventCreateProps) => {
  const [groups] = useContext(GroupsContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;

  const location = useLocation();

  // Event Form
  const fromGroupId = useNavigateFromGroup(location, groups);
  const defaultValues = useEventFormDefaultValues(fromGroupId || null, user);
  const values = useEventFormValues(savedEventDetails);

  const eventDetailsForm = useForm<EventDetailsFormData>({
    criteriaMode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    values, // These values replace the default values if provided, use this for populating the fields from saved event data
  });

  const disableFields = hasStarted(savedEventDetails);

  return (
    <DefaultLayout
      appBarStyles={{
        borderBottomColor: 'grey.300',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
      }}
      header={
        <Container
          maxWidth='lg'
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography
            color='text.primary'
            component='h1'
            noWrap
            sx={{ flexGrow: 1 }}
            variant='h3'
          >
            {savedEventDetails &&
              `${savedEventDetails?.id ? 'Draft' : 'New'} event`}
          </Typography>
          <EventToolbar
            eventId={savedEventDetails?.id || null}
            formState={eventDetailsForm.formState}
            handleSubmit={eventDetailsForm.handleSubmit}
            isPublished={savedEventDetails?.published || false}
            rsvpPauseActive={savedEventDetails?.rsvp_pause_active || false}
            watch={eventDetailsForm.watch}
          />
        </Container>
      }
    >
      <Container maxWidth='lg'>
        <form>
          <EventForm
            disableFields={disableFields}
            eventId={savedEventDetails?.id}
            hookForm={eventDetailsForm}
            isLoading={isLoading}
            isPublished={savedEventDetails?.published || false}
            meetingCreator={{
              creatorId: savedEventDetails?.meeting?.creator_id,
              creatorName: savedEventDetails?.meeting?.creator_name,
              isSaved: savedEventDetails?.meeting?.link != null,
            }}
          />
        </form>
      </Container>
    </DefaultLayout>
  );
};

export default EventCreate;
