import React, { useCallback, useMemo, useState } from 'react';
import { Button, Popover, Stack, TextField } from '@mui/material';
import { Link } from '@phosphor-icons/react';
import { Editor } from '@tiptap/react';

import RichTextToolbarButton from 'components/shared/RichTextToolbarButton';
import { ValidateUrl } from 'utils/utils';

const RichTextLink = ({ editor }: { editor: Editor }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [text, setText] = useState<string | null>(null);
  const [url, setUrl] = useState<string | null>(null);

  const popoverId = 'toolbar-link-popover';

  const handleClick = (event: any) => {
    let text = '';
    if (editor.isActive('link')) {
      // cursor is within linked text
      if (editor?.state?.selection?.from === editor?.state?.selection?.to) {
        // no characters are selected
        editor.chain().focus().extendMarkRange('link').run();
        // select all surrounding (linked) characters
      }
    }
    if (editor?.state?.selection) {
      text = editor?.state?.doc?.textBetween(
        editor?.state?.selection?.from,
        editor?.state?.selection?.to,
        ' '
      );
    }
    setUrl(editor.getAttributes('link').href || '');
    setSelectedText(text);
    setText(text);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsInvalid(false);
  };

  const open = Boolean(anchorEl);
  const id = useMemo(() => (open ? popoverId : undefined), [open, popoverId]);

  const setLink = useCallback(() => {
    // cancelled
    if (url === null) {
      setIsInvalid(false);
      return;
    }

    // empty
    if (url === '') {
      setIsInvalid(false);
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    if (url) {
      if (ValidateUrl(url) || ValidateUrl(`http://${url}`)) {
        // update link
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url.indexOf('http') === -1 ? `http://${url}` : url })
          .run();
        if (selectedText !== text) {
          // text changed, update in editor
          editor
            .chain()
            .focus()
            .insertContentAt(
              {
                from: editor?.state?.selection?.from,
                to: editor?.state?.selection?.to,
              },
              text
            )
            .run();
        }
        handleClose();
      } else {
        setIsInvalid(true);
      }
    }
  }, [editor, selectedText, text, url]);

  return (
    <>
      <RichTextToolbarButton
        aria-describedby={id}
        disabled={!editor.can().chain().focus().extendMarkRange('link')}
        onClick={handleClick}
        onMouseDown={(e) => e.preventDefault()}
        selected={editor?.isActive('link') || open}
        value='link'
      >
        <Link data-testid='TODO:DATA-LINK-65211' />
      </RichTextToolbarButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id={id}
        onClose={handleClose}
        open={open}
      >
        <Stack direction='column' gap={1} pb={2} pt={1} px={2}>
          <TextField
            label='Link text'
            onChange={(e: any) => setText(e.target.value)}
            onFocus={(e: any) => e.preventDefault()}
            value={text}
          />
          <TextField
            error={isInvalid}
            helperText={isInvalid ? 'Please enter a valid URL' : null}
            label='Link URL'
            onChange={(e: any) => setUrl(e.target.value)}
            onFocus={(e: any) => e.preventDefault()}
            type='url'
            value={url}
          />
          <Stack direction='row' gap={1} justifyContent='flex-end'>
            {editor.isActive('link') ? (
              <Button
                data-testid='unlink'
                onClick={() => {
                  editor.chain().focus().unsetLink().run();
                  handleClose();
                }}
                size='small'
              >
                Unlink
              </Button>
            ) : null}
            <Button
              data-testid='save'
              onClick={setLink}
              size='small'
              variant='outlined'
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default RichTextLink;
