import React from 'react';
import { Stack } from '@mui/material';

import EventBudgetOverview from 'components/Event/Budget/EventBudgetOverview';
import EventBudgetSettings from 'components/Event/Budget/EventBudgetSettings';
import EventExpenseTracker from 'components/Event/Budget/EventExpenseTracker';
import EventGroupChips from 'components/Event/Budget/EventGroupChips';
import SectionWrapper from 'components/shared/SectionWrapper';

const EventBudget = () => (
  <Stack direction='column' gap={3}>
    <SectionWrapper action={<EventBudgetSettings />} title='Overview'>
      <EventGroupChips />
      <EventBudgetOverview />
    </SectionWrapper>
    <EventExpenseTracker />
  </Stack>
);

export default EventBudget;
