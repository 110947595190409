import React from 'react';
import { Typography } from '@mui/material';

import Head from 'components/Core/Head';

const OutlookLoginRedirector = () => (
  <>
    <Head title='Outlook integration' />
    <Typography>Sending you back to Five To Nine...</Typography>
  </>
);

export default OutlookLoginRedirector;
