import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import AutocompleteMenu from 'components/shared/AutocompleteMenu';

const Filters = ({
  areCombined = false,
  children,
  filters,
  flexGrow = 1,
  handleClearAll,
}) => {
  const [clearAll, setClearAll] = useState(0);
  const [filterCounts, setFilterCounts] = useState({});

  const updateCounts = ({ label, value }) => {
    const count = typeof value === 'string' ? 1 : value?.length;
    const updatedCounts = Object?.assign(filterCounts, {
      [label]: count,
    });
    setFilterCounts(updatedCounts);
  };

  return (
    <Stack
      alignItems='center'
      columnGap={1}
      direction='row'
      flexGrow={flexGrow}
      flexWrap='wrap'
      rowGap={0.5}
    >
      {children}
      {filters !== null && (
        <>
          {filters?.map(
            ({
              defaultValueId,
              handleChange,
              label,
              manuallySorted,
              options,
              pluralLabel,
              type = 'multi',
            }) => (
              <AutocompleteMenu
                clearAll={clearAll}
                defaultValueId={defaultValueId}
                handleChange={(props) => {
                  updateCounts(props);
                  handleChange(
                    // eslint-disable-next-line react/prop-types
                    props.data
                      ? props
                      : areCombined
                      ? // eslint-disable-next-line react/prop-types
                        props?.value
                      : // eslint-disable-next-line react/prop-types
                        props?.value?.map((v) => v.id)
                  );
                }}
                key={`${label}-filter-menu`}
                label={label}
                manuallySorted={manuallySorted}
                options={options}
                pluralLabel={pluralLabel || pluralize(label)}
                type={type}
              />
            )
          )}
          {Object?.values(filterCounts)?.some((count) => count > 0) && (
            <Button
              data-testid='clear-filters'
              onClick={() => {
                setClearAll(clearAll + 1);
                handleClearAll && handleClearAll();
              }}
              size='small'
            >
              Clear filters
            </Button>
          )}
        </>
      )}
    </Stack>
  );
};

Filters.propTypes = {
  areCombined: PropTypes.bool,
  children: PropTypes.node,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      defaultValueId: PropTypes.string,
      handleChange: PropTypes.func,
      label: PropTypes.string,
      manuallySorted: PropTypes.bool,
      options: PropTypes.array,
      pluralLabel: PropTypes.string,
      type: PropTypes.oneOf(['multi', 'single']),
    })
  ),
  flexGrow: PropTypes.number,
  handleClearAll: PropTypes.func,
};

export default Filters;
