import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Search from 'components/Core/Search';
import Filters from 'components/shared/Filters';
import useGetEmployeesForOptions from 'hooks/useGetEmployeesForOptions';
import { fullName } from 'utils/employee';

interface CollectionFiltersProps {
  onOrganizerChange: (organizers: string[]) => void;
  onSearchChange: (search: string) => void;
  searchValue: string;
  selectedOrganizers: string[];
  handleClearAll: () => void;
}

const CollectionFilters: React.FC<CollectionFiltersProps> = ({
  onOrganizerChange,
  onSearchChange,
  searchValue,
  selectedOrganizers,
  handleClearAll,
}) => {
  const {
    getCollectionOrganizers: { data: collection_organizer_options },
  } = useGetEmployeesForOptions();


  const organizerOpts = useMemo(() =>
    collection_organizer_options?.map((o: any) => ({
      id: o?.id,
      name: fullName(o),
    }))
  , [collection_organizer_options]);


  const handleOrganizerChange = (value: string[]) => {
    const organizerIds = value;
    onOrganizerChange(organizerIds);
  };

  const handleClearAllFilters = () => {
    onOrganizerChange([]);
    onSearchChange('');
    handleClearAll();
  };

  return (
    <Filters
      filters={[
        {
          handleChange: handleOrganizerChange,
          label: 'Organizer',
          options: organizerOpts || [],
        },
      ]}
      handleClearAll={handleClearAllFilters}
    >
      <Search
        label='Search collections'
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder='Search collections'
        value={searchValue}
      />
    </Filters>
  );
};

CollectionFilters.propTypes = {
  onOrganizerChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.any.isRequired,
  selectedOrganizers: PropTypes.array.isRequired,
  handleClearAll: PropTypes.func.isRequired,
};

export default CollectionFilters;
