import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useGraphQLClient } from 'hooks/useGraphQLClient';

const fetchCollectionAnnouncements = gql`
  query fetchEventsCollectionAnnouncements($eventsCollectionId: ID!) {
    fetchEventsCollectionAnnouncements(eventsCollectionId: $eventsCollectionId) {
      id
      status
      recipientNames
      sentBy {
        fullName
        avatarKey
      }
      sentAt
    }
  }
`;

export interface SentBy {
  fullName: string;
  avatarKey?: string;
}

export interface Announcement {
  id: string;
  status: string;
  sentBy: SentBy;
  sentAt: string;
  recipientNames: string[];
}

export const useFetchCollectionAnnouncements = (eventsCollectionId: string) => {
  const graphqlClient = useGraphQLClient();

  return useQuery<Announcement[]>({
    queryFn: async () => {
      const response = await graphqlClient.request<{ fetchEventsCollectionAnnouncements: Announcement[] }>(
        fetchCollectionAnnouncements,
        { eventsCollectionId }
      );
      return response.fetchEventsCollectionAnnouncements;
    },
    queryKey: ['fetchEventsCollectionAnnouncements', eventsCollectionId],
    enabled: !!eventsCollectionId,
  });
};
