import React, { useContext, useRef } from 'react';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PropTypes from 'prop-types';

import { LayoutContext } from 'contexts/LayoutContext';
import { flexColumn } from 'utils/styles';

const StandaloneSurveyImage = ({ coverImageUrl, event }) => {
  const { isLarge, isMobile } = useContext(LayoutContext);

  const eventTitleRef = useRef();

  return (
    <Grid
      height={{ lg: '100vh' }}
      item
      justifyContent='center'
      lg={6}
      sx={flexColumn}
      textAlign={{ lg: 'right' }}
      xs={12}
    >
      {coverImageUrl && (
        <ImageList
          cols={1}
          gap={0}
          row
          rows={1}
          sx={{
            height: 'auto',
            m: 0,
            maxHeight: { lg: '80vh', xs: '50vh' },
            maxWidth: { lg: '50vw', xs: '100vw' },
          }}
        >
          <ImageListItem
            key='cover-image'
            sx={{
              alignItems: { lg: 'flex-end' },
              borderTopLeftRadius: [6, 0],
              borderTopRightRadius: [6, 0],
              maxHeight: { lg: '80vh', xs: '50vh' },
              maxWidth: { lg: '50vw', xs: '100vw' },
            }}
          >
            <ImageListItemBar
              position='below'
              ref={eventTitleRef}
              title={
                <Typography
                  mr={1}
                  textAlign={{ lg: 'right', xs: 'center' }}
                  variant='h1'
                  whiteSpace='normal'
                >
                  {event?.name}
                </Typography>
              }
            />
            <img
              alt='event-cover'
              loading='lazy'
              src={coverImageUrl}
              srcSet={coverImageUrl}
              style={{
                aspectRatio: '16 / 9',
                borderRadius: 6,
                ...(isLarge ? { objectPosition: 'right' } : {}),
                flexGrow: 'unset',
                marginBottom: isMobile
                  ? 0
                  : `${eventTitleRef?.current?.clientHeight || 57}px`,
                maxWidth: '100%',
                transition: 'background-color 300ms ease-in-out',
                width: isMobile ? '100%' : null,
              }}
            />
          </ImageListItem>
        </ImageList>
      )}
    </Grid>
  );
};

StandaloneSurveyImage.propTypes = {
  coverImageUrl: PropTypes.string,
  event: PropTypes.object,
};

export default StandaloneSurveyImage;
