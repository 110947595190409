import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

import NoResponses from 'components/Events/Controls/Analytics/Charts/NoResponses';
import { SurveyIndividualQuestion } from 'components/Events/Controls/Analytics/SurveyIndividualQuestion';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import RadioLabel from 'components/shared/RadioLabel';

const SurveyIndividualTab = () => {
  const {
    state: { savedEvent, surveyType },
  } = useContext(ManagerContext);
  const [respondent, setRespondent] = useState(
    savedEvent?.[surveyType?.value]?.analytics_data?.unique_responders[0]
  );

  useEffect(() => {
    if (
      savedEvent?.[surveyType?.value]?.analytics_data?.unique_responders
        ?.length > 0
    ) {
      setRespondent(
        savedEvent?.[surveyType?.value]?.analytics_data?.unique_responders[0]
      );
    } else {
      setRespondent(null);
    }
  }, [savedEvent, surveyType]);

  const survey = useMemo(
    () => savedEvent?.[surveyType?.value]?.analytics_data,
    [savedEvent, surveyType?.value]
  );

  const startsPositionAtZero = useMemo(
    () => survey?.questions?.map((q) => q.position).includes(0),
    [survey]
  );

  const isAnonymous = useMemo(
    () => savedEvent?.[surveyType?.value]?.is_anonymous,
    [savedEvent, surveyType]
  );

  const wasAnonymous = useMemo(
    () =>
      isAnonymous === false &&
      survey?.unique_responders?.some((r) => r.isAnonymous === true),
    [isAnonymous, survey?.unique_responders]
  );

  const respondentIndex = useMemo(
    () =>
      survey?.unique_responders?.findIndex(
        (r) => r.submissionId === respondent?.submissionId
      ),
    [respondent?.submissionId, survey?.unique_responders]
  );

  const previousRespondent = useMemo(
    () =>
      respondentIndex > 0
        ? survey?.unique_responders[respondentIndex - 1]
        : null,
    [respondentIndex, survey?.unique_responders]
  );

  const nextRespondent = useMemo(
    () =>
      respondentIndex < survey?.unique_responders?.length
        ? survey?.unique_responders[respondentIndex + 1]
        : null,
    [respondentIndex, survey?.unique_responders]
  );

  return survey?.unique_responders?.length > 0 ? (
    <>
      <Stack
        alignItems={{ md: 'center', xs: 'flex-start' }}
        direction={{ md: 'row', xs: 'column' }}
        justifyContent={{ md: 'space-between', xs: 'flex-start' }}
        p={{ md: 3, xs: 2 }}
        sx={{
          backgroundColor: 'grey.100',
          borderColor: 'grey.300',
          borderRadius: 2,
          borderStyle: `solid`,
          borderWidth: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Autocomplete
          disableClearable
          getOptionLabel={(option) => option.name}
          id='respondent-selector'
          isOptionEqualToValue={(option, value) =>
            option?.submissionId === value?.submissionId
          }
          onChange={(e, value) => setRespondent(value)}
          options={survey?.unique_responders || []}
          renderInput={(params) => (
            <TextField
              helperText={
                isAnonymous || wasAnonymous
                  ? `This survey ${
                      isAnonymous ? 'is' : 'was previously'
                    } anonymous, so${
                      wasAnonymous ? ' some' : ''
                    } respondent names and emails are not displayed.`
                  : null
              }
              label='Respondent'
              sx={{ m: 0, minWidth: 300 }}
              {...params}
            />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} component='li' key={option?.id}>
              <RadioLabel
                primary={option?.name}
                secondary={option?.email?.trim()}
              />
            </MenuItem>
          )}
          value={respondent}
        />
        <Stack
          alignItems='center'
          direction='row'
          gap={1}
          justifyContent='center'
        >
          <IconButton
            data-testid='previous-respondent'
            disabled={!previousRespondent}
            onClick={() => setRespondent(previousRespondent)}
          >
            <CaretLeft />
          </IconButton>
          <TextField
            data-testid='respondent-number'
            InputProps={{
              inputProps: {
                max: survey?.unique_responders?.length,
                min: 1,
                step: 1,
              },
            }}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              if (
                !isNaN(newValue) &&
                newValue - 1 > 0 &&
                newValue <= survey?.unique_responders?.length
              ) {
                setRespondent(survey?.unique_responders[newValue - 1]);
              }
            }}
            sx={{ maxWidth: 60, minWidth: 40 }}
            value={respondentIndex + 1}
          />
          <Typography variant='body1' whiteSpace='nowrap' width={40}>
            of {survey?.unique_responders?.length}
          </Typography>
          <IconButton
            data-testid='next-respondent'
            disabled={!nextRespondent}
            onClick={() => setRespondent(nextRespondent)}
          >
            <CaretRight />
          </IconButton>
        </Stack>
      </Stack>
      {survey?.questions
        ?.sort((a, b) => a.position - b.position)
        ?.map((q, index) => (
          <React.Fragment key={`individual-${index}-${q.questionId}`}>
            {index === 0 ? null : <Divider />}
            <SurveyIndividualQuestion
              displayResponders={!isAnonymous}
              forShort={
                (survey?.options && survey?.options[q.questionId]) || []
              }
              individualResponses={
                (survey?.individual_responses &&
                  survey?.individual_responses[q.questionId]?.filter(
                    (r) => r?.submissionId === respondent?.submissionId
                  )) ||
                []
              }
              isPreEvent={surveyType === 'pre_event_survey'}
              options={
                (survey?.options && survey?.options[q?.questionId]) || []
              }
              question={q}
              startsPositionAtZero={startsPositionAtZero}
              user={respondent}
            />
          </React.Fragment>
        ))}
    </>
  ) : (
    <Box height={200}>
      <NoResponses />
    </Box>
  );
};

export default SurveyIndividualTab;
