import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { UserRolesTable } from 'components/Settings/UserRolesTable';
import useUserInvitations from 'hooks/useUserInvitations';
import { RolesSelectOptions } from 'utils/user';
import { ValidateEmail } from 'utils/utils';

const UserInvitationForm = ({ closeDialog, organization }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [username, setUsername] = useState('');

  const { create } = useUserInvitations();

  const validEmail = useMemo(() => ValidateEmail(username), [username]);

  const inviteUser = () => {
    if (!validEmail) {
      return enqueueSnackbar('You must enter a valid email address', {
        variant: 'error',
      });
    }
    create({ email: username, name, role }, organization);
    closeDialog();
  };

  return (
    <>
      <DialogTitle>Invite a new user</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id='settings-add-user-name'
          label='Name'
          onChange={(e) => setName(e.target.value)}
          placeholder='Eva Employee'
          required
          value={name}
        />
        <TextField
          fullWidth
          id='settings-add-user-email'
          label='Email'
          onChange={(e) => setUsername(e.target.value)}
          placeholder='eva@company.com'
          required
          value={username}
        />
        <Autocomplete
          data-testid='user-role'
          disableClearable
          onChange={(ev, val) => setRole(val)}
          options={RolesSelectOptions}
          renderInput={(params) => (
            <TextField
              fullWidth
              label='Role'
              placeholder='Select role'
              {...params}
            />
          )}
          value={role}
        />
        <Accordion data-testid='role-descriptions'>
          <AccordionSummary
            aria-controls='panel1a-content'
            expandIcon={<CaretDown />}
            id='panel1a-header'
          >
            <Typography>Role descriptions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UserRolesTable />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          data-testid='send-invite'
          disabled={!(name && username && role)}
          onClick={inviteUser}
          variant='contained'
        >
          Send Invite
        </Button>
      </DialogActions>
    </>
  );
};

UserInvitationForm.propTypes = {
  closeDialog: PropTypes.func,
  organization: PropTypes.shape({
    id: PropTypes.any,
  }),
};

export default UserInvitationForm;
