import React from 'react';

import RichTextDisplay from 'components/shared/RichTextDisplay';
import useEventDateTimeRange from 'hooks/useEventDateTimeRange';
interface Props {
  customMessage?: string;
  event: any;
}

const PreviewEmailMessage = ({ customMessage, event }: Props) => {
  const eventDateTimeRange = useEventDateTimeRange(event);

  return (
    <div
      className='wrapper'
      data-body-style='font-size:14px; font-family:arial,helvetica,sans-serif; color:#000000; background-color:#f2f2f2;'
      data-link-color='#636E7B'
    >
      <div
        className='webkit'
        style={{
          alignItems: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <table
          align='center'
          style={{
            borderCollapse: 'collapse',
            borderWidth: 0,
            maxWidth: '100%',
            tableLayout: 'fixed',
            width: '100%',
          }}
          width='100%'
        >
          <tbody style={{ maxWidth: '100%' }}>
            <tr>
              <td
                align='left'
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  padding: '36px 24px 36px 24px',
                  textAlign: 'left',
                }}
                width='100%'
              >
                <table
                  className='module preheader preheader-hide'
                  data-type='preheader'
                  style={{
                    color: 'transparent',
                    display: 'none !important',
                    height: 0,
                    opacity: 0,
                    visibility: 'hidden',
                    width: 0,
                  }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td />
                    </tr>
                  </tbody>
                </table>
                <table
                  className='module'
                  data-muid='0bbc19b6-1e7f-4676-8972-e4728e1face2'
                  data-type='code'
                  style={{ height: 45, tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td
                        align='center'
                        style={{
                          fontSize: '6px',
                          lineHeight: '10px',
                          padding: '0px 0px 0px 0px',
                          textAlign: 'center',
                        }}
                        valign='top'
                      >
                        <img
                          alt=''
                          className='max-width'
                          data-proportionally-constrained='true'
                          data-responsive='true'
                          src='http://cdn.mcauto-images-production.sendgrid.net/bbfd5678dc161eb3/68a5adcf-da93-4866-9ea7-0399e244e1be/740x112.png'
                          style={{
                            color: '#000000',
                            fontFamily: 'Helvetica, arial, sans-serif',
                            fontSize: '16px',
                            height: 'auto !important',
                            maxWidth: '50% !important',
                            minWidth: '300px',
                            textDecoration: 'none',
                            width: '50%',
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className='module'
                  data-muid='4245c81c-faa3-4e79-a620-a4b22530c0ef'
                  data-type='spacer'
                  style={{ tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: '0px 0px 30px 0px' }}> </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className='module'
                  data-muid='3b5cde11-407b-4bf1-91c5-a9f0eabc2aeb'
                  data-type='code'
                  style={{ tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td height='100%' valign='top'>
                        <div
                          style={{
                            color: '#212121',
                            fontFamily: 'inherit',
                            fontSize: '16px',
                            padding: '12px 0px',
                            textAlign: 'inherit',
                          }}
                        >
                          <span style={{ fontFamily: 'inherit' }}>
                            A message from your organizer for{' '}
                            <strong>{event?.name}</strong>:
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className='module'
                  data-muid='1e8d609d-0a16-4688-8a7d-7923c1b2c95e'
                  data-type='code'
                  style={{ tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td height='100%' valign='top'>
                        <table
                          className='html-description'
                          style={{
                            backgroundColor: 'rgba(206, 231, 231, 0.25)',
                            borderLeft: '6px #008290 solid',
                            borderRadius: '6px',
                            padding: '24px',
                            width: '100%',
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: 'top', width: 64 }}>
                                <img
                                  alt='banner'
                                  height='40'
                                  src='http://cdn.mcauto-images-production.sendgrid.net/bbfd5678dc161eb3/f81c36bb-51fd-48de-bad7-2c8469582ca8/120x121.png'
                                  width='40'
                                />
                              </td>

                              <td
                                className='html-description-content'
                                style={{
                                  borderBottomColor: 'initial',
                                  borderBottomStyle: 'initial',
                                  borderBottomWidth: '0px',
                                  borderImageOutset: 'initial',
                                  borderImageRepeat: 'initial',
                                  borderImageSlice: 'initial',
                                  borderImageSource: 'initial',
                                  borderImageWidth: 'initial',
                                  borderLeftColor: 'initial',
                                  borderLeftStyle: 'initial',
                                  borderLeftWidth: '0px',
                                  borderRightColor: 'initial',
                                  borderRightStyle: 'initial',
                                  borderRightWidth: '0px',
                                  borderTopColor: 'initial',
                                  borderTopStyle: 'initial',
                                  borderTopWidth: '0px',
                                  boxSizing: 'border-box',
                                  color: '#212121',
                                  // display: 'inline',
                                  float: 'none',
                                  fontFamily: 'inherit',
                                  fontSize: '16px',
                                  fontStretch: 'inherit',
                                  fontStyle: 'inherit',
                                  fontVariantCaps: 'inherit',
                                  fontVariantEastAsian: 'inherit',
                                  fontVariantLigatures: 'inherit',
                                  fontVariantNumeric: 'inherit',
                                  fontWeight: 'inherit',
                                  letterSpacing: 'normal',
                                  lineHeight: 'inherit',
                                  marginBottom: '0px',
                                  marginLeft: '0px',
                                  marginRight: '0px',
                                  marginTop: '0px',
                                  orphans: 2,
                                  padding: '0px 0px',
                                  textAlign: 'start',
                                  textDecorationColor: 'initial',
                                  textDecorationStyle: 'initial',
                                  textDecorationThickness: 'initial',
                                  textIndent: '0px',
                                  textTransform: 'none',
                                  verticalAlign: 'top',
                                  WebkitTextStrokeWidth: '0px',
                                  whiteSpace: 'pre-wrap',
                                  widows: 2,
                                  wordSpacing: '0px',
                                }}
                              >
                                {customMessage && customMessage?.length > 0 ? (
                                  <RichTextDisplay
                                    sx={{
                                      'p:last-of-type': {
                                        mb: 0,
                                      },
                                    }}
                                    text={
                                      customMessage && customMessage?.length > 0
                                        ? customMessage
                                        : event?.description
                                    }
                                  />
                                ) : (
                                  <div style={{ backgroundColor: 'white' }}>
                                    <div
                                      style={{
                                        alignItems: 'stretch',
                                        // background: 'rgba(255, 255, 255, 0.75)',
                                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%2380C0C7FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                                        borderRadius: 0.75,
                                        borderSpacing: 8,
                                        display: 'flex',
                                        flex: 1,
                                        justifyContent: 'stretch',
                                        minHeight: '40px',
                                        padding: 1,
                                        textAlign: 'center',
                                        width: '100%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          alignItems: 'center',
                                          color: '#616161',
                                          display: 'flex',
                                          flex: 1,
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <div>Your message will appear here</div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className='module'
                  data-muid='2497cb7e-8a24-4206-9f3b-c23bd664c196'
                  data-type='spacer'
                  style={{ tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: '0px 0px 40px 0px' }}> </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  align='center'
                  data-distribution='1'
                  data-type='columns'
                  style={{ padding: '0px 32px 0px 32px', tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td height='100%' valign='top'>
                        <table
                          align='left'
                          className='column column-0'
                          style={{
                            borderCollapse: 'collapse',
                            borderSpacing: 0,
                            tableLayout: 'fixed',
                            width: '100%',
                          }}
                          width='484'
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderSpacing: 0,
                                  margin: '0px',
                                  padding: '0px',
                                }}
                              >
                                <table
                                  className='module'
                                  data-mc-module-version='2019-10-22'
                                  data-muid='9af96762-f60e-4901-b070-7c981c7485f4'
                                  data-type='text'
                                  style={{ tableLayout: 'fixed' }}
                                  width='100%'
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        height='100%'
                                        style={{
                                          lineHeight: '27px',
                                          padding: '0px 0px 0px 0px',
                                          textAlign: 'inherit',
                                        }}
                                        valign='top'
                                      >
                                        <div>
                                          <div
                                            style={{
                                              fontFamily: 'inherit',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor:
                                                  'rgb(255, 255, 255)',
                                                color: '#000000',
                                                display: 'inline',
                                                float: 'none',
                                                fontFamily: 'inherit',
                                                fontSize: '18px',
                                                fontStyle: 'normal',
                                                fontVariantCaps: 'normal',
                                                fontVariantLigatures: 'normal',
                                                fontWeight: 400,
                                                letterSpacing: 'normal',
                                                orphans: 2,
                                                textAlign: 'center',
                                                textDecorationColor: 'initial',
                                                textDecorationStyle: 'initial',
                                                textDecorationThickness:
                                                  'initial',
                                                textIndent: '0px',
                                                textTransform: 'none',
                                                WebkitTextStrokeWidth: '0px',
                                                whiteSpace: 'pre-wrap',
                                                widows: 2,
                                                wordSpacing: '0px',
                                              }}
                                            >
                                              <strong>{event.name}</strong>
                                            </span>
                                          </div>
                                          <div />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  className='module'
                                  data-mc-module-version='2019-10-22'
                                  data-muid='08df1172-46ba-4d9e-b78a-19433f2c8540'
                                  data-type='text'
                                  style={{ tableLayout: 'fixed' }}
                                  width='100%'
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        height='100%'
                                        style={{
                                          lineHeight: '22px',
                                          padding: '4px 0px 24px 0px',
                                          textAlign: 'inherit',
                                        }}
                                        valign='top'
                                      >
                                        <div>
                                          <div
                                            style={{
                                              fontFamily: 'inherit',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor:
                                                  'rgb(255, 255, 255)',
                                                color: '#000000',
                                                display: 'inline',
                                                float: 'none',
                                                fontFamily: 'inherit',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontVariantCaps: 'normal',
                                                fontVariantLigatures: 'normal',
                                                fontWeight: 400,
                                                letterSpacing: 'normal',
                                                orphans: 2,
                                                textAlign: 'center',
                                                textDecorationColor: 'initial',
                                                textDecorationStyle: 'initial',
                                                textDecorationThickness:
                                                  'initial',
                                                textIndent: '0px',
                                                textTransform: 'none',
                                                WebkitTextStrokeWidth: '0px',
                                                whiteSpace: 'pre-wrap',
                                                widows: 2,
                                                wordSpacing: '0px',
                                              }}
                                            >
                                              {eventDateTimeRange}
                                            </span>
                                          </div>
                                          <div />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  className='module'
                                  data-muid='9267cb12-b0f9-4d6b-956f-f09ec1bde9f1'
                                  data-type='code'
                                  style={{ tableLayout: 'fixed' }}
                                  width='100%'
                                >
                                  <tbody>
                                    <tr>
                                      <td height='100%' valign='top'>
                                        <img
                                          alt=''
                                          className='max-width'
                                          data-proportionally-constrained='true'
                                          data-responsive='true'
                                          src='https://res.cloudinary.com/five-to-nine/image/upload/v1622648516/Default_Event_Image.jpg'
                                          style={{
                                            borderRadius: '6px',
                                            color: '#000000',
                                            display: 'block',
                                            fontFamily:
                                              'Helvetica, arial, sans-serif',
                                            fontSize: '16px',
                                            height: 'auto !important',
                                            maxWidth: '100% !important',
                                            textDecoration: 'none',
                                            width: '100%',
                                          }}
                                          width='315'
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  className='module'
                                  data-mc-module-version='2019-10-22'
                                  data-muid='42654323-f999-4933-85c0-b1624f77dfb3'
                                  data-type='text'
                                  style={{ tableLayout: 'fixed' }}
                                  width='100%'
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        height='100%'
                                        style={{
                                          lineHeight: '22px',
                                          padding: '24px 0px 24px 0px',
                                          textAlign: 'inherit',
                                        }}
                                        valign='top'
                                      >
                                        <div>
                                          <div
                                            style={{
                                              fontFamily: 'inherit',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <span style={{ fontSize: 16 }}>
                                              See more event details and RSVP on
                                              the event page.
                                            </span>
                                          </div>
                                          <div />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  className='module'
                                  data-muid='e17084c7-e18c-42ff-81ba-ecafa72b7fea'
                                  data-role='module-button'
                                  data-type='button'
                                  style={{ tableLayout: 'fixed' }}
                                  width='100%'
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        align='center'
                                        className='outer-td'
                                        style={{ padding: '0px 0px 0px 0px' }}
                                      >
                                        <table
                                          className='wrapper-mobile'
                                          style={{ textAlign: 'center' }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align='center'
                                                className='inner-td'
                                                style={{
                                                  backgroundColor: 'inherit',
                                                  borderRadius: '6px',
                                                  fontSize: '16px',
                                                  textAlign: 'center',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor: '#008290',
                                                    border: '1px solid #008290',
                                                    borderColor: '#008290',
                                                    borderRadius: '6px',
                                                    borderStyle: 'solid',
                                                    borderWidth: '1px',
                                                    color: '#ffffff',
                                                    cursor: 'pointer',
                                                    display: 'inline-block',
                                                    fontFamily: 'inherit',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    letterSpacing: '0px',
                                                    lineHeight: 'normal',
                                                    padding:
                                                      '12px 18px 12px 18px',
                                                    textAlign: 'center',
                                                    textDecoration: 'none',
                                                  }}
                                                >
                                                  View event page
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className='module'
                  data-muid='376188b6-4e4a-4168-8464-b5e0cb4661d1'
                  data-type='spacer'
                  style={{ tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: '0px 0px 64px 0px' }}> </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  align='center'
                  data-distribution='1'
                  data-type='columns'
                  style={{ padding: '0px 0px 0px 0px' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td height='100%' valign='top'>
                        <table
                          align='left'
                          className='column column-0'
                          style={{
                            borderCollapse: 'collapse',
                            borderSpacing: 0,
                            margin: '0px 10px 0px 10px',
                            maxWidth: '548px',
                            tableLayout: 'fixed',
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderSpacing: 0,
                                  margin: '0px',
                                  padding: '0px',
                                }}
                              >
                                <table
                                  className='module'
                                  data-mc-module-version='2019-10-22'
                                  data-muid='6e116083-23c3-49cc-9c5d-f8ffa28ee4d0'
                                  data-type='text'
                                  //
                                  style={{ tableLayout: 'fixed' }}
                                  width='100%'
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        height='100%'
                                        //
                                        style={{
                                          lineHeight: '22px',
                                          padding: '0px 0px 24px 0px',
                                          textAlign: 'inherit',
                                        }}
                                        valign='top'
                                      >
                                        <div>
                                          <div
                                            style={{
                                              fontFamily: 'inherit',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <br />
                                          </div>
                                          <div
                                            style={{
                                              fontFamily: 'inherit',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor:
                                                  'rgb(255, 255, 255)',
                                                borderBottomColor: 'initial',
                                                borderBottomStyle: 'initial',
                                                borderBottomWidth: '0px',
                                                borderImageOutset: 'initial',
                                                borderImageRepeat: 'initial',
                                                borderImageSlice: 'initial',
                                                borderImageSource: 'initial',
                                                borderImageWidth: 'initial',
                                                borderLeftColor: 'initial',
                                                borderLeftStyle: 'initial',
                                                borderLeftWidth: '0px',
                                                borderRightColor: 'initial',
                                                borderRightStyle: 'initial',
                                                borderRightWidth: '0px',
                                                borderTopColor: 'initial',
                                                borderTopStyle: 'initial',
                                                borderTopWidth: '0px',
                                                boxSizing: 'border-box',
                                                color: '#212121',
                                                display: 'inline',
                                                float: 'none',
                                                fontFamily: 'inherit',
                                                fontFeatureSettings: 'inherit',
                                                fontKerning: 'inherit',
                                                fontOpticalSizing: 'inherit',
                                                fontSize: '14px',
                                                fontStretch: 'inherit',
                                                fontStyle: 'inherit',
                                                fontVariantCaps: 'inherit',
                                                fontVariantEastAsian: 'inherit',
                                                fontVariantLigatures: 'inherit',
                                                fontVariantNumeric: 'inherit',
                                                fontVariationSettings:
                                                  'inherit',
                                                fontWeight: 'inherit',
                                                letterSpacing: 'normal',
                                                lineHeight: 'inherit',
                                                marginBottom: '0px',
                                                marginLeft: '0px',
                                                marginRight: '0px',
                                                marginTop: '0px',
                                                orphans: 2,
                                                paddingBottom: '0px',
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                                paddingTop: '0px',
                                                textAlign: 'center',
                                                textDecorationColor: 'initial',
                                                textDecorationStyle: 'initial',
                                                textDecorationThickness:
                                                  'initial',
                                                textIndent: '0px',
                                                textTransform: 'none',
                                                verticalAlign: 'baseline',
                                                WebkitTextStrokeWidth: '0px',
                                                whiteSpace: 'pre-wrap',
                                                widows: 2,
                                                wordSpacing: '0px',
                                              }}
                                            >
                                              Powered by{' '}
                                            </span>
                                            <span
                                              style={{
                                                backgroundColor:
                                                  'rgb(255, 255, 255)',
                                                borderBottomColor: 'initial',
                                                borderBottomStyle: 'initial',
                                                borderBottomWidth: '0px',
                                                borderImageOutset: 'initial',
                                                borderImageRepeat: 'initial',
                                                borderImageSlice: 'initial',
                                                borderImageSource: 'initial',
                                                borderImageWidth: 'initial',
                                                borderLeftColor: 'initial',
                                                borderLeftStyle: 'initial',
                                                borderLeftWidth: '0px',
                                                borderRightColor: 'initial',
                                                borderRightStyle: 'initial',
                                                borderRightWidth: '0px',
                                                borderTopColor: 'initial',
                                                borderTopStyle: 'initial',
                                                borderTopWidth: '0px',
                                                boxSizing: 'border-box',
                                                color: '#008290',
                                                cursor: 'pointer',
                                                display: 'inline',
                                                float: 'none',
                                                fontFamily: 'inherit',
                                                fontFeatureSettings: 'inherit',
                                                fontKerning: 'inherit',
                                                fontOpticalSizing: 'inherit',
                                                fontSize: '14px',
                                                fontStretch: 'inherit',
                                                fontStyle: 'inherit',
                                                fontVariantCaps: 'inherit',
                                                fontVariantEastAsian: 'inherit',
                                                fontVariantLigatures: 'inherit',
                                                fontVariantNumeric: 'inherit',
                                                fontVariationSettings:
                                                  'inherit',
                                                fontWeight: 'inherit',
                                                letterSpacing: 'normal',
                                                lineHeight: 'inherit',
                                                marginBottom: '0px',
                                                marginLeft: '0px',
                                                marginRight: '0px',
                                                marginTop: '0px',
                                                orphans: 2,
                                                outlineColor: 'initial',
                                                outlineStyle: 'none',
                                                outlineWidth: 'initial',
                                                paddingBottom: '0px',
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                                paddingTop: '0px',
                                                textAlign: 'center',
                                                textDecorationColor: 'initial',
                                                textDecorationLine: 'underline',
                                                textDecorationStyle: 'initial',
                                                textDecorationThickness:
                                                  'initial',
                                                textIndent: '0px',
                                                textTransform: 'none',
                                                transitionDelay: '0s',
                                                transitionDuration: '0.3s',
                                                transitionProperty: 'color',
                                                transitionTimingFunction:
                                                  'ease',
                                                verticalAlign: 'baseline',
                                                WebkitTextStrokeWidth: '0px',
                                                whiteSpace: 'pre-wrap',
                                                widows: 2,
                                                wordSpacing: '0px',
                                              }}
                                            >
                                              <strong>Five to Nine</strong>
                                            </span>
                                            <span
                                              style={{
                                                backgroundColor:
                                                  'rgb(255, 255, 255)',
                                                borderBottomColor: 'initial',
                                                borderBottomStyle: 'initial',
                                                borderBottomWidth: '0px',
                                                borderImageOutset: 'initial',
                                                borderImageRepeat: 'initial',
                                                borderImageSlice: 'initial',
                                                borderImageSource: 'initial',
                                                borderImageWidth: 'initial',
                                                borderLeftColor: 'initial',
                                                borderLeftStyle: 'initial',
                                                borderLeftWidth: '0px',
                                                borderRightColor: 'initial',
                                                borderRightStyle: 'initial',
                                                borderRightWidth: '0px',
                                                borderTopColor: 'initial',
                                                borderTopStyle: 'initial',
                                                borderTopWidth: '0px',
                                                boxSizing: 'border-box',
                                                color: '#212121',
                                                display: 'inline',
                                                float: 'none',
                                                fontFamily: 'inherit',
                                                fontFeatureSettings: 'inherit',
                                                fontKerning: 'inherit',
                                                fontOpticalSizing: 'inherit',
                                                fontSize: '14px',
                                                fontStretch: 'inherit',
                                                fontStyle: 'inherit',
                                                fontVariantCaps: 'inherit',
                                                fontVariantEastAsian: 'inherit',
                                                fontVariantLigatures: 'inherit',
                                                fontVariantNumeric: 'inherit',
                                                fontVariationSettings:
                                                  'inherit',
                                                fontWeight: 'inherit',
                                                letterSpacing: 'normal',
                                                lineHeight: 'inherit',
                                                marginBottom: '0px',
                                                marginLeft: '0px',
                                                marginRight: '0px',
                                                marginTop: '0px',
                                                orphans: 2,
                                                paddingBottom: '0px',
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                                paddingTop: '0px',
                                                textAlign: 'center',
                                                textDecorationColor: 'initial',
                                                textDecorationStyle: 'initial',
                                                textDecorationThickness:
                                                  'initial',
                                                textIndent: '0px',
                                                textTransform: 'none',
                                                verticalAlign: 'baseline',
                                                WebkitTextStrokeWidth: '0px',
                                                whiteSpace: 'pre-wrap',
                                                widows: 2,
                                                wordSpacing: '0px',
                                              }}
                                            >
                                              .
                                            </span>
                                            &nbsp;
                                          </div>
                                          <div
                                            style={{
                                              fontFamily: 'inherit',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <br />
                                          </div>
                                          <div
                                            style={{
                                              fontFamily: 'inherit',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor:
                                                  'rgb(255, 255, 255)',
                                                borderBottomColor: 'initial',
                                                borderBottomStyle: 'initial',
                                                borderBottomWidth: '0px',
                                                borderImageOutset: 'initial',
                                                borderImageRepeat: 'initial',
                                                borderImageSlice: 'initial',
                                                borderImageSource: 'initial',
                                                borderImageWidth: 'initial',
                                                borderLeftColor: 'initial',
                                                borderLeftStyle: 'initial',
                                                borderLeftWidth: '0px',
                                                borderRightColor: 'initial',
                                                borderRightStyle: 'initial',
                                                borderRightWidth: '0px',
                                                borderTopColor: 'initial',
                                                borderTopStyle: 'initial',
                                                borderTopWidth: '0px',
                                                boxSizing: 'border-box',
                                                color: '#212121',
                                                fontFamily: 'inherit',
                                                fontFeatureSettings: 'inherit',
                                                fontKerning: 'inherit',
                                                fontOpticalSizing: 'inherit',
                                                fontSize: '14px',
                                                fontStretch: 'inherit',
                                                fontStyle: 'inherit',
                                                fontVariantCaps: 'inherit',
                                                fontVariantEastAsian: 'inherit',
                                                fontVariantLigatures: 'inherit',
                                                fontVariantNumeric: 'inherit',
                                                fontVariationSettings:
                                                  'inherit',
                                                fontWeight: 'inherit',
                                                letterSpacing: 'normal',
                                                lineHeight: 'inherit',
                                                marginBottom: '0px',
                                                marginLeft: '0px',
                                                marginRight: '0px',
                                                marginTop: '0px',
                                                orphans: 2,
                                                paddingBottom: '0px',
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                                paddingTop: '0px',
                                                textAlign: 'center',
                                                textDecorationColor: 'initial',
                                                textDecorationStyle: 'initial',
                                                textDecorationThickness:
                                                  'initial',
                                                textIndent: '0px',
                                                textTransform: 'none',
                                                verticalAlign: 'baseline',
                                                WebkitTextStrokeWidth: '0px',
                                                whiteSpace: 'pre-wrap',
                                                widows: 2,
                                                wordSpacing: '0px',
                                              }}
                                            >
                                              222 W. Merchanidise Mart Plaza
                                              #1212,&nbsp;
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              fontFamily: 'inherit',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor:
                                                  'rgb(255, 255, 255)',
                                                borderBottomColor: 'initial',
                                                borderBottomStyle: 'initial',
                                                borderBottomWidth: '0px',
                                                borderImageOutset: 'initial',
                                                borderImageRepeat: 'initial',
                                                borderImageSlice: 'initial',
                                                borderImageSource: 'initial',
                                                borderImageWidth: 'initial',
                                                borderLeftColor: 'initial',
                                                borderLeftStyle: 'initial',
                                                borderLeftWidth: '0px',
                                                borderRightColor: 'initial',
                                                borderRightStyle: 'initial',
                                                borderRightWidth: '0px',
                                                borderTopColor: 'initial',
                                                borderTopStyle: 'initial',
                                                borderTopWidth: '0px',
                                                boxSizing: 'border-box',
                                                color: '#212121',
                                                fontFamily: 'inherit',
                                                fontFeatureSettings: 'inherit',
                                                fontKerning: 'inherit',
                                                fontOpticalSizing: 'inherit',
                                                fontSize: '14px',
                                                fontStretch: 'inherit',
                                                fontStyle: 'inherit',
                                                fontVariantCaps: 'inherit',
                                                fontVariantEastAsian: 'inherit',
                                                fontVariantLigatures: 'inherit',
                                                fontVariantNumeric: 'inherit',
                                                fontVariationSettings:
                                                  'inherit',
                                                fontWeight: 'inherit',
                                                letterSpacing: 'normal',
                                                lineHeight: 'inherit',
                                                marginBottom: '0px',
                                                marginLeft: '0px',
                                                marginRight: '0px',
                                                marginTop: '0px',
                                                orphans: 2,
                                                paddingBottom: '0px',
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                                paddingTop: '0px',
                                                textAlign: 'center',
                                                textDecorationColor: 'initial',
                                                textDecorationStyle: 'initial',
                                                textDecorationThickness:
                                                  'initial',
                                                textIndent: '0px',
                                                textTransform: 'none',
                                                verticalAlign: 'baseline',
                                                WebkitTextStrokeWidth: '0px',
                                                whiteSpace: 'pre-wrap',
                                                widows: 2,
                                                wordSpacing: '0px',
                                              }}
                                            >
                                              Chicago, IL, 60654
                                            </span>
                                          </div>
                                          <div />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PreviewEmailMessage;
