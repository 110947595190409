import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { useOrganization } from 'hooks/useOrganization';
import Currency from 'types/Currency';

interface Props {
  control?: Control<any>;
  disabled?: boolean;
  groupsWatch?: any[];
  label?: string;
  name: string;
}

const CurrencySelect = ({
  control,
  disabled,
  groupsWatch,
  label,
  name,
}: Props) => {
  const {
    retrieve: { data },
  } = useOrganization();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth>
          {label && <InputLabel id='currency-label'>{label}</InputLabel>}
          <Select
            disabled={groupsWatch && groupsWatch?.length > 0 ? true : disabled}
            fullWidth
            label={label}
            labelId='currency-label'
            onChange={(ev) => {
              field.onChange(ev.target.value);
            }}
            value={field.value}
          >
            {data?.currencies?.map((currency: Currency) => (
              <MenuItem key={currency.id} value={currency.id}>
                {currency.name} ({currency.symbol})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default CurrencySelect;
