import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Plus } from '@phosphor-icons/react';

import CollectionFilters from 'components/Collections/CollectionFilters';
import CollectionsTab from 'components/Collections/CollectionsTab';
import TabsLayout from 'components/shared/layout/TabsLayout';

const Collections = () => {
  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();

  const [filterOrganizers, setFilterOrganizers] = useState<string[]>([]);
  const [filterSearch, setFilterSearch] = useState<string>('');

  const filters = useMemo(
    () => ({
      organizers: filterOrganizers,
      search: filterSearch,
    }),
    [filterOrganizers, filterSearch]
  );

  const clearFilters = () => {
    setFilterOrganizers([]);
    setFilterSearch('');
  };

  const handleOrganizerChange = (organizerIds: string[]) => {
    setFilterOrganizers(organizerIds);
  };

  return (
    <TabsLayout
      button={
        <Button
          onClick={() => navigate('/collections/create')}
          startIcon={<Plus />}
          variant='contained'
        >
          New collection
        </Button>
      }
      fixedContent={
        <CollectionFilters
          handleClearAll={clearFilters}
          onOrganizerChange={handleOrganizerChange}
          onSearchChange={setFilterSearch}
          searchValue={filterSearch}
          selectedOrganizers={filterOrganizers}
        />
      }
      tabs={['upcoming', 'past', 'drafts']}
      title='Collections'
    >
      <CollectionsTab filters={filters} type={String(tab)} />
    </TabsLayout>
  );
};

export default Collections;
