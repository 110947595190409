import * as React from 'react';
import { InputAdornment } from '@mui/material';
import { MagnifyingGlass } from '@phosphor-icons/react';

const SearchStartAdornment = () => (
  <InputAdornment position='start'>
    <MagnifyingGlass size={20} />
  </InputAdornment>
);

export default SearchStartAdornment;
