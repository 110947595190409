import { capitalize } from '@mui/material';
import { Link, NotePencil, PaperPlaneTilt, Trash } from '@phosphor-icons/react';

import Action from 'types/Action';

const actionData: Action[] = [
  {
    color: 'secondary',
    Icon: NotePencil,
    id: 'edit',
  },
  {
    color: 'error',
    Icon: Trash,
    id: 'delete',
  },
  {
    color: 'secondary',
    Icon: PaperPlaneTilt,
    id: 'send',
    label: 'Send now',
  },
  {
    color: 'secondary',
    Icon: Link,
    id: 'copyLink',
    label: 'Copy link',
    labelWithType: (type: string) => `Copy ${type} link`,
  },
];

const actionDataFormatted: any[] = [...actionData].map((action) => [
    action.id,
    {
      ...action,
      ...(!action.label ? { label: capitalize(action.id || '') } : {}),
    },
  ]);

const Actions: Map<string, Action> = new Map(actionDataFormatted);

export default Actions;
