/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  capitalize,
  Checkbox,
  FormControl,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { CheckSquare } from '@phosphor-icons/react';
import { isEqual } from 'lodash';
import { singular } from 'pluralize';
import PropTypes from 'prop-types';

import Search from 'components/Core/Search';
import GuestStatusChip from 'components/shared/GuestStatusChip';
import RadioLabel from 'components/shared/RadioLabel';
import TabLabelWithCount from 'components/shared/TabLabelWithCount';
import { fixedCheckListItem } from 'utils/styles';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .already-invited`]: {
    ...fixedCheckListItem,
    '& .MuiDataGrid-cellCheckbox': {
      opacity: 0,
    },
    position: 'relative',
  },
  [`& .already-invited svg.invited-checkmark`]: {
    color: theme.palette.grey[900],
    left: theme.spacing(1.25),
    position: 'absolute',
    top: theme.spacing(1.75),
  },
  [`& .already-invited:hover`]: {
    backgroundColor: 'transparent',
  },
  [`& .MuiDataGrid-cell.MuiDataGrid-withBorderColor`]: {
    borderColor: 'transparent',
  },
}));

const GuestCheckList = ({
  disableColumnMenu = true,
  disabled: isDisabled,
  expandableRows = false,
  hasSearch,
  height = 400,
  isSelectable = true,
  label,
  noOptionsMessage,
  nounForSearch,
  nounPlural,
  onChange,
  optionRender = null,
  options: optionsList,
  rowHeight = 40,
  showFixedAsDisabled,
  showSelectedCountInHeader = false,
  sx = {},
  value = [],
}) => {
  const apiRef = useGridApiRef();
  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState(1);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    apiRef?.current?.scrollToIndexes({ colIndex: 0, rowIndex: 0 });
    setSearchTerm('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nounPlural]);

  useEffect(() => {
    if (currentPage !== 0 && selections?.length === 0) {
      setCurrentPage(0);
    }
    if (!isEqual(options, optionsList)) {
      setOptions(optionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsList, selections]);

  useEffect(() => {
    if (options?.length > 0 && isSelectable && !isEqual(value, selections)) {
      setSelections(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const filteredOptions = useMemo(
    () =>
      searchTerm?.length > 0
        ? options?.filter((o) =>
            o?.name
              ?.toLowerCase()
              ?.includes(searchTerm?.replace('#', '')?.toLowerCase())
          )
        : options,
    [options, searchTerm]
  );

  // eslint-disable-next-line react/no-multi-comp
  const CustomNoRowsOverlay = () => (
    <Box p={2}>
      <Typography color='text.secondary' variant='body1'>
        {options?.length < 1
          ? noOptionsMessage || `No ${nounPlural || 'options'} selected yet!`
          : `No ${nounPlural ? 'matching ' + nounPlural : 'results'} found.`}
      </Typography>
    </Box>
  );

  return (
    <>
      {hasSearch && (
        <FormControl fullWidth>
          <Search
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`Search ${
              nounForSearch ? ['by', nounForSearch].join(' ') : nounPlural || ''
            }`}
            value={searchTerm}
          />
        </FormControl>
      )}
      <StyledDataGrid
        apiRef={apiRef}
        checkboxSelection
        columnHeadersHeight={rowHeight}
        columns={[
          {
            disableColumnMenu,
            field: 'name',
            flex: 1,
            headerName: nounPlural ? capitalize(nounPlural) : label || '',
            renderCell: (params) => (
              <>
                {params.row.fixed && showFixedAsDisabled ? (
                  <CheckSquare
                    className='invited-checkmark'
                    color={theme.palette.grey[500]}
                    size={29}
                    weight='fill'
                  />
                ) : null}
                {optionRender != null ? (
                  optionRender(params.row)
                ) : params.row.email?.length > 0 ? (
                  <RadioLabel
                    primary={params.value}
                    secondary={params.row.email}
                  />
                ) : (
                  <Typography>{params.value}</Typography>
                )}
                {!optionRender && (
                  <GuestStatusChip
                    status={params.row.status}
                    // tooltipText={params.row.text}
                  />
                )}
              </>
            ),
            renderHeader: ({ colDef }) => {
              if (isSelectable && showSelectedCountInHeader) {
                return (
                  <Typography variant='body2'>
                    <TabLabelWithCount
                      count={selections?.length}
                      label={colDef.headerName}
                    />
                  </Typography>
                );
              } else {
                return colDef.headerName;
              }
            },
            valueGetter: ({ row }) => row?.name,
          },
        ]}
        disableColumnSelector
        disableDensitySelector
        getRowClassName={(params) =>
          isSelectable && showFixedAsDisabled && params?.row?.fixed
            ? 'already-invited'
            : ''
        }
        getRowHeight={() => (expandableRows ? 'auto' : rowHeight)}
        isRowSelectable={(params) =>
          isSelectable &&
          !isDisabled &&
          (!showFixedAsDisabled || !params.row.fixed)
        }
        keepNonExistentRowsSelected
        localeText={{
          footerRowSelected: (count) => {
            const lowerNounPlural = nounPlural.toLowerCase();
            return `${count} ${
              count !== 1 ? lowerNounPlural : singular(lowerNounPlural)
            } selected`;
          },
        }}
        onRowSelectionModelChange={(newSelections) => {
          if (isSelectable) {
            setSelections(newSelections);
            if (onChange) {
              onChange(newSelections);
            }
          }
        }}
        rows={filteredOptions || []}
        rowSelectionModel={selections}
        slotProps={{
          footer: { sx: { bgcolor: 'grey.100' } },
          pagination: {
            rowsPerPageOptions: [100],
            size: 'small',
            ...(filteredOptions?.length <= 100
              ? {
                  backIconButtonProps: { sx: { display: 'none' } },
                  nextIconButtonProps: { sx: { display: 'none' } },
                }
              : {}),
          },
        }}
        slots={{
          baseCheckbox: (props) => <Checkbox {...props} color='secondary' />,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        style={{ height }}
        sx={{
          ...sx,
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            fontSize: 14,
            fontWeight: 500,
          },
          maxHeight: `calc(100vh - 280px)`,
        }}
      />
    </>
  );
};

GuestCheckList.propTypes = {
  disableColumnMenu: PropTypes.bool,
  disabled: PropTypes.bool,
  expandableRows: PropTypes.bool,
  hasSearch: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSelectable: PropTypes.bool,
  label: PropTypes.string,
  noOptionsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  nounForSearch: PropTypes.string,
  nounPlural: PropTypes.string,
  onChange: PropTypes.func,
  optionRender: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      fixed: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
      ]),
    })
  ),
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showFixedAsDisabled: PropTypes.bool,
  showSelectedCountInHeader: PropTypes.bool,
  status: PropTypes.string,
  sx: PropTypes.object,
  value: PropTypes.array,
};

export default GuestCheckList;
