import { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { calendarServices } from 'utils/calendarServices';

const OutlookScopes =
  'Calendars.ReadWrite email offline_access openid profile User.Read';
const OutlookAuthorizationUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize`;

export const useMicrosoftLogin = (
  org,
  onFailure,
  onCodeReceived,
  onSuccess,
  onboardToken = null,
  onMfaRequired = null
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const redirectUri = `${window.location.origin}${calendarServices.microsoft.uriSuffix}`;

  const handleFailure = () => {
    // we don't want to give out too much details about the error
    enqueueSnackbar('Something went wrong!', { variant: 'error' });
  };

  const openPopup = () => {
    const width = 500;
    const height = 820;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    return window.open(
      `${OutlookAuthorizationUrl}?scope=${OutlookScopes}&client_id=${
        import.meta.env.VITE_MICROSOFT_CLIENT_ID
      }&redirect_uri=${redirectUri}&response_type=code&response_mode=query`,
      '',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height= ${height}, top=${top}, left= ${left}`
    );
  };

  const popupWatcher = (popup) => {
    const watch = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(watch);
        setIsLoading(false);
      }

      const closePopup = () => {
        clearInterval(watch);
        popup.close();
      };

      try {
        if (
          popup?.location?.hostname &&
          !popup.location.hostname.includes('microsoftonline.com')
        ) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const code = query.get('code');
            const error = query.get({ variant: 'error' });

            const user = {
              code,
              organization_id: org.id,
              redirect_uri: redirectUri,
            };
            if (onboardToken) {
              user.onboard_code = onboardToken;
            }

            closePopup();
            if (code) {
              if (onCodeReceived) {
                onCodeReceived(code, () => {
                  setIsLoading(false);
                });
              } else {
                axios
                  .post(
                    `${import.meta.env.VITE_BE_URL}/api/${
                      onboardToken ? 'admins' : 'users'
                    }/outlook_oauth`,
                    {
                      user,
                    }
                  )
                  .then((res) => {
                    if (res.data?.mfa_required) {
                      onMfaRequired && onMfaRequired(res.data.user_id);
                    } else if (res.data.token) {
                      setIsLoading(false);
                      onSuccess && onSuccess(res.data.token, res.data.user);
                    }
                  })
                  .catch((err) => {
                    onFailure ? onFailure(err) : handleFailure(err);
                  });
              }
            } else if (error) {
              setIsLoading(false);
            }
          }
        }
      } catch (error) {
        if (error instanceof DOMException) {
          // We expect this error until the popup gets redirected back to us
        } else {
          setIsLoading(false);
          onFailure ? onFailure(error) : handleFailure(error);
        }
      }
    }, 500);
  };

  return {
    isLoading,
    signIn: () => {
      if (!isLoading) {
        setIsLoading(true);
        popupWatcher(openPopup());
      }
    },
  };
};
