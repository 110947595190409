import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { RocketLaunch } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import EventPreview from 'components/Event/EventPreview';
import { usePublishEvent } from 'hooks/usePublishEvent';
import useSaveEvent from 'hooks/useSaveEvent';
import {
  EventDetailsFormFormState,
  EventDetailsFormHandleSubmit,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';

interface Props {
  eventId: number | null;
  formState: EventDetailsFormFormState;
  handleSubmit: EventDetailsFormHandleSubmit;
  isPublished: boolean;
  rsvpPauseActive?: boolean;
  watch: EventDetailsFormWatch;
}

const EventToolbar = ({
  eventId,
  formState,
  handleSubmit,
  isPublished,
  rsvpPauseActive = false,
  watch,
}: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isClosing, setIsClosing] = useState(false);

  const { isDirty: hasChanges } = formState;

  // Publish Event
  const { isPending: isPublishing, mutate: publishMutate } = usePublishEvent({
    dataHandler: (returnedEvent) => {
      navigate(`/event/${returnedEvent.id}/details`, { replace: true });
    },
  });

  // Save Draft
  const { isPending: isSaving, mutate: saveDraftMutate } = useSaveEvent({
    dataHandler: (returnedEvent) => {
      if (returnedEvent.published === false && eventId == null) {
        // Redirect on first save
        navigate(`/event/${returnedEvent.id}/create`, { replace: true });
      }
    },
    eventId,
    saveAsDraft: true,
  });

  const invalidHandler = () => {
    enqueueSnackbar('Please resolve errors before saving your changes', {
      variant: 'error',
    });
  };

  const buttonSize = 'medium';

  const handleCloseDialog = () => setIsClosing(false);

  const saveChanges = async () => {
    handleSubmit((d) => saveDraftMutate(d), invalidHandler)();
    handleCloseDialog();
  };

  const CancelButton = (
    <Button
      data-testid='close'
      onClick={() => {
        if (hasChanges) {
          setIsClosing(true);
        } else {
          navigate('/events');
        }
      }}
      size={buttonSize}
      sx={{ px: 2 }}
      variant='text'
    >
      Cancel
    </Button>
  );

  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        gap={1.5}
        justifyContent='flex-end'
      >
        {CancelButton}
        <EventPreview
          isPublished={isPublished}
          rsvpPauseActive={rsvpPauseActive}
          watch={watch}
        />

        <LoadingButton
          data-testid='save-draft'
          disabled={!hasChanges}
          loading={isSaving}
          onClick={() => saveChanges()}
          size={buttonSize}
          sx={{ px: 2 }}
          variant='outlined'
        >
          Save draft
        </LoadingButton>
        <LoadingButton
          color='primary'
          data-testid='event-create-cta'
          loading={isPublishing}
          onClick={() => {
            handleSubmit((d) => publishMutate(d), invalidHandler)();
          }}
          size={buttonSize}
          startIcon={<RocketLaunch />}
          variant='contained'
        >
          Publish event
        </LoadingButton>
      </Stack>
      <Dialog
        data-testid='unsaved-event-changes'
        onClose={handleCloseDialog}
        open={isClosing}
      >
        <DialogTitle>You have unsaved changes!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to save them before closing?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='cancel'
            onClick={handleCloseDialog}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            data-testid='discard-changes'
            onClick={() => {
              handleCloseDialog();
              navigate('/events');
            }}
            variant='outlined'
          >
            No, discard
          </Button>
          <Button
            data-testid='save-changes'
            onClick={() => {
              saveChanges();
            }}
            variant='contained'
          >
            Yes, save changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EventToolbar;
