import React, { useState } from 'react';
import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { MagnifyingGlass } from '@phosphor-icons/react';
import dayjs from 'dayjs';

import EventCards from 'components/Events/EventCards';
import { Event } from 'types/CollectionForm';

interface CollectionEventsProps {
  collection: any;
  events: Event[];
}

const CollectionEvents: React.FC<CollectionEventsProps> = ({ collection, events }) => {
  const [collectionEvents, setCollectionEvents] = useState(events);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('All events');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterType(event.target.value);
  };

  const now = dayjs();

  const removeFromAllChunks = (id: number) => {
    const currentEvents = collectionEvents.filter(
      (event: Event) => Number(event.id) !== id
    );
    setCollectionEvents(currentEvents);
  };

  const filteredEvents = collectionEvents.filter((event) => {
    const matchesSearch = event.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    if (filterType === 'Upcoming events') {
      return matchesSearch && dayjs(event.start).isAfter(now);
    } else if (filterType === 'Past events') {
      return matchesSearch && dayjs(event.start).isBefore(now);
    }

    return matchesSearch;
  });

  const chunk = {
    hasMore: false,
    isLoading: false,
    requestMore: () => {},
    values: filteredEvents,
  };

  return (
    <Box>
      <Box
        alignItems='center'
        display='flex'
        gap={2}
        justifyContent='flex-start'
        mb={2}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <MagnifyingGlass size={20} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
          placeholder='Search events'
          size='small'
          value={searchTerm}
          variant='outlined'
        />
        <Select
          displayEmpty
          onChange={handleFilterChange}
          size='small'
          value={filterType}
        >
          <MenuItem value='All events'>All events</MenuItem>
          <MenuItem value='Upcoming events'>Upcoming events</MenuItem>
          <MenuItem value='Past events'>Past events</MenuItem>
        </Select>
      </Box>

      <EventCards collection={collection} chunk={chunk} removeFromAllChunks={removeFromAllChunks} />
    </Box>
  );
};

export default CollectionEvents;
