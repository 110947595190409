import React, { useCallback, useContext, useMemo } from 'react';
import { Autocomplete, SxProps, Theme } from '@mui/material';

import { ConditionalLoader } from 'components/Core/Loader';
import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import { AutocompleteRenderInput } from 'components/shared/AutocompleteRenderInput';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { Group } from 'types/Group';

interface Selection {
  employee_ids?: number[];
  label: string;
  value: number;
}

interface GroupsAutocompleteProps {
  forFilter?: boolean;
  fullWidth?: boolean;
  isDisabled?: boolean;
  maxTags?: number;
  onChange: (event: React.SyntheticEvent, value: Selection[]) => void;
  sx?: SxProps<Theme>;
  value?: (string | number)[];
}

const GroupsAutocomplete = ({
  forFilter = false,
  fullWidth = false,
  isDisabled = false,
  maxTags = -1,
  onChange,
  sx = {},
  value = [],
}: GroupsAutocompleteProps) => {
  const [rawOptions] = useContext(GroupsContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;

  const isAdminOrMember = useCallback(
    (group: Group) =>
      group.admin_employee_ids?.includes(parseInt(user?.id ?? '')) ||
      group.employee_ids?.includes(parseInt(user?.id ?? '')),
    [user?.id]
  );

  const options = useMemo(
    () =>
      rawOptions
        ?.filter((o: Group) => !o?.private || isAdminOrMember(o))
        ?.sort((a: Group, b: Group) => a?.name?.localeCompare(b?.name))
        ?.map(
          ({
            employee_ids,
            ...o
          }: {
            employee_ids: number[];
            id: number;
            name: string;
          }) => ({
            employee_ids,
            label: o?.name,
            value: o?.id,
          })
        ) || [],
    [isAdminOrMember, rawOptions]
  );

  return (
    <ConditionalLoader conditions={[!options]}>
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        disabled={isDisabled}
        fullWidth={fullWidth}
        id='select-groups'
        limitTags={maxTags}
        multiple
        onChange={onChange}
        options={options}
        renderInput={(params) => (
          <AutocompleteRenderInput
            forFilter={forFilter}
            label='Group'
            params={params}
            value={value}
          />
        )}
        renderOption={(props, option, state, ownerState) => (
          <AutocompleteCheckboxOption
            option={option}
            ownerState={ownerState}
            props={props}
            state={state}
          />
        )}
        slotProps={{
          popper: {
            placement: 'bottom-start',
            style: { width: 'fit-content' },
          },
        }}
        sx={sx}
        value={options?.filter((e: Selection) => value?.includes(e.value))}
      />
    </ConditionalLoader>
  );
};

export default GroupsAutocomplete;
