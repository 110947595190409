import React, { createContext, useContext, useMemo, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { reactQueryAxios } from 'utils/axios';

export const EmployeesContext = createContext<any>(null);

export const EmployeesProvider = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  // const employeesPaged = useChunking('/api/employees', 'employees');
  // const [employees, setEmployees] = useState([]);
  // We are going to load data immediately, so let's keep from flip floppiing the ConditionalLoaders until we are done with the initial load
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext) as AuthContextType;

  type GetAllEmployeesParams = {
    page: number;
    perPage?: number;
  };

  const getAllEmployees = async ({
    apiClient,
    page = 1,
  }: GetAllEmployeesParams & { apiClient: any }) =>
    apiClient({
      method: 'get',
      url: `/api/employees?page=${page}&per_page=${perPage}`,
    });

  const {
    data: allEmployeesData,
    isFetching,
    isPending,
    isPlaceholderData,
    refetch,
  } = useQuery({
    enabled: isAuthenticated,
    gcTime: Infinity,
    placeholderData: keepPreviousData,
    queryFn: () =>
      getAllEmployees({
        apiClient: reactQueryAxios,
        page: currentPage,
        perPage,
      }),
    queryKey: ['employees'],
    staleTime: 86400000,
  });

  const employees = useMemo(
    () => allEmployeesData?.data?.data,
    [allEmployeesData?.data]
  );

  const totalPages = useMemo(
    () => allEmployeesData?.data?.meta?.total_pages,
    [allEmployeesData?.data]
  );

  const totalCount = useMemo(
    () => allEmployeesData?.data?.meta?.total_count,
    [allEmployeesData?.data]
  );
  const nextPage = useMemo(
    () => allEmployeesData?.data?.meta?.next_page,
    [allEmployeesData?.data]
  );

  const prevPage = useMemo(
    () => allEmployeesData?.data?.meta?.prev_page,
    [allEmployeesData?.data]
  );

  return (
    <EmployeesContext.Provider
      value={{
        currentPage,
        employees,
        // fetchEmployee,
        getAllEmployees,
        isEmpLoading: isPending,
        isFetching,
        isPlaceholderData,
        nextPage,
        prevPage,
        refetch,
        setCurrentPage,
        setPerPage,
        totalCount,
        totalPages,
      }}
    >
      {props.children}
    </EmployeesContext.Provider>
  );
};
