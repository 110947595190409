import React, { useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { AutocompleteElement, FormContainer } from 'react-hook-form-mui';
import { Box, Card, CardContent, CardHeader, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import EditSaveButton from 'components/shared/EditSaveButton';
import LabeledTextColumn from 'components/shared/LabeledTextColumn';
import months from 'constants/month.constants';
import { useOrganization } from 'hooks/useOrganization';
import Organization from 'types/Organization';

const BudgetPeriod = ({
  organization,
}: {
  organization: Organization | undefined;
}) => {
  const { update: updateOrganization } = useOrganization();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);

  const defaultMonthValue = useMemo(
    () => organization?.budget_start_month || 'January',
    [organization?.budget_start_month]
  );

  const formContext = useForm<{
    startMonth: string;
  }>({
    defaultValues: {
      startMonth: organization?.budget_start_month,
    },
  });

  const currentYear = dayjs().get('year');
  const dateFormat = 'MMMM D, YYYY';

  const currentYearStart = useMemo(
    () =>
      dayjs(`${organization?.budget_start_month} 1, ${currentYear}`).format(
        dateFormat
      ),
    [organization, currentYear]
  );

  const periodStart = useMemo(
    () =>
      dayjs().isAfter(currentYearStart)
        ? currentYearStart
        : dayjs(currentYearStart).subtract(1, 'year').format(dateFormat),
    [currentYearStart]
  );

  const periodEnd = useMemo(
    () =>
      dayjs(periodStart).add(1, 'year').subtract(1, 'day').format(dateFormat),
    [periodStart]
  );

  return (
    <Card>
      <FormContainer formContext={formContext}>
        <CardHeader
          action={
            <EditSaveButton
              handleCancel={() => setIsEditing(false)}
              handleEdit={() => {
                formContext.setValue('startMonth', defaultMonthValue);
                setIsEditing(true);
              }}
              handleSave={formContext.handleSubmit(
                ({ startMonth }: FieldValues) => {
                  try {
                    updateOrganization.mutate({
                      budget_start_month: startMonth,
                    });
                    setIsEditing(false);
                  } catch (error) {
                    enqueueSnackbar('Failed to update starting month', {
                      variant: 'error',
                    });
                  }
                }
              )}
              isEditing={isEditing}
              size='small'
            />
          }
          title='Budget periods'
        />
        <CardContent>
          <Stack direction='column' gap={1.5}>
            <Stack direction='row' gap={1.5}>
              <LabeledTextColumn label='Frequency' text='Yearly' />
              {!isEditing && (
                <LabeledTextColumn
                  label='Starting month'
                  text={organization?.budget_start_month || ''}
                />
              )}
            </Stack>
            {isEditing ? (
              <AutocompleteElement
                label='Starting month'
                matchId
                name='startMonth'
                options={months}
                textFieldProps={{
                  helperText:
                    'Budget resets annually on the 1st day of the month starting at 12am.',
                }}
              />
            ) : (
              <Box bgcolor='grey.100' borderRadius={0.75} px={1.5} py={1}>
                <LabeledTextColumn
                  label='Current period'
                  text={`${periodStart} – ${periodEnd}`}
                />
              </Box>
            )}
          </Stack>
        </CardContent>
      </FormContainer>
    </Card>
  );
};

export default BudgetPeriod;
