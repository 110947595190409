import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import RichTextEditor from 'components/shared/RichTextEditor';

const InviteMessageModal = ({
  defaultBody: body,
  defaultSubject: subject,
  method,
  onClose,
  onSubmit,
  show,
}) => {
  const [isSubjectChanged, setIsSubjectChanged] = useState(false);
  const [newBody, setNewBody] = useState('');
  const [newSubject, setNewSubject] = useState(subject);

  useEffect(() => {
    setNewBody(body || '');
  }, [body]);

  const handleBodyChange = (newValue = '') => {
    const updatedMessage = newValue.replace(/&nbsp;|<[^>]+>/g, '')?.trim();

    if (updatedMessage.length === 0) {
      setNewBody('');
    } else {
      setNewBody(newValue);
    }
  };

  const handleSubjectChange = (newValue = '') => {
    setIsSubjectChanged(true);
    setNewSubject(newValue);
  };

  const handleSubjectBlur = () => {
    setNewSubject((prevSubject) => prevSubject.trim());
  };

  const clearChanges = () => {
    setNewBody(body || '');
    if (method === 'email') {
      if (isSubjectChanged) {
        setNewSubject(subject || '');
      }
    }
  };

  const handleSubmit = () => {
    onSubmit(newBody, newSubject);
    onClose();
  };

  return (
    method !== 'calendar' && (
      <Dialog
        aria-label='delete'
        disableEnforceFocus
        fullWidth
        maxWidth='md'
        onClose={() => {
          onClose();
          clearChanges();
        }}
        open={show}
      >
        <DialogTitle>
          Customize the message that will accompany your invite!
        </DialogTitle>
        <DialogContent>
          {method === 'email' && (
            <FormGroup>
              <TextField
                fullWidth
                label='Subject'
                onBlur={handleSubjectBlur}
                onChange={(e) => handleSubjectChange(e.target.value)}
                value={newSubject}
              />
            </FormGroup>
          )}
          <FormGroup>
            <RichTextEditor
              isSlackCompatible={method === 'slack'}
              label='Body'
              onChange={(v) => handleBodyChange(v)}
              value={newBody}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            data-testid='TODO:DATA-BUTTON-15852'
            onClick={() => handleSubmit(method)}
            variant='contained'
          >
            {`${body?.length > 0 ? 'Update' : 'Save'} message`}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

InviteMessageModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.any,
};

export default InviteMessageModal;
