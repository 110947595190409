import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { EventBudgetFormData } from 'types/EventBudget';
import { reactQueryAxios } from 'utils/axios';

const saveEventExpenseItems = async ({
  apiClient,
  data,
}: {
  apiClient: any;
  data: EventBudgetFormData;
}) =>
  apiClient({
    data,
    method: 'post',
    url: `/api/expense_items`,
  });

export const useExpenseSave = ({
  dataHandler = () => undefined,
  eventId,
}: {
  dataHandler?: () => unknown;
  eventId: number;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (data: any) =>
      saveEventExpenseItems({
        apiClient: reactQueryAxios,
        data,
      }),

    onError: (error: Error) => {
      enqueueSnackbar('Failed to add expense items', { variant: 'error' });
      Sentry.captureException(error);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['event', `${eventId}`, 'budget'],
      });
      dataHandler();
    },
  });
};
