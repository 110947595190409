export const imageFileTypes = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
  'image/avif',
];

export const isImageFileType = (file) => imageFileTypes.includes(file?.type);
