import React from 'react';
import {
  Avatar,
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';

import InfoTooltip from 'components/shared/InfoTooltip';

/**
 * Renders <RadioSelect /> component
 * @param {object} props
 * @param {{
 *  id: string|number,
 *  value: string|number,
 *  label: any,
 *  iconProps?: any,
 *  component?: React.ReactNode,
 *  disable?: boolean
 * }[]} props.options options in the radio group
 * @param {Function} props.onChange onchange callback
 * @param {string|number|null} props.value value of currently selected radio option
 * @param {string|null} props.label label for group
 * @param {string} props.variant style of options
 * @param {boolean} props.disabled true if the select field is disabled
 * @param {boolean} props.showComponentWhenDisabled show option component if option is disabled
 * @param {object} props.sx
 */
const RadioSelect = ({
  disabled,
  label,
  labelTooltipText,
  onChange,
  optionClicked,
  options,
  row = false,
  showComponentWhenDisabled = false,
  sx = {},
  value,
  variant,
}) => {
  return (
    <FormControl disabled={disabled} fullWidth sx={sx}>
      {label && (
        <FormLabel id='radio-buttons-group-label'>
          {labelTooltipText ? (
            <InfoTooltip content={labelTooltipText} text={label} />
          ) : (
            label
          )}
        </FormLabel>
      )}
      <RadioGroup
        aria-labelledby='radio-buttons-group-label'
        disabled={disabled}
        name='radio-buttons-group'
        onChange={(event) => {
          !disabled && onChange && onChange(event.target.value);
        }}
        row={row}
        sx={{
          '& .MuiFormControlLabel-label': {
            flexGrow: 1,
            pl: 1,
          },
          flexWrap: 'nowrap',
          ...(variant === 'boxed'
            ? {
                '& .MuiFormControlLabel-root': {
                  '&.Mui-disabled': {
                    bgcolor: 'grey.100',
                  },
                  borderColor: 'grey.300',
                  borderRadius: 0.75,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  ml: 0,
                  p: 1.5,
                },
                gap: 1.5,
              }
            : {}),
          gap: 1,
        }}
        value={value}
      >
        {options.map((option, index) => {
          const optId = option.id || index;
          const optHasSubtitle = option.label?.secondary?.length > 0;
          const iconProps = option.iconProps || null;
          const optElement = (
            <React.Fragment key={optId}>
              <FormControlLabel
                className={value === option.value ? 'Mui-selected' : ''}
                control={<Radio onClick={optionClicked} sx={{ p: 0 }} />}
                disabled={option.disable}
                id={optId}
                key={optId}
                label={
                  <>
                    <ListItem
                      disableGutters
                      disablePadding
                      sx={{ flexGrow: 1 }}
                    >
                      {iconProps && iconProps?.type === 'avatar' && (
                        <ListItemAvatar sx={{ minWidth: 'unset', pr: 1 }}>
                          <Avatar
                            src={iconProps?.src}
                            sx={{
                              height: iconProps?.size,
                              opacity: option?.disable ? 0.5 : 1,
                              width: iconProps?.size,
                            }}
                            variant={iconProps?.variant}
                          />
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        primary={
                          typeof option.label === 'string'
                            ? option.label
                            : option?.label?.primary
                            ? option?.label?.primary
                            : option?.label
                        }
                        secondary={option?.label?.secondary}
                        sx={{
                          flexGrow: 1,
                          m: 0,
                          mt: optHasSubtitle || option.component ? '3px' : 0,
                        }}
                      />
                    </ListItem>
                    {option.component && (
                      <Collapse
                        in={
                          value === option.value ||
                          (option.disable && showComponentWhenDisabled)
                        }
                      >
                        <Box>{option.component}</Box>
                      </Collapse>
                    )}
                  </>
                }
                sx={{
                  mr: 0,
                  ...(optHasSubtitle || option.component
                    ? { alignItems: 'flex-start' }
                    : {}),
                }}
                value={option.value}
              />
            </React.Fragment>
          );
          return option.tooltipText ? (
            <Tooltip
              data-testid='TODO:DATA-TOOLTIP-72781'
              key={optId}
              placement='right'
              title={option.tooltipText}
            >
              <Box>{optElement}</Box>
            </Tooltip>
          ) : (
            optElement
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const valueTypes = [
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.object,
];

RadioSelect.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelTooltipText: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          direction: PropTypes.oneOf(['row', 'column']),
          primary: PropTypes.string,
          secondary: PropTypes.string,
        }),
      ]),
      value: PropTypes.oneOfType([...valueTypes]),
    })
  ),
  row: PropTypes.bool,
  showComponentWhenDisabled: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.oneOfType([...valueTypes]),
};
export default RadioSelect;
