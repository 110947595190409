import { capitalize } from '@mui/material';
import {
  Buildings,
  GlobeHemisphereWest,
  LockSimple,
} from '@phosphor-icons/react';
import { plural } from 'pluralize';

import { EventInvitationGuestType } from 'types/EventInvitationGuestType';

export const eventPrivacyOptions = [
  {
    Icon: GlobeHemisphereWest,
    id: 'open',
    label: {
      direction: 'column',
      primary: 'Public',
      secondary: 'Anyone with a link can access this event.',
    },
    name: 'Public',
    value: 'open',
  },
  {
    Icon: Buildings,
    id: 'organization',
    label: {
      direction: 'column',
      primary: 'Organization',
      secondary: 'Invited guests and members of my organization can RSVP',
    },
    name: 'Organization',
    value: 'organization',
  },
  {
    Icon: LockSimple,
    id: 'invite_only',
    label: {
      direction: 'column',
      primary: 'Private',
      secondary: 'Only people invited can access this event.',
    },
    name: 'Private',
    value: 'invite_only',
  },
];

export const eventInvitationGuestTypes: EventInvitationGuestType[] = [
  { id: 'group' },
  { id: 'list', label: 'contact list' },
  { id: 'office' },
  { id: 'contact' },
  { id: 'channel', isSlackOnly: true },
].map((type: { id: string; isSlackOnly?: boolean; label?: string }) =>
  Object.assign({}, type, {
    label: capitalize(plural(type.label || type.id)),
  } as EventInvitationGuestType)
);

export const eventInvitationGuestTypeLabelsById = Object.fromEntries(
  eventInvitationGuestTypes.map((t) => [t.id, t.label])
);
