import {
  lateRsvpButtonText,
  lateRsvpSubheader,
} from 'components/Events/Controls/Rsvp/Rsvp.constants';
import { OtherBlue, OtherGreen, OtherPurple } from 'utils/colors';

const howToAttendInPerson = 'Please meet us in person at the event location';
const howToAttendVirtually = 'using the button below';

const accessVirtualLink = 'access the virtual link for this event';

const lateRsvpSubheaderVirtual = (eventName: string) =>
  `${lateRsvpSubheader(eventName)} to ${accessVirtualLink}.`;

const virtualTooltipPrefix = `To ${accessVirtualLink}, please RSVP`;

const virtualToolTips = {
  tooltip: {
    default: `${virtualTooltipPrefix}${' '}“Yes” or “Maybe”`,
    late: `${virtualTooltipPrefix}${' '}by clicking the “${lateRsvpButtonText}” button`,
  },
};

type EventLocationTypeId = 'hybrid' | 'inperson' | 'virtual';
interface EventLocationType {
  colors: { dark: string; light: string; main: string };
  label?: string;
  tooltip?: {
    default: string;
    late: string;
  };
  upcomingText: {
    noRsvp: (name: string) => string;
    rsvp: string;
  };
}

export const EventLocationTypes: {
  [key in EventLocationTypeId]: EventLocationType;
} = {
  hybrid: {
    colors: OtherPurple,
    upcomingText: {
      noRsvp: (name) => lateRsvpSubheaderVirtual(name),
      rsvp: `This is a hybrid event. ${howToAttendInPerson} or virtually ${howToAttendVirtually}.`,
    },
    ...virtualToolTips,
  },
  inperson: {
    colors: OtherBlue,
    label: 'In-person',
    upcomingText: {
      noRsvp: (name) => lateRsvpSubheader(name),
      rsvp: `${howToAttendInPerson}.`,
    },
  },
  virtual: {
    colors: OtherGreen,
    upcomingText: {
      noRsvp: (name) => lateRsvpSubheaderVirtual(name),
      rsvp: `Join your virtual event ${howToAttendVirtually}.`,
    },
    ...virtualToolTips,
  },
};
