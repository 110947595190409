import React, { useContext, useEffect, useState } from 'react';
import {
  FormContainer,
  SwitchElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';
import {
  Box,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  Fade,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { UserPlus } from '@phosphor-icons/react';
import * as _ from 'lodash';
import {
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_Updater,
  useMaterialReactTable,
} from 'material-react-table';
import { useSnackbar } from 'notistack';

import CSVUploadModal from 'components/Core/CSVUploadModal';
import { ConditionalLoader } from 'components/Core/Loader';
import ContactListsAutocomplete from 'components/shared/ContactListsAutocomplete';
import DialogFullscreenTitle from 'components/shared/DialogFullscreenTitle';
import DialogFullscreenTransition from 'components/shared/DialogFullscreenTransition';
import EmptyState from 'components/shared/EmptyState';
import GroupsAutocomplete from 'components/shared/GroupsAutocomplete';
import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import { ListItemWithCheckbox } from 'components/shared/ListItemWithCheckbox';
import MRTToolbarAlertBannerContent from 'components/shared/MRTToolbarAlertBannerContent';
import MRTTopToolbarWrapper from 'components/shared/MRTTopToolbarWrapper';
import OfficesAutocomplete from 'components/shared/OfficesAutocomplete';
import {
  getSelectableMRTRowProps,
  getServerSideMRTOptions,
} from 'constants/table.constants';
import { EmployeesContext } from 'contexts/EmployeesContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { useEmployees } from 'hooks/useEmployees';
import { Employee } from 'types/Employee';
import { axiosAuthenticated as axios } from 'utils/axios';
import { fullName } from 'utils/employee';

interface Props {
  handleClose: () => void;
  list: {
    employees: Employee[];
    id: number;
    is_public: boolean;
    name: string;
  };
  lists: any[];
  setLists: (val: any) => void;
  show: boolean;
}

interface EmployeeOption {
  full_name: string;
  id: number;
}

const initialPagination = {
  pageIndex: 0,
  pageSize: 10,
};

const ContactListFormDialog = ({
  handleClose: handleCloseDialog,
  list,
  setLists,
  show,
}: Props) => {
  const { employees } = useContext(EmployeesContext);
  const [org] = useContext(OrganizationContext);

  const { enqueueSnackbar } = useSnackbar();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [isShowingCSV, setShowingCSV] = useState(false);
  const [loading, setLoader] = useState(false);
  const [newUploads, setNewUploads] = useState<Employee[]>([]);
  const [pagination, setPagination] =
    useState<MRT_PaginationState>(initialPagination);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(
    list?.employees && list?.employees?.length > 0
      ? Object.fromEntries(list?.employees?.map((s: any) => [String(s), true]))
      : {}
  );
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(
    list?.employees && list?.employees?.length > 0 ? list?.employees : []
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const form = useForm({
    values: {
      employees: list?.employees || [],
      is_private: list ? list?.is_public === false : false,
      name: list?.name || '',
    },
  });

  useEffect(() => {
    if (list?.employees && list?.employees?.length > 0) {
      setSelectedEmployees(list?.employees);
      setRowSelection(
        Object.fromEntries(
          list?.employees?.map((m: any) => [String(m.id), true])
        )
      );
    }
  }, [list?.employees]);

  const {
    retrieveAll: { data, isLoading, isRefetching },
    retrieveByIds,
  } = useEmployees({
    columnFilters,
    globalFilter,
    pagination,
    sorting,
  });

  const handleGlobalFilterChange = (updater: MRT_Updater<any>) => {
    setGlobalFilter((prevGlobalFilter) =>
      updater instanceof Function ? updater(prevGlobalFilter) : updater
    );
    if (pagination.pageIndex !== 0) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
  };

  const updateSelections = (newValue: any, isDeselecting = false) => {
    setRowSelection(newValue);
    if (!isDeselecting) {
      retrieveByIds({
        selections: newValue,
      }).then((response) => {
        setSelectedEmployees(response.selections);
      });
    } else {
      const newSelections = selectedEmployees.filter((e) =>
        Object.keys(newValue).includes(String(e.id))
      );
      setSelectedEmployees(newSelections);
    }
  };

  const handleRowSelectionChange = (
    updater: MRT_Updater<MRT_RowSelectionState>
  ) => {
    const resolver = (prevRowSelection: any): { [x: string]: boolean } =>
      updater instanceof Function ? updater(prevRowSelection) : updater;
    const newValue = resolver(rowSelection);
    updateSelections(newValue);
  };

  const clearData = () => {
    setSelectedEmployees([]);
    setRowSelection({});
    setPagination(initialPagination);
    setSorting([]);
    setColumnFilters([]);
    setGlobalFilter('');
  };

  const handleClose = () => {
    clearData();
    handleCloseDialog();
  };

  const handleFilterChange = (id: string, newValue: any) => {
    if (pagination.pageIndex !== 0) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
    const updatedFilters = [...columnFilters];
    const filterIndex = columnFilters?.findIndex((f) => f.id === id);
    if (filterIndex === -1) {
      setColumnFilters([...updatedFilters, { id, value: newValue }]);
    } else {
      updatedFilters[filterIndex].value = newValue;
      setColumnFilters(updatedFilters.filter((f: any) => f.value?.length > 0));
    }
  };

  const serverSideProps = getServerSideMRTOptions<EmployeeOption>('45vh');

  const table = useMaterialReactTable({
    ...serverSideProps,
    columns: [
      {
        accessorKey: 'full_name',
        header: 'Name',
      },
    ],
    data: data?.data || [],
    enableRowSelection: true,
    enableStickyFooter: true,
    enableStickyHeader: true,
    muiTableBodyRowProps: getSelectableMRTRowProps,
    onGlobalFilterChange: handleGlobalFilterChange,
    onPaginationChange: setPagination,
    onRowSelectionChange: handleRowSelectionChange,
    onSortingChange: setSorting,
    positionToolbarAlertBanner: 'none',
    renderTopToolbar: ({ table }) => (
      <MRTTopToolbarWrapper
        nounPlural='employees'
        table={table}
        wrapperSxProps={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'flex-start',
        }}
      >
        <>
          <OfficesAutocomplete
            forFilter
            onChange={(e, newValues) =>
              handleFilterChange(
                'office_id',
                newValues?.map((v: any) => v.value)
              )
            }
            value={
              (columnFilters?.find((f) => f.id === 'office_id')
                ?.value as any[]) || []
            }
          />
          <ContactListsAutocomplete
            forFilter
            onChange={(e, newValues) =>
              handleFilterChange(
                'contact_list',
                newValues?.map((v: any) => v.value)
              )
            }
            value={
              (columnFilters?.find((f) => f.id === 'contact_list')
                ?.value as any[]) || []
            }
          />
          <GroupsAutocomplete
            forFilter
            onChange={(e, newValues) =>
              handleFilterChange(
                'groups',
                newValues?.map((v: any) => v.value)
              )
            }
            value={
              (columnFilters?.find((f) => f.id === 'groups')?.value as any[]) ||
              []
            }
          />
          <Collapse in={columnFilters?.length > 0}>
            <Button
              onClick={() => {
                if (pagination.pageIndex !== 0) {
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }
                setColumnFilters([]);
              }}
              size='small'
            >
              Clear filters
            </Button>
          </Collapse>
        </>
      </MRTTopToolbarWrapper>
    ),
    rowCount: data?.meta?.total_count ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      rowSelection,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  useEffect(() => {
    if (newUploads?.length > 0) {
      const newUploadIds =
        newUploads.map((u: { id: string | number }) => u.id) || [];
      const newUploadsAsEmployees: Employee[] =
        employees?.filter((e: Employee) => newUploadIds.includes(e.id)) || [];
      if (newUploads?.length === newUploadsAsEmployees?.length) {
        setSelectedEmployees([
          ...(selectedEmployees || []),
          ...newUploadsAsEmployees,
        ]);
        setNewUploads([]);
      }
    }
  }, [employees, newUploads, selectedEmployees]);

  const createList = () => {
    setLoader(true);
    const name = form.getValues('name');
    axios(
      {
        data: {
          employee_ids: selectedEmployees.map((e) => e.id),
          is_private: form.getValues('is_private'),
          name,
        },
        method: 'post',
        url: `/api/lists`,
      },
      (res) => {
        setLists(res.data);
        // TODO: Determine if we still need clearDataAndClose() here, or if just calling handleClose() will suffice
        // -> handleClose will change the value of "show", which should trigger the useEffect, which calls clearData()
        handleClose();
        setLoader(false);
        enqueueSnackbar(`"${name}" list created!`, {
          variant: 'success',
        });
      },
      () => {
        enqueueSnackbar(
          'We were unable to create this list. Please try again!',
          { variant: 'error' }
        );
        setLoader(false);
      }
    );
  };

  const updateList = (id: number | string) => {
    setLoader(true);
    const name = form.getValues('name');
    axios(
      {
        data: {
          employee_ids: selectedEmployees.map((e) => e.id),
          is_private: form.getValues('is_private'),
          name,
        },
        method: 'patch',
        url: `/api/lists/${id}`,
      },
      (res) => {
        setLists(res.data);
        // TODO: Determine if we still need clearDataAndClose() here, or if just calling handleClose() will suffice
        // -> handleClose will change the value of "show", which should trigger the useEffect, which calls clearData()
        handleClose();
        setLoader(false);
        enqueueSnackbar(`"${name}" list updated!`, {
          variant: 'success',
        });
      },
      () => {
        enqueueSnackbar(
          'We were unable to update this list. Please try again!',
          { variant: 'error' }
        );
        setLoader(false);
      }
    );
  };

  const handleUpload = (uploadedContacts: any[]) => {
    setNewUploads(uploadedContacts);
  };

  return (
    <FormContainer formContext={form}>
      <Dialog
        aria-label={`${list?.id ? 'Edit' : 'Create a '} contact list`}
        data-testid='contact-list-form-dialog'
        fullScreen
        onClose={handleClose}
        open={show}
        PaperProps={{ sx: { bgcolor: 'grey.50' } }}
        scroll='paper'
        TransitionComponent={DialogFullscreenTransition}
      >
        <DialogFullscreenTitle
          buttonProps={{
            disabled:
              form.watch('name')?.length === 0 ||
              selectedEmployees?.length === 0,
            loading,
          }}
          onClose={handleClose}
          onSave={() => (list?.id ? updateList(list?.id) : createList())}
          title={`${list?.id ? 'Edit' : 'Create a '} contact list`}
        />
        <ContentWidthContainer
          ContainerProps={{ component: DialogContent }}
          pb={3}
          pt={2}
        >
          <ConditionalLoader conditions={[!org]}>
            <Stack alignItems='center' direction='row' gap={3}>
              <TextFieldElement
                aria-describedby='name'
                aria-placeholder='Enter list name'
                data-testid='name-textfield'
                id='contact-list-name'
                label='List name'
                margin='none'
                name='name'
                placeholder='Enter list name'
                required
                size='small'
              />
              <FormControl component='fieldset' variant='standard'>
                <FormLabel component='legend' sx={{ mb: 0 }}>
                  Privacy
                </FormLabel>
                <SwitchElement
                  data-testid='privacy-toggle'
                  id='privacy-toggle'
                  label='Only visible to me'
                  name='is_private'
                />
              </FormControl>
            </Stack>
            <Divider sx={{ my: 2 }} />

            <Stack alignItems='center' direction='row' gap={2}>
              <Typography variant='h5'>Select list members</Typography>
              <Button
                data-testid='upload-csv'
                onClick={() => setShowingCSV(true)}
                size='small'
                variant='outlined'
              >
                Upload CSV
              </Button>
            </Stack>
            <Grid columnSpacing={2} container direction='row' height='65vh'>
              <Grid
                data-testid='table-wrapper'
                height='100%'
                item
                lg={9}
                md={8}
                sm={7}
                xs
              >
                <MaterialReactTable table={table} />
              </Grid>
              <Grid
                data-testid='selections'
                height='100%'
                item
                lg={3}
                md={4}
                sm={5}
                xs
              >
                <Card
                  sx={{ height: '100%', minHeight: 355.5, overflow: 'auto' }}
                  variant='outlined'
                >
                  <Collapse
                    collapsedSize={0}
                    in={Object.keys(rowSelection)?.length > 0}
                  >
                    <List dense>
                      <ListSubheader>
                        <MRTToolbarAlertBannerContent table={table} />
                      </ListSubheader>
                      {selectedEmployees?.map((employee: Employee) => (
                        <ListItemWithCheckbox
                          handleToggle={() => {
                            const newRowSelection = _.omit(
                              rowSelection,
                              String(employee.id)
                            );
                            updateSelections(newRowSelection, true);
                          }}
                          key={employee.id}
                          label={fullName(employee)}
                          value
                        />
                      ))}
                    </List>
                  </Collapse>
                  <Fade
                    in={!selectedEmployees || selectedEmployees?.length === 0}
                  >
                    <Box
                      alignItems='center'
                      display='flex'
                      flexDirection='column'
                      height='100%'
                      justifyContent='center'
                      px={1}
                      width='100%'
                    >
                      <EmptyState
                        Icon={UserPlus}
                        primary='No employees selected yet'
                        secondary={`Click the employees you'd like to add to this group`}
                      />
                    </Box>
                  </Fade>
                </Card>
              </Grid>
            </Grid>
            <CSVUploadModal
              noSegment
              onClose={() => setShowingCSV(false)}
              onUpload={(contacts: any[]) => {
                handleUpload(contacts);
              }}
              show={isShowingCSV}
            />
          </ConditionalLoader>
        </ContentWidthContainer>
      </Dialog>
    </FormContainer>
  );
};

export default ContactListFormDialog;
