import React from 'react';
import { Box, capitalize, Chip, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';

import useEventDateTimeRange from 'hooks/useEventDateTimeRange';
import { EventDetailsFormWatch } from 'types/EventDetailsForm';
import { EventLocationTypes } from 'utils/location.constants';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const EventPreviewHeader = ({
  isPublished,
  watch,
}: {
  isPublished: boolean;
  watch: EventDetailsFormWatch;
}) => {
  const start = watch('start');
  const ends = watch('ends');
  const name = watch('name');
  const locationType = watch('locationType');
  const eventDateTimeRange = useEventDateTimeRange({ ends, start });
  const inToolbar = false;

  return (
    <Box {...(inToolbar ? { minWidth: 0 } : {})}>
      <Stack alignItems='center' direction='row' gap={2}>
        <Typography
          data-testid='event-title'
          maxWidth='80vw'
          {...(inToolbar
            ? {
                sx: { overflow: 'hidden' },
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }
            : {})}
          variant={
            inToolbar
              ? isPublished || location.pathname.includes('public')
                ? 'h1'
                : 'h4'
              : location.pathname.indexOf('view') !== -1 ||
                location.pathname.includes('public')
              ? 'h1'
              : 'h2'
          }
        >
          {name || 'New Event'}
        </Typography>
      </Stack>
      <Stack alignItems='center' direction='row' gap={2}>
        <Typography color='grey.600' component='div' variant='body2'>
          {eventDateTimeRange}
        </Typography>
        {locationType && (
          <Chip
            label={
              EventLocationTypes[locationType]?.label ||
              capitalize(locationType)
            }
            size='medium'
            sx={{
              bgcolor: EventLocationTypes[locationType]?.colors?.light,
              color: EventLocationTypes[locationType]?.colors?.dark,
              fontWeight: 600,
              // INFO: Prevent chip from increasing the height of this row when displayed
              my: -0.5,
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default EventPreviewHeader;
