import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import CSVUploadModal from 'components/Core/CSVUploadModal';
import IntegrationCard from 'components/Settings/Integrations/IntegrationCard';
import { EmployeesContext } from 'contexts/EmployeesContext';

const EmployeeDataUpload = () => {
  const { getAllEmployees, setCurrentPage } = useContext(EmployeesContext);
  const [isShowingModal, setShowModal] = useState(false);

  const formatUploadData = (employeesToAdd) => {
    const formattedEmployeeData = employeesToAdd?.map((emp) => ({
      active: emp?.status?.toLowerCase() === 'active',
      birthday: dayjs(emp?.birthday)?.format('YYYY/MM/DD') ?? null,
      email: emp?.email?.trim(),
      ethnicity: emp?.ethnicity?.toLowerCase()?.trim(),
      first_name: emp?.first_name?.trim(),
      gender: emp?.gender?.toLowerCase()?.trim(),
      last_name: emp?.last_name?.trim(),
      office: emp?.office?.trim(),
      role: emp?.role?.trim(),
      start_date: dayjs(emp.start_date)?.format('YYYY/MM/DD') ?? null,
    }));
    return {
      contacts: formattedEmployeeData,
    };
  };

  const handleUpload = () => {
    setCurrentPage(1);
    getAllEmployees({ page: 1 });
  };

  return (
    <>
      <IntegrationCard
        buttonText='Upload CSV'
        description='Upload a CSV of your employee data to add contacts to Five to Nine and
        capture employee demographic information.'
        imageName='employee-csv-upload'
        name='Employee upload'
        onSetUp={() => setShowModal(true)}
      />
      <CSVUploadModal
        dataFormatter={(employeesToAdd) => formatUploadData(employeesToAdd)}
        file='/files/Sample - HRIS.csv'
        hideUploadDetail
        instruction='Upload a CSV file of your employee data, with employee data fields as columns.'
        noSegment
        onClose={() => setShowModal(false)}
        onUpload={handleUpload}
        show={isShowingModal}
        toastMessage='Your CSV was uploaded.'
        url='/api/organizations/upload_employees'
      />
    </>
  );
};

EmployeeDataUpload.propTypes = {
  setIsLoading: PropTypes.func,
};

export default EmployeeDataUpload;
