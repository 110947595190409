import React from 'react';
import { NumericFormat } from 'react-number-format';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { omit } from 'lodash';

import Currency from 'types/Currency';

export interface CurrencyTextFieldProps extends BaseTextFieldProps {
  alignInput?: 'right' | 'left';
  allowNegative?: boolean;
  currency?: Currency;
  fullWidth?: boolean;
  onChange: (val: any) => void;
  onEnter?: (val: any) => void;
  placeholder?: string;
  value?: number;
}

const CurrencyTextField = ({
  alignInput = 'right',
  allowNegative = false,
  currency,
  fullWidth = false,
  label,
  onChange,
  onEnter,
  placeholder,
  value,
  variant = 'outlined',
  ...props
}: CurrencyTextFieldProps) => (
  <NumericFormat
    allowNegative={allowNegative}
    customInput={TextField}
    decimalScale={2}
    fullWidth={fullWidth}
    inputProps={{ sx: { textAlign: alignInput } }}
    InputProps={{
      startAdornment: currency ? currency?.symbol : '$',
    }}
    label={label}
    max={2147483647} // INFO: Max value for a 32-bit signed integer - RoR limit
    onKeyDown={(event) => {
      switch (event.key) {
        case 'ArrowDown':
          if ((value && value > 0) || allowNegative) {
            onChange(Number(value) - 1);
          }
          break;
        case 'ArrowUp':
          onChange(Number(value) + 1);
          break;
        case 'Enter':
          onEnter && onEnter(value);
          break;
        default:
          break;
      }
    }}
    onValueChange={(values) => {
      onChange(values?.value ? Number(values.value) : null);
    }}
    placeholder={placeholder}
    thousandSeparator
    value={value === null ? '' : value}
    variant={variant}
    {...omit(props, ['defaultValue', 'type'])}
  />
);

export default CurrencyTextField;
