import React from 'react';
import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CheckCircle, X } from '@phosphor-icons/react';

import { DarkGrayColor, PrimaryColor } from 'utils/color';

const TableWithSmallFont = styled(Table)(({ theme }) => ({
  [`.${tableCellClasses.head}`]: {
    fontSize: 13,
    textAlign: 'center',
  },
  [`.${tableCellClasses.body}`]: {
    [`&:not(:first-child)`]: {
      textAlign: 'center',
    },
    fontSize: 13,
  },
}));

export const UserRolesTable = (props) => {
  const footnoteProps = {
    display: 'block',
    fontSize: 11,
    lineHeight: 1.1,
    sx: {
      my: 0.75,
    },
  };
  return (
    <>
      <TableContainer>
        <TableWithSmallFont
          data-testid='permission-detail'
          padding='none'
          size='small'
        >
          <TableHead>
            <TableRow className='permission-header'>
              <TableCell width='40%'>&nbsp;</TableCell>
              <TableCell width='20%'>Admin</TableCell>
              <TableCell width='20%'>Organizer</TableCell>
              <TableCell width='20%'>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>View events</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Create events</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Manage events<sup>1</sup>
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>View groups</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Create groups</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Manage groups<sup>2</sup>
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Manage contacts</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Manage user permissions</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Manage platform settings</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Manage integrations</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>View analytics</TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <CheckCircle color={PrimaryColor} size={20} weight='fill' />
              </TableCell>
              <TableCell>
                <X color={DarkGrayColor} size={20} />
              </TableCell>
            </TableRow>
          </TableBody>
        </TableWithSmallFont>
      </TableContainer>
      <Typography {...footnoteProps}>
        [1] An organizer must also be the co-host of an event to manage/view its
        analytics
      </Typography>
      <Typography {...footnoteProps}>
        [2] An organizer must also be the admin of a group to manage/view its
        analytics
      </Typography>
    </>
  );
};
