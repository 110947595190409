import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Export, Link, PaperPlaneTilt } from '@phosphor-icons/react';

import SurveyIndividualTab from 'components/Events/Controls/Analytics/SurveyIndividualTab';
import SurveySelector from 'components/Events/Controls/Analytics/SurveySelector';
import SurveySummaryTab from 'components/Events/Controls/Analytics/SurveySummaryTab';
import EventGuestsOverview from 'components/Events/Controls/GuestList/EventGuestsOverview';
import ConfirmSendSurvey from 'components/Events/Controls/Surveys/ConfirmSendSurvey';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import InfoTooltip from 'components/shared/InfoTooltip';
import TabLabelWithCount from 'components/shared/TabLabelWithCount';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { getEventLink, hasStarted } from 'utils/event';
import { hasSurvey } from 'utils/survey';

export const EventAnalytics = () => {
  const {
    actions: { analyticsExport, sendSurvey },
    state: { savedEvent, surveyType },
  } = useContext(ManagerContext);
  const { copy } = useCopyToClipboard();
  const navigate = useNavigate();

  const [currTab, setCurrTab] = useState(0);
  const [isConfirmingSend, setIsConfirmingSend] = useState(false);

  const survey = useMemo(
    () => savedEvent?.[surveyType?.value]?.analytics_data,
    [savedEvent, surveyType?.value]
  );

  const tabs = [
    {
      component: <SurveySummaryTab />,
      key: 'summary',
      label: `Summary`,
    },
    {
      component: <SurveyIndividualTab />,
      key: 'individualSurveys',
      label: 'Individual surveys',
      labelCount: `${survey?.unique_responders?.length}`,
    },
  ];

  const handleSendSurveys = () => {
    sendSurvey(surveyType?.value, () => setIsConfirmingSend(false));
  };

  return (
    <>
      <Stack direction='column' justifyContent='flex-start' spacing={2}>
        <Stack direction='column' gap={1.5}>
          <InfoTooltip
            content='All counts refer to unique guests on the guest list. “Virtual” and “In-person” counts refer to attendees.'
            text='Guest list overview'
            textVariant='h4'
          />
          <EventGuestsOverview />
        </Stack>
        <Stack direction='column' gap={1.5}>
          <Typography component='h2' variant='h4'>
            Survey responses
          </Typography>
          {hasSurvey(savedEvent) && (
            <Stack
              alignItems='flex-end'
              direction={{ md: 'row', xs: 'column' }}
              gap={2}
              justifyContent='space-between'
            >
              <SurveySelector />
              <Stack
                alignItems='flex-end'
                direction='row'
                gap={1}
                justifyContent='flex-end'
              >
                <Button
                  data-testid='copy-survey-link'
                  onClick={() =>
                    copy(
                      getEventLink('survey', surveyType?.value, savedEvent),
                      'survey link'
                    )
                  }
                  startIcon={<Link data-testid='TODO:DATA-LINK-36370' />}
                  variant='text'
                >
                  Copy survey link
                </Button>
                {surveyType?.value === 'post_event_survey' &&
                  hasStarted(savedEvent) && (
                    <Button
                      data-testid='send-survey'
                      onClick={() => setIsConfirmingSend(true)}
                      startIcon={<PaperPlaneTilt />}
                      variant='text'
                    >
                      Send survey
                    </Button>
                  )}
                <Button
                  data-testid='export'
                  onClick={() => analyticsExport(savedEvent.name)}
                  startIcon={<Export />}
                  variant='bordered'
                >
                  Export
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Card data-testid='TODO:DATA-CARD-21883'>
          {hasSurvey(savedEvent) && (
            <Tabs
              aria-label='select guests tabs'
              onChange={(ev, val) => setCurrTab(val)}
              value={currTab}
            >
              {tabs?.map(({ key, label, labelCount }, idx) => (
                <Tab
                  key={key}
                  label={
                    <TabLabelWithCount
                      count={+labelCount}
                      label={label}
                      variant={currTab === idx ? 'standard' : 'light'}
                    />
                  }
                  value={idx}
                />
              ))}
            </Tabs>
          )}
          <CardContent sx={{ px: 3 }}>
            {hasSurvey(savedEvent) ? (
              <Stack direction='column' gap={3}>
                {tabs[currTab].component}
              </Stack>
            ) : (
              <Alert
                action={
                  <Button
                    color='info'
                    data-testid='create-a-survey'
                    onClick={() => navigate(`/event/${savedEvent?.id}/surveys`)}
                    size='small'
                    variant='text'
                  >
                    Create a survey
                  </Button>
                }
                data-testid='create-one-and-share-it-with-your-guests-to-see-the-results-here!'
                severity='info'
              >
                <AlertTitle>
                  This event doesn&apos;t have any surveys
                </AlertTitle>
                Create one and share it with your guests to see the results
                here!
              </Alert>
            )}
          </CardContent>
        </Card>
      </Stack>
      <ConfirmSendSurvey
        handleClose={() => setIsConfirmingSend(false)}
        handleSendSurveys={handleSendSurveys}
        show={isConfirmingSend}
        type={surveyType?.value}
      />
    </>
  );
};
