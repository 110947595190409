import React, { useRef } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { MRT_Row } from 'material-react-table';

import { Invitee } from 'gql/graphql';
import { truncateText } from 'utils/styles';

const EventGuestsTableNameCell = ({ row }: { row: MRT_Row<Invitee> }) => {
  const nameRef = useRef<any>();
  const emailRef = useRef<any>();
  return (
    <Stack direction='column' gap={0.5} maxWidth='100%'>
      <Tooltip
        placement='right-end'
        title={
          nameRef?.current?.scrollWidth > nameRef?.current?.clientWidth
            ? row.original.fullName
            : null
        }
      >
        <Typography
          component={Box}
          ref={nameRef}
          sx={truncateText}
          variant='body2'
        >
          {row.original.fullName}
        </Typography>
      </Tooltip>
      <Tooltip
        placement='right-end'
        title={
          emailRef?.current?.scrollWidth > emailRef?.current?.clientWidth
            ? row.original.email
            : null
        }
      >
        <Typography
          color='grey.600'
          component={Box}
          ref={emailRef}
          sx={truncateText}
          variant='body1'
        >
          {row.original.email}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default EventGuestsTableNameCell;
