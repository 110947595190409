import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Renders <Dropdown /> component
 * @param {object} props
 * @param {string} props.label Label to display with the control
 * @param {string|string[]} props.value currently selected value(s)
 * @param {object} props.options options that are available to select from
 * @param {Function} props.onChange on change event callback
 * @param {string} props.place placeholder to display if no value is supplied
 * @param {string} props.className class list to apply to underlying select control
 * @param {object} props.dropdownStyles select styles object to override default with
 * @param {object} props.components components to be used by underlying select, see doc
 * @param {boolean} props.groupOnChange set to true to get the full selection array in onchange rather than individual selections
 * @param {boolean} props.isMulti set to true to allow selecting multiple values
 * @param {boolean} props.requireOne set to true to disallow removing of the last selected item
 * @param {string} props.tooltip optional tooltip to display next to the label
 * @param {boolean} props.disabled true if the dropdown field is disabled
 */
const Dropdown = ({
  disabled: isDisabled,
  isMulti,
  label,
  onChange,
  options,
  place,
  value,
  blurOnSelect = false,
  ...props
}) => {
  // const useStyles = _.assign({}, dropdownStyles || customStyles);
  return (
    (<Autocomplete
      blurOnSelect={blurOnSelect}
      disabled={isDisabled}
      id={`${label?.replace(' ', '-').toLowerCase()}-select`}
      multiple={isMulti}
      onChange={onChange}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={place} />
      )}
      value={value}
      {...props}
    />)
  );
};
// <Select
//   isMulti={isMulti}
//   value={value}
//   onChange={
//     groupOnChange ? (s, a) => onChange(selectionArray(s, a)) : onChange
//   }
//   options={options}
//   styles={useStyles}
//   disableClearable
//   placeholder={place}
//   components={components}
//   menuPortalTarget={document.body}
//   theme={DropdownTheme}
//   isDisabled={isDisabled}
// />

Dropdown.propTypes = {
  blurOnSelect: PropTypes.bool,
  className: PropTypes.string,
  components: PropTypes.object,
  disabled: PropTypes.bool,
  dropdownStyles: PropTypes.object,
  groupOnChange: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  place: PropTypes.string,
  requireOne: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.any,
};

export default Dropdown;
