import React from 'react';
import { ListSubheader, Typography } from '@mui/material';

import TabLabelWithCount from 'components/shared/TabLabelWithCount';

const SharedCalendarList = ({
  cals,
  children,
  label,
  showCount,
}: {
  cals: any[];
  children: React.ReactElement;
  label: string;
  showCount: boolean;
}) => (
  <>
    <ListSubheader sx={{ bgcolor: 'grey.200', px: 3 }}>
      {showCount ? (
        <TabLabelWithCount count={cals?.length} label={label} />
      ) : (
        label
      )}
    </ListSubheader>
    {cals?.length > 0 ? (
      <>{children}</>
    ) : (
      <Typography color='text.secondary' sx={{ px: 3, py: 2 }} variant='body1'>
        No calendars
      </Typography>
    )}
  </>
);

export default SharedCalendarList;
