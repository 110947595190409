import React, { useState } from 'react';
import { Button } from '@mui/material';
import { NotePencil, Plus } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import InviteMessageModal from 'components/Events/Controls/Shared/InviteMessageModal';

const InviteMessage = ({
  defaultBody: body,
  defaultSubject: subject,
  disable,
  hasMessage,
  method,
  onSubmit,
}) => {
  const [showingMessageModal, setShowingMessageModal] = useState(false);

  return (
    <>
      <Button
        data-testid='custom-message'
        disabled={disable}
        onClick={() => !disable && setShowingMessageModal(true)}
        startIcon={hasMessage ? <NotePencil /> : <Plus />}
        sx={{ alignSelf: 'flex-start', mb: 2 }}
        variant='bordered'
      >
        {`${hasMessage ? 'Edit' : 'Add a '}`} custom message
      </Button>
      <InviteMessageModal
        defaultBody={body}
        defaultSubject={subject}
        method={method}
        onClose={() => setShowingMessageModal(false)}
        onSubmit={onSubmit}
        show={showingMessageModal}
      />
    </>
  );
};

InviteMessage.propTypes = {
  body: PropTypes.string,
  disable: PropTypes.bool,
  hasMessage: PropTypes.bool,
  subject: PropTypes.string,
};

export default InviteMessage;
