import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { Eye, PaperPlaneTilt } from '@phosphor-icons/react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import SendPreviewEmailModal from 'components/Events/Controls/GuestList/SendPreviewEmailModal';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import RichTextEditor from 'components/shared/RichTextEditor';
import { AuthContext } from 'contexts/AuthContext';

const SendGuestsEmailModal = ({ onClose, selections, show }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const {
    actions: { emailGuests },
  } = useContext(ManagerContext);

  const [newMessage, setNewMessage] = useState('');
  const [newSubject, setNewSubject] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [previewEmail, setPreviewEmail] = useState(user?.email);

  const handleClose = () => {
    if (showPreview) {
      setPreviewEmail(user?.email);
      setShowPreview(false);
    } else {
      onClose();
      setNewMessage('');
      setNewSubject('');
      setShowDeleteModal(false);
    }
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleSend = () => {
    emailGuests(
      showPreview ? previewEmail : selections,
      newSubject,
      newMessage,
      showPreview
    );
    handleClose();
  };

  const handleMessageChange = (newValue = '') => {
    const updatedMessage = newValue.replace(/&nbsp;|<[^>]+>/g, '').trim();
    if (updatedMessage.length === 0) {
      setNewMessage('');
    } else {
      setNewMessage(newValue);
    }
  };

  const handleSubjectChange = (newValue = '') => {
    if (newValue?.trim().length === 0) {
      setNewSubject('');
    }
    setNewSubject(newValue?.trimStart());
  };

  return (
    <Dialog
      aria-label='Message Guests'
      maxWidth='md'
      onClose={handleClose}
      open={show}
    >
      <DialogTitle>Send message to guests</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your message below, then it will be sent to the selected
          {` ${pluralize('guest', selections?.length)}`} via email.
        </DialogContentText>
        <FormGroup>
          <TextField
            fullWidth
            label='Subject'
            onChange={(e) => handleSubjectChange(e.target.value)}
            value={newSubject}
          />
        </FormGroup>
        <FormGroup>
          <RichTextEditor
            label='Message'
            onChange={(v) => handleMessageChange(v)}
            value={newMessage}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Stack alignItems='center' direction='row' gap={38}>
          <Button
            data-testid='send-preview'
            disabled={newMessage?.length === 0 || newSubject?.length === 0}
            onClick={handlePreview}
            startIcon={<Eye />}
            variant='outlined'
          >
            Send preview
          </Button>

          <Stack direction='row' gap={2}>
            <Button
              data-testid='cancel'
              onClick={() =>
                newMessage?.length > 0 && newSubject?.length > 0
                  ? setShowDeleteModal(true)
                  : handleClose()
              }
              variant='text'
            >
              Cancel
            </Button>
            <Button
              data-testid='send-message'
              disabled={newMessage?.length === 0 || newSubject?.length === 0}
              onClick={handleSend}
              startIcon={<PaperPlaneTilt />}
              variant='contained'
            >
              Send message
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
      <SendPreviewEmailModal
        email={previewEmail}
        handleClose={handleClose}
        sendPreviewEmail={handleSend}
        setPreviewEmail={setPreviewEmail}
        show={showPreview}
      />
      <DeleteModal
        custom={{
          byline:
            "You haven't sent this message to your selected guests yet. If you continue, your message and subject will be discarded.",
          confirm: 'Yes, discard message',
          title: `Are you sure?`,
        }}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={() => handleClose()}
        show={showDeleteModal}
      />
    </Dialog>
  );
};

SendGuestsEmailModal.propTypes = {
  onClose: PropTypes.func,
  selections: PropTypes.array,
  show: PropTypes.bool,
};
export default SendGuestsEmailModal;
