import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { alpha, Button, Stack, Typography, useTheme } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import { isArray } from 'lodash';
import { useSnackbar } from 'notistack';

import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import EditGroup from 'components/Groups/EditGroup';
import GroupBudget from 'components/Groups/GroupBudget';
import GroupLoader from 'components/Groups/GroupLoader';
import Members from 'components/Groups/Members';
import ViewGroup from 'components/Groups/ViewGroup';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import TabsLayout from 'components/shared/layout/TabsLayout';
import SlackSyncedChip from 'components/shared/SlackSyncedChip';
import { AuthContext } from 'contexts/AuthContext';
import { EmployeesContext } from 'contexts/EmployeesContext';
import { GroupContext } from 'contexts/GroupContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';

const Group = () => {
  const { dispatch, state: editGroup } = useContext(GroupContext);
  const { employees } = useContext(EmployeesContext);
  const [groups, setGroups] = useContext(GroupsContext);
  const [org] = useContext(OrganizationContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const navigate = useNavigate();
  const { id, tab } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [group, findGroup] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [tryingClose, setTryClose] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (groups) {
      findGroup(groups?.find((g) => g.id === parseInt(id)));
    }
  }, [editGroup, group, groups, id]);

  useEffect(() => {
    if (group) {
      dispatch({ group, type: 'SET_GROUP' });
    }
  }, [dispatch, group]);

  useEffect(() => {
    if (group && editGroup && tab === 'edit') {
      if (editGroup?.coverImageChanged) {
        setHasUnsavedChanges(true);
      }
      const changeWatchList = [
        'name',
        'external_link',
        'description',
        'group_type_id',
        'coverImageChanged',
        'private',
        'office_ids',
        ['group_admin_ids', 'admin_employee_ids'],
      ];
      const hasChanged = changeWatchList.map((key) => {
        if (key === 'coverImageChanged') {
          return editGroup.coverImageChanged;
        }
        if (isArray(key)) {
          return (
            JSON.stringify(editGroup[key[0]]?.sort()) !==
            JSON.stringify(group[key[1]]?.sort())
          );
        } else {
          return editGroup[key] !== group[key];
        }
      });
      setHasUnsavedChanges(hasChanged.some((val) => val === true));
    } else {
      setHasUnsavedChanges(false);
    }
  }, [editGroup, group, tab, hasUnsavedChanges, setHasUnsavedChanges]);

  const handleContextSetting = useCallback(
    (data, switchTab = true) => {
      const index = groups?.findIndex((g) => g.id === data.id);
      groups[index] = data;
      setGroups(groups);
      findGroup(data);
      if (switchTab) {
        navigate(`/group/${data.id}`);
      }
    },
    [groups, setGroups, navigate]
  );

  const handleGroupUpdate = useCallback(
    (data) => {
      handleContextSetting(data);
      setHasUnsavedChanges(false);
      enqueueSnackbar('Group updated!', { variant: 'success' });
    },
    [enqueueSnackbar, handleContextSetting]
  );

  const handleGroupFailure = useCallback(
    (error) => {
      enqueueSnackbar(`Something went wrong! ${error}`, {
        variant: 'error',
      });
    },
    [enqueueSnackbar]
  );

  const handleLeaveGroup = useCallback(
    (data) => {
      handleContextSetting(data);
      enqueueSnackbar('You have left this group.', {
        variant: 'success',
      });
    },
    [enqueueSnackbar, handleContextSetting]
  );

  const handleJoinGroup = useCallback(
    (data, message, switchTab = true) => {
      handleContextSetting(data, switchTab);
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar, handleContextSetting]
  );

  const canEdit = useMemo(
    () =>
      user?.is_admin ||
      group?.admin_employee_ids?.includes(user?.employee_id) ||
      (group?.can_organizers_edit && user?.is_organizer),
    [
      group?.admin_employee_ids,
      group?.can_organizers_edit,
      user?.employee_id,
      user?.is_admin,
      user?.is_organizer,
    ]
  );

  const tabs = useMemo(
    () => [
      {
        component: (
          <ViewGroup
            group={group}
            groups={groups}
            handleJoinGroup={(data) =>
              handleJoinGroup(data, 'You have joined this group!')
            }
            handleLeaveGroup={handleLeaveGroup}
            org={org}
            setTab={(tab) => navigate(`/group/${id}/${tab}`)}
            user={user}
          />
        ),
        label: 'View group',
        value: 'view',
      },
      ...(canEdit
        ? [
            {
              component: (
                <EditGroup
                  group={group}
                  handleGroupFailure={handleGroupFailure}
                  handleGroupUpdate={handleGroupUpdate}
                  hasUnsavedChanges={hasUnsavedChanges}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              ),
              label: 'Edit group',
              value: 'edit',
            },
            {
              component: (
                <Members
                  canEdit={canEdit}
                  employees={employees}
                  group={group}
                  setGroup={(g) => handleContextSetting(g, false)}
                  syncedChannel={group?.synced_slack_channel}
                />
              ),
              label: 'Members',
              value: 'members',
            },
            {
              component: <GroupBudget />,
              label: 'Budget',
              value: 'budget',
            },
          ]
        : []),
    ],
    [
      canEdit,
      employees,
      group,
      groups,
      handleContextSetting,
      handleGroupFailure,
      handleGroupUpdate,
      handleJoinGroup,
      handleLeaveGroup,
      hasUnsavedChanges,
      org,
      user,
      navigate,
      id,
    ]
  );

  const backToGroupsButton = (
    <Button
      data-testid='back-to-groups'
      onClick={() => navigate('/groups')}
      startIcon={<ArrowLeft />}
    >
      Back to Groups
    </Button>
  );

  return (
    <GroupLoader condition={group && user && org && id && employees}>
      {tabs.length === 1 ? (
        <DefaultLayout button={backToGroupsButton} title={group?.name}>
          {tabs[0].component}
        </DefaultLayout>
      ) : (
        <>
          <TabsLayout
            button={backToGroupsButton}
            hasUnsavedChanges={hasUnsavedChanges}
            tabs={tabs}
            title={
              <Stack alignItems='center' direction='row' gap={3}>
                <Typography variant='inherit'>{group?.name}</Typography>
                {group?.synced_slack_channel && (
                  <SlackSyncedChip
                    size='medium'
                    sx={{
                      backgroundColor: alpha(theme.palette.info.light, 50),
                      maxHeight: 32,
                    }}
                  />
                )}
              </Stack>
            }
          />
          {tab === 'edit' && (
            <UnsavedChangesModal
              hasUnsavedChanges={hasUnsavedChanges}
              onCancel={() => {
                setTryClose(false);
              }}
              onConfirm={() => {
                setHasUnsavedChanges(false);
                dispatch({ group, type: 'SET_GROUP' });
              }}
              tryClose={tryingClose}
            />
          )}
        </>
      )}
    </GroupLoader>
  );
};

export default Group;
