import { useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';

import { EventDetailsFormData } from 'types/EventDetailsForm';

const useEventFormDefaultValues = (fromGroupId: number | null, user: any) => {
  const today = dayjs();
  const neverPublicFlagEnabled = useFlag('event-privacy-never-public');

  return useMemo<EventDetailsFormData>(
    () => ({
      // Object with the default values for fields (when there's no event loaded & the user hasn't modified the field yet)
      ends: today
        .add(7, 'day')
        .set('hour', 10)
        .set('minute', 0)
        .set('second', 0),
      groupIds: fromGroupId ? [fromGroupId] : [],
      hostIds: [user?.employee_id],
      hosts: {
        displayOrganizersAsHosts: false,
      },
      officeIds: [],
      privacy: neverPublicFlagEnabled ? 'organization' : 'open',
      resources: [],
      start: today
        .add(7, 'day')
        .set('hour', 9)
        .set('minute', 0)
        .set('second', 0),
    }),
    [fromGroupId, neverPublicFlagEnabled, today, user?.employee_id]
  );
};

export default useEventFormDefaultValues;
