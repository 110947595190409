/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import {
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  getMRT_RowSelectionHandler,
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_Updater,
  useMaterialReactTable,
} from 'material-react-table';

import SearchStartAdornment from 'components/Core/SearchStartAdornment';
import {
  CommunicationMethodId,
  InvitationFormData,
} from 'components/Events/Controls/Communications/communication.types';
import InvitationSelectContactsFilter, {
  LogicalOperator,
} from 'components/Events/Controls/Invites/InvitationSelectContactsFilter';
import { InvitationSelectGuestStatusChip } from 'components/Events/Controls/Invites/InvitationSelectGuestStatusChip';
import MRTToolbarAlertBannerContent from 'components/shared/MRTToolbarAlertBannerContent';
import RadioLabel from 'components/shared/RadioLabel';
import {
  getDefaultMRTOptions,
  getServerSideMRTOptions,
} from 'constants/table.constants';
import { useEventInvitation } from 'hooks/useEventInvitation';
import { useEventInvitationOptions } from 'hooks/useEventInvitationOptions';
import { EventInvitationGuestTypeId } from 'types/EventInvitationGuestType';

interface Contact {
  email: string;
  id: number;
  name: string;
  scheduled_for: string | null;
  sent_at: string | null;
}

const InvitationSelectContactsTable = () => {
  const { commId, id, method } = useParams<{
    commId?: string;
    id?: string;
    method?: CommunicationMethodId;
  }>();
  const eventId = Number(id);
  const invitationId = Number(commId) || 'new';
  const form = useFormContext<InvitationFormData>();

  const [, setAutoSelect] = useState(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  // const [fixedIds, setFixedIds] = useState<number[]>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [logicalOperator, setLogicalOperator] =
    useState<LogicalOperator>('any');
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const {
    retrieve: { data, isLoading, isRefetching },
  } = useEventInvitationOptions({
    columnFilters,
    eventId,
    globalFilter,
    invitationId,
    logicalOperator,
    method,
    optionType: 'contact' as EventInvitationGuestTypeId,
    pagination,
    sorting,
  });

  const rowSelection = form.watch(`rowSelection.contact`);
  const selectedGuestIds = form.watch(`selectedGuestIds`);

  const isCheckable = useCallback(
    (row: MRT_Row<Contact>) => {
      const isChecked = rowSelection[String(row?.id)];
      const wasPreviouslyInvited =
        (row?.original?.scheduled_for &&
          row?.original?.scheduled_for?.length > 0) ||
        (row?.original?.sent_at && row?.original?.sent_at?.length > 0);
      const isSelectedAsMember =
        selectedGuestIds &&
        selectedGuestIds?.size > 0 &&
        selectedGuestIds.has(Number(row?.id));
      return !wasPreviouslyInvited && (isSelectedAsMember ? isChecked : true);
    },
    [rowSelection, selectedGuestIds]
  );

  const { retrieveSelectionData } = useEventInvitation({
    eventId,
    invitationId,
    method,
  });

  const columns = useMemo<MRT_ColumnDef<Contact>[]>(
    () => [
      {
        accessorKey: 'name',
        // eslint-disable-next-line react/no-multi-comp
        Cell: ({ row }: { row: MRT_Row<Contact> }) => (
          <Stack
            alignItems='center'
            direction='row'
            gap={2}
            justifyContent='space-between'
            width='100%'
          >
            <RadioLabel
              primary={row.original.name}
              secondary={row.original.email}
            />
            <InvitationSelectGuestStatusChip row={row as MRT_Row<any>} />
          </Stack>
        ),
        grow: true,
        header: 'Name',
      },
    ],
    []
  );

  const handleRowSelectionChange = (
    updater: MRT_Updater<MRT_RowSelectionState>
  ) => {
    const resolver = (prevRowSelection: any): { [x: string]: boolean } =>
      updater instanceof Function ? updater(prevRowSelection) : updater;
    const newValue = resolver(rowSelection);
    form.setValue(`rowSelection.contact`, newValue);
    retrieveSelectionData({
      guestType: 'contact',
      selections: newValue,
    }).then((val: { selections: any[] }) => {
      form.setValue(`selections.contact`, val.selections);
    });
  };

  const clearSelections = () => {
    form.setValue(`rowSelection.contact`, {});
    form.setValue(`selections.contact`, []);
  };

  const serverSideMRTOptions = getServerSideMRTOptions<Contact>();

  const table = useMaterialReactTable({
    ...serverSideMRTOptions,
    columnFilterDisplayMode: 'custom',
    columns,
    columnVirtualizerOptions: {
      overscan: 5,
    },
    data: data?.data || [],
    displayColumnDefOptions: {
      'mrt-row-select': {
        grow: false,
      },
    },
    enableRowSelection: (row: MRT_Row<Contact>) => isCheckable(row),
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
    },
    muiTableBodyRowProps: ({ row, staticRowIndex, table }) => {
      const isSelectable = isCheckable(row);
      return {
        onClick: (event) => {
          if (isSelectable) {
            getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event);
          }
        },
        sx: {
          ':NOT(.Mui-selected)&:hover td::after': {
            bgcolor: isSelectable ? 'grey.100' : 'white',
          },
          height: 56,
          ...(isSelectable && { cursor: 'pointer' }),
        },
      };
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onRowSelectionChange: handleRowSelectionChange,
    onSortingChange: setSorting,
    positionToolbarAlertBanner: 'bottom',
    renderToolbarAlertBannerContent: ({ table }) => (
      <MRTToolbarAlertBannerContent
        table={table}
        {...getDefaultMRTOptions()}
        onClear={clearSelections}
      />
    ),
    renderTopToolbar: ({ table }) => (
      <Box
        sx={{
          alignItems: 'center',
          bgcolor: 'white',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'flex-start',
          pb: 1,
        }}
      >
        <MRT_GlobalFilterTextField
          InputProps={{ startAdornment: <SearchStartAdornment /> }}
          size='medium'
          sx={{
            '& .MuiInputAdornment-positionEnd': {
              opacity: globalFilter?.length > 0 ? 1 : 0,
            },
          }}
          table={table}
        />
        <InvitationSelectContactsFilter
          columnFilters={columnFilters}
          logicalOperator={logicalOperator}
          pagination={pagination}
          setAutoSelect={setAutoSelect}
          setColumnFilters={setColumnFilters}
          setLogicalOperator={setLogicalOperator}
          sorting={sorting}
        />
      </Box>
    ),
    rowCount: data?.meta?.total_count ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading: isLoading || isRefetching,
      pagination,
      rowSelection,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable table={table} />;
};

export default InvitationSelectContactsTable;
