import {
  CalendarPlus,
  ChatCircleDots,
  Check,
  Clipboard,
  ClockCountdown,
  EnvelopeSimple,
  SlackLogo,
  SpinnerGap,
  UserCirclePlus,
  Warning,
} from '@phosphor-icons/react';
import { keyBy } from 'lodash';

import {
  CommunicationMethod,
  CommunicationMethodId,
  CommunicationStatus,
  CommunicationType,
  CommunicationTypeId,
} from 'components/Events/Controls/Communications/communication.types';
import { attendedStatusDetails } from 'components/Events/Controls/GuestList/EventGuests.constants';
import { rsvpResponseValues } from 'components/Events/Controls/Rsvp/Rsvp.constants';

export const communicationMethods: CommunicationMethod[] = [
  {
    color: 'otherBlue',
    Icon: SlackLogo,
    id: 'slack' as CommunicationMethodId,
    label: 'Slack',
    tooltip: {
      integration: `Slack invitations are currently unavailable. 
      To enable Slack invitations, please contact a system administrator to integrate with your organization's Slack instance.`,
      invitees: `No Slack channels have been invited to this event. 
      To message guests via Slack, please invite the channel you would like to message.`,
      rsvpPause: `You cannot use this invitation method because RSVP pausing was enabled when this event was published.`,
    },
  },
  {
    color: 'otherPurple',
    Icon: CalendarPlus,
    id: 'calendar' as CommunicationMethodId,
    tooltip: {
      integration: `Calendar invitations are currently unavailable. 
      To enable Calendar invitations, please set up your Google calendar integration from the profile page.`,
      rsvpPause: `You cannot use this invitation method because RSVP pausing was enabled when this event was published.`,
    },
  },
  {
    color: 'otherPink',
    Icon: EnvelopeSimple,
    id: 'email' as CommunicationMethodId,
    tooltip: {
      rsvpPause: `You cannot use this invitation method because RSVP was closed`,
    },
  },
].map((m) =>
  // INFO: Sets label to id value if not already defined
  ({ ...m, label: m.label || m.id })
);

export const communicationMethodDetails = keyBy(communicationMethods, 'id');

export const communicationTypes: CommunicationType[] = [
  {
    buttonLabel: `Invite guests`,
    Icon: UserCirclePlus,
    id: 'invitation' as CommunicationTypeId,
    subheader: `Invite employees to your event and collect RSVPs by sending or scheduling invitations through`, // Slack, email, or calendar.
    subheaderAlt: `You can no longer invite guests because your event has ended. View your guest list to see who was invited.`,
  },
  {
    buttonLabel: `Send message`,
    Icon: ChatCircleDots,
    id: 'message' as CommunicationTypeId,
    subheader: `Stay in touch with your guest list by sending or scheduling a message through`, // Slack or email.
    subheaderAlt: `You cannot send messages at this time because your guest list is empty. 
                   To send messages to your guests, please send out event invitations to add invitees to the guest list.`,
  },
  {
    Icon: Clipboard,
    id: 'survey' as CommunicationTypeId,
    label: 'Post-event survey',
  },
];

export const communicationTypeDetails = keyBy(communicationTypes, 'id');

export const communicationStatuses: CommunicationStatus[] = [
  {
    color: 'success',
    Icon: Check,
    id: 'sent',
  },
  {
    color: 'warning',
    Icon: ClockCountdown,
    id: 'scheduled',
  },
  {
    color: 'info',
    Icon: SpinnerGap,
    id: 'drafted',
    label: 'Updating',
  },
  {
    color: 'info',
    Icon: SpinnerGap,
    id: 'sending',
  },
  {
    color: 'error',
    Icon: Warning,
    id: 'failed',
  },
  {
    color: 'error',
    Icon: Warning,
    id: 'reschedule',
  },
];

export const communicationStatusDetails = keyBy(communicationStatuses, 'id');

const attendedStatus = attendedStatusDetails['yes'];

export const messageEmailRecipients: any[] = [
  ...rsvpResponseValues.map((v) => ({ ...v, type: 'Invitation status' })),
  {
    ...attendedStatus,
    color: attendedStatus.colorName,
    id: 'attended',
    type: 'Guest attendance status',
  },
];
