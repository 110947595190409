import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  Box,
  Grid,
  Stack,
  Typography,
  Alert,
  AlertTitle,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Paper,
} from '@mui/material';
import { AddCircleOutline, EventAvailable, StickyNote2 } from '@mui/icons-material';
import AnnouncementRecipientsField from 'components/Collections/AnnouncementRecipientsField';
import CommunicationMessageField from 'components/Events/Controls/Communications/CommunicationMessageField';
import AnnouncementSlackPreview from 'components/Collections/AnnouncementSlackPreview';

interface Props {
  form: UseFormReturn<any>;
  collection: any;
}

const CollectionAnnouncementFieldsAndPreview: React.FC<Props> = ({ form, collection }) => {
  const { register, watch } = form;
  const messageBody = watch('message_body');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Card sx={{ padding: 2 }}>
          <CardContent>
            <Stack spacing={4}>
              <Stack spacing={1}>
                <Box display="flex" alignItems="center">
                  <AddCircleOutline sx={{ marginRight: 1 }} />
                  <Typography variant="h4">Recipients</Typography>
                </Box>
                <Box mt={1}>
                  <AnnouncementRecipientsField
                    hookForm={form}
                    collectionId={collection.id}
                  />
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Members of selected Slack channels will be added to the guest list for all events in this collection, unless already invited.
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Box display="flex" alignItems="center">
                  <EventAvailable sx={{ marginRight: 1 }} />
                  <Typography variant="h4">Calendar invitation</Typography>
                </Box>
                <FormControlLabel
                  control={<Switch {...register('send_calendar_invites')} />}
                  label={
                    <Box>
                      <Typography variant="body1" component="span">
                        Send recipients calendar invitations
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ display: 'block', mt: 0.5 }}>
                        Add calendar invitations for all Slack channel members.
                      </Typography>
                    </Box>
                  }
                  sx={{ alignItems: 'flex-start', marginTop: '0', marginBottom: '0', '.MuiFormControlLabel-label': { marginLeft: 0 } }}
                />
              </Stack>
              <Stack spacing={1}>
                <Box display="flex" alignItems="center">
                  <StickyNote2 sx={{ marginRight: 1 }} />
                  <Typography variant="h4">Message</Typography>
                </Box>
                <Box mt={1}>
                  <CommunicationMessageField hookForm={form} isSlack={true} hideLabel />
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <Grid display='flex' flexDirection='column' gap={2} item md={6} xs={12}>
        <Alert severity="info">
          <AlertTitle>This is a preview of your Slack message</AlertTitle>
        </Alert>
        <Card>
          <CardContent>
            <AnnouncementSlackPreview
              customMessage={messageBody}
              customPreviewComponent
              collection={collection}
              slackAppName="Collection App"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CollectionAnnouncementFieldsAndPreview;
