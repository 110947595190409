import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  List,
  ListSubheader,
  Stack,
} from '@mui/material';
import { UserPlus } from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';
import { AxiosError } from 'axios';
import * as _ from 'lodash';
import {
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_Updater,
  useMaterialReactTable,
} from 'material-react-table';
import { useSnackbar } from 'notistack';

import ContactListsAutocomplete from 'components/shared/ContactListsAutocomplete';
import EmptyState from 'components/shared/EmptyState';
import { ListItemWithCheckbox } from 'components/shared/ListItemWithCheckbox';
import MRTToolbarAlertBannerContent from 'components/shared/MRTToolbarAlertBannerContent';
import MRTTopToolbarWrapper from 'components/shared/MRTTopToolbarWrapper';
import OfficesAutocomplete from 'components/shared/OfficesAutocomplete';
import {
  getSelectableMRTRowProps,
  getServerSideMRTOptions,
} from 'constants/table.constants';
import { useEmployees } from 'hooks/useEmployees';
import { Employee } from 'types/Employee';
import { axiosAuthenticated as axios } from 'utils/axios';

interface Props {
  employees: Employee[];
  group: {
    employee_ids: number[];
    id: number;
  };
  setGroup: (g: any) => void;
  setShow: (s: boolean) => void;
  show: any;
}

const initialPagination = {
  pageIndex: 0,
  pageSize: 10,
};

const initialSorting = [{ desc: false, id: 'full_name' }];

const AddGroupMembers = ({ group, setGroup, setShow, show }: Props) => {
  const contactListFlagEnabled = useFlag('contact-lists');
  const { enqueueSnackbar } = useSnackbar();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] =
    useState<MRT_PaginationState>(initialPagination);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>(initialSorting);

  const {
    retrieveAll: { data, isLoading, isRefetching },
    retrieveByIds,
  } = useEmployees({
    columnFilters,
    globalFilter,
    pagination,
    sorting,
  });

  const handleGlobalFilterChange = (updater: MRT_Updater<any>) => {
    setGlobalFilter((prevGlobalFilter) =>
      updater instanceof Function ? updater(prevGlobalFilter) : updater
    );
    if (pagination.pageIndex !== 0) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
  };

  const handleRowSelectionChange = (
    updater: MRT_Updater<MRT_RowSelectionState>
  ) => {
    const resolver = (prevRowSelection: any): { [x: string]: boolean } =>
      updater instanceof Function ? updater(prevRowSelection) : updater;
    const newValue = resolver(rowSelection);
    setRowSelection(newValue);
    retrieveByIds({
      selections: newValue,
    }).then((val: { selections: any[] }) => {
      setSelectedEmployees(val.selections);
    });
  };

  const handleClose = () => {
    setShow(false);
    setSelectedEmployees([]);
    setRowSelection({});
    setPagination(initialPagination);
    setSorting(initialSorting);
    setColumnFilters([]);
    setGlobalFilter('');
  };

  const handleFilterChange = (id: string, newValue: any) => {
    if (pagination.pageIndex !== 0) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
    const updatedFilters = [...columnFilters];
    const filterIndex = columnFilters?.findIndex((f) => f.id === id);
    if (filterIndex === -1) {
      setColumnFilters([...updatedFilters, { id, value: newValue }]);
    } else {
      updatedFilters[filterIndex].value = newValue;
      setColumnFilters(updatedFilters.filter((f: any) => f.value?.length > 0));
    }
  };

  const massJoin = () => {
    const toAddEmployeeIds = Object.keys(rowSelection || {}).map((s: any) =>
      Number(s)
    );
    axios(
      {
        data: {
          employee_ids: toAddEmployeeIds,
        },
        method: 'post',
        url: `/api/groups/${group.id}/mass_join`,
      },
      (res) => {
        setGroup(res.data);
        enqueueSnackbar('Group members updated!', {
          variant: 'success',
        });
        handleClose();
      },
      (error: AxiosError) => {
        console.log(error);
        enqueueSnackbar(
          'Something went wrong when trying to add members, please try again.',
          { variant: 'error' }
        );
      }
    );
  };

  const serverSideProps = getServerSideMRTOptions<Employee>('45vh');

  const table = useMaterialReactTable({
    ...serverSideProps,
    columns: [
      {
        accessorKey: 'full_name',
        header: 'Name',
      },
    ],
    data: data?.data || [],
    enableRowSelection: (row) =>
      group?.employee_ids?.indexOf(Number(row.id)) === -1,
    enableStickyFooter: true,
    enableStickyHeader: true,
    muiTableBodyRowProps: getSelectableMRTRowProps,
    onGlobalFilterChange: handleGlobalFilterChange,
    onPaginationChange: setPagination,
    onRowSelectionChange: handleRowSelectionChange,
    onSortingChange: setSorting,
    positionToolbarAlertBanner: 'none',
    renderTopToolbar: ({ table }) => (
      <MRTTopToolbarWrapper
        table={table}
        wrapperSxProps={{
          alignItems: 'flex-start',
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          gap: 1,
        }}
      >
        <Stack alignItems='flex-start' direction='row' flex={1} gap={1}>
          <OfficesAutocomplete
            forFilter
            onChange={(e, newValues) =>
              handleFilterChange(
                'office_id',
                newValues?.map((v: any) => v.value)
              )
            }
            sx={{ flex: 1, minWidth: 160 }}
            value={
              (columnFilters?.find((f) => f.id === 'office_id')
                ?.value as any[]) || []
            }
          />
          {contactListFlagEnabled && (
            <ContactListsAutocomplete
              forFilter
              onChange={(e, newValues) =>
                handleFilterChange(
                  'contact_list',
                  newValues?.map((v: any) => v.value)
                )
              }
              sx={{ flex: 1, minWidth: 160 }}
              value={
                (columnFilters?.find((f) => f.id === 'contact_list')
                  ?.value as any[]) || []
              }
            />
          )}
        </Stack>
      </MRTTopToolbarWrapper>
    ),
    rowCount: data?.meta?.total_count ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      rowSelection,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <Dialog
      data-testid='add-group-members'
      fullWidth
      maxWidth='lg'
      onClose={handleClose}
      open={show}
    >
      <DialogTitle>Add members to group</DialogTitle>
      <DialogContent>
        <Grid container direction='row' spacing={3}>
          <Grid data-testid='members-wrapper' item sm={8} xs>
            <MaterialReactTable table={table} />
          </Grid>
          <Grid item sm={4} xs>
            <Card sx={{ height: '60vh', overflow: 'auto' }} variant='outlined'>
              <Collapse
                collapsedSize={0}
                in={Object.keys(rowSelection)?.length > 0}
              >
                <List dense>
                  <ListSubheader>
                    <MRTToolbarAlertBannerContent table={table} />
                  </ListSubheader>
                  {selectedEmployees?.map((employee: Employee) => (
                    <ListItemWithCheckbox
                      handleToggle={() =>
                        handleRowSelectionChange(
                          _.omit(rowSelection, String(employee.id))
                        )
                      }
                      key={employee.id}
                      label={employee.name}
                      value
                    />
                  ))}
                </List>
              </Collapse>
              <Fade in={!selectedEmployees || selectedEmployees?.length === 0}>
                <Box
                  alignItems='center'
                  display='flex'
                  flexDirection='column'
                  height='100%'
                  justifyContent='center'
                  px={1}
                  width='100%'
                >
                  <EmptyState
                    Icon={UserPlus}
                    primary='No employees selected yet'
                    secondary={`Click the employees you'd like to add to this group`}
                  />
                </Box>
              </Fade>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button data-testid='cancel' onClick={handleClose} variant='outlined'>
          Cancel
        </Button>
        <Button
          color='primary'
          data-testid='add-selected-to-group'
          disabled={selectedEmployees?.length === 0}
          onClick={massJoin}
          variant='contained'
        >
          Add selected to Group
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGroupMembers;
