import React from 'react';
import { MenuItem, Select, Stack } from '@mui/material';
import {
  ArrowUDownLeft,
  ArrowUDownRight,
  Eraser,
  ListBullets,
  ListNumbers,
  Quotes,
  TextBolder,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
} from '@phosphor-icons/react';
import { Editor } from '@tiptap/react';

import ButtonDivider from 'components/shared/ButtonDivider';
import RichTextEmoji from 'components/shared/RichTextEmoji';
import RichTextLink from 'components/shared/RichTextLink';
import RichTextToolbarButton from 'components/shared/RichTextToolbarButton';

const RichTextToolbar = ({
  editor,
  isSlackCompatible,
}: {
  editor: Editor | null;
  isSlackCompatible: boolean;
}) => {
  const ToolbarButton = RichTextToolbarButton;
  const styleOptions = ['Normal', 'Heading 1', 'Heading 2', 'Heading 3'];
  const handleStyleChange = (e: { target: { value: any } }) => {
    const newValue = e.target.value;
    editor &&
      editor
        .chain()
        .focus()
        .toggleHeading({
          level:
            newValue > 0 ? newValue : editor?.getAttributes('heading')?.level,
        })
        .run();
  };

  if (!editor) {
    return null;
  }

  return (
    <Stack
      alignItems='center'
      columnGap={0.75}
      direction='row'
      flexWrap='wrap'
      rowGap={0.5}
      sx={{
        bgcolor: 'grey.100',
        borderBottomColor: 'grey.300',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        padding: 1,
      }}
    >
      {isSlackCompatible ? null : (
        <Select
          data-testid='font-style-menu'
          onChange={handleStyleChange}
          onMouseDown={(e) => e.preventDefault()}
          sx={{ width: 117 }}
          value={editor?.getAttributes('heading')?.level || 0}
        >
          {styleOptions.map((opt, index) => (
            <MenuItem key={opt} value={index}>
              {opt}
            </MenuItem>
          ))}
        </Select>
      )}
      <ToolbarButton
        onClick={() => editor?.chain()?.focus()?.toggleBold()?.run()}
        onMouseDown={(e) => e.preventDefault()}
        selected={editor.isActive('bold')}
        value='bold'
      >
        <TextBolder size={24} weight='bold' />
      </ToolbarButton>
      <ToolbarButton
        onClick={() => editor?.chain()?.focus()?.toggleItalic()?.run()}
        onMouseDown={(e) => e.preventDefault()}
        selected={editor.isActive('italic')}
        value='italic'
      >
        <TextItalic size={24} />
      </ToolbarButton>
      {!isSlackCompatible && (
        <ToolbarButton
          onClick={() => editor?.chain()?.focus()?.toggleUnderline()?.run()}
          onMouseDown={(e) => e.preventDefault()}
          selected={editor.isActive('underline')}
          value='underline'
        >
          <TextUnderline size={24} />
        </ToolbarButton>
      )}
      <ToolbarButton
        onClick={() => editor?.chain()?.focus()?.toggleStrike()?.run()}
        onMouseDown={(e) => e.preventDefault()}
        selected={editor.isActive('strike')}
        value='strike'
      >
        <TextStrikethrough size={24} />
      </ToolbarButton>
      <ButtonDivider />
      <RichTextLink editor={editor} />
      <RichTextEmoji editor={editor} />
      <ButtonDivider />
      <ToolbarButton
        onClick={() => editor?.chain()?.focus()?.toggleBulletList()?.run()}
        onMouseDown={(e) => e.preventDefault()}
        selected={editor.isActive('bulletList')}
        value='bulletList'
      >
        <ListBullets size={24} />
      </ToolbarButton>
      <ToolbarButton
        onClick={() => editor?.chain()?.focus()?.toggleOrderedList()?.run()}
        onMouseDown={(e) => e.preventDefault()}
        selected={editor.isActive('orderedList')}
        value='orderedList'
      >
        <ListNumbers size={24} />
      </ToolbarButton>
      <ToolbarButton
        onClick={() => editor?.chain()?.focus()?.toggleBlockquote()?.run()}
        onMouseDown={(e) => e.preventDefault()}
        selected={editor.isActive('blockquote')}
        value='blockquote'
      >
        <Quotes size={24} />
      </ToolbarButton>
      <ButtonDivider />
      <ToolbarButton
        onClick={() => {
          editor?.chain()?.focus()?.unsetAllMarks()?.run();
          editor?.chain()?.focus()?.clearNodes()?.run();
        }}
        onMouseDown={(e) => e.preventDefault()}
        value='clear'
      >
        <Eraser size={24} />
      </ToolbarButton>
      <ToolbarButton
        disabled={!editor?.can()?.chain()?.focus()?.undo()?.run()}
        onClick={() => editor?.chain()?.focus()?.undo()?.run()}
        onMouseDown={(e) => e.preventDefault()}
        value='undo'
      >
        <ArrowUDownLeft size={24} />
      </ToolbarButton>
      <ToolbarButton
        disabled={!editor?.can()?.chain()?.focus()?.redo()?.run()}
        onClick={() => editor?.chain()?.focus()?.redo()?.run()}
        onMouseDown={(e) => e.preventDefault()}
        value='redo'
      >
        <ArrowUDownRight size={24} />
      </ToolbarButton>
    </Stack>
  );
};

export default RichTextToolbar;
