import { useMemo } from 'react';

import { EventInvitation } from 'gql/graphql';

export const useInvitedSlackChannels = (
  invitations: EventInvitation[],
  syncedChannels?: any[]
) => {
  const invitedChannelIds = useMemo(() => {
    if (invitations?.length > 0) {
      const slackInvitations = invitations?.filter(
        (h: EventInvitation) => h.inviteType === 'slack' && h.isSent
      );
      if (slackInvitations?.length > 0) {
        const slackChannelRecipients = slackInvitations?.flatMap(
          (h: EventInvitation) =>
            h?.recipients?.filter(
              (r: any) => r.recipientType === 'SlackChannel'
            )
        );
        if (slackChannelRecipients?.length > 0) {
          const slackChannelRecipientIds = slackChannelRecipients?.map(
            (r: any) => r.recipientId
          );
          return new Set(slackChannelRecipientIds);
        }
      }
    }
    return new Set([]);
  }, [invitations]);

  const invitedChannels = useMemo(
    () =>
      syncedChannels && syncedChannels?.length > 0
        ? syncedChannels
            ?.filter((c: any) => invitedChannelIds?.has(c?.id))
            ?.map((i: any) => ({ ...i, id: i.slack_id }))
        : [],
    [invitedChannelIds, syncedChannels]
  );

  return { invitedChannelIds, invitedChannels };
};
