/* eslint-disable react/jsx-pascal-case */
import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import {
  MRT_GlobalFilterTextField,
  MRT_TableHeadCellFilterContainer,
  MRT_TableInstance,
} from 'material-react-table';

import SearchStartAdornment from 'components/shared/SearchStartAdornment';
import { hasNoData } from 'utils/mrtTable';

type Props = {
  children: React.ReactElement;
  filterColumnKeys?: string[];
  table: MRT_TableInstance<any>;
};

const TableTopToolbar = ({ children, filterColumnKeys, table }: Props) => {
  const hasActiveFilter = useCallback(
    (table: MRT_TableInstance<any>) =>
      table.getState().columnFilters?.length > 0 &&
      table.getState().columnFilters.some((cf: any) => cf?.value?.length > 0),
    []
  );

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 1,
        justifyContent: 'space-between',
        pb: 1,
        ...(hasNoData(table) ? { display: 'none' } : {}),
      }}
    >
      <Stack
        alignItems='center'
        direction='row'
        gap={1}
        justifyContent='flex-start'
      >
        <MRT_GlobalFilterTextField
          InputProps={{ startAdornment: <SearchStartAdornment /> }}
          margin='none'
          size='medium'
          sx={{
            '& .MuiInputAdornment-positionEnd': {
              opacity: table.getState().globalFilter?.length > 0 ? 1 : 0,
            },
          }}
          table={table}
        />
        {filterColumnKeys &&
          filterColumnKeys?.length > 0 &&
          table
            .getLeafHeaders()
            .filter((header) => filterColumnKeys?.includes(header.id))
            .map((header) => (
              <MRT_TableHeadCellFilterContainer
                header={header}
                in
                key={header.id}
                sx={{
                  '& .MuiChip-root': {
                    '& .MuiChip-label': {
                      fontSize: 14,
                    },
                    py: 0.5,
                  },
                  '& .MuiInputAdornment-positionEnd': {
                    maxWidth: 24,
                    opacity: hasActiveFilter(table) ? 1 : 0,
                    px: hasActiveFilter(table) ? 2 : 0,
                    transition: 'all 300ms ease-in-out',
                    zIndex: hasActiveFilter(table) ? 1 : -1,
                  },
                  '& .MuiInputBase-root': {
                    '& .MuiSelect-select': {
                      mr: -6,
                    },
                    display: 'inline-flex',
                  },
                  pr: hasActiveFilter(table) ? 2 : 0,
                }}
                table={table}
              />
            ))}
      </Stack>
      {children}
    </Box>
  );
};

export default TableTopToolbar;
