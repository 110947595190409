import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, Dialog, Stack } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { MinusCircle, PencilSimple, Plus } from '@phosphor-icons/react';

import DeleteModal from 'components/Core/DeleteModal';
import Search from 'components/Core/Search';
import OfficeActions from 'components/Settings/OfficeActions';
import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const OfficeSettings = ({ offices: allOffices }) => {
  const [organization, setOrganization] = useContext(OrganizationContext);

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const closeForm = () => {
    setIsCreating(false);
    setIsEditing(false);
    setIsConfirmingDelete(false);
    setSelectedRowIds([]);
  };

  const handleRemove = (officeId, onDone) => {
    axios({ method: 'delete', url: `/api/offices/${officeId}` }, (res) => {
      setOrganization(res.data);
      setIsConfirmingDelete(false);
      enqueueSnackbar('Office was deleted.', { variant: 'success' });
      onDone();
    });
  };

  const offices = useMemo(
    () =>
      searchVal?.length > 0
        ? allOffices?.filter((o) =>
            o?.name.toLowerCase()?.includes(searchVal?.toLowerCase())
          )
        : allOffices,
    [allOffices, searchVal]
  );

  const selectedOffice =
    selectedRowIds.length > 0
      ? allOffices.find((o) => o.id === selectedRowIds[0])
      : null;

  const columns = [
    {
      field: 'name',
      flex: 2,
      headerName: 'Office',
    },
    {
      field: 'users_count',
      flex: 1,
      headerName: 'Users',
      type: 'number',
    },
    {
      field: 'events_count',
      flex: 1,
      headerName: 'Events',
      type: 'number',
    },
    {
      field: 'groups_count',
      flex: 1,
      headerName: 'Groups',
      type: 'number',
    },
    {
      field: 'actions',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<PencilSimple size={24} />}
          key={`${params.id}-edit-button`}
          label='Edit'
          onClick={() => {
            setSelectedRowIds([params.row.id]);
            setIsEditing(true);
          }}
        />,
        <GridActionsCellItem
          icon={<MinusCircle size={24} />}
          key={`${params.id}-delete-button`}
          label='Delete'
          onClick={(e) => {
            setSelectedRowIds([params.row.id]);
            setIsConfirmingDelete(true);
          }}
        />,
      ],
      headerName: 'Edit | Remove',
      minWidth: 125,
      type: 'actions',
    },
  ];

  return (
    <Box>
      <Stack direction='column' gap={2}>
        <Stack direction='row' justifyContent='space-between'>
          <Search
            label='Search offices'
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder='Search offices'
            value={searchVal}
          />
          <Button
            color='primary'
            data-testid='new-office'
            onClick={() => {
              setSelectedRowIds([]);
              setIsCreating(true);
            }}
            startIcon={<Plus />}
            variant='contained'
          >
            New office
          </Button>
        </Stack>
        {columns && (
          <FtnDataGrid
            columns={columns}
            data-testid='TODO:DATA-FTNDATAGRID-56665'
            disableRowSelectionOnClick
            isRowSelectable={false}
            noun='office'
            rows={offices}
          />
        )}
      </Stack>
      <Dialog
        data-testid='TODO:DATA-DIALOG-26583'
        onClose={closeForm}
        open={isCreating || isEditing}
      >
        <OfficeActions
          office={selectedOffice}
          onUpdate={() => {
            setSelectedRowIds([]);
            closeForm();
          }}
        />
      </Dialog>
      <DeleteModal
        noun='Office'
        onCancel={closeForm}
        onDelete={(onDone) => {
          handleRemove(selectedOffice?.id, onDone);
        }}
        owner={organization.display_name}
        show={isConfirmingDelete}
        subject={selectedOffice?.name}
      />
    </Box>
  );
};

export default OfficeSettings;
