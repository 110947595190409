import * as amplitude from '@amplitude/analytics-browser';

const Amplitude = () => {
  const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;

  if (amplitudeApiKey != null) {
    amplitude.init(amplitudeApiKey, {
      defaultTracking: true,
    });
  }

  return null;
};

export default Amplitude;
