import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useUnleashClient } from '@unleash/proxy-client-react';
import axios from 'axios';
import PropTypes from 'prop-types';

import Landing from 'components/Landing/Landing';

export const PublicOrgContext = createContext();

export const PublicOrgProvider = (props) => {
  const [publicOrg, setPublicOrg] = useState();
  // We are going to load data immediately, so let's keep from flip flopping the ConditionalLoaders until we are done with the initial load
  const [isLoading, setIsLoading] = useState(true);

  const unleashClient = useUnleashClient();

  useEffect(() => {
    const hostParts = window.location.host.split('.');
    let subdomain = '';
    if (hostParts.length > 1) {
      if (hostParts[0] === 'd335fxah1ssbos') {
        subdomain = 'ftn';
      } else {
        subdomain = hostParts[0];
      }
    }

    setIsLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_BE_URL
        }/api/organizations/subdomain?subdomain=${subdomain}`
      )
      .then((res) => {
        setPublicOrg(res.data);

        unleashClient.setContextField('organizationId', res.data.id);
        unleashClient.setContextField('organizationSlug', res.data.subdomain);

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [unleashClient]);

  const active = useMemo(() => publicOrg?.active !== false, [publicOrg]);

  return (
    <PublicOrgContext.Provider value={[publicOrg, isLoading]}>
      {active ? props.children : <Landing isLoading={isLoading} />}
    </PublicOrgContext.Provider>
  );
};

PublicOrgProvider.propTypes = {
  children: PropTypes.any,
};
