import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const OktaOidcSettings = ({
  clientId,
  setClientId,
  secret,
  setSecret,
  issuer,
  setIssuer,
}) => {
  return (
    <>
      <TextField
        fullWidth
        id='okta-client-id'
        label='Okta Client ID'
        onChange={(e) => setClientId(e.target.value)}
        placeholder='0oaqf2zf8sA0aw1g20h7'
        value={clientId}
      />
      <TextField
        fullWidth
        id='okta-client-secret'
        label='Okta Client Secret'
        onChange={(e) => setSecret(e.target.value)}
        placeholder='PnKTy8h82OUK-_5uTw-VFctdzOUuZMhI3l2WegOg'
        value={secret}
      />
      <TextField
        fullWidth
        helperText='Your Okta Organization URL'
        id='okta-issuer'
        label='Okta Issuer'
        onChange={(e) => setIssuer(e.target.value)}
        placeholder='https://dev-986945.oktapreview.com'
        value={issuer}
      />
    </>
  );
};

OktaOidcSettings.propTypes = {
  clientId: PropTypes.any,
  issuer: PropTypes.any,
  secret: PropTypes.any,
  setClientId: PropTypes.func,
  setIssuer: PropTypes.func,
  setSecret: PropTypes.func,
};

export default OktaOidcSettings;
