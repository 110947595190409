import React from 'react';
import PropTypes from 'prop-types';

import QuestionNps from 'components/Core/Questions/QuestionNps';
import QuestionWithOptions from 'components/Core/Questions/QuestionWithOptions';
import ShortAnswer from 'components/Core/Questions/ShortAnswer';

const PreviewQuestion = ({
  displayPosition,
  handleSupplyResponse,
  noOrder = false,
  options,
  previewTemplate,
  question,
  questionIndex,
  responses,
  setLeftEmailField,
}) => {
  const questionProps = {
    key: question?.id || questionIndex,
    onSupplyResponse: handleSupplyResponse,
    options: options
      ? options.filter((opt) =>
          question.id
            ? opt.survey_question_id === question.id
            : opt.survey_question_id === question.position
        )
      : null,
    question,
    questionIndex,
    questionPosition: question?.position || displayPosition,
    readonly: previewTemplate,
    value: question?.id
      ? responses[question.id]
      : responses[-Math.abs(questionIndex)],
  };

  if (question?._destroy === 1) {
    return null;
  } else {
    switch (question?.question_type) {
      case 'short':
        return (
          <ShortAnswer
            {...questionProps}
            noOrder={noOrder}
            noun={question?.noun}
            setLeftEmailField={setLeftEmailField}
          />
        );
      case 'nps':
        return <QuestionNps {...questionProps} />;
      case 'multi_select':
      case 'single_select':
        return <QuestionWithOptions {...questionProps} />;
      default:
        return null;
    }
  }
};

PreviewQuestion.propTypes = {
  displayPosition: PropTypes.any,
  handleSupplyResponse: PropTypes.func,
  noOrder: PropTypes.bool,
  options: PropTypes.array,
  previewTemplate: PropTypes.any,
  question: PropTypes.shape({
    _destroy: PropTypes.number,
    id: PropTypes.any,
    noun: PropTypes.string,
    position: PropTypes.any,
    question_type: PropTypes.any,
  }),
  questionIndex: PropTypes.number,
  responses: PropTypes.any,
  setLeftEmailField: PropTypes.any,
};

export default PreviewQuestion;
