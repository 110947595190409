import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import RadioSelect from 'components/Core/RadioSelect';
import { GroupContext } from 'contexts/GroupContext';

const GroupPrivacy = ({ setField }) => {
  const { state: group } = useContext(GroupContext);

  const onChange = (value) => {
    setField('private', value);
  };
  const isPrivate = group.private;

  return (
    <RadioSelect
      label='Visibility'
      onChange={onChange}
      options={[
        {
          id: 'open',
          label: {
            primary: 'Organization',
            secondary:
              'Anyone in the organization can access & join this group',
          },
          value: false,
        },
        {
          id: 'invite_only',
          label: {
            primary: 'Private',
            secondary: 'Only accessible by group members',
          },
          value: true,
        },
      ]}
      value={isPrivate}
    />
  );
};

GroupPrivacy.propTypes = {
  setField: PropTypes.func,
};

export default GroupPrivacy;
