import { useMemo } from 'react';

import { Event } from 'hooks/useGroupBudget';

interface UseEventTrackerDataProps {
  events: Event[];
  eventTypes: { id: number; name: string }[];
  eventTypeSelections: number[];
  search: string;
}

export const useEventTrackerData = ({
  events,
  eventTypeSelections,
  search,
}: UseEventTrackerDataProps) => {
  const rows = useMemo(
    () =>
      events?.filter(
        (event) =>
          event?.name?.toLowerCase()?.includes(search?.toLowerCase()) &&
          (eventTypeSelections?.length === 0 ||
            eventTypeSelections?.includes(event.event_type_id))
      ),
    [events, search, eventTypeSelections]
  );

  const uniqueEventTypeIds = useMemo(
    () => Array.from(new Set(events?.map((event) => event.event_type_id))),
    [events]
  );

  return { rows, uniqueEventTypeIds };
};
