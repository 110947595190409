import React, { useContext, useMemo } from 'react';
import {
  alpha,
  Box,
  Chip,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Link as LinkIcon,
  MapPin,
  Timer,
  User,
  VideoCamera,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as geopattern from 'geopattern';
import PropTypes from 'prop-types';

import VirtualLinkHiddenTooltip from 'components/Events/Controls/Shared/VirtualLinkHiddenTooltip';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import RichTextDisplay from 'components/shared/RichTextDisplay';
import { ImageContext } from 'contexts/ImageContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { getEventLink } from 'utils/event';
import { flexColumn } from 'utils/styles';
import { toOxfordComma } from 'utils/text';
import { useContextWithFallback } from 'utils/utils';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const PreviewCard = ({
  displayDifferentWebinarLink,
  isDone,
  isEventPreview = false,
  isNow,
  isSoon,
  rsvpIsYesOrMaybe,
}) => {
  const { hasImage, pendingImage, savedImage } = useContext(ImageContext);
  const [organization, , , , , eventTypeColors] =
    useContext(OrganizationContext);
  const {
    state: { event, hosts, savedEvent },
  } = useContextWithFallback(ManagerContext, PublicEventContext);

  const { copy } = useCopyToClipboard();
  const theme = useTheme();

  const hasVirtualLink = useMemo(
    () => event?.meeting?.link?.length > 0,
    [event?.meeting?.link?.length]
  );

  const virtualMeetingLink = useMemo(
    () =>
      displayDifferentWebinarLink && event?.meeting?.service === 'zoom_webinar'
        ? 'https://zoom.us/signin#/login'
        : event?.meeting?.link,
    [displayDifferentWebinarLink, event]
  );

  const details = useMemo(() => {
    const start = dayjs(event?.start);
    const end = dayjs(event?.ends);
    const duration = dayjs.duration(end?.diff(start));
    const formattedDuration = duration.format('D[ days] H[ hours] m[ minutes]');
    return [
      {
        Icon: Timer,
        type: 'duration',
        value: formattedDuration
          .replace(/\b0\s+[a-z]+\s*/gi, '')
          .replace(/^(1\s[^s]+)s/gi, '$1')
          .replace(/(\s1\s[^s]+)s/gi, '$1')
          .trim(),
      },
      { Icon: MapPin, type: 'location', value: event?.location || null },
      {
        Icon: VideoCamera,
        type: 'virtual',
        value:
          event?.meeting?.service?.length > 0 ? (
            rsvpIsYesOrMaybe || isEventPreview ? (
              <Stack alignItems='center' direction='row'>
                <Typography variant='inherit'>
                  {hasVirtualLink ? virtualMeetingLink : 'Virtual link'}
                </Typography>
                {hasVirtualLink ? (
                  <IconButton
                    data-testid='TODO:DATA-ICONBUTTON-97347'
                    onClick={() =>
                      copy(getEventLink('meeting', null, event), 'virtual link')
                    }
                    sx={{ my: -1 }}
                  >
                    <LinkIcon />
                  </IconButton>
                ) : null}
              </Stack>
            ) : (
              <VirtualLinkHiddenTooltip
                canRsvp={!event?.rsvp_pause_active}
                isDone={isDone}
                isNow={isNow}
                isSoon={isSoon}
              />
            )
          ) : null,
      },
      {
        Icon: User,
        type: 'hosts',
        value:
          hosts?.length > 0
            ? `Hosted by ${toOxfordComma(
                hosts?.map((host) => host.label || host)
              )}`
            : null,
      },
    ];
  }, [
    copy,
    event,
    hasVirtualLink,
    hosts,
    isDone,
    isEventPreview,
    isNow,
    isSoon,
    rsvpIsYesOrMaybe,
    virtualMeetingLink,
  ]);

  const image = useMemo(
    () =>
      geopattern.generate(savedEvent?.name || 'New', {
        baseColor: theme.palette.primary.main,
        color: theme.palette.grey[300],
      }),
    [savedEvent?.name, theme.palette.grey, theme.palette.primary.main]
  );

  const imageUrl = useMemo(() => image?.toDataUri(), [image]);

  const aspectRatio = '16 / 9';

  const setImageToPlaceholder = (e) => {
    e.currentTarget.source = imageUrl;
  };

  const listItems = useMemo(
    () =>
      details
        ?.filter((d) => d.value !== null)
        ?.map(({ Icon, type, value }) => (
          <ListItem disablePadding key={type}>
            <ListItemIcon slot='start' sx={{ minWidth: 32 }}>
              <Icon size={20} />
            </ListItemIcon>
            <ListItemText primary={value} sx={{ m: 0 }} />
          </ListItem>
        )),
    [details]
  );

  return (
    <Stack direction='column' gap={5} pb={isEventPreview ? 2 : 5}>
      <Box
        data-testid='preview-card'
        sx={{
          aspectRatio,
          bgcolor: 'transparent',
          borderRadius: '6px',
          overflow: 'hidden',
          textAlign: 'center',
        }}
      >
        <Fade in={hasImage || imageUrl?.length > 0}>
          <img
            alt='event banner'
            onError={setImageToPlaceholder}
            src={
              pendingImage && pendingImage?.url && pendingImage?.url?.length > 0
                ? pendingImage?.url
                : savedImage && savedImage?.url && savedImage?.url?.length > 0
                ? savedImage?.url
                : imageUrl || null
            }
            style={{
              aspectRatio,
              maxWidth: '100%',
              objectFit: hasImage ? 'contain' : 'cover',
              transition: 'all 300ms ease-in-out',
              width: '100%',
            }}
          />
        </Fade>
      </Box>
      <Stack direction='column' gap={3}>
        <Stack direction='column' gap={2}>
          <Typography variant='h4'>Event details</Typography>
          <List
            data-testid='TODO:DATA-LIST-57679'
            dense
            disablePadding
            sx={{ ...flexColumn, gap: 2 }}
          >
            {listItems}
          </List>
        </Stack>
        {event?.description ? (
          <RichTextDisplay text={event?.description} />
        ) : null}
        {(event?.event_type_id > 0 && organization) ||
        event?.event_type_name?.length > 0 ? (
          <Stack direction='row' gap={1.5}>
            {/* TODO: Iterate through event types to display a chip for each (once we can capture > 1) */}
            <Chip
              color='primary'
              label={
                event?.event_type_name ||
                organization?.event_types?.find(
                  (t) => t?.id === event?.event_type_id
                )?.name
              }
              sx={
                eventTypeColors !== null
                  ? {
                      bgcolor: alpha(
                        eventTypeColors[event?.event_type_id],
                        0.5
                      ),
                      color: 'text.secondary',
                    }
                  : null
              }
              variant='light'
            />
          </Stack>
        ) : null}
      </Stack>
      {event?.resources?.length > 0 ? (
        <Stack direction='column' gap={2}>
          <Typography variant='h4'>Resources</Typography>
          {event?.resources?.map(({ id, link, title }, index) => (
            <Link
              data-testid='TODO:DATA-LINK-71216'
              href={link.indexOf('http') === -1 ? `https://${link}` : link}
              key={id || index}
              sx={{ textDecoration: 'none !important' }}
              target='_blank'
            >
              {title}
            </Link>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};

PreviewCard.propTypes = {
  displayDifferentWebinarLink: PropTypes.bool,
  image: PropTypes.any,
  isDone: PropTypes.bool,
  isEventPreview: PropTypes.bool,
  isNow: PropTypes.bool,
  isSoon: PropTypes.bool,
  rsvpIsYesOrMaybe: PropTypes.bool,
};

export default PreviewCard;
