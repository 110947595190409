import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  lateRsvpButtonText,
  rsvpOptions,
} from 'components/Events/Controls/Rsvp/Rsvp.constants';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import { SurveyResponseContext } from 'components/Survey/SurveyResponseContext';
import { useContextWithFallback } from 'utils/utils';

const RsvpButtons = ({
  areDisabled,
  handleSelection,
  isNow,
  isSoon,
  showLabel,
  showMaybeButton = true,
  statusId,
}) => {
  const {
    state: { event, rsvpStatus },
  } = useContextWithFallback(
    ManagerContext,
    PublicEventContext,
    SurveyResponseContext
  );

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const theme = useTheme();

  const statusVal = useMemo(() => {
    if (rsvpStatus) {
      return rsvpStatus;
    } else if (statusId) {
      return rsvpOptions.find((o) => o.id === statusId)?.id;
    }
    return null;
  }, [rsvpStatus, statusId]);

  const handleChange = (e, newValue) => {
    if (newValue !== null) {
      if (event?.rsvp_pause_active && statusId === 'rsvp') {
        setShowConfirmDialog(true);
        setNextStatus(newValue);
      } else {
        handleSelection(newValue);
      }
    }
  };

  const onlyShowYes = useMemo(
    () =>
      (isSoon || isNow) &&
      (!statusId ||
        statusId === 'not_attending' ||
        statusId === 'guest_list' ||
        statusId === 'invited'),
    [isNow, isSoon, statusId]
  );

  return onlyShowYes ? (
    <Button
      data-testid='TODO:DATA-BUTTON-28818'
      disabled={areDisabled}
      fullWidth
      onClick={(e) => handleChange(e, 'rsvp')}
      variant='contained'
    >
      {lateRsvpButtonText}
    </Button>
  ) : (
    <>
      {showLabel && <FormLabel>Your RSVP</FormLabel>}
      <ToggleButtonGroup
        aria-label='event rsvp'
        color='neutral'
        disabled={areDisabled}
        exclusive
        fullWidth
        onChange={handleChange}
        sx={{
          flexDirection: { md: 'columns', sm: 'row', xs: 'column' },
        }}
        value={statusVal}
      >
        {rsvpOptions
          .filter((opt) =>
            opt.buttonLabel === 'Maybe' ? showMaybeButton : true
          )
          .map((opt) => {
            return (
              <ToggleButton
                aria-label={opt.verb}
                disabled={areDisabled}
                key={opt.id}
                value={opt.id}
              >
                {opt.buttonLabel}
              </ToggleButton>
            );
          })}
      </ToggleButtonGroup>
      <Dialog data-testid='TODO:DATA-DIALOG-33248' open={showConfirmDialog}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you continue, your host will be notified and you will no longer
            be on the guest list for this event. Because this event is not
            currently accepting RSVPs, you will not be able to undo this action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='never-mind'
            onClick={() => {
              setShowConfirmDialog(false);
            }}
            variant='text'
          >
            Never mind
          </Button>
          <Button
            data-testid='yes,-update-my-rsvp'
            onClick={() => {
              handleSelection(nextStatus);
              setShowConfirmDialog(false);
              setNextStatus(null);
            }}
            variant='contained'
          >
            Yes, update my RSVP
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RsvpButtons.propTypes = {
  areDisabled: PropTypes.bool,
  handleSelection: PropTypes.func,
  isNow: PropTypes.bool,
  isSoon: PropTypes.bool,
  showLabel: PropTypes.bool,
  showMaybeButton: PropTypes.bool,
  statusId: PropTypes.string,
};

export default RsvpButtons;
