import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated } from 'utils/axios';

export const SlackEmployeesContext = createContext();

export const SlackEmployeesProvider = (props) => {
  const [slackEmployees, setSlackEmployees] = useState([]);
  // We are going to load data immediately, so let's keep from flip floppiing the ConditionalLoaders until we are done with the initial load
  const [isLoading, setIsLoading] = useState(true);
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      axiosAuthenticated(
        { timeout: 30000, url: '/api/slack_users' },
        (res) => {
          setSlackEmployees(res.data);
          setIsLoading(false);
        },
        () => setIsLoading(false)
      );
    } else {
      setIsLoading(false); // Clean up after initial load
    }
  }, [isAuthenticated]);

  return (
    <SlackEmployeesContext.Provider
      value={[slackEmployees, setSlackEmployees, isLoading]}
    >
      {props.children}
    </SlackEmployeesContext.Provider>
  );
};

SlackEmployeesProvider.propTypes = {
  children: PropTypes.any,
};
