import React, { ReactNode, useEffect, useMemo } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { capitalize, Tab, Tabs } from '@mui/material';

import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import DefaultAppBar, {
  DefaultAppBarProps,
} from 'components/shared/layout/DefaultAppBar';
import TabPanel from 'components/shared/TabPanel';

interface TabType {
  component?: ReactNode;
  label: string;
  value: string;
}

interface TabsLayoutProps extends DefaultAppBarProps {
  children?: ReactNode;
  hasUnsavedChanges?: boolean;
  onChange?: (newIndex: number, event: React.SyntheticEvent) => void;
  tabs: TabType[] | string[];
  tabValue?: string;
}

const a11yProps = (index: number) => ({
  'aria-controls': `tabpanel-${index}`,
  id: `tab-${index}`,
});

const TabsLayout = ({
  children,
  tabs,
  tabValue,
  ...props
}: TabsLayoutProps) => {
  const navigate = useNavigate();
  const { tab: tabParam } = useParams<{ tab: string }>();

  const activeTab = useMemo(
    () => tabValue || tabParam || null,
    [tabParam, tabValue]
  );

  const activeTabIndex = useMemo(
    () =>
      activeTab && tabs?.length > 0
        ? tabs.findIndex(
            (t) => activeTab === String(typeof t === 'string' ? t : t.value)
          )
        : 0,
    [activeTab, tabs]
  );

  useEffect(() => {
    if (!activeTab && !tabParam && tabs?.length > 0) {
      navigate(typeof tabs[0] === 'string' ? tabs[0] : tabs[0].value, {
        replace: true,
      });
    }
  }, [navigate, activeTab, tabParam, tabs]);

  return (
    <>
      <DefaultAppBar
        {...props}
        fixedLocation={props?.fixedLocation || 'below-tabs'}
        TabBar={
          <Tabs
            aria-label={`${props.title} tabs`}
            scrollButtons='auto'
            value={
              activeTab && tabs?.length > 0
                ? tabs.findIndex(
                    (t) => activeTab === (typeof t === 'string' ? t : t.value)
                  )
                : 0
            }
            variant='scrollable'
          >
            {tabs.map((tab, index) => (
              <Tab
                {...a11yProps(index)}
                component={NavLink}
                key={index}
                label={typeof tab === 'string' ? capitalize(tab) : tab.label}
                relative='path'
                to={`../${typeof tab === 'string' ? tab : tab.value}`}
              />
            ))}
          </Tabs>
        }
      />
      <ContentWidthContainer>
        {children ? (
          <TabPanel index={activeTabIndex} value={activeTabIndex}>
            {children}
          </TabPanel>
        ) : (
          tabs.map((tab, index) => (
            <TabPanel index={index} key={index} value={activeTabIndex}>
              {typeof tab !== 'string' && tab.component}
            </TabPanel>
          ))
        )}
      </ContentWidthContainer>
    </>
  );
};

export default TabsLayout;
