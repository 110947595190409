import React, { useCallback, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Button, IconButton, Stack, styled, TextField } from '@mui/material';
import { Books, PlusCircle, Trash } from '@phosphor-icons/react';
import { isEqual } from 'lodash';

import SummaryField from 'components/shared/SummaryField';
import {
  EventDetailsFormControl,
  EventDetailsFormGetValues,
} from 'types/EventDetailsForm';

const StyledTextField = styled(TextField)(() => ({
  flexGrow: 1,
  margin: 0,
}));

const defaultResource = {
  id: '',
  link: '',
  title: '',
};

export interface EventResource {
  destroy?: boolean;
  id?: string | number;
  link?: string;
  title?: string;
}

interface EventResourcesFieldProps {
  control: EventDetailsFormControl;
  disableFields: boolean;
  getValues: EventDetailsFormGetValues;
  isLoading?: boolean;
}

const EventResourcesField = ({
  control,
  disableFields,
  getValues,
  isLoading = false,
}: EventResourcesFieldProps) => {
  const resources = getValues('resources');

  const [pendingValue, setPendingValue] = useState(resources || []);

  const updateResource = useCallback(
    (
      val: string | boolean,
      field: 'title' | 'link' | 'destroy',
      index: number
    ) => {
      const newResources = [...pendingValue];
      newResources[index] = { ...newResources[index], [field]: val };
      setPendingValue(newResources);
    },
    [pendingValue]
  );

  const removeResource = useCallback(
    (resource: any, index: number) => {
      if (resource?.id && resource?.id !== '') {
        updateResource(true, 'destroy', index);
      } else {
        const before = pendingValue.slice(0, index);
        const after = pendingValue.slice(index + 1);
        setPendingValue([...before, ...after]);
      }
    },
    [pendingValue, updateResource]
  );

  const addResource = () => {
    setPendingValue([...pendingValue, { ...defaultResource }]);
  };

  const pendingVisibleValues = useMemo(
    () =>
      pendingValue
        ?.map((resource: any, index: number) => ({ ...resource, index }))
        ?.filter((resource: any) => !resource['destroy']),
    [pendingValue]
  );

  return (
    <Controller
      control={control}
      name='resources'
      render={({ field }) => (
        <SummaryField
          component={
            <Stack
              alignItems='flex-start'
              direction='column'
              gap={1.5}
              width='100%'
            >
              {pendingVisibleValues?.map(
                (
                  resource: EventResource & { index: number },
                  keyIndex: number
                ) => (
                  <Stack
                    alignItems='flex-end'
                    direction='row'
                    gap={0.5}
                    key={resource?.id || keyIndex}
                    width='100%'
                  >
                    <Stack direction='row' flexGrow={1} gap={2}>
                      <StyledTextField
                        InputProps={{ readOnly: disableFields }}
                        label='Resource name'
                        onChange={(e: React.ChangeEvent<any> | undefined) =>
                          updateResource(
                            e?.target?.value,
                            'title',
                            resource?.index
                          )
                        }
                        required
                        value={resource?.title}
                      />
                      <StyledTextField
                        InputProps={{ readOnly: disableFields, type: 'url' }}
                        label='URL'
                        onChange={(e: React.ChangeEvent<any> | undefined) =>
                          updateResource(
                            e?.target?.value,
                            'link',
                            resource?.index
                          )
                        }
                        required
                        value={resource?.link}
                      />
                    </Stack>
                    {!disableFields && (
                      <IconButton
                        onClick={() =>
                          removeResource(resource, resource?.index)
                        }
                      >
                        <Trash />
                      </IconButton>
                    )}
                  </Stack>
                )
              )}
              {!disableFields && (
                <Button
                  color='primary'
                  onClick={addResource}
                  startIcon={<PlusCircle />}
                >
                  {[
                    'Add',
                    pendingVisibleValues?.length > 0 ? 'another' : 'a',
                    'resource',
                  ].join(' ')}
                </Button>
              )}
            </Stack>
          }
          hasChanges={!isEqual(pendingValue, field.value)}
          Icon={Books}
          isEditable={!disableFields}
          isLoading={isLoading}
          onCancel={() => setPendingValue(field.value || [])}
          onOpen={() => setPendingValue(field.value || [])}
          onSubmit={() => {
            if (!disableFields) {
              const fieldsWithValues = pendingValue.filter(
                (resource: any) =>
                  resource?.title?.trim().length > 0 &&
                  resource?.link?.trim().length > 0
              );
              if (!isEqual(fieldsWithValues, field.value)) {
                field.onChange(fieldsWithValues);
              } else {
                setPendingValue(field.value || []);
              }
            }
          }}
          primary='Resources'
          secondary='Add links to resources or related articles that would be helpful to your guests.'
          type='dialog'
          value={field.value?.filter(
            (resource: EventResource) => !resource['destroy']
          )}
          valueType='checkmark'
        />
      )}
    />
  );
};

export default EventResourcesField;
