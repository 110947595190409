import React, { useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  UseFormReturn,
} from 'react-hook-form';
import { FormControlLabel, ListItemText, Switch } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { MessageFormData } from 'components/Events/Controls/Communications/communication.types';

interface SlackAttachmentTogglesProps {
  eventEnds: Dayjs;
  form: UseFormReturn<MessageFormData>;
  showEventDetailsButton: boolean;
  showRsvpButtons: boolean;
}

interface SlackAttachmentOption {
  checked: boolean;
  data: 'show_event_details_button' | 'show_rsvp_buttons';
  name: string;
  primary: string;
  secondary: string;
}

const SlackAttachmentToggles = ({
  eventEnds,
  form: { control, watch },
  showEventDetailsButton,
  showRsvpButtons,
}: SlackAttachmentTogglesProps) => {
  const [isScheduled, scheduledFor] = watch(['is_scheduled', 'scheduled_for']);

  const shouldHideSlackRsvpButtonToggle = useMemo(() => {
    if (isScheduled) {
      return dayjs(scheduledFor).isAfter(eventEnds);
    } else {
      return dayjs().isAfter(eventEnds);
    }
  }, [isScheduled, scheduledFor, eventEnds]);

  const slackAttachmentOptions: SlackAttachmentOption[] = useMemo(
    () => [
      {
        checked: showEventDetailsButton,
        data: 'show_event_details_button',
        name: 'details-button',
        primary: 'Event details button',
        secondary: 'Allow guests to see more event details from this message.',
      } as SlackAttachmentOption,
      ...(shouldHideSlackRsvpButtonToggle
        ? []
        : ([
            {
              checked: showRsvpButtons,
              data: 'show_rsvp_buttons',
              name: 'rsvp-buttons',
              primary: 'RSVP buttons',
              secondary:
                'Allow guests to RSVP from this message until event ends.',
            },
          ] as SlackAttachmentOption[])),
    ],
    [shouldHideSlackRsvpButtonToggle, showEventDetailsButton, showRsvpButtons]
  );

  const toggleSlackOptionValue = (
    field: ControllerRenderProps<
      MessageFormData,
      'show_event_details_button' | 'show_rsvp_buttons'
    >
  ) => {
    field.onChange(!field.value);
  };

  return (
    <>
      {slackAttachmentOptions.map((opt) => (
        <Controller
          control={control}
          key={opt.name}
          name={opt.data}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  checked={field.value} // opt.checked}
                  name={opt.name}
                  onChange={() => toggleSlackOptionValue(field)}
                />
              }
              key={opt.name}
              label={
                <ListItemText
                  primary={opt.primary}
                  secondary={opt.secondary}
                  sx={{ ml: 1 }}
                />
              }
              sx={{ alignItems: 'flex-start', display: 'flex' }}
            />
          )}
        />
      ))}
    </>
  );
};
export default SlackAttachmentToggles;
