import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface CollectionType {
  startsAt: Dayjs | string;
  endsAt: Dayjs | string;
}

const useCollectionDateTimeRange = (collection: CollectionType) => {
  const dateFormat = `MMMM D, YYYY`;

  const start = useMemo(() => dayjs(collection?.startsAt), [collection?.startsAt]);
  const end = useMemo(() => dayjs(collection?.endsAt), [collection?.endsAt]);

  const formattedStart = useMemo(() => start?.format(dateFormat), [start]);
  const formattedEnd = useMemo(() => end?.format(dateFormat), [end]);

  return useMemo(() => `${formattedStart} - ${formattedEnd}`, [formattedStart, formattedEnd]);
};

export default useCollectionDateTimeRange;
