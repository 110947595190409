import * as _ from 'lodash';

export const fullName = (employee) => {
  if (employee?.fullName || employee?.full_name || employee?.name) {
    return employee?.fullName || employee?.full_name || employee?.name;
  }

  if (employee?.first_name && employee?.last_name) {
    return [employee.first_name, employee.last_name].join(' ');
  }

  if (employee?.first_name) {
    return employee.first_name;
  }

  if (employee?.last_name) {
    return employee.first_name;
  }

  if (employee?.email) {
    return employee.email.split('@')[0];
  }

  return '';
};

export const segmentsFilter = (filteredSegments, emp, lists) =>
  filteredSegments.length === 0
    ? true
    : _.some(filteredSegments, (lisId) =>
        (
          _.find(lists, (l) => l.id === lisId) || { employee_ids: [] }
        ).employee_ids.includes(emp.id)
      );

export const officesFilter = (filteredOffices, emp) =>
  filteredOffices.length === 0 ? true : filteredOffices.includes(emp.office_id);

export const groupsFilter = (filteredGroups, emp, groups) =>
  filteredGroups.length === 0
    ? true
    : _.some(filteredGroups, (grpId) =>
        (
          _.find(groups, (g) => g.id === grpId) || { employee_ids: [] }
        ).employee_ids.includes(emp.id)
      );

export const searchFilter = (searchVal, option, byNameOnly = false) => {
  if (!searchVal) return true;

  const searchValueLower = searchVal.toLowerCase().trim();

  const isInName = () =>
    option?.name?.toLowerCase()?.includes(searchValueLower);
  const isInFullName = () =>
    fullName(option)?.toLowerCase()?.includes(searchValueLower);
  const isInEmail = () =>
    option?.email?.toLowerCase()?.includes(searchValueLower);

  if (byNameOnly) {
    return isInName();
  } else {
    return isInName() || isInFullName() || isInEmail();
  }
};
