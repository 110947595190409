import React, { useState } from 'react';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

import RichTextEditor from 'components/shared/RichTextEditor';

const DescriptionModal = ({
  description,
  edit,
  handleClose,
  handleSave,
  noun,
  show,
  slackCompatible,
}) => {
  const [value, setValue] = useState('');

  const handleChange = (v) => {
    if (v.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setValue('');
    } else {
      setValue(v);
    }
  };

  return (
    (<Dialog
        aria-label='Edit'
        fullWidth
        maxWidth='md'
        onClose={handleClose}
        open={show}
      >
      <DialogTitle>
        {`${edit ? 'Update' : 'Add'} ${capitalize(noun)} Description`}
      </DialogTitle>
      <DialogContent>
        <RichTextEditor
          isSlackCompatible={slackCompatible}
          onChange={handleChange}
          value={description}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' data-testid="cancel" onClick={handleClose} variant='text'>
          Cancel
        </Button>
        <Button
          color='primary'
          data-testid="save"
          onClick={() => {
            handleSave(value);
            handleClose();
          }}
          variant='contained'>
          Save
        </Button>
      </DialogActions>
    </Dialog>)
  );
};

DescriptionModal.propTypes = {
  description: PropTypes.string,
  edit: PropTypes.any,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  noun: PropTypes.any,
  show: PropTypes.any,
  slackCompatible: PropTypes.any,
};

export default DescriptionModal;
