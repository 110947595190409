import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

const eventInviteesQueryDocument = gql`
  query eventInviteesQueryDocument(
    $eventId: ID!
    $page: Int
    $perPage: Int
    $sort: SortingTableState
    $filter: ColumnFiltersTableState
    $search: String
  ) {
    invitees(
      eventId: $eventId
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
      search: $search
    ) {
      invitees {
        id
        email
        fullName
        status
        attended
        attendanceType
        updatedAt
        inviteScheduled
        inviteScheduledFor
      }
      totalCount
      totalPages
      currentPage
      nextPage
      prevPage
    }
  }
`;

export const useEventGuests = ({
  columnFilters,
  eventId,
  globalFilter,
  pagination,
  sorting,
}: {
  columnFilters?: MRT_ColumnFiltersState;
  eventId: number;
  globalFilter?: string;
  pagination: MRT_PaginationState;
  sorting?: MRT_SortingState;
}) => {
  const graphQLClient = useGraphQLClient();

  const queryKey = useMemo(
    () => [
      'event',
      eventId,
      'guests',
      'table',
      columnFilters, // refetch when columnFilters changes
      globalFilter, // refetch when globalFilter changes
      pagination?.pageIndex, // refetch when pagination.pageIndex changes
      pagination?.pageSize, // refetch when pagination.pageSize changes
      sorting, // refetch when sorting changes
    ],
    [
      columnFilters,
      eventId,
      globalFilter,
      pagination?.pageIndex,
      pagination?.pageSize,
      sorting,
    ]
  );

  return useQuery({
    queryFn: async () =>
      await graphQLClient?.request(eventInviteesQueryDocument, {
        eventId,
        filter: { columnFilters },
        page: pagination?.pageIndex + 1,
        perPage: pagination?.pageSize,
        search: globalFilter,
        sort: { sorting },
      }),
    queryKey,
  });
};
