import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const SurveyAnonymitySubheader = ({ displayResponders }) => {
  return (
    <Typography color='grey.700' variant='body1'>
      {`This survey is ${displayResponders ? 'not' : 'completely'} anonymous.`}
    </Typography>
  );
};

SurveyAnonymitySubheader.propTypes = {
  displayResponders: PropTypes.bool,
};

export default SurveyAnonymitySubheader;
