import React from 'react';
import { Stack, Typography, TypographyVariant } from '@mui/material';
import { Circle } from '@phosphor-icons/react';

export interface IChartLegendItem {
  borderColor?: string;
  color: string;
  label: string;
  legendOrientation?: 'horizontal' | 'vertical';
  textVariant?: TypographyVariant;
  value: number;
}

const ChartLegendItem = ({
  borderColor,
  color,
  label,
  legendOrientation = 'vertical',
  textVariant,
  value,
}: IChartLegendItem) => (
  <Stack
    alignItems='flex-start'
    direction='row'
    gap={legendOrientation === 'vertical' ? 1 : 0.5}
    maxWidth='100%'
    mb={legendOrientation === 'vertical' ? 1 : 0}
  >
    <Circle
      color={color}
      size={16}
      style={{
        flex: '0 0 16px',
        ...(borderColor ? { stroke: borderColor, strokeWidth: 8 } : {}),
        ...(legendOrientation === 'vertical' ? { marginTop: 1 } : {}),
      }}
      weight={value > 0 ? 'fill' : 'regular'}
    />
    <Typography
      color='grey.700'
      flex='auto'
      overflow='hidden'
      textOverflow='ellipsis'
      variant={textVariant || 'caption'}
      whiteSpace='nowrap'
    >
      {label}
    </Typography>
  </Stack>
);

export default ChartLegendItem;
