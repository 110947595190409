import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import ImageInput from 'components/Core/ImageInput';
import { ConditionalLoader } from 'components/Core/Loader';
import {
  NEW_LIST_ITEM,
  SET_FIELD,
} from 'components/Settings/actions/editSettings';
import EmailDomainSettings from 'components/Settings/EmailDomainSettings';
import EventTypeSettings from 'components/Settings/EventTypeSettings';
import GroupTypeSettings from 'components/Settings/GroupTypeSettings';
import { useEditSettings } from 'contexts/SettingsContext';

const MainSettings = ({
  favicon,
  hasUnsavedChanges,
  isLoading,
  logo,
  onDrop,
  onSave,
  organization,
}) => {
  const { dispatch, state: editSettings } = useEditSettings();
  const [orgDisplayName, setOrgDisplayName] = useState(
    editSettings?.display_name || organization?.display_name || ''
  );

  const setField = useCallback(
    (key, value, skip = false) => {
      dispatch({ field: key, skip, type: SET_FIELD, value });
    },
    [dispatch]
  );

  const newListItem = useCallback(
    (optionsField, selectionField, data) => {
      dispatch({
        data,
        optionsField,
        selectionField,
        type: NEW_LIST_ITEM,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!editSettings.display_name) {
      setField('display_name', organization.display_name, true);
    }

    if (organization.favicon) {
      setField('favicon_key', organization.favicon.key, true);
    }

    if (organization.logo) {
      setField('logo_key', organization.logo.key, true);
    }
  }, [
    organization.display_name,
    organization.favicon,
    organization.logo,
    dispatch,
    editSettings.display_name,
    setField,
  ]);

  return (
    <ConditionalLoader conditions={[isLoading]}>
      <Grid alignItems='stretch' container direction='row' spacing={3}>
        <Grid container direction='column' flex={1} item md={5}>
          <Card
            data-testid='TODO:DATA-CARD-84765'
            sx={{ height: '100%', maxWidth: '100%' }}
          >
            <CardContent>
              <TextField
                fullWidth
                label='Organization name'
                multiline
                onChange={(e) => {
                  const val = e.target.value;
                  setOrgDisplayName(val);
                  setField('display_name', val, true);
                }}
                placeholder='Add an organization name'
                value={orgDisplayName}
              />
              <ImageInput
                id='org-logo'
                image={logo}
                imageKey={editSettings.logo_key}
                label='Logo'
                onDrop={(files) => onDrop(files, 'logo')}
                onRemove={() => onDrop(null, 'logo')}
                previewMaxWidth={300}
                sizeRecommendation={{ height: 130, width: 170 }}
              />
              <ImageInput
                id='org-icon'
                image={favicon}
                imageKey={editSettings.favicon_key}
                label='Icon'
                onDrop={onDrop}
                onRemove={() => onDrop(null)}
                previewMaxWidth={100}
                sizeRecommendation={{ height: 50, width: 50 }}
              />
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-start' }}>
              <Button
                color='primary'
                data-testid='save-changes'
                disabled={!hasUnsavedChanges}
                onClick={onSave}
                variant='contained'
              >
                Save changes
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={7}>
          <Card
            data-testid='TODO:DATA-CARD-65948'
            sx={{ height: '100%', maxWidth: '100%' }}
          >
            <CardContent>
              <Stack direction='column' gap={3}>
                <EmailDomainSettings
                  newListItem={newListItem}
                  organization={organization}
                  setField={setField}
                />
                <EventTypeSettings
                  organization={organization}
                  setField={setField}
                />
                <GroupTypeSettings
                  newListItem={newListItem}
                  organization={organization}
                  setField={setField}
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ConditionalLoader>
  );
};

MainSettings.propTypes = {
  favicon: PropTypes.any,
  hasUnsavedChanges: PropTypes.bool,
  isLoading: PropTypes.bool,
  logo: PropTypes.any,
  onDrop: PropTypes.func,
  onSave: PropTypes.func,
  organization: PropTypes.shape({
    display_name: PropTypes.any,
    favicon: PropTypes.shape({
      key: PropTypes.any,
    }),
    logo: PropTypes.shape({
      key: PropTypes.any,
    }),
  }),
};

export default MainSettings;
