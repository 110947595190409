import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import {
  CalendarCheck,
  CheckCircle,
  ClipboardText,
  Envelope,
} from '@phosphor-icons/react';
import { sumBy } from 'lodash';
import PropTypes from 'prop-types';

import ProgressMetric from 'components/Core/ProgressMetric';
import { OtherBlue, OtherGreen, OtherOrange, OtherPink } from 'utils/colors';

const Engagement = ({ events, isLoading }) => {
  const invited = useMemo(() => sumBy(events, 'invited_count'), [events]);
  const accepted = useMemo(() => sumBy(events, 'rsvp_count'), [events]);
  const attended = useMemo(() => sumBy(events, 'attended_count'), [events]);
  const submittedSurvey = useMemo(
    () => sumBy(events, 'survey_responses_count'),
    [events]
  );

  return (
    <Card data-testid='TODO:DATA-CARD-51853'>
      <CardHeader title='Event engagement' />
      <CardContent>
        <Stack alignItems='stretch' direction='column' gap={2} width='100%'>
          <ProgressMetric
            color={OtherGreen}
            Icon={Envelope}
            isLoading={isLoading}
            label='Invited guests'
            total={invited}
            value={invited}
          />
          <ProgressMetric
            color={OtherBlue}
            Icon={CheckCircle}
            isLoading={isLoading}
            label='Accepted'
            total={invited}
            value={accepted}
          />
          <ProgressMetric
            color={OtherPink}
            Icon={CalendarCheck}
            isLoading={isLoading}
            label='Attended'
            total={invited}
            value={attended}
          />
          <ProgressMetric
            color={OtherOrange}
            Icon={ClipboardText}
            isLoading={isLoading}
            label='Submitted post-event survey'
            total={invited}
            value={submittedSurvey}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

Engagement.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

export default Engagement;
