import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { PublicOrgContext } from 'contexts/PublicOrgContext';
import { AudioEyeHashes } from 'utils/audio_eye';

const AudioEye = () => {
  const [org] = useContext(PublicOrgContext);
  const [audioEyeHash, setAudioEyeHash] = useState(null);

  useEffect(() => {
    if (!audioEyeHash) {
      const hash = AudioEyeHashes[org?.subdomain];
      setAudioEyeHash(hash);
    }
  }, [org, audioEyeHash]);

  return (
    (import.meta.env.VITE_AUDIOEYE_ENABLED === 'true' && audioEyeHash && (
      <Helmet>
        <script type='text/javascript'>{`!function(){var b=function(){window.__AudioEyeSiteHash = "${audioEyeHash}"; var a=document.createElement("script");a.src="https://wsmcdn.audioeye.com/aem.js";a.type="text/javascript";a.setAttribute("async","");document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();`}</script>
      </Helmet>
    )) ||
    null
  );
};

export default AudioEye;
