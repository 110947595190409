import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import EventDetailsFormDataBuilder from 'components/Event/EventDetailsFormDataBuilder';
import { EventDetailsFormData } from 'types/EventDetailsForm';
import FtnEvent from 'types/FtnEvent';
import { reactQueryAxios } from 'utils/axios';

type SaveDraftParams = {
  eventId: number | null;
};

const saveEvent = async ({
  apiClient,
  eventDetails,
  eventId,
  saveAsDraft,
}: SaveDraftParams & {
  apiClient: any;
  eventDetails: EventDetailsFormData;
  saveAsDraft: boolean;
}) => {
  const method = eventId != null ? 'put' : 'post';
  const url = eventId != null ? `/api/events/${eventId}` : '/api/events';

  return apiClient({
    data: EventDetailsFormDataBuilder(eventDetails, !saveAsDraft),
    method,
    timeout: 30000,
    url,
  });
};

export const useSaveEvent = ({
  dataHandler = () => undefined,
  eventId,
  saveAsDraft = false,
}: {
  dataHandler?: (e: FtnEvent) => unknown;
  eventId: number | null;
  saveAsDraft?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (eventDetails: EventDetailsFormData) =>
      saveEvent({
        apiClient: reactQueryAxios,
        eventDetails,
        eventId,
        saveAsDraft,
      }),

    onError: (error: Error) => {
      console.error(error);
      enqueueSnackbar('There was an error saving your event', {
        variant: 'error',
      });
      Sentry.captureException(error);
    },

    onSuccess: (response: any) => {
      const ftnEvent = response.data;
      queryClient.setQueryData(['event', ftnEvent.id], ftnEvent);
      dataHandler(ftnEvent);
    },
  });
};

export default useSaveEvent;
