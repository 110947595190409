import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { useGraphQLClient } from 'hooks/useGraphQLClient';
import Collection from 'types/Collection';

const fetchEventsCollection = gql`
  query fetchEventsCollection($id: ID!) {
    fetchEventsCollection(id: $id) {
      id
      name
      description
      imageKey
      imageUrl
      startsAt
      endsAt
      timezone
      announcementCount
      published
      config
      organizerEmployeeIds
      events {
        id
        coverImageUrl
        name
        start
        ends
        description
        inviteesCount
        privacy
        published
        safeDescription
      }
    }
  }
`;
interface EventsCollection extends Collection {
  endsAt: string;
  startsAt: string;
  timezone: string;
}

interface FetchEventsCollectionResponse {
  fetchEventsCollection: EventsCollection;
}

export const useFetchCollection = (id: string) => {
  const graphqlClient = useGraphQLClient();

  return useQuery<EventsCollection>({
    enabled: !!id,
    queryFn: async () => {
      const response =
        await graphqlClient.request<FetchEventsCollectionResponse>(
          fetchEventsCollection,
          { id }
        );
      return response.fetchEventsCollection;
    },
    queryKey: ['fetchEventsCollection', id],
  });
};
