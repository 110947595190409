import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const GroupContext = createContext();

const initialState = {
  can_organizers_edit: false,
  cover_image: '',
  cover_image_key: '',
  coverImageChanged: false,
  description: '',
  external_link: '',
  group_admin_ids: [],
  group_type_id: '',
  id: '',
  name: '',
  office_ids: [],
  organizer_id: '',
  private: false,
  slack_channel_id: '',
};

const groupReducer = (state, action) => {
  if (!action) {
    console.error('Undefined action passed to groupReducer');
    return state;
  }

  if (!action.type) {
    console.error('Undefined action type in groupReducer:', action);
  }
  switch (action.type) {
    case 'POST_GROUP_BEGIN':
    case 'POST_GROUP_SUCCESS':
    case 'POST_GROUP_FAILURE':
      return {
        ...state,
        isGroupSaving: action.type === 'POST_GROUP_BEGIN',
      };
    case 'POPULATE_GROUP_BEGIN':
    case 'POPULATE_GROUP_FAILURE':
      return {
        ...state,
        isGroupPopulating: action.type === 'POPULATE_GROUP_BEGIN',
      };
    case 'POPULATE_GROUP_SUCCESS':
      return {
        ...state,
        description: action.group.description,
        external_link: action.group.link,
        isGroupPopulating: false,
        name: action.group.name,
      };
    case 'SET_GROUP':
      return {
        ...initialState,
        ...action.group,
      };
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'CLEAR_GROUP':
      return initialState;
    default:
      return state;
  }
};

export const GroupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(groupReducer, initialState);
  return (
    <GroupContext.Provider value={{ dispatch, state }}>
      {children}
    </GroupContext.Provider>
  );
};

GroupProvider.propTypes = {
  children: PropTypes.any,
};
