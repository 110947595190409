import React from 'react';
import PropTypes from 'prop-types';

import InfoTooltip from 'components/shared/InfoTooltip';
import { EventLocationTypes } from 'utils/location.constants';

const VirtualLinkHiddenTooltip = ({ canRsvp, isDone, isNow, isSoon }) => {
  const subject = 'Virtual link';
  const text = `${subject} is hidden`;
  const tooltip = EventLocationTypes.virtual?.tooltip;

  return isDone || !canRsvp ? (
    subject
  ) : (
    <InfoTooltip
      content={isSoon || isNow ? tooltip?.late : tooltip?.default}
      placement='top'
      text={text}
    />
  );
};

VirtualLinkHiddenTooltip.propTypes = {
  canRsvp: PropTypes.bool,
  isDone: PropTypes.bool,
  isNow: PropTypes.bool,
  isSoon: PropTypes.bool,
};

export default VirtualLinkHiddenTooltip;
