import React, { useContext } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { DownloadSimple } from '@phosphor-icons/react';

import EventGuestsOverview from 'components/Events/Controls/GuestList/EventGuestsOverview';
import EventGuestsTable from 'components/Events/Controls/GuestList/EventGuestsTable';
import RsvpPauseToggle from 'components/Events/Controls/Rsvp/RsvpPauseToggle';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { LayoutContext } from 'contexts/LayoutContext';

const EventGuests = () => {
  const { isMobile } = useContext(LayoutContext);
  const {
    actions: { exportGuestList },
    state: { event },
  } = useContext(ManagerContext);

  return (
    <Stack direction='column' gap={2} justifyContent='flex-start'>
      <Stack direction='column' gap={1.5}>
        <Typography component='h2' variant='h4'>
          Overview
        </Typography>
        <EventGuestsOverview />
      </Stack>
      {event?.rsvp_pause_allowed && <RsvpPauseToggle />}
      <Stack
        alignItems='center'
        direction='row'
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        fullWidth
        gap={1}
        justifyContent='space-between'
      >
        <Typography component='h2' variant='h4'>
          Guest list
        </Typography>
        <Button
          data-testid='export'
          onClick={() => exportGuestList(name)}
          startIcon={<DownloadSimple />}
          sx={{ justifySelf: 'flex-end', my: -1 }}
        >
          Download
        </Button>
      </Stack>
      <EventGuestsTable eventId={event?.id} eventName={event?.name} />
    </Stack>
  );
};

export default EventGuests;
