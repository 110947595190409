import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import PasswordStrength from 'components/Onboard/PasswordStrength';
import { ValidatePassword } from 'utils/utils';

const PasswordTextField = ({
  autoComplete = 'current-password',
  errors = [],
  handleChange,
  label = 'Password',
  placeholder = 'Enter password',
  showStrengthOnFocus = false,
}) => {
  const [hasVisited, setHasVisited] = useState(false);
  const [helperText, setHelperText] = useState(null);
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [isShowingStrength, setIsShowingStrength] = useState(false);
  const [value, setValue] = useState('');
  const id = label.replace(' ', '-').toLowerCase();

  useEffect(() => {
    if (hasVisited && value.length > 0) {
      // TODO: Re-enable this condition once we have better form/error handling for all fields
      // if (value.length === 0) {
      //   setHelperText('This field is required');
      // } else
      if (ValidatePassword(value) === false) {
        setHelperText(`Value doesn't meet requirements`);
      } else {
        const activeErrors = errors.filter((e) => e.hasError === true);
        if (activeErrors.length > 0) {
          setHelperText(activeErrors[0].text);
        } else {
          setHelperText(null);
        }
      }
    }
  }, [hasVisited, errors, value]);

  const handleClickShowPassword = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBlur = () => {
    setHasVisited(true);
    if (showStrengthOnFocus) {
      setIsShowingStrength(false);
    }
  };

  const handleFocus = () => {
    if (showStrengthOnFocus) {
      setIsShowingStrength(true);
    }
  };

  return (
    <>
      <TextField
        autoComplete={autoComplete}
        data-testid={id}
        error={helperText !== null}
        fullWidth
        helperText={helperText}
        id={id}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                edge='end'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {isShowingPassword ? <EyeSlash /> : <Eye />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={label}
        minLength={8}
        onBlur={handleBlur}
        onChange={(e) => {
          setValue(e.target.value);
          handleChange(e);
        }}
        onFocus={handleFocus}
        placeholder={placeholder}
        required
        type={isShowingPassword ? 'text' : 'password'}
        value={value}
      />
      {showStrengthOnFocus && isShowingStrength && (
        <PasswordStrength password={value} />
      )}
    </>
  );
};

PasswordTextField.propTypes = {
  autoComplete: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      hasError: PropTypes.bool,
      text: PropTypes.string,
    })
  ),
  handleChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showStrengthOnFocus: PropTypes.bool,
};

export default PasswordTextField;
