import React from 'react';
import PropTypes from 'prop-types';

import OnboardFrame from 'components/Onboard/OnboardFrame';

const Landing = ({ isLoading }) => (
  <OnboardFrame conditions={[isLoading]}>
    <div className='tag center'>
      Build impactful event programs for your organization.
    </div>

    <div className='title is-medium is-5 center'>
      Five to Nine makes it easy for you to create, manage, and evaluate
      workplace events & programs.
    </div>

    <a href='https://fivetonine.co/contact/' rel='noreferrer' target='_blank'>
      Book A Demo
    </a>
  </OnboardFrame>
);

Landing.propTypes = {
  isLoading: PropTypes.bool,
};

export default Landing;
