import React from 'react';
import { Chip } from '@mui/material';

interface BudgetStatusChipProps {
  status: string | undefined;
}

const BudgetStatusChip = ({ status }: BudgetStatusChipProps) => {
  const getChipColor = () => {
    switch (status) {
      case 'On budget':
        return 'primary';
      case 'Over budget':
        return 'error';
      case 'Budget undefined':
        return 'default';
      default:
        return 'default';
    }
  };

  return (
    <Chip
      color={getChipColor()}
      label={status}
      size='small'
      sx={{ px: 1, py: 0.5 }}
      variant='light'
    />
  );
};

export default BudgetStatusChip;
