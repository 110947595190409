// [Organization Subdomain]: [Hotjar Setting Id]
export const HotJarIds = {
  amplitude: 2496097,
  clever: 2751484,
  ftn: 2465093,
  harrysinc: 3236381,
  headspacehealth: 3236444,
  malwarebytes: 2751486,
  masterclass: 2496095,
  nextdoor: 3236448,
  ouryahoo: 2751493,
  ro: 2751491,
  'shipt-deib': 3879414,
  splunk: 3879412,
  upwork: 2496093,
  webflow: 2751496, 
  ziprecruiter: 3236436,
};
