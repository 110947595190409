import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import EventDetailsFormDataBuilder from 'components/Event/EventDetailsFormDataBuilder';
import { EventDetailsFormData } from 'types/EventDetailsForm';
import FtnEvent from 'types/FtnEvent';
import { reactQueryAxios } from 'utils/axios';

const publishEvent = async (
  eventDetails: EventDetailsFormData,
  apiClient: any
) =>
  apiClient({
    data: EventDetailsFormDataBuilder(eventDetails, true),
    method: 'post',
    timeout: 30000,
    url: `/api/events/publish`,
  });

export const usePublishEvent = ({
  dataHandler = () => undefined,
}: {
  dataHandler?: (d: FtnEvent) => unknown;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (eventDetails: EventDetailsFormData) =>
      publishEvent(eventDetails, reactQueryAxios),

    onError: (error: Error) => {
      enqueueSnackbar('Failed to publish event', { variant: 'error' });
      Sentry.captureException(error);
    },

    onSuccess: (response: any) => {
      const ftnEvent = response.data;
      queryClient.setQueryData(['event', ftnEvent.id], ftnEvent);
      dataHandler(ftnEvent);
    },
  });
};
