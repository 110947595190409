import { useQuery } from '@tanstack/react-query';

import { axiosAuthenticated } from 'utils/axios';

interface SlackEmployee {
  created_at: string;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  initials: string;
  is_admin: boolean | null;
  is_organizer: boolean | null;
  last_name: string;
  office_id: number | null;
  role_type: string;
  state: string | null;
  user_id: number | null;
}

const fetchSlackEmployees = (): Promise<SlackEmployee[]> =>
  new Promise((resolve, reject) => {
    axiosAuthenticated(
      { url: '/api/slack_users' },
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const useSlackEmployees = () =>
  useQuery({
    queryFn: fetchSlackEmployees,
    queryKey: ['slackEmployees'],
    staleTime: Infinity,
  });
