import React from 'react';
import { IconButton, TextField } from '@mui/material';
import { Copy } from '@phosphor-icons/react';

import InfoTooltip from 'components/shared/InfoTooltip';
import { Integration } from 'types/Integration';

interface Props {
  copyLink: () => unknown;
  isTrackingAttendance: boolean;
  meetingLink: string;
  meetingType: Integration | undefined;
}

const EventVirtualLocationCopyLink = ({
  copyLink,
  isTrackingAttendance,
  meetingLink,
  meetingType,
}: Props) => (
    <TextField
      data-testid='virtual-location-service-link'
      fullWidth
      helperText={
        meetingType?.id === 'zoom_meeting' && (
          <InfoTooltip
            content={`Attendance tracking through Zoom registration requires guests 
                      to fill out their name and email before entering the meeting. 
                      This feature can be changed by the creator of this Zoom meeting.`}
            text={`Attendance tracking is ${
              isTrackingAttendance ? 'en' : 'dis'
            }abled`}
          />
        )
      }
      InputProps={{
        endAdornment: (
          <IconButton
            data-testid='virtual-location-service-link-copy'
            onClick={copyLink}
          >
            <Copy />
          </IconButton>
        ),
        readOnly: true,
        startAdornment: meetingType?.logoAvatar,
      }}
      value={`${meetingType?.label} (${meetingLink})`}
    />
  );

export default EventVirtualLocationCopyLink;
