import { alpha, Button, styled } from '@mui/material';

export const InlineButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    background: 'none',
    color: theme.palette.primary.dark,
    textDecorationColor: theme.palette.primary.main,
  },
  color: theme.palette.primary.main,
  fontSize: 'inherit',
  height: '12px',
  minWidth: 'unset',
  padding: 0,
  textDecoration: 'underline !important',
  textDecorationColor: alpha(theme.palette.primary.main, 0.4),
  verticalAlign: 'unset !important',
}));
