import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import { GroupsContext } from 'contexts/GroupsContext';

const GroupsWaiter = ({ children }) => {
  const [, , isGroupsLoading] = useContext(GroupsContext);
  return (
    <ConditionalLoader conditions={[isGroupsLoading]}>
      {children}
    </ConditionalLoader>
  );
};

GroupsWaiter.propTypes = {
  children: PropTypes.any,
};
export default GroupsWaiter;
