import * as _ from 'lodash';
/*
  interface error =
  {
    type: "field" | "option",
    message: string,
    index?: number,
    optionKey?: string,
    optionIndex?: number,
  }
*/
const createError = (
  msg,
  index = undefined,
  optionKey = undefined,
  optionIndex = undefined,
  optionId = undefined,
  questionId = undefined
) => [
  {
    index,
    message: msg,
    optionId,
    optionIndex,
    optionKey,
    questionId,
    type: index !== undefined ? 'option' : 'field',
  },
];

const isEmptyOrWhitespace = (value) => _.chain(value).trim().isEmpty().value();

/*
 All field validator functions accept a single argument (the new value being set)
 and are expected to return true if the new value is valid or an array of error objects
 (see interface above) if the new value is invalid.

 NOTE: You can use multiple validators for a single field, so don't use the array
 return to check multiple things in a single validator. This will make the validors
 more reusable through composition.
*/

const validateNotEmpty =
  (
    msg,
    index = undefined,
    optionKey = undefined,
    optionIndex = undefined,
    optionId = undefined,
    questionId = undefined
  ) =>
  (v) =>
    !isEmptyOrWhitespace(v) ||
    createError(msg, index, optionKey, optionIndex, optionId, questionId);

const validatePresent =
  (
    msg,
    index = undefined,
    optionKey = undefined,
    optionIndex = undefined,
    optionId = undefined,
    questionId = undefined
  ) =>
  (v) =>
    !_.isNil(v) ||
    createError(msg, index, optionKey, optionIndex, optionId, questionId);

const validateDateInFuture =
  (
    msg,
    index = undefined,
    optionKey = undefined,
    optionIndex = undefined,
    optionId = undefined,
    questionId = undefined
  ) =>
  (v) =>
    new Date(v) >= new Date() ||
    createError(msg, index, optionKey, optionIndex, optionId, questionId);

export const validateUrl =
  (
    msg,
    index = undefined,
    optionKey = undefined,
    optionIndex = undefined,
    optionId = undefined,
    questionId = undefined
  ) =>
  (v) => {
    if (!isEmptyOrWhitespace(v)) {
      try {
        const checkUrl = _.startsWith(v, 'http://') ? v : 'http://' + v;

        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const url = new URL(checkUrl);
      } catch (err) {
        return createError(
          msg,
          index,
          optionKey,
          optionIndex,
          optionId,
          questionId
        );
      }
    }

    return true;
  };

// Validates a single resource, not indended to be used as a generic validator function
const validateResource = (res, index) => {
  if (res._destroy === 1) {
    return [];
  }
  const errors = [];
  if (res.title.length > 0 && isEmptyOrWhitespace(res.title)) {
    errors.push(
      createError('Title is required', index, 'title', null, null, res?.id)
    );
  }
  if (res.link.length > 0 && isEmptyOrWhitespace(res.link)) {
    errors.push(
      createError('Link is required', index, 'link', null, null, res?.id)
    );
  }
  if (res.link.length > 0 && isEmptyOrWhitespace(res.title)) {
    errors.push(
      createError('There is no title', index, 'title', null, null, res?.id)
    );
  }
  return errors;
};

export const DetailsValidators = [
  { field: 'name', validator: validateNotEmpty('Event name is required') },
  {
    field: 'host_ids',
    validator: validateNotEmpty('At least 1 host is required'),
  },
  {
    field: 'start',
    validator: validatePresent('Start date is required'),
  },
  {
    field: 'ends',
    validator: validatePresent('End date is required'),
  },
  {
    field: 'start',
    validator: validateDateInFuture('Date is in the past'),
  },
  {
    field: 'ends',
    validator: validateDateInFuture('Date is in the past'),
  },
  {
    field: 'resources',
    validator: (v) => {
      const resErrors = _.chain(v)
        .map((resource, i) => validateResource(resource, i))
        .flattenDeep()
        .value();
      return resErrors.length === 0 || resErrors;
    },
  },
];

const validateSurvey = () => (v) => {
  const errors = [];
  if (v?.editing && !_.some(v?.survey_questions, (q) => q._destroy !== 1)) {
    // Check that there is at least 1 non-deleted question
    errors.push(createError('There are no questions added to this survey'));
  } else {
    _.forEach(v?.survey_questions, (q, index) => {
      if (q._destroy !== 1) {
        // Question Needs a Title
        const validateTitle = validateNotEmpty(
          'Please add a question title',
          index,
          'title',
          null,
          null,
          q.pending_id || q.id
        )(q?.title);
        if (validateTitle !== true) {
          errors.push(validateTitle[0]);
        }
        // Question Needs some Non-deleted Options
        const optionsForQuestion = _.filter(
          v?.survey_options,
          (opt) =>
            opt.survey_question_id ===
              (q.id ? q.id : q.position ? q.position : index) &&
            opt._destroy !== 1
        );
        if (
          optionsForQuestion.length < 2 &&
          (q.question_type === 'single_select' ||
            q.question_type === 'multi_select')
        ) {
          errors.push(
            createError(
              'Please add more than 1 option',
              index,
              null,
              null,
              null,
              q.pending_id || q.id
            )[0]
          );
        }
      }
    });
  }

  // Check Options
  _.forEach(v?.survey_options, (opt) => {
    if (opt._destroy !== 1) {
      let questionIndex = _.findIndex(v?.survey_questions, (q) =>
        q.id
          ? opt.survey_question_id === q.id
          : opt.survey_question_id === q.position
      );
      if (questionIndex < 0) {
        questionIndex = _.findIndex(
          v?.survey_questions,
          (q, qIndex) => opt.survey_question_id === qIndex
        );
      }

      const questionType = v?.survey_questions[questionIndex]?.question_type;

      const optionsForQuestion = _.filter(
        v?.survey_options,
        (optForQ) =>
          optForQ.survey_question_id === opt.survey_question_id &&
          optForQ._destroy !== 1
      );
      const optIndex = _.findIndex(
        optionsForQuestion,
        (optInQ) => optInQ === opt
      );

      // Check that non-deleted options have a title, if question's question_type is not short-anwer or nps
      if (
        optionsForQuestion.length <= 2 &&
        (questionType === 'multi_select' || questionType === 'single_select')
      ) {
        const validateOptTitle = validateNotEmpty(
          'Please add an option title',
          questionIndex,
          'optionTitle',
          optIndex,
          opt?.pending_id || opt?.id
        )(opt?.text);
        if (validateOptTitle !== true) {
          errors.push(validateOptTitle[0]);
        }
      }
    }
  });

  return errors.length === 0 || errors;
};

export const SurveysValidators = [
  { field: 'pre_event_survey', validator: validateSurvey() },
  { field: 'post_event_survey', validator: validateSurvey() },
];

// Object validator (Able to validate across fields)
export const ValidateEvent = (ev) => {
  let errors = [];

  if (
    ev?.meeting?.service === 'unknown' &&
    isEmptyOrWhitespace(ev?.meeting?.link)
  ) {
    errors = [
      ...errors,
      ...[
        {
          errors: [{ message: 'Make sure to add a valid URL.', type: 'field' }],
          field: 'meeting.link',
        },
      ],
    ];
  }

  return errors;
};
