import React from 'react';
import { MultipleFieldErrors } from 'react-hook-form';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';

import EventFormDateTimeSkeleton from 'components/Event/EventFormDateTimeSkeleton';
import TimezoneLabel from 'components/Event/TimeZoneLabel';
import DateTimePickers from 'components/shared/DateTimePickers';
import {
  EventDetailsFormControl,
  EventDetailsFormFormState,
  EventDetailsFormGetValues,
  EventDetailsFormRegister,
  EventDetailsFormSetValue,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';

interface EventFormDateTimeFieldsProps {
  control: EventDetailsFormControl;
  disableFields: boolean;
  formState: EventDetailsFormFormState;
  getValues: EventDetailsFormGetValues;
  isLoading?: boolean;
  isNewOrUpcoming: boolean;
  register: EventDetailsFormRegister;
  setValue: EventDetailsFormSetValue;
  watch: EventDetailsFormWatch;
}

const EventFormDateTimeFields = ({
  control,
  disableFields,
  formState,
  getValues,
  isLoading = false,
  isNewOrUpcoming: eventIsNewOrUpcoming,
  setValue,
  watch,
}: EventFormDateTimeFieldsProps) => {
  const today = dayjs.utc();

  const startFormValue = watch('start');
  const endsFormValue = watch('ends');

  const {
    errors: { ends: endsState, start: startState },
    isSubmitted,
  } = formState;

  /* Using FormState.errors here instead of getFieldState to access ALL errors for a given field */
  type ErrorKeys =
    | ''
    | 'dateAndTimeInFuture'
    | 'dateInFutureOrToday'
    | 'startBeforeEnd'
    | 'validDayJs';

  const errorMessages: Record<ErrorKeys, string | null> = {
    '': null,
    dateAndTimeInFuture: 'Time must be in the future',
    dateInFutureOrToday: 'Date must be in the future',
    startBeforeEnd: 'Event end time must be after start time',
    validDayJs: 'Invalid date',
  };

  const dateErrors: ErrorKeys[] = ['dateInFutureOrToday', 'validDayJs'];
  const timeErrors: ErrorKeys[] = ['dateAndTimeInFuture', 'validDayJs'];
  const endDateErrors: ErrorKeys[] = [...dateErrors, 'startBeforeEnd'];
  const endTimeErrors: ErrorKeys[] = [...timeErrors, 'startBeforeEnd'];
  const matchingError = (
    received: MultipleFieldErrors | undefined,
    watch: ErrorKeys[]
  ) =>
    errorMessages[
      (Object.keys(received || {}) as ErrorKeys[])?.find((key) =>
        watch.includes(key)
      ) || ''
    ];

  const fieldsWithErrors = {
    endDate:
      endsState != null ? matchingError(endsState?.types, endDateErrors) : null,
    endTime:
      endsState != null ? matchingError(endsState?.types, endTimeErrors) : null,
    startDate:
      startState != null ? matchingError(startState?.types, dateErrors) : null,
    startTime:
      startState != null ? matchingError(startState?.types, timeErrors) : null,
  };

  if (isLoading) {
    return <EventFormDateTimeSkeleton />;
  }

  return (
    <>
      <Stack
        columnGap={1.5}
        display='grid'
        gridTemplateColumns='repeat(2, 1fr)'
        rowGap={2}
      >
        <DateTimePickers
          control={control}
          dateProps={{
            disableHighlightToday: true,
            disableOpenPicker: disableFields,
            disablePast: eventIsNewOrUpcoming,
            // id: 'event-start-date',
            label: 'Start date',
            minDate: eventIsNewOrUpcoming ? today : undefined,
            readOnly: disableFields,
            slotProps: {
              textField: {
                error: fieldsWithErrors.startDate != null && !disableFields,
                helperText:
                  fieldsWithErrors.startDate && !disableFields
                    ? fieldsWithErrors.startDate
                    : undefined,
                InputProps: {
                  readOnly: disableFields,
                },
                required: true,
              },
            },
          }}
          disableFields={disableFields}
          getValues={getValues}
          name='start'
          offsetDependent={{
            name: 'ends',
            validateImmediately: isSubmitted,
          }}
          setValue={setValue}
          timeProps={{
            disableIgnoringDatePartForTimeValidation: true,
            disableOpenPicker: disableFields,
            disablePast: eventIsNewOrUpcoming,
            label: 'Start time',
            minTime: eventIsNewOrUpcoming ? today : null,
            readOnly: disableFields,
            slotProps: {
              textField: {
                error: fieldsWithErrors.startTime != null && !disableFields,
                helperText:
                  fieldsWithErrors.startTime && !disableFields
                    ? fieldsWithErrors.startTime
                    : undefined,
                InputProps: {
                  readOnly: disableFields,
                },
              },
            },
          }}
        />
        <DateTimePickers
          control={control}
          dateProps={{
            disableHighlightToday: true,
            disableOpenPicker: disableFields,
            disablePast: eventIsNewOrUpcoming,
            label: 'End date',
            minDate: startFormValue ? dayjs(startFormValue) : undefined,
            readOnly: disableFields,
            slotProps: {
              textField: {
                error: fieldsWithErrors.endDate != null && !disableFields,
                helperText:
                  fieldsWithErrors.endDate && !disableFields
                    ? fieldsWithErrors.endDate
                    : undefined,
                InputProps: {
                  readOnly: disableFields,
                },
                required: true,
              },
            },
          }}
          disableFields={disableFields}
          name='ends'
          timeProps={{
            disableIgnoringDatePartForTimeValidation: true,
            disableOpenPicker: disableFields,
            disablePast: eventIsNewOrUpcoming,
            label: 'End time',
            minTime:
              eventIsNewOrUpcoming &&
              startFormValue &&
              dayjs(startFormValue).isSame(endsFormValue, 'date')
                ? dayjs(startFormValue)
                : undefined,
            readOnly: disableFields,
            slotProps: {
              textField: {
                error: fieldsWithErrors.endTime != null && !disableFields,
                helperText:
                  fieldsWithErrors.endTime && !disableFields
                    ? fieldsWithErrors.endTime
                    : undefined,
                InputProps: {
                  readOnly: disableFields,
                },
              },
            },
          }}
        />
      </Stack>
      <TimezoneLabel />
    </>
  );
};

export default EventFormDateTimeFields;
