import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FloppyDisk } from '@phosphor-icons/react';

import CollectionEventsList from 'components/Collections/CollectionEventsList';
import { Event } from 'types/CollectionForm';

interface AddEventsDialogProps {
  initialSelectedEvents: Event[];
  onClose: () => void;
  onSave: (events: Event[]) => void;
  open: boolean;
}

const AddEventsDialog: React.FC<AddEventsDialogProps> = ({
  initialSelectedEvents,
  onClose,
  onSave,
  open,
}) => {
  const [selectedEvents, setSelectedEvents] = useState<Event[]>(
    initialSelectedEvents
  );

  useEffect(() => {
    setSelectedEvents(initialSelectedEvents);
  }, [initialSelectedEvents]);

  const handleSaveSelectedEvents = (events: Event[]) => {
    setSelectedEvents(events);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      onClose={onClose}
      open={open}
      sx={{ maxHeight: '100vh' }}
    >
      <DialogTitle>Add events to your collection</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the events you would like to add to your collection:
        </DialogContentText>
        <CollectionEventsList
          initialSelectedEvents={selectedEvents}
          onSaveSelection={handleSaveSelectedEvents}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSave(selectedEvents)}
          startIcon={<FloppyDisk />}
          variant='contained'
        >
          {initialSelectedEvents.length === 0 ? 'Add' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEventsDialog;
