import React from 'react';
import { Box, Chip, Typography } from '@mui/material';

import CollectionEventsListItem from 'components/Collections/CollectionEventsListItem';
import { Event } from 'types/CollectionForm';

interface SelectedEventsListProps {
  events: Event[];
  onRemoveEvent: (eventId: number) => void;
}

const SelectedEventsList: React.FC<SelectedEventsListProps> = ({
  events,
  onRemoveEvent,
}) => {
  if (events.length === 0) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' maxHeight='40vh'>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Typography variant='subtitle1'>Selected Events:</Typography>
        <Chip
          color='primary'
          label={`${events.length} selected`}
          variant='light'
        />
      </Box>
      <Box flexGrow={1} overflow='auto'>
        <Box display='flex' flexDirection='column' gap={0.5}>
          {events.map((event) => (
            <CollectionEventsListItem
              event={event}
              isSelected
              key={event.id}
              onToggleSelect={() => onRemoveEvent(event.id)}
              removable
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SelectedEventsList;
