import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  capitalize,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ClockCountdown } from '@phosphor-icons/react';
import dayjs, { Dayjs } from 'dayjs';
import { plural } from 'pluralize';

import {
  CommunicationFormData,
  CommunicationMethodId,
  CommunicationTypeId,
  InvitationSelections,
  MessageMethodId,
} from 'components/Events/Controls/Communications/communication.types';
import DiscardCommunicationConfirmation from 'components/Events/Controls/Communications/DiscardCommunicationConfirmation';
import MessageGuests from 'components/Events/Controls/Communications/MessageGuests';
import PreviewInvitation from 'components/Events/Controls/Communications/PreviewInvitation';
import ScheduleCommunicationDialog from 'components/Events/Controls/Communications/ScheduleCommunicationDialog';
import SendCommunicationDialogTransition from 'components/Events/Controls/Communications/SendCommunicationDialogTransition';
import InvitationSelectGuests from 'components/Events/Controls/Invites/InvitationSelectGuests';
import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import { useCommunicationHistoryDelete } from 'hooks/useCommunicationHistoryDelete';
import useLocalStorage from 'hooks/useLocalStorage';
import FtnEvent from 'types/FtnEvent';
import { hasStarted } from 'utils/event';
import { flexRow } from 'utils/styles';

interface SendCommunicationDialogProps {
  isLoading: boolean;
  isScheduled: boolean;
  onClose: () => void;
  onSave: () => void;
  open: boolean;
  savedEvent: FtnEvent;
  scheduledFor: Dayjs | null;
}

const SendCommunicationDialog = ({
  isLoading,
  isScheduled,
  onClose,
  onSave,
  open,
  savedEvent,
  scheduledFor,
}: SendCommunicationDialogProps) => {
  // eslint-disable-next-line prefer-const
  let { commId, method, type } = useParams<{
    commId?: string;
    method?: CommunicationMethodId;
    type?: CommunicationTypeId;
  }>();

  const form = useFormContext<CommunicationFormData>();

  const [isCanceling, setIsCanceling] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const theme = useTheme();

  const [
    infoTooltipScheduleCommDismissed,
    setInfoTooltipScheduleCommDismissed,
  ] = useLocalStorage('infoTooltipScheduleCommDismissed', false);

  const handleToolTipClose = () => {
    setIsTooltipOpen(false);
    setInfoTooltipScheduleCommDismissed(true);
  };

  const dialogCopy = useMemo(
    () =>
      isCanceling
        ? {
            byline: `You have un${
              type === 'message' ? 'sent' : 'saved'
            } changes in your ${type}. If you exit now, these
            changes will be discarded. Are you sure you want to exit without ${
              type === 'message' ? 'sending' : 'saving'
            }?`,
            title: 'Discard changes?',
          }
        : {
            byline: `This action cannot be undone. 
              Deleting your scheduled ${type} will permanently remove it from your event.`,
            title: `Are you sure you want to delete your scheduled ${type}?`,
          },
    [isCanceling, type]
  );

  const { isDirty, isValid } = form.formState;

  const selections: InvitationSelections | undefined = form.watch('selections');
  const selectedGuestIds = form.watch('selectedGuestIds');

  const selectedIdsTypesArray = useMemo(
    () =>
      selections
        ? Object.entries(selections || {}).flatMap(([k, v]) =>
            v.map((x) => `${k}-${x.id}`)
          )
        : [],
    [selections]
  );

  const {
    isPending: isPendingDelete,
    isSuccess: isDeleted,
    mutate: deleteCommunication,
  } = useCommunicationHistoryDelete({
    dataHandler: () => {
      setIsCanceling(false);
      setIsDeleting(false);
      onClose();
    },
  });

  const handleClose = () => {
    if (isDirty && !isCanceling) {
      setIsCanceling(true);
    } else {
      setIsClosing(true);
    }
  };

  const updateRoute = useCallback(() => {
    if (isClosing) {
      onClose && onClose();
      setIsClosing(false);
    }
  }, [isClosing, onClose]);

  const allSelectionsFieldsEmpty = useMemo(() => {
    if (method === 'slack') {
      return !selections?.channel || selections?.channel?.length === 0;
    } else {
      return selectedIdsTypesArray?.length < 1;
      // selections?.office?.length === 0
    }
  }, [method, selectedIdsTypesArray?.length, selections?.channel]);

  const tooltipTitle = useMemo(() => {
    const isMessage = type === 'message';
    const allSaved = 'You have no unsaved changes.';
    if (isMessage) {
      return !isDirty && commId
        ? allSaved
        : isScheduled
        ? 'Please select recipients and personalize your message to schedule.'
        : 'Please add recipients and a message to send now.';
    } else {
      if (!isDirty && !allSelectionsFieldsEmpty) {
        return allSaved;
      } else {
        return `Please add guests to ${
          isScheduled ? 'schedule' : 'send'
        } your invitation.`;
      }
    }
  }, [allSelectionsFieldsEmpty, commId, isDirty, isScheduled, type]);

  return (
    <>
      <Dialog
        data-testid='send-communication-dialog'
        fullScreen
        onClose={handleClose}
        onTransitionExited={updateRoute}
        open={open && !isClosing}
        PaperProps={{ sx: { bgcolor: 'grey.50' } }}
        TransitionComponent={SendCommunicationDialogTransition}
      >
        <DialogTitle component='div' sx={{ p: 0 }}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar
              data-testid='communication-dialog-toolbar'
              sx={{
                bgcolor: 'white',
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                minHeight: `83px !important`,
              }}
            >
              <ContentWidthContainer>
                <Stack alignItems='center' direction='row' gap={2}>
                  <Stack direction='column' flex={1}>
                    <Typography
                      color='text.secondary'
                      component='div'
                      variant='overline'
                    >
                      {savedEvent?.name}
                    </Typography>
                    <Typography
                      color='text.primary'
                      component='div'
                      sx={{ flex: 1 }}
                      variant='h4'
                    >
                      {[
                        capitalize(method || ''),
                        type === 'invitation' ? plural(type || '') : type,
                      ].join(' ')}
                    </Typography>
                  </Stack>
                  {isScheduled && scheduledFor !== null ? (
                    <Stack
                      alignItems='center'
                      color='grey.700'
                      direction='row'
                      gap={0.5}
                    >
                      <ClockCountdown size={16} />
                      <Typography color='text.secondary' variant='overline'>
                        {dayjs(scheduledFor).format('ddd, MMM D, h:mma z')}
                      </Typography>
                    </Stack>
                  ) : null}
                  <Button
                    data-testid='cancel'
                    onClick={() => {
                      handleClose();
                    }}
                    variant='text'
                  >
                    Cancel
                  </Button>
                  {type === 'invitation' ? (
                    <PreviewInvitation
                      method={method as CommunicationMethodId}
                      type={type}
                    />
                  ) : null}
                  {commId ? (
                    <Button
                      data-testid='delete'
                      onClick={() => setIsDeleting(true)}
                      variant='text'
                    >
                      Delete
                    </Button>
                  ) : null}
                  {!infoTooltipScheduleCommDismissed &&
                  (!hasStarted || type !== 'invitation') ? (
                    <Tooltip
                      color='info'
                      id='schedule-popover'
                      open={isTooltipOpen}
                      placement='top'
                      slotProps={{
                        arrow: {
                          sx: { color: theme.palette.info.main },
                        },
                        tooltip: {
                          sx: {
                            backgroundColor: theme.palette.info.main,
                            maxWidth: 305,
                          },
                        },
                      }}
                      title={
                        <Stack
                          display='grid'
                          gridTemplateColumns='22px 1fr'
                          m={1.5}
                        >
                          <Typography variant='body2'>✨</Typography>
                          <Typography gutterBottom variant='body2'>
                            Schedule your communications
                          </Typography>
                          <Box>&nbsp;</Box>
                          <Typography variant='overline'>
                            Stay organized and plan ahead by scheduling your
                            communications in advance!
                          </Typography>
                          <Box>&nbsp;</Box>
                          <Button
                            data-testid='schedule-ok-button'
                            onClick={handleToolTipClose}
                            size='small'
                            sx={{ marginTop: 1 }}
                            variant='outlinedLight'
                          >
                            Ok
                          </Button>
                        </Stack>
                      }
                    >
                      <span>
                        <ScheduleCommunicationDialog
                          form={form}
                          savedEvent={savedEvent}
                          type={type as CommunicationTypeId}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <ScheduleCommunicationDialog
                      form={form}
                      savedEvent={savedEvent}
                      type={type as CommunicationTypeId}
                    />
                  )}
                  <Tooltip
                    arrow
                    disableHoverListener={
                      type === 'message'
                        ? isValid
                        : method === 'slack'
                        ? selections?.channel && selections?.channel?.length > 0
                        : selectedGuestIds && selectedGuestIds?.size > 0
                    }
                    title={tooltipTitle}
                  >
                    <span>
                      <LoadingButton
                        color='primary'
                        data-testid='communication-dialog-cta'
                        disabled={
                          type === 'message'
                            ? !isValid
                            : method === 'slack'
                            ? !selections?.channel ||
                              selections?.channel?.length === 0
                            : !selectedGuestIds || selectedGuestIds?.size === 0
                        }
                        loading={isLoading}
                        onClick={onSave}
                        variant='contained'
                      >
                        {`${
                          isScheduled
                            ? commId
                              ? 'Save changes'
                              : 'Schedule'
                            : 'Send now'
                        }`}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Stack>
              </ContentWidthContainer>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <ContentWidthContainer
          ContainerProps={{ component: DialogContent }}
          {...flexRow}
          pb={3}
          pt={2}
          sx={{
            '&.DialogContent-root': {
              overflow: 'hidden',
            },
          }}
        >
          {type === 'message' && method !== 'calendar' ? (
            <MessageGuests
              form={form}
              method={method as MessageMethodId}
              savedEvent={savedEvent}
            />
          ) : (
            <InvitationSelectGuests
              commId={Number(commId)}
              form={form}
              method={method as CommunicationMethodId}
              savedEvent={savedEvent}
            />
          )}
        </ContentWidthContainer>
      </Dialog>
      <DiscardCommunicationConfirmation
        byline={dialogCopy?.byline}
        confirmButtonLabel={['Yes,', isCanceling ? 'exit' : 'delete'].join(' ')}
        isDiscarding={isPendingDelete || isDeleted}
        isOpen={(isDirty && isCanceling) || isDeleting}
        onCancel={() => {
          setIsCanceling(false);
          setIsDeleting(false);
        }}
        onDiscard={() => {
          if (isDeleting) {
            deleteCommunication(
              Number(form.getValues('communicationHistoryId'))
            );
          } else if (isCanceling) {
            handleClose();
          }
        }}
        title={dialogCopy?.title}
      />
    </>
  );
};

export default SendCommunicationDialog;
