import React, { useMemo, useRef } from 'react';
import {
  Controller,
  ControllerRenderProps,
  UseFormReturn,
} from 'react-hook-form';
import {
  Autocomplete,
  capitalize,
  Checkbox,
  Chip,
  createFilterOptions,
  Divider,
  FormControlLabel,
  Palette,
  PaletteColor,
  Paper,
  TextField,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { Hash, Icon, Lock } from '@phosphor-icons/react';
import dayjs from 'dayjs';

import { messageEmailRecipients } from 'components/Events/Controls/Communications/communication.constants';
import { MessageFormData } from 'components/Events/Controls/Communications/communication.types';
import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import AutocompleteGroup from 'components/shared/AutocompleteGroup';
import GuestStatusChip from 'components/shared/GuestStatusChip';
import InfoTooltip from 'components/shared/InfoTooltip';
import SlackChannelLabel from 'components/shared/SlackChannelLabel';
import TooltipWithCustomWidth from 'components/shared/TooltipWithCustomWidth';

interface MessageRecipientsProps {
  allowedChannels: any[];
  hookForm: UseFormReturn<MessageFormData>;
  method?: 'slack' | 'email';
}

interface Recipient {
  color?: keyof Palette;
  email?: string;
  Icon?: Icon;
  id: string;
  label?: string;
  name?: string;
  private?: boolean;
  sendDate?: string;
  status?: 'scheduled' | 'invited' | undefined;
  type?: string;
}

const MessageRecipientsField = ({
  allowedChannels,
  hookForm: { control },
  method,
}: MessageRecipientsProps) => {
  const isSlack = method === 'slack';

  const fieldRef = useRef();
  const theme = useTheme();

  const recipientOptions = useMemo<Recipient[]>(() => {
    const options =
      (method === 'slack' ? allowedChannels : messageEmailRecipients) || [];
    if (method === 'slack' && options?.length > 0) {
      return [
        ...options.map((opt: Recipient) => ({
          ...opt,
          Icon: opt.private ? Lock : Hash,
          label: <SlackChannelLabel isPrivate={opt.private} name={opt.name} />,
        })),
      ];
    } else {
      return options;
    }
  }, [method, allowedChannels]);

  const handleToggleSelectAll = (
    field: ControllerRenderProps<
      MessageFormData,
      'invitee_statuses' | 'slack_channel_ids'
    >
  ) => {
    const hasSelectedAll = recipientOptions?.length === field.value?.length;
    const newValue = hasSelectedAll ? [] : [...recipientOptions];
    field.onChange(newValue.map((v) => v.id));
  };

  const clearGroup = (
    type: string,
    field: ControllerRenderProps<
      MessageFormData,
      'invitee_statuses' | 'slack_channel_ids'
    >
  ) => {
    if (type) {
      const selection = recipientOptions?.filter((opt: Recipient) =>
        (field.value as string[])?.includes(opt.id)
      );
      const newValue = selection?.filter((v) => v.type !== type);
      field.onChange(newValue.map((v) => v.id));
    }
  };

  const slackFilterOptions = createFilterOptions({
    stringify: (option: Recipient) => `#${option?.name || option?.label}`,
  });

  return (
    <Controller
      control={control}
      name={isSlack ? 'slack_channel_ids' : 'invitee_statuses'}
      render={({ field }) => {
        const selectedValues = recipientOptions?.filter((opt: Recipient) =>
          (field.value as string[])?.includes(opt.id)
        );
        return (
          <Autocomplete
            {...(isSlack
              ? {}
              : {
                  groupBy: (option: any) => option?.type,
                  renderGroup: (params) => (
                    <AutocompleteGroup
                      clearSelected={(type: any) => clearGroup(type, field)}
                      params={params}
                      selectionCount={
                        selectedValues?.filter(
                          (v: Recipient) => v?.type === params.group
                        )?.length
                      }
                    />
                  ),
                })}
            disableClearable
            disableCloseOnSelect
            filterOptions={isSlack ? slackFilterOptions : undefined}
            getOptionLabel={(option: Recipient) =>
              method === 'email' ? option?.label || '' : option?.name || ''
            }
            id='event-hosts'
            ListboxProps={{ sx: { pt: 0 } }}
            multiple
            onChange={(_, value) =>
              field.onChange((value || []).map((v) => v.id))
            }
            options={recipientOptions}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps} elevation={8}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={2}
                    py={1}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            field.value?.length === recipientOptions?.length
                          }
                          color='secondary'
                          id='select-all-checkbox'
                          indeterminate={
                            (field.value || '')?.length > 0 &&
                            (field.value || '')?.length <
                              recipientOptions?.length
                          }
                        />
                      }
                      label='Select all'
                      onClick={(e) => {
                        e.preventDefault(); // prevent blur
                        handleToggleSelectAll(field);
                      }}
                      sx={{ mr: 0, width: '100%' }}
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              );
            }}
            ref={fieldRef}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  isSlack ? (
                    'Slack channels'
                  ) : (
                    <InfoTooltip
                      content={
                        isSlack
                          ? 'You can only message channels that have received or are scheduled to receive an invite for this event.'
                          : 'This message will reach guests who hold the selected statuses at the time of sending.'
                      }
                      text='Recipients'
                    />
                  )
                }
                placeholder={`${
                  isSlack
                    ? 'Select invited Slack channels'
                    : 'Select recipients via guest statuses'
                }`}
                required
              />
            )}
            renderOption={(props: any, option: Recipient, state) => {
              const isChecked = selectedValues?.some(
                (selected) => selected.id === option.id
              );

              const invitationLabel = option?.status;
              const isScheduled = invitationLabel === 'scheduled';

              const invitationDateFormatted = dayjs(option.sendDate).format(
                'DD/MM/YY [at] h:mma z'
              );

              const tooltipTitle = isScheduled
                ? `${capitalize(
                    invitationLabel
                  )} to receive invitation on ${invitationDateFormatted}.`
                : `${capitalize(
                    invitationLabel ?? ''
                  )} on ${invitationDateFormatted}.`;

              return (
                <React.Fragment key={option.id}>
                  <AutocompleteCheckboxOption
                    option={{
                      ...option,
                      iconEdge: 'start',
                      ...(invitationLabel
                        ? {
                            chip: (
                              <TooltipWithCustomWidth
                                placement='top'
                                title={tooltipTitle}
                                width={isScheduled ? 230 : 170}
                              >
                                <span>
                                  <GuestStatusChip
                                    scheduledNeutral
                                    status={invitationLabel}
                                  />
                                </span>
                              </TooltipWithCustomWidth>
                            ),
                          }
                        : {}),
                    }}
                    props={props}
                    state={{ ...state, selected: isChecked }}
                  />
                </React.Fragment>
              );
            }}
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => (
                <Chip
                  {...getTagProps({ index })}
                  {...(item?.Icon && item?.Icon !== undefined
                    ? {
                        icon: (
                          <item.Icon
                            color={
                              isSlack
                                ? undefined
                                : item?.color
                                ? (theme.palette[item.color] as PaletteColor)
                                    .main
                                : undefined
                            }
                          />
                        ),
                      }
                    : {})}
                  key={item.id || index}
                  label={item.name || item.label}
                  size='small'
                />
              ))
            }
            sx={{
              '& .MuiFormControl-root.MuiTextField-root': {
                m: 0,
              },
            }}
            value={selectedValues}
          />
        );
      }}
      rules={{ required: true }}
    />
  );
};
export default MessageRecipientsField;
