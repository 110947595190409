import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSnackbar } from 'notistack';

import { useGraphQLClient } from 'hooks/useGraphQLClient';
import { handleSlackEmojiConversion } from 'utils/text';

interface GraphQLError {
  message: string;
}

interface UpdateEventsCollectionsResponse {
  eventsCollectionUpdate: {
    errors?: GraphQLError[];
    eventsCollection: {
      description: string;
      endsAt: string;
      events: {
        id: string;
      }[];
      id: string;
      name: string;
      organization: {
        id: string;
      };
      organizerEmployeeIds: [];
      startsAt: string;
      timezone: string;
      user: {
        id: string;
      };
    };
  };
}

const UPDATE_EVENTS_COLLECTION_MUTATION = gql`
  mutation EventsCollectionUpdate($input: EventsCollectionUpdateInput!) {
    eventsCollectionUpdate(input: $input) {
      eventsCollection {
        id
        name
        description
        imageUrl
        config
        startsAt
        endsAt
        timezone
        organization {
          id
        }
        organizerEmployeeIds
        user {
          id
        }
        events {
          id
          coverImageUrl
          name
          start
          ends
          description
          inviteesCount
          privacy
          published
          safeDescription
        }
      }
      errors
    }
  }
`;

const handleGraphQLResponse = <T>(
  response: T | undefined,
  errors: GraphQLError[] | undefined,
  enqueueSnackbar: (
    message: string,
    options: { variant: 'success' | 'error' | 'warning' }
  ) => void
): T | null => {
  if (errors?.length) {
    enqueueSnackbar(`Failed: ${errors.map((e) => e.message).join(', ')}`, {
      variant: 'error',
    });
    return null;
  }

  if (!response) {
    enqueueSnackbar('Failed: No data returned', {
      variant: 'error',
    });
    return null;
  }

  return response;
};

export const useEventsCollectionUpdate = () => {
  const graphQLClient = useGraphQLClient();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ data }: { data: any }) => {
      const modifiedInput = {
        ...data,
        description: handleSlackEmojiConversion(data.description) || '', // Process description
        eventIds: data.eventIds?.map((event: any) => String(event.id)) || [], // Extract event IDs
        organizerEmployeeIds: data.organizerEmployeeIds?.map(String) || [], // Extract organizer IDs
      };
      const response =
        await graphQLClient.request<UpdateEventsCollectionsResponse>(
          UPDATE_EVENTS_COLLECTION_MUTATION,
          { input: modifiedInput }
        );
      return response;
    },
    onError: (error: any) => {
      enqueueSnackbar(`Failed to update collection: ${error.message}`, {
        variant: 'error',
      });
    },
    onSuccess: (response: UpdateEventsCollectionsResponse) => {
      const { eventsCollectionUpdate } = response || {};

      const collectionData = handleGraphQLResponse(
        eventsCollectionUpdate?.eventsCollection,
        eventsCollectionUpdate?.errors,
        enqueueSnackbar
      );

      if (collectionData) {
        enqueueSnackbar('Collection updated successfully!', {
          variant: 'success',
        });
        queryClient.invalidateQueries({
          queryKey: ['fetchEventsCollection', collectionData.id],
        });
        queryClient.setQueryData(
          ['fetchEventsCollection', collectionData.id],
          collectionData
        );
      }
    },
  });
};
