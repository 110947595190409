import { useMemo } from 'react';

import { Expense, ExpenseCategory } from 'types/EventBudget';

interface UseExpenseTrackerDataProps {
  categorySelections: string[];
  expenseCategories: ExpenseCategory[];
  expenseItems: Expense[];
  search: string;
}

export const useExpenseTrackerData = ({
  categorySelections,
  expenseCategories,
  expenseItems,
  search,
}: UseExpenseTrackerDataProps) => {
  const rowsWithIndex = useMemo(
    () =>
      expenseItems
        ?.map((item, index) => ({
          ...item,
          expense_category_name:
            expenseCategories?.find(
              (category) => category?.id === item?.expense_category_id
            )?.name || '',
          index,
        }))
        ?.filter(
          (item) =>
            item?.name?.toLowerCase()?.includes(search?.toLowerCase()) &&
            (categorySelections?.length === 0 ||
              categorySelections?.includes(item.expense_category_name))
        ),
    [expenseItems, search, categorySelections, expenseCategories]
  );

  const uniqueCategoryNames = useMemo(
    () =>
      Array.from(
        new Set(expenseItems?.map((item) => item.expense_category_id))
      ).map(
        (categoryId) =>
          expenseCategories?.find((category) => category.id === categoryId)
            ?.name || ''
      ),
    [expenseItems, expenseCategories]
  );

  return { rowsWithIndex, uniqueCategoryNames };
};
