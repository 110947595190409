import React from 'react';
import PropTypes from 'prop-types';

const ChartTooltip = ({
  label,
  obj,
  prefix = '',
  showCount = false,
  textKey = 'id',
}) => (
  <div
    className='viz-tooltip-wrapper'
    style={{
      backgroundColor: '#00000090',
      borderRadius: '4px',
      color: '#fff',
      maxWidth: 200,
      padding: '8px 12px',
    }}
  >
    <strong>
      {label || (obj ? (obj.data || {})[textKey] || obj[textKey] : '')}
    </strong>
    :{' '}
    {showCount
      ? `${prefix}${Number(obj?.value).toLocaleString()}`
      : `${Math.round((obj?.value / obj?.data?.total) * 100) || 0}%`}
  </div>
);

ChartTooltip.propTypes = {
  label: PropTypes.string,
  obj: PropTypes.object,
  prefix: PropTypes.string,
  showCount: PropTypes.bool,
  textKey: PropTypes.string,
};

export default ChartTooltip;
