import React, { useMemo } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ResponsivePie } from '@nivo/pie';
import PropTypes from 'prop-types';

import ChartTooltip from 'components/Events/Controls/Analytics/Charts/ChartTooltip';
import NoResponses from 'components/Events/Controls/Analytics/Charts/NoResponses';
import ChartLegendItem from 'components/shared/ChartLegendItem';

const SurveyQuestionResponsesPieChart = ({ options, question }) => {
  const noData = useMemo(
    () => question?.responseCount === 0,
    [question?.responseCount]
  );

  const data = useMemo(
    () =>
      options?.map((o) => ({
        color: o.color,
        id: o.id,
        label: o.title,
        total: question.responseCount,
        value: o.responseCount,
      })),
    [options, question.responseCount]
  );

  return (
    <>
      {noData ? (
        <NoResponses />
      ) : (
        <Grid2
          alignItems='center'
          display='grid'
          gap={4}
          gridTemplateColumns={{
            md: '260px auto',
            xs: '100%',
          }}
          gridTemplateRows={{
            md: '220px',
            xs: '220px min-content',
          }}
          width='100%'
        >
          <Grid2
            alignContent='center'
            alignItems='center'
            height={260}
            maxWidth={260}
          >
            <ResponsivePie
              activeOuterRadiusOffset={6}
              animate
              arcLabelsComponent={({ datum, style }) =>
                datum.value > 0 ? (
                  <g
                    style={{ pointerEvents: 'none' }}
                    transform={style.transform.get()}
                  >
                    <text
                      dominantBaseline='central'
                      fill='#fff'
                      fontFamily='Inter, Roboto, Helvetica, Arial, sans-serif'
                      style={{ fontSize: 16, fontWeight: 700 }}
                      textAnchor='middle'
                    >
                      {datum.value}
                    </text>
                  </g>
                ) : null
              }
              arcLabelsTextColor='#fff'
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              borderWidth={1}
              colors={(datum) => datum.data.color}
              data={data}
              fit
              innerRadius={0.5}
              layers={['arcs', 'arcLabels', 'legends']}
              layout='horizontal'
              margin={{ bottom: 30, top: 30 }}
              theme={{
                fontFamily: 'Inter',
                fontSize: 14,
                labels: {
                  fontWeight: 700,
                },
              }}
              tooltip={(item) => (
                <ChartTooltip obj={item?.datum} textKey='label' />
              )}
            />
          </Grid2>
          <Grid2>
            {options.map((opt) => (
              <ChartLegendItem
                color={opt.color}
                key={opt.title}
                label={opt.title}
                textVariant='overline'
                value={opt.responseCount}
              />
            ))}
          </Grid2>
        </Grid2>
      )}
    </>
  );
};

SurveyQuestionResponsesPieChart.propTypes = {
  options: PropTypes.array,
  question: PropTypes.shape({
    responseCount: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.any,
  }),
};

export default SurveyQuestionResponsesPieChart;
