/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  alpha,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Popover,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { HomeContext } from 'contexts/HomeContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { DarkColor, PrimaryColor, WhiteColor } from 'utils/color';
import { getContrastColor } from 'utils/colors';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const StyledCalendar = styled(Calendar)(({ theme }) => ({
  '& .rbc-agenda-event-cell': {
    fontSize: theme.typography.overline.fontSize,
  },
  '& .rbc-event-content, & .rbc-event-label': {
    fontSize: theme.typography.caption.fontSize,
  },
  '& .rbc-header, & .rbc-header button': {
    backgroundColor: theme.palette.grey[100],
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    minHeight: 25,
  },
  '& .rbc-month-view': {
    border: 'none',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  '& .rbc-off-range-bg': {
    backgroundColor: theme.palette.grey[50],
  },
  '& .rbc-show-more': {
    color: theme.palette.primary.main,
    fontSize: theme.typography.caption,
    fontWeight: 500,
  },
  '& .rbc-time-gutter .rbc-timeslot-group': {
    backgroundColor: theme.palette.grey[100],
  },
  '& .rbc-time-view .rbc-allday-cell': {
    height: 'auto',
  },
  '& .rbc-today:not(.rbc-header), & .rbc-day-bg.rbc-today': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.5)} !important`,
  },
  button: {
    fontFamily: theme.typography.fontFamily,
  },
}));

// INFO: Unfortunately, react-big-calendar doesn't support dayjs,
// so we'll keep this as the sole instance of moment
const Localizer = momentLocalizer(moment);

const Toolbar = (props) => {
  const {
    date,
    localizer: { messages },
    onNavigate,
    onView,
    view: activeView,
    views,
  } = props;

  const viewNamesGroup = (messages) => {
    const viewNames = views;
    const view = props.view;

    if (viewNames.length > 1) {
      return (
        <ToggleButtonGroup
          aria-label='calendar view'
          exclusive
          onChange={(e, val) => onView(val)}
          size='small'
          value={view}
        >
          {viewNames
            .map((name) => (
              <ToggleButton aria-label={name} key={name} value={name}>
                {messages[name]}
              </ToggleButton>
            ))
            .reverse()}
        </ToggleButtonGroup>
      );
    }
  };

  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='flex-start'
      pr={2}
      py={0.5}
    >
      <IconButton
        color='primary'
        data-testid='TODO:DATA-ICONBUTTON-18026'
        onClick={() => onNavigate('PREV')}
      >
        <CaretLeft />
      </IconButton>
      <Typography textAlign='center' variant='h2' width={150}>
        {dayjs(date).format(`MMMM${activeView === 'day' ? ' D' : ''}`)}
      </Typography>
      <IconButton
        color='primary'
        data-testid='TODO:DATA-ICONBUTTON-23201'
        onClick={() => onNavigate('NEXT')}
      >
        <CaretRight />
      </IconButton>
      <Button
        data-testid='today'
        onClick={() => onNavigate('TODAY')}
        size='small'
        variant='outlined'
      >
        Today
      </Button>
      <Stack direction='row' flexGrow={1} justifyContent='flex-end'>
        {viewNamesGroup(messages)}
      </Stack>
    </Stack>
  );
};

Toolbar.propTypes = {
  date: PropTypes.any,
  localizer: PropTypes.shape({ messages: PropTypes.any }),
  onNavigate: PropTypes.func,
  onView: PropTypes.func,
  view: PropTypes.string,
  views: PropTypes.array,
};

const WeekHeader = ({ date }) => (
  <Typography
    aria-sort='none'
    data-testid='week-header'
    role='columnheader'
    variant='body2'
  >
    {dayjs(date).format('ddd D')}
  </Typography>
);

WeekHeader.propTypes = {
  date: PropTypes.shape({
    getDate: PropTypes.func,
    getFullYear: PropTypes.func,
    getMonth: PropTypes.func,
  }),
  label: PropTypes.shape({
    split: PropTypes.func,
  }),
};

const MonthEvent = ({ event }) => (
  <Typography
    color={getContrastColor(event.color)}
    display='block'
    lineHeight={1.15}
    variant='caption'
  >
    {moment(event.start).format('ha ')}{' '}
    <Typography
      fontWeight={500}
      lineHeight={1.1}
      variant='caption'
      whiteSpace='normal'
    >
      {event.title}
    </Typography>
  </Typography>
);

MonthEvent.propTypes = {
  event: PropTypes.shape({
    color: PropTypes.any,
    start: PropTypes.any,
    title: PropTypes.string,
  }),
};

const Event = ({ event }) => (
  <Typography variant='caption'>{event.title}</Typography>
);

Event.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
  }),
};

const EventTooltipContents = ({ event }) => {
  const [org, , , , , eventTypeColors] = useContext(OrganizationContext);

  const navigate = useNavigate();
  const location = useLocation();

  const dateTimesUS = useMemo(
    () =>
      new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }),
    []
  );

  const dateTimeRange = useMemo(() => {
    const isSingleDay = dayjs(event.start).isSame(dayjs(event.ends), 'date');
    const range = dateTimesUS
      .formatRange(new Date(event.start), new Date(event.ends))
      .toString()
      .replace(/:00/g, '')
      .replace(/\sAM/g, 'am')
      .replace(/\sPM/g, 'pm');
    return isSingleDay
      ? range.replace('2023, ', '2023  ⋅  ')
      : range.replace(' – ', '  –  ');
  }, [dateTimesUS, event.ends, event.start]);

  const eventType = org.event_types.find(
    (type) => type.id === event?.event_type_id
  );

  return (
    <Card data-testid='TODO:DATA-CARD-34199'>
      <CardActionArea
        onClick={() =>
          navigate(`/event/${event.id}`, {
            from: [location?.pathname, location?.hash || ''].join(''),
          })
        }
      >
        <CardHeader
          subheader={dateTimeRange}
          subheaderTypographyProps={{ variant: 'body1' }}
          title={event.name}
        />
        <CardContent>
          {eventType ? (
            <Chip
              label={eventType?.name}
              sx={{
                backgroundColor:
                  eventTypeColors !== null
                    ? eventTypeColors[eventType.id]
                    : 'grey',
                color: getContrastColor(
                  eventTypeColors !== null
                    ? eventTypeColors[eventType.id]
                    : 'grey'
                ),
              }}
            />
          ) : null}
          {event?.description}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

EventTooltipContents.propTypes = {
  event: PropTypes.shape({
    description: PropTypes.any,
    ends: PropTypes.any,
    event_type_id: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.string,
    start: PropTypes.any,
  }),
};

const EventCalendar = ({ calEvents }) => {
  const {
    calendar: { events, requestEventRange },
  } = useContext(HomeContext);
  const [currView, setView] = useState('month');
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectionTarget, setSelectionTarget] = useState(null);
  const [showEvent, setShowEvent] = useState(false);

  useEffect(() => {
    // Default view is Month focused on current day, load the data for that
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    requestEventRange(new Date(year, month, -6), new Date(year, month + 1, 6)); // Extra 6 days on both ends to account for 1st not being on a sunday always
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedEvent = _.find(events, (evt) => evt.id === selectedEventId);

  return (
    <Card data-testid='event-calendar' sx={{ height: '70vh' }}>
      <StyledCalendar
        components={{
          month: {
            event: MonthEvent,
          },
          toolbar: Toolbar,
        }}
        eventPropGetter={(event, start, end, isSelected) => ({
          style: {
            backgroundColor: event.color,
            borderColor: isSelected ? PrimaryColor : WhiteColor,
            borderRadius: 4,
            color: DarkColor,
            outlineColor: PrimaryColor,
            padding: '4px',
          },
        })}
        events={calEvents}
        localizer={Localizer}
        messages={{
          noEventsInRange: (
            <Box p={3}>
              <Typography color='text.secondary' variant='body1'>
                No events scheduled!
              </Typography>
            </Box>
          ),
        }}
        onRangeChange={({ end, start }) => {
          setShowEvent(false);
          requestEventRange(start, end);
        }}
        onSelectEvent={(event, synth) => {
          setSelectionTarget(synth.target);
          setSelectedEventId(event.eventId);
          setShowEvent(true);
          synth.stopPropagation();
        }}
        onView={(view) => {
          setView(view);
          setShowEvent(false);
        }}
        view={currView}
        views={Object.keys(Views).map((k) => Views[k])}
      />
      <Popover
        anchorEl={selectionTarget}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        id={`${selectedEventId}-popover`}
        onClose={() => {
          setShowEvent(false);
        }}
        open={showEvent}
      >
        <EventTooltipContents event={selectedEvent} />
      </Popover>
    </Card>
  );
};

EventCalendar.propTypes = {
  calEvents: PropTypes.any,
};
export default EventCalendar;
