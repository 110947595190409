import React from 'react';
import { Control, Controller } from 'react-hook-form';

import CurrencyTextField, {
  CurrencyTextFieldProps,
} from 'components/shared/CurrencyTextField';
import Currency from 'types/Currency';

export type FieldPathByValueType<TFormData, TValue> = {
  [K in keyof TFormData]: TFormData[K] extends TValue ? K : never;
}[keyof TFormData];

interface EventBudgetCurrencyFieldProps
  extends Partial<CurrencyTextFieldProps> {
  control: Control<any>;
  currency?: Currency;
  label?: string;
  name: string;
  placeholder?: string;
}

const EventBudgetCurrencyField = ({
  control,
  currency,
  label,
  name,
  placeholder,
  ...props
}: EventBudgetCurrencyFieldProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState: { error } }) => (
      <CurrencyTextField
        {...props}
        currency={currency}
        error={!!error}
        helperText={error?.message}
        label={label}
        onChange={(v) => {
          field.onChange(v === null ? null : parseInt(v, 10));
        }}
        placeholder={placeholder}
        value={field.value}
        variant='outlined'
      />
    )}
  />
);

export default EventBudgetCurrencyField;
