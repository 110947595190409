import React, { useContext } from 'react';
import { CardHeader } from '@mui/material';
import PropTypes from 'prop-types';

import SurveyAnonymitySubheader from 'components/Events/Controls/Shared/SurveyAnonymitySubheader';
import { LayoutContext } from 'contexts/LayoutContext';

const StandaloneSurveyHeader = ({
  displayResponders = false,
  hasDescription,
  inDialog,
  isPreEvent,
}) => {
  const { isMobile } = useContext(LayoutContext);

  return (
    <CardHeader
      subheader={
        <SurveyAnonymitySubheader displayResponders={displayResponders} />
      }
      subheaderTypographyProps={isMobile ? { variant: 'overline' } : {}}
      sx={{
        ...(inDialog ? { p: 0 } : {}),
        ...(hasDescription ? { pb: 0 } : {}),
        ...(isPreEvent ? { px: 0 } : {}),
      }}
      title={
        isPreEvent
          ? `Please submit this survey before the event!`
          : `Your feedback is important to us!`
      }
      titleTypographyProps={isMobile ? { variant: 'h5' } : {}}
    />
  );
};

StandaloneSurveyHeader.propTypes = {
  displayResponders: PropTypes.bool,
  hasDescription: PropTypes.bool,
  inDialog: PropTypes.bool,
  isPreEvent: PropTypes.bool,
};
export default StandaloneSurveyHeader;
