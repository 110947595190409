import React from 'react';
import { Box, Stack } from '@mui/material';

import AppSidenav from 'components/shared/AppSidenav';

const AppLayout = ({ children }) => {
  return (
    (<Stack direction='row' display='flex' flexWrap='nowrap' width='100vw'>
      <AppSidenav />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </Stack>)
  );
};

export default AppLayout;
