import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import sanitize from 'sanitize-filename';

import { reactQueryAxios } from 'utils/axios';

const exportExpenseTracker = async ({
  apiClient,
  eventId,
}: {
  apiClient: any;
  eventId: number;
}) =>
  apiClient({
    method: 'get',
    responseType: 'blob',
    url: `/api/event_budgets/export?event_id=${eventId}`,
  });

export const useBudgetTrackerExport = (
  eventId: number,
  eventName: string | undefined
) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: () =>
      exportExpenseTracker({ apiClient: reactQueryAxios, eventId }),
    onError: (error: Error) => {
      enqueueSnackbar('Something went wrong! Export failed. Please try again', {
        variant: 'error',
      });
      Sentry.captureException(error);
    },
    onSuccess: (data: { data: string }) => {
      fileDownload(data?.data, sanitize(`${eventName}_expense_tracker.csv`));
      enqueueSnackbar('Expense Tracker has been exported to a CSV!', {
        variant: 'success',
      });
    },
  });
};
