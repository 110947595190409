import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

export const FtnDataGridEmptyState = ({
  noun,
  ...props
}: { noun: string } & TypographyProps) => (
  <Typography color='text.secondary' p={2} variant='body1' {...props}>
    No {noun}s
  </Typography>
);
