import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

const SendPreviewEmailModal = ({
  email,
  handleClose,
  sendPreviewEmail,
  setPreviewEmail,
  show,
}) => {
  const handleEmailChange = (newValue = '') => {
    if (newValue?.trim().length === 0) {
      setPreviewEmail('');
    }
    setPreviewEmail(newValue?.trimStart());
  };

  return (
    (<Dialog aria-label='Send Preview Message' onClose={handleClose} open={show}>
      <DialogTitle>Send preview message</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Preview your message before sending it out to guests. Your preview
          will be sent to the email below.
        </DialogContentText>
        <FormGroup>
          <TextField
            fullWidth
            label='Work email'
            onChange={(e) => handleEmailChange(e.target.value)}
            value={email}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Stack alignItems='center' direction='row' gap={2}>
          <Button data-testid="cancel" onClick={handleClose} variant='text'>
            Cancel
          </Button>
          <Button
            data-testid="send-preview"
            disabled={email?.length === 0}
            onClick={sendPreviewEmail}
            variant='contained'>
            Send preview
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>)
  );
};

SendPreviewEmailModal.propTypes = {
  email: PropTypes.string,
  handleClose: PropTypes.func,
  sendPreviewEmail: PropTypes.func,
  setPreviewEmail: PropTypes.func,
  show: PropTypes.bool,
};
export default SendPreviewEmailModal;
