import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Chip } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';

import SlackSettings from 'components/Settings/Integrations/Slack/SlackIntegrationSettings';
import SyncedSlackChannels from 'components/Settings/Integrations/Slack/SyncedSlackChannels';
import TabsLayout from 'components/shared/layout/TabsLayout';

const SlackIntegration = () => {
  const { slackTab } = useParams();
  const navigate = useNavigate();

  return (
    <TabsLayout
      button={
        <Button
          data-testid='back-to-integrations'
          onClick={() => navigate('/settings/integrations')}
          startIcon={<ArrowLeft />}
          variant='text'
        >
          Back to Integrations
        </Button>
      }
      fixedContent={
        <Chip
          color='success'
          label='Connected'
          sx={{ justifySelf: 'flex-start' }}
        />
      }
      fixedLocation='with-title'
      tabs={['channels', 'settings']}
      tabValue={slackTab || 'channels'}
      title='Slack'
    >
      {!slackTab || slackTab === 'channels' ? (
        <SyncedSlackChannels />
      ) : (
        <SlackSettings />
      )}
    </TabsLayout>
  );
};

export default SlackIntegration;
