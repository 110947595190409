import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Spinner, Trash } from '@phosphor-icons/react';

interface Props {
  byline?: string;
  confirmButtonLabel?: string;
  isDiscarding?: boolean;
  isOpen?: boolean;
  onCancel?(...args: unknown[]): unknown;
  onDiscard?(...args: unknown[]): unknown;
  title?: string;
}

const DiscardCommunicationConfirmation: FC<Props> = ({
  byline,
  confirmButtonLabel,
  isDiscarding = false,
  isOpen = false,
  onCancel,
  onDiscard,
  title,
}) => {
  const handleClose = () => {
    onCancel && onCancel();
  };

  const handleDiscard = () => {
    onDiscard && onDiscard();
  };

  return (
    <Dialog aria-label='delete' onClose={handleClose} open={Boolean(isOpen)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{byline}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid='cancel'
          onClick={handleClose}
          sx={{ color: 'neutral' }}
          variant='text'
        >
          Cancel
        </Button>
        <LoadingButton
          aria-label='confirm discard'
          color='error'
          loading={isDiscarding}
          onClick={handleDiscard}
          startIcon={isDiscarding ? <Spinner /> : <Trash />}
          variant='contained'
        >
          {confirmButtonLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardCommunicationConfirmation;
