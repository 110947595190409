import { useMemo } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';

import Collection from 'types/Collection';
import { CollectionFormData } from 'types/CollectionForm';

const useCollectionFormValues = (
  savedCollectionDetails: Collection | undefined
) => {
  const myCld = useMemo(
    () =>
      new Cloudinary({
        cloud: {
          cloudName: 'five-to-nine',
        },
      }),
    []
  );

  return useMemo<CollectionFormData | undefined>(() => {
    if (savedCollectionDetails === undefined) return undefined;
    const coverImage = {
      cropDataUrl: savedCollectionDetails?.config?.cover_image_url || '',
      dimensions: savedCollectionDetails?.config?.cover_image_dimensions || {
        height: null,
        width: null,
        x: null,
        y: null,
      },
      fullDataUrl: myCld
        .image(savedCollectionDetails?.imageKey || undefined)
        .toURL(),
    };

    return {
      collection_image: coverImage,
      description: savedCollectionDetails.description || '',
      events: savedCollectionDetails.events,
      name: savedCollectionDetails.name,
      organizer_employee_ids:
        savedCollectionDetails?.organizerEmployeeIds?.map(Number),
    };
  }, [savedCollectionDetails, myCld]);
};

export default useCollectionFormValues;
