import React, { createContext, useMemo, useRef, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useWindowSize } from 'usehooks-ts';

export const LayoutContext = createContext();

export const LayoutProvider = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const horizontalPadding = { lg: 4, md: 4, sm: 3, xl: 3, xs: 2 };
  const headerRef = useRef();

  const [mobileSidenavIsOpen, setMobileSidenavIsOpen] = useState(false);
  const [sidenavIsHidden, setSidenavIsHidden] = useState(false);
  const [sidenavIsOpen, setSidenavIsOpen] = useState(false);

  const { height: viewportHeight = 0 } = useWindowSize();

  const headerHeight = useMemo(() => {
    if (headerRef && headerRef.current) {
      return headerRef.current.clientHeight;
    } else {
      return 190;
    }
  }, [headerRef]);

  const sidenavIsVisible = useMemo(() => {
    if (isMobile) {
      return mobileSidenavIsOpen;
    } else {
      return sidenavIsOpen;
    }
  }, [sidenavIsOpen, isMobile, mobileSidenavIsOpen]);

  const tableHeightOffset = useMemo(() => headerHeight + 66, [headerHeight]);

  const tableHeight = useMemo(() => {
    if (viewportHeight && headerHeight) {
      return viewportHeight - headerHeight;
    } else {
      return `calc(100vh - ${headerHeight + 66}px)`;
    }
  }, [headerHeight, viewportHeight]);

  const toggleSidenav = () => {
    isMobile
      ? setMobileSidenavIsOpen(!mobileSidenavIsOpen)
      : setSidenavIsOpen(!sidenavIsOpen);
  };

  return (
    <LayoutContext.Provider
      value={{
        headerHeight,
        headerRef,
        horizontalPadding,
        isLarge,
        isMobile,
        mobileSidenavIsOpen,
        setMobileSidenavIsOpen,
        setSidenavIsHidden,
        setSidenavIsOpen,
        sidenavIsHidden,
        sidenavIsOpen,
        sidenavIsVisible,
        tableHeight,
        tableHeightOffset,
        toggleSidenav,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.any,
};
