import React from 'react';
import {
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';

import { SurveyAnonymityOptions } from 'utils/survey';

const SurveyAnonymity = ({ onChange, value }) => (
  <Stack direction='column'>
    <FormLabel>Survey responses</FormLabel>
    <List data-testid='TODO:DATA-LIST-44747' disablePadding>
      <ListItem disableGutters disablePadding>
        <ListItemIcon>
          <FormControlLabel
            control={
              <Switch
                checked={value}
                data-testid='TODO:DATA-SWITCH-57711'
                inputProps={{
                  'aria-label': 'survey-anonymity' || 'switch',
                }}
                onChange={(e) => onChange(e)}
                sx={{ m: -0.875 }}
              />
            }
            data-testid='TODO:DATA-FORMCONTROLLABEL-57100'
            sx={{ ml: 0, mt: -1.5 }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            SurveyAnonymityOptions.find((o) => o.value === true).switchLabel
          }
          secondary={
            SurveyAnonymityOptions.find((o) => o.value === value)?.helperText
          }
          sx={{ m: 0 }}
        />
      </ListItem>
    </List>
  </Stack>
);

SurveyAnonymity.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.bool,
};

export default SurveyAnonymity;
