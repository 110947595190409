import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';

import FtnEvent from 'types/FtnEvent';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const useEventDateTimeRange = (
  event: FtnEvent | { ends: Dayjs | string, start: Dayjs | string; }
) => {
  const dateFormat = `MMMM D, `;

  const hourFormat = 'h';
  const minuteFormat = 'mm';
  const meridiemFormat = 'a';
  const timeFormatHourMeridiem = `${hourFormat}${meridiemFormat}`;
  const timeFormatHourMinutes = `${hourFormat}:${minuteFormat}`;
  const timeFormatHourMinutesMeridiem = `${timeFormatHourMinutes}${meridiemFormat}`;

  const timezone = useMemo(
    () => dayjs(event?.start).format('z'),
    [event?.start]
  );

  const start = useMemo(() => dayjs(event?.start), [event?.start]);

  const end = useMemo(() => dayjs(event?.ends), [event?.ends]);

  const startMeridiem = useMemo(() => start?.format(meridiemFormat), [start]);

  const endMeridiem = useMemo(() => end?.format(meridiemFormat), [end]);

  const isSameMeridiem = useMemo(
    () => startMeridiem === endMeridiem,
    [endMeridiem, startMeridiem]
  );
  const isSameDay = useMemo(() => start?.isSame(end, 'day'), [end, start]);

  const startsOnTheHour = (dt: Dayjs) => dt?.minute() === 0;

  const startTimeFormatting = useMemo(() => {
    const isOnTheHour = startsOnTheHour(start);
    if (!isSameDay) {
      return isOnTheHour
        ? timeFormatHourMeridiem
        : timeFormatHourMinutesMeridiem;
    } else if (isOnTheHour && isSameMeridiem) {
      return hourFormat;
    } else if (isOnTheHour) {
      return timeFormatHourMeridiem;
    } else if (isSameMeridiem) {
      return timeFormatHourMinutes;
    } else {
      return timeFormatHourMinutesMeridiem;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, isSameDay, isSameMeridiem]);

  const endTimeFormatting = useMemo(() => {
    const isOnTheHour = startsOnTheHour(end);
    return isOnTheHour ? timeFormatHourMeridiem : timeFormatHourMinutesMeridiem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end]);

  const startFormatting = useMemo(
    () =>
      isSameDay
        ? `dddd, ${dateFormat} ${startTimeFormatting}`
        : `${dateFormat} ${startTimeFormatting}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSameDay, isSameMeridiem, startTimeFormatting]
  );

  const endFormatting = useMemo(
    () =>
      isSameDay ? endTimeFormatting : `${dateFormat} ${endTimeFormatting}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSameDay, endTimeFormatting]
  );

  const formattedStart = useMemo(
    () => start?.format(startFormatting),
    [start, startFormatting]
  );

  const formattedEnd = useMemo(
    () => end?.format(endFormatting),
    [end, endFormatting]
  );

  const separator = useMemo(() => (isSameDay ? '-' : '–'), [isSameDay]);

  return useMemo(
    () => [formattedStart, separator, formattedEnd, timezone].join(' '),
    [formattedEnd, formattedStart, separator, timezone]
  );
};

export default useEventDateTimeRange;
