import React from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';

interface CheckListItemProps {
  disabled?: boolean;
  hideTooltip?: boolean;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  option: { id?: number | string; name: string };
  sx?: object;
  tooltipText?: string;
  value: boolean;
}

export const CheckListItem: React.FC<CheckListItemProps> = ({
  disabled,
  hideTooltip,
  onClick: handleChange,
  option,
  sx = {},
  tooltipText,
  value: isChecked = true,
}) => {
  const item = (
    <FormControlLabel
      componentsProps={{
        typography: { flex: '1 1 auto', overflow: 'hidden' },
      }}
      control={
        <Checkbox
          checked={isChecked}
          disabled={disabled}
          name={option.id?.toString()}
          onChange={handleChange}
        />
      }
      key={option.id}
      label={option.name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        minWidth: '100%',
        ml: -0.75,
        mr: 0,
        overflow: 'hidden',
        width: '100%',
        ...sx,
      }}
    />
  );

  return (
    <>
      {!hideTooltip ? (
        <Tooltip
          data-testid='checklist-item-tooltip'
          placement='top'
          title={tooltipText}
        >
          {item}
        </Tooltip>
      ) : (
        item
      )}
    </>
  );
};
