import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import EventPreviewDialogContent from 'components/Event/EventPreviewDialogContent';
import { EventDetailsFormWatch } from 'types/EventDetailsForm';

interface EventPreviewProps {
  isPublished: boolean;
  rsvpPauseActive?: boolean;
  watch: EventDetailsFormWatch;
}

const EventPreview = ({
  isPublished,
  rsvpPauseActive,
  watch,
}: EventPreviewProps) => {
  const [isPreviewing, setIsPreviewing] = useState(false);

  const handleClose = () => setIsPreviewing(false);

  return (
    <>
      <Button onClick={() => setIsPreviewing(true)} sx={{ px: 2 }}>
        Preview
      </Button>
      <Dialog
        fullWidth
        maxWidth='lg'
        onClose={handleClose}
        open={isPreviewing}
        scroll='paper'
      >
        <DialogTitle>
          Event page preview
          <Typography>This is what your event page will look like.</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ bgcolor: 'grey.100' }}>
          <Card>
            <CardContent sx={{ px: 3 }}>
              <EventPreviewDialogContent
                isPublished={isPublished}
                rsvpPauseActive={rsvpPauseActive}
                watch={watch}
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions sx={{ pb: 1 }}>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EventPreview.propTypes = {
  handleClose: PropTypes.func,
  type: PropTypes.string,
};

export default EventPreview;
