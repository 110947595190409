import React from 'react';
import { Box, Popover, Tooltip, Typography } from '@mui/material';
import { Info } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import { flexRow } from 'utils/styles';

const InfoTooltip = ({
  content,
  maxWidth = null,
  placement = 'bottom',
  text,
  textColor,
  textVariant,
  usePopover = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverIsOpen = Boolean(anchorEl);

  const iconProps = { size: 16 };
  const tooltip = !content ? null : usePopover ? (
    <>
      <Info
        {...iconProps}
        aria-haspopup='true'
        aria-owns={popoverIsOpen ? 'mouse-over-popover' : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        disableRestoreFocus
        id='mouse-over-popover'
        onClose={handlePopoverClose}
        open={popoverIsOpen}
        sx={{
          pointerEvents: 'none',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <>{content}</>
      </Popover>
    </>
  ) : (
    <Tooltip
      arrow
      data-testid='info-tooltip'
      placement={placement}
      PopperProps={maxWidth ? { sx: { maxWidth } } : {}}
      title={content}
    >
      <Info {...iconProps} />
    </Tooltip>
  );
  return (
    <>
      {text ? (
        <Box
          sx={{
            ...flexRow,
            alignItems: 'center',
            display: 'inline-flex',
            gap: 0.5,
          }}
        >
          <Typography
            color={textColor || null}
            variant={textVariant || 'inherit'}
          >
            {text}
          </Typography>
          {tooltip}
        </Box>
      ) : (
        <>{tooltip}</>
      )}
    </>
  );
};

InfoTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxWidth: PropTypes.number,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  text: PropTypes.string,
  textColor: PropTypes.string,
  textVariant: PropTypes.string,
  usePopover: PropTypes.bool,
};

export default InfoTooltip;
