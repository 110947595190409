import React, { useState } from 'react';
import {
  Badge,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Funnel } from '@phosphor-icons/react';
import { plural } from 'pluralize';

import TabLabelWithCount from 'components/shared/TabLabelWithCount';

interface Params {
  forFilter: boolean;
  label: string;
  params: TextFieldProps;
  value: any[];
}

export const AutocompleteRenderInput = ({
  forFilter,
  label,
  params,
  value,
}: Params) => {
  const [hasFocus, setHasFocus] = useState(false);

  const textFieldLabel = forFilter ? null : label;
  const pluralLowerLabel = plural(label).toLowerCase();
  return (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            {forFilter ? (
              <>
                {hasFocus ? (
                  <InputAdornment position='start'>
                    <Badge
                      badgeContent={hasFocus ? value?.length : undefined}
                      color='secondary'
                    >
                      <Funnel size={20} />
                    </Badge>
                  </InputAdornment>
                ) : (
                  <TabLabelWithCount
                    color='neutral'
                    count={value?.length}
                    label={label}
                    labelProps={{
                      color:
                        value?.length > 0 ? 'text.primary' : 'text.secondary',
                      whiteSpace: 'nowrap',
                    }}
                    variant='light'
                  />
                )}
              </>
            ) : (
              <>{params?.InputProps?.startAdornment}</>
            )}
          </>
        ),
      }}
      label={textFieldLabel}
      onBlur={() => setHasFocus(false)}
      onFocus={() => setHasFocus(true)}
      onKeyDown={(event) =>
        event.key === 'Escape' ? setHasFocus(false) : null
      }
      placeholder={
        forFilter ? (hasFocus ? label : '') : `Select ${pluralLowerLabel}`
      }
      sx={{
        minWidth: 160,
        ...(forFilter
          ? {
              '& .MuiAutocomplete-inputRoot': {
                flexWrap: 'nowrap',
              },
              '& .MuiInputBase-input': {
                ...(hasFocus
                  ? {}
                  : {
                      position: 'absolute',
                    }),
              },
              m: 0,
            }
          : {}),
      }}
    />
  );
};
