import React, { useContext } from 'react';
import { Autocomplete, SxProps, Theme } from '@mui/material';

import { ConditionalLoader } from 'components/Core/Loader';
import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import { AutocompleteRenderInput } from 'components/shared/AutocompleteRenderInput';
import { ListsContext } from 'contexts/ListsContext';
import { ContactList } from 'types/ContactList';

interface Selection {
  employee_ids?: number[];
  label: string;
  value: number;
}

interface ContactListsAutocompleteProps {
  forFilter?: boolean;
  fullWidth?: boolean;
  isDisabled?: boolean;
  maxTags?: number;
  onChange: (event: React.SyntheticEvent, value: Selection[]) => void;
  sx?: SxProps<Theme>;
  value?: (string | number)[];
}

const ContactListsAutocomplete = ({
  forFilter = false,
  fullWidth = false,
  isDisabled = false,
  maxTags = -1,
  onChange,
  sx = {},
  value = [],
}: ContactListsAutocompleteProps) => {
  const [rawOptions] = useContext(ListsContext);

  const options =
    rawOptions
      ?.sort((a: ContactList, b: ContactList) =>
        a?.name?.localeCompare(b?.name)
      )
      ?.map(
        ({
          employee_ids,
          ...o
        }: {
          employee_ids: number[];
          id: number;
          name: string;
        }) => ({
          employee_ids,
          label: o?.name,
          value: o?.id,
        })
      ) || [];

  return (
    <ConditionalLoader conditions={[!options]}>
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        disabled={isDisabled}
        fullWidth={fullWidth}
        id='select-contact-lists'
        limitTags={maxTags}
        multiple
        onChange={onChange}
        openOnFocus
        options={options}
        renderInput={(params) => (
          <AutocompleteRenderInput
            forFilter={forFilter}
            label='Contact list'
            params={params}
            value={value}
          />
        )}
        renderOption={(props, option, state, ownerState) => (
          <AutocompleteCheckboxOption
            option={option}
            ownerState={ownerState}
            props={props}
            state={state}
          />
        )}
        slotProps={{
          popper: {
            placement: 'bottom-start',
            style: { width: 'fit-content' },
          },
        }}
        sx={sx}
        value={options?.filter((e: Selection) => value?.includes(e.value))}
      />
    </ConditionalLoader>
  );
};

export default ContactListsAutocomplete;
