import React, { useContext } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import pluralize from 'pluralize';

import Avatar from 'components/Core/Avatar';
import InfoTooltip from 'components/shared/InfoTooltip';
import { LayoutContext } from 'contexts/LayoutContext';
import { useAuth } from 'hooks/useAuth';
import { fullName } from 'utils/employee';

const ProfileHeader = ({
  columnContent,
}: {
  columnContent: React.ReactNode;
}) => {
  const {
    userProvider: { user },
  } = useAuth();
  const { isMobile } = useContext(LayoutContext);

  return (
    <Grid
      color='grey.900'
      container
      direction='row'
      gap={2}
      item
      justifyContent='space-between'
      wrap='wrap'
      xs={12}
    >
      <Grid item xs='auto'>
        <Stack alignItems='center' direction='row' gap={2}>
          {user && (
            <Avatar
              data-testid='profile-avatar'
              employeeId={String(user?.employee_id || '')}
              height={isMobile ? 50 : 75}
              width={isMobile ? 50 : 75}
            >
              {user?.initials || ''}
            </Avatar>
          )}
          <Stack>
            <Typography variant='h2' whiteSpace='nowrap'>
              {fullName(user)}
            </Typography>
            <Typography variant='body1'>{user?.email}</Typography>
          </Stack>
        </Stack>
      </Grid>
      {isMobile ? (
        columnContent
      ) : (
        <Grid container direction='row' gap={3} item wrap='wrap' xs='auto'>
          <Grid item xs='auto'>
            <Typography fontWeight={600} variant='caption'>
              Office
            </Typography>
            <Typography variant='body1'>
              {user?.office?.name || 'No office assigned'}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <Typography fontWeight={600} variant='caption'>
              <InfoTooltip
                content='Timezone is determined from your local system settings. Use the Date & Time settings in your operating system to change the timezone as needed.'
                text='Timezone'
              />
            </Typography>
            <Typography variant='body1'>
              {Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <Typography fontWeight={600} variant='caption' whiteSpace='nowrap'>
              {`${pluralize('Event', user?.events_hosted_count)} hosted`}
            </Typography>
            <Typography variant='body1'>
              {user?.events_hosted_count || 0}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default ProfileHeader;
