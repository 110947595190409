import React, { FC, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import pluralize from 'pluralize';

interface DeleteModalProps {
  confirm?: boolean;
  custom?: {
    byline?: string;
    confirm?: string;
    delete?: string;
    headline?: string;
    title?: string;
    icon?: React.ReactNode;
  };
  isPerson?: boolean;
  itemCount?: number;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  noun?: string;
  onCancel?(...args: unknown[]): unknown;
  onDelete?(...args: unknown[]): unknown;
  owner?: string;
  plural?: boolean;
  preposition?: string;
  show?: boolean;
  subject?: string;
  verb?: string;
}

const DeleteModal: FC<DeleteModalProps> = ({
  confirm,
  custom,
  isPerson,
  itemCount = 1,
  maxWidth,
  noun,
  onCancel,
  onDelete,
  owner,
  plural: isPlural,
  preposition = 'available on',
  show,
  subject,
  verb = 'Delete',
}) => {
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    if (!show && isWorking) {
      setIsWorking(false);
    }
  }, [isWorking, show]);

  const derivedItemCount = useMemo(
    () =>
      // Ideally we would just use [itemCount] to determine if words should be pluralized,
      // but some instances of <DeleteModal> that use [plural] can't easily be refactored
      // to pass the actual number of items. In these cases, we will just use 2 as the
      // (derived) item count, so words will be pluralized correctly.
      isPlural ? 2 : itemCount,
    [isPlural, itemCount]
  );
  const lowerNoun = useMemo(() => String(noun?.toLocaleLowerCase()), [noun]);
  const lowerVerb = useMemo(() => String(verb?.toLocaleLowerCase()), [verb]);

  const handleClose = () => {
    setIsWorking(false);
    onCancel && onCancel();
  };

  const handleDelete = () => {
    setIsWorking(true);
    if (onDelete) {
      onDelete(() => setIsWorking(false));
    }
  };

  return (
    <Dialog
      aria-label='delete'
      onClose={handleClose}
      open={Boolean(show)}
      {...(maxWidth ? { fullWidth: true, maxWidth } : {})}
    >
      <DialogTitle>{custom?.title || `${verb} ${subject}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {custom?.byline ||
            `${pluralize('This', derivedItemCount)} ${pluralize(
              lowerNoun,
              derivedItemCount,
              itemCount !== 1
            )} will no longer be ${preposition} ${owner}. Are you sure you want to
                  ${lowerVerb} ${itemCount !== 1 || isPerson ? 'them' : 'it'}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          data-testid='cancel'
          onClick={handleClose}
          variant='bordered'
        >
          Cancel
        </Button>
        <LoadingButton
          aria-label='confirm delete'
          color={confirm || custom?.confirm ? 'primary' : 'error'}
          loading={isWorking}
          onClick={handleDelete}
          variant='contained'
          startIcon={custom?.icon}
        >
          {custom?.confirm ||
            custom?.delete ||
            `Yes, ${lowerVerb} ${
              noun ? pluralize(lowerNoun, derivedItemCount) : ''
            }`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
