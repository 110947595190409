import React, { useContext, useMemo } from 'react';
import {
  Avatar,
  capitalize,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@mui/material';

import {
  CommunicationMethod,
  CommunicationMethodTooltip,
  CommunicationType,
} from 'components/Events/Controls/Communications/communication.types';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import useCommunicationMethods from 'hooks/useCommunicationMethods';

interface Props {
  handleClick: React.MouseEventHandler<HTMLElement>;
  hasChannelsToMessage?: boolean;
  method: CommunicationMethod;
  tooltip?: CommunicationMethodTooltip | undefined;
  type: CommunicationType;
}

const CommunicationMethodMenuItem = ({
  handleClick,
  hasChannelsToMessage,
  method,
  tooltip,
  type,
}: Props) => {
  const {
    state: { event },
  } = useContext(ManagerContext);

  const { enabledMethods } = useCommunicationMethods();

  const isInvitations = useMemo(() => type?.id === 'invitation', [type?.id]);

  const isConnected = useMemo(
    () => enabledMethods.findIndex((m) => m.id === method.id) !== -1,
    [enabledMethods, method.id]
  );

  const isDisabledByRsvpPausing = useMemo(
    () =>
      isInvitations && event?.rsvp_pause_allowed === true
        ? ['slack', 'calendar'].includes(method.id)
        : false,
    [event?.rsvp_pause_allowed, isInvitations, method.id]
  );

  const isDisabledByRsvpClosing = useMemo(
    () =>
      isInvitations && event?.rsvp_pause_active === true
        ? ['slack', 'calendar', 'email'].includes(method.id)
        : false,
    [event?.rsvp_pause_active, isInvitations, method.id]
  );

  const hasInviteesToMessage = useMemo(
    () =>
      method?.id !== 'slack' &&
      type?.id === 'message' &&
      event?.invitees_count > 0,
    [event?.invitees_count, method?.id, type?.id]
  );

  const isEnabled = useMemo(() => {
    switch (type?.id) {
      case 'invitation':
        return (
          isConnected && !isDisabledByRsvpPausing && !isDisabledByRsvpClosing
        );
      case 'message':
        return isConnected && (hasChannelsToMessage || hasInviteesToMessage);
      default:
        return false;
    }
  }, [
    type?.id,
    isConnected,
    isDisabledByRsvpPausing,
    isDisabledByRsvpClosing,
    hasChannelsToMessage,
    hasInviteesToMessage,
  ]);

  const tooltipText = useMemo(() => {
    if (!isConnected) {
      return tooltip?.integration;
    } else if (isDisabledByRsvpPausing) {
      return tooltip?.rsvpPause;
    } else if (isDisabledByRsvpClosing) {
      return tooltip?.rsvpPause;
    } else if (!isEnabled) {
      return tooltip?.invitees;
    }
    return '';
  }, [
    isConnected,
    isDisabledByRsvpPausing,
    isDisabledByRsvpClosing,
    isEnabled,
    tooltip?.integration,
    tooltip?.rsvpPause,
    tooltip?.invitees,
  ]);

  return (
    <Tooltip
      disableHoverListener={isEnabled}
      placement='left'
      title={tooltipText}
    >
      <span>
        <MenuItem disabled={!isEnabled} key={method?.id} onClick={handleClick}>
          {method?.Icon ? (
            <ListItemAvatar sx={{ minWidth: 36 }}>
              <Avatar
                sx={{
                  bgcolor: `${method?.color}.light`,
                  color: `${method?.color}.dark`,
                  height: 28,
                  width: 28,
                }}
                variant='rounded'
              >
                <method.Icon size={20} />
              </Avatar>
            </ListItemAvatar>
          ) : null}
          <ListItemText primary={capitalize(method?.label ?? '')} />
        </MenuItem>
      </span>
    </Tooltip>
  );
};

export default CommunicationMethodMenuItem;
