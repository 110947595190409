import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';
import { ArrowRight } from '@phosphor-icons/react';
import { snakeCase } from 'lodash';

import { flexColumn } from 'utils/styles';
import { toHtmlId } from 'utils/text';

interface Props {
  buttonText?: string;
  description: string;
  imageName?: string;
  isActive: boolean;
  isLoading: boolean;
  name: string;
  onEdit: () => void;
  onSetUp: () => void;
}

const IntegrationCard = ({
  buttonText,
  description,
  imageName,
  isActive,
  isLoading,
  name,
  onEdit,
  onSetUp,
}: Props) => {
  const btnText = buttonText || `${isActive ? 'Manage' : 'Set up'} ${name}`;
  const btnFunction = isActive ? onEdit : onSetUp;
  const id = toHtmlId(name);
  return (
    <Card
      data-testid={`integration-card-${id}`}
      sx={{ ...flexColumn, flex: '1 1 auto' }}
    >
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Box display='flex' justifyContent='space-between'>
          <Avatar
            data-testid={`integration-card-${id}-avatar`}
            src={`/images/${imageName || snakeCase(name)}.svg`}
            sx={{ height: 36, width: 36 }}
            variant='square'
          />
          {isActive && (
            <Chip color='success' label='Connected' variant='outlined' />
          )}
        </Box>
        <Typography gutterBottom mt={1} variant='h3'>
          {name}
        </Typography>
        <Typography variant='body1'>{description}</Typography>
      </CardContent>
      <CardActions>
        <LoadingButton
          aria-label={btnText}
          color='primary'
          endIcon={<ArrowRight />}
          loading={isLoading}
          onClick={btnFunction}
          sx={{ lineHeight: 1.5, textAlign: 'right' }}
          variant='text'
        >
          {btnText}
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default IntegrationCard;
