import React, { ReactNode, useMemo } from 'react';
import { createCloudinaryLegacyURL } from '@cloudinary/url-gen';
import { Avatar as MuiAvatar, useTheme } from '@mui/material';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { merge } from 'lodash';

import { reactQueryAxios } from 'utils/axios';

interface AvatarProps {
  [key: string]: any;
  children?: ReactNode;
  employeeId?: string;
  height?: number;
  rootClassName?: string;
  width?: number; // To allow additional props
}

const Avatar = ({
  children,
  employeeId,
  height = 24,
  width = 24,
  ...props
}: AvatarProps) => {
  const themePalette = useTheme().palette;

  const getAvatar = async (employeeId: string | undefined) => {
    if (employeeId) {
      return reactQueryAxios({
        method: 'get',
        url: `/api/employees/${employeeId}/avatar`,
      });
    }
    return null;
  };

  const { data }: UseQueryResult<{ avatar: { key: string } }> = useQuery({
    gcTime: Infinity,
    queryFn: () => getAvatar(employeeId),
    queryKey: ['avatar', employeeId],
    staleTime: 86400000, // 24 hours
  });

  const migrateUrl = useMemo(
    () =>
      data?.avatar?.key
        ? createCloudinaryLegacyURL(data.avatar.key, {
            aspect_ratio: '1.0',
            cloud_name: 'five-to-nine',
            crop: 'fit',
            height: height * 3,
            public_id: data.avatar.key,
            secure: process.env.NODE_ENV !== 'development',
          })
        : '',
    [data?.avatar?.key, height]
  );

  const fontSize = useMemo(() => 0.5 * height, [height]);

  const avatarProps = merge(
    {
      src: migrateUrl,
      sx: {
        bgcolor: themePalette.secondary.main,
        fontSize,
        height,
        width,
      },
    },
    props
  );

  return <MuiAvatar {...avatarProps}>{children}</MuiAvatar>;
};

export default Avatar;
