import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Trash } from '@phosphor-icons/react';

import useDialogState from 'hooks/useDialogState';
import { useOrganization } from 'hooks/useOrganization';
import { DialogState } from 'types/DialogState';
import { ExpenseCategory } from 'types/EventBudget';

const ExpenseCategoryForm = ({
  category,
  dialogState: formDialog,
}: {
  category: ExpenseCategory;
  dialogState: DialogState;
}) => {
  const { update } = useOrganization();

  const confirmDialog = useDialogState();

  const { formState, handleSubmit, register, resetField } = useForm({
    defaultValues: { name: category?.name },
  });

  const closeConfirmDialog = () => confirmDialog.close();

  const deleteCategory = () => {
    update.mutate({
      expense_categories_attributes: [{ ...category, active: false }],
      successMessage: `“${category.name}” deleted from your expense categories!`,
    });
    closeConfirmDialog();
    formDialog.close();
  };

  const updateCategory = (name: string) => {
    const previousName = category.name;
    update.mutate({
      expense_categories_attributes: [{ ...category, name }],
      successMessage: `“${previousName}” expense category updated to “${name}”!`,
    });
    formDialog.close();
  };

  const hasExpenseItems = React.useMemo(
    () => category?.expense_items_count && category?.expense_items_count > 0,
    [category]
  );

  return (
    <form>
      <Dialog
        aria-labelledby='expense-categories-title'
        fullWidth
        maxWidth='xs'
        onClose={() => formDialog.close()}
        open={formDialog.isOpen}
      >
        <DialogTitle id='expense-categories-title'>
          Edit expense category
        </DialogTitle>
        <DialogContent>
          <TextField
            error={formState.errors?.name?.type === 'required'}
            fullWidth
            helperText={formState.errors?.name?.message}
            label='Name'
            placeholder='Expense category name'
            required
            {...register('name', {
              required: 'Please enter a name or delete this category',
            })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='neutral'
            onClick={() => confirmDialog.open()}
            startIcon={<Trash />}
          >
            Delete
          </Button>
          <Box flexGrow={1}>&nbsp;</Box>
          <Button
            color='primary'
            onClick={() => {
              formDialog.close();
              resetField('name');
            }}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            disabled={!formState.isDirty}
            onClick={handleSubmit((data) => updateCategory(data.name))}
            variant='contained'
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={closeConfirmDialog} open={confirmDialog.isOpen}>
        <DialogTitle>Delete expense category?</DialogTitle>
        <DialogContent>
          <DialogContentText sx={hasExpenseItems ? { mb: 2 } : {}}>
            By proceeding, “{category?.name}” will be deleted as an expense
            category from your organization settings.
            {hasExpenseItems
              ? ` Any expenses associated with this category will no longer be assigned to any expense category.`
              : ''}
          </DialogContentText>
          {hasExpenseItems ? (
            <Alert severity='error'>
              <AlertTitle sx={{ typography: 'body1' }}>
                {category?.expense_items_count} expenses will be unassigned from
                this expense category.
              </AlertTitle>
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            color='neutral'
            onClick={closeConfirmDialog}
            variant='bordered'
          >
            Cancel
          </Button>
          <Button
            color='error'
            onClick={deleteCategory}
            startIcon={<Trash />}
            variant='contained'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default ExpenseCategoryForm;
