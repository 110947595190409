import React, { useContext } from 'react';
import {
  Avatar,
  Box,
  capitalize,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { ArrowDown, ArrowsDownUp, ArrowUp } from '@phosphor-icons/react';

import { FtnDataGridEmptyState } from 'components/shared/dataGrid/FtnDataGridEmptyState';
import { FtnDataGridFooter } from 'components/shared/dataGrid/FtnDataGridFooter';
import SelectionToolbar from 'components/shared/SelectionToolbar';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { LayoutContext } from 'contexts/LayoutContext';

interface FtnDataGridProps extends DataGridProps {
  canEdit?: boolean;
  columns: any[];
  isLoading?: boolean;
  noRowsOverlay?: React.JSXElementConstructor<any> | undefined;
  noRowsTableHeight?: number;
  noun?: string;
  pageSize?: number;
  rows: any[];
  selectionToolbarProps?: any; // shape({ ...SelectionToolbar.propTypes }),
  withLinearLoading?: boolean;
  withoutCustomFooter?: boolean;
  withoutFirstLastButtons?: boolean;
  withoutPageSizeSelect?: boolean;
}

const FtnDataGrid = ({
  columns,
  initialState,
  isLoading = false,
  noRowsOverlay,
  noRowsTableHeight,
  noun = 'row',
  pageSize = 10,
  selectionToolbarProps,
  withLinearLoading = false,
  withoutCustomFooter = false,
  withoutFirstLastButtons = false,
  withoutPageSizeSelect = false,
  ...props
}: FtnDataGridProps) => {
  const {
    userProvider: { isOrganizerOrAdmin },
  } = useContext(AuthContext) as AuthContextType;
  const { tableHeight } = useContext(LayoutContext);

  const defaultNoRowsOverlay = () => <FtnDataGridEmptyState noun={noun} />;

  const formatColumns = (columns: any[], canEdit: boolean | null = null) => {
    const flexTotal = columns
      .filter((col) => col.flex)
      .map((col) => col.flex)
      .reduce((total, current) => total + current, 0);

    return columns
      .map((col) => {
        const {
          avatar,
          field,
          flex,
          headerName,
          minWidth: minWidthTotal,
          subfield,
        } = col;
        const flexAsPercent = flex ? flex / flexTotal : 0.25;
        const minWidth =
          props?.density && props?.density === 'comfortable'
            ? minWidthTotal + 40
            : minWidthTotal;
        return {
          ...col,
          ...(!headerName && field?.length > 0
            ? { headerName: capitalize(field) }
            : {}),
          ...(!minWidth
            ? { minWidth: 300 * flexAsPercent }
            : !flex
            ? { width: minWidth }
            : {}),
          ...(subfield
            ? {
                renderCell: (params: GridRenderCellParams<any, Date>) => (
                  <Stack maxWidth='100%'>
                    <Typography variant='body2'>{params.row[field]}</Typography>
                    <Typography
                      color='grey.600'
                      maxWidth='100%'
                      overflow='hidden'
                      textOverflow='ellipsis'
                      variant='body1'
                    >
                      {params.row[subfield]}
                    </Typography>
                  </Stack>
                ),
              }
            : {}),
          ...(avatar
            ? {
                renderCell: (params: GridRenderCellParams<any, Date>) => (
                  <Stack
                    alignItems='center'
                    direction='row'
                    gap={1.5}
                    maxWidth='100%'
                  >
                    <Avatar
                      src={avatar.src}
                      sx={{
                        height: avatar.size,
                        width: avatar.size,
                      }}
                      variant='circular'
                    />
                    <Typography
                      overflow='hidden'
                      textOverflow='ellipsis'
                      variant='body1'
                    >
                      {params.row[field]}
                    </Typography>
                  </Stack>
                ),
              }
            : {}),
        };
      })
      .filter((col) =>
        col.field === 'actions'
          ? canEdit !== null
            ? canEdit
            : isOrganizerOrAdmin
          : true
      );
  };

  return (
    <Box>
      <DataGrid
        {...props}
        columnHeaderHeight={props.rows?.length > 0 ? props.rowHeight || 60 : 0}
        columns={formatColumns(columns, props?.canEdit)}
        disableVirtualization
        hideFooter={props.rows?.length < 1}
        initialState={{
          pagination: { paginationModel: { pageSize } },
          sorting: initialState?.sorting || {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        loading={isLoading}
        rowHeight={props.rowHeight || 60}
        slotProps={{
          footer: {
            noun,
            withoutFirstLastButtons,
            withoutPageSizeSelect,
          },
        }}
        slots={{
          ...(withLinearLoading ? { loadingOverlay: LinearProgress } : {}),
          ...(withoutCustomFooter ? {} : { footer: FtnDataGridFooter }),
          columnSortedAscendingIcon: ArrowDown,
          columnSortedDescendingIcon: ArrowUp,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          columnUnsortedIcon: ({ sortingOrder, ...other }) => (
            <ArrowsDownUp {...other} />
          ),
          noRowsOverlay: noRowsOverlay || defaultNoRowsOverlay,
          toolbar: () =>
            selectionToolbarProps ? (
              <SelectionToolbar {...selectionToolbarProps} />
            ) : null,
        }}
        style={{
          height:
            props.rows?.length > 0
              ? tableHeight || `calc(100vh - 256px)`
              : noRowsTableHeight || '50vh',
        }}
        sx={{
          ...(selectionToolbarProps
            ? {
                '&.MuiDataGrid-root, & .MuiDataGrid-columnHeaders': {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                },
              }
            : {
                '& .MuiDataGrid-main': {
                  borderTopLeftRadius: 6,
                  borderTopRightRadius: 6,
                },
              }),
          ...(props?.onRowClick || selectionToolbarProps
            ? {
                cursor: 'pointer',
              }
            : {
                '& .MuiDataGrid-row:hover': {
                  bgcolor: 'transparent',
                },
              }),
          ...props.sx,
          ...(props.rows?.length > 0
            ? {}
            : {
                '& .MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaders': {
                  borderBottom: 'none',
                },
              }),
        }}
      />
    </Box>
  );
};

export default FtnDataGrid;
