import React, { useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';

import { getDefaultMRTOptions } from 'constants/table.constants';
import { Employee } from 'types/Employee';
import { fullName } from 'utils/employee';

interface Props {
  handleClose: () => void;
  list: {
    employees: Employee[];
    name: string;
  };
  officesById: any;
  show: boolean;
}

interface Member {
  email: string;
  id: string | number;
  name: string;
  office?: string;
}

const ContactListMembersDialog = ({
  handleClose,
  list,
  officesById,
  show,
}: Props) => {
  const data: Member[] | undefined = useMemo(() => {
    if (list && Object.keys(list).length > 0) {
      return list.employees?.map((e) => ({
        email: e.email,
        id: e.id,
        name: fullName(e),
        office: officesById[e.office_id]?.name || '',
      }));
    }
  }, [list, officesById]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Contact name',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'office',
      header: 'Office',
    },
  ];

  const defaultOptions = getDefaultMRTOptions<Member>('60vh', true);

  return (
    <Dialog
      data-testid='contact-list-members'
      fullWidth
      maxWidth='lg'
      onClose={handleClose}
      open={show}
      sx={{ minHeight: '90vh' }}
    >
      <DialogTitle>{list?.name}</DialogTitle>
      <DialogContent>
        <MaterialReactTable
          columns={columns}
          data={data || []}
          layoutMode='grid'
          {...defaultOptions}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ContactListMembersDialog;
