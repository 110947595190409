import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Stack, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';

import GroupBudgetCard from 'components/Groups/GroupBudgetCard';
import GroupBudgetSpendBreakdown from 'components/Groups/GroupBudgetSpendBreakdown';
import GroupBudgetSpendOverTime from 'components/Groups/GroupBudgetSpendOverTime';
import GroupBudgetTracker from 'components/Groups/GroupBudgetTracker';
import SectionWrapper from 'components/shared/SectionWrapper';
import { useGroup } from 'hooks/useGroup';
import { useGroupBudget } from 'hooks/useGroupBudget';

const FlexCard = styled(Card)(() => ({
  flex: 1,
  overflow: 'visible',
}));

export default function GroupBudget() {
  const { id } = useParams<{ id: string }>();
  const groupId = Number(id);

  const {
    retrieveBudget: { data: eventsBudgetData },
    retrieveBudgetPeriodBreakdown: { data: expensesData },
  } = useGroupBudget({ groupId });

  const {
    retrieve: { data: group },
  } = useGroup(groupId);

  if (!expensesData) {
    return null;
  }

  const {
    budget_period_end_date,
    budget_period_start_date,
    budget_period_total,
    budget_period_total_spent,
    event_type_breakdown,
    expense_category_breakdown,
    monthly_breakdown,
  } = expensesData;

  const sortedExpenseCategoryBreakdown = expense_category_breakdown.sort(
    (a, b) => a?.name?.localeCompare(b?.name)
  );

  const chartData = [
    // { name: monthly_breakdown[0]?.name || '', value: 0 },
    ...monthly_breakdown,
  ];

  return (
    <Stack alignItems='stretch' direction='column' gap={3}>
      <SectionWrapper
        action={
          <Typography color='text.secondary'>
            {[
              dayjs(budget_period_start_date).format('ll'),
              dayjs(budget_period_end_date).format('ll'),
            ].join(' - ')}
          </Typography>
        }
        title='Overview'
      >
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          gap={2}
          justifyContent='space-between'
          width='100%'
        >
          <FlexCard>
            <GroupBudgetCard
              group={group}
              groupBudget={budget_period_total}
              totalSpent={budget_period_total_spent}
            />
          </FlexCard>
          <FlexCard>
            <GroupBudgetSpendOverTime
              chartData={chartData}
              currency={group?.currency_details}
            />
          </FlexCard>
          <FlexCard>
            <GroupBudgetSpendBreakdown
              currency={group?.currency_details}
              eventTypeBreakdown={event_type_breakdown}
              expenseCategoryBreakdown={sortedExpenseCategoryBreakdown}
            />
          </FlexCard>
        </Stack>
      </SectionWrapper>
      <GroupBudgetTracker
        currency={group?.currency_details}
        eventsGroup={eventsBudgetData || { events: [] }}
        groupId={String(groupId)}
        groupName={group?.name || ''}
      />
    </Stack>
  );
}
