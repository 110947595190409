import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chip, Collapse, Stack, Typography } from '@mui/material';
import pluralize from 'pluralize';

import TooltipWithCustomWidth from 'components/shared/TooltipWithCustomWidth';
import { useEventBudgetSettingsForm } from 'hooks/useEventBudgetSettingsForm';

const EventGroupChips = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const eventId = Number(id);

  const { form } = useEventBudgetSettingsForm({
    eventId,
  });
  const { watch } = form;

  const groupsWatch = watch('groups');

  return (
    <Collapse
      in={!!groupsWatch && groupsWatch.length > 0}
      orientation='vertical'
      sx={{ mt: -1 }}
    >
      <Stack direction='row' justifyContent='flex-start'>
        <TooltipWithCustomWidth
          placement='bottom-start'
          title={`Total spend will be ${
            groupsWatch && groupsWatch?.length > 1 ? 'evenly ' : ''
          }deducted from ${
            groupsWatch && groupsWatch?.length > 1
              ? "linked groups' budgets"
              : "this group's budget"
          }.`}
          width={240}
        >
          <Stack alignItems='center' direction='row' flexWrap='wrap' gap={1}>
            <Typography color='grey.700' variant='body2'>
              Linked {pluralize('group', groupsWatch?.length || 0)}:
            </Typography>
            {groupsWatch?.map(({ id, name }) => (
              <Chip
                clickable
                color='primary'
                key={id}
                label={name}
                onClick={() => navigate(`/group/${id}/budget`)}
                size='small'
                variant='light'
              />
            ))}
          </Stack>
        </TooltipWithCustomWidth>
      </Stack>
    </Collapse>
  );
};

export default EventGroupChips;
