import { alpha, styled, ToggleButton } from '@mui/material';

const iconSize = 24;

const iconSizeStyles = {
  height: iconSize,
  width: iconSize,
};

const RichTextToolbarButton = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, selected ? 1 : 0),
  borderWidth: `0 !important`,
  height: 32,
  padding: theme.spacing(0.5),
  ...(selected
    ? {
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.8),
        },
        color: 'white',
        svg: {
          ...iconSizeStyles,
          color: 'white',
        },
      }
    : {
        '&:not(.Mui-disabled)': {
          svg: { color: theme.palette.grey[700], ...iconSizeStyles },
        },
      }),
}));

export default RichTextToolbarButton;
