import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CalendarBlank, Clipboard, UsersThree } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import WelcomeMessageButton from 'components/Onboard/WelcomeMessageButton';

const WelcomeMessageUser = ({ advanceStep }) => (
  <>
    <List data-testid='user-welcome-message-list'>
      <ListItem>
        <ListItemIcon>
          <CalendarBlank size={32} />
        </ListItemIcon>
        <ListItemText
          primary='Check out upcoming events'
          secondary='Browse & RSVP to events that are coming up at your organization.'
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <UsersThree size={32} />
        </ListItemIcon>
        <ListItemText
          primary="Join your organization's groups"
          secondary='Check out events & programs hosted by your favorite groups.'
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Clipboard size={32} />
        </ListItemIcon>
        <ListItemText
          primary='Give feedback'
          secondary='Fill out a survey after an event to let the organizer know what you thought.'
        />
      </ListItem>
    </List>
    <WelcomeMessageButton advanceStep={advanceStep} />
  </>
);

export default WelcomeMessageUser;

WelcomeMessageUser.propTypes = {
  advanceStep: PropTypes.any,
};
