import React, { useState } from 'react';
import { Button, capitalize, FormGroup, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';

import DescriptionModal from 'components/shared/DescriptionModal';
import InfoTooltip from 'components/shared/InfoTooltip';
import RichTextDisplay from 'components/shared/RichTextDisplay';
import RichTextEditor from 'components/shared/RichTextEditor';

const DescriptionButton = ({
  disabled,
  handleChange,
  labelStyles = {},
  noMargin = false,
  path,
  showInline = false,
  showLabel = true,
  showPreview = false,
  showTypeInLabel = false,
  tooltip = null,
  truncateLong = false,
  type,
  value,
}) => {
  const [show, setShow] = useState(false);
  const label = `${showTypeInLabel ? capitalize(type) + ' d' : 'D'}escription`;

  return (
    <FormGroup sx={noMargin ? {} : { my: 1 }}>
      {showLabel ? (
        <FormLabel
          disabled={disabled}
          htmlFor={`${type}-description`}
          sx={{ display: 'block', maxWidth: '100%', ...labelStyles }}
        >
          {tooltip !== null ? (
            <InfoTooltip content={tooltip} text={label} />
          ) : (
            <>{label}</>
          )}
        </FormLabel>
      ) : null}
      {showPreview ? (
        <RichTextDisplay text={value} truncateLong={truncateLong} />
      ) : null}
      {showInline ? (
        <RichTextEditor
          isDisabled={disabled}
          onChange={handleChange}
          value={value || ''}
        />
      ) : (
        <Button
          color='secondary'
          data-testid='TODO:DATA-BUTTON-12034'
          disabled={disabled}
          endIcon={
            !showLabel && tooltip?.length > 0 ? (
              <InfoTooltip content={tooltip} />
            ) : null
          }
          fullWidth={false}
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
          size='small'
          sx={{ alignSelf: 'flex-start' }}
          variant='bordered'
        >
          {`${!value || value?.length === 0 ? 'Add' : 'Edit'} description`}
        </Button>
      )}
      <DescriptionModal
        description={value || ''}
        edit={value?.length > 0}
        handleClose={() => setShow(false)}
        handleSave={handleChange}
        noun={type}
        show={show}
      />
    </FormGroup>
  );
};

DescriptionButton.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  labelStyles: PropTypes.object,
  noMargin: PropTypes.bool,
  path: PropTypes.any,
  showInline: PropTypes.bool,
  showLabel: PropTypes.bool,
  showPreview: PropTypes.bool,
  showTypeInLabel: PropTypes.bool,
  tooltip: PropTypes.any,
  tooltipId: PropTypes.any,
  truncateLong: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default DescriptionButton;
