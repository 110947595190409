import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Autocomplete, TextField, Checkbox, Box, Divider, Paper, Typography, CircularProgress, ListItem } from '@mui/material';
import SlackChannelLabel from 'components/shared/SlackChannelLabel';
import { useFetchEventsCollectionSlackChannelOptions } from 'hooks/useFetchEventsCollectionSlackChannelOptions';

interface AnnouncementRecipientsFieldProps {
  hookForm: UseFormReturn<any>;
  collectionId: string;
}

const AnnouncementRecipientsField: React.FC<AnnouncementRecipientsFieldProps> = ({
  hookForm: { control },
  collectionId,
}) => {
  const { data: slackChannelOptions = [], isLoading } = useFetchEventsCollectionSlackChannelOptions(collectionId);

  return (
    <Controller
      control={control}
      name="slack_channel_ids"
      render={({ field }) => {
        const selectedValues = slackChannelOptions.filter((channel) =>
          field.value?.includes(channel.slackId)
        );

        return (
          <Autocomplete
            multiple
            options={slackChannelOptions}
            disableClearable
            disableCloseOnSelect
            getOptionLabel={(option) => option.name || 'Unknown'}
            value={selectedValues}
            onChange={(_, newValue) => {
              const selectedIds = newValue.map((v) => v.slackId);
              field.onChange(selectedIds);
            }}
            PaperComponent={(props) => (
              <Paper {...props} elevation={8}>
                <Box pl={2} py={1} sx={{ backgroundColor: '#f9f9f9' }}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontWeight: 600, letterSpacing: '0.05em' }}
                  >
                    SLACK CHANNELS
                  </Typography>
                </Box>
                <Divider />
                {isLoading ? (
                  <ListItem>
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                      <CircularProgress size={24} />
                    </Box>
                  </ListItem>
                ) : (
                  props.children
                )}
              </Paper>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select slack channels"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} sx={{ mr: 1 }} />
                <SlackChannelLabel name={option.name} isPrivate={option.private} />
              </li>
            )}
          />
        );
      }}
    />
  );
};

export default AnnouncementRecipientsField;
