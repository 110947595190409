/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FormControl, LinearProgress, Typography } from '@mui/material';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import Search from 'components/Core/Search';
import SlackChannelLabel from 'components/shared/SlackChannelLabel';

const SlackCheckList = ({
  currentPage,
  disabled: isDisabled,
  loading,
  onChange,
  onPageChange,
  onSearchChange,
  options,
  searchTerm,
  totalCount,
  value = [],
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    if (options?.length > 0 && !isEqual(value, selections)) {
      setSelections(value);
    }
  }, [value, options, selections]);

  return (
    <>
      <FormControl fullWidth>
        <Search
          fullWidth
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder='Search by channel name'
          value={searchTerm}
        />
      </FormControl>
      <DataGrid
        checkboxSelection={!isDisabled}
        columnHeaderHeight={40}
        columns={[
          {
            field: 'name',
            flex: 1,
            headerName: 'Name',
            renderCell: ({ row: { name, private: isPrivate } }) => (
              <SlackChannelLabel
                isBold={false}
                isPrivate={isPrivate}
                name={name}
              />
            ),
            valueGetter: (params) => params.value,
          },
        ]}
        disableColumnSelector
        disableDensitySelector
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        keepNonExistentRowsSelected
        loading={loading}
        onRowSelectionModelChange={(newSelections) => {
          setHasChanged(true);
          setSelections(newSelections);
          if (onChange) {
            onChange(newSelections);
          }
        }}
        rowHeight={40}
        rows={options}
        rowSelectionModel={selections}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: (props = {}) => (
            <Typography color='text.secondary' p={2} variant='body1' {...props}>
              No channels found
            </Typography>
          ),
          pagination: (props) => (
            <GridPagination
              {...props}
              count={totalCount}
              onPageChange={(event, page) => {
                onPageChange(page + 1);
              }}
              page={currentPage - 1}
              rowsPerPage={30}
              rowsPerPageOptions={[30]}
              showFirstButton={totalCount > 60}
              showLastButton={totalCount > 60}
              size='small'
              variant='outlined'
            />
          ),
        }}
        style={{ height: 400 }}
      />
    </>
  );
};

SlackCheckList.propTypes = {
  currentPage: PropTypes.number,
  disabled: PropTypes.any,
  loading: PropTypes.any,
  onChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  options: PropTypes.array,
  searchTerm: PropTypes.any,
  totalCount: PropTypes.number,
  value: PropTypes.array,
};

export default SlackCheckList;
