import React, { useMemo } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { Hash, LockSimple } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import GuestStatusChip from 'components/shared/GuestStatusChip';

const iconProps = { className: 'slack-channel-type' };

const SlackChannelLabel = ({
  chipColors,
  isBold,
  isChip,
  isPrivate,
  name,
  status,
}) => {
  const Icon = useMemo(
    () =>
      isPrivate ? (
        <LockSimple
          {...iconProps}
          color={chipColors ? chipColors.textColor : null}
        />
      ) : (
        <Hash {...iconProps} color={chipColors ? chipColors.textColor : null} />
      ),
    [chipColors, isPrivate]
  );

  return isChip ? (
    <Chip
      icon={Icon}
      label={name}
      sx={
        chipColors
          ? {
              backgroundColor: chipColors,
              color: chipColors.textColor,
            }
          : {}
      }
    />
  ) : (
    <Stack alignItems='center' direction='row' gap={1.5}>
      {Icon}
      <Typography color='currentcolor' fontWeight={isBold ? 600 : 400}>
        {name}
      </Typography>
      <GuestStatusChip status={status} />
    </Stack>
  );
};

SlackChannelLabel.propTypes = {
  chipColors: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
  }),
  isBold: PropTypes.bool,
  isChip: PropTypes.bool,
  isPrivate: PropTypes.bool,
  name: PropTypes.string,
  status: PropTypes.string,
};

export default SlackChannelLabel;
