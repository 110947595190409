import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from '@mui/material';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { InlineLoader } from 'components/Core/Loader';
import { axiosAuthenticated as axios } from 'utils/axios';

const PasswordModal = ({ setShow, show }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isWorking, setIsWorking] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [currPass, setCurrentPassword] = useState('');
  const [hasSubmitted, setSubmitted] = useState(false);

  const validConfirmation = useMemo(
    () => _.trim(confirmation).length > 0 && confirmation === password,
    [confirmation, password]
  );
  const validPassword = useMemo(() => _.trim(password).length > 0, [password]);
  const validCurrent = useMemo(() => _.trim(currPass).length > 0, [currPass]);

  const onHide = () => {
    clearAll();
    setShow(false);
  };

  const onUpdate = () => {
    setSubmitted(true);
    if (password !== confirmation) {
      enqueueSnackbar(
        'The new password does not match, check you typed it the same.',
        { variant: 'error' }
      );
    } else if (!validCurrent) {
      enqueueSnackbar(
        'You must enter your current password in order to change it.',
        { variant: 'error' }
      );
    } else if (!validPassword) {
      enqueueSnackbar('You must enter a new password.', {
        variant: 'error',
      });
    } else if (_.trim(confirmation).length <= 0) {
      enqueueSnackbar('You must enter your new password twice to confirm.', {
        variant: 'error',
      });
    } else {
      setIsWorking(true);

      const fieldMap = {
        current_password: 'Current password',
        password: 'Password',
      };

      axios(
        {
          data: {
            current_password: currPass,
            password,
            password_confirmation: confirmation,
          },
          method: 'post',
          url: '/api/users/update_pass',
        },
        () => {
          setIsWorking(false);
          enqueueSnackbar('Your password has been updated.', {
            variant: 'success',
          });
          onHide();
        },
        (err) => {
          const errors = err.response.data.errors;
          let message = '';
          _.forEach(errors, (errs, field) => {
            if (field === 'password') {
              setConfirmation('');
              setPassword('');
            } else if (field === 'current_password') {
              setCurrentPassword('');
            }
            message += `${fieldMap[field]} ${errs[0]}`;
          });
          setIsWorking(false);
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      );
    }
  };

  const clearAll = () => {
    setPassword('');
    setConfirmation('');
    setCurrentPassword('');
    setSubmitted(false);
  };

  if (!show) {
    return null;
  }

  return (
    <Dialog aria-label='Password' onClose={onHide} open={show}>
      <DialogTitle>Change your Password</DialogTitle>
      {isWorking && (
        <div>
          <InlineLoader />
        </div>
      )}
      <DialogContent>
        <FormGroup>
          <TextField
            autoComplete='current-password'
            error={hasSubmitted && !validCurrent}
            fullWidth
            id='current-password'
            label='Current Password'
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder='Enter your current password'
            type='password'
            value={currPass}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            autoComplete='new-password'
            error={hasSubmitted && !validPassword}
            fullWidth
            id='new-password'
            label='New Password'
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Enter your new password'
            type='password'
            value={password}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            autoComplete='new-password'
            error={hasSubmitted && !validConfirmation}
            fullWidth
            id='confirm-new-password'
            label='Confirm New Password'
            onChange={(e) => setConfirmation(e.target.value)}
            placeholder='Confirm your new password'
            type='password'
            value={confirmation}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          data-testid='cancel'
          onClick={onHide}
          variant='outlined'
        >
          Cancel
        </Button>
        <Button
          color='primary'
          data-testid='update-password'
          disabled={
            !(validCurrent && validPassword && validConfirmation) &&
            hasSubmitted
          }
          onClick={onUpdate}
          variant='contained'
        >
          Update password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PasswordModal.propTypes = {
  setShow: PropTypes.func,
  show: PropTypes.any,
};
export default PasswordModal;
