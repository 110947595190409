import React, { forwardRef } from 'react';
import { Grow, Paper, PaperProps } from '@mui/material';

const PaperComponent = (paperProps: PaperProps, ref: any) => (
  <Grow
    in
    style={{ transformOrigin: 'top center', transitionDuration: '300ms' }}
  >
    <Paper {...paperProps} elevation={8} ref={ref} />
  </Grow>
);

export const AutocompletePaperComponent = forwardRef(PaperComponent);
