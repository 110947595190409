import React, { useEffect, useMemo, useState } from 'react';
import { capitalize, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import {
  GridFooterContainer,
  gridPageCountSelector,
  GridPagination,
  gridPaginationModelSelector,
  selectedGridRowsCountSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid';

const defaultPageSizeOptions = [10, 25, 50, 100];

interface Props {
  noun: string;
  withoutFirstLastButtons: boolean;
  withoutPageSizeSelect: boolean;
}

export const FtnDataGridFooter = ({
  noun,
  withoutFirstLastButtons,
  withoutPageSizeSelect,
}: Props) => {
  const apiRef = useGridApiContext();

  const selectedRowCount = useGridSelector(
    apiRef,
    selectedGridRowsCountSelector
  );
  const totalPagesNeeded = useGridSelector(apiRef, gridPageCountSelector);
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);

  const { checkboxSelection } = useGridRootProps();

  const [autoPageSize, setAutoPageSize] = useState<boolean | number>(false);
  const [pageSizeOptions, setPageSizeOptions] = useState(
    defaultPageSizeOptions
  );

  const currentPageSize = useMemo(
    () => paginationModel?.pageSize,
    [paginationModel?.pageSize]
  );

  useEffect(() => {
    const dimensions = apiRef?.current?.getRootDimensions();
    if (dimensions && !withoutPageSizeSelect) {
      const rowsPerPage = Math.floor(
        dimensions?.viewportInnerSize?.height / 52
      );
      setAutoPageSize(rowsPerPage);
      if (!pageSizeOptions?.includes(rowsPerPage)) {
        const pages = new Set([rowsPerPage, ...defaultPageSizeOptions]);
        setPageSizeOptions(Array.from(pages));
      }
      if (!pageSizeOptions?.includes(currentPageSize)) {
        apiRef.current.setPaginationModel({ page: 0, pageSize: rowsPerPage });
      }
    } else {
      if (pageSizeOptions?.length === 0) {
        setPageSizeOptions(defaultPageSizeOptions);
      }
    }
  }, [
    apiRef,
    autoPageSize,
    currentPageSize,
    pageSizeOptions,
    withoutPageSizeSelect,
  ]);

  const isSinglePage = useMemo(
    () => paginationModel.page === 0 && totalPagesNeeded <= 1,
    [paginationModel.page, totalPagesNeeded]
  );

  const hasSelections = useMemo(
    () => checkboxSelection && selectedRowCount > 0,
    [checkboxSelection, selectedRowCount]
  );

  const hideArrowsWhenSinglePage = useMemo(
    () => (isSinglePage ? { sx: { display: 'none' } } : {}),
    [isSinglePage]
  );

  return (
    <GridFooterContainer sx={{ bgcolor: 'grey.50' }}>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        width='100%'
      >
        {hasSelections ? (
          <Typography pl={2} variant='body1' whiteSpace='nowrap'>
            {`${selectedRowCount} selected`}
          </Typography>
        ) : null}
        <Box flexGrow={1}>&nbsp;</Box>
        <GridPagination
          backIconButtonProps={hideArrowsWhenSinglePage}
          hidden={totalPagesNeeded === 0}
          labelRowsPerPage={
            withoutPageSizeSelect
              ? ''
              : `${noun?.length > 0 ? capitalize(noun) : ''}s per page`
          }
          nextIconButtonProps={hideArrowsWhenSinglePage}
          rowsPerPageOptions={pageSizeOptions}
          SelectProps={{
            defaultValue: autoPageSize || defaultPageSizeOptions[0],
            size: 'small',
            variant: 'outlined',
          }}
          showFirstButton={!isSinglePage && !withoutFirstLastButtons}
          showLastButton={!isSinglePage && !withoutFirstLastButtons}
          sx={
            withoutPageSizeSelect
              ? { '.MuiTablePagination-input': { display: 'none' } }
              : {}
          }
        />
      </Stack>
    </GridFooterContainer>
  );
};
