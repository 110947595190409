import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/Core/Dropdown';
import { useEditSettings } from 'contexts/SettingsContext';

const GroupTypeSuggestions = [
  'Employee Resource Group (ERG)',
  'Social',
  'Professional Development',
];

const GroupTypeSettings = ({ organization, setField, newListItem }) => {
  const { state: editSettings } = useEditSettings();

  useEffect(() => {
    setField(
      'group_types_ids',
      organization.group_types.map((o) => o.id),
      true
    );

    setField(
      'group_types_attributes',
      organization.group_types.map((o) => ({
        id: o.id,
        name: o.name,
        uses: o.uses,
      }))
    );
  }, [organization, setField]);

  const opts = editSettings.group_types_attributes
    ? editSettings.group_types_attributes
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((o) => ({ value: o.id, label: o.name, uses: o.uses }))
    : [];

  const selOpts = opts?.filter((o) =>
    editSettings.group_types_ids?.includes(o.value)
  );

  return (
    <Dropdown
      allowCreate
      allowSuggestion
      attributesField='group_types_attributes'
      className='multi'
      components={{ DropdownIndicator: null }}
      dataProp='name'
      field='group_types_ids'
      helperText='How do you categorize your groups?'
      isMulti
      label='Group types'
      newer={newListItem}
      options={opts}
      placeholder='Select from our suggestions or create your own!'
      selectedOption={selOpts}
      setter={setField}
      suggestions={GroupTypeSuggestions}
    />
  );
};

GroupTypeSettings.propTypes = {
  organization: PropTypes.shape({
    group_types: PropTypes.array,
  }),
};

export default GroupTypeSettings;
