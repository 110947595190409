import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { reactQueryAxios } from 'utils/axios';

interface GroupBudget {
  budget: number;
  currency_id: number;
  id: number;
  name: string;
}

interface Response {
  data: GroupBudget[];
}

interface CustomError {
  data: {
    message: string;
  };
  message: string;
  status: number;
}

interface UpdateGroupsBudgetsParams {
  budget?: number;
  conversion_rate?: number;
  currency_id?: string;
  group_id: number;
}

export const useGroupsBudgets = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const url = `/api/organizations`;

  const queryKey = useMemo(() => ['groups', 'budgets'], []);

  const getGroupsBudgets = async ({ apiClient }: { apiClient: any }) =>
    apiClient({
      method: 'get',
      url: `${url}/group_budgets`,
    });

  const retrieve = useQuery<GroupBudget[], Error>({
    queryFn: () =>
      getGroupsBudgets({ apiClient: reactQueryAxios }).then(
        (response: Response) => response.data
      ),
    queryKey,
  });

  const rowsWithIndex = useMemo(
    () =>
      retrieve.data?.map((row, index) => ({
        ...row,
        index,
      })) || [],
    [retrieve.data]
  );

  const updateGroupsBudgets = async ({
    apiClient,
    groupsBudgets,
  }: {
    apiClient: any;
    groupsBudgets: UpdateGroupsBudgetsParams[];
  }) =>
    apiClient({
      data: { groups_budgets: groupsBudgets },
      method: 'put',
      url: `/api/organizations/update_group_budgets`,
    });

  const update = useMutation({
    mutationFn: (groupsBudgets: UpdateGroupsBudgetsParams[]) =>
      updateGroupsBudgets({
        apiClient: reactQueryAxios,
        groupsBudgets,
      }),
    onError: (error) => {
      const message = 'Failed to update groups budgets';
      console.error(`${message}: `, error);
      enqueueSnackbar(message, { variant: 'error' });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['groups', 'budgets'],
      });
      queryClient.setQueryData(
        ['groups_budgets'],
        response.data.groups_budgets
      );
      enqueueSnackbar('Currencies updated successfully!', {
        variant: 'success',
      });
    },
  });

  return { retrieve, rowsWithIndex, update };
};
