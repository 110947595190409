import { useQuery } from '@tanstack/react-query';

import { reactQueryAxios } from 'utils/axios';

type GetEventDetailsParams = {
  eventId: string | number;
};

const getEventDetails = async ({
  apiClient,
  eventId,
}: GetEventDetailsParams & {
  apiClient: <T = any, E = any>({
    data,
    method,
    timeout,
    url,
  }: {
    data?: any;
    method: string;
    timeout?: number;
    url: string;
  }) => Promise<{
    data: T | null;
    error: E | null;
  }>;
}) =>
  apiClient({
    method: 'get',
    timeout: 300000,
    url: `/api/events/${eventId}`,
  });

export const useGetEventDetails = ({ eventId }: GetEventDetailsParams) => {
  if (typeof eventId === 'string') {
    eventId = parseInt(eventId);
  }

  return useQuery({
    enabled: Boolean(String(eventId)?.length > 0),
    queryFn: () =>
      getEventDetails({ apiClient: reactQueryAxios, eventId }).then(
        (response) => response.data
      ),
    queryKey: ['event', eventId],
  });
};
