import React, { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { omit } from '@okta/okta-auth-js';
import { useSnackbar } from 'notistack';

import EditSaveButton from 'components/shared/EditSaveButton';
import { useCurrencies } from 'hooks/useCurrencies';
import Currency from 'types/Currency';
import Organization from 'types/Organization';

const CurrencySettings = ({
  organization,
}: {
  organization: Organization | undefined;
}) => {
  const {
    retrieve: { data: currencies },
    update,
  } = useCurrencies();

  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);

  const form = useForm<{
    currencies: Currency[];
  }>();

  return (
    <Card>
      <form>
        <CardHeader
          action={
            <EditSaveButton
              handleCancel={() => setIsEditing(false)}
              handleEdit={() => {
                form.setValue('currencies', organization?.currencies || []);
                setIsEditing(true);
              }}
              handleSave={form.handleSubmit(({ currencies }: FieldValues) => {
                try {
                  update.mutate(currencies);
                  setIsEditing(false);
                } catch (error) {
                  enqueueSnackbar('Failed to update currencies', {
                    variant: 'error',
                  });
                }
              })}
              isEditing={isEditing}
              size='small'
            />
          }
          title='Currencies'
        />
        <CardContent>
          {isEditing ? (
            <Controller
              control={form.control}
              name='currencies'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableCloseOnSelect
                  getOptionDisabled={(option: Currency) =>
                    option?.code === 'USD'
                  }
                  getOptionLabel={(option: Currency) =>
                    `${option?.code} ${option?.symbol}`
                  }
                  isOptionEqualToValue={(option: Currency, value: Currency) =>
                    option?.id === value?.id
                  }
                  multiple
                  onChange={(
                    event: any,
                    newValue: (Currency | undefined)[],
                    reason: AutocompleteChangeReason,
                    details?: AutocompleteChangeDetails<Currency | undefined>
                  ) => {
                    if (
                      reason === 'removeOption' &&
                      details?.option?.code === 'USD'
                    ) {
                      onChange([...newValue, details?.option]);
                    } else {
                      onChange(newValue);
                    }
                  }}
                  options={currencies || []}
                  renderInput={(params: any) => (
                    <TextField {...params} margin='none' variant='outlined' />
                  )}
                  renderOption={(props, option, state) => (
                    <MenuItem
                      {...props}
                      component='li'
                      sx={{
                        '&.MuiAutocomplete-option[aria-disabled="true"]': {
                          opacity: 1,
                        },
                        '&.MuiAutocomplete-option[aria-disabled="true"][aria-selected="true"]':
                          {
                            '& svg': {
                              fill: 'inherit',
                              fillOpacity: 0.35,
                            },
                          },
                      }}
                    >
                      <Checkbox checked={state.selected} />
                      {`${option?.code} ${option?.symbol}`}
                    </MenuItem>
                  )}
                  renderTags={(tags, getTagProps) =>
                    tags.map((item, index) => {
                      const tagProps = getTagProps({ index });
                      const nonDeleteProps = omit(tagProps, ['onDelete']);
                      const isUSD = item?.code === 'USD';
                      return (
                        <Chip
                          {...(isUSD ? nonDeleteProps : tagProps)}
                          component='div'
                          key={item?.id}
                          label={`${item?.code} ${item?.symbol}`}
                          size='small'
                        />
                      );
                    })
                  }
                  value={value}
                />
              )}
            />
          ) : (
            <Stack direction='row' flexWrap='wrap' gap={1}>
              {organization?.currencies?.map((currency) => (
                <Chip
                  key={currency.id}
                  label={`${currency.code} ${currency.symbol}`}
                  size='small'
                />
              ))}
            </Stack>
          )}
        </CardContent>
      </form>
    </Card>
  );
};

export default CurrencySettings;
