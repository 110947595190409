import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import * as _ from 'lodash';

const MinimumLoadingTimeMS = 100;

interface LoaderProps {
  inline?: boolean;
  noTimeout?: boolean;
  text?: string;
}

const Loader = ({ noTimeout = false, text = '' }: LoaderProps) => {
  const [timerElapsed, setTimerElapsed] = useState(noTimeout);

  useEffect(() => {
    if (!noTimeout) {
      const t = setTimeout(() => setTimerElapsed(true), MinimumLoadingTimeMS);
      return () => clearTimeout(t);
    }
    return _.noop();
  }, [noTimeout]);

  if (!timerElapsed) {
    return null;
  }
  return (
    <>
      <CircularProgress color='primary' />
      {text && (
        <Typography data-testid='loader-text' variant='body1'>
          {text}
        </Typography>
      )}
    </>
  );
};

export default Loader;
