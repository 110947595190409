import React, { useContext, useMemo } from 'react';
import { Divider } from '@mui/material';

import { SurveySummaryQuestion } from 'components/Events/Controls/Analytics/SurveySummaryQuestion';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';

const SurveySummaryTab = () => {
  const {
    state: { savedEvent, surveyType },
  } = useContext(ManagerContext);

  const displayResponders = useMemo(
    () => savedEvent?.[surveyType?.value]?.display_responders,
    [savedEvent, surveyType]
  );

  const survey = useMemo(
    () => savedEvent?.[surveyType?.value]?.analytics_data,
    [savedEvent, surveyType?.value]
  );

  return survey && survey?.questions?.length > 0 ? (
    survey?.questions
      ?.sort((a, b) => a.position - b.position)
      ?.map((q, index) => (
        <React.Fragment key={q.questionId}>
          {index === 0 ? null : <Divider />}
          <SurveySummaryQuestion
            displayResponders={displayResponders}
            event={savedEvent}
            individualResponses={
              (survey?.individual_responses &&
                survey?.individual_responses[q.questionId]) ||
              []
            }
            isPreEvent={surveyType === 'pre_event_survey'}
            options={(survey?.options && survey?.options[q.questionId]) || []}
            position={index + 1}
            question={q}
          />
        </React.Fragment>
      ))
  ) : (
    <>&nbsp;</>
  );
};

export default SurveySummaryTab;
