import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  styled,
  Typography,
} from '@mui/material';

import InfoTooltip from 'components/shared/InfoTooltip';
import Currency from 'types/Currency';
import { flexRow } from 'utils/styles';

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: `${theme.spacing(1.25)} !important`,
  paddingTop: 0,
}));

interface IEventBudgetOverviewMetricProps {
  currency?: Currency;
  EditComponent?: any;
  title: string;
  tooltip: string;
  value?: number;
}

const EventBudgetOverviewMetric = ({
  currency,
  EditComponent,
  title,
  tooltip,
  value,
}: IEventBudgetOverviewMetricProps) => (
  <Card>
    <CardHeader
      sx={{ pb: 0.25 }}
      title={<InfoTooltip content={tooltip} text={title} />}
      titleTypographyProps={{ component: 'h4', variant: 'body2' }}
    />
    <StyledCardContent
      sx={{
        ...flexRow,
        alignItems: 'center',
        height: 50,
        justifyContent: EditComponent
          ? 'space-between'
          : value
          ? 'flex-start'
          : 'stretch',
      }}
    >
      {value ? (
        <>
          <Typography variant='h3'>
            {currency?.symbol || ''}
            {Number(value).toLocaleString()}
          </Typography>
          {EditComponent && <EditComponent currency={currency} />}
        </>
      ) : EditComponent ? (
        <EditComponent />
      ) : (
        <Box
          sx={{
            bgcolor: 'grey.100',
            borderRadius: 0.5,
            color: 'grey.700',
            fontSize: '12px',
            height: 24,
            lineHeight: '24px',
            textAlign: 'center',
            width: '100%',
          }}
        >
          No data
        </Box>
      )}
    </StyledCardContent>
  </Card>
);

export default EventBudgetOverviewMetric;
