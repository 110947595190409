import React from 'react';
import { Controller } from 'react-hook-form';
import { MicrophoneStage } from '@phosphor-icons/react';
import { groupBy } from 'lodash';

import AutocompleteWithAvatars from 'components/shared/AutocompleteWithAvatars';
import SummaryField from 'components/shared/SummaryField';
import useDisplayedHostSelections from 'hooks/useDisplayedHostSelections';
import {
  EventDetailsFormControl,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';

interface EventHostedByFieldProps {
  control: EventDetailsFormControl;
  disableFields: boolean;
  isLoading?: boolean;
  savedHostIds?: number[];
  watch: EventDetailsFormWatch;
}

const EventHostedByField = ({
  control,
  disableFields,
  isLoading = false,
  savedHostIds,
  watch,
}: EventHostedByFieldProps) => {
  const hosts = watch('hosts');
  const { allDisplayedHostOptions, allDisplayedHostSelections } =
    useDisplayedHostSelections(hosts?.hosts);

  const onChange = (_: any, selections: any, field: any) => {
    const groupedByType = groupBy(selections, 'type');

    const selectedOptions = Object.keys(groupedByType).map((key) => ({
      ids: groupedByType[key].map((selection) => selection.value),
      type: key,
    }));

    const groupHosts = (
      selectedOptions.find((options) => options.type === 'Groups') || {
        ids: [],
      }
    ).ids;

    const organizerHosts = (
      selectedOptions.find((options) => options.type === 'Organizers') || {
        ids: [],
      }
    ).ids;

    const officeHosts = (
      selectedOptions.find((options) => options.type === 'Offices') || {
        ids: [],
      }
    ).ids;

    const allHostsAreOrganizers = savedHostIds?.every((host: number) =>
      organizerHosts.map((h: any) => h.id).includes(host)
    );

    field.onChange({
      displayOrganizersAsHosts: allHostsAreOrganizers ? true : undefined,
      groupIds: groupHosts,
      hosts: selectedOptions,
      officeIds: officeHosts,
    });
  };

  return (
    <Controller
      control={control}
      name='hosts'
      render={({ field }) => (
          <SummaryField
            component={
              <AutocompleteWithAvatars
                disabled={disableFields}
                hasGroups
                isOptionEqualToValue={(opt: any, value: any) =>
                  opt?.value === value?.value && opt?.type === value?.type
                }
                onChange={(ev, value) => onChange(ev, value, field)}
                options={allDisplayedHostOptions.map((opt: any) => ({
                  ...opt,
                  id: opt.value,
                }))}
                value={allDisplayedHostSelections}
              />
            }
            Icon={MicrophoneStage}
            isLoading={isLoading}
            primary='Displayed host'
            secondary='Select the host that will be displayed on your event page and on event communications'
            value={allDisplayedHostSelections}
            valueType='avatars'
          />
        )}
    />
  );
};

export default EventHostedByField;
