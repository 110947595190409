import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { isInteger } from 'utils/utils';

const useRedirectToItem = (singularItemName: string) => {
  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();

  return useEffect(() => {
    if (tab && (isInteger(tab) || isInteger(+tab))) {
      navigate(`/${singularItemName}/${tab}`);
    }
  }, [navigate, singularItemName, tab]);
};

export default useRedirectToItem;
