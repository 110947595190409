import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { axiosAuthenticated, reactQueryAxios } from 'utils/axios';

const useUserInvitations = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const url = '/api/invitation_mappings';

  const queryKey = ['invitations'];

  const getInvitations = async ({ apiClient }: { apiClient: any }) =>
    apiClient({
      method: 'get',
      url,
    });

  const retrieve = useQuery({
    queryFn: () =>
      getInvitations({ apiClient: reactQueryAxios }).then(
        (response: AxiosResponse) => response.data
      ),
    queryKey,
  });

  const create = (user: any, org: any, isResending?: boolean) => {
    axiosAuthenticated(
      {
        data: {
          invitation_mapping: {
            invited_user_email: user.email,
            invited_user_name: user.name,
            organization_id: org.id,
            role_proposed: user.role?.value || user.role,
            sign_up_URL: `${window.location.origin}/sign-up`,
          },
        },
        method: 'POST',
        url: '/api/invitation_mappings',
      },
      () => {
        queryClient.invalidateQueries({ queryKey });
        enqueueSnackbar(
          isResending
            ? `Another invite has been emailed to ${user.name}!`
            : `${user.name} invited via email!`,
          { variant: 'success' }
        );
      },
      (err: AxiosError) => {
        if (err?.response?.status === 400) {
          enqueueSnackbar(
            `Your organization already has a user with ${user.email} as their email address`,
            {
              variant: 'error',
            }
          );
        } else {
          enqueueSnackbar('Something went wrong, please try again.', {
            variant: 'error',
          });
        }
        console.log(err);
      }
    );
  };

  return { create, retrieve };
};

export default useUserInvitations;
