/* eslint-disable react/no-multi-comp */
import React, { useCallback, useContext, useMemo } from 'react';
import {
  alpha,
  Avatar,
  Box,
  capitalize,
  Chip,
  CircularProgress,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { UserCircleGear } from '@phosphor-icons/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
  useMaterialReactTable,
} from 'material-react-table';

import {
  communicationMethodDetails,
  communicationStatusDetails,
} from 'components/Events/Controls/Communications/communication.constants';
import {
  CommunicationMethodId,
  CommunicationStatus,
  CommunicationStatusId,
} from 'components/Events/Controls/Communications/communication.types';
import CommunicationsRowActions from 'components/Events/Controls/Communications/CommunicationsRowActions';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import EmptyState from 'components/shared/EmptyState';
import { getDefaultMRTOptions } from 'constants/table.constants';
import { CommunicationHistory } from 'gql/graphql';
import { useGraphQLClient } from 'hooks/useGraphQLClient';
import { isOver } from 'utils/event';
import { toInitials } from 'utils/text';
import { CloudinaryUrl } from 'utils/utils';

const communicationHistoriesQueryDocument = gql`
  query communicationHistoriesQueryDocument(
    $eventId: ID!
    $needsRescheduling: Boolean
  ) {
    communicationHistories(
      eventId: $eventId
      needsRescheduling: $needsRescheduling
    ) {
      id
      commId
      eventInvitationIds
      historyType
      deliveryMethods
      sendBy {
        avatarKey
        fullName
      }
      sendTime
      status
    }
  }
`;

// INFO: commId is eventMessageId, primaryInvitationId, or surveyId

const CommunicationsTable = () => {
  const {
    state: { event },
  } = useContext(ManagerContext);

  const graphQLClient = useGraphQLClient();
  const queryClient = useQueryClient();

  const theme = useTheme();

  const pendingRows = useCallback(
    (data?: { communicationHistories: CommunicationHistory[] }) =>
      data?.communicationHistories
        ? data?.communicationHistories?.filter(
            (comm: any) =>
              (comm?.historyType === 'invitation' &&
                !isOver(event) &&
                comm?.method !== 'slack' &&
                comm?.status === 'sending') ||
              (comm?.historyType === 'message' &&
                (comm?.status === 'drafted' || comm?.status === 'sending'))
          )
        : [],
    [event]
  );

  const { data, isLoading }: { data: any; isLoading: boolean } = useQuery({
    queryFn: async () =>
      await graphQLClient?.request(communicationHistoriesQueryDocument, {
        eventId: String(event?.id),
        needsRescheduling: null,
      }),
    queryKey: ['event', event?.id, 'communications'],
    refetchInterval: (query) =>
      query.state.data &&
      pendingRows(
        query.state.data as { communicationHistories: CommunicationHistory[] }
      )?.length > 0
        ? 2000
        : false,
  });

  const mrtColumns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'status',
        Cell: (params) => {
          const status: CommunicationStatusId = params?.row?.original?.status;
          const statusDetails: CommunicationStatus =
            communicationStatusDetails[status];
          const StatusIcon = statusDetails?.Icon;
          const StatusChip = (
            <Chip
              {...(StatusIcon ? { icon: <StatusIcon size={18} /> } : {})}
              color={statusDetails?.color}
              label={capitalize(
                statusDetails?.label || statusDetails?.id || ''
              )}
              size='small'
              sx={{ height: 32 }}
              variant='light'
            />
          );

          return statusDetails?.id === 'reschedule' ? (
            <Tooltip
              placement='top'
              title='Edit this invitation so that the scheduled send time is prior to your event, otherwise it will be deleted once your event starts.'
            >
              {StatusChip}
            </Tooltip>
          ) : (
            <>{StatusChip}</>
          );
        },
        header: 'Status',
      },
      {
        accessorFn: (originalRow) =>
          originalRow.type === 'survey'
            ? 'Post-event survey'
            : capitalize(originalRow.historyType || ''),
        accessorKey: 'historyType',
        header: 'Type',
      },
      {
        accessorFn: (originalRow) =>
          originalRow.deliveryMethods?.length > 0
            ? originalRow.deliveryMethods[0]
            : '',
        accessorKey: 'deliveryMethods',
        Cell: (params) => {
          const deliveryMethods = params.row.original.deliveryMethods;
          if (deliveryMethods?.length > 0) {
            return (
              <Stack direction='row' gap={1.5}>
                {deliveryMethods.map((method: CommunicationMethodId) => {
                  const methodDetails = communicationMethodDetails[method];
                  const MethodIcon = methodDetails?.Icon;
                  const methodColor: any =
                    theme.palette[
                      methodDetails?.color as keyof typeof theme.palette
                    ];
                  return (
                    <Avatar
                      key={method}
                      sx={{
                        bgcolor: alpha(methodColor?.light, 0.5),
                        color: methodColor?.dark,
                        height: 28,
                        width: 28,
                      }}
                      variant='rounded'
                    >
                      <MethodIcon size={20} />
                    </Avatar>
                  );
                })}
              </Stack>
            );
          } else {
            return '';
          }
        },
        header: 'Delivery method',
        sortable: false,
      },
      {
        accessorFn: (originalRow) => originalRow?.sendBy?.fullName || '',
        accessorKey: 'sendBy',
        Cell: ({ row }: { row: MRT_Row<any> }) => {
          const name = row?.original?.sendBy?.fullName;
          const key = row?.original?.sendBy?.avatarKey;
          const avatarUrl =
            key?.length > 0 ? CloudinaryUrl(key, 30, 30) : undefined;
          return (
            <Stack direction='row' gap={1.5}>
              <Avatar
                src={avatarUrl}
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  fontSize: 12,
                  height: 24,
                  width: 24,
                }}
              >
                {toInitials(name)}
              </Avatar>
              <Typography variant='inherit' whiteSpace='nowrap'>
                {name}
              </Typography>
            </Stack>
          );
        },
        header: 'From',
      },
      {
        accessorFn: (originalRow) =>
          originalRow.sendTime ? new Date(originalRow.sendTime) : new Date(),
        accessorKey: 'sendTime',
        Cell: (params) => {
          if (params.row.original.status === 'sending') {
            return <Skeleton sx={{ fontSize: 12, minWidth: 200 }} />;
          } else {
            return (
              <div>
                {params.renderedCellValue &&
                  dayjs(String(params.renderedCellValue)).format(
                    'ddd, MMM D, h:mma z'
                  )}
              </div>
            );
          }
        },
        header: 'Send time',
        type: 'dateTime',
      },
      {
        Cell: (params) => (
          <CommunicationsRowActions
            handleChange={() =>
              queryClient.invalidateQueries({
                queryKey: ['event', event?.id, 'communications'],
              })
            }
            params={params}
          />
        ),
        header: 'Actions',
        size: 90,
        sortable: false,
      },
    ],
    [event?.id, queryClient, theme]
  );
  const defaultMrtOptions = getDefaultMRTOptions<any>(400, true);

  const table = useMaterialReactTable({
    ...defaultMrtOptions,
    columns: mrtColumns,
    data: data?.communicationHistories || [],
    enableBottomToolbar: data?.communicationHistories?.length > 0,
    enablePagination: data?.communicationHistories?.length > 10,
    enableRowSelection: false,
    enableTableHead: data?.communicationHistories?.length > 0,
    enableTopToolbar: false,
    initialState: {
      sorting: [
        {
          desc: true,
          id: 'sendTime',
        },
      ],
    },
    muiTableBodyRowProps: {
      sx: {
        '&:hover > td::after': { bgcolor: 'white' },
        bgcolor: 'white',
        height: 60,
      },
    },
    renderEmptyRowsFallback: () => (
      <Box
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        pt={4}
        width='100%'
      >
        {isLoading ? (
          <CircularProgress variant='indeterminate' />
        ) : (
          <EmptyState
            Icon={UserCircleGear}
            primary='No communications have been added yet.'
            secondary='Click "Invite guests" to get started!'
          />
        )}
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default CommunicationsTable;
