import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { ArrowLeft, CalendarBlank } from '@phosphor-icons/react';

import Head from 'components/Core/Head';
import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <ContentWidthContainer data-testid='lost-frame'>
      <Head title='Page Not Found' />
      <Stack
        alignItems='center'
        data-testid='msg-wrapper'
        direction='column'
        gap={3}
        sx={{ textAlign: 'center' }}
      >
        <img
          alt='404 page not found'
          src='/images/404.svg'
          style={{ maxWidth: 500 }}
        />
        <Typography variant='h2'>
          The page you are looking for was moved or doesn&apos;t exist.
        </Typography>
        <Typography variant='h3'>Let&apos;s get you back.</Typography>
        <Stack direction='row' gap={2}>
          <Button
            data-testid='go-back'
            onClick={() => navigate(-1)()}
            startIcon={<ArrowLeft />}
            variant='contained'
          >
            Go back
          </Button>
          <Button
            data-testid='go-to-events'
            onClick={() => navigate('/events')}
            startIcon={<CalendarBlank />}
            variant='outlined'
          >
            Go to events
          </Button>
        </Stack>
      </Stack>
    </ContentWidthContainer>
  );
};
export default NotFound;
