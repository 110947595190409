export const truncateText = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const flex = {
  display: 'flex',
};

export const flexColumn = {
  ...flex,
  flexDirection: 'column',
};

export const flexRow = {
  ...flex,
  flexDirection: 'row',
};

export const inlineBadgeProps = {
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  sx: {
    '& .MuiBadge-badge': {
      position: 'relative',
      transform: 'none',
      transformOrigin: 'none',
    },
  },
};

export const fixedCheckListItem = {
  '&:hover': {
    backgroundColor: 'transparent',
  },
};
