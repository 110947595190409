import React from 'react';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import { useSlackEmployees } from 'hooks/useSlackEmployees';

const SlackEmployeeWaiter = ({ children }) => {
  const { isLoading: isSlackEmployeesLoading } = useSlackEmployees();
  return (
    <ConditionalLoader conditions={[isSlackEmployeesLoading]}>
      {children}
    </ConditionalLoader>
  );
};

SlackEmployeeWaiter.propTypes = {
  children: PropTypes.any,
};
export default SlackEmployeeWaiter;
