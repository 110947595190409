import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from '@mui/material';
import { PropTypes } from 'prop-types';

import SurveyQuestionResponses from 'components/Events/Controls/Analytics/SurveyQuestionResponses';
import { SurveyQuestionTitle } from 'components/Events/Controls/Analytics/SurveyQuestionTitle';

const SurveyQuestionResponsesDialog = ({
  displayResponders,
  handleClose,
  individualResponses = [],
  options = [],
  show,
  titleProps,
  type,
}) => {
  const theme = useTheme();
  const borderStyle = `1px solid ${theme.palette.grey[300]}`;

  return (
    <Dialog
      aria-label='all individual responses'
      fullWidth
      maxWidth='lg'
      onClose={handleClose}
      open={show}
      sx={{ maxHeight: '80vh' }}
    >
      <DialogTitle sx={{ borderBottom: borderStyle, pb: 0 }}>
        <SurveyQuestionTitle {...titleProps} titleVariant='h4' />
      </DialogTitle>
      <DialogContent>
        <Stack direction='column' gap={1.5} mt={2}>
          <SurveyQuestionResponses
            displayResponders={displayResponders}
            individualResponses={individualResponses}
            options={options}
            type={type}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: borderStyle, px: 3, py: 2 }}>
        <Button data-testid='close' onClick={handleClose} variant='outlined'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SurveyQuestionResponsesDialog.propTypes = {
  displayResponders: PropTypes.bool,
  handleClose: PropTypes.func,
  individualResponses: PropTypes.any,
  options: PropTypes.array,
  show: PropTypes.bool,
  titleProps: PropTypes.any,
  type: PropTypes.any,
};

export default SurveyQuestionResponsesDialog;
