import React, { createContext, useCallback, useMemo, useState } from 'react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { FullscreenLoader } from 'components/Core/Loader';
import { SurveysValidators } from 'components/Events/Editor/validators';
import { useDeleteSurvey } from 'components/Events/Hooks/Actions';
import {
  useFormValidation,
  usePendingData,
} from 'components/Events/Hooks/Generic';
import {
  usePendingSurveyTemplate,
  useSaveSurveyTemplate,
} from 'components/Survey/SurveyTemplates/Hooks/TemplateSurveys';
import { useDelayedLoading } from 'utils/utils';

export const SurveyTemplatesContext = createContext();

export const SurveyTemplatesProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [savedSurveyTemplate, setSavedSurveyTemplate] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [surveyErrors, setSurveyErrors] = useState({});
  const [surveyFieldIdsToDisplayErrorsOn, setSurveyFieldIdsToDisplayErrorsOn] =
    useState([]);

  const DefaultSurveyTemplate = useMemo(
    () => ({
      post_event_survey: {
        editing: false,
      },
      pre_event_survey: {
        editing: false,
      },
    }),
    []
  );

  const { applyMulti, applySingle, clearPending, pendingData } = usePendingData(
    {
      surveys: DefaultSurveyTemplate,
    }
  );
  const { endTask, isWorking, loadingMessage, longWorking, startTask } =
    useDelayedLoading();
  const pendingSurveyTemplate = usePendingSurveyTemplate(
    savedSurveyTemplate,
    pendingData
  );

  const type = useMemo(
    () =>
      pendingData?.style === 'pre' ? 'pre_event_survey' : 'post_event_survey',
    [pendingData?.style]
  );

  const _afterSaveFail = useCallback(() => {
    enqueueSnackbar('Please resolve errors before saving', {
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  const _afterSaveSuccess = useCallback(
    (res) => {
      const template = res.data;
      setSavedSurveyTemplate(template);
      applySingle(template.style, `style`);
      enqueueSnackbar('Survey template saved!', {
        variant: 'success',
      });
      clearPending(false, true, false);
      setShowErrors(false);
    },
    [clearPending, enqueueSnackbar, applySingle]
  );

  const _afterDeleteSuccess = useCallback(() => {
    enqueueSnackbar('Survey template deleted!', {
      variant: 'success',
    });
    setShowErrors(false);
    setSavedSurveyTemplate(null);
  }, [enqueueSnackbar]);

  const clearPendingSurvey = useCallback(
    (type) => {
      clearPending(false, true, false, false, type);
      setShowErrors(false);
    },
    [clearPending]
  );

  const saveSurveyTemplate = useSaveSurveyTemplate(
    startTask,
    endTask,
    pendingData,
    savedSurveyTemplate,
    _afterSaveSuccess,
    _afterSaveFail,
    type
  );

  const deleteSurvey = useDeleteSurvey(
    startTask,
    endTask,
    pendingData,
    savedSurveyTemplate,
    _afterDeleteSuccess,
    _afterSaveFail
  );

  const checkUnsavedSurvey = useMemo(
    () => !_.isEqual(pendingData?.surveys, DefaultSurveyTemplate),
    [pendingData?.surveys, DefaultSurveyTemplate]
  );

  const actions = {
    applyMulti,
    applySingle,
    clearPending,
    clearPendingSurvey,
    deleteSurvey,
    endTask,
    saveSurveyTemplate,
    setSavedSurveyTemplate,
    setShowErrors,
    setSurveyErrors,
    setSurveyFieldIdsToDisplayErrorsOn,
    startTask,
  };

  const state = {
    inSurveyTemplatesContext: true,
    isWorking,
    privacy: pendingSurveyTemplate[type]?.privacy,
    savedSurveyTemplate,
    showErrors,
    style: pendingData?.style,
    surveyErrors,
    surveyFieldIdsToDisplayErrorsOn,
    surveys: pendingSurveyTemplate,
    surveyType: type,
    unsavedChangesEditSurveyTemplate: checkUnsavedSurvey,
  };

  const validationDataObj = useMemo(
    () => pendingSurveyTemplate,
    [pendingSurveyTemplate]
  );

  const validation = useFormValidation(SurveysValidators, validationDataObj);

  return (
    <SurveyTemplatesContext.Provider
      value={{
        actions,
        state,
        validation,
      }}
    >
      <FullscreenLoader message={loadingMessage} show={longWorking} />
      {!longWorking && children}
    </SurveyTemplatesContext.Provider>
  );
};

SurveyTemplatesProvider.propTypes = {
  children: PropTypes.any,
};
