import React, { useContext, useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { communicationMethodDetails } from 'components/Events/Controls/Communications/communication.constants';
import {
  MessageFormData,
  MessageMethodId,
} from 'components/Events/Controls/Communications/communication.types';
import CommunicationMessageField from 'components/Events/Controls/Communications/CommunicationMessageField';
import MessageRecipientsField from 'components/Events/Controls/Communications/MessageRecipientsField';
import PreviewEmailMessage from 'components/Events/Controls/Communications/PreviewEmailMessage';
import PreviewSlackMessage from 'components/Events/Controls/Communications/PreviewSlackMessage';
import SlackAttachmentToggles from 'components/Events/Controls/Communications/SlackAttachmentToggles';
import { SyncedSlackChannelContext } from 'contexts/SyncedSlackChannelContext';
import { useEventInvitations } from 'hooks/useEventInvitations';
import { useInvitedSlackChannels } from 'hooks/useInvitedSlackChannels';
import FtnEvent from 'types/FtnEvent';
import { flexColumn } from 'utils/styles';

dayjs.extend(relativeTime);

interface Props {
  form: UseFormReturn<MessageFormData>;
  method?: MessageMethodId;
  savedEvent: FtnEvent;
}

const MessageGuests = ({ form, method = 'slack', savedEvent }: Props) => {
  const [syncedChannels, , , fetchSyncedSlackChannels] = useContext(
    SyncedSlackChannelContext
  );

  const { data } = useEventInvitations({ eventId: String(savedEvent?.id) });

  const messageBody = form?.watch('message_body');
  const showEventDetailsButton = form?.watch('show_event_details_button');
  const showRsvpButtons = form?.watch('show_rsvp_buttons');

  const isSlack = useMemo(() => method === 'slack', [method]);

  const { invitedChannels } = useInvitedSlackChannels(
    data?.eventInvitations,
    syncedChannels
  );

  useEffect(() => {
    if (isSlack && (!syncedChannels || syncedChannels?.length === 0)) {
      fetchSyncedSlackChannels();
    }
  }, [fetchSyncedSlackChannels, isSlack, syncedChannels]);

  return (
    <Grid
      alignItems='stretch'
      container
      flexDirection={{ md: 'row', xs: 'column' }}
      flexWrap='nowrap'
      spacing={3}
    >
      <Grid display='flex' flexDirection='column' gap={2} item md={6} xs={12}>
        <Card sx={{ flex: 1 }}>
          <CardContent
            sx={{
              gap: 4,
              height: '100%',
              overflowY: 'auto',
              ...flexColumn,
            }}
          >
            <Stack direction='column' gap={1}>
              <Typography variant='h4'>Select recipients</Typography>
              <MessageRecipientsField
                allowedChannels={invitedChannels}
                hookForm={form}
                method={method}
              />
            </Stack>
            <Stack direction='column' gap={1}>
              <Typography variant='h4'>Personalize message</Typography>
              <CommunicationMessageField hookForm={form} isSlack={isSlack} />
              {isSlack ? (
                <Stack
                  direction='column'
                  gap={2}
                  justifyContent='space-between'
                  mt={2}
                >
                  <FormControl
                    component='fieldset'
                    margin='none'
                    variant='standard'
                  >
                    <FormLabel component='legend'>Select attachments</FormLabel>
                    <FormGroup>
                      <SlackAttachmentToggles
                        eventEnds={dayjs(savedEvent?.ends)}
                        form={form}
                        showEventDetailsButton
                        showRsvpButtons
                      />
                    </FormGroup>
                  </FormControl>
                </Stack>
              ) : null}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid display='flex' flexDirection='column' gap={2} item md={6} xs={12}>
        <Alert data-testid='message-preview' severity='info'>
          <AlertTitle sx={{ typography: 'body1' }}>
            This is a preview of your{' '}
            {communicationMethodDetails[method]?.label} message.
          </AlertTitle>
        </Alert>
        <Card sx={{ overflowY: 'auto' }}>
          {isSlack ? (
            <PreviewSlackMessage
              customMessage={messageBody}
              customPreviewComponent
              event={savedEvent}
              hasDetailsButton={showEventDetailsButton}
              hasRsvpButton={showRsvpButtons}
              slackAppName='Five to Nine'
            />
          ) : (
            <PreviewEmailMessage
              customMessage={messageBody}
              event={savedEvent}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default MessageGuests;
