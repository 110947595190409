import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';

interface GroupBudgetSpentProps {
  budgetStatus: string | undefined;
  currencySymbol: string;
  total: number | undefined;
  totalSpent: number;
}

const GroupBudgetSpent = ({
  budgetStatus,
  currencySymbol,
  total,
  totalSpent,
}: GroupBudgetSpentProps) => {
  const theme = useTheme();
  const totalColor =
    budgetStatus === 'Over budget'
      ? theme.palette.error.dark
      : theme.palette.grey[900];

  return (
    <Stack alignItems='baseline' direction='row' gap={0.5}>
      <Typography color={totalColor} variant='h3'>
        {[
          currencySymbol,
          totalSpent > (total ?? 0)
            ? totalSpent?.toFixed(0)
            : total?.toFixed(0),
        ].join('')}
      </Typography>
      <Typography variant='body1'>
        {budgetStatus === 'Over budget' ? 'spent' : 'budget'}
      </Typography>
    </Stack>
  );
};

export default GroupBudgetSpent;
