import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';

import CreateSurveyTemplateButton from 'components/Events/Controls/Surveys/CreateSurveyTemplateButton';
import SurveyTemplateCard from 'components/Home/SurveyTemplateCard';
import GridCards from 'components/shared/GridCards';
import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated } from 'utils/axios';

const SurveyTemplateCards = ({
  chunk,
  inProfile,
  searchTerm,
  surveyType,
  tab,
}) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const [filteredValues, setFilteredValues] = useState(chunk?.values);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();
    axiosAuthenticated(
      {
        cancelToken: source.token,
        url: '/api/users',
      },
      (res) => {
        if (isMounted) {
          setUsers(res.data);
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          console.warn('Request cancelled', error.message);
        } else {
          console.error('Error', error.message);
        }
      }
    );
    return () => {
      isMounted = false;
      source.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    if (chunk?.values) {
      setFilteredValues(
        chunk?.values
          .filter((val) =>
            searchTerm?.length > 0
              ? val?.name?.toLowerCase().includes(searchTerm.toLowerCase())
              : true
          )
          .filter((val) =>
            surveyType?.length > 0 ? surveyType.includes(val?.style) : true
          )
      );
    }
  }, [chunk?.values, searchTerm, surveyType]);

  const removeFromChunks = (id) => {
    chunk.removeItemFromChunk(id);
  };

  return (
    <Box aria-labelledby={tab} data-testid='card-deck' role='tabpanel'>
      <GridCards
        CardComponent={(template) => (
          <SurveyTemplateCard
            creator={
              template.creator?.full_name
                ? template.creator
                : users?.find((u) => u.id === template.creator_id)
            }
            data-testid='card-grid-item'
            key={template?.id}
            removeFromChunks={removeFromChunks}
            tab={tab}
            template={template}
            user={user}
          />
        )}
        chunk={{
          hasMore: chunk?.hasMore,
          isLoading: chunk?.isLoading,
          requestMore: chunk?.requestMore,
          values: filteredValues,
        }}
        isNarrow={inProfile}
        NoDataComponent={
          user?.is_admin || user?.is_organizer ? (
            <CreateSurveyTemplateButton showAsLink />
          ) : null
        }
        noun='survey templates'
      />
    </Box>
  );
};

SurveyTemplateCards.propTypes = {
  chunk: PropTypes.shape({
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    removeItemFromChunk: PropTypes.func,
    requestMore: PropTypes.func,
    values: PropTypes.array,
  }),
  inProfile: PropTypes.bool,
  searchTerm: PropTypes.string,
  surveyType: PropTypes.string,
  tab: PropTypes.any,
};

export default SurveyTemplateCards;
