import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, Chip } from '@mui/material';
import { Plus, Warning } from '@phosphor-icons/react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import EditSurveyCard from 'components/Events/Controls/Surveys/EditSurveyCard';
import SelectSurveyTemplate from 'components/Events/Controls/Surveys/SelectSurveyTemplate';
import ViewSurveyCard from 'components/Events/Controls/Surveys/ViewSurveyCard';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { hasStarted, isOver } from 'utils/event';
import {
  AddSurvey,
  oppositeType,
  PostEventSurveyOption,
  PreEventSurveyOption,
} from 'utils/survey';
import { useContextWithFallback } from 'utils/utils';

const SurveyCard = ({ responsesCount, subtitle, text, title, type }) => {
  const {
    actions: {
      applyMulti,
      applySingle,
      clearPendingEvent,
      setShowErrors,
      setSurveyFieldIdsToDisplayErrorsOn,
    },
    state: {
      event,
      hasUnsavedSurveyChanges,
      inManagerContext,
      showErrors,
      surveyErrors,
      surveys,
    },
  } = useContextWithFallback(ManagerContext, SurveyTemplatesContext);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.fromCommunications) {
      applySingle(true, 'surveys.post_event_survey.editing');
      const el = document.querySelector(`#${PostEventSurveyOption.value}`);
      (el?.parentElement ?? el)?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      applySingle(false, 'surveys.post_event_survey.editing');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSurvey = (type) =>
    (surveys?.[type]?.enabled || surveys?.[type]?.id) &&
    !surveys?.[type]?._destroy;

  const isEditing = (type) => surveys[type]?.editing;

  const handleCreateOrEditSurvey = (create = true) => {
    if (isEditing(oppositeType(type)) && surveyErrors?.length > 0) {
      if (!showErrors) {
        setShowErrors(true);
      }
      const ids = surveyErrors
        .filter((e) => !_.isNil(e?.questionId) || !_.isNil(e?.optionId))
        .map((e) => e.optionId || e.questionId);
      setSurveyFieldIdsToDisplayErrorsOn(ids);

      enqueueSnackbar('Please resolve errors before creating a new survey', {
        variant: 'error',
      });
    } else if (
      (isEditing(oppositeType(type)) || surveys[oppositeType(type)].enabled) &&
      hasUnsavedSurveyChanges
    ) {
      enqueueSnackbar(
        `Please save or cancel your changes before ${
          create ? 'creating a new survey' : 'editing the other survey'
        }`,
        {
          variant: 'error',
        }
      );
    } else {
      if (!create) {
        applyMulti([
          [false, `surveys.${oppositeType(type)}.editing`],
          [true, `surveys.${type}.editing`],
        ]);
      } else {
        applyMulti([
          [AddSurvey(type), `surveys.${type}`],
          [false, `surveys.${oppositeType(type)}.editing`],
        ]);
      }
    }
  };

  const headerProps = useMemo(
    () => ({
      subheaderTypographyProps: { color: 'grey.600', variant: 'body1' },
      sx: { '.MuiCardHeader-action': { m: 0 }, px: 3 },
      title,
      titleTypographyProps: { variant: 'h4' },
    }),
    [title]
  );

  return (
    <>
      {hasSurvey(type) ? (
        isEditing(type) ? (
          <EditSurveyCard
            headerProps={headerProps}
            subtitle={subtitle}
            type={type}
          />
        ) : (
          <ViewSurveyCard
            handleEditSurvey={() => handleCreateOrEditSurvey(false)}
            headerProps={headerProps}
            responsesCount={responsesCount}
            type={type}
          />
        )
      ) : type !== PreEventSurveyOption?.value ||
        (!hasStarted(event) && !isOver(event)) ? (
        <Card data-testid={`survey-card-${type}`}>
          <CardHeader
            action={
              <Chip
                color='warning'
                icon={<Warning size={16} />}
                label='No survey'
                variant='light'
              />
            }
            subheader={text}
            {...headerProps}
          />
          <CardContent>
            <Button
              color='primary'
              data-testid='create'
              onClick={() => handleCreateOrEditSurvey()}
              startIcon={<Plus size={20} />}
              sx={{ mr: 2 }}
              variant='outlined'
            >
              Create {String(title).toLowerCase()}
            </Button>
            <SelectSurveyTemplate
              activeTemplateId={_.get(surveys, `${type}.survey_template_id`)}
              buttonVariant='outlined'
              surveyType={type}
            />
          </CardContent>
        </Card>
      ) : null}
      <UnsavedChangesModal
        hasUnsavedChanges={
          inManagerContext === true && hasUnsavedSurveyChanges === true
        }
        onConfirm={clearPendingEvent}
      />
    </>
  );
};

SurveyCard.propTypes = {
  responsesCount: PropTypes.any,
  subtitle: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
  type: PropTypes.any,
};

export default SurveyCard;
