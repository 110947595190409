import React from 'react';
import { Navigate } from 'react-router-dom';

const OktaCludge = ({ children }: { children: any }) => {
  const href = window.location.href;
  const search = '#/auth/okta/saml';
  const splitIndex = href.indexOf(search);
  if (splitIndex !== -1) {
    const query = href.substring(splitIndex + search.length);
    return (
      <Navigate replace to={{ pathname: '/auth/okta/saml', search: query }} />
    );
  }
  return <>{children}</>;
};

export default OktaCludge;
