import React, { useContext, useMemo } from 'react';
import {
  Box,
  Chip,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Link as LinkIcon,
  MapPin,
  Timer,
  User,
  VideoCamera,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as geopattern from 'geopattern';

import RichTextDisplay from 'components/shared/RichTextDisplay';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDisplayedHostSelections from 'hooks/useDisplayedHostSelections';
import { EventDetailsFormWatch } from 'types/EventDetailsForm';
import { flexColumn } from 'utils/styles';
import { toOxfordComma } from 'utils/text';

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface EventPreviewCardProps {
  watch: EventDetailsFormWatch;
}

const EventPreviewCard = ({ watch }: EventPreviewCardProps) => {
  const [organization, , , , , eventTypeColors] =
    useContext(OrganizationContext);

  const { copy } = useCopyToClipboard();
  const theme = useTheme();

  const start = watch('start');
  const ends = watch('ends');
  const meeting = watch('meeting');
  const hostsData = watch('hosts.hosts');
  const name = watch('name');
  const location = watch('location');
  const description = watch('description');
  const resources = watch('resources');
  const eventTypeId = watch('eventTypeId');
  const coverImage = watch('coverImage');

  const { allDisplayedHostSelections: hosts } =
    useDisplayedHostSelections(hostsData);
  const hasImage =
    ((coverImage?.fullDataUrl || '')?.length > 0 ||
      (coverImage?.cropDataUrl || '')?.length > 0) &&
    !coverImage?.deleteImage;

  const hasVirtualLink = useMemo(
    () => (meeting?.link || '')?.length > 0,
    [meeting?.link]
  );

  const details = useMemo(() => {
    const duration = dayjs.duration(dayjs(ends)?.diff(start));
    const formattedDuration = duration.format('D[ days] H[ hours] m[ minutes]');
    return [
      {
        Icon: Timer,
        type: 'duration',
        value: formattedDuration
          .replace(/\b0\s+[a-z]+\s*/gi, '')
          .replace(/^(1\s[^s]+)s/gi, '$1')
          .replace(/(\s1\s[^s]+)s/gi, '$1')
          .trim(),
      },
      { Icon: MapPin, type: 'location', value: location || null },
      {
        Icon: VideoCamera,
        type: 'virtual',
        value:
          (meeting?.service || '')?.length > 0 ? (
            <Stack alignItems='center' direction='row'>
              <Typography variant='inherit'>
                {hasVirtualLink ? meeting?.link : 'Virtual link'}
              </Typography>
              {hasVirtualLink ? (
                <IconButton
                  data-testid='TODO:DATA-ICONBUTTON-97347'
                  onClick={() => copy(meeting?.link, 'virtual link')}
                  sx={{ my: -1 }}
                >
                  <LinkIcon />
                </IconButton>
              ) : null}
            </Stack>
          ) : null,
      },
      {
        Icon: User,
        type: 'hosts',
        value:
          hosts?.length > 0
            ? `Hosted by ${toOxfordComma(
                hosts?.map((host: any) => host.label || host)
              )}`
            : null,
      },
    ];
  }, [
    copy,
    ends,
    hasVirtualLink,
    hosts,
    location,
    meeting?.link,
    meeting?.service,
    start,
  ]);

  const image = useMemo(
    () =>
      geopattern.generate(name || 'New', {
        baseColor: theme.palette.primary.main,
        color: theme.palette.grey[300],
      }),
    [name, theme.palette.grey, theme.palette.primary.main]
  );

  const imageUrl = useMemo(() => image?.toDataUri(), [image]);

  const aspectRatio = '16 / 9';

  const setImageToPlaceholder = (e: any) => {
    e.currentTarget.source = imageUrl;
  };

  return (
    <Stack direction='column' gap={5} pb={2}>
      <Box
        data-testid='preview-card'
        sx={{
          aspectRatio,
          bgcolor: 'transparent',
          borderRadius: '6px',
          overflow: 'hidden',
          textAlign: 'center',
        }}
      >
        <Fade in={hasImage || imageUrl?.length > 0}>
          <img
            alt='event banner'
            onError={setImageToPlaceholder}
            src={
              (coverImage?.cropDataUrl || '')?.length > 0
                ? coverImage?.cropDataUrl
                : (coverImage?.fullDataUrl || '')?.length > 0
                ? coverImage?.fullDataUrl
                : imageUrl || undefined
            }
            style={{
              aspectRatio,
              maxWidth: '100%',
              objectFit: hasImage ? 'contain' : 'cover',
              transition: 'all 300ms ease-in-out',
              width: '100%',
            }}
          />
        </Fade>
      </Box>
      <Stack direction='column' gap={3}>
        <Stack direction='column' gap={2}>
          <Typography variant='h4'>Event details</Typography>
          <List
            data-testid='TODO:DATA-LIST-57679'
            dense
            disablePadding
            sx={{ ...flexColumn, gap: 2 }}
          >
            {details
              ?.filter((d) => d.value !== null)
              ?.map(({ Icon, type, value }) => (
                <ListItem disablePadding key={type}>
                  <ListItemIcon slot='start' sx={{ minWidth: 32 }}>
                    <Icon size={20} />
                  </ListItemIcon>
                  <ListItemText primary={value} sx={{ m: 0 }} />
                </ListItem>
              ))}
          </List>
        </Stack>
        {description ? <RichTextDisplay text={description} /> : null}
        {(eventTypeId || 0) > 0 && organization ? (
          <Stack direction='row' gap={1.5}>
            {/* TODO: Iterate through event types to display a chip for each (once we can capture > 1) */}
            <Chip
              color='primary'
              label={
                organization?.event_types?.find(
                  (t: { id?: number }) => t?.id === eventTypeId
                )?.name
              }
              sx={
                eventTypeColors !== null
                  ? {
                      bgcolor: eventTypeColors[eventTypeId as number],
                      color: 'grey.900',
                    }
                  : null
              }
              variant='light'
            />
          </Stack>
        ) : null}
      </Stack>
      {resources?.length > 0 ? (
        <Stack direction='column' gap={2}>
          <Typography variant='h4'>Resources</Typography>
          {resources?.map(({ id, link, title }, index) => (
            <Link
              data-testid='TODO:DATA-LINK-71216'
              href={
                (link || '').indexOf('http') === -1 ? `https://${link}` : link
              }
              key={id || index}
              sx={{ textDecoration: 'none !important' }}
              target='_blank'
            >
              {title}
            </Link>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default EventPreviewCard;
