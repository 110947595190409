import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import EventForm from 'components/Event/EventForm';
import EventTipsForSuccess from 'components/Event/EventTipsForSuccess';
import useEventFormValues from 'components/Event/useEventFormValues';
import EventTimeChangedCheckCommsAlert from 'components/Events/Controls/Shared/EventTimeChangedCheckCommsAlert';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import DetailsHeader from 'components/Events/Steps/DetailsHeader';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import { useCloneEvent } from 'hooks/useCloneEvent';
import { useDeleteEvent } from 'hooks/useDeleteEvent';
import { useGetEventDetails } from 'hooks/useGetLoadEventDetails';
import useSaveEvent from 'hooks/useSaveEvent';
import { EventDetailsFormData } from 'types/EventDetailsForm';
import FtnEvent from 'types/FtnEvent';
import { hasStarted } from 'utils/event';
import { transformEvent } from 'utils/transform';

const DetailsStep = () => {
  const { id } = useParams<{ id: string }>();

  const { data: eventDetails, isPending } = useGetEventDetails({
    eventId: String(id),
  });

  const {
    actions: { setSavedEvent },
  } = useContext(ManagerContext);

  const values = useEventFormValues(eventDetails);
  const disableFields = hasStarted(eventDetails);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const eventDetailsForm = useForm<EventDetailsFormData>({
    criteriaMode: 'all',
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    values, // These values replace the default values if provided, use this for populating the fields from saved event data
  });

  const { isDirty } = eventDetailsForm.formState;

  const {
    deleteModal,
    isPending: isDeleting,
    showDeleteModal,
  } = useDeleteEvent({
    dataHandler: () => {
      enqueueSnackbar(`Event deleted!`, { variant: 'success' });
      navigate('/events');
    },
    eventId: eventDetails?.id || 0,
    handleSubmit: eventDetailsForm.handleSubmit,
    name: eventDetails?.name || '',
  });

  const { isPending: isCloning, mutate: cloneEvent } = useCloneEvent({
    dataHandler: (d) => {
      enqueueSnackbar(
        `The event was duplicated! You can rename it, update it as needed, then publish it!`,
        { variant: 'success' }
      );
      navigate(`/event/${d.id}/create`);
    },
  });

  // Save changes
  const { mutate: saveEvent } = useSaveEvent({
    dataHandler: (d: FtnEvent) => {
      enqueueSnackbar('Your event has been updated!', {
        variant: 'success',
      });
      eventDetailsForm.reset();
      const newValue = transformEvent(d);
      setSavedEvent(newValue); // Temp, remove when we get rid of ManagerContext
    },
    eventId: id || eventDetails?.id || 0,
  });

  return (
    <DefaultLayout
      appBarStyles={undefined}
      header={
        <DetailsHeader
          cloneEvent={cloneEvent}
          disableFields={disableFields}
          eventId={eventDetails?.id || 0}
          eventName={eventDetails?.name || ''}
          formState={eventDetailsForm.formState}
          handleSubmit={eventDetailsForm.handleSubmit}
          hasStarted={disableFields}
          isLoading={isPending}
          isPublic={eventDetails?.privacy === 'open'}
          reset={eventDetailsForm.reset}
          saveEvent={saveEvent}
          showDeleteModal={showDeleteModal}
        />
      }
      noPadding
      title={null}
    >
      <Box>
        <EventTimeChangedCheckCommsAlert />
        <Grid alignItems='flex-start' container direction='row' spacing={3}>
          <Grid item lg={9} md={12} xs={12}>
            <EventForm
              disableFields={disableFields}
              eventId={eventDetails?.id}
              hookForm={eventDetailsForm}
              isLoading={isPending || isCloning || isDeleting}
              isPublished={eventDetails?.published || false}
              meetingCreator={{
                creatorId: eventDetails?.meeting?.creator_id,
                creatorName: eventDetails?.meeting?.creator_name,
                isSaved: eventDetails?.meeting?.link != null,
              }}
            />
          </Grid>
          <Grid item lg={3} md={12} position='sticky' top={146} xs={12}>
            <EventTipsForSuccess />
          </Grid>
          <UnsavedChangesModal
            hasUnsavedChanges={isDirty}
            onConfirm={() => eventDetailsForm.reset()}
          />
        </Grid>
      </Box>
      {deleteModal}
    </DefaultLayout>
  );
};
export default DetailsStep;
