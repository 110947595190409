import React, { useCallback } from 'react';
import { useBlocker } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

// TODO: Handle tab changes, reimplement unique cases

const UnsavedChangesModal = ({
  hasUnsavedChanges = false,
  onCancel,
  onConfirm,
}) => {
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname,
    [hasUnsavedChanges]
  );

  const blocker = useBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  React.useEffect(() => {
    if (blocker.state === 'blocked' && !hasUnsavedChanges) {
      blocker.reset();
    }
  }, [blocker, hasUnsavedChanges]);

  const handleCancel = () => {
    blocker.reset?.();
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    blocker.proceed?.();
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog
      aria-label='Unsaved Changes'
      onClose={handleCancel}
      open={blocker?.state === 'blocked'}
    >
      <DialogTitle>You have unsaved changes</DialogTitle>
      <DialogContent>
        Wait! You have unsaved changes. If you leave now, you&apos;ll lose these
        changes. Are you sure you&apos;d like to continue?
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          data-testid='back'
          onClick={handleCancel}
          variant='bordered'
        >
          Back
        </Button>
        <Button
          color='primary'
          data-testid='continue'
          onClick={handleConfirm}
          variant='contained'
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnsavedChangesModal.propTypes = {
  hasUnsavedChanges: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  surveyTab: PropTypes.any,
  tryClose: PropTypes.bool,
};
export default UnsavedChangesModal;
