import React from 'react';

import Head from 'components/Core/Head';
import Home from 'components/Home/Home';
import { HomeProvider } from 'contexts/HomeContext';

const Events = () => (
  <>
    <Head title='Events' />
    <HomeProvider>
      <Home />
    </HomeProvider>
  </>
);

export default Events;
