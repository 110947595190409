import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated as axios } from 'utils/axios';

export const GroupsContext = createContext();

export const GroupsProvider = (props) => {
  const [groups, setGroups] = useState([]);
  // We are going to load data immediately, so let's keep from flip floppiing the ConditionalLoaders until we are done with the initial load
  const [isLoading, setIsLoading] = useState(true);
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);

  const loadGroups = useCallback(() => {
    setIsLoading(true);
    axios(
      { url: '/api/groups' },
      (res) => {
        const groupsData = res?.data;
        const groupsWithCurrencies = groupsData?.map((g) => {
          const currency = g.currency_details.symbol || '$';
          const currencyCode = g?.currency_details?.code || 'USD';
          return {
            ...g,
            currency_symbol: currency,
            nameWithCurrency: `${g.name} (${currencyCode} ${currency})`,
          };
        });
        setGroups(groupsWithCurrencies);
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      loadGroups();
    } else {
      setIsLoading(false); // Make sure we clean up after the inital load
    }
  }, [isAuthenticated, loadGroups]);

  return (
    <GroupsContext.Provider value={[groups, setGroups, isLoading, loadGroups]}>
      {props.children}
    </GroupsContext.Provider>
  );
};

GroupsProvider.propTypes = {
  children: PropTypes.any,
};
