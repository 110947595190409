import * as Sentry from '@sentry/react';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  noTimeout?: boolean;
}

export const axiosAuthenticated = (
  config: AxiosRequestConfig,
  onSuccess: (arg0: AxiosResponse<any>) => void,
  onFailure: (error: any) => void,
  manualToken: string | undefined = undefined
  // authToken = token || null,
  // cancelToken: CancelToken | undefined = undefined
) => {
  const token = manualToken || localStorage.getItem('token');
  config.headers = {
    ...config.headers,
    ...{
      Authorization: `Bearer ${token}`,
    },
  };
  config.url = import.meta.env.VITE_BE_URL + config.url;
  axios(config)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      Sentry.addBreadcrumb({
        category: 'axios',
        data: err,
        level: 'error',
        message: `${config.method} request made to ${config.url} failed`,
      });

      if (err?.response?.status === 401) {
        localStorage.removeItem('token'); // setTokenWrapper(null);
        window.location.replace('/login');
      } else if (onFailure) {
        onFailure(err);
      }
    });
};

// react-query gets the response of the query and caches it
// therefore we need to pass the axios request callback and
// wrap it on a promise that we will resolve once the
// callback is called in the AuthContext
export const reactQueryAxios = <T = any, E = any>({
  data,
  method,
  params = {},
  timeout = undefined,
  url,
}: {
  data?: any;
  method: string;
  params?: any;
  timeout?: number;
  url: string;
}): Promise<{ data: T | null; error: E | null }> =>
  new Promise((resolve, reject) => {
    axiosAuthenticated(
      {
        data,
        method: method as Method,
        params,
        timeout,
        url,
      },
      (response) => resolve({ data: response.data as T, error: null }),
      (error) => {
        const normalizedError = error.response
          ? {
              data: error.response.data,
              message: error.message,
              status: error.response.status,
            }
          : { data: null, message: error.message, status: null };
        reject(normalizedError);
      }
    );
  });
