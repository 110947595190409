import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm, FormProvider } from 'react-hook-form';
import CollectionAnnouncementFieldsAndPreview from 'components/Collections/CollectionAnnouncementFieldsAndPreview';
import DialogFullscreenTransition from 'components/shared/DialogFullscreenTransition';
import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import { flexRow } from 'utils/styles';
import DeleteModal from 'components/Core/DeleteModal';

interface CollectionAnnouncementDialogProps {
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  open: boolean;
  collection: any;
}

const CollectionAnnouncementDialog: React.FC<CollectionAnnouncementDialogProps> = ({
  isLoading,
  open,
  onClose,
  onSubmit,
  collection,
}) => {
  const methods = useForm();
  const { handleSubmit, watch, reset, formState } = methods;
  const selectedChannels = watch('slack_channel_ids');
  const messageBody = watch('message_body');
  const [isDiscardModalOpen, setDiscardModalOpen] = useState(false);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setFormHasChanges(Object.keys(formState.dirtyFields).length > 0);
  }, [formState.dirtyFields]);

  const handleClose = () => {
    if (formHasChanges) {
      setDiscardModalOpen(true);
    } else {
      onClose();
    }
  };

  const handleDiscard = () => {
    reset();
    setDiscardModalOpen(false);
    onClose();
  };

  const handleCancelDiscard = () => {
    setDiscardModalOpen(false);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { bgcolor: 'grey.50' } }}
        TransitionComponent={DialogFullscreenTransition}
      >
        <DialogTitle component='div' sx={{ p: 0 }}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar
              sx={{
                    bgcolor: 'white',
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    minHeight: `83px !important`,
                  }}
            >
              <ContentWidthContainer>
                <Stack alignItems='center' direction='row' gap={2}>
                  <Stack direction='column' flex={1}>
                    <Typography
                      color='text.secondary'
                      component='div'
                      variant='overline'
                    >
                      {collection?.name}
                    </Typography>
                    <Typography
                      color='text.primary'
                      component='div'
                      sx={{ flex: 1 }}
                      variant='h4'
                    >
                      Announce Collection
                    </Typography>
                  </Stack>
                  <Button
                    data-testid='cancel'
                    onClick={handleClose}
                    variant='text'
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    color='primary'
                    disabled={!selectedChannels?.length}
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    variant='contained'
                  >
                    Send Now
                  </LoadingButton>
                </Stack>
              </ContentWidthContainer>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <ContentWidthContainer
          ContainerProps={{ component: DialogContent }}
          {...flexRow}
          pb={3}
          pt={2}
          sx={{ '&.DialogContent-root': { overflow: 'hidden' } }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CollectionAnnouncementFieldsAndPreview
                form={methods}
                collection={collection}
              />
            </form>
          </FormProvider>
        </ContentWidthContainer>
      </Dialog>
      <DeleteModal
        custom={{
          byline: 'You have unsent changes in your announcement. If you exit now, these changes will be discarded. Are you sure you want to exit without sending?',
          delete: 'Yes, discard changes',
          title: 'Discard changes?',
          icon: <DeleteIcon />,
        }}
        onCancel={handleCancelDiscard}
        onDelete={handleDiscard}
        show={isDiscardModalOpen}
      />
    </>
  );
};

export default CollectionAnnouncementDialog;
