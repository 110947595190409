import React from 'react';
import _ from 'lodash';

import { InlineLoader } from 'components/Core/Loader';
import Loader from 'components/Core/Loader/Loader';

interface ConditionalLoaderProps {
  children: React.ReactNode;
  conditions: boolean[];
  inline?: boolean;
  loaderComponent?: React.ReactNode;
  text?: string;
}

const ConditionalLoader = ({
  children,
  conditions,
  inline,
  loaderComponent,
  text = '',
}: ConditionalLoaderProps) => {
  if (_.some(conditions, (c) => c === true)) {
    return (
      loaderComponent || (inline ? <InlineLoader text={text} /> : <Loader />)
    );
  } else {
    return <>{children}</>;
  }
};

export default ConditionalLoader;
