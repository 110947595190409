// Colors that are rarely used & therefore don't make sense to be defined in our MUI palette

export const OtherBlue = {
  dark: '#305687',
  light: '#BCD8FC',
  main: '#4D89D6',
};

export const OtherGreen = {
  dark: '#5B6A1C',
  light: '#E0E9BA',
  main: '#92A544',
};

export const OtherPurple = {
  dark: '#634794',
  light: '#DCC9FE',
  main: '#A783EA',
};

export const OtherPink = {
  dark: '#A45084',
  light: '#EDCFE2',
  main: '#D975B2',
};

export const OtherOrange = {
  dark: '#C35E26',
  light: '#F9D0BA',
  main: '#E49826',
};

export const OtherYellow = {
  dark: '#C17900',
  light: '#F3E4B1',
  main: '#F1C841',
};

export const EventGray = '#E4E7EB';
export const ZoomBlue = '#4A8CFF';
export const ChartGrayBlue = '#80A3C0';

export const getContrastColor = (color: string) => {
  const lum = [0.299, 0.587, 0.114].reduce((result, value, index) => {
    const num = parseInt(color?.slice(index * 2 + 1, index * 2 + 3), 16);
    return num * value + result;
  }, 0);

  const isDark = lum < 128;
  return isDark ? '#fff' : '#111';
};
