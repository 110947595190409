import React from 'react';
import { Controller } from 'react-hook-form';
import { GlobeHemisphereWest } from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';

import SummaryField from 'components/shared/SummaryField';
import { eventPrivacyOptions } from 'constants/event.constants';
import { EventDetailsFormControl } from 'types/EventDetailsForm';

interface EventPrivacyFieldProps {
  control: EventDetailsFormControl;
  disableFields: boolean;
  isLoading?: boolean;
}

const EventPrivacyField = ({
  control,
  disableFields,
  isLoading = false,
}: EventPrivacyFieldProps) => {
  const neverPublicFlagEnabled = useFlag('event-privacy-never-public');
  return (
    <Controller
      control={control}
      name='privacy'
      render={({ field }) => {
        const selectedValue = eventPrivacyOptions?.find(
          (opt) => opt.value === field.value
        );
        return (
          <SummaryField
            Icon={selectedValue?.Icon || GlobeHemisphereWest}
            isEditable={!disableFields}
            isLoading={isLoading}
            menuItems={eventPrivacyOptions.filter((opt: any) =>
              neverPublicFlagEnabled ? opt.id !== 'open' : true
            )}
            onSubmit={(v) => field.onChange(v.value)}
            primary='Event privacy'
            selectionLimits={{ max: 1, min: 1 }}
            type='menu'
            value={selectedValue?.name}
            valueType='text'
          />
        );
      }}
    />
  );
};

export default EventPrivacyField;
