import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

const guestOverviewQueryDocument = gql`
  query GuestOverview($id: ID!) {
    guestlistOverview(eventId: $id) {
      statusCounts {
        rsvp
        maybeAttending
        notAttending
        invited
        scheduled
      }
      attendedCounts {
        yes
        no
        notRecorded
      }
      attendedAttendanceCounts {
        virtual
        inPerson
        unknown
      }
    }
  }
`;

interface GuestListOverview {
  guestlistOverview: {
    attendedAttendanceCounts: {
      inPerson: number;
      unknown: number;
      virtual: number;
    };
    attendedCounts: {
      no: number;
      notRecorded: number;
      yes: number;
    };
    statusCounts: {
      invited: number;
      maybeAttending: number;
      notAttending: number;
      rsvp: number;
      scheduled: number;
    };
  };
}

export const useEventGuestCounts = ({ eventId }: { eventId: number }) => {
  const graphQLClient = useGraphQLClient();

  return useQuery<GuestListOverview>({
    queryFn: async () =>
      await graphQLClient?.request(guestOverviewQueryDocument, { id: eventId }),
    queryKey: ['event', eventId, 'guests', 'overview'],
  });
};
