import React from 'react';

type Children = React.ReactElement;

interface Props {
  children: Children;
  condition: boolean;
  wrapper: (children: Children) => React.JSX.Element;
}

const ConditionalWrapper = ({
  children,
  condition,
  wrapper,
}: Props): React.JSX.Element =>
  condition ? wrapper(children) : <>{children}</>;

export default ConditionalWrapper;
