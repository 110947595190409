import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowClockwise, Plus } from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

import ContactListsTable from 'components/Contacts/ContactListsTable';
import ContactsTable from 'components/Contacts/ContactsTable';
import Search from 'components/Core/Search';
import Buttons from 'components/shared/Buttons';
import TabsLayout from 'components/shared/layout/TabsLayout';
import { ListsContext } from 'contexts/ListsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { fullName } from 'utils/employee';

const Contacts = () => {
  const { tab } = useParams();
  const [lists, setLists] = useContext(ListsContext);
  const [organization] = useContext(OrganizationContext);

  const contactListFlagEnabled = useFlag('contact-lists');
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const [addList, setAddList] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const officesById = useMemo(
    () => (organization ? _.keyBy(organization?.offices, 'id') : []),
    [organization]
  );

  const listsForTable = useMemo(() => {
    if (lists) {
      return lists
        ?.filter((l) =>
          searchTerm
            ? l.name?.toLowerCase().includes(searchTerm?.toLowerCase())
            : true
        )
        ?.map((list) => ({
          contacts_count: list.contacts_count,
          created_at: dayjs(list.created_at).format('MMM D, YYYY'),
          employees: list.employees,
          id: list.id,
          is_public: list.is_public,
          name: list.name,
          owner: fullName(list.owner_employee),
        }));
    }
  }, [lists, searchTerm]);

  const reSyncGoogleGroups = () => {
    const syncingSnackbar = enqueueSnackbar('Syncing Google Groups...', {
      persist: true,
      variant: 'info',
    });
    axios({ method: 'get', url: `/api/lists/resync_google_groups` }, (res) => {
      setLists(res.data);
      enqueueSnackbar('Google Groups synced!', {
        onEntered: () => closeSnackbar(syncingSnackbar),
        variant: 'success',
      });
    });
  };

  const tabs = useMemo(() => {
    const tabs = [
      {
        label: 'Contacts',
        value: 'all',
      },
      ...(contactListFlagEnabled && lists?.length > 0
        ? [
            {
              label: 'Contact lists',
              value: 'lists',
            },
          ]
        : []),
    ];
    return tabs;
  }, [contactListFlagEnabled, lists?.length]);

  return (
    <TabsLayout
      button={
        tab === 'lists' && contactListFlagEnabled ? (
          <Buttons
            buttons={[
              ...(lists?.filter((l) => l.is_google_group).length > 0
                ? [
                    {
                      onClick: () => {
                        reSyncGoogleGroups();
                      },
                      startIcon: <ArrowClockwise />,
                      text: 'Resync Google Groups',
                    },
                  ]
                : []),
              {
                onClick: () => {
                  setAddList(true);
                },
                startIcon: <Plus />,
                text: 'New contact list',
              },
            ]}
            justifyContent='flex-end'
          />
        ) : null
      }
      fixedContent={
        tab === 'lists' ? (
          <Search
            label='Search all contact lists'
            onChange={handleChange}
            placeholder='Search all contact lists'
            value={searchTerm}
          />
        ) : null
      }
      tabs={tabs}
      title='Contacts'
    >
      {tab === 'all' ? (
        <ContactsTable />
      ) : (
        <ContactListsTable
          addList={addList}
          doneAdding={() => setAddList(false)}
          lists={listsForTable}
          officesById={officesById}
          organization={organization}
        />
      )}
    </TabsLayout>
  );
};

export default Contacts;
