import React, { useContext } from 'react';
import { Box, Drawer, Stack, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import DefaultLayout from 'components/shared/layout/DefaultLayout';
import TabsLayout from 'components/shared/layout/TabsLayout';
import { LayoutContext } from 'contexts/LayoutContext';
import { flexColumn, flexRow } from 'utils/styles';

const RightColumnLayout = ({
  children,
  columnContent,
  isTabsLayout,
  ...props
}) => {
  const theme = useTheme();
  const isXlPlus = useMediaQuery(theme.breakpoints.up('xl'));
  const { isMobile } = useContext(LayoutContext);
  const drawerWidth = 288;

  return (
    <Box sx={{ ...flexRow, justifyContent: 'center' }}>
      <Box
        component='main'
        sx={
          isMobile
            ? {
                width: '100vw',
              }
            : {
                flexGrow: 1,
                flexShrink: 1,
                maxWidth: `calc(100% - ${drawerWidth}px)`,
              }
        }
      >
        {isTabsLayout && props.tabs?.length > 0 ? (
          <TabsLayout {...props}>{children}</TabsLayout>
        ) : (
          <DefaultLayout {...props}>{children}</DefaultLayout>
        )}
      </Box>
      {isXlPlus ? (
        <Box position='relative' width={`${drawerWidth}px`}>
          <Stack
            direction='column'
            gap={1}
            p={2}
            position='sticky'
            top={0}
            width='100%'
          >
            {columnContent}
          </Stack>
        </Box>
      ) : !isMobile ? (
        <Drawer
          anchor='right'
          data-testid='TODO:DATA-DRAWER-74092'
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
            flexShrink: 0,
            overflowY: 'auto',
            width: drawerWidth,
          }}
          variant='permanent'
        >
          <Box p={2} sx={{ ...flexColumn, gap: 1 }}>
            {columnContent}
          </Box>
        </Drawer>
      ) : null}
    </Box>
  );
};

RightColumnLayout.propTypes = {
  ...DefaultLayout.propTypes,
  ...TabsLayout.propTypes,
  columnContent: PropTypes.node,
  isTabsLayout: PropTypes.bool,
};

export default RightColumnLayout;
