import { Theme } from '@mui/material';

export const getRichTextStyling = (theme: Theme) => ({
  blockquote: {
    borderLeft: `2px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(2),
  },
  'h1, h2, h3, h4, h5, h6': { lineHeight: 1.1 },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  'th, td': {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1),
  },
  'ul, ol': {
    ...theme.typography.body1,
    li: {
      '> p': {
        margin: 0,
      },
      margin: `${theme.spacing(0.5)} 0`,
    },
    padding: `0 0 0 ${theme.spacing(2.25)}`,
  },
});
