import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated as axios } from 'utils/axios';

export const ListsContext = createContext();

export const ListsProvider = (props) => {
  const [lists, setLists] = useState();
  // We are going to load data immediately, so let's keep from flip floppiing the ConditionalLoaders until we are done with the initial load
  const [isLoading, setIsLoading] = useState(true);
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      axios(
        { url: '/api/lists' },
        (res) => {
          setLists(res.data);
          setIsLoading(false);
        },
        () => setIsLoading(false)
      );
    } else {
      setIsLoading(false); // Clean up after initial load
    }
  }, [isAuthenticated]);

  return (
    <ListsContext.Provider value={[lists, setLists, isLoading]}>
      {props.children}
    </ListsContext.Provider>
  );
};

ListsProvider.propTypes = {
  children: PropTypes.any,
};
