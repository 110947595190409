import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import OnboardFrame from 'components/Onboard/OnboardFrame';
import { AuthContext } from 'contexts/AuthContext';
import { PublicOrgContext } from 'contexts/PublicOrgContext';
import { useExternalSnackbarProps } from 'utils/snackbar';

const Login = () => {
  const {
    onboard: { setTempToken },
  } = useContext(AuthContext);
  const [org, isPublicOrgLoading] = useContext(PublicOrgContext);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const snackbarProps = useExternalSnackbarProps();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const doLogin = () => {
    setIsLoading(true);
    axios
      .post(`${import.meta.env.VITE_BE_URL}/api/admins/login`, {
        user: { organization_id: org.id, password, username },
      })
      .then((res) => {
        if (setTempToken) {
          setIsLoading(false);
          setTempToken(res.data.token);
          navigate('/sign-up');
        }
      })
      .catch(() => {
        setIsLoading(false);
        enqueueSnackbar('Something went wrong! Please contact Five to Nine', {
          ...snackbarProps,
          variant: 'error',
        });
      });
  };

  const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

  // if (org && org.force_okta_sign_in) {
  //   return <Redirect to='/login' />;
  // }

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <OnboardFrame
        conditions={[isPublicOrgLoading, isLoading]}
        subtitle='Enter the temporary username and password provided by Five to Nine to start creating your account.'
        title='Welcome!'
      >
        <form data-testid='sign-in-form admin-size' onSubmit={doLogin}>
          <TextField
            autoComplete='temporary username'
            fullWidth
            id='temporary-username'
            label='Temporary username'
            onChange={(e) => setUsername(e.target.value)}
            placeholder='Add your temporary username here'
            value={username}
          />
          <TextField
            autoComplete='temporary password'
            fullWidth
            id='temporary-password'
            label='Temporary Password'
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Add your temporary password here'
            type='password'
            value={password}
          />
          <Button
            color='secondary'
            data-testid='submit'
            onClick={doLogin}
            variant='contained'
          >
            Submit
          </Button>
        </form>
      </OnboardFrame>
    </GoogleOAuthProvider>
  );
};
export default Login;
