import React, { useContext, useMemo, useState } from 'react';
import { CardContent, Collapse, styled } from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';
import dayjs from 'dayjs';

import { useEventLocationType } from 'components/Events/Hooks/Event';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useEventDateTimeRange from 'hooks/useEventDateTimeRange';

import 'components/Events/Controls/Communications/PreviewSlackMessage.css';

interface Props {
  customMessage: any;
  customPreviewComponent?: boolean;
  event: any;
  hasDetailsButton?: boolean;
  hasRsvpButton?: boolean;
  slackAppName: string;
}

const CustomMessageWrapper = styled('div')(() => ({
  '> p:first-of-type': {
    marginBlockStart: 0,
    marginTop: 0,
  },
  'img[data-slack-emoji]': {
    height: '20px',
    margin: '0 2px',
    verticalAlign: 'middle',
    width: '20px',
  },
  'li > p': {
    display: 'inline',
  },
  'ul > li': {
    listStyleType: "'• '",
  },
  'ul, ol': {
    listStylePosition: 'inside',
    paddingInlineStart: 0,
  },
}));

const PreviewSlackMessage = ({
  customMessage,
  customPreviewComponent = false,
  event,
  hasDetailsButton = true,
  hasRsvpButton = true,
}: Props) => {
  const eventDateTimeRange = useEventDateTimeRange(event);
  const { displayName: eventLocationType } = useEventLocationType(event);
  const [isImageCollapsed, setIsImageCollapsed] = useState(false);
  const [organization] = useContext(OrganizationContext);

  const slackAppName = useMemo(
    () => organization?.slack_bot?.name || 'Five to Nine',
    [organization?.slack_bot?.name]
  );

  const slackAppIcon = useMemo(
    () =>
      organization?.slack_bot?.icon ||
      'https://ca.slack-edge.com/T27AUKYPJ-U015N4VA570-a8bbe4caa0e0-48',
    [organization?.slack_bot?.icon]
  );

  return (
    <CardContent>
      <div className='c-message_kit__gutter'>
        <div
          className='c-message_kit__gutter__left'
          data-stringify-ignore='true'
          role='presentation'
        >
          <span className='p-member_profile_hover_card' role='presentation'>
            <span
              aria-hidden='true'
              aria-label='View Profile'
              className='c-message_kit__avatar c-avatar'
              style={{ height: 36, width: 36 }}
            >
              <span
                className='c-base_icon__width_only_container'
                style={{ height: 36, width: 36 }}
              >
                <img
                  alt='app-avatar'
                  aria-hidden='true'
                  className='c-base_icon c-base_icon--image'
                  src={slackAppIcon}
                  style={{ width: 36 }}
                />
              </span>
            </span>
          </span>
        </div>
        <div
          className='c-message_kit__gutter__right'
          data-qa='message_content'
          role='presentation'
        >
          <span
            className='c-message__sender c-message_kit__sender'
            data-qa='message_sender'
            data-stringify-text={slackAppName}
            data-stringify-type='replace'
          >
            <span className='p-member_profile_hover_card' role='presentation'>
              <span
                className='c-message__sender_button'
                data-message-sender='U015N4VA570'
                data-qa='message_sender_name'
              >
                {slackAppName}
              </span>
            </span>
            <span style={{ marginLeft: 4 }}>
              <span className='c-app_badge' data-stringify-suffix=' '>
                APP
              </span>
            </span>
          </span>
          &nbsp;&nbsp;
          <span
            aria-label={dayjs().format('LT')}
            className='c-link c-timestamp'
            data-stringify-requires-siblings='true'
            data-stringify-text={`['${dayjs().format('H:mm a')}']`}
            data-stringify-type='replace'
          >
            <span className='c-timestamp__label'>{dayjs().format('LT')}</span>
          </span>
          <br />
          <div className='c-message_kit__blocks'>
            <div className='p-autoclog__hook'>
              <div className='c-message__message_blocks' data-qa='message-text'>
                <div
                  className='p-block_kit_renderer'
                  data-qa='block-kit-renderer'
                >
                  <div className='p-block_kit_renderer__block_wrapper p-block_kit_renderer__block_wrapper--first'>
                    <div
                      className='p-section_block p-section_block--no_top_margin'
                      data-qa='bk_section_block'
                    >
                      <div className='p-section_block_text_content'>
                        <div className='p-section_block__text'>
                          <div className='p-mrkdwn_element'>
                            <span dir='auto'>
                              {customMessage?.length > 0 ? (
                                <CustomMessageWrapper
                                  dangerouslySetInnerHTML={{
                                    __html: customMessage,
                                  }}
                                />
                              ) : customPreviewComponent ? (
                                <span
                                  style={{
                                    alignItems: 'center',
                                    backgroundColor: '#FAFAFA',
                                    border: '2px dashed #E0E0E0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    justifyContent: 'center',
                                    padding: '1rem',
                                  }}
                                >
                                  Your message will appear here
                                </span>
                              ) : (
                                <b data-stringify-type='bold'>
                                  📣 You&apos;re invited!
                                </b>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-block_kit_renderer__block_wrapper'>
                    <div
                      className='p-divider_block'
                      data-qa='bk_divider_block'
                    />
                  </div>
                  <div className='p-block_kit_renderer__block_wrapper'>
                    <div className='p-section_block' data-qa='bk_section_block'>
                      <div className='p-section_block_text_content'>
                        <span dir='auto'>
                          <b data-stringify-type='bold'>{event?.name}</b>
                          <br />
                          {eventDateTimeRange}{' '}
                          {eventLocationType ? `(${eventLocationType})` : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                  {event?.cover_image ? (
                    <div className='p-block_kit_renderer__block_wrapper'>
                      <div className='p-image_block p-image_block--full_width'>
                        <div className='p-image_block_container p-image_block_container--full_width'>
                          <div className='p-image_block_title'>
                            <span
                              className='c-message_attachment__media_trigger c-message_attachment__media_trigger--caption'
                              data-stringify-ignore='true'
                            >
                              {' '}
                              (10 kB)
                              <button
                                aria-expanded={!isImageCollapsed}
                                className='c-button-unstyled c-expandable_trigger'
                                data-qa-expandable-trigger-is-expanded={
                                  !isImageCollapsed
                                }
                                data-qa-expandable-trigger-key='block_image_1698776732.734289_DsVys'
                                onClick={() =>
                                  setIsImageCollapsed(!isImageCollapsed)
                                }
                                title='collapse'
                                type='button'
                              >
                                <CaretDown
                                  size={10}
                                  style={
                                    isImageCollapsed
                                      ? { transform: 'rotate(-90deg)' }
                                      : {}
                                  }
                                  weight='fill'
                                />
                              </button>
                            </span>
                          </div>
                          <div
                            aria-label=''
                            className='p-image_block_container__expandable_container'
                            data-expanded={!isImageCollapsed}
                            data-qa-expandable-container-is-expanded={
                              !isImageCollapsed
                            }
                            data-qa-expandable-container-key='block_image_1698776732.734289_DsVys'
                          >
                            <Collapse in={!isImageCollapsed} unmountOnExit>
                              <div
                                className='c-aspect_box__outer c-message_attachment__image_container p-message_attachment__image_container--has-actions'
                                style={{ width: 250 }}
                              >
                                <div
                                  className='c-aspect_box__inner'
                                  style={{ paddingTop: '56.4%' }}
                                >
                                  <div className='c-aspect_box__content'>
                                    <span
                                      aria-label='cover-image'
                                      className='c-link c-message_attachment__image'
                                      data-qa='attachment_image'
                                      style={{
                                        backgroundImage: `url(${event?.config?.cover_image_url})`,
                                        backgroundSize: 'contain',
                                        cursor: 'unset',
                                        height: 141,
                                        width: 250,
                                      }}
                                      title='cover-image'
                                    >
                                      <img
                                        alt='cover'
                                        height={141}
                                        src={event?.config?.cover_image_url}
                                        width={250}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {hasDetailsButton ? (
                    <div className='p-block_kit_renderer__block_wrapper'>
                      <div
                        className='p-actions_block'
                        data-qa='bk_actions_block'
                      >
                        <div className='p-actions_block_elements'>
                          <div
                            className='p-actions_block__action'
                            data-qa='bk_actions_block_action'
                          >
                            <button
                              className='c-button c-button--outline c-button--small p-block_kit_button_element'
                              data-qa-action-id='event_details'
                              data-qa-block-id='event-details'
                              id='event_details-event-details'
                              type='button'
                            >
                              <div
                                className='p-plain_text_element'
                                data-qa='bk-plain_text_element'
                              >
                                <span dir='auto'>View Event Details</span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className='p-action_block__icons' />
                      </div>
                    </div>
                  ) : null}
                </div>
                {hasRsvpButton ? (
                  <>
                    <div className='p-block_kit_renderer__block_wrapper'>
                      <div
                        className='p-divider_block'
                        data-qa='bk_divider_block'
                      />
                    </div>
                    <div className='p-block_kit_renderer__block_wrapper'>
                      <div
                        className='p-section_block'
                        data-qa='bk_section_block'
                      >
                        <div className='p-section_block_text_content'>
                          <div className='p-section_block__text'>
                            <div
                              className='p-plain_text_element'
                              data-qa='bk-plain_text_element'
                            >
                              <span dir='auto'>Will you be attending?</span>
                            </div>
                          </div>
                        </div>
                        <div className='p-action_block__icons' />
                      </div>
                    </div>
                    <div className='p-block_kit_renderer__block_wrapper'>
                      <div
                        className='p-actions_block'
                        data-qa='bk_actions_block'
                      >
                        <div className='p-actions_block_elements'>
                          <div
                            className='p-actions_block__action'
                            data-qa='bk_actions_block_action'
                          >
                            <button
                              className='c-button c-button--outline-primary c-button--small p-block_kit_button_element'
                              data-qa-action-id='rsvp'
                              data-qa-block-id='nFj'
                              id='rsvp-nFj'
                              type='button'
                            >
                              <div
                                className='p-plain_text_element'
                                data-qa='bk-plain_text_element'
                              >
                                <span dir='auto'>Yes</span>
                              </div>
                            </button>
                          </div>
                          <div
                            className='p-actions_block__action'
                            data-qa='bk_actions_block_action'
                          >
                            <button
                              className='c-button c-button--outline c-button--small p-block_kit_button_element'
                              data-qa-action-id='maybe_attending'
                              data-qa-block-id='nFj'
                              id='maybe_attending-nFj'
                              type='button'
                            >
                              <div
                                className='p-plain_text_element'
                                data-qa='bk-plain_text_element'
                              >
                                <span dir='auto'>Maybe</span>
                              </div>
                            </button>
                          </div>
                          <div
                            className='p-actions_block__action'
                            data-qa='bk_actions_block_action'
                          >
                            <button
                              className='c-button c-button--outline-danger c-button--small p-block_kit_button_element'
                              data-qa-action-id='not_attending'
                              data-qa-block-id='nFj'
                              id='not_attending-nFj'
                              type='button'
                            >
                              <div
                                className='p-plain_text_element'
                                data-qa='bk-plain_text_element'
                              >
                                <span dir='auto'>No</span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className='p-action_block__icons' />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  );
};

export default PreviewSlackMessage;
