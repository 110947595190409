import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';

import EmptyState, { EmptyStateProps } from 'components/shared/EmptyState';
import { toHtmlId } from 'utils/text';

type Props = {
  cards?: {
    action?: React.ReactNode;
    children: React.ReactNode;
    title: string;
  }[];
  children?: React.ReactNode;
  EmptyStateProps?: EmptyStateProps;
  heading: string;
};

const CardsBelowHeading = ({
  cards,
  children,
  EmptyStateProps,
  heading,
}: Props) => (
  <Stack
    direction='column'
    gap={3}
    sx={{ '& .MuiCardContent-root': { pb: 1.5, pt: 0 } }}
    width='100%'
  >
    <Typography component='h3' variant='h4'>
      {heading}
    </Typography>
    {cards ? (
      <>
        {cards?.length > 0 ? (
          <>
            {cards.map(({ action, children, title }) => (
              <Card
                data-testid={`budget-settings-${toHtmlId(title)}`}
                key={toHtmlId(title)}
              >
                <CardHeader
                  action={action}
                  title={title}
                  titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent sx={action ? { pt: 0 } : {}}>
                  {children}
                </CardContent>
              </Card>
            ))}
          </>
        ) : cards?.length === 0 && EmptyStateProps ? (
          <EmptyState {...EmptyStateProps} />
        ) : null}
      </>
    ) : children ? (
      <>{children}</>
    ) : null}
  </Stack>
);

export default CardsBelowHeading;
