import React from 'react';
import { Avatar, Stack, Typography, TypographyVariant } from '@mui/material';
import pluralize from 'pluralize';

import { SurveyQuestionType } from 'types/SurveyQuestionType';

interface SurveyQuestionTitleProps {
  hideResponseCount: boolean;
  individualResponsesLength: number;
  isPreEvent: boolean;
  position: number;
  title: string;
  titleVariant: TypographyVariant;
  typeConfig: SurveyQuestionType;
}

export const SurveyQuestionTitle = ({
  hideResponseCount,
  individualResponsesLength,
  isPreEvent,
  position,
  title,
  titleVariant = 'body2',
  typeConfig,
}: SurveyQuestionTitleProps) => (
  <Stack
    alignItems='flex-start'
    direction='row'
    gap={1.5}
    justifyContent='space-between'
    mb={2}
  >
    <Avatar
      data-testid='question-type-icon'
      sx={{
        bgcolor: typeConfig?.colors?.light,
        color: typeConfig?.colors?.dark,
        height: 28,
        width: 28,
      }}
      variant='rounded'
    >
      {typeConfig?.analyticsIcon}
    </Avatar>
    <Stack alignItems='flex-start' direction='row' gap={0.5}>
      <Typography variant={titleVariant}>{`${position}.`}</Typography>
      <Typography variant={titleVariant}>
        {title || `${isPreEvent ? 'Pre' : 'Post'}-event survey`}
      </Typography>
    </Stack>
    <Typography
      color='text.secondary'
      flexGrow={1}
      textAlign='right'
      variant='body1'
      whiteSpace='nowrap'
    >
      {!hideResponseCount &&
        pluralize('response', individualResponsesLength, true)}
    </Typography>
  </Stack>
);
