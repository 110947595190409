import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Search from 'components/Core/Search';
import CreateSurveyTemplateButton from 'components/Events/Controls/Surveys/CreateSurveyTemplateButton';
import Filters from 'components/shared/Filters';
import TabsLayout from 'components/shared/layout/TabsLayout';
import SurveyTemplateCards from 'components/Survey/SurveyTemplates/SurveyTemplateCards';
import { AuthContext } from 'contexts/AuthContext';
import useRedirectToItem from 'hooks/useRedirectToItem';
import { SurveyTypes } from 'utils/survey';
import { ApplyFilterToChunk, useChunking } from 'utils/utils';

const Surveys = () => {
  const surveysChunk = useChunking('/api/survey_templates', 'templates');
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const { tab } = useParams();

  const [searchTerm, setSearchTerm] = useState('');
  const [surveyType, setSurveyType] = useState('');

  useRedirectToItem('surveyTemplate');

  const mySurveysChunk = ApplyFilterToChunk(
    (survey) => survey.creator_id === user.id,
    surveysChunk
  );

  const chunk = useMemo(
    () => (tab === 'all' ? surveysChunk : mySurveysChunk),
    [mySurveysChunk, surveysChunk, tab]
  );

  return (
    <TabsLayout
      button={<CreateSurveyTemplateButton />}
      fixedContent={
        <Filters
          filters={[
            {
              handleChange: setSurveyType,
              label: 'Survey type',
              options: SurveyTypes,
            },
          ]}
        >
          <Search
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Search templates'
            sx={{ minWidth: 220 }}
            value={searchTerm}
          />
        </Filters>
      }
      fixedLocation='below-tabs'
      tabs={[
        {
          label: 'All survey templates',
          value: 'all',
        },
        {
          label: 'My survey templates',
          value: 'my',
        },
      ]}
      title='Survey templates'
    >
      <SurveyTemplateCards
        chunk={chunk}
        searchTerm={searchTerm}
        surveyType={surveyType}
        tab={tab}
      />
    </TabsLayout>
  );
};

export default Surveys;
