import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';

const SendEmailUpdatesModal = ({ isOpen, onClose, onDontSend, onSend }) => {
  const {
    actions: { applySingle },
    state: { event },
    validation: { getFirstError },
  } = useContext(ManagerContext);

  const handleClose = () => {
    onClose();
  };

  const handleDontSend = () => {
    onDontSend();
  };

  const handleSend = () => {
    onSend();
    handleClose();
  };

  return (
    <Dialog aria-label='Unsaved Changes' onClose={handleClose} open={isOpen}>
      <DialogTitle>Would you like to send your guests an update?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to notify your guests of the event changes you are about
          to save? Guests will receive these updates via their invitation method
          for this event (i.e. email, Slack, and calendar).
        </DialogContentText>
        <TextField
          error={getFirstError('email_updates_message')?.message != null}
          fullWidth
          helperText={getFirstError('email_updates_message')?.message}
          id='event-update-event-message'
          label='Optional message'
          multiline
          onChange={(e) =>
            applySingle(e?.target?.value, 'event.email_updates_message')
          }
          placeholder='Add a message to send with your notification.'
          rows={2}
          value={event.email_updates_message}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid='cancel' onClick={handleClose} variant='text'>
          Cancel
        </Button>
        <Button
          data-testid="no,-don't-send"
          onClick={handleDontSend}
          variant='text'
        >
          No, don&apos;t send
        </Button>
        <Button
          data-testid='yes,-send'
          onClick={handleSend}
          variant='contained'
        >
          Yes, send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SendEmailUpdatesModal.propTypes = {
  isEventUpdate: PropTypes.bool,
  onClose: PropTypes.func,
  onDontSend: PropTypes.func,
  onSend: PropTypes.func,
};
export default SendEmailUpdatesModal;
