import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

export const SyncedSlackChannelContext = createContext();

export const SyncedSlackChannelProvider = (props) => {
  const [organization] = useContext(OrganizationContext);
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);

  const [syncedChannels, setSyncedChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSyncedSlackChannels = useCallback(() => {
    setIsLoading(true);
    axios(
      { noTimeout: true, url: '/api/organizations/synced_slack_channels' },
      (res) => {
        setSyncedChannels(res.data);
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  }, []);

  useEffect(() => {
    if (isAuthenticated && organization?.slack_enabled) {
      fetchSyncedSlackChannels();
    } else {
      setIsLoading(false);
    }
  }, [fetchSyncedSlackChannels, isAuthenticated, organization?.slack_enabled]);

  return (
    <SyncedSlackChannelContext.Provider
      value={[
        syncedChannels,
        setSyncedChannels,
        isLoading,
        fetchSyncedSlackChannels,
      ]}
    >
      {props.children}
    </SyncedSlackChannelContext.Provider>
  );
};

SyncedSlackChannelProvider.propTypes = {
  children: PropTypes.node,
};
