const NPSYellow = 0;
const NPSGreen = 30;

export const NpsAccent = (score) => {
  if (score <= NPSYellow) {
    return 'error';
  } else if (score <= NPSGreen) {
    return 'warning';
  } else {
    return 'success';
  }
};
