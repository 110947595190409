/* eslint-disable react/no-multi-comp */
import React, { useMemo, useState } from 'react';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { ChartPie } from '@phosphor-icons/react';
import { animated } from '@react-spring/web';

import EmptyState from 'components/shared/EmptyState';
import InfoTooltip from 'components/shared/InfoTooltip';
import TooltipWithCustomWidth from 'components/shared/TooltipWithCustomWidth';
import {
  EventTypeBreakdown,
  ExpenseCategoryBreakdown,
} from 'hooks/useGroupBudget';
import Currency from 'types/Currency';
import { chartHeight } from 'utils/text';
import { asCurrency } from 'utils/utils';

interface GroupBudgetSpendBreakdownProps {
  currency: Currency | undefined;
  eventTypeBreakdown: EventTypeBreakdown[];
  expenseCategoryBreakdown: ExpenseCategoryBreakdown[];
}

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-icon': {
    border: 'none',
    color: theme.palette.primary.main,
  },
  '& .MuiSelect-select': {
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  '& fieldset': {
    border: 'none',
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.5),
  },
  color: theme.palette.primary.main,
  fontWeight: 500,
  margin: `${theme.spacing(-0.5)} 0`,
  transition: 'all 200ms ease-in-out',
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[200],
  },
  '&:hover:not(.Mui-selected)': {
    backgroundColor: theme.palette.grey[50],
  },
}));

const GroupBudgetSpendBreakdown: React.FC<GroupBudgetSpendBreakdownProps> = ({
  currency,
  eventTypeBreakdown,
  expenseCategoryBreakdown,
}) => {
  const [breakdownMode, setBreakdownMode] = useState<'eventType' | 'category'>(
    'eventType'
  );
  const theme = useTheme();

  const filteredEventTypeBreakdown = eventTypeBreakdown.filter(
    (item) =>
      item.name && item.name.toLowerCase() !== 'unknown' && item.name !== ''
  );

  const data =
    breakdownMode === 'eventType'
      ? filteredEventTypeBreakdown.sort((a, b) => b.value - a.value)
      : expenseCategoryBreakdown.sort((a, b) => b.value - a.value);

  const chartData: BarDatum[] = useMemo(() => {
    const totalValue = data.reduce((sum, item) => sum + item.value, 0);
    const minBarSize = 0.03; // 3% of total as minimum bar size

    return data.map((item) => {
      const normalizedValue = item.value / totalValue;
      const adjustedValue = Math.max(normalizedValue, minBarSize);

      return {
        ...item,
        [breakdownMode === 'eventType' ? 'eventType' : 'category']: item.name,
        adjustedValue,
        originalValue: item.value,
      };
    });
  }, [data, breakdownMode]);

  const hasData = data.length > 0;

  const AxisLeftTick = ({
    animatedProps,
    value,
    x,
    y,
  }: {
    animatedProps: any;
    value: string;
    x: number;
    y: number;
  }) => (
    <animated.g
      transform={`translate(${x},${y})`}
      style={{ opacity: animatedProps.opacity }}
    >
      <text
        dominantBaseline='middle'
        style={{
          fill: theme.palette.text.primary,
          fontFamily: 'Inter',
          fontSize: 12,
        }}
        textAnchor='end'
      >
        <tspan dy='0' x='-10'>
          {value.length > 20 ? `${value.slice(0, 17)}...` : value}
        </tspan>
      </text>
    </animated.g>
  );

  const CustomTooltip = ({ color, data }: any) => (
    <TooltipWithCustomWidth placement='bottom-start' title='' width={250}>
      <Card
        sx={{
          bgcolor: 'white',
          maxWidth: 250,
          px: 1.5,
          py: 1,
          width: 'auto',
        }}
        variant='elevation'
      >
        <Typography
          display='block'
          mb={1}
          variant='overline'
          whiteSpace='normal'
        >
          {data.name}
        </Typography>
        <Typography fontWeight='bold' variant='body2'>
          {asCurrency(data?.originalValue, currency?.code)}
        </Typography>
      </Card>
    </TooltipWithCustomWidth>
  );

  return (
    <>
      <CardHeader
        title={
          <Stack
            alignItems='center'
            direction='row'
            display='flex'
            flexWrap='wrap'
          >
            <Typography component='h3' variant='h5' whiteSpace='nowrap'>
              Spend breakdown
            </Typography>
            <FormControl margin='none' sx={{ border: 'none' }}>
              <StyledSelect
                color='primary'
                onChange={(e) =>
                  setBreakdownMode(e.target.value as 'eventType' | 'category')
                }
                size='small'
                value={breakdownMode}
              >
                <StyledMenuItem value='eventType'>by event type</StyledMenuItem>
                <StyledMenuItem value='category'>
                  by expense category
                </StyledMenuItem>
              </StyledSelect>
            </FormControl>
            <InfoTooltip
              content='The breakdown of group spend based on event type or expense categories.'
              text=''
            />
          </Stack>
        }
      />
      {hasData ? (
        <Box component='div' height={`${chartHeight(data)}px`}>
          <ResponsiveBar
            axisBottom={null}
            axisLeft={{
              renderTick: AxisLeftTick,
              tickSize: 0,
            }}
            borderRadius={2}
            colors={[theme.palette.secondary.main]}
            data={chartData}
            enableGridY={false}
            enableLabel={false}
            indexBy={breakdownMode === 'eventType' ? 'eventType' : 'category'}
            keys={['adjustedValue']}
            layout='horizontal'
            margin={{ bottom: 20, left: 130, right: 20, top: 0 }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 12,
                  },
                },
              },
              labels: {
                text: {
                  color: theme.palette.grey[700],
                  fontSize: 12,
                },
              },
              text: {
                fontFamily: 'Inter',
              },
            }}
            tooltip={CustomTooltip}
          />
        </Box>
      ) : (
        <CardContent>
          <EmptyState
            Icon={ChartPie}
            iconColor='primary'
            secondary='Tag events and log event expenses to see the breakdown of spend.'
          />
        </CardContent>
      )}
    </>
  );
};

export default GroupBudgetSpendBreakdown;
