import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  NEW_LIST_ITEM,
  SET_FIELD,
} from 'components/Settings/actions/editSettings';

const SettingsContext = createContext();

const initialState = {
  display_name: null,
  email_extensions_attributes: null,
  email_extensions_ids: null,
  event_types_attributes: null,
  event_types_ids: null,
  favicon_key: null,
  faviconChanged: null,
  group_types_attributes: null,
  group_types_ids: null,
  id: '',
  logo_key: null,
  logoChanged: null,
  offices: null,
  pending_changes: false,
};

const editSettingsReducer = (state, action) => {
  if (!action || !action.type) {
    console.error('Action or action type is not defined:', action);
    return state;
  }

  switch (action.type) {
    case SET_FIELD: {
      const pendingChanges =
        !action.skip && state[action.field] !== action.value;
      return {
        ...state,
        [action.field]: action.value,
        pending_changes: pendingChanges,
      };
    }
    case NEW_LIST_ITEM: {
      const extensionsToAdd = state[action.optionsField]
        ? [...state[action.optionsField]]
        : [];
      const newId = (extensionsToAdd.length + 1) * -1;
      extensionsToAdd.push({ ...action.data, id: newId });
      const extensionIds = state[action.selectionField]
        ? [...state[action.selectionField], newId]
        : [newId];
      return {
        ...state,
        [action.selectionField]: extensionIds,
        [action.optionsField]: extensionsToAdd,
        pending_changes: true,
      };
    }
    case 'POST_ORGANIZATION_SUCCESS': {
      return {
        ...state,
        faviconChanged: false,
        logoChanged: false,
        pending_changes: false,
      };
    }
    case 'DISCARD_CHANGES': {
      return initialState;
    }
    default:
      return state;
  }
};

export const useEditSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useEditSettings must be used within an SettingsProvider');
  }
  return context;
};

export const SettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(editSettingsReducer, initialState);
  return (
    <SettingsContext.Provider value={{ dispatch, state }}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.any,
};
