import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowUpRight } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import NpsChart from 'components/Analytics/NpsChart';
import SurveyQuestionResponses from 'components/Events/Controls/Analytics/SurveyQuestionResponses';
import SurveyQuestionResponsesBarChart from 'components/Events/Controls/Analytics/SurveyQuestionResponsesBarChart';
import SurveyQuestionResponsesDialog from 'components/Events/Controls/Analytics/SurveyQuestionResponsesDialog';
import SurveyQuestionResponsesPieChart from 'components/Events/Controls/Analytics/SurveyQuestionResponsesPieChart';
import { SurveyQuestionTitle } from 'components/Events/Controls/Analytics/SurveyQuestionTitle';
import { SurveyQuestionTypeMap } from 'constants/survey.constants';
import { Gradient } from 'typescript-color-gradient';
import { flexColumn } from 'utils/styles';

export const SurveySummaryQuestion = ({
  displayResponders,
  event,
  individualResponses,
  isPreEvent,
  options,
  position,
  question,
}) => {
  const { title, type } = question;

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const theme = useTheme();

  const handleModalClose = () => {
    setIsDialogOpen(false);
  };

  const typeConfig = useMemo(
    () => (type ? SurveyQuestionTypeMap.get(type) : {}),
    [type]
  );

  const colors = useMemo(() => {
    const colorData = typeConfig?.colors || theme.palette.primary;
    return question?.type?.indexOf('select') !== -1 && options?.length > 0
      ? new Gradient()
          .setGradient(colorData?.light, colorData?.main, colorData?.dark)
          .setNumberOfColors(options?.length)
          .getColors()
      : [];
  }, [
    options?.length,
    question?.type,
    theme.palette.primary,
    typeConfig?.colors,
  ]);

  const optsWithColors = useMemo(
    () =>
      options?.map((opt, idx) => ({
        ...opt,
        ...(colors?.length > 0 ? { color: colors[idx] } : {}),
      })),
    [colors, options]
  );

  const chartProps = useMemo(
    () => ({
      colorConfig: typeConfig?.colors,
      displayResponders,
      individualResponses,
      isPreEvent: question.setting === 'pre',
      key: question.questionId,
      options: optsWithColors,
      question,
    }),
    [
      displayResponders,
      individualResponses,
      optsWithColors,
      question,
      typeConfig?.colors,
    ]
  );

  const titleProps = useMemo(
    () => ({
      individualResponsesLength: individualResponses?.length,
      isPreEvent,
      position,
      title,
      typeConfig,
    }),
    [individualResponses?.length, isPreEvent, position, title, typeConfig]
  );

  return (
    <Card
      sx={{
        ...flexColumn,
        '& .MuiCardHeader-action': {
          alignSelf: 'center',
          m: 0,
        },
        border: 'none',
        borderRadius: '0',
        height: '100%',
        p: 0,
      }}
    >
      <SurveyQuestionTitle {...titleProps} />
      <Box pl={7}>
        {question?.type === 'short' ? (
          <Stack direction='column' gap={1.5}>
            <SurveyQuestionResponses
              displayResponders={displayResponders}
              individualResponses={individualResponses}
              isForSummaryView
              options={options}
              type={type}
            />
          </Stack>
        ) : null}
        {question?.type === 'nps' ? (
          <NpsChart
            events={[event]}
            height={200}
            isSingleEvent
            key={question.questionId}
          />
        ) : null}
        {question?.type === 'multi_select' ? (
          <SurveyQuestionResponsesBarChart {...chartProps} />
        ) : null}
        {question?.type === 'single_select' ? (
          <SurveyQuestionResponsesPieChart {...chartProps} />
        ) : null}
        {individualResponses?.length > 10 ? (
          <>
            <Button
              data-testid='view-all-individual-responses'
              endIcon={<ArrowUpRight size={22} weight='bold' />}
              onClick={() => setIsDialogOpen(true)}
              size='small'
              sx={{ alignSelf: 'flex-start' }}
              variant='text'
            >
              View all individual responses
            </Button>
            <SurveyQuestionResponsesDialog
              displayResponders={displayResponders}
              handleClose={handleModalClose}
              individualResponses={individualResponses}
              options={options}
              question={question}
              show={isDialogOpen}
              titleProps={titleProps}
              type={type}
            />
          </>
        ) : individualResponses?.length > 0 && type !== 'short' ? (
          <Accordion
            elevation={0}
            expanded={isAccordionExpanded}
            onChange={(e, expanded) => setIsAccordionExpanded(expanded)}
            sx={{
              '&.MuiAccordion-root::before': {
                opacity: 0,
              },
            }}
          >
            <AccordionSummary
              aria-label='Expand all individual responses'
              id='view-all-individual-responses-header'
              sx={{
                '&.Mui-expanded': {
                  borderBottomWidth: 0,
                },
                '.MuiAccordionSummary-content': { flexGrow: 0 },
                '.MuiAccordionSummary-expandIconWrapper': {
                  color: 'primary.main',
                },
                color: 'primary.main',
                gap: 1,
                justifyContent: 'flex-start',
              }}
            >
              <Typography fontWeight={500}>
                View individual responses
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack direction='column' gap={1.5}>
                <SurveyQuestionResponses
                  displayResponders={displayResponders}
                  individualResponses={individualResponses}
                  isForSummaryView
                  options={optsWithColors}
                  type={type}
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Box>
    </Card>
  );
};

SurveySummaryQuestion.propTypes = {
  displayResponders: PropTypes.bool,
  event: PropTypes.any,
  individualResponses: PropTypes.any,
  isPreEvent: PropTypes.bool,
  options: PropTypes.array,
  position: PropTypes.number,
  question: PropTypes.object,
};
