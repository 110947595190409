import React, { useContext, useState } from 'react';

import IntegrationCard from 'components/Settings/Integrations/IntegrationCard';
import OktaModal from 'components/Settings/Integrations/OktaModal';
import { OrganizationContext } from 'contexts/OrganizationContext';

const OktaIntegrationCard = () => {
  const [org] = useContext(OrganizationContext);
  const [showingModal, setShowModal] = useState(false);

  return (
    <>
      <IntegrationCard
        description="Integrate your okta application with Five to Nine to provision users via
        Okta and enable a Five to Nine chicklet on your organization's Okta
        home."
        isActive={org?.okta_enabled}
        name='Okta'
        onEdit={() => setShowModal(true)}
        onSetUp={() => setShowModal(true)}
      />
      <OktaModal onHide={() => setShowModal(false)} show={showingModal} />
    </>
  );
};
export default OktaIntegrationCard;
