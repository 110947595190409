import React, { useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CheckCircle, CircleWavyCheck } from '@phosphor-icons/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import CalendarOptions from 'components/Events/Controls/Rsvp/CalendarOptions';
import {
  getRsvpStatusDetails,
  rsvpButtonsSubheader,
  rsvpButtonsTitle,
} from 'components/Events/Controls/Rsvp/Rsvp.constants';
import RsvpButtons from 'components/Events/Controls/Rsvp/RsvpButtons';

const PostRsvpView = ({
  event,
  handleSelection,
  isSoon,
  rsvpIsYesOrMaybe,
  status: statusId,
}) => {
  const theme = useTheme();

  const status = useMemo(() => getRsvpStatusDetails(statusId), [statusId]);

  const statusColor = useMemo(
    () => ({
      dark: theme.palette[status?.colorName]?.dark,
      light: theme.palette[status?.colorName]?.light,
    }),
    [status?.colorName, theme.palette]
  );

  const iconProps = useMemo(
    () => ({
      color: statusColor?.dark,
      size: 28,
      weight: 'fill',
    }),
    [statusColor?.dark]
  );

  const cantChangeRsvp = useMemo(
    () => event?.rsvp_pause_active && statusId !== 'rsvp',
    [event?.rsvp_pause_active, statusId]
  );

  return (
    <>
      {!cantChangeRsvp && (
        <Card
          data-testid='TODO:DATA-CARD-66440'
          sx={{
            backgroundColor: `${statusColor?.light} !important`,
          }}
        >
          <ConditionalLoader conditions={[status === null]}>
            <CardHeader
              avatar={
                rsvpIsYesOrMaybe ? (
                  <CircleWavyCheck {...iconProps} />
                ) : (
                  <CheckCircle {...iconProps} />
                )
              }
              sx={{ '.MuiCardHeader-avatar': { mr: 1.5 }, pb: 0 }}
              title={status?.rsvpHeader}
              titleTypographyProps={{
                color: statusColor?.dark,
                variant: 'h4',
              }}
            />
            <CardContent>
              <Stack direction='column' gap={2}>
                <Typography variant='body1'>{status?.rsvpBody}</Typography>
                <>
                  {rsvpIsYesOrMaybe && (
                    <GoogleOAuthProvider
                      clientId={import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID}
                    >
                      <CalendarOptions
                        color={status?.colorName}
                        event={event}
                      />
                    </GoogleOAuthProvider>
                  )}
                </>
              </Stack>
            </CardContent>
          </ConditionalLoader>
        </Card>
      )}
      <Stack direction='column'>
        <Typography gutterBottom variant='h4'>
          {rsvpButtonsTitle[cantChangeRsvp ? 'paused' : 'default']}
        </Typography>
        <Typography paragraph variant='body1'>
          {rsvpButtonsSubheader[cantChangeRsvp ? 'paused' : 'default']}
        </Typography>
        <RsvpButtons
          areDisabled={cantChangeRsvp}
          handleSelection={handleSelection}
          isSoon={isSoon}
          showLabel={false}
          showMaybeButton={!(event?.rsvp_pause_active && statusId === 'rsvp')}
          statusId={statusId}
        />
      </Stack>
    </>
  );
};

PostRsvpView.propTypes = {
  event: PropTypes.shape({
    location: PropTypes.any,
    meeting: PropTypes.shape({
      link: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  handleSelection: PropTypes.func,
  isSoon: PropTypes.bool,
  rsvpIsYesOrMaybe: PropTypes.bool,
  status: PropTypes.string,
};

export default PostRsvpView;
