import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Trash } from '@phosphor-icons/react';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

const EventExpenseDeleteDialog = ({ isOpen, onCancel, onDelete }: Props) => (
  <Dialog open={isOpen}>
    <DialogTitle>Delete expense</DialogTitle>
    <DialogContent>
      <DialogContentText>
        By proceeding, this expense and any associated details will be deleted
        from your expense tracker for this event. Any costs associated with this
        item will no longer count towards existing event budget or estimated
        costs.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color='neutral' onClick={onCancel} variant='text'>
        Cancel
      </Button>
      <Button
        color='error'
        onClick={onDelete}
        startIcon={<Trash size={24} />}
        variant='contained'
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default EventExpenseDeleteDialog;
