import * as React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { MinusCircle, PlusCircle } from '@phosphor-icons/react';
import pluralize from 'pluralize';

import useDialogState from 'hooks/useDialogState';
import { useOrganization } from 'hooks/useOrganization';
import { ExpenseCategory } from 'types/EventBudget';

type NewExpenseCategory = Omit<ExpenseCategory, 'id'>;

const ExpenseCategoriesForm = () => {
  const { update } = useOrganization();

  const dialogState = useDialogState();

  const { control, formState, handleSubmit, register, reset } = useForm({
    defaultValues: { categories: [{ active: true, name: '' }] },
  });
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'categories',
  });

  const saveCategories = (newCategories: NewExpenseCategory[]) => {
    const count = newCategories.length;
    update.mutate({
      expense_categories_attributes: newCategories,
      successMessage: `${count} expense ${pluralize('category', count)} added!`,
    });
    dialogState.close();
  };

  return (
    <Box>
      <Button
        onClick={() => {
          reset();
          dialogState.open();
        }}
        startIcon={<PlusCircle />}
      >
        Add categories
      </Button>
      <form>
        <Dialog
          aria-labelledby='expense-categories-title'
          fullWidth
          maxWidth='sm'
          onClose={dialogState.close}
          open={dialogState.isOpen}
        >
          <DialogTitle id='expense-categories-title'>
            Add expense categories
          </DialogTitle>
          <DialogContent>
            <Stack alignItems='flex-start' direction='column' gap={0.5}>
              {fields.map((field, index) => (
                <TextField
                  fullWidth
                  InputProps={{
                    endAdornment:
                      fields.length > 1 ? (
                        <IconButton onClick={() => remove(index)} size='small'>
                          <MinusCircle />
                        </IconButton>
                      ) : null,
                  }}
                  key={field.id}
                  placeholder='Expense category name'
                  {...register(`categories.${index}.name`)}
                />
              ))}
              <Button
                onClick={() => append({ active: true, name: '' })}
                startIcon={<PlusCircle />}
              >
                Add category
              </Button>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              onClick={() => {
                dialogState.close();
              }}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              disabled={!formState.isDirty}
              onClick={handleSubmit((data) =>
                saveCategories(
                  data.categories.filter((cat) => cat.name.length > 0)
                )
              )}
              variant='contained'
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </Box>
  );
};

export default ExpenseCategoriesForm;
