import { useQuery } from '@tanstack/react-query';

import { axiosAuthenticated } from 'utils/axios';

interface SyncedSlackChannel {
  id: string;
  name: string;
  private: boolean;
  slack_id: string;
  slack_users: Array<{
    email: string;
    employee: {
      first_name: string;
      last_name: string;
    };
    id: string;
  }>;
  synced_group_id: string | null;
}

const fetchSyncedSlackChannels = (): Promise<SyncedSlackChannel[]> =>
  new Promise((resolve, reject) => {
    axiosAuthenticated(
      { url: '/api/organizations/synced_slack_channels' },
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const useSyncedSlackChannels = () => {
  const query = useQuery<SyncedSlackChannel[], Error>({
    queryFn: fetchSyncedSlackChannels,
    queryKey: ['syncedSlackChannels'],
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    ...query,
    refetchSyncedSlackChannels: query.refetch,
  };
};
