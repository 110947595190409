import React from 'react';
import { Box, Collapse } from '@mui/material';

import EventInPersonLocationField from 'components/Event/EventInPersonLocationField';
import EventLocationTypeButtons from 'components/Event/EventLocationTypeButtons';
import EventVirtualLocationField from 'components/Event/EventVirtualLocationField';
import AutocompletePlaceholder from 'components/shared/AutocompletePlaceholder';
import {
  EventDetailsFormControl,
  EventDetailsFormRegister,
  EventDetailsFormSetValue,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';

interface EventLocationTypeFieldProps {
  control: EventDetailsFormControl;
  disableFields: boolean;
  eventId?: number | null;
  isLoading?: boolean;
  meetingCreator: {
    creatorId?: number;
    creatorName?: string;
    isSaved: boolean;
  };
  register: EventDetailsFormRegister;
  setValue: EventDetailsFormSetValue;
  watch: EventDetailsFormWatch;
}

const EventLocationTypeField = ({
  control,
  disableFields,
  eventId = null,
  isLoading = false,
  meetingCreator: { creatorId, creatorName, isSaved },
  register,
  setValue,
  watch,
}: EventLocationTypeFieldProps) => {
  const locationType = watch('locationType');

  return (
    <Box>
      {!disableFields && (
        <EventLocationTypeButtons
          clearEventLocation={() => setValue('location', '')}
          clearEventMeeting={() =>
            setValue('meeting', {
              attendanceTracking: false,
              link: null,
              service: null,
              serviceUid: null,
            })
          }
          control={control}
          disableFields={disableFields || isLoading}
        />
      )}
      <Collapse
        in={locationType !== undefined}
        sx={{
          '.MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          },
        }}
        unmountOnExit
      >
        <Collapse
          in={['virtual', 'hybrid'].includes(locationType || '')}
          unmountOnExit
        >
          <Box pt={disableFields ? 0 : 1.5}>
            <EventVirtualLocationField
              control={control}
              creatorId={creatorId}
              creatorName={creatorName}
              disableFields={disableFields}
              eventId={eventId}
              hasSavedMeeting={isSaved}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </Box>
        </Collapse>
        <Collapse
          in={['inperson', 'hybrid'].includes(locationType || '')}
          unmountOnExit
        >
          <Box pt={disableFields ? 0 : 1.5}>
            <EventInPersonLocationField
              disableFields={disableFields}
              register={register}
            />
          </Box>
        </Collapse>
      </Collapse>
      {!['virtual', 'hybrid', 'inperson'].includes(locationType || '') &&
        disableFields && <AutocompletePlaceholder items={[]} />}
    </Box>
  );
};

export default EventLocationTypeField;
