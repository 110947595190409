import React, { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { Star } from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';

import ProfileIntegrationServices from 'components/Profile/ProfileIntegrationServices';
import { Integration } from 'types/Integration';

interface Props {
  canConnectSecondaryService?: boolean;
  canDisconnect?: boolean;
  isConnected: boolean;
  isDisconnecting?: boolean;
  onButtonClick: (action?: any) => unknown;
  service: Integration;
}

const ProfileIntegrationCard = ({
  canConnectSecondaryService,
  canDisconnect,
  isConnected,
  isDisconnecting = false,
  onButtonClick,
  service,
}: Props) => {
  const orgAllowsZoomWebinar = useFlag('zoom-webinar');

  const buttonLabel = useMemo(
    () =>
      isConnected
        ? isDisconnecting
          ? 'Disconnecting...'
          : 'Disconnect'
        : 'Connect',
    [isDisconnecting, isConnected]
  );

  const isZoomService = useMemo(
    () => service?.id === 'zoom_meeting' || service?.id === 'zoom_webinar',
    [service]
  );

  return (
    <Card data-testid='TODO:DATA-CARD-99875'>
      <CardHeader
        action={
          <Chip
            color={isConnected ? 'success' : 'default'}
            label={isConnected ? 'Connected' : 'Not connected'}
            sx={{ height: 32 }}
            variant={isConnected ? 'light' : 'filled'}
          />
        }
        avatar={service?.logoAvatar}
        sx={{
          '& .MuiAvatar-root': {
            height: 32,
            width: 32,
          },
        }}
      />
      <CardContent sx={{ pt: 0 }}>
        <Stack direction='column' gap={0.5}>
          <Typography variant='h4'>
            {isZoomService ? 'Zoom' : service?.name}
          </Typography>
          <Typography variant='body1'>
            {service?.authCardSubtitle &&
              service?.authCardSubtitle[
                isConnected
                  ? service?.name === 'Zoom Webinar'
                    ? orgAllowsZoomWebinar
                      ? 'connected'
                      : 'connected_alternative'
                    : 'connected'
                  : 'connect'
              ]}
          </Typography>
          {orgAllowsZoomWebinar && (
            <ProfileIntegrationServices
              conditional={Boolean(
                isConnected && service?.profileCardNames?.length
              )}
              items={service.profileCardNames}
            />
          )}
        </Stack>
      </CardContent>
      {canConnectSecondaryService && (
        <CardActions>
          <Button
            color='primary'
            data-testid={`connect-${service?.id}`}
            fullWidth
            onClick={() => onButtonClick('connect')}
            variant='contained'
          >
            {`${service?.secondaryAction}`}
          </Button>
        </CardActions>
      )}
      {(!isConnected || canDisconnect) && (
        <CardActions>
          <LoadingButton
            data-testid={`${isConnected ? '' : 'dis'}connect-${service?.id}`}
            fullWidth
            loading={canDisconnect && isDisconnecting}
            loadingPosition='start'
            onClick={() =>
              onButtonClick(isConnected ? 'disconnect' : 'connect')
            }
            size='small'
            variant={isConnected ? 'outlined' : 'contained'}
            {...(isDisconnecting
              ? {
                  startIcon: <Star />,
                }
              : {})}
          >
            {buttonLabel}
          </LoadingButton>
        </CardActions>
      )}
    </Card>
  );
};
export default ProfileIntegrationCard;
