import React from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  SxProps,
  Typography,
} from '@mui/material';

const CircularProgressWithLabel = ({
  isPlaceholder,
  isRounded = false,
  label,
  prefix = '',
  suffix = '',
  thickness = 3.6,
  value,
  variant,
  wrapperProps,
  ...props
}: CircularProgressProps & {
  isPlaceholder?: boolean;
  isRounded?: boolean;
  label?: number | string; // displayed in center (if set)
  prefix?: string;
  suffix?: string;
  value?: number; // used in CircularProgress, displayed in center (if no label set)
  wrapperProps?: SxProps;
}) => {
  const displayedValue = React.useMemo(() => {
    const val = label || value;
    const roundedVal = isRounded ? Math.round(Number(val)) : val;
    return [prefix, roundedVal, suffix].join('');
  }, [isRounded, label, prefix, suffix, value]);

  return (
    <Box sx={{ display: 'inline-flex', position: 'relative', ...wrapperProps }}>
      <Box
        sx={{
          alignItems: 'center',
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <Typography color='grey.700' component='div' variant='caption'>
          {displayedValue}
        </Typography>
      </Box>
      <CircularProgress
        color={isPlaceholder ? 'neutral' : props.color}
        thickness={thickness}
        value={isPlaceholder ? 100 : value}
        variant={variant || 'determinate'}
        {...(isPlaceholder && { sx: { opacity: 0.1 } })}
        {...props}
      />
    </Box>
  );
};

export default CircularProgressWithLabel;
