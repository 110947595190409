import { useCallback } from 'react';
import { useTheme } from '@mui/material';

export const useMuiPaletteColor = () => {
  const theme = useTheme();

  const paletteHasColor = useCallback(
    (name: string) => Object.keys(theme?.palette)?.includes(name),
    [theme]
  );

  const getPaletteColor = useCallback(
    (name: string) => theme?.palette?.[name as keyof typeof theme.palette],
    [theme]
  );

  const getValidColorName: any = useCallback(
    ({
      colorName,
      fallbackColorName,
    }: {
      colorName: string;
      fallbackColor?: string;
      fallbackColorName?: string;
    }) =>
      paletteHasColor(colorName)
        ? colorName
        : fallbackColorName && paletteHasColor(fallbackColorName)
        ? fallbackColorName
        : 'primary',
    [paletteHasColor]
  );

  const getColor: any = useCallback(
    ({
      colorName,
      fallbackColor,
      fallbackColorName,
    }: {
      colorName: string;
      fallbackColor?: string;
      fallbackColorName?: string;
    }) =>
      paletteHasColor(colorName)
        ? getPaletteColor(colorName)
        : fallbackColorName && paletteHasColor(fallbackColorName)
        ? getPaletteColor(fallbackColorName)
        : fallbackColor || theme.palette.primary.main,
    [getPaletteColor, paletteHasColor, theme.palette.primary.main]
  );

  return { getColor, getValidColorName };
};
