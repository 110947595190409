import React, { useContext } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { X } from '@phosphor-icons/react';

import SearchStartAdornment from 'components/Core/SearchStartAdornment';
import { LayoutContext } from 'contexts/LayoutContext';

interface SearchProps {
  fullWidth?: boolean;
  label: string;
  mb?: number;
  mt?: number;
  onChange: React.EventHandler<any>;
  placeholder: string;
  value: string;
}

const Search = ({
  fullWidth = false,
  label,
  mb = 0,
  mt = 0,
  onChange,
  placeholder,
  value,
}: SearchProps) => {
  const { isMobile } = useContext(LayoutContext);

  return (
    <TextField
      aria-describedby={placeholder}
      aria-label={label}
      fullWidth={isMobile ? true : fullWidth}
      helperText={null}
      id={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='clear search'
              defaultValue=''
              edge='end'
              onClick={() => onChange({ target: { value: '' } })}
              size='small'
              sx={{ visibility: value ? 'visible' : 'hidden' }}
            >
              <X />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: <SearchStartAdornment />,
      }}
      onChange={onChange}
      placeholder={placeholder}
      role='search'
      sx={{
        'input[type="search" i]::-webkit-search-cancel-button': { margin: 0 },
        maxWidth: '100%',
        mb,
        minWidth: 200,
        mt,
      }}
      value={value}
    />
  );
};

export default Search;
