import React from 'react';
import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';

interface Props {
  text?: string;
}

export const ProfileIntegrationService = ({ text }: Props) => {
  const theme = useTheme();
  return (
    <ListItem disableGutters disablePadding>
      <ListItemIcon sx={{ minWidth: 32 }}>
        <CheckCircle color={theme.palette.success.main} />
      </ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{ variant: 'body1' }}
      />
    </ListItem>
  );
};
