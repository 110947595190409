import * as React from 'react';
import {
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  styled,
  SxProps,
  TypographyProps,
} from '@mui/material';
import { Icon as PhosphorIcon, IconProps } from '@phosphor-icons/react';

const IconWrapper = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette['grey'][900],
  flexDirection: 'column',
  height: 24,
  justifyContent: 'center',
  minWidth: 20,
}));

interface Props {
  Icon?: PhosphorIcon;
  iconProps?: IconProps;
  iconWrapperProps?: ListItemIconProps;
  primary: string;
  primaryTypographyProps?:
    | TypographyProps<
        'span',
        {
          component?: 'span' | undefined;
        }
      >
    | undefined;
  secondary?: string;
  textProps?: SxProps;
}

const TextWithIcon = ({
  Icon,
  iconProps,
  iconWrapperProps,
  primary,
  primaryTypographyProps = { variant: 'body2' },
  secondary,
  textProps,
}: Props) => (
  <>
    {Icon && (
      <IconWrapper {...iconWrapperProps}>
        <Icon size={20} {...iconProps} />
      </IconWrapper>
    )}
    <ListItemText
      primary={primary}
      primaryTypographyProps={primaryTypographyProps}
      secondary={secondary || undefined}
      sx={{ m: 0, ...textProps }}
    />
  </>
);

export default TextWithIcon;
