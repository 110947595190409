import React from 'react';
import { Grid } from '@mui/material';

const Maintenance = () => {
  return (
    <>
      <div>
        <img alt='' src='/images/banner.svg' />
      </div>
      <div className='lost-frame has-primary-background is-white'>
        <Grid className='lost-row' container>
          <Grid className='msg-col' item lg={6}>
            <div className='msg-wrapper title is-semibold'>
              <div>
                <p className='title is-medium'>Five to Nine is</p>
                <h1 className='title'>in maintenance mode</h1>
              </div>
              <p className='title is-medium'>
                Please check back in a few hours or reach out to
                info@fivetonine.co for support
              </p>
            </div>
          </Grid>
          <Grid className='graphic-col' item lg={6}>
            <img alt='' src='/images/ladder.svg' />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Maintenance;
