import React, { useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Avatar } from '@mui/material';
import { OktaAuth } from '@okta/okta-auth-js';
import PropTypes from 'prop-types';

import { PublicOrgContext } from 'contexts/PublicOrgContext';

const OktaLoginButton = () => {
  const [loading, setLoading] = useState(false);
  const [org] = useContext(PublicOrgContext);
  return (
    <LoadingButton
      data-testid='okta'
      fullWidth
      loading={loading}
      onClick={() => {
        const okta = new OktaAuth({
          clientId: org.okta_client_id,
          issuer: org.okta_issuer,
          pkce: false,
          redirectUri: window.location.origin + '/auth/okta',
          responseType: 'code',
          state: org.id,
        });
        okta
          .signInWithRedirect()
          .then(() => {
            setLoading(true);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      startIcon={
        <Avatar
          data-testid='TODO:DATA-AVATAR-12633'
          src='/images/okta-logo.svg'
          sx={{ height: 24, width: 24 }}
        />
      }
      variant='bordered'
    >
      Sign in with Okta
    </LoadingButton>
  );
};
OktaLoginButton.propTypes = {
  loading: PropTypes.bool,
};

export default OktaLoginButton;
