import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { reactQueryAxios } from 'utils/axios';

const apiPost = async ({
  apiClient,
  communicationId,
}: {
  apiClient: any;
  communicationId: number;
}) =>
  apiClient({
    data: { id: communicationId },
    method: 'post',
    timeout: 30000,
    url: `/api/communication_histories/send_now`,
  });

export const useCommunicationSendNow = ({
  dataHandler = () => undefined,
  eventId,
}: {
  dataHandler?: () => void;
  eventId: number;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (communicationId: number) =>
      apiPost({ apiClient: reactQueryAxios, communicationId }),

    onError: (error: Error) => {
      enqueueSnackbar('Something went wrong! Please try again', {
        variant: 'error',
      });
      Sentry.captureException(error);
    },

    onSuccess: () => {
      enqueueSnackbar('Communication sent!', {
        variant: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['event', Number(eventId), 'communications'],
      });
      dataHandler();
    },
  });
};
