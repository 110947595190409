import React from 'react';
import { alpha, styled, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import TabLabelWithCount from 'components/shared/TabLabelWithCount';

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== 'withSeparators',
})(({ theme, withSeparators }) => ({
  '& .MuiTab-root': {
    '& .MuiBadge-colorPrimary': {
      transition: 'all 300ms ease-in-out',
    },
    '&.Mui-selected': {
      '& .MuiBadge-colorPrimary': {
        backgroundColor: alpha(theme.palette.common.white, 0.2),
      },
      color: theme.palette.common.white,
    },
    '&:hover:not(.Mui-selected)': {
      color: theme.palette.primary.main,
    },
    color: theme.palette.grey[700],
    flex: '1 1 auto',
    minHeight: 40,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    transition: 'all 300ms ease-in-out',
    zIndex: 2,
  },
  '.MuiTabs-flexContainer': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 6,
    flexDirection: 'row',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
  },
  '.MuiTabs-indicator': {
    borderRadius: 6,
    bottom: theme.spacing(1),
    height: 'auto',
    left: theme.spacing(1),
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
  },
  borderBottom: 'none',
  ...(withSeparators
    ? {
        '.MuiTab-root:not(:last-of-type)': {
          '&::after': {
            backgroundColor: alpha(theme.palette.grey[700], 0.2),
            bottom: theme.spacing(1),
            content: '""',
            position: 'absolute',
            right: -4,
            top: theme.spacing(1),
            width: 1,
          },
          overflow: 'visible',
        },
      }
    : {}),
}));

const ButtonTabs = ({
  onChange,
  tabs,
  type,
  value = 0,
  withCount = true,
  withSeparators = false,
}) => (
  <StyledTabs
    aria-label={`button-tabs-${type}`}
    onChange={(e, val) => onChange(val)}
    value={value}
    withSeparators={withSeparators}
  >
    {tabs?.map(({ key, label, labelCount }) => (
      <Tab
        data-testid={`${label}-tab`}
        key={key || label}
        label={
          withCount ? (
            <TabLabelWithCount count={labelCount} label={label} />
          ) : (
            label
          )
        }
      />
    ))}
  </StyledTabs>
);

ButtonTabs.propTypes = {
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      labelCount: PropTypes.number,
    })
  ).isRequired,
  type: PropTypes.string,
  value: PropTypes.number,
  withCount: PropTypes.bool,
  withSeparators: PropTypes.bool,
};

export default ButtonTabs;
