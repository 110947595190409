import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Checkbox, FormGroup, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import InfoTooltip from 'components/shared/InfoTooltip';

const Dropdown = ({
  allowCreate,
  attributesField,
  dataProp,
  field,
  helperText = null,
  isMulti,
  label,
  newer,
  options,
  placeholder,
  requireOne = false,
  selectedOption,
  setter,
  suggestions,
  sx = {},
  tooltip,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [displayedOptions, setDisplayedOptions] = useState([]);
  useEffect(() => {
    setDisplayedOptions([
      ...options,
      ...(suggestions?.length > 0
        ? suggestions.filter(
            (s) =>
              !selectedOption?.find((opt) => opt?.label === s) &&
              !options?.find((opt) => opt?.label === s)
          )
        : []),
    ]);
  }, [options, selectedOption, suggestions]);

  const onChange = (event, newSelections = []) => {
    if (requireOne && newSelections.length === 0) {
      enqueueSnackbar('At least one item must be selected', {
        variant: 'error',
      });
      return;
    }
    const newValue =
      typeof newSelections === 'string'
        ? newSelections
        : newSelections.find((s) => typeof s === 'string');
    const changedOpt = isMulti
      ? newSelections?.find((s) => s?.value === newValue?.value) ||
        selectedOption?.find(
          (o) => newSelections?.findIndex((s) => s?.value === o?.value) === -1
        )
      : newSelections;
    const isDeselecting = newSelections?.length < selectedOption?.length;

    if (isMulti && isDeselecting && changedOpt?.uses && changedOpt?.uses > 0) {
      enqueueSnackbar(
        `We can't remove the "${
          changedOpt?.label
        }" type — it's currently applied to ${changedOpt?.uses} ${label
          ?.toLowerCase()
          .replace(' type', '')}`,
        { variant: 'error' }
      );
      return;
    }

    if (allowCreate && typeof newValue === 'string') {
      createOption(newValue);
    } else {
      const value = isMulti
        ? newSelections
          ? newSelections?.map((s) => s?.value || s)
          : []
        : newSelections
        ? newSelections?.value
        : null;

      setter(field, value);
    }
  };

  const handleSingleChange = (e, val) => {
    if (val && val?.value) {
      setter(field, val?.value);
    } else {
      setter(field, null);
    }
  };

  const createOption = (newSelected) => {
    newer(attributesField, field, { [dataProp]: newSelected });
  };

  // Need to ensure remove button is hidden on the last option, if only one option remains
  if (requireOne && selectedOption?.length === 1) {
    selectedOption[0].isFixed = true;
  }

  const inputProps = useMemo(
    () => ({
      label: tooltip ? <InfoTooltip content={tooltip} text={label} /> : label,
    }),
    [label, tooltip]
  );

  const sharedProps = useMemo(
    () => ({
      fullWidth: true,
      id: `${label}-select`,
      options: displayedOptions,
      renderInput: (params) => (
        <TextField
          helperText={helperText}
          placeholder={isMulti && selectedOption?.length > 0 ? '' : placeholder}
          {...params}
          {...inputProps}
          data-testid="TODO:DATA-TEXTFIELD-93692" />
      ),
      renderOption: (props, option, { selected }) => {
        return (
          (<li {...props}>
            {isMulti && <Checkbox checked={selected} data-testid="TODO:DATA-CHECKBOX-23246" />}
            {option?.label || option}
          </li>)
        );
      },
      required: requireOne,
      value: selectedOption,
    }),
    [
      displayedOptions,
      helperText,
      inputProps,
      isMulti,
      label,
      placeholder,
      requireOne,
      selectedOption,
    ]
  );

  return isMulti ? (
    <FormGroup sx={{ width: '100%' }}>
      <Autocomplete
        {...sharedProps}
        clearOnBlur
        disableClearable={requireOne || allowCreate}
        disableCloseOnSelect
        freeSolo={allowCreate}
        multiple
        onChange={onChange}
        selectOnFocus={allowCreate}
        sx={sx}
        data-testid="TODO:DATA-AUTOCOMPLETE-16565" />
    </FormGroup>
  ) : (
    <Autocomplete
      {...sharedProps}
      label={inputProps.label}
      multiple={false}
      onChange={handleSingleChange}
      placeholder={placeholder}
    />
  );
};

Dropdown.propTypes = {
  allowCreate: PropTypes.any,
  allowSuggestion: PropTypes.any,
  attributesField: PropTypes.any,
  className: PropTypes.string,
  components: PropTypes.any,
  dataProp: PropTypes.any,
  field: PropTypes.any,
  helperText: PropTypes.string,
  id: PropTypes.any,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  newer: PropTypes.func,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  requireOne: PropTypes.bool,
  selectedOption: PropTypes.any,
  setter: PropTypes.func,
  styles: PropTypes.any,
  suggestions: PropTypes.any,
  sx: PropTypes.any,
  tooltip: PropTypes.any,
};

export default Dropdown;
