import React from 'react';
import { Avatar } from '@mui/material';

import { Integration, IntegrationId } from 'types/Integration';

export const integrations: Integration[] = [
  {
    authCardSubtitle: {
      connect: 'Connect your Google Calendar to sync Five to Nine events.',
      connected: 'Your Google Calendar is synced.',
    },
    authService: 'Google Calendar',
    id: 'google_calendar' as IntegrationId,
    logo: '/images/google-cal.svg',
    name: 'Google Calendar',
  },
  {
    authService: 'Google Calendar',
    id: 'google_meet' as IntegrationId,
    isMeetingService: true,
    joinButton: 'Join with Google Meet',
    logo: '/images/google-meet.svg',
    name: 'Google Meet',
  },
  {
    authCardSubtitle: {
      connect: 'Connect to generate Zoom meeting links for your events.',
      connected: 'You can generate Zoom meeting links.',
    },
    authService: 'Zoom',
    id: 'zoom_meeting' as IntegrationId,
    isMeetingService: true,
    joinButton: 'Join Zoom meeting',
    logo: '/images/zoom-circle.svg',
    meetingLabel: 'Zoom meeting',
    name: 'Zoom',
    profileCardNames: ['Zoom Meetings'],
    secondaryAction: 'Enable Zoom Webinar',
  },
  {
    authCardSubtitle: {
      connected: 'You can generate Zoom meeting links and webinars.',
      connected_alternative: 'You can generate Zoom meeting links.',
    },
    authService: 'Zoom',
    id: 'zoom_webinar' as IntegrationId,
    isMeetingService: true,
    joinButton: 'Join as panelist',
    joinUrlHidden: true,
    logo: '/images/zoom-circle.svg',
    meetingLabel: 'Zoom webinar',
    name: 'Zoom Webinar',
    profileCardNames: ['Zoom Meetings', 'Zoom Webinars'],
  },
].map((service) => ({
  ...service,
  authService: service?.authService || service?.name,
  label: service?.meetingLabel || service?.name,
  logoAvatar: (
    <Avatar
      data-testid={`service-logo-${service.id}`}
      src={service?.logo}
      sx={{ height: 20, width: 20 }}
      variant='square'
    />
  ),
}));

export const integrationsByIdMap = new Map(
  [...integrations].map((i) => [i.id, i])
);

export const knownMeetingServices: Integration[] = integrations.filter(
  ({ isMeetingService }) => isMeetingService
);

const customMeeting: Integration = {
  id: 'unknown' as IntegrationId,
  joinButton: 'Join meeting',
  label: 'Custom meeting',
  name: 'Custom',
};

export const allMeetingServices: Integration[] = [
  ...knownMeetingServices,
  customMeeting,
];
