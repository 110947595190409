import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import {
  UPDATE_ORGANIZATION_BEGIN,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_SUCCESS,
} from 'components/Settings/actions/organizations';
import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import {
  OtherBlue,
  OtherGreen,
  OtherOrange,
  OtherPink,
  OtherPurple,
  OtherYellow,
} from 'utils/colors';

export const OrganizationContext = createContext();

const organizationReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ORGANIZATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        current: action.payload.organization,
        loading: false,
      };
    case UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const OrganizationProvider = (props) => {
  // We are going to load data immediately, so let's keep from flip floppiing the ConditionalLoaders until we are done with the initial load
  const [eventTypeColors, setEventTypeColors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [org, setOrg] = useState();

  const [state, dispatch] = useReducer(organizationReducer, {
    current: null,
    error: false,
    loading: false,
  });

  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);

  const colorOptions = useMemo(() => {
    const eventTypeColorObjects = [
      OtherYellow,
      OtherOrange,
      OtherPink,
      OtherPurple,
      OtherBlue,
      OtherGreen,
    ];
    return [
      ...eventTypeColorObjects.map((c) => c.main),
      ...eventTypeColorObjects.map((c) => c.light),
      ...eventTypeColorObjects.map((c) => c.dark),
    ];
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      axios(
        { url: `/api/organizations/current` },
        (res) => {
          const org = res.data;
          setOrg(org);
          Sentry.setTag('organization', org.id);
          if (org?.event_types?.length > 0) {
            const colorMap = Object.fromEntries(
              org?.event_types
                ?.sort((a, b) => a.name?.localeCompare(b.name))
                ?.map((type, index) => [type.id, colorOptions[index]])
            );
            setEventTypeColors(colorMap);
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.log(error);
        }
      );
    } else {
      setIsLoading(false);
    }
  }, [colorOptions, isAuthenticated]);

  return (
    <OrganizationContext.Provider
      value={[org, setOrg, isLoading, state, dispatch, eventTypeColors]}
    >
      {props.children}
    </OrganizationContext.Provider>
  );
};

OrganizationProvider.propTypes = {
  children: PropTypes.any,
};
