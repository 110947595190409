import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { NotePencil } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { trim } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import ImageInput from 'components/Core/ImageInput';
import PasswordModal from 'components/Profile/PasswordModal';
import ProfileCardTwoFactor from 'components/Profile/ProfileCardTwoFactorAuthentication';
import { EmployeesContext } from 'contexts/EmployeesContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const EditProfileModal = ({ setShow, setUser, show, user }) => {
  const { getAllEmployees, setCurrentPage } = useContext(EmployeesContext);
  const [organization] = useContext(OrganizationContext);

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [hasChangedAvatar, setHasChangedAvatar] = useState(false);
  const [image, setImage] = useState('');
  const [imageKey, setImageKey] = useState('');
  const [isPasswording, setIsPasswording] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [lastName, setLastName] = useState('');
  const [officeId, setOfficeId] = useState('');
  const [timezone, setTimezone] = useState({});

  const { data: avatarData, refetch: refetchAvatar } = useQuery({
    queryKey: ['avatar', user?.employee_id],
  });

  useEffect(() => {
    if (avatarData?.avatar?.key) {
      setImageKey(avatarData?.avatar?.key);
    }
  }, [avatarData]);

  useEffect(() => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setTimezone(user.timezone);
    if (user.office?.id) {
      setOfficeId(user.office.id);
    }
  }, [user]);

  const officeOptions = useMemo(
    () =>
      organization?.offices.map((o) => ({
        id: o.id,
        label: o.name,
      })),
    [organization?.offices]
  );

  const enforceOktaLogin = useMemo(
    () => organization?.okta_enabled && organization?.okta_saml_url?.length > 0,
    [organization]
  );

  const onDrop = (files) => {
    if (files && files.length > 0) {
      setImage(files[0]);
    } else if (files === undefined) {
      setImage('');
      setImageKey('');
    }
    setHasChangedAvatar(true);
  };

  const onHide = () => {
    setShow(false);
  };

  const setIfExists = (str, prop, fd) => {
    if (trim(str).length > 0) {
      fd.append(`user[${prop}]`, str);
    }
  };

  const onUpdate = () => {
    setIsWorking(true);
    const data = new FormData();
    setIfExists(firstName, 'first_name', data);
    setIfExists(lastName, 'last_name', data);
    setIfExists(email, 'email', data);
    setIfExists(officeId, 'office_id', data);
    setIfExists(timezone.value, 'timezone', data);
    if (trim(image?.name).length > 0) {
      data.append('user[avatar]', image);
    }
    data.append('user[profileImageChanged]', `${hasChangedAvatar}`);

    axios(
      {
        data,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
        method: 'POST',
        url: '/api/users/update_profile',
      },
      (res) => {
        setCurrentPage(1);
        setUser(res.data.user);
        getAllEmployees({ apiClient: axios, page: 1 });
        enqueueSnackbar('Your profile was updated', {
          variant: 'success',
        });
        onHide();
        refetchAvatar();
        setIsWorking(false);
      },
      () => {
        enqueueSnackbar("Profile couldn't be saved, please try again", {
          variant: 'error',
        });
        setIsWorking(false);
      }
    );
  };

  if (!show) {
    return null;
  }

  return (
    <Dialog aria-label='Edit' onClose={onHide} open={show}>
      <DialogTitle>Edit profile info</DialogTitle>
      <DialogContent>
        <Grid columnSpacing={2} container>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id='edit-first-name'
              label='First name'
              onChange={(e) => setFirstName(e.target.value)}
              placeholder='Add first name'
              value={firstName}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id='edit-last-name'
              label='Last name'
              onChange={(e) => setLastName(e.target.value)}
              placeholder='Add last name'
              value={lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Email'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Add email'
              required
              type='email'
              value={email}
            />
          </Grid>
          {!enforceOktaLogin && (
            <Grid item xs={12}>
              <ProfileCardTwoFactor setUser={setUser} user={user} />
            </Grid>
          )}
          <Grid item xs={12}>
            <ImageInput
              id='profile-picture'
              image={image}
              imageKey={imageKey}
              label='Upload profile picture'
              onDrop={onDrop}
              previewMaxWidth={200}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='office-select-label'>Office</InputLabel>
              <Select
                data-testid='select-profile-office'
                fullWidth
                label='Office'
                labelId='office-select-label'
                onChange={(e) => {
                  setOfficeId(e.target.value);
                }}
                placeholder='Select an office'
                value={officeId}
              >
                {officeOptions.map((o) => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {!enforceOktaLogin && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Password'
                placeholder='***********'
                value=''
              />
              <Button
                data-testid='update-password'
                onClick={() => setIsPasswording(true)}
                size='small'
                startIcon={<NotePencil size={20} />}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  justifyContent: 'start',
                }}
                variant='text'
              >
                Update password
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button data-testid='cancel' onClick={onHide} variant='text'>
          Cancel
        </Button>
        <Button
          data-testid='changes'
          onClick={onUpdate}
          startIcon={
            isWorking ? <CircularProgress color='inherit' size={16} /> : null
          }
          variant='contained'
        >
          {isWorking ? 'Saving' : 'Save'} changes
        </Button>
      </DialogActions>
      <PasswordModal setShow={setIsPasswording} show={isPasswording} />
    </Dialog>
  );
};

EditProfileModal.propTypes = {
  setShow: PropTypes.func,
  setUser: PropTypes.func,
  show: PropTypes.any,
  user: PropTypes.shape({
    avatar: PropTypes.shape({
      key: PropTypes.any,
    }),
    email: PropTypes.string,
    employee_id: PropTypes.number,
    first_name: PropTypes.any,
    last_name: PropTypes.any,
    office: PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string,
    }),
    timezone: PropTypes.any,
  }),
};
export default EditProfileModal;
