import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { axiosAuthenticated as axios } from 'utils/axios';

interface Props {
  initialUrl?: string;
}

const useZoomAuth = ({ initialUrl }: Props) => {
  const {
    userProvider: { setUser, user },
  } = useContext(AuthContext) as AuthContextType;
  // const history = useHistory();
  const [finalUrl, setFinalUrl] = useLocalStorage('zoomSetUp', initialUrl);
  const { enqueueSnackbar } = useSnackbar();
  const orgAllowsZoomWebinar = useFlag('zoom-webinar');

  const clientId = import.meta.env.VITE_ZOOM_CLIENT_ID;
  const url = import.meta.env.VITE_ZOOM_URL;

  const isLocalEnv = window.location.toString().includes('localhost');

  const subdomain = isLocalEnv ? null : location.host?.split('.')[0];
  const callback = isLocalEnv ? null : url?.split('.').slice(-2).join('.');
  const zoomUrl = isLocalEnv ? url : `https://${subdomain}.${callback}`;

  const isMeetingConnected = useMemo(() => user?.has_zoom_meeting_auth, [user]);

  const isWebinarConnected = useMemo(
    () => user?.has_zoom_webinar_license && user?.has_zoom_webinar_auth,
    [user]
  );

  const canEnableZoomWebinar = useMemo(
    () =>
      orgAllowsZoomWebinar &&
      (user?.has_zoom_webinar_license === null ||
        user?.has_zoom_webinar_license),
    [orgAllowsZoomWebinar, user]
  );

  const redirectAfterAuth = useCallback(() => {
    window.location.assign(finalUrl);
    setFinalUrl(null);
  }, [finalUrl, setFinalUrl]);

  const connect = useCallback(() => {
    setFinalUrl(window.location.href);
    const url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${zoomUrl}`;
    window.location.assign(url);
  }, [clientId, setFinalUrl, zoomUrl]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    if (code) {
      axios(
        {
          data: {
            code,
            redirect_uri: zoomUrl,
          },
          method: 'post',
          mode: 'CORS',
          url: '/api/zoom/install',
        } as any,
        (res: AxiosResponse) => {
          setUser(res.data.user);
          enqueueSnackbar('Zoom account connected!', {
            variant: 'success',
          });
          redirectAfterAuth();
        },
        () => redirectAfterAuth()
      );
    }
  }, [
    enqueueSnackbar,
    redirectAfterAuth,
    setUser,
    user?.has_zoom_meeting_auth,
    user?.has_zoom_webinar_auth,
    zoomUrl,
  ]);

  const disconnect = useCallback(() => {
    if (!finalUrl) {
      setFinalUrl(window.location.href);
    }
    axios(
      {
        method: 'post',
        url: '/api/zoom/deauthorize',
      },
      (res: AxiosResponse) => {
        enqueueSnackbar('Zoom account disconnected.', {
          variant: 'success',
        });
        setUser(res.data);
      },
      (err: AxiosError) => console.error(err)
    );
  }, [enqueueSnackbar, finalUrl, setFinalUrl, setUser]);

  return {
    canEnableZoomWebinar,
    connectToZoom: connect,
    disconnectZoom: disconnect,
    isZoomMeetingConnected: isMeetingConnected,
    isZoomWebinarConnected: isWebinarConnected,
    redirectAfterZoomAuth: redirectAfterAuth,
    setFinalUrl,
  };
};

export default useZoomAuth;
