import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';

import { ConditionalLoader } from 'components/Core/Loader';
import SlackCheckList from 'components/Core/SlackCheckList';
import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import SlackPrivateChannelTip from 'components/Settings/Integrations/Slack/SlackPrivateChannelTip';
import SlackUnsyncDialog from 'components/Settings/Integrations/Slack/SlackUnsyncDialog';
import Buttons from 'components/shared/Buttons';
import RadioLabel from 'components/shared/RadioLabel';
import SlackChannelLabel from 'components/shared/SlackChannelLabel';
import { GroupsContext } from 'contexts/GroupsContext';
import { SlackChannelsContext } from 'contexts/SlackChannelsContext';
import { useSyncables, useUpdateSyncables } from 'hooks/useSyncables';
import { useSyncedSlackChannels } from 'hooks/useSyncedSlackChannels';
import { fullName } from 'utils/employee';
import { flexRow } from 'utils/styles';

const SyncedSlackChannels = () => {
  const {
    currentPage,
    fetchSlackChannels,
    isFetching: isFetchingSlackChannels,
    isPending: isSlackChannelsLoading,
    searchTerm,
    setCurrentPage,
    setSearchTerm,
    slackChannels,
    totalCount,
    totalPages,
  } = useContext(SlackChannelsContext);

  const { data: syncedChannels, isLoading: isSyncedChannelsLoading } =
    useSyncedSlackChannels();

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isSyncablesLoading } = useSyncables();
  const updateSyncablesMutation = useUpdateSyncables();

  const [, , , loadGroups] = useContext(GroupsContext);
  const [activeFilter, setActiveFilter] = useState('All');
  const [expandedChannel, setExpandedChannel] = useState('');
  const [isCanceling, setIsCanceling] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showingUnsyncDialog, setShowingUnsyncDialog] = useState(false);
  const [selectedChannelIds, setSelectedChannelIds] = useState(
    syncedChannels?.map((ch) => ch.id)
  );
  const [wasUpdated, setWasUpdated] = useState(false);

  useEffect(() => {
    if (isEditing && !isSyncedChannelsLoading) {
      setSelectedChannelIds(syncedChannels?.map((ch) => ch.id));
    }
  }, [isEditing, syncedChannels, isSyncedChannelsLoading]);

  const startEditing = async () => {
    // No need to request syncables explicitly, React Query will handle it
    setIsEditing(true);
  };

  const stopEditing = () => {
    setSearchTerm('');
    setActiveFilter('All');
    setIsEditing(false);
    setIsCanceling(false);
    setWasUpdated(false);
  };

  const cancelEditing = () => {
    if (wasUpdated) {
      setIsCanceling(true);
    } else {
      stopEditing();
    }
    setSelectedChannelIds(syncedChannels?.map((ch) => ch.id));
  };

  const groupSyncedChannelsBeingRemoved = useMemo(
    () =>
      syncedChannels
        ?.filter(
          (ch) =>
            !selectedChannelIds?.includes(ch.id) && ch.synced_group_id != null
        )
        .map((ch) => ({ name: ch.name, private: ch.private })),
    [selectedChannelIds, syncedChannels]
  );

  const checkSyncedGroupRemoval = (selectedChannelIds) => {
    const deslectedChannels = syncedChannels?.filter(
      (ch) => !selectedChannelIds?.includes(ch.id)
    );
    return (
      deslectedChannels?.filter((ch) => ch.synced_group_id != null)?.length > 0
    );
  };

  const updateSyncedChannels = async () => {
    if (checkSyncedGroupRemoval(selectedChannelIds)) {
      setShowingUnsyncDialog(true);
    } else {
      setIsSaving(true);
      try {
        await updateSyncablesMutation.mutateAsync(
          { ids: selectedChannelIds, type: 'slack_channels' },
          {
            onError: (error) => {
              console.error('Error updating syncables:', error);
              enqueueSnackbar(
                `We were unable to update the syncables. ${error.message}`,
                { variant: 'error' }
              );
            },
            onSuccess: () => {
              fetchSlackChannels();
              stopEditing();
              enqueueSnackbar(
                'Changes saved! Your selected Slack channels are now being synced.',
                { variant: 'success' }
              );
            },
          }
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  const acceptUnsyncDialog = async () => {
    setIsSaving(true);
    try {
      await updateSyncablesMutation.mutateAsync(
        { ids: selectedChannelIds, type: 'slack_channels' },
        {
          onError: (error) => {
            console.error('Error updating syncables:', error);
            enqueueSnackbar(
              `We were unable to update the syncables. ${error.message}`,
              { variant: 'error' }
            );
          },
          onSuccess: () => {
            loadGroups();
            fetchSlackChannels();
            stopEditing();
            enqueueSnackbar(
              'Changes saved! Your selected Slack channels are now being synced.',
              { variant: 'success' }
            );
          },
        }
      );
    } finally {
      setIsSaving(false);
      setShowingUnsyncDialog(false);
    }
  };

  const toggleExpanded = (slackId) => (e, isExpanded) => {
    setExpandedChannel(isExpanded ? slackId : '');
  };

  const handleToggle = (selectedIds) => {
    setSelectedChannelIds(selectedIds);
  };

  const filteredChannels = useMemo(() => {
    if (slackChannels) {
      return slackChannels
        ?.filter((ch) => {
          const isSelected = selectedChannelIds?.indexOf(ch.id) !== -1;
          switch (activeFilter) {
            case 'Selected':
              return isSelected;
            case 'Unselected':
              return !isSelected;
            default:
              return true;
          }
        })
        ?.filter((ch) => ch.syncables.length || isEditing);
    }
  }, [activeFilter, slackChannels, isEditing, selectedChannelIds]);

  return (
    <Stack spacing={1}>
      <SlackPrivateChannelTip />
      <Card data-testid='TODO:DATA-CARD-75367'>
        <CardHeader
          action={
            <Buttons
              buttons={
                isEditing
                  ? [
                      {
                        disabled: isSaving,
                        onClick: () => cancelEditing(),
                        text: 'Cancel',
                        variant: 'text',
                      },
                      {
                        color: 'primary',
                        disabled:
                          isSlackChannelsLoading ||
                          isFetchingSlackChannels ||
                          isSaving,
                        onClick: () => updateSyncedChannels(),
                        startIcon: isSaving ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null,
                        text: isSaving ? 'Saving...' : 'Save changes',
                        variant: 'contained',
                      },
                    ]
                  : [
                      {
                        color: 'primary',
                        disabled:
                          isSlackChannelsLoading || isFetchingSlackChannels,
                        onClick: () => startEditing(),
                        text: 'Edit channels',
                        variant: 'contained',
                      },
                    ]
              }
            />
          }
          subheader={
            isEditing ? (
              <Typography variant='body1'>
                Select the Slack channels you wish to sync for sending invites
                and/or creating Groups.
              </Typography>
            ) : (
              <Typography variant='body1'>
                There {pluralize('is', syncedChannels?.length)}{' '}
                {isSyncedChannelsLoading ? (
                  <CircularProgress color='primary' size={16} />
                ) : (
                  <Typography
                    color='primary'
                    fontWeight={700}
                    sx={{ display: 'inline' }}
                  >
                    {syncedChannels?.length}
                  </Typography>
                )}{' '}
                synced Slack {pluralize('channel', syncedChannels?.length)}.
              </Typography>
            )
          }
          title={`${isEditing ? 'All' : 'Synced'} Slack Channels`}
        />
        <>
          {isEditing ? (
            <CardContent>
              <ConditionalLoader
                conditions={[
                  isSlackChannelsLoading,
                  isSyncedChannelsLoading,
                  isSyncablesLoading,
                ]}
              >
                <Stack alignItems='center' direction='row' gap={2}>
                  <FormLabel>Show:</FormLabel>
                  <ToggleButtonGroup
                    exclusive
                    onChange={(ev, val) => setActiveFilter(val)}
                    value={activeFilter}
                  >
                    {['All', 'Selected', 'Unselected'].map((val) => (
                      <ToggleButton
                        data-testid='TODO:DATA-TOGGLEBUTTON-18051'
                        key={val}
                        value={val}
                      >
                        {val}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
                <SlackCheckList
                  currentPage={currentPage}
                  hasSearch
                  hasSelectAll
                  loading={isFetchingSlackChannels}
                  onChange={handleToggle}
                  onPageChange={setCurrentPage}
                  onSearchChange={(newValue) => {
                    if (newValue !== searchTerm) {
                      setSearchTerm(newValue);
                    }
                  }}
                  options={filteredChannels}
                  searchTerm={searchTerm}
                  totalCount={totalCount}
                  totalPage={totalPages}
                  value={selectedChannelIds}
                />
                <SlackUnsyncDialog
                  channels={groupSyncedChannelsBeingRemoved}
                  onClose={() => setShowingUnsyncDialog(false)}
                  onUnsync={async (onDone) => {
                    await acceptUnsyncDialog();
                    setShowingUnsyncDialog(false);
                    onDone();
                  }}
                  show={showingUnsyncDialog}
                />
              </ConditionalLoader>
            </CardContent>
          ) : (
            <ConditionalLoader
              conditions={[isSyncedChannelsLoading, isSlackChannelsLoading]}
            >
              {syncedChannels?.map(
                ({
                  name,
                  private: isPrivate,
                  slack_id: slackId,
                  slack_users: members,
                }) => (
                  <Accordion
                    data-testid='TODO:DATA-ACCORDION-32576'
                    expanded={expandedChannel === slackId}
                    key={name}
                    onChange={toggleExpanded(slackId)}
                    sx={
                      expandedChannel === slackId
                        ? { bgcolor: 'grey.100' }
                        : null
                    }
                    variant='square'
                  >
                    <AccordionSummary id={`${name}-panel-header`}>
                      <SlackChannelLabel
                        isBold={expandedChannel === slackId}
                        isPrivate={isPrivate}
                        name={name}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <List
                        data-testid='TODO:DATA-LIST-96056'
                        dense
                        disablePadding
                      >
                        {members
                          ?.sort((a, b) =>
                            a?.employee?.first_name?.localeCompare(
                              b?.employee?.first_name
                            )
                          )
                          ?.map(({ email, employee, id }) => (
                            <ListItem key={id}>
                              <ListItemText
                                primary={
                                  <RadioLabel
                                    primary={fullName(employee)}
                                    secondary={email}
                                  />
                                }
                              />
                            </ListItem>
                          ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
              {isSyncablesLoading && (
                <CardContent sx={{ ...flexRow, justifyContent: 'center' }}>
                  <CircularProgress />
                </CardContent>
              )}
            </ConditionalLoader>
          )}
          <UnsavedChangesModal
            onCancel={() => setIsCanceling(false)}
            onConfirm={stopEditing}
            tryClose={isCanceling}
          />
        </>
      </Card>
    </Stack>
  );
};

export default SyncedSlackChannels;
