import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';

interface Props {
  hasCount?: boolean;
  items?: any[];
}

const AutocompletePlaceholder = ({ hasCount = true, items }: Props) => {
  const theme = useTheme();
  return (
    <>
      {items && items?.length > 0 ? (
        <Stack alignItems='center' direction='row' gap={1}>
          <Typography color='success.main' whiteSpace='nowrap'>
            {hasCount ? `${items?.length} added` : 'Added'}
          </Typography>
          <CheckCircle
            color={theme.palette.success.main}
            size={20}
            weight='fill'
          />
        </Stack>
      ) : (
        <Typography color='grey.500' fontStyle='italic'>
          None added
        </Typography>
      )}
    </>
  );
};

export default AutocompletePlaceholder;
