import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import * as geopattern from 'geopattern';
import PropTypes from 'prop-types';

const PlaceholderImage = ({ itemName }) => {
  const theme = useTheme();

  const imageUrl = useMemo(
    () =>
      geopattern
        .generate(itemName, {
          color: theme.palette.grey[300],
          baseColor: theme.palette.primary.main,
        })
        .toDataUrl(),
    [itemName, theme.palette.grey, theme.palette.primary.main]
  );

  return (
    <Box
      height='auto'
      minWidth='100%'
      sx={{
        aspectRatio: '16 / 9',
        backgroundImage: imageUrl,
        overflow: 'hidden',
      }}
      width='100%'
    />
  );
};

PlaceholderImage.propTypes = {
  itemName: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PlaceholderImage;
