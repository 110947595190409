import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';

const AuthOktaSaml = () => {
  const [params] = useSearchParams();

  const newToken = params.get('token');

  if (newToken) {
    localStorage.setItem('token', newToken);
    // navigate('/events'); // Just push the path without hash part
    return <Navigate to='/events' />;
  } else {
    return (
      <Typography>
        Something went wrong, please contact your Administrator if the problem
        continues.
      </Typography>
    );
  }
};

export default AuthOktaSaml;
