import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { reactQueryAxios } from 'utils/axios';

const deleteExpenseItem = async ({
  apiClient,
  expenseItemId,
}: {
  apiClient: any;
  expenseItemId: number;
}) =>
  apiClient({
    method: 'delete',
    url: `/api/expense_items/${expenseItemId}`,
  });

export const useExpenseDelete = ({
  dataHandler = () => undefined,
  eventId,
}: {
  dataHandler?: () => void;
  eventId: number;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (expenseItemId: number) =>
      deleteExpenseItem({ apiClient: reactQueryAxios, expenseItemId }),

    onError: (error: Error) => {
      enqueueSnackbar('Something went wrong! Please try again', {
        variant: 'error',
      });
      Sentry.captureException(error);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['event', eventId, 'budget'],
      });
      dataHandler();
    },
  });
};
