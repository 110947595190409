/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { createCloudinaryLegacyURL } from '@cloudinary/url-gen';
import {
  Button,
  FormControl,
  FormLabel,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { Image } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { isImageFileType } from 'utils/valid_file';

const getBorderStyles = ({ color, gapColor }) =>
  `repeating-linear-gradient(0deg, ${color}, ${color} 8px, ${gapColor} 8px, ${gapColor} 16px, ${color} 16px), repeating-linear-gradient(90deg, ${color}, ${color} 8px, ${gapColor} 8px, ${gapColor} 16px, ${color} 16px), repeating-linear-gradient(180deg, ${color}, ${color} 8px, ${gapColor} 8px, ${gapColor} 16px, ${color} 16px), repeating-linear-gradient(270deg, ${color}, ${color} 8px, ${gapColor} 8px, ${gapColor} 16px, ${color} 16px)`;

const UploadContainer = styled('div')(({ isReadyToDrop, theme }) => {
  const borderColors = {
    color: isReadyToDrop ? theme.palette.primary.main : theme.palette.grey[500],
    gapColor: isReadyToDrop ? theme.palette.primary.main : 'transparent',
  };
  return {
    alignItems: 'center',
    backgroundColor: isReadyToDrop
      ? theme.palette.primary.light
      : theme.palette.grey[50],
    backgroundImage: getBorderStyles(borderColors),
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
    borderRadius: 1,
    color: theme.palette.grey[600],
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    outline: 'none',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    transition: 'all 0.24s ease-in-out',
  };
});

/**
 * Renders <ImageInput /> component
 * @param {object} props
 * @param {{name:string}} props.image image to show in input
 * @param {string} props.imageKey imageKey to show if using cloudinary
 * @param {Function} props.onDrop drag and drop callback
 * @param {string} props.label label to show alongside the input field
 * @param {string} props.id css id to be used for the input label to be associated with input
 * @param {{width: number, height: number}} props.sizeRecommendation recommended width and height of the image the user will supply (default: 100x100)
 */
const ImageInput = ({
  id,
  image,
  imageKey,
  isDisabled,
  label,
  onDrop,
  onRemove,
  previewMaxHeight,
  previewMaxWidth,
  sizeRecommendation,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const name = image?.name || imageKey;
  const styleProps = {
    style: {
      alignSelf: 'flex-start',
      maxHeight: previewMaxHeight || '400px',
      maxWidth: previewMaxWidth || '100%',
      objectFit: 'contain',
    },
  };
  const [imageUrl, setImageUrl] = useState(null);
  const [isReadyToDrop, setIsReadyToDrop] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { getInputProps, getRootProps, isDragAccept, isDragReject, isFocused } =
    useDropzone({
      accept: { 'image/*': [] },
      disabled: isDisabled,
      onDragEnter: () => setIsReadyToDrop(true),
      onDragLeave: () => setIsReadyToDrop(false),
      onDrop: (files) => {
        setIsReadyToDrop(false);
        if (files.length === 0 || isImageFileType(files[0])) {
          setIsRemoving(false);
          onDrop(files);
        } else if (!isImageFileType(files[0])) {
          enqueueSnackbar('You must upload an image file.', {
            variant: 'error',
          });
        }
      },
    });

  const theme = useTheme();
  const [hasAnImage, setHasAnImage] = useState(
    image?.name?.length > 0 || imageKey?.length > 0
  );

  useEffect(() => {
    const hasImage = image?.name?.length > 0 || imageKey?.length > 0;
    if (hasImage !== hasAnImage) {
      setHasAnImage(hasImage);
    }
  }, [hasAnImage, image?.name?.length, imageKey?.length]);

  useEffect(() => {
    if (image?.name) {
      setImageUrl(URL.createObjectURL(image));
    } else if (imageKey) {
      setImageUrl(
        createCloudinaryLegacyURL(imageKey, {
          cloud_name: 'five-to-nine',
          public_id: imageKey,
          secure: process.env.NODE_ENV !== 'development',
        })
      );
    } else {
      setImageUrl('');
    }
  }, [image, image?.name, imageKey]);

  const handleRemove = () => {
    setIsRemoving(true);
    if (!onRemove) {
      onDrop();
    } else {
      onRemove();
    }
  };

  return (
    <FormControl disabled={isDisabled} fullWidth>
      <Stack alignItems='center' direction='row' justifyContent='space-between'>
        <FormLabel
          htmlFor={id}
          sx={name && !isRemoving ? { pb: '0 !important' } : {}}
        >
          {label}
          {sizeRecommendation && (
            <Typography
              color='text.secondary'
              display='block'
              variant='overline'
            >
              We recommend an image that is {sizeRecommendation?.width} by{' '}
              {sizeRecommendation?.height} pixels
            </Typography>
          )}
        </FormLabel>
        {name && !isRemoving && (
          <Button
            data-testid='remove'
            disabled={isDisabled}
            onClick={() => handleRemove()}
            size='small'
          >
            Remove
          </Button>
        )}
      </Stack>
      {hasAnImage && !isRemoving ? (
        <img alt={label} src={imageUrl} {...styleProps} />
      ) : (
        <UploadContainer
          {...getRootProps(isFocused, isDragAccept, isDragReject)}
          isReadyToDrop={isDisabled ? null : isReadyToDrop}
        >
          <input {...getInputProps()} />
          <Stack alignItems='center' direction='column' gap={1}>
            <Image color={theme.palette.grey[600]} size={32} />
            <Typography
              color='text.secondary'
              gutterBottom
              textAlign='center'
              variant='body1'
            >
              Drop an image here or browse your files
            </Typography>
            <Button
              data-testid='browse-files'
              disabled={isDisabled}
              size='small'
              variant='bordered'
            >
              Browse files
            </Button>
          </Stack>
        </UploadContainer>
      )}
    </FormControl>
  );
};

ImageInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ]),
  imageKey: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onDrop: PropTypes.func,
  onRemove: PropTypes.func,
  previewMaxHeight: PropTypes.number,
  previewMaxWidth: PropTypes.number,
  sizeRecommendation: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
};

export default ImageInput;
