import React, { useContext, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Palette,
  PaletteColor,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CalendarCheck,
  CheckCircle,
  ClockCountdown,
  Icon,
  MapPin,
  Question,
  UserCirclePlus,
  VideoCamera,
  XCircle,
} from '@phosphor-icons/react';
import pluralize from 'pluralize';

import InviteMetric from 'components/Events/Controls/Analytics/InviteMetric';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { useEventGuestCounts } from 'hooks/useEventGuestCounts';

interface Metric {
  color?: string | undefined;
  Icon: Icon;
  isSmall?: boolean;
  label: string;
  value: number;
}

interface MetricSection {
  data: Array<Metric | Metric[]>;
  minItemWidth: number;
  title: string;
}

const EventGuestsOverview = () => {
  const {
    state: { event },
  } = useContext(ManagerContext);
  const { isLarge } = useContext(LayoutContext);

  const { data } = useEventGuestCounts({ eventId: event?.id });

  const theme = useTheme();

  const metrics: MetricSection[] = useMemo(
    () => [
      {
        data: [
          {
            color: 'info',
            Icon: UserCirclePlus,
            label: 'Invited',
            value: data?.guestlistOverview?.statusCounts?.invited || 0,
          },
          {
            color: 'success',
            Icon: CheckCircle,
            label: 'Yes',
            value: data?.guestlistOverview?.statusCounts?.rsvp || 0,
          },
          {
            color: 'warning',
            Icon: Question,
            label: 'Maybe',
            value: data?.guestlistOverview?.statusCounts?.maybeAttending || 0,
          },
          {
            color: 'error',
            Icon: XCircle,
            label: 'No',
            value: data?.guestlistOverview?.statusCounts?.notAttending || 0,
          },
          {
            color: 'neutral',
            Icon: ClockCountdown,
            label: 'Scheduled',
            value: data?.guestlistOverview?.statusCounts?.scheduled || 0,
          },
        ],
        minItemWidth: 120,
        title: 'Invitation summary',
      },
      {
        data: [
          {
            color: 'success',
            Icon: CalendarCheck,
            label: 'Attended',
            value: data?.guestlistOverview?.attendedCounts?.yes || 0,
          },
          [
            {
              color: 'success',
              Icon: VideoCamera,
              isSmall: true,
              label: `Virtual ${pluralize(
                'attendance',
                data?.guestlistOverview?.attendedAttendanceCounts?.virtual
              )}`,
              value:
                data?.guestlistOverview?.attendedAttendanceCounts?.virtual || 0,
            },
            {
              color: 'success',
              Icon: MapPin,
              isSmall: true,
              label: `In-person ${pluralize(
                'attendance',
                data?.guestlistOverview?.attendedAttendanceCounts?.inPerson
              )}`,
              value:
                data?.guestlistOverview?.attendedAttendanceCounts?.inPerson ||
                0,
            },
          ],
        ],
        minItemWidth: 136,
        title: 'Attendance summary',
      },
    ],
    [
      data?.guestlistOverview?.statusCounts?.invited,
      data?.guestlistOverview?.statusCounts?.rsvp,
      data?.guestlistOverview?.statusCounts?.maybeAttending,
      data?.guestlistOverview?.statusCounts?.notAttending,
      data?.guestlistOverview?.statusCounts?.scheduled,
      data?.guestlistOverview?.attendedCounts?.yes,
      data?.guestlistOverview?.attendedAttendanceCounts?.virtual,
      data?.guestlistOverview?.attendedAttendanceCounts?.inPerson,
    ]
  );

  const defaultColor: PaletteColor = {
    contrastText: theme.palette.text.primary,
    dark: theme.palette.grey[900],
    light: theme.palette.grey[200],
    main: theme.palette.grey[700],
  };

  return (
    <Card data-testid='guest-list-overview'>
      <CardContent sx={{ p: 3 }}>
        <Stack
          alignItems='stretch'
          direction={{ lg: 'row', xs: 'column' }}
          gap={4}
          justifyContent={{ lg: 'space-between', xs: 'flex-start' }}
        >
          <>
            {metrics.map(({ data, minItemWidth, title }, index) => (
              <React.Fragment key={title}>
                {index > 0 && isLarge ? (
                  <Box>
                    <Divider orientation='vertical' />
                  </Box>
                ) : null}
                <Stack direction='column' flex='auto' gap={1.5} key={title}>
                  <Typography component='h3' variant='body2'>
                    {title}
                  </Typography>
                  <Stack
                    columnGap={1}
                    direction='row'
                    flexWrap='wrap'
                    justifyContent={{ lg: 'space-between', xs: 'flex-start' }}
                    rowGap={2}
                  >
                    {data?.map((metric: any) => {
                      const { Icon, label, value } = metric;
                      if (Array.isArray(metric)) {
                        return (
                          <Grid
                            container
                            direction='column'
                            flexWrap='nowrap'
                            item
                            key={`${label}-grid1`}
                            spacing={1}
                            xs={6}
                          >
                            {metric.map((submetric) => (
                              <Grid item key={submetric?.label} xs={6}>
                                <InviteMetric {...submetric} />
                              </Grid>
                            ))}
                          </Grid>
                        );
                      } else {
                        const themeColor: PaletteColor =
                          (metric?.color
                            ? (theme.palette[
                                metric?.color as keyof Palette
                              ] as PaletteColor)
                            : defaultColor) || defaultColor;
                        return (
                          <Stack
                            direction='column'
                            gap={1}
                            key={label}
                            minWidth={minItemWidth}
                          >
                            <Stack
                              alignItems='center'
                              direction='row'
                              gap={0.5}
                            >
                              <Icon
                                {...(themeColor?.main &&
                                themeColor?.main?.length > 0
                                  ? { color: themeColor?.main }
                                  : {})}
                                size={20}
                              />
                              <Typography variant='body1' whiteSpace='nowrap'>
                                {label}
                              </Typography>
                            </Stack>
                            <Typography
                              component='p'
                              lineHeight={1.2}
                              variant='h2'
                            >
                              {value}
                            </Typography>
                          </Stack>
                        );
                      }
                    })}
                  </Stack>
                </Stack>
              </React.Fragment>
            ))}
          </>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default EventGuestsOverview;
