import React from 'react';
import { ListItem, Stack, Unstable_Grid2 } from '@mui/material';
import { Icon } from '@phosphor-icons/react';

import TextWithIcon from 'components/shared/TextWithIcon';

interface FormSectionProps {
  description?: string;
  fields: React.JSX.Element;
  Icon: Icon;
  title: string;
}

const FormSection = ({
  description,
  fields,
  Icon,
  title,
}: FormSectionProps) => {
  const Grid = Unstable_Grid2;
  return (
    <Grid
      container
      flexDirection='row'
      justifyContent='stretch'
      spacing={5}
      width='100%'
    >
      <Grid lg={5} md={4} sm={12}>
        <ListItem
          disableGutters
          disablePadding
          sx={{
            alignItems: 'flex-start',
            gap: 1,
            justifyContent: 'flex-start',
          }}
        >
          <TextWithIcon Icon={Icon} primary={title} secondary={description} />
        </ListItem>
      </Grid>
      <Grid
        lg={7}
        md={8}
        sm={12}
        sx={{
          '& .MuiFormControl-root': {
            m: 0,
          },
        }}
      >
        <Stack direction='column' gap={2} width='100%'>
          {fields}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FormSection;
