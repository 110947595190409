import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  capitalize,
  Collapse,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { getRichTextStyling } from 'components/shared/RichTextStyling';

interface RTDisplayProps {
  noun?: string;
  props?: any;
  sx?: object;
  text: string;
  truncateLong?: boolean;
}

const RichTextDisplay: React.FC<RTDisplayProps> = ({
  noun = 'Description',
  sx = {
    fontFamily: 'sans-serif',
  },
  text,
  truncateLong = false,
  ...props
}) => {
  const maxPreviewHeight = 100;
  const previewRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const theme = useTheme();

  return (
    <Box
      minHeight={maxPreviewHeight}
      sx={getRichTextStyling(theme)}
      width='100%'
    >
      {truncateLong ? (
        <Box minHeight={maxPreviewHeight} width='100%'>
          <Collapse
            collapsedSize={`${maxPreviewHeight}px`}
            easing='1000ms'
            in={isExpanded}
            orientation='vertical'
            // unmountOnExit
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: text || '',
              }}
              {...props}
              ref={previewRef}
              sx={{
                '& p, h1, h2, h3, ol, ul': {
                  '&:first-child': {
                    mt: 0,
                  },
                },
                ...sx,
              }}
              width='100%'
            />
          </Collapse>
          <Divider>
            <Stack
              alignItems='center'
              columnGap={1}
              direction={{ sm: 'row', xs: 'column' }}
              width='100%'
            >
              <Typography
                color='text.secondary'
                component='div'
                display='inline-block'
                variant='overline'
              >
                {`${capitalize(noun)} truncated`}
              </Typography>
              <Button
                data-testid='show'
                onClick={() => setIsExpanded(!isExpanded)}
                size='small'
                sx={{
                  display: 'inline',
                  fontSize: theme.typography.overline.fontSize,
                }}
              >
                {`Show ${isExpanded ? 'less' : 'more'}`}
              </Button>
            </Stack>
          </Divider>
        </Box>
      ) : (
        <Box
          dangerouslySetInnerHTML={{
            __html: text || '',
          }}
          {...props}
          ref={previewRef}
          sx={{
            '& p, h1, h2, h3, ol, ul': {
              '&:first-child': {
                mt: 0,
              },
            },
            ...sx,
          }}
          width='100%'
        />
      )}
    </Box>
  );
};

export default RichTextDisplay;
