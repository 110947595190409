import React, { useCallback, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import PropTypes from 'prop-types';

const CheckboxesGroup = ({
  controlId,
  label,
  labelId,
  onChange,
  options,
  readonly,
  required,
}) => {
  const [state, setState] = useState(
    Object.fromEntries(options?.map((o) => [o.text, false]))
  );

  const getSelectedIdsFromState = useCallback(
    (stateVal) =>
      Object.entries(stateVal)
        .map(([name, checked]) => {
          if (checked) {
            const selection = options.find((o) => o.text === name);
            return selection?.id;
          } else {
            return null;
          }
        })
        .filter((val) => val !== null),
    [options]
  );

  const handleChange = (event) => {
    const newStateValue = {
      ...state,
      [event.target.name]: event.target.checked,
    };
    setState(newStateValue);
    onChange(getSelectedIdsFromState(newStateValue));
  };

  return (
    (<FormControl id={controlId} required={required}>
      <FormLabel id={labelId}>{label}</FormLabel>
      <FormGroup aria-labelledby={labelId}>
        {options.map((option) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={state[option.id]}
                name={option.text}
                onChange={(event) => {
                  readonly ? event.preventDefault() : handleChange(event);
                }}
                data-testid="TODO:DATA-CHECKBOX-40652" />
            }
            key={option.id}
            label={option.text}
          />
        ))}
      </FormGroup>
    </FormControl>)
  );
};

CheckboxesGroup.propTypes = {
  controlId: PropTypes.any,
  label: PropTypes.string,
  labelId: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.any.isRequired,
  readonly: PropTypes.any,
  required: PropTypes.bool,
};

export default CheckboxesGroup;
