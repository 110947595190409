import React, { useContext, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { Check, DownloadSimple, GoogleLogo } from '@phosphor-icons/react';
import { useGoogleLogin } from '@react-oauth/google';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import sanitize from 'sanitize-filename';

import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { GoogleCalendarScopes } from 'utils/google';

const CalendarOptions = ({ color, event }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const [addToGCal, setAddToGCal] = useState(false);
  const [addToICal, setAddToICal] = useState(false);
  const [isAddingToGcal, setIsAddingToGcal] = useState(false);
  const [isConnectingGcal, setIsConnectingGcal] = useState(false);
  const [isDownloadingICal, setIsDownloadingICal] = useState(false);

  const handleGoogleResponse = (response) => {
    setIsAddingToGcal(false);
    if (response === 'success') {
      setAddToGCal(true);
      enqueueSnackbar('Event added to your Google Calendar!', {
        variant: 'success',
      });
    } else if (response === 'fail') {
      enqueueSnackbar(
        'We were unable to add this event to your calendar. Please try again later!',
        { variant: 'error' }
      );
    }
  };

  const handleChange = (value) => {
    if (value === 'gCal') {
      setIsAddingToGcal(true);
      axios(
        { url: `/api/events/${event?.id}/gcal` },
        () => {
          handleGoogleResponse('success');
        },
        () => {
          handleGoogleResponse('fail');
        }
      );
    } else if (value === 'iCal') {
      setIsDownloadingICal(true);
      axios(
        {
          method: 'get',
          responseType: 'blob',
          url: `/api/events/${event?.id}/ical`,
        },
        (res) => {
          setIsDownloadingICal(false);
          setAddToICal(true);
          fileDownload(res.data, sanitize(event.name + '.ics'));
          enqueueSnackbar(
            'Open the downloaded file to add this event to your Outlook calendar.',
            { variant: 'success' }
          );
        },
        () => {
          setIsDownloadingICal(false);
          enqueueSnackbar(
            'We were unable to add the event to your calendar. Please try again later!',
            { variant: 'error' }
          );
        }
      );
    }
  };

  const connectCalendar = useGoogleLogin({
    flow: 'auth-code',
    onFailure: (response) => {
      setIsConnectingGcal(false);
      enqueueSnackbar(
        'We were unable to connect to your Google account. Please try again later!',
        {
          variant: 'error',
        }
      );
    },
    onSuccess: (response) => {
      setIsConnectingGcal(true);
      axios(
        {
          data: { code: response.code, redirect_uri: window.location.origin },
          method: 'POST',
          mode: 'CORS',
          url: `/api/events/${event?.id}/gcal_auth`,
        },
        () => {
          setIsConnectingGcal(false);
          handleGoogleResponse('success');
        },
        () => {
          setIsConnectingGcal(false);
          handleGoogleResponse('fail');
        }
      );
    },
    prompt: 'select_account',
    scope: GoogleCalendarScopes,
  });

  const isGoogleAuthorized = useMemo(
    () => user && user.has_google_calendar_auth,
    [user]
  );

  return (
    <Stack alignItems='stretch' direction='column' gap={1.5} width='100%'>
      <LoadingButton
        color={color}
        data-testid='add'
        endIcon={addToGCal && <Check />}
        fullWidth
        loading={isAddingToGcal || isConnectingGcal}
        onClick={() =>
          isGoogleAuthorized ? handleChange('gCal') : connectCalendar()
        }
        startIcon={<GoogleLogo />}
        variant='outlined-dark'
      >
        Add{addToGCal && 'ed'} to Google Calendar
      </LoadingButton>
      <LoadingButton
        color={color}
        data-testid='download'
        endIcon={addToICal && <Check />}
        fullWidth
        loading={isDownloadingICal}
        onClick={() => handleChange('iCal')}
        startIcon={<DownloadSimple />}
        variant='outlined-dark'
      >
        Download{addToICal && 'ed'} iCal file
      </LoadingButton>
    </Stack>
  );
};

CalendarOptions.propTypes = {
  color: PropTypes.string,
  event: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
  }),
};

export default CalendarOptions;
