import React, { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { ConditionalLoader } from 'components/Core/Loader';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { EventAnalytics } from 'components/Events/Steps/EventAnalytics';

const AnalyticsStep = () => {
  const {
    actions: { requestAnalyticsData },
  } = useContext(ManagerContext);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    requestAnalyticsData(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <Stack direction='column' spacing={3}>
      <ConditionalLoader condition={[isLoading]}>
        <EventAnalytics />
      </ConditionalLoader>
    </Stack>
  );
};
export default AnalyticsStep;
