import React, { useContext, useEffect, useMemo } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';

const SurveySelector = () => {
  const {
    actions: { applySingle },
    state: { event, savedEvent, surveyType },
  } = useContext(ManagerContext);

  const options = useMemo(
    () => [
      ...(savedEvent?.pre_event_survey?.id || event?.pre_event_survey?.id
        ? [{ label: 'Pre-event survey', value: 'pre_event_survey' }]
        : []),
      ...(savedEvent?.post_event_survey?.id || event?.post_event_survey?.id
        ? [{ label: 'Post-event survey', value: 'post_event_survey' }]
        : []),
    ],
    [
      savedEvent?.pre_event_survey?.id,
      savedEvent?.post_event_survey?.id,
      event?.pre_event_survey?.id,
      event?.post_event_survey?.id,
    ]
  );

  useEffect(() => {
    if (options?.length > 0 && !surveyType?.value) {
      applySingle(options[0], 'surveyType');
    }
  }, [options, surveyType, applySingle]);

  return (
    <ToggleButtonGroup
      color='neutral'
      exclusive
      onChange={(e, v) => {
        applySingle(
          options.find((o) => o.value === v),
          'surveyType'
        );
      }}
      sx={{ bgcolor: 'white' }}
      value={surveyType.value}
    >
      {options.map((opt) => (
        <ToggleButton
          data-testid='TODO:DATA-TOGGLEBUTTON-30366'
          key={opt.value}
          value={opt.value}
        >
          {opt.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

SurveySelector.propTypes = {
  setType: PropTypes.func,
  type: PropTypes.any,
};

export default SurveySelector;
