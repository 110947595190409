import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import Search from 'components/Core/Search';
import EventBudgetCurrencyField from 'components/Events/Controls/Budget/EventBudgetCurrencyField';
import SendCommunicationDialogTransition from 'components/Events/Controls/Communications/SendCommunicationDialogTransition';
import CurrencyConversionDialog from 'components/Settings/CurrencyConversionDialog';
import CurrencySelect from 'components/shared/CurrencySelect';
import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import Filters from 'components/shared/Filters';
import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import { AuthContext } from 'contexts/AuthContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { useGroupsBudgets } from 'hooks/useGroupsBudget';
import { useOrganization } from 'hooks/useOrganization';
import Currency from 'types/Currency';
import { Group } from 'types/Group';

const BudgetsFormDialog = ({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) => {
  const dateFormat = 'MMM D, YYYY';
  const {
    retrieve: { data: organization },
  } = useOrganization();
  const [groups] = useContext(GroupsContext);

  const [groupsWithEvents, setGroupsWithEvents] = useState<any[]>([]);

  const {
    userProvider: {
      user: { events },
    },
  } = useContext(AuthContext) as any;

  const { rowsWithIndex: groupsBudgetData, update } = useGroupsBudgets();

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      groupsBudgets: groupsBudgetData,
    },
    values: {
      groupsBudgets: groupsBudgetData,
    },
  });

  const { isDirty } = formState;

  const [conversionRates, setConversionRates] = useState<{
    [groupId: number]: number;
  }>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [showLinkedEventsDialog, setShowLinkedEventsDialog] = useState(false);
  const [groupsWithDisabledCurrency, setGroupsWithDisabledCurrency] = useState<
    number[]
  >([]);

  useEffect(() => {
    const eventHasMultipleGroups = (eventId: number) => {
      const event = events.find((e: any) => e.id === eventId);
      return event && event.group_ids.length > 1;
    };
    const groupsWithDisabledCurrency = groups
      ?.filter((group: any) =>
        group.event_ids.some((eventId: number) =>
          eventHasMultipleGroups(eventId)
        )
      )
      ?.map((group: any) => group.id);

    setGroupsWithDisabledCurrency(groupsWithDisabledCurrency);
  }, [groups, events]);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchTerm(event.target.value);
  };

  const searchGroupName = useCallback(
    (group: Group, searchTerm: string) =>
      group?.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
    []
  );

  const typeFilter = useCallback(
    (selectedTypes: string[], group: { group_type_id: any }) =>
      selectedTypes.length === 0 || selectedTypes.includes(group.group_type_id),
    []
  );

  const columns = [
    {
      field: 'name',
      flex: 4,
      headerName: 'Group name',
      minWidth: 300,
    },
    {
      field: 'budget',
      flex: 2,
      headerName: 'Budget',
      minWidth: 200,
      renderCell: ({ row }: GridCellParams) => {
        const currency = organization?.currencies.find(
          (c: Currency) => c.id === row.currency_id
        );

        return (
          <EventBudgetCurrencyField
            control={control}
            currency={currency}
            name={`groupsBudgets.${row.index}.budget`}
            placeholder='0.00'
          />
        );
      },
    },
    {
      field: 'currency_id',
      flex: 2,
      headerName: 'Currency',
      minWidth: 200,
      renderCell: ({ row }: GridCellParams) => {
        const isCurrencyDisabled = groupsWithDisabledCurrency.includes(row.id);
        return (
          <Tooltip
            title={
              isCurrencyDisabled
                ? 'Group currency cannot be changed due to shared event expenses.'
                : ''
            }
          >
            <Box width='100%'>
              <CurrencySelect
                control={control}
                disabled={isCurrencyDisabled}
                name={`groupsBudgets.${row.index}.currency_id`}
              />
            </Box>
          </Tooltip>
        );
      },
    },
  ];

  const groupsMap = new Map(
    groups?.map((group: { id: any }) => [group.id, group])
  );

  const filteredGroupsBudgetData = (groupsBudgetData || []).filter(
    (groupBudget) => {
      const group = groupsMap?.get(groupBudget.id);
      return (
        group &&
        searchGroupName(group as any, searchTerm) &&
        typeFilter(selectedTypes, group as any)
      );
    }
  );

  const onSubmit = (data: any) => {
    const modifiedGroups = data?.groupsBudgets
      ?.filter((group: any) => {
        const initialGroup = groupsBudgetData?.find(
          (initialGroup: any) => initialGroup.id === group.id
        );

        return (
          initialGroup &&
          (group.budget !== initialGroup.budget ||
            group.currency_id !== initialGroup.currency_id)
        );
      })
      ?.map((group: any) => ({
        ...group,
        new_budget: group.budget,
      }));

    const currencyChangedGroups = modifiedGroups?.filter((group: any) => {
      const initialGroup = groupsBudgetData?.find(
        (initialGroup: any) => initialGroup.id === group.id
      );
      return group.currency_id !== initialGroup?.currency_id;
    });

    if (currencyChangedGroups.length > 0) {
      const groupsWithEvents = currencyChangedGroups.filter(
        (group: any) => group.has_linked_events
      );

      if (groupsWithEvents.length > 0) {
        setShowLinkedEventsDialog(true);
        setGroupsWithEvents(groupsWithEvents);
      } else {
        const updatedGroups = modifiedGroups?.map((group: any) => ({
          budget: group.budget as number,
          conversion_rate: 1,
          currency_id: group.currency_id as string,
          group_id: group.id as number,
        }));

        update.mutate(updatedGroups);
        onClose();
      }
    } else {
      const updatedGroups = modifiedGroups?.map((group: any) => ({
        budget: group.budget as number,
        conversion_rate: 1,
        currency_id: group.currency_id as string,
        group_id: group.id as number,
      }));

      update.mutate(updatedGroups);
      onClose();
    }
  };

  const handleLinkedEventsDialogClose = () => {
    setShowLinkedEventsDialog(false);
    onClose();
  };

  const handleConversionRateChange = (groupId: number, rate: number) => {
    setConversionRates((prevRates) => ({
      ...prevRates,
      [groupId]: rate,
    }));
  };

  const handleSaveAndContinue = () => {
    const updatedGroups = groupsWithEvents?.map((group: any) => {
      const initialGroup = groupsBudgetData?.find(
        (initialGroup: any) => initialGroup.id === group.id
      );

      const currencyChanged = group.currency_id !== initialGroup?.currency_id;
      const conversionRate = currencyChanged
        ? conversionRates[group.id] || 1
        : 1;

      return {
        budget: group.budget as number,
        conversion_rate: conversionRate,
        currency_id: group.currency_id as string,
        group_id: group.id as number,
      };
    });

    update.mutate(updatedGroups);

    handleLinkedEventsDialogClose();
  };

  const handleCancel = () => {
    reset({
      groupsBudgets: groupsBudgetData,
    });
    onClose();
  };

  return (
    <>
      <Dialog
        fullScreen
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { bgcolor: 'grey.50' } }}
        TransitionComponent={SendCommunicationDialogTransition}
      >
        <DialogTitle component='div' sx={{ p: 0 }}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar
              sx={{
                bgcolor: 'white',
                borderBottomColor: 'grey.300',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                minHeight: `83px !important`,
              }}
            >
              <ContentWidthContainer>
                <Stack alignItems='center' direction='row' gap={2}>
                  <Typography color='text.primary' flex={1} variant='h4'>
                    Manage group budgets
                  </Typography>

                  <Button
                    data-testid='cancel'
                    onClick={handleCancel}
                    variant='text'
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid='save'
                    disabled={!isDirty}
                    onClick={handleSubmit(onSubmit)}
                    variant='contained'
                  >
                    Save
                  </Button>
                </Stack>
              </ContentWidthContainer>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Stack alignItems='center' direction='column' pb={3} pt={2}>
            <Stack
              alignItems='stretch'
              direction='column'
              gap={2}
              maxWidth={{ md: 912, xs: '90%' }}
            >
              <Stack
                alignItems='center'
                direction='row'
                flexGrow={1}
                gap={6}
                justifyContent='space-around'
              >
                <Filters
                  filters={[
                    {
                      handleChange: setSelectedTypes,
                      label: 'Type',
                      options: organization?.group_types || [],
                    },
                  ]}
                >
                  <Search
                    label='Search groups'
                    onChange={handleChange}
                    placeholder='Search groups'
                    value={searchTerm}
                  />
                </Filters>
                <Typography color='grey.700' variant='body1'>{`${dayjs(
                  `${organization?.budget_start_month} 1, ${dayjs().year()}`
                ).format(dateFormat)} - ${dayjs(
                  `${organization?.budget_start_month} 1, ${dayjs().year()}`
                )
                  .add(1, 'year')
                  .subtract(1, 'day')
                  .format(dateFormat)}`}</Typography>
              </Stack>
              <FtnDataGrid
                columns={columns}
                rows={filteredGroupsBudgetData || []}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <CurrencyConversionDialog
        conversionRates={conversionRates}
        groupsBudgetData={groupsBudgetData}
        groupsWithEvents={groupsWithEvents}
        onClose={handleLinkedEventsDialogClose}
        onConversionRateChange={handleConversionRateChange}
        onSaveAndContinue={handleSaveAndContinue}
        open={showLinkedEventsDialog}
        organization={organization}
      />
    </>
  );
};

export default BudgetsFormDialog;
