import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import { ArrowClockwise } from '@phosphor-icons/react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import SurveyQuestionList from 'components/Events/Controls/Shared/SurveyQuestionList';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { flexColumn } from 'utils/styles';
import { oppositeType } from 'utils/survey';
import { useChunking, useContextWithFallback } from 'utils/utils';

const SelectSurveyTemplate = ({
  activeTemplateId = null,
  buttonVariant = 'contained',
  surveyType,
}) => {
  const {
    actions: { applyMulti, setShowErrors, setSurveyFieldIdsToDisplayErrorsOn },
    state: { hasUnsavedSurveyChanges, showErrors, surveyErrors, surveys },
  } = useContextWithFallback(ManagerContext, SurveyTemplatesContext);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const templates = useChunking('/api/survey_templates', 'templates');
  const onSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isSelectingTemplate, setIsSelectingTemplate] = useState(false);
  const [isConfirmingSwitch, setIsConfirmingSwitch] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [surveyTemplates, setSurveyTemplates] = useState(null);

  useEffect(() => {
    if (!templates || (templates?.hasMore && !templates.isLoading)) {
      templates?.requestMore();
    }
  });

  useEffect(() => {
    const surveyTypeId = surveyType.split('_')[0];
    const templatesForType = templates?.values?.filter(
      (template) => template.style === surveyTypeId
    );
    setSurveyTemplates(templatesForType);
  }, [setSurveyTemplates, surveyType, templates?.values]);

  useEffect(() => {
    if (activeTemplateId && !selectedTemplate?.id) {
      setSelectedTemplate(
        templates?.values?.find((v) => v.id === activeTemplateId)
      );
    }
  }, [
    activeTemplateId,
    isSelectingTemplate,
    selectedTemplate,
    templates?.values,
  ]);

  const isEditing = (type) => {
    return surveys[type]?.editing;
  };

  const closeSelectModal = useCallback(() => {
    if (isConfirmingSwitch) {
      setIsConfirmingSwitch(false);
    }
    setSelectedTemplate(null);
    setIsSelectingTemplate(false);
  }, [isConfirmingSwitch]);

  const formatToDestroy = (items, positionOffset) => {
    // Ignores items that haven't been saved yet
    return items
      .filter((item) => item.id !== '')
      .map((item) => {
        return {
          ...item,
          ...(positionOffset > -1 && {
            position: item.position + positionOffset,
          }),
          _destroy: 1,
        };
      });
  };

  const applyTemplateToSurvey = () => {
    if (selectedTemplate) {
      // sorts new questions by position value
      const sortedQuestions = selectedTemplate.survey_questions.sort(
        (a, b) => a.position - b.position
      );
      // maps question index to question id so options can use index as survey_question_id
      const positionByQuestionId = new Map(
        [...sortedQuestions].map((q, qIndex) => [q.id, qIndex])
      );

      // uses sorted index as new position value (in case there are gaps in position values)
      const newQuestions = [...sortedQuestions].map((q, qIndex) => {
        return {
          id: '',
          pending_id: `${q.question_type}-${dayjs().unix()}`,
          position: qIndex,
          question_type: q.question_type,
          required: q.required,
          title: q.title,
        };
      });
      // sets options' survey_question_id to the sorted index of their question
      const newOptions = selectedTemplate.survey_options.map((o) => {
        return {
          id: '',
          pending_id: `${o.survey_question_id}-${dayjs().unix()}`,
          survey_question_id: positionByQuestionId.get(o.survey_question_id),
          text: o.text,
        };
      });

      // (1) uses newQuestions.length to offset prevQuestions' position values
      //   - this ensures:
      //     1. each question has a unique position value (no conflicts b/t prev & new)
      //     2. prevQuestions are at the end of the survey
      // (2) adds _destroy: 1 to each prev item
      const prevQuestions = formatToDestroy(
        surveys[surveyType].survey_questions,
        [...newQuestions].length
      );
      const prevOptions = formatToDestroy(surveys[surveyType].survey_options);

      const combinedQuestions = [...newQuestions, ...prevQuestions];
      const combinedOptions = [...newOptions, ...prevOptions];

      applyMulti([
        [true, `surveys.${surveyType}.editing`],
        [false, `surveys.${oppositeType(surveyType)}.editing`],
        [true, `surveys.${surveyType}.enabled`],
        [false, `surveys.${oppositeType(surveyType)}.enabled`],
        [combinedQuestions, `surveys.${surveyType}.survey_questions`],
        [combinedOptions, `surveys.${surveyType}.survey_options`],
        [selectedTemplate.id, `surveys.${surveyType}.survey_template_id`],
      ]);
    }
    closeSelectModal();
  };

  const handleApplyOrSwitchTemplate = () => {
    if (activeTemplateId && selectedTemplate.id !== activeTemplateId) {
      // has selected a different template than before
      setIsSelectingTemplate(false);
      setIsConfirmingSwitch(true);
    } else if (selectedTemplate && !activeTemplateId) {
      // has selected a template for the first time
      applyTemplateToSurvey();
    } else {
      // has selected the same template as before
      setIsSelectingTemplate(false);
    }
  };

  const hasSurveyTemplates = surveyTemplates?.length > 0;
  const buttonText = `${
    selectedTemplate && activeTemplateId ? 'Switch' : 'Use'
  } survey template`;

  const handleUseSurveyTemplate = (create = true) => {
    if (isEditing(oppositeType(surveyType)) && surveyErrors?.length > 0) {
      if (!showErrors) {
        setShowErrors(true);
      }
      const ids = surveyErrors
        .filter((e) => !_.isNil(e?.questionId) || !_.isNil(e?.optionId))
        .map((e) => e.optionId || e.questionId);
      setSurveyFieldIdsToDisplayErrorsOn(ids);

      enqueueSnackbar('Please resolve errors before creating a new survey', {
        variant: 'error',
      });
    } else if (
      (isEditing(oppositeType(surveyType)) ||
        surveys[oppositeType(surveyType)].enabled) &&
      hasUnsavedSurveyChanges
    ) {
      enqueueSnackbar(
        `Please save or cancel your changes before ${
          create ? 'creating a new survey' : 'editing the other survey'
        }`,
        {
          variant: 'error',
        }
      );
    } else {
      setIsSelectingTemplate(true);
    }
  };

  return (
    <>
      <Button
        data-testid='TODO:DATA-BUTTON-84730'
        onClick={() => handleUseSurveyTemplate()}
        startIcon={<ArrowClockwise />}
        variant={buttonVariant}
      >
        {buttonText}
      </Button>
      <Dialog
        aria-label='Select survey template'
        fullWidth={hasSurveyTemplates}
        maxWidth='md'
        onClose={() => setIsSelectingTemplate(false)}
        open={isSelectingTemplate}
      >
        <DialogTitle>
          {selectedTemplate && activeTemplateId ? 'Switch' : 'Use'} survey
          template
          {hasSurveyTemplates && (
            <Autocomplete
              aria-label='Select survey template'
              autoHighlight
              disableListWrap
              disablePortal
              fullWidth
              getOptionLabel={(option) => option?.name}
              id='select-survey-template'
              onChange={(e, val) => {
                setSelectedTemplate(val);
              }}
              openOnFocus
              options={surveyTemplates?.sort(
                (a, b) => -b.name[0].localeCompare(a.name[0])
              )}
              placeholder='Survey template'
              renderInput={(params) => (
                <TextField
                  {...params}
                  data-testid='TODO:DATA-TEXTFIELD-22337'
                  placeholder='Select survey template'
                />
              )}
              renderOption={(props, option) => {
                const questionCount = option.survey_questions.length;
                return (
                  <ListItem {...props} key={option.id}>
                    <ListItemText
                      primary={option.name}
                      secondary={`${pluralize(
                        'question',
                        questionCount,
                        true
                      )} • ${option.creator?.full_name}`}
                    />
                  </ListItem>
                );
              }}
              value={selectedTemplate}
            />
          )}
        </DialogTitle>
        <DialogContent sx={hasSurveyTemplates ? { p: 0 } : {}}>
          {hasSurveyTemplates && (
            <>
              {selectedTemplate ? (
                <>
                  <Box px={4}>
                    <Typography variant='h5'>
                      {selectedTemplate?.name}
                    </Typography>
                    <Typography
                      color='text.secondary'
                      component='div'
                      variant='overline'
                    >
                      Created
                      {selectedTemplate?.creator?.full_name
                        ? ` by ${selectedTemplate?.creator?.full_name}`
                        : ''}{' '}
                      on{' '}
                      {dayjs(selectedTemplate?.created_at).format('MM/DD/YY')}
                    </Typography>
                  </Box>
                  <Stack direction='column' spacing={3}>
                    <SurveyQuestionList
                      questions={selectedTemplate?.survey_questions}
                      showSummaries
                      summarySx={{ px: 4, py: 2 }}
                      type={surveyType}
                    />
                  </Stack>
                </>
              ) : (
                <Box
                  alignItems='center'
                  display='grid'
                  flex={1}
                  gridTemplateRows='1fr min-content'
                >
                  <Typography
                    align='center'
                    color='text.secondary'
                    m={3}
                    sx={{ fontWeight: 500 }}
                    variant='h5'
                  >
                    Choose a survey template from the dropdown to build your
                    survey faster
                  </Typography>
                  <img
                    alt=''
                    src='/images/people-doing-puzzle.svg'
                    width='100%'
                  />
                </Box>
              )}
            </>
          )}
          {!hasSurveyTemplates && (
            <Box sx={{ ...flexColumn, gap: 1.5 }}>
              <Typography variant='body1'>
                A survey template is a set of questions that can be reused
                across events. You haven&apos;t created any yet.
              </Typography>
              <div>
                <Typography variant='h4'>
                  2 ways to create survey templates
                </Typography>
                <ol>
                  <li className='mb-1'>
                    Click the{' '}
                    <Typography variant='body2'>Save as template</Typography>{' '}
                    button while editing an event survey
                  </li>
                  <li>
                    Go to the <Typography variant='body2'>Surveys</Typography>{' '}
                    link in the left side menu to create one from scratch!
                  </li>
                </ol>
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='TODO:DATA-BUTTON-30163'
            onClick={closeSelectModal}
            sx={{
              order: onSmallScreen ? 1 : 0,
            }}
            variant='text'
          >
            {hasSurveyTemplates ? 'Cancel' : 'Okay'}
          </Button>
          {hasSurveyTemplates && (
            <Button
              color='primary'
              data-testid='TODO:DATA-BUTTON-23575'
              disabled={surveyTemplates?.length < 1 || !selectedTemplate}
              onClick={handleApplyOrSwitchTemplate}
              type='submit'
              variant='contained'
            >
              {buttonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <DeleteModal
        confirm
        custom={{
          byline:
            'Are you sure you want to use this survey template? All of your existing survey questions will be deleted!',
          confirm: 'Yes, use template',
          title: 'Use survey template',
        }}
        onCancel={() => {
          setIsConfirmingSwitch(false);
          setSelectedTemplate(null);
        }}
        onDelete={(onDone) => {
          applyTemplateToSurvey();
          onDone();
        }}
        show={isConfirmingSwitch}
      />
    </>
  );
};

SelectSurveyTemplate.propTypes = {
  activeTemplateId: PropTypes.any,
  buttonVariant: PropTypes.string,
  surveyType: PropTypes.string,
};

export default SelectSurveyTemplate;
