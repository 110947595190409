import React, { useMemo } from 'react';
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  darken,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Clock, Link, MapPin, VideoCamera } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';

import {
  lateRsvpButtonText,
  lateRsvpSubheader,
} from 'components/Events/Controls/Rsvp/Rsvp.constants';
import RsvpCardHeader from 'components/Events/Controls/Rsvp/RsvpCardHeader';
import VirtualLinkHiddenTooltip from 'components/Events/Controls/Shared/VirtualLinkHiddenTooltip';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { ZoomBlue } from 'utils/colors';
import { getEventLink } from 'utils/event';
import { EventLocationTypes } from 'utils/location.constants';
import { useContextWithFallback } from 'utils/utils';

dayjs.extend(calendar);
dayjs.extend(relativeTime);

const AttendEvent = ({
  allAnswersSubmitted,
  isDone,
  isSoon,
  rsvpIsYesOrMaybe,
  user,
}) => {
  const {
    actions: { handleRsvpClick },
    state: { event, locationType },
  } = useContextWithFallback(ManagerContext, PublicEventContext);

  const { copy } = useCopyToClipboard();
  const theme = useTheme();

  const timeFormat = 'h:mma';

  const locTypeDetails = useMemo(
    () => EventLocationTypes[locationType],
    [locationType]
  );

  const virtualServiceUnknown = useMemo(
    () =>
      event?.meeting?.service ? event?.meeting?.service === 'unknown' : null,
    [event?.meeting?.service]
  );

  const virtualServiceLabel = useMemo(
    () =>
      event?.meeting?.service
        ? virtualServiceUnknown
          ? `virtual meeting`
          : startCase(event?.meeting?.service?.replace('_', ' '))
        : '',
    [event?.meeting?.service, virtualServiceUnknown]
  );

  const isZoomWebinarPanelist = useMemo(
    () =>
      user?.id === event?.meeting?.creator_id &&
      event?.meeting?.service === 'zoom_webinar',
    [user, event]
  );

  const isZoomService = useMemo(
    () =>
      event?.meeting?.service === 'zoom_meeting' ||
      event?.meeting?.service === 'zoom_webinar',
    [event]
  );

  const virtualMeetingLink = useMemo(
    () =>
      isZoomWebinarPanelist
        ? 'https://zoom.us/signin#/login'
        : event?.meeting?.link,
    [event, isZoomWebinarPanelist]
  );

  const askDefaultQuestions = useMemo(() => !user && !isDone, [user, isDone]);

  const details = useMemo(() => {
    const eventStart = dayjs(event?.start);
    const eventStartTime = eventStart.format(timeFormat);
    const eventEnd = dayjs(event?.ends);
    const eventEndTime = eventEnd.format(`${timeFormat} z`);
    const showRelativeDates =
      dayjs().isSame(eventStart, 'date') ||
      (dayjs().isAfter(eventStart, 'date') &&
        dayjs().isBefore(eventEnd, 'date'));
    const isSingleDay = eventEnd.isSame(eventStart, 'date');
    return [
      {
        condition: true,
        Icon: Clock,
        label: 'Meeting time',
        value: [
          showRelativeDates
            ? eventStart?.calendar().replace('at ', '')
            : `${eventStart?.format('LL')} ${eventStartTime}`,
          isSingleDay
            ? `- ${eventEndTime}`
            : [
                '-',
                showRelativeDates
                  ? eventEnd?.calendar().replace('at ', '')
                  : eventEnd?.format('LL'),
              ].join(' '),
        ].join(' '),
      },
      {
        condition:
          (locationType === 'hybrid' || locationType === 'inperson') &&
          event?.location?.length > 0,
        Icon: MapPin,
        label: 'Location',
        value: event?.location,
      },
      {
        condition:
          (locationType === 'hybrid' || locationType === 'virtual') &&
          event?.meeting?.link?.length > 0,
        Icon: VideoCamera,
        label: 'Virtual location',
        value: isDone ? (
          rsvpIsYesOrMaybe ? (
            virtualServiceUnknown ? (
              'Custom link'
            ) : (
              virtualServiceLabel
            )
          ) : (
            <VirtualLinkHiddenTooltip
              canRsvp={!event?.rsvp_pause_active}
              isDone={isDone}
              isSoon={isSoon}
            />
          )
        ) : (
          <>
            {rsvpIsYesOrMaybe ? (
              <Stack direction='column' gap={1}>
                <Button
                  data-testid='TODO:DATA-BUTTON-94258'
                  fullWidth
                  href={event?.meeting?.link}
                  startIcon={
                    virtualServiceUnknown ? null : (
                      <Avatar
                        data-testid='TODO:DATA-AVATAR-75877'
                        src={`/images/${event?.meeting?.service
                          ?.replace(/[_-]?(meeting|webinar)?/g, '')
                          ?.replace('_', '-')}.svg`}
                        sx={{
                          '& img': {
                            height: 'auto',
                            maxHeight: '100%',
                            maxWidth: '100%',
                            width: 'auto',
                          },
                          height: 20,
                          width: 20,
                        }}
                        variant='square'
                      />
                    )
                  }
                  sx={
                    isZoomService
                      ? {
                          '&:hover': {
                            bgcolor: darken(ZoomBlue, 0.25),
                          },
                          bgcolor: ZoomBlue,
                        }
                      : {}
                  }
                  target='_blank'
                  variant='contained'
                >
                  {[
                    'Join',
                    ...(virtualServiceUnknown
                      ? []
                      : virtualServiceLabel === 'Zoom Webinar'
                      ? ['as']
                      : []),
                    virtualServiceLabel === 'Zoom Webinar'
                      ? isZoomWebinarPanelist
                        ? 'panelist'
                        : ' attendee'
                      : virtualServiceLabel,
                  ].join(' ')}
                </Button>
                <Stack alignItems='center' direction='row' gap={1}>
                  <Typography color='grey.600' variant='overline'>
                    {virtualMeetingLink}
                  </Typography>
                  <IconButton
                    color='primary'
                    data-testid='TODO:DATA-ICONBUTTON-22771'
                    onClick={() =>
                      copy(
                        getEventLink('meeting', null, event) || '',
                        `${virtualServiceLabel} link`
                      )
                    }
                    size='small'
                  >
                    <Link />
                  </IconButton>
                </Stack>
                {isZoomWebinarPanelist && (
                  <Typography>Log into Zoom to join as a panelist.</Typography>
                )}
              </Stack>
            ) : (
              <VirtualLinkHiddenTooltip
                canRsvp={!event?.rsvp_pause_active}
                isDone={isDone}
                isSoon={isSoon}
              />
            )}
          </>
        ),
      },
    ];
  }, [
    copy,
    event,
    isDone,
    isSoon,
    isZoomService,
    isZoomWebinarPanelist,
    locationType,
    rsvpIsYesOrMaybe,
    virtualMeetingLink,
    virtualServiceLabel,
    virtualServiceUnknown,
  ]);

  const filteredDetails = useMemo(
    () => details?.filter((d) => d?.condition === true),
    [details]
  );

  const cardTitle = useMemo(
    () =>
      `${rsvpIsYesOrMaybe ? 'Your' : 'This'} event ${
        isSoon
          ? `is starting soon${rsvpIsYesOrMaybe ? '!' : '...'}`
          : ['has', isDone ? 'ended' : 'started'].join(' ')
      }`,
    [isDone, isSoon, rsvpIsYesOrMaybe]
  );

  return (
    <>
      {!rsvpIsYesOrMaybe && !isDone && !event.rsvp_pause_active ? (
        <Card data-testid='TODO:DATA-CARD-17991'>
          <RsvpCardHeader
            subheader={
              isDone ? null : (
                <>
                  {locationType
                    ? locTypeDetails?.upcomingText?.noRsvp(event?.name) || ''
                    : !event.rsvp_pause_active && !rsvpIsYesOrMaybe
                    ? lateRsvpSubheader(event?.name)
                    : null}
                </>
              )
            }
            title={cardTitle}
          />
          <CardActions>
            <Button
              data-testid='TODO:DATA-BUTTON-36473'
              disabled={isDone || (askDefaultQuestions && !allAnswersSubmitted)}
              fullWidth
              onClick={() => handleRsvpClick('rsvp')}
              variant='contained'
            >
              {lateRsvpButtonText}
            </Button>
          </CardActions>
        </Card>
      ) : null}
      {isDone ? (
        <Alert
          data-testid='this-event-has-ended'
          severity='info'
          sx={{ color: 'grey.900' }}
        >
          This event has ended
        </Alert>
      ) : (
        <Card data-testid='TODO:DATA-CARD-96119'>
          <RsvpCardHeader
            subheader={
              rsvpIsYesOrMaybe && !isDone
                ? locTypeDetails?.upcomingText?.rsvp
                : null
            }
            title={rsvpIsYesOrMaybe || isDone ? cardTitle : 'How to attend'}
          />
          <CardContent sx={{ pb: `${theme.spacing(1)} !important`, pt: 0 }}>
            <List data-testid='TODO:DATA-LIST-67238' disablePadding>
              {filteredDetails?.map(({ Icon, label, value }, index) => (
                <ListItem
                  className='alignTop'
                  disableGutters
                  divider={index !== filteredDetails?.length - 1}
                  key={label}
                  sx={{ py: 2 }}
                >
                  <ListItemIcon>
                    <Icon size={20} />
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    primaryTypographyProps={{
                      color: 'grey.700',
                      mb:
                        typeof value === 'string' || !rsvpIsYesOrMaybe ? 1 : 2,
                      variant: 'body2',
                    }}
                    secondary={value}
                    secondaryTypographyProps={{
                      color: 'text.primary',
                      variant: 'body1',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </>
  );
};

AttendEvent.propTypes = {
  allAnswersSubmitted: PropTypes.bool,
  isDone: PropTypes.bool,
  isSoon: PropTypes.bool,
  rsvpIsYesOrMaybe: PropTypes.bool,
  user: PropTypes.object,
};

export default AttendEvent;
