import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/Core/Dropdown';
import {
  NEW_LIST_ITEM,
  SET_FIELD,
} from 'components/Settings/actions/editSettings';
import { useEditSettings } from 'contexts/SettingsContext';

const EventTypeSuggestions = [
  'Workshop',
  'Community & Culture',
  'Learning & Development',
  'Speakers',
  'Art & Music',
  'Volunteerism',
  'Health & Wellness',
];

const EventTypeSettings = ({ organization }) => {
  const { dispatch, state: editSettings } = useEditSettings();

  const setField = useCallback(
    (key, value) => {
      dispatch({ field: key, type: SET_FIELD, value });
    },
    [dispatch]
  );

  const newListItem = useCallback(
    (optionsField, selectionField, data) => {
      dispatch({
        data,
        optionsField,
        selectionField,
        type: NEW_LIST_ITEM,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    setField(
      'event_types_ids',
      organization.event_types.map((o) => o.id),
      true
    );

    setField(
      'event_types_attributes',
      organization.event_types.map((o) => ({
        id: o.id,
        name: o.name,
        uses: o.uses,
      }))
    );
  }, [organization, setField]);

  const opts = editSettings.event_types_attributes
    ? editSettings.event_types_attributes
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((o) => ({ label: o.name, uses: o.uses, value: o.id }))
    : [];

  const selOpts = opts?.filter((o) =>
    editSettings.event_types_ids?.includes(o.value)
  );

  return (
    <Dropdown
      allowCreate
      allowSuggestion
      attributesField='event_types_attributes'
      className='multi'
      components={{ DropdownIndicator: null }}
      dataProp='name'
      field='event_types_ids'
      helperText='How do you categorize your events?'
      isMulti
      label='Event types'
      newer={newListItem}
      options={opts}
      placeholder='Select from our suggestions or create your own!'
      selectedOption={selOpts}
      setter={setField}
      suggestions={EventTypeSuggestions}
    />
  );
};

EventTypeSettings.propTypes = {
  organization: PropTypes.shape({
    event_types: PropTypes.array,
  }),
};

export default EventTypeSettings;
