import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import PropTypes from 'prop-types';

/**
 * Renders <Cloudinary /> component
 * @param {object} props
 * @param {string} props.publicId cloudinary image key
 * @param {Number} props.width width of generated image
 * @param {Number} props.height height of generated image
 * @param {string} props.className css class list to apply instead of default (optional)
 * @param {HTMLElement | HTMLElement[]} props.children elements to display if publicId is null
 */
const Cloudinary = ({
  publicId,
  width,
  height,
  className,
  children,
  alt,
  ...props
}) => {
  if (!publicId) {
    return children;
  }

  return (
    (<Image
      alt={alt}
      className={className || 'cloudinary'}
      cloudName='five-to-nine'
      publicId={publicId}
      secure={process.env.NODE_ENV !== 'development'}
      {...props}
    >
      <Transformation crop='fill' height={height} width={width} />
    </Image>)
  );
};

Cloudinary.propTypes = {
  alt: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.number,
  publicId: PropTypes.string,
  width: PropTypes.number,
};

export default Cloudinary;
