import React, { useMemo } from 'react';
import { LinearProgress, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import AnalyticsAvatar from 'components/Analytics/AnalyticsAvatar';
/**
 * Renders <ProgressMetric /> component
 * @param {object} props
 * @param {string} props.img src path of icon
 * @param {string} props.accent accent color of progress bar
 * @param {Number} props.total maximum value of progress
 * @param {Number} props.value progress amount
 * @param {string} props.label label text
 * @param {Number} props.min minimum value of progress or 0 if not set
 * @param {object} props.customContainerStyle optional style object to apply to container
 */
const ProgressMetric = ({
  color,
  Icon,
  inset = false,
  isLoading = true,
  label,
  min,
  total,
  value,
}) => {
  min = min || 0;
  const valPercent = useMemo(
    () => Math.round(((value - min) / (total - min)) * 100),
    [min, total, value]
  );

  return (
    <Stack alignItems='center' direction='row' gap={3} width='100%'>
      <AnalyticsAvatar color={color} Icon={Icon} />
      <Stack alignItems='flex-start' direction='column' flexGrow={1} gap={0.5}>
        <Stack
          alignItems='center'
          direction='row'
          gap={2}
          justifyContent='space-between'
          width='100%'
        >
          <Typography fontWeight={inset ? 300 : 500}>{label}</Typography>
          {(value || value === 0) && !isLoading ? (
            <Typography sx={{ color: color?.dark }} variant='h4'>
              {value.toLocaleString()}
            </Typography>
          ) : (
            <Skeleton height={27} variant='text' width={42} />
          )}
        </Stack>
        <LinearProgress
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: color?.main,
            },
            backgroundColor: color?.light,
            borderRadius: 8,
            width: '100%',
          }}
          value={valPercent}
          variant='determinate'
        />
      </Stack>
    </Stack>
  );
};

ProgressMetric.propTypes = {
  color: PropTypes.object,
  Icon: PropTypes.any,
  inset: PropTypes.bool,
  label: PropTypes.string,
  min: PropTypes.number,
  total: PropTypes.number,
  value: PropTypes.number,
};

export default ProgressMetric;
