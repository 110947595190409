import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import Currency from 'types/Currency';
import { reactQueryAxios } from 'utils/axios';

export const useCurrencies = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const getCurrencies = async ({ apiClient }: { apiClient: any }) =>
    await apiClient({
      method: 'get',
      url: `/api/currencies`,
    });

  const retrieve = useQuery({
    queryFn: async () => {
      const response = await getCurrencies({ apiClient: reactQueryAxios });
      return response.data;
    },
    queryKey: ['currencies'],
  });

  const updateCurrencies = async ({
    apiClient,
    currencyIds,
  }: {
    apiClient: any;
    currencyIds: number[];
  }) =>
    apiClient({
      data: { currency_ids: currencyIds },
      method: 'put',
      url: `/api/organizations/update_currencies`,
    });

  const update = useMutation({
    mutationFn: (currencies: Currency[]) =>
      updateCurrencies({
        apiClient: reactQueryAxios,
        currencyIds: currencies.map((currency) => currency.id),
      }),

    onError: (error) => {
      const message = 'Failed to update currencies';
      console.error(`${message}: `, error);
      enqueueSnackbar(message, { variant: 'error' });
    },

    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['organization'],
      });
      queryClient.setQueryData(['currencies'], response.data.currencies);
      enqueueSnackbar('Currencies updated successfully!', {
        variant: 'success',
      });
    },
  });

  return { retrieve, update };
};
