import React from 'react';
import { Chip } from '@mui/material';
import { CalendarDots, Shapes } from '@phosphor-icons/react';
import dayjs from 'dayjs';

import GridCard from 'components/shared/GridCard';

interface CollectionCardProps {
  handleOpenCard: () => void;
  item: {
    endsAt: string;
    events: any[];
    imageUrl: string | null;
    name: string;
    numberOfEvents: number;
    safeDescription: string;
    startsAt: string;
    upcomingEvents: number;
  };
}

const CollectionCard = ({ handleOpenCard, item }: CollectionCardProps) => {
  const { endsAt, events, imageUrl, name, safeDescription, startsAt } = item;

  return (
    <GridCard
      avatars={[]}
      ChipComponent={Chip}
      description={safeDescription}
      handleOpenCard={handleOpenCard}
      imageUrl={imageUrl || null}
      item={item}
      itemType='collection'
      stats={[
        {
          Icon: CalendarDots,
          id: 'date',
          text:
            events.length > 0
              ? `${dayjs(startsAt).format('MM/DD/YYYY')} - ${dayjs(
                  endsAt
                ).format('MM/DD/YYYY')}`
              : '---',
        },
        {
          Icon: Shapes,
          id: 'events',
          text: `${events.length} events`,
        },
      ]}
      statsPosition='bottom'
      title={name}
    />
  );
};

export default CollectionCard;
