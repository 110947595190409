import React from 'react';
import { Box, capitalize, Chip, Typography } from '@mui/material';
import { alpha } from '@mui/system';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { MRT_Row } from 'material-react-table';

import { communicationStatusDetails } from 'components/Events/Controls/Communications/communication.constants';
import TooltipWithCustomWidth from 'components/shared/TooltipWithCustomWidth';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const InvitationSelectGuestStatusChip = ({
  row,
}: {
  row: MRT_Row<any>;
}) => {
  const statusDetails = row.original.sent_at
    ? {
        ...communicationStatusDetails['sent'],
        time: dayjs(row.original.sent_at),
      }
    : row.original.scheduled_for
    ? {
        ...communicationStatusDetails['scheduled'],
        time: dayjs(row.original.scheduled_for),
      }
    : null;
  const StatusIcon =
    statusDetails && statusDetails.Icon ? statusDetails.Icon : null;
  return (
    statusDetails !== null && (
      <TooltipWithCustomWidth
        title={
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            justifyContent='center'
            textAlign='center'
            width='100%'
          >
            <Typography display='block' variant='overline'>
              {[
                statusDetails.id === 'sent' ? 'Invited' : 'Will be invited',
                statusDetails?.time.fromNow(),
              ].join(' ')}
            </Typography>
            <Typography
              display='inline'
              ml={1}
              sx={{ color: alpha('#fff', 0.75) }}
              variant='caption'
            >
              {statusDetails.time.format('[(]M/D/YY [at] h:mma z[)]')}
            </Typography>
          </Box>
        }
        width={250}
      >
        <Chip
          {...(statusDetails && StatusIcon && StatusIcon !== null
            ? { icon: <StatusIcon size={18} /> }
            : {})}
          color={statusDetails?.color}
          label={capitalize(statusDetails?.label || statusDetails?.id || '')}
          size='small'
          sx={{ height: 30, m: -0.5 }}
          variant='light'
        />
      </TooltipWithCustomWidth>
    )
  );
};
