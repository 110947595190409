import React, { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import SurveyQuestionResponses from 'components/Events/Controls/Analytics/SurveyQuestionResponses';
import { SurveyQuestionTitle } from 'components/Events/Controls/Analytics/SurveyQuestionTitle';
import { SurveyQuestionTypeMap } from 'constants/survey.constants';

export const SurveyIndividualQuestion = ({
  displayResponders,
  individualResponses,
  isPreEvent,
  options,
  question,
  startsPositionAtZero,
}) => {
  const typeConfig = useMemo(
    () => (question?.type ? SurveyQuestionTypeMap.get(question?.type) : {}),
    [question?.type]
  );

  return (
    <Stack>
      <SurveyQuestionTitle
        hideResponseCount
        individualResponsesLength={individualResponses?.length}
        isPreEvent={isPreEvent}
        position={
          startsPositionAtZero ? question?.position + 1 : question?.position
        }
        title={question?.title}
        typeConfig={typeConfig}
      />
      <Box pb={0} pl={7}>
        <SurveyQuestionResponses
          displayResponders={displayResponders}
          individualResponses={individualResponses}
          isForSingleResponder
          options={options}
          type={question?.type}
        />
      </Box>
    </Stack>
  );
};

SurveyIndividualQuestion.propTypes = {
  displayResponders: PropTypes.any,
  individualResponses: PropTypes.any,
  isPreEvent: PropTypes.bool,
  options: PropTypes.any,
  question: PropTypes.object,
  startsPositionAtZero: PropTypes.bool,
};
