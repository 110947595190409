import React, { useEffect, useMemo, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Popover, useTheme } from '@mui/material';
import { Smiley } from '@phosphor-icons/react';
import { Editor } from '@tiptap/react';
import { init } from 'emoji-mart';

import RichTextToolbarButton from 'components/shared/RichTextToolbarButton';
import { useSlackCustomEmojis } from 'hooks/useSlackCustomEmojis';

import 'components/shared/RichTextEmoji.css';

init({ data });

const RichTextEmoji = ({ editor }: { editor: Editor }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    retrieve: { data: customEmojis, isLoading },
  } = useSlackCustomEmojis();
  const [customEmojiData, setCustomEmojiData] = useState<any[]>([]);

  useEffect(() => {
    if (customEmojis && customEmojis?.length > 0 && !isLoading) {
      const transformedEmojis = customEmojis?.map((emoji: any) => ({
        custom: true,
        emoticons: [],
        id: emoji.names,
        keywords: emoji.names,
        name: emoji.names,
        shortcodes: `:${emoji.names}:`,
        skins: [{ src: emoji.imgUrl }],
      }));
      setCustomEmojiData([
        {
          emojis: transformedEmojis,
          id: 'custom',
          name: 'Slack',
        },
      ]);
    }
  }, [customEmojis, isLoading]);

  const open = Boolean(anchorEl);

  const popoverId = 'toolbar-emoji-popover';

  const id = useMemo(() => (open ? popoverId : undefined), [open, popoverId]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <RichTextToolbarButton
        aria-describedby={id}
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
        selected={open}
        value='emoji'
      >
        <Smiley />
      </RichTextToolbarButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id={id}
        onClose={handleClose}
        open={open}
      >
        <Picker
          custom={customEmojiData || []}
          data={data}
          emojiButtonColors={[theme.palette.primary.light]}
          onEmojiSelect={(data: {
            name: string;
            native: string;
            src: string;
          }) =>
            data?.native
              ? editor
                  ?.chain()
                  ?.focus()
                  ?.insertContentAt(
                    {
                      from: editor?.state?.selection?.from,
                      to: editor?.state?.selection?.to,
                    },
                    data?.native
                  )
                  .run()
              : editor
                  ?.chain()
                  ?.focus()
                  ?.setImage({
                    alt: data?.name,
                    src: data?.src,
                    title: data?.name,
                  })
                  ?.run()
          }
          theme='light'
        />
      </Popover>
    </>
  );
};

export default RichTextEmoji;
