import React from 'react';
import { CardContent, CardHeader, useTheme } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { ChartLine } from '@phosphor-icons/react';

import ChartWithLegend from 'components/shared/ChartWithLegend';
import EmptyState from 'components/shared/EmptyState';
import InfoTooltip from 'components/shared/InfoTooltip';
import { MonthlyBreakdown } from 'hooks/useGroupBudget';
import Currency from 'types/Currency';
import { ChartGrayBlue } from 'utils/colors';
import { asCurrency } from 'utils/utils';

interface GroupBudgetSpendOverTimeProps {
  chartData: MonthlyBreakdown[];
  currency: Currency | undefined;
}

type SeriesData = {
  x: string;
  y: number;
};

const GroupBudgetSpendOverTime = ({
  chartData,
  currency,
}: GroupBudgetSpendOverTimeProps) => {
  const theme = useTheme();

  const totalSpendData = chartData.reduce((acc, cur, index) => {
    const prevValue = index > 0 ? acc[index - 1].y : 0;
    const currentValue = cur.value;
    acc.push({ x: cur.name, y: prevValue + currentValue });
    return acc;
  }, [] as SeriesData[]);

  const data = [
    { color: ChartGrayBlue, data: totalSpendData, id: 'Total spend' },
    {
      color: theme.palette.secondary.main,
      data: chartData.map((d) => ({ x: d.name, y: d.value })) as SeriesData[],
      id: 'Monthly spend',
    },
  ];

  const monthsWithSpending = chartData.filter((month) => month.value > 0);

  return (
    <>
      <CardHeader
        title={
          <InfoTooltip
            content='Group spending over the current budget period.'
            text='Spend over time'
          />
        }
      />
      <CardContent>
        {monthsWithSpending.length > 0 ? (
          <ChartWithLegend
            Chart={
              <ResponsiveLine
                axisBottom={{
                  format: (value) => value.toString().slice(0, 3),
                  tickPadding: 8,
                  tickRotation: 0,
                  tickSize: 0,
                }}
                axisLeft={{
                  format: (value) => asCurrency(value, currency?.code, 0),
                  tickPadding: 12,
                  tickRotation: 0,
                  tickSize: 0,
                  tickValues: 3,
                }}
                axisRight={null}
                axisTop={null}
                colors={(d) => d?.color}
                data={data}
                enableArea
                enableSlices='x'
                lineWidth={3}
                margin={{ bottom: 24, left: 50, right: 20, top: 0 }}
                pointSize={0}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fontSize: 12,
                      },
                    },
                  },
                  labels: {
                    text: {
                      color: theme.palette.grey[700],
                      fontSize: 12,
                    },
                  },
                  legends: {},
                  text: {
                    fontFamily: 'Inter',
                  },
                }}
                useMesh
                xScale={{ type: 'point' }}
                yFormat={(value) => asCurrency(value, currency?.code)}
                yScale={{
                  max: Math.max(...totalSpendData.map((d) => d.y)) + 50,
                  min: 0,
                  nice: true,
                  stacked: false,
                  type: 'linear',
                }}
              />
            }
            legendItems={data.map(
              (d: {
                color: string;
                data: SeriesData | SeriesData[];
                id: string;
              }) => ({
                ...d,
                label: d.id,
                value: Array.isArray(d.data)
                  ? Math.max(...d.data.map((m) => m.y))
                  : d.data.y,
              })
            )}
            orientation='vertical'
          />
        ) : (
          <EmptyState
            Icon={ChartLine}
            iconColor='primary'
            secondary='Tag events and log event expenses to track your spend over time.'
          />
        )}
      </CardContent>
    </>
  );
};

export default GroupBudgetSpendOverTime;
