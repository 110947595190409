import React from 'react';

import { FullscreenLoader } from 'components/Core/Loader';

interface Props {
  children: React.JSX.Element;
  condition: boolean;
}

const GroupLoader = ({ children, condition }: Props) =>
  condition ? children : <FullscreenLoader show />;

export default GroupLoader;
