import React, { useMemo, useState } from 'react';
import { capitalize, FormControl, MenuItem, Select } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { MinusCircle, Power } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import { RolesSelectOptions } from 'utils/user';

const UsersTable = ({
  handleUserStatus,
  handleUserUpdate,
  isLoading,
  organization,
  users,
  userStatus,
}) => {
  const [isConfirmingAction, setIsConfirmingAction] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const action = useMemo(
    () => (userStatus === 'activated' ? 'deactivate' : 'reactivate'),
    [userStatus]
  );

  const isActive = useMemo(() => userStatus === 'activated', [userStatus]);

  const Columns = useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'email',
        flex: 2,
        minWidth: 150,
      },
      {
        field: 'office',
        flex: 1,
        minWidth: 150,
      },
      ...(isActive
        ? [
            {
              field: 'role',
              flex: 1,
              minWidth: 150,
              renderCell: (params) => (
                <FormControl fullWidth>
                  <Select
                    defaultValue={params.row.role}
                    id={`user-${[params.row.id]}-role-select`}
                    onChange={(ev) =>
                      handleUserUpdate({
                        id: params.row.id,
                        role: ev.target.value,
                        user: params.row,
                      })
                    }
                    value={params.row.role}
                  >
                    {RolesSelectOptions.map((opt, index) => (
                      <MenuItem
                        key={`row-${params.row.id}-select-option-${index}`}
                        value={opt.label}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ),
            },
          ]
        : []),
      {
        field: 'actions',
        getActions: (params) => [
          <GridActionsCellItem
            icon={isActive ? <MinusCircle size={24} /> : <Power size={24} />}
            key={`${params.id}-${action}-button`}
            label='Delete'
            onClick={() => {
              setSelectedUser(params.row);
              setIsConfirmingAction(true);
            }}
          />,
        ],
        headerName: capitalize(action || ''),
        type: 'actions',
      },
    ],
    [action, handleUserUpdate, isActive]
  );

  return (
    <>
      <FtnDataGrid
        columns={Columns}
        data-testid='users-table'
        loading={isLoading}
        noun='user'
        rows={users?.filter((u) => u.status === userStatus) || []}
        withLinearLoading
      />
      <DeleteModal
        custom={{
          byline: isActive
            ? `Are you sure you want to revoke ${selectedUser?.name}'s access to Five to Nine?`
            : `Are you sure you want to allow ${selectedUser?.name} to log in to Five to Nine?`,
          confirm: `Yes, ${action}`,
          title: `${capitalize(action || '')} user`,
        }}
        maxWidth='xs'
        onCancel={() => setIsConfirmingAction(false)}
        onDelete={() => {
          handleUserStatus(selectedUser, action);
          setIsConfirmingAction(false);
        }}
        show={isConfirmingAction}
      />
    </>
  );
};

UsersTable.propTypes = {
  handleUserStatus: PropTypes.func,
  handleUserUpdate: PropTypes.func,
  isLoading: PropTypes.bool,
  organization: PropTypes.any,
  users: PropTypes.array,
  userStatus: PropTypes.oneOf(['activated', 'deactivated']),
};

export default UsersTable;
