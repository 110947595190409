import React from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from '@mui/material';
import { Icon } from '@phosphor-icons/react';

import { flexColumn } from 'utils/styles';

interface RadioBoxOption {
  Icon?: Icon;
  id: string;
  primary: string;
  secondary?: string;
}

interface RadioBoxesProps {
  onChange: (value: string) => void;
  options: RadioBoxOption[];
  value: string;
}

const RadioBoxes = ({ onChange, options, value }: RadioBoxesProps) => (
    <List disablePadding sx={{ ...flexColumn, gap: 1.5 }}>
      {options.map(({ Icon, id, primary, secondary }) => {
        const labelId = `radio-box-label-${id}`;
        return (
          <ListItem disableGutters disablePadding key={id}>
            <ListItemButton
              onClick={() => onChange(id)}
              role={undefined}
              selected={value === id}
              sx={{
                '& .MuiTouchRipple-child': {
                  bgcolor: 'grey.500',
                },
                '&.Mui-selected, &.Mui-selected:hover': {
                  bgcolor: 'grey.50',
                },
                alignItems: 'flex-start',
                borderColor: 'grey.300',
                borderRadius: 0.75,
                borderStyle: 'solid',
                borderWidth: 1,
                p: 3,
              }}
            >
              <ListItemIcon sx={{ minWidth: 'unset' }}>
                <Radio
                  checked={value === id}
                  disableRipple
                  edge='end'
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  onClick={() => onChange(id)}
                  sx={{ mr: 0.5 }}
                  tabIndex={-1}
                  value={id}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={primary}
                primaryTypographyProps={{ variant: 'h4' }}
                secondary={secondary}
                secondaryTypographyProps={{ lineHeight: 1.5, variant: 'body1' }}
                sx={{ ...flexColumn, gap: 0.5, m: 0 }}
              />
              {Icon && (
                <ListItemIcon
                  sx={{ alignSelf: 'center', justifyContent: 'flex-end' }}
                >
                  <Icon size={24} />
                </ListItemIcon>
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );

export default RadioBoxes;
