import React from 'react';

import Head from 'components/Core/Head';
import EventView from 'components/Events/Controls/Shared/EventView';
import { EventWrapper } from 'components/Events/EventWrapper';
import { PublicEventProvider } from 'components/Events/PublicEvent/PublicEventContext';

const PublicEvent = () => (
  <PublicEventProvider>
    <Head title='Event' />
    <EventWrapper>
      <EventView isPublic />
    </EventWrapper>
  </PublicEventProvider>
);

export default PublicEvent;
