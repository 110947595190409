import React, { useMemo } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import ChartLegendItem, {
  IChartLegendItem,
} from 'components/shared/ChartLegendItem';

interface IChartWithLegend {
  Chart: React.ReactNode;
  legendItems: IChartLegendItem[];
  orientation?: 'horizontal' | 'vertical';
}

const ChartWithLegend = ({
  Chart,
  legendItems,
  orientation = 'horizontal',
}: IChartWithLegend) => {
  const isHorizontal = useMemo(
    () => orientation === 'horizontal',
    [orientation]
  );
  return (
    <Grid2
      display='grid'
      gap={2}
      gridTemplateColumns={isHorizontal ? 'auto minmax(auto, 50%)' : '1fr'}
      gridTemplateRows={isHorizontal ? '200px' : '200px auto'}
      width='100%'
    >
      <Grid2>{Chart}</Grid2>
      <Grid2
        alignItems={isHorizontal ? 'flex-start' : 'center'}
        display='flex'
        flexDirection={isHorizontal ? 'column' : 'row'}
        flexWrap={isHorizontal ? 'nowrap' : 'wrap'}
        gap={isHorizontal ? 0 : 2}
        justifyContent='center'
        pr={isHorizontal ? 3 : 0}
      >
        {legendItems?.map((item) => (
          <ChartLegendItem
            key={item.label}
            {...item}
            legendOrientation={isHorizontal ? 'vertical' : 'horizontal'}
          />
        ))}
      </Grid2>
    </Grid2>
  );
};

export default ChartWithLegend;
