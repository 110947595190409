import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ArrowDown,
  ArrowUp,
  CheckCircle,
  CopySimple,
  Plus,
  Trash,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import EditQuestionOption from 'components/Events/Controls/Surveys/EditQuestionOptions';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { SurveyQuestionTypes } from 'constants/survey.constants';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { DefaultOptions, DefaultQuestion } from 'utils/survey';
import { useContextWithFallback } from 'utils/utils';

const StyledSelect = styled(Select)(() => ({
  '& .MuiListItemIcon-root': {
    minWidth: 32,
  },
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '& .MuiSelect-select': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  minWidth: 200,
}));

const EditQuestion = ({
  hasNps,
  isLastQuestion,
  onDelete,
  question,
  questionIndex,
  questionOptions,
  surveyType,
  visibleIndex,
}) => {
  const {
    actions: { applyMulti, applySingle },
    state: { showErrors, surveyFieldIdsToDisplayErrorsOn, surveys },
  } = useContextWithFallback(ManagerContext, SurveyTemplatesContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedType, setSelectedType] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (question?.question_type !== selectedType) {
      setSelectedType(
        SurveyQuestionTypes.find((opt) => opt.value === question?.question_type)
          .value
      );
    }
  }, [question?.question_type, selectedType]);

  const questionLookupId = useMemo(
    () =>
      question.pending_id
        ? question.pending_id
        : question.id
        ? question.id
        : question.position,
    [question.id, question.pending_id, question.position]
  );

  const hasOptions = useMemo(
    () => question?.question_type.includes('_select'),
    [question?.question_type]
  );

  const isFirstQuestion = useMemo(() => visibleIndex === 0, [visibleIndex]);

  const selectedTypeObj = useMemo(
    () => SurveyQuestionTypes.find((opt) => opt.value === selectedType),
    [selectedType]
  );

  const update = (value, field, index, type) => {
    const allSurveyQuestions = _.get(surveys, `${type}.survey_questions`);
    const update = _.cloneDeep(allSurveyQuestions);
    update[index][field] = value;
    applySingle(update, `surveys.${type}.survey_questions`);
  };

  const changeTypeToNps = (updatedSurveyQuestions, allSurveyOptions, index) => {
    updatedSurveyQuestions[index] = DefaultQuestion('nps', index);

    const questions = [
      updatedSurveyQuestions,
      `surveys.${surveyType}.survey_questions`,
    ];
    const npsOptions = DefaultOptions(index, allSurveyOptions?.length, 'nps');

    if (questionOptions) {
      _.forEach(questionOptions, (option) => (option['_destroy'] = 1));
    }

    const updatedOptions = [
      _.concat(allSurveyOptions, npsOptions),
      `surveys.${surveyType}.survey_options`,
    ];
    applyMulti([questions, updatedOptions]);
  };

  const addOptions = (update, survey_options) => {
    const questions = [update, `surveys.${surveyType}.survey_questions`];
    const options = [
      _.concat(
        survey_options,
        DefaultOptions(
          question.id ? question.id : question.position,
          survey_options?.length
        )
      ),
      `surveys.${surveyType}.survey_options`,
    ];
    applyMulti([questions, options]);
  };

  const changeType = (targetQuestionType, field, questionIndex, surveyType) => {
    const allSurveyQuestions = _.get(surveys, `${surveyType}.survey_questions`);
    const allSurveyOptions = _.get(surveys, `${surveyType}.survey_options`);
    const updatedSurveyQuestions = _.cloneDeep(allSurveyQuestions);
    updatedSurveyQuestions[questionIndex][field] = targetQuestionType;
    updatedSurveyQuestions[questionIndex][
      'pending_id'
    ] = `${targetQuestionType}-${dayjs().unix()}`;

    if (hasOptions) {
      switch (targetQuestionType) {
        case 'nps':
          return changeTypeToNps(
            updatedSurveyQuestions,
            allSurveyOptions,
            questionIndex
          );
        default:
          return applySingle(
            updatedSurveyQuestions,
            `surveys.${surveyType}.survey_questions`
          );
      }
    } else {
      switch (targetQuestionType) {
        case 'nps':
          return changeTypeToNps(
            updatedSurveyQuestions,
            allSurveyOptions,
            questionIndex
          );
        case 'single_select':
          return addOptions(updatedSurveyQuestions, allSurveyOptions);
        case 'multi_select':
          return addOptions(updatedSurveyQuestions, allSurveyOptions);
        default:
          return applySingle(
            updatedSurveyQuestions,
            `surveys.${surveyType}.survey_questions`
          );
      }
    }
  };

  const changePosition = (moveDown = true) => {
    let moveToIndex = -1;
    const allQuestions = _.chain(surveys)
      .get(`${surveyType}.survey_questions`)
      .cloneDeep()
      .map((q) => ({ ...q, old_position: q.position }))
      .value();

    // Calculate new index, skipping deleted questions
    for (
      let i = questionIndex + (moveDown ? 1 : -1);
      moveDown ? i < allQuestions?.length : i >= 0;
      i = i + (moveDown ? 1 : -1)
    ) {
      if (allQuestions[i]['_destroy'] !== 1) {
        moveToIndex = i;
        break;
      }
    }

    if (moveToIndex >= 0 && moveToIndex < allQuestions?.length) {
      const otherQuestionsSorted = _.chain(allQuestions)
        .cloneDeep()
        .filter((a, i) => i !== questionIndex)
        .sort((a, b) => _.toNumber(a?.position) - _.toNumber(b?.position))
        .value();
      const allOptions = _.chain(surveys)
        .get(`${surveyType}.survey_options`)
        .cloneDeep()
        .value();

      // Insert question in the new location
      otherQuestionsSorted.splice(moveToIndex, 0, {
        ...question,
        old_position:
          question.position === questionIndex
            ? questionIndex
            : question.position,
      });

      // Update the position of questions to match new order
      _.forEach(otherQuestionsSorted, (q, i) => (q.position = i));

      // Update options to move with the questions
      _.forEach(allOptions, (opt) => {
        if (opt.id === '') {
          // Need to update options associated with non-saved questions and new options added to existing questions
          const oldPosition = opt.survey_question_id;

          // Find out the question to be associated with the option
          const newQuestion = _.find(otherQuestionsSorted, (q) =>
            q.id ? q.id === oldPosition : q.old_position === oldPosition
          );
          // For existing questions, newly added option uses question id; for newly added questions, options use question position
          opt.survey_question_id =
            newQuestion?.id && typeof newQuestion.id === 'number'
              ? newQuestion?.id
              : newQuestion?.position;
        }
      });

      // Removed temporary property
      _.forEach(otherQuestionsSorted, (q) => _.unset(q, 'old_position'));

      applyMulti([
        [otherQuestionsSorted, `surveys.${surveyType}.survey_questions`],
        [allOptions, `surveys.${surveyType}.survey_options`],
      ]);
    }
  };

  const remove = (index, type) => {
    if (question.question_type === 'short') {
      update(1, '_destroy', index, type);
    } else {
      // Update question with destroy tag
      const allSurveyQuestions = _.cloneDeep(
        _.get(surveys, `${type}.survey_questions`)
      );
      allSurveyQuestions[index]['_destroy'] = 1;

      // Update question options with destroy tag
      const allOptions = _.cloneDeep(_.get(surveys, `${type}.survey_options`));
      _.forEach(allOptions, (option) => {
        if (
          option.survey_question_id === question.id ||
          option.survey_question_id === question.position
        ) {
          option['_destroy'] = 1;
        }
      });

      applyMulti([
        [allSurveyQuestions, `surveys.${type}.survey_questions`],
        [allOptions, `surveys.${type}.survey_options`],
      ]);
    }
  };

  const duplicate = (question, index, type) => {
    const allSurveyQuestions = _.get(surveys, `${type}.survey_questions`);
    const allSurveyOptions = _.get(surveys, `${type}.survey_options`);

    const clonedQuestion = _.cloneDeep(question);
    clonedQuestion['id'] = '';
    clonedQuestion['pending_id'] = `${
      question.question_type
    }-${dayjs().unix()}`;
    clonedQuestion['position'] = _.last(allSurveyQuestions)?.position + 1;

    if (
      question.question_type === 'multi_select' ||
      question.question_type === 'single_select'
    ) {
      const updatedQuestions = [
        _.concat(allSurveyQuestions, clonedQuestion),
        `surveys.${type}.survey_questions`,
      ];

      const updatedClonedOptions = _.forEach(
        _.cloneDeep(questionOptions),
        (option, key) => {
          const qId = _.last(allSurveyQuestions)?.position + 1;
          option['id'] = '';
          option['survey_question_id'] = qId;
          option['position'] = key;
          option['pending_id'] = `${qId}-${key || 0}-${dayjs().unix()}`;
        }
      );

      const updatedOptions = [
        _.concat(allSurveyOptions, updatedClonedOptions),
        `surveys.${type}.survey_options`,
      ];

      applyMulti([updatedQuestions, updatedOptions]);
    } else
      applySingle(
        _.concat(allSurveyQuestions, clonedQuestion),
        `surveys.${type}.survey_questions`
      );
  };

  const addOption = (type) => {
    const allSurveyOptions = _.get(surveys, `${surveyType}.survey_options`);
    const groupedOptions = _.groupBy(
      allSurveyOptions,
      (option) => option.survey_question_id
    );
    const qId = question.id ? question.id : question.position;
    const optId = groupedOptions[qId]?.length;
    applySingle(
      _.concat(allSurveyOptions, {
        id: '',
        pending_id: `${qId}-${optId || 0}-${dayjs().unix()}`,
        position: optId,
        survey_question_id: qId,
        text: '',
      }),
      `surveys.${type}.survey_options`
    );
  };

  const handleRemoval = () => {
    remove(questionIndex, surveyType);
    enqueueSnackbar('Question removed!', {
      variant: 'success',
    });
  };

  return question?.['_destroy'] === 1 ? null : (
    <Card
      data-testid='survey-questions-form'
      key={`survey-question-${questionIndex}`}
      variant='outlined'
    >
      <Toolbar
        data-testid='TODO:DATA-TOOLBAR-75569'
        sx={{ bgcolor: theme.palette.grey[100] }}
      >
        <Grid
          alignItems='center'
          container
          direction='row'
          item
          justifyContent='space-between'
          spacing={0.5}
          xs
        >
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='row'
            gap={2}
            item
            xs='auto'
          >
            <Typography>{`${visibleIndex + 1}.`}</Typography>
            <StyledSelect
              disabled={question?.question_type === 'nps'}
              onChange={(e) => {
                const newVal = e.target.value;
                setSelectedType(newVal);
                changeType(newVal, 'question_type', questionIndex, surveyType);
              }}
              value={selectedType}
            >
              {SurveyQuestionTypes.filter((opt) =>
                (surveyType === 'pre_event_survey' || hasNps) &&
                opt.value === 'nps'
                  ? selectedType === 'nps'
                  : true
              ).map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  <ListItemIcon>{type.surveyIcon}</ListItemIcon>
                  <ListItemText>{type.label}</ListItemText>
                </MenuItem>
              ))}
            </StyledSelect>
            {!(isFirstQuestion && isLastQuestion) && (
              <Stack
                data-testid='question-order-buttons'
                flexDirection='row'
                flexGrow={1}
              >
                {!isFirstQuestion && (
                  <Tooltip
                    data-testid='TODO:DATA-TOOLTIP-52629'
                    title='Move question up'
                  >
                    <IconButton
                      data-testid='TODO:DATA-ICONBUTTON-67395'
                      onClick={() => changePosition(false)}
                    >
                      <ArrowUp />
                    </IconButton>
                  </Tooltip>
                )}
                {!isLastQuestion && (
                  <Tooltip
                    data-testid='TODO:DATA-TOOLTIP-62728'
                    title='Move question down'
                  >
                    <IconButton
                      data-testid='TODO:DATA-ICONBUTTON-93513'
                      onClick={() => changePosition(true)}
                    >
                      <ArrowDown />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            )}
          </Grid>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='row'
            flexGrow={1}
            flexWrap='nowrap'
            gap={0.5}
            item
            justifyContent='end'
            xs
          >
            <FormControlLabel
              control={
                <Switch
                  checked={question?.required}
                  id={`${questionIndex}-required`}
                  inputProps={{ 'aria-label': 'question required' }}
                  onChange={() =>
                    update(
                      !question.required,
                      'required',
                      questionIndex,
                      surveyType
                    )
                  }
                />
              }
              label='Required'
            />
            <Tooltip data-testid='TODO:DATA-TOOLTIP-42256' title='Duplicate'>
              <IconButton
                data-testid='TODO:DATA-ICONBUTTON-74603'
                onClick={() => duplicate(question, questionIndex, surveyType)}
                sx={{
                  visibility:
                    question?.question_type === 'nps' ? 'hidden' : 'visible',
                }}
              >
                <CopySimple />
              </IconButton>
            </Tooltip>
            <Tooltip data-testid='TODO:DATA-TOOLTIP-27450' title='Delete'>
              <IconButton
                data-testid='TODO:DATA-ICONBUTTON-17764'
                onClick={() => handleRemoval()}
              >
                <Trash />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      <CardContent
        data-testid='survey-question-wrapper'
        sx={{ '&.MuiCardContent-root:last-child': { pb: 2 } }}
      >
        <TextField
          disabled={question?.question_type === 'nps'}
          error={
            showErrors &&
            surveyFieldIdsToDisplayErrorsOn?.includes(questionLookupId)
              ? question?.title?.trim().length < 1
              : false
          }
          fullWidth
          helperText={
            showErrors &&
            surveyFieldIdsToDisplayErrorsOn?.includes(questionLookupId) &&
            (!question.title || question?.title?.trim().length < 1)
              ? 'Please add a question title'
              : null
          }
          id={`survey-question-${questionIndex + 1}`}
          label='Question'
          multiline
          name='title'
          onChange={(e) =>
            update(e.target.value, 'title', questionIndex, surveyType)
          }
          placeholder={selectedTypeObj?.placeholder}
          required
          value={question?.title || ''}
        />
        {hasOptions && (
          <Box sx={{ mt: 2 }}>
            <FormLabel>
              <span>Options *</span>
              <Typography
                color={
                  showErrors &&
                  surveyFieldIdsToDisplayErrorsOn?.includes(questionLookupId) &&
                  questionOptions.length < 2
                    ? theme.palette.error.main
                    : 'text.secondary'
                }
                display='flex'
                flexDirection='row'
                gap={1}
                gutterBottom
                variant='caption'
              >
                {showErrors &&
                surveyFieldIdsToDisplayErrorsOn?.includes(questionLookupId) &&
                questionOptions.length < 2 ? (
                  'Please add more than 1 option'
                ) : (
                  <>
                    <span>Add up to 10 options </span>
                    {questionOptions?.length < 10 ? null : (
                      <CheckCircle
                        alt='10 option max met'
                        color={theme.palette.success.main}
                        size={16}
                        weight='fill'
                      />
                    )}
                  </>
                )}
              </Typography>
            </FormLabel>
            <Stack alignItems='flex-start' direction='column' gap={1}>
              {questionOptions?.map((so, displayIndex) => {
                const soIndex = _.findIndex(
                  _.get(surveys, `${surveyType}.survey_options`),
                  (thisOpt) => _.isEqual(thisOpt, so)
                );
                return (
                  <EditQuestionOption
                    applySingle={applySingle}
                    isLast={displayIndex === questionOptions?.length - 1}
                    key={`survey-question-${questionIndex}-${displayIndex}`}
                    onDeleteOption={onDelete}
                    option={so}
                    optionIndex={displayIndex}
                    optionResponseIndex={soIndex}
                    question={question}
                    questionIndex={questionIndex}
                    surveys={surveys}
                    surveyType={surveyType}
                  />
                );
              })}
              {questionOptions?.length < 10 ? (
                <Button
                  data-testid='add-option'
                  onClick={() => addOption(surveyType)}
                  size='small'
                  startIcon={<Plus />}
                  variant='text'
                >
                  Add option
                </Button>
              ) : null}
            </Stack>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

EditQuestion.propTypes = {
  hasNps: PropTypes.bool,
  isLastQuestion: PropTypes.bool,
  onDelete: PropTypes.func,
  question: PropTypes.shape({
    id: PropTypes.any,
    pending_id: PropTypes.string,
    position: PropTypes.any,
    question_type: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
  }),
  questionIndex: PropTypes.number,
  questionOptions: PropTypes.array,
  surveyType: PropTypes.string,
  visibleIndex: PropTypes.number,
};

export default EditQuestion;
