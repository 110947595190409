import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Badge, Chip, Stack, Tooltip, Typography } from '@mui/material';
import pluralize from 'pluralize';

import {
  CommunicationFormData,
  CommunicationMethodId,
} from 'components/Events/Controls/Communications/communication.types';
import InfoTooltip from 'components/shared/InfoTooltip';

interface Props {
  asBadge?: boolean;
  text?: string;
}

const UniqueGuestCount = ({ asBadge = false, text }: Props) => {
  const form = useFormContext<CommunicationFormData>();
  const { method } = useParams<{
    method?: CommunicationMethodId;
  }>();

  const isSlack = useMemo(() => method === 'slack', [method]);

  const selectedGuestIds = form.watch('selectedGuestIds');

  const formattedCount = useMemo(
    () =>
      selectedGuestIds && selectedGuestIds.size > 0
        ? Intl.NumberFormat().format(selectedGuestIds.size)
        : '0',
    [selectedGuestIds]
  );

  const isEmptySelection = selectedGuestIds?.size === 0;

  const tooltipText = useMemo(
    () =>
      isEmptySelection
        ? 'No guests selected. Please select at least one guest to send the invite.'
        : `The total number of unique guests selected is ${formattedCount}.`,
    [formattedCount, isEmptySelection]
  );

  const chipColor = isEmptySelection ? 'error' : 'info';
  const chipLabel = isEmptySelection
    ? 'No guests selected'
    : `${formattedCount} ${pluralize('guest', selectedGuestIds?.size)}`;

  return (
    <Stack
      alignItems='center'
      direction='row'
      gap={1}
      justifyContent={asBadge ? 'flex-start' : 'space-between'}
      mt={2}
      pb={2}
      px={2}
    >
      <Typography flex={asBadge ? 0 : 1} variant='h4'>
        {text || 'Selected'}
      </Typography>
      {isSlack ? null : asBadge ? (
        <Tooltip data-testid='guest-count-badge-tooltip' title={tooltipText}>
          <Badge
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            badgeContent={<>{formattedCount}</>}
            color={chipColor}
            component='span'
            data-testid='guest-count-badge'
            sx={{
              '& .MuiBadge-badge': {
                position: 'relative',
                transform: 'none',
                transformOrigin: 'none',
              },
            }}
            variant='light'
          />
        </Tooltip>
      ) : (
        <Chip
          color={chipColor}
          label={
            <InfoTooltip
              content={tooltipText}
              placement='bottom-end'
              text={chipLabel}
              textVariant='overline'
            />
          }
          variant='light'
        />
      )}
    </Stack>
  );
};

export default UniqueGuestCount;
