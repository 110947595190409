import { CheckCircle, Circle, Question, XCircle } from '@phosphor-icons/react';

import { flexColumn } from 'utils/styles';

const rsvpSubmittedText = `Your RSVP has been submitted successfully.`;
const addToCalendarText = `Don’t forget to add this event to your calendar`;
const yesOrMaybePrefix = `${rsvpSubmittedText} ${addToCalendarText}`;

export const rsvpOptions = [
  {
    buttonLabel: 'Yes',
    buttonOutcome: 'RSVP',
    colorName: 'success',
    Icon: CheckCircle,
    id: 'rsvp',
    response: {
      color: 'success',
      id: 'yes',
    },
    rsvpBody: `${yesOrMaybePrefix}. We look forward to seeing you there!`,
    rsvpHeader: 'You’re going!',
    status: 'going',
  },
  {
    buttonLabel: 'No',
    buttonOutcome: 'cancellation',
    colorName: 'info',
    Icon: XCircle,
    id: 'not_attending',
    response: {
      color: 'error',
      id: 'no',
    },
    rsvpBody: `${rsvpSubmittedText} Thanks for letting us know! We hope to see you at the next one.`,
    rsvpHeader: 'You’re not going',
    status: 'not going',
  },
  {
    buttonLabel: 'Maybe',
    buttonOutcome: 'interest',
    colorName: 'warning',
    Icon: Question,
    id: 'maybe_attending',
    response: {
      color: 'warning',
      id: 'maybe',
    },
    rsvpBody: `${yesOrMaybePrefix} and update your RSVP if needed. We hope to see you there!`,
    rsvpHeader: 'You might go!',
    rsvpSecondaryBody: `${rsvpSubmittedText} We hope to see you there!`,
    status: 'interested',
  },
].map((o) => ({
  ...o,
  snackbarMessage: `We’ve let the event organizer know about your ${o.buttonOutcome}`,
}));

const noResponse = {
  buttonLabel: 'No response',
  Icon: Circle,
  response: {
    color: 'info',
    id: 'no_response',
  },
};

export const rsvpResponseValues = [...rsvpOptions, noResponse].map(
  ({ buttonLabel, Icon, response }) => ({
    color: response?.color,
    Icon,
    id: response?.id,
    label: buttonLabel,
  })
);

export const getRsvpStatusDetails = (status) => {
  const details = rsvpOptions.find((opt) => opt.id === status);
  return details || null;
};

// Shared styles

export const rsvpCardHeaderStyles = {
  sx: {
    '& .MuiCardHeader-content': { ...flexColumn, gap: 2 },
    pb: 1,
  },
  titleTypographyProps: { variant: 'h4' },
};

export const rsvpSnackbarMessageGeneric = `Thanks for your response!`;

// Event is soon or now

export const lateRsvpButtonText = `Yes, Iʼll be attending!`;

export const lateRsvpSubheader = (eventName, locationType = '') =>
  `Let your host know if you are now able to make it to "${eventName}"${
    locationType === 'virtual' || locationType === 'hybrid'
      ? ' and you will gain access to the virtual link'
      : ''
  }`;

// RSVP button card

export const rsvpButtonsTitle = {
  default: 'Update your RSVP',
  paused: 'You can no longer change your RSVP',
};

export const rsvpButtonsSubheader = {
  default:
    'If anything changes, please update your RSVP to keep your host informed.',
  paused:
    'This event is not accepting RSVPs. You can no longer change your RSVP response.',
};
