import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { CommunicationMethodId } from 'components/Events/Controls/Communications/communication.types';
import { EventInvitationGuestTypeId } from 'types/EventInvitationGuestType';
import { reactQueryAxios } from 'utils/axios';

interface Params {
  eventId: number;
  invitationId?: number | 'new';
  method?: CommunicationMethodId | null;
}

interface ApiParams {
  apiClient: any;
}

export const useEventInvitation = ({
  eventId,
  invitationId,
  method,
}: Params) => {
  const url = `/api/event_invitations`;

  const apiGetExistingInvitation = async ({
    apiClient,
    invitationId,
  }: {
    invitationId: number | 'new';
  } & ApiParams) =>
    apiClient({
      method: 'get',
      url: `${url}/existing_event_invitation?event_invitation_id=${invitationId}`,
    }).then((response: AxiosResponse) => response.data);

  const apiGetNewInvitation = async ({
    apiClient,
    invitationMethod,
  }: ApiParams & { invitationMethod: CommunicationMethodId }) =>
    apiClient({
      method: 'get',
      url: `${url}/new_event_invitation?event_id=${eventId}&method=${invitationMethod}`,
    }).then((response: AxiosResponse) => response.data);

  const retrieveDefaultValues = useQuery({
    queryFn: () =>
      invitationId
        ? apiGetExistingInvitation({ apiClient: reactQueryAxios, invitationId })
        : method
        ? apiGetNewInvitation({
            apiClient: reactQueryAxios,
            invitationMethod: method,
          })
        : null,
    queryKey: [
      'event',
      eventId,
      'communications',
      'invitation',
      invitationId || 'new',
    ],
  });

  const apiGetSelectionData = async ({
    apiClient,
    guestType,
    selections,
  }: {
    apiClient: any;
    guestType: EventInvitationGuestTypeId;
    selections: any;
  }) =>
    apiClient({
      method: 'get',
      params: selections,
      url: `${url}/${guestType}_selections`,
    });

  const retrieveSelectionData = ({
    guestType,
    selections,
  }: {
    guestType: EventInvitationGuestTypeId;
    selections: any;
  }) =>
    apiGetSelectionData({
      apiClient: reactQueryAxios,
      guestType,
      selections,
    }).then((response: AxiosResponse) => response.data);

  return {
    retrieveDefaultValues,
    retrieveSelectionData,
  };
};
