import { useState } from 'react';
import { capitalize } from '@mui/material';
import { useSnackbar } from 'notistack';

const useCopyToClipboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [copiedText, setCopiedText] = useState(null);

  const copy = async (text, type = 'link') => {
    if (!navigator?.clipboard) {
      enqueueSnackbar(
        'Clipboard not supported. Please check your browser settings.',
        { variant: 'error' }
      );
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      enqueueSnackbar(`${capitalize(type)} copied to clipboard!`, {
        variant: 'success',
      });
      return true;
    } catch (error) {
      enqueueSnackbar(`Unable to copy ${type}.`, {
        variant: 'error',
      });
      setCopiedText(null);
      return false;
    }
  };

  return { copiedText, copy };
};

export default useCopyToClipboard;
