import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Star } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import { ConditionalLoader } from 'components/Core/Loader';
import AutocompleteWithAvatars from 'components/shared/AutocompleteWithAvatars';
import SummaryField from 'components/shared/SummaryField';
import useGetEmployeesForOptions from 'hooks/useGetEmployeesForOptions';
import {
  CollectionFormControl,
  CollectionFormWatch,
} from 'types/CollectionForm';
import { fullName } from 'utils/employee';

interface CollectionOrganizerFieldProps {
  control: CollectionFormControl;
  disableFields: boolean;
  isLoading?: boolean;
  watch: CollectionFormWatch;
}

const CollectionOrganizerField = ({
  control,
  disableFields,
  isLoading = false,
  watch,
}: CollectionOrganizerFieldProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const hosts = watch('organizer_employee_ids');
  const { getEventHosts } = useGetEmployeesForOptions();

  const employeeOptions = useMemo(
    () =>
      getEventHosts.data
        ?.map((o: any) => ({
          additional: o.initials,
          id: o.id,
          label: fullName(o),
          type: 'Organizers',
          value: o.id,
          withAvatar: true,
        }))
        ?.sort((a: any, b: any) =>
          `${a.label} ${a.secondary}`?.localeCompare(
            `${b.label} ${b.secondary}`
          )
        ) || [],
    [getEventHosts.data]
  );

  const processedEmployeeOptions = useMemo(
    () =>
      employeeOptions?.map((option: any) => ({
        ...option,
        label: option.name || option.label,
      })) || [],
    [employeeOptions]
  );

  const onChange = (field: any, newValue: any) => {
    const hasNoSelections = newValue?.length === 0;
    if (hasNoSelections) {
      enqueueSnackbar('Unable to remove. You need at least 1 organizer!', {
        variant: 'error',
      });
    } else {
      const selectedIds = newValue?.map(
        (s: { id?: number; label?: string; value?: number }) =>
          s?.value || s?.id
      );
      field.onChange(selectedIds);
    }
  };

  return (
    <Controller
      control={control}
      name='organizer_employee_ids'
      render={({ field }) => {
        const value =
          field.value
            ?.map((hostId: number) =>
              processedEmployeeOptions.find(
                (opt: { id?: number }) => opt.id === hostId
              )
            )
            .filter(Boolean) || [];

        return (
          <SummaryField
            component={
              <ConditionalLoader conditions={[!processedEmployeeOptions]}>
                <AutocompleteWithAvatars
                  disabled={disableFields}
                  onChange={(_, value) => onChange(field, value)}
                  options={processedEmployeeOptions || []}
                  value={value}
                />
              </ConditionalLoader>
            }
            Icon={Star}
            isEditable={!disableFields}
            isLoading={isLoading}
            primary='Organizer'
            value={value}
            valueType='avatars'
          />
        );
      }}
    />
  );
};

export default CollectionOrganizerField;
