import React, { useContext } from 'react';
import { Stack, Toolbar, useTheme } from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';

import PreviewHeader from 'components/Events/Controls/Shared/PreviewHeader';
import ManageNavbar from 'components/Events/Header/ManageNavbar';
import ViewEventAction from 'components/Events/Header/ViewEventAction';
import DetailsActions from 'components/Events/Steps/DetailsActions';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import {
  EventDetailsFormData,
  EventDetailsFormFormState,
  EventDetailsFormHandleSubmit,
  EventDetailsFormReset,
} from 'types/EventDetailsForm';

interface DetailsHeaderProps {
  cloneEvent: UseMutateFunction<number, Error, number, unknown>;
  disableFields: boolean;
  eventId: number;
  eventName: string;
  formState: EventDetailsFormFormState;
  handleSubmit: EventDetailsFormHandleSubmit;
  hasStarted: boolean;
  isLoading: boolean;
  isPublic: boolean;
  reset: EventDetailsFormReset;
  saveEvent: UseMutateFunction<
    EventDetailsFormData,
    Error,
    EventDetailsFormData,
    unknown
  >;
  showDeleteModal: () => void;
}

const DetailsHeader = ({
  cloneEvent,
  disableFields,
  eventId,
  eventName,
  formState,
  handleSubmit,
  hasStarted,
  isLoading,
  isPublic,
  reset,
  saveEvent,
  showDeleteModal,
}: DetailsHeaderProps) => {
  const theme = useTheme();
  const {
    userProvider: { isOrganizerOrAdmin },
  } = useContext(AuthContext) as AuthContextType;

  return (
    <Stack
      alignItems='stretch'
      direction='column'
      pt={1.5}
      spacing={0}
      width='100%'
    >
      <Toolbar
        data-testid='event-details-header'
        disableGutters
        sx={{
          alignItems: 'flex-start',
          flexDirection: { md: 'row', xs: 'column' },
          gap: theme.spacing(1),
          justifyContent: { md: 'space-between', xs: 'flex-start' },
          px: 0,
          py: 1.5,
        }}
      >
        <PreviewHeader inToolbar />
        <Stack
          alignItems={{
            lg: 'center',
            md: 'flex-end',
            sm: 'center',
            xs: 'center',
          }}
          columnGap={2}
          flexDirection={{
            lg: 'row',
            md: 'column-reverse',
            sm: 'row-reverse',
            xs: 'row',
          }}
          rowGap={1}
        >
          {isOrganizerOrAdmin && (
            <DetailsActions
              cloneEvent={cloneEvent}
              disableFields={disableFields}
              eventId={eventId}
              eventName={eventName}
              formState={formState}
              handleSubmit={handleSubmit}
              hasStarted={hasStarted}
              isLoading={isLoading}
              isPublic={isPublic}
              reset={reset}
              saveEvent={saveEvent}
              showDeleteModal={showDeleteModal}
            />
          )}
          <ViewEventAction />
        </Stack>
      </Toolbar>
      {isOrganizerOrAdmin && (
        <Toolbar
          data-testid='manage-tab-wrapper'
          disableGutters
          sx={{
            alignItems: 'flex-end',
            minHeight: '36px !important',
            px: 0,
            py: 0,
          }}
        >
          <ManageNavbar />
        </Toolbar>
      )}
    </Stack>
  );
};

export default DetailsHeader;
