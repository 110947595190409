import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { Chip, SxProps, Tooltip, Typography } from '@mui/material';
import { SlackLogo } from '@phosphor-icons/react';

interface SSChipProps {
  size?: 'small' | 'medium';
  sx?: SxProps;
}

const SlackSyncedChip: React.FC<SSChipProps> = ({ size, sx = {} }) => {
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  return (
    <Chip
      color='info'
      icon={<SlackLogo size={18} />}
      label={
        <Tooltip placement='top' title='Group members are synced from Slack.'>
          <Typography sx={{ fontWeight: 400 }} variant='h6'>
            <ResponsiveEllipsis
              basedOn='letters'
              maxLine='2'
              text='Synced'
              trimRight
            />
          </Typography>
        </Tooltip>
      }
      size={size}
      sx={sx}
      variant='light'
    />
  );
};

export default SlackSyncedChip;
