import React from 'react';
import { Navigate } from 'react-router-dom';

import { ConditionalLoader } from 'components/Core/Loader';
import { useAuth } from 'hooks/useAuth';

const AuthenticatedRoute = ({ children }: { children: React.ReactElement }) => {
  const {
    isAuthenticated,
    userProvider: { user },
  } = useAuth();

  if (!isAuthenticated) {
    const pathname = window.location.href;
    sessionStorage.setItem('fromPageUrl', JSON.stringify(pathname));
    return <Navigate replace to='/login' />;
  }
  return <ConditionalLoader conditions={[!user]}>{children}</ConditionalLoader>;
};

export default AuthenticatedRoute;
