import { useMemo } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table';

import { CommunicationMethodId } from 'components/Events/Controls/Communications/communication.types';
import { ApiResponsePaginated } from 'types/ApiResponse';
import { EventInvitationGuestTypeId } from 'types/EventInvitationGuestType';

interface Params {
  columnFilters?: MRT_ColumnFiltersState;
  eventId: number;
  globalFilter?: string;
  invitationId?: number | 'new';
  logicalOperator?: 'any' | 'all';
  method?: CommunicationMethodId;
  optionType: EventInvitationGuestTypeId;
  pagination: MRT_PaginationState;
  sorting?: MRT_SortingState;
}

export const useEventInvitationOptions = ({
  columnFilters,
  eventId,
  globalFilter,
  invitationId,
  logicalOperator,
  method,
  optionType,
  pagination,
  sorting,
}: Params) => {
  const url = `/api/event_invitations/${optionType}_options`;

  const queryKey = useMemo(
    () => [
      'event',
      eventId,
      'invitation-method',
      method, // 'email', 'slack', 'calendar'
      'invitation',
      invitationId, // eventInvitationId OR 'new'
      'invitation-options',
      optionType, // 'group', 'list', 'office', 'contact'
      columnFilters,
      globalFilter,
      logicalOperator,
      pagination?.pageSize,
      pagination?.pageIndex,
      sorting,
    ],
    [
      eventId,
      method,
      optionType,
      columnFilters,
      globalFilter,
      invitationId,
      logicalOperator,
      pagination?.pageIndex,
      pagination?.pageSize,
      sorting,
    ]
  );

  const apiRetrieve = useQuery({
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const fetchURL = new URL(`${import.meta.env.VITE_BE_URL}${url}`);
      fetchURL.searchParams.set('event_id', `${eventId}`);
      invitationId !== 'new' &&
        fetchURL.searchParams.set('event_invitation_id', `${invitationId}`);
      fetchURL.searchParams.set('method', `${method}`);
      fetchURL.searchParams.set('page', `${(pagination?.pageIndex || 0) + 1}`);
      fetchURL.searchParams.set('per_page', `${pagination?.pageSize}`);
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('search', globalFilter ?? '');
      if (sorting && sorting?.length > 0) {
        fetchURL.searchParams.set('sorting', JSON.stringify(sorting[0]));
      }
      fetchURL.searchParams.set('filter_type', logicalOperator || '');

      const response = await fetch(fetchURL.href, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const json = (await response.json()) as ApiResponsePaginated;
      return json;
    },
    queryKey,
  });

  return {
    retrieve: apiRetrieve,
  };
};
