import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import {
  CaretUpDown,
  Icon as PhosphorIcon,
  PencilSimple,
  PlusCircle,
} from '@phosphor-icons/react';

import AutocompletePlaceholder from 'components/shared/AutocompletePlaceholder';
import TextWithIcon from 'components/shared/TextWithIcon';

type SummaryFieldType = 'menu' | 'dialog';
type SummaryFieldValueType =
  | 'text'
  | 'avatars'
  | 'none'
  | 'checkmark'
  | 'checkmark-boolean';

interface SummaryFieldProps {
  component?: React.JSX.Element; // field rendered in the DialogContent
  hasChanges?: boolean;
  headerAction?: React.JSX.Element;
  Icon?: PhosphorIcon;
  isEditable?: boolean;
  isLoading?: boolean;
  menuItems?: any[];
  onCancel?: () => unknown;
  onOpen?: () => unknown;
  onSubmit?: (val?: any) => unknown;
  primary?: string;
  secondary?: string;
  selectionLimits?: {
    max?: number;
    min?: number;
  };
  type?: SummaryFieldType;
  typeProps?: any;
  value?: any | null; // displayed in the ListItem
  valueType?: SummaryFieldValueType;
}

const SummaryField = ({
  component,
  hasChanges,
  headerAction,
  Icon,
  isEditable = true,
  isLoading = false,
  menuItems,
  onCancel,
  onOpen,
  onSubmit,
  primary,
  secondary,
  selectionLimits,
  type,
  typeProps,
  value,
  valueType = 'text',
}: SummaryFieldProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState<
    any | any[] | null | undefined
  >(undefined);

  useEffect(() => {
    setPendingValue(value);
  }, [value]);

  const handleButtonClick = useCallback(
    (event: any) => {
      onOpen && onOpen();
      setAnchorEl(event?.currentTarget);
      setIsOpen((previousOpen) => !previousOpen);
    },
    [onOpen]
  );

  const handleDialogClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    setIsOpen(false);
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onCancel && onCancel();
    }
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const isMenu = useMemo(() => type === 'menu', [type]);

  const fieldComponent = useMemo(
    () => (
      <>
        {type === 'dialog' || !component ? (
          <>
            {isEditable ? (
              <Button
                aria-controls={isMenu && isOpen ? primary : undefined}
                aria-describedby={primary}
                aria-expanded={isOpen ? 'true' : undefined}
                aria-haspopup='true'
                color='neutral'
                endIcon={
                  isMenu ? (
                    <CaretUpDown />
                  ) : value && (!Array.isArray(value) || value?.length > 0) ? (
                    <PencilSimple />
                  ) : (
                    <PlusCircle />
                  )
                }
                onClick={(event) => {
                  if (isMenu) {
                    handleButtonClick(event);
                  } else {
                    setIsOpen(true);
                    onOpen && onOpen();
                  }
                }}
                ref={anchorEl}
                sx={{
                  '& .MuiButton-endIcon': {
                    color: 'grey.700',
                  },
                  '&:hover': {
                    bgcolor: 'grey.200',
                  },
                }}
              >
                {valueType === 'text' && value?.length > 0 ? (
                  <Typography color='grey.900'>{value}</Typography>
                ) : (
                  <AutocompletePlaceholder
                    hasCount={valueType !== 'checkmark-boolean'}
                    items={value}
                  />
                )}
              </Button>
            ) : (
              <>
                {valueType === 'text' && value?.length > 0 ? (
                  <Typography color='grey.900'>{value}</Typography>
                ) : (
                  <AutocompletePlaceholder
                    hasCount={valueType !== 'checkmark-boolean'}
                    items={value}
                  />
                )}
              </>
            )}
          </>
        ) : component ? (
          <>{component}</>
        ) : null}
      </>
    ),
    [
      anchorEl,
      component,
      handleButtonClick,
      isEditable,
      isMenu,
      isOpen,
      onOpen,
      primary,
      type,
      value,
      valueType,
    ]
  );

  return (
    <>
      <Stack
        alignItems='center'
        bgcolor='grey.50'
        direction='row'
        gap={1}
        height={48}
        justifyContent='space-between'
        p={1}
      >
        <Stack direction='row' gap={1}>
          <TextWithIcon
            Icon={Icon}
            primary={String(primary)}
            primaryTypographyProps={{ variant: 'body2', whiteSpace: 'nowrap' }}
            textProps={{ flexGrow: 0, flexShrink: 0 }}
          />
        </Stack>
        <Stack
          alignItems='center'
          direction='row'
          gap={1}
          justifyContent='flex-end'
        >
          {isLoading ? (
            <Skeleton animation='wave' height={20} variant='text' width={120} />
          ) : (
            fieldComponent
          )}
        </Stack>
      </Stack>
      {type === 'dialog' ? (
        <Dialog
          fullWidth
          maxWidth='sm'
          onClose={handleDialogClose}
          open={isOpen}
          {...typeProps}
        >
          <DialogTitle>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
            >
              <Typography variant='inherit'>{primary}</Typography>
              {headerAction || null}
            </Stack>
          </DialogTitle>
          <DialogContent>
            {secondary && (
              <DialogContentText
                sx={{ color: 'grey.900', mb: 2, typography: 'body1' }}
              >
                {secondary}
              </DialogContentText>
            )}
            {component}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onCancel && onCancel();
                setIsOpen(false);
              }}
              variant='text'
            >
              Cancel
            </Button>
            <Button
              disabled={!hasChanges}
              onClick={() => {
                onSubmit && onSubmit();
                setIsOpen(false);
              }}
              variant='contained'
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {type === 'menu' ? (
        <Menu
          anchorEl={anchorEl}
          id={`${primary}-menu-id`}
          key={`${primary}-menu`}
          MenuListProps={{
            role: 'listbox',
          }}
          onClose={handleMenuClose}
          open={isOpen}
        >
          {!selectionLimits?.min && (
            <MenuItem
              key='none'
              onClick={() => {
                const newValue = null;
                setPendingValue(newValue);
                onSubmit && onSubmit(newValue);
                handleMenuClose();
              }}
              selected={pendingValue === null}
              sx={{ alignItems: 'flex-start', py: 1.25 }}
            >
              <Typography color='text.secondary' fontStyle='italic'>
                None added
              </Typography>
            </MenuItem>
          )}
          {menuItems?.map((opt) => {
            const Icon = opt?.Icon;
            const optValue = opt?.id || opt?.value;
            const optLabel = opt?.name || opt?.label?.primary;
            return (
              <MenuItem
                key={optValue}
                onClick={() => {
                  setPendingValue(optValue);
                  onSubmit && onSubmit(opt);
                  handleMenuClose();
                }}
                selected={
                  pendingValue
                    ? pendingValue === opt
                    : value
                    ? optValue === value
                    : false
                }
                sx={{ alignItems: 'flex-start', py: 1.25 }}
              >
                <TextWithIcon
                  Icon={Icon}
                  primary={optLabel}
                  secondary={opt?.label?.secondary || ''}
                />
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </>
  );
};

export default SummaryField;
