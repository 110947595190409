import React, { useContext } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import { LayoutContext } from 'contexts/LayoutContext';
import { flexColumn } from 'utils/styles';

const OnboardFrame = ({ children, conditions, subtitle, title }) => {
  const { isMobile } = useContext(LayoutContext);
  const theme = useTheme();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('lg'));
  const columnProps = {
    item: true,
    lg: 6,
    md: 12,
    sm: 12,
    xs: 12,
  };

  return (
    <ConditionalLoader conditions={conditions}>
      <Grid
        alignItems='stretch'
        container
        data-testid='onboard-wrapper'
        direction='row'
        height='100vh'
        justifyContent='center'
        overflow={smallScreen ? 'auto' : 'hidden'}
        sx={{
          background:
            'radial-gradient(433.91% 102.14% at 81.67% 87.19%, #CEE7E7 0%, #025963 100%)',
        }}
        // display='flex'
        width='100vw'
        wrap='nowrap'
      >
        {!smallScreen && (
          <Grid
            data-testid='onboard-image'
            sx={{
              background:
                'radial-gradient(433.91% 102.14% at 81.67% 87.19%, #CEE7E7 0%, #025963 100%)',
            }}
            {...columnProps}
          >
            <Box
              p={8}
              sx={{
                ...flexColumn,
                alignItems: 'center',
                backgroundImage: `url('/images/people-in-crowd.svg')`,
                backgroundPosition: 'left 0 top 150px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '100%',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <img
                alt='Five to Nine logo'
                src='/images/logo-white.svg'
                width='340'
              />
            </Box>
          </Grid>
        )}
        <Grid
          backgroundColor={smallScreen ? 'transparent' : 'white'}
          data-testid='onboard-form'
          sx={!smallScreen ? { overflowY: 'auto' } : {}}
          {...columnProps}
        >
          {smallScreen && (
            <Box p={3} {...flexColumn} alignItems='center'>
              <img
                alt='Five to Nine logo'
                src='/images/logo-white.svg'
                width='340'
              />
            </Box>
          )}
          <Card
            data-testid='TODO:DATA-CARD-95971'
            elevation={0}
            sx={{
              ...(isMobile ? { p: 2 } : { px: 10, py: 4 }),
              ...(smallScreen ? { maxWidth: '90%', minWidth: '60%' } : {}),
              mx: 'auto',
            }}
            variant='elevation'
          >
            <CardHeader
              subheader={subtitle}
              sx={{ pb: 1 }}
              title={title}
              titleTypographyProps={{
                component: 'h1',
                variant: 'h2',
              }}
            />
            <CardContent data-testid='onboard-form-wrapper'>
              {children}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ConditionalLoader>
  );
};

OnboardFrame.propTypes = {
  children: PropTypes.node,
  conditions: PropTypes.any,
  subtitle: PropTypes.any,
  title: PropTypes.string,
};
export default OnboardFrame;
