/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import {
  MRT_GlobalFilterTextField,
  MRT_RowData,
  MRT_TableInstance,
} from 'material-react-table';

import SearchStartAdornment from 'components/Core/SearchStartAdornment';

const MRTTopToolbarWrapper = <TData extends MRT_RowData>({
  children,
  nounPlural,
  table,
  wrapperSxProps = { bgcolor: 'white' },
}: {
  children?: React.ReactElement;
  nounPlural?: string;
  table: MRT_TableInstance<TData>;
  wrapperSxProps?: SxProps;
}) => (
  <Box pb={2} pt={1} sx={wrapperSxProps}>
    <Box minWidth={150}>
      <MRT_GlobalFilterTextField
        InputProps={{ startAdornment: <SearchStartAdornment /> }}
        margin='none'
        placeholder={['Search', ...(nounPlural ? [nounPlural] : [])].join(' ')}
        size='medium'
        sx={{
          '& .MuiInputAdornment-positionEnd': {
            opacity: table.getState().globalFilter?.length > 0 ? 1 : 0,
          },
          flexShrink: 0,
          minWidth: 150,
        }}
        table={table}
      />
    </Box>
    {children}
  </Box>
);

export default MRTTopToolbarWrapper;
