import React from 'react';
import { FormControl, FormLabel, Skeleton, Stack } from '@mui/material';

const EventFormDateTimeSkeleton = () => (
    <Stack
      columnGap={1.5}
      display='grid'
      gridTemplateColumns='repeat(2, 1fr)'
      rowGap={2}
    >
      <FormControl>
        <FormLabel required>Start date</FormLabel>
        <Skeleton animation='wave' height={44} />
      </FormControl>
      <FormControl>
        <FormLabel>Start time</FormLabel>
        <Skeleton animation='wave' height={44} />
      </FormControl>
      <FormControl>
        <FormLabel required>End date</FormLabel>
        <Skeleton animation='wave' height={44} />
      </FormControl>
      <FormControl>
        <FormLabel>End time</FormLabel>
        <Skeleton animation='wave' height={44} />
      </FormControl>
    </Stack>
  );

export default EventFormDateTimeSkeleton;
