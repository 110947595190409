import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import { omit } from 'lodash';

import { communicationMethods } from 'components/Events/Controls/Communications/communication.constants';
import {
  CommunicationMethod,
  CommunicationMethodId,
  CommunicationTypeId,
} from 'components/Events/Controls/Communications/communication.types';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { EmployeesContext } from 'contexts/EmployeesContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { ListsContext } from 'contexts/ListsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { useSyncedSlackChannels } from 'hooks/useSyncedSlackChannels';
import { getPersonalCalendar } from 'utils/calendar';
import { useGoogleCalendarLogin } from 'utils/google';
import { FindSelected } from 'utils/invitations';
import { useMicrosoftLogin } from 'utils/microsoft';

const useSelectCommunicationToSend = () => {
  const {
    actions: {
      applyMulti,
      applySingle,
      deleteCommunication,
      saveGoogleAuth,
      saveMicrosoftAuth,
      sendCommunication,
      setActiveInvitationMethod: setActiveMethod,
      setOpenInvitationMethod: setOpenMethod,
    },
    state: {
      activeInvitationMethod: activeMethod,
      hostsEmployees,
      inManagerContext,
      savedEvent,
    },
  } = useContext(ManagerContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;
  const { data: syncedChannels } = useSyncedSlackChannels();
  const { employees } = useContext(EmployeesContext);
  const [groups] = useContext(GroupsContext);
  const [lists] = useContext(ListsContext);
  const [organization] = useContext(OrganizationContext);

  const isCommsFlagEnabled = useFlag('event-communications');
  const navigate = useNavigate();
  useParams<{ id: string }>();

  const [pendingSendTime, setPendingSendTime] = useState(null);
  const [savedSendTime, setSavedSendTime] = useState(null);

  const signInGoogle = useGoogleCalendarLogin(
    saveGoogleAuth,
    () => null,
    organization
  );

  const { signIn: signInMicrosoft } = useMicrosoftLogin(
    organization,
    () => null,
    saveMicrosoftAuth
  );

  const signInCalendar = useCallback(
    (provider: string) => {
      switch (provider) {
        case 'google':
          return signInGoogle();
        case 'outlook':
          return signInMicrosoft();
        default:
          break;
      }
    },
    [signInGoogle, signInMicrosoft]
  );

  const needsCalAuth = useMemo(() => {
    switch (organization?.calendar_enabled) {
      case 'none':
        return false;
      case 'google':
        return !user?.has_google_calendar_auth;
      case 'outlook':
        return !user?.has_outlook_auth;
    }
  }, [
    organization?.calendar_enabled,
    user?.has_google_calendar_auth,
    user?.has_outlook_auth,
  ]);

  const openMethodAndSetUrl = useCallback(
    (
      method: CommunicationMethodId,
      commType: CommunicationTypeId,
      commId?: string | number
    ) => {
      setOpenMethod(method);
      if (isCommsFlagEnabled && inManagerContext) {
        const baseUrl = `${commType}/${method}`;
        navigate(commId ? [baseUrl, commId].join('/') : baseUrl);
      }
    },
    [inManagerContext, isCommsFlagEnabled, navigate, setOpenMethod]
  );

  const getRecipientObjects = useCallback(
    (type: string, ids: Array<number>) => {
      switch (type) {
        case 'groups':
          return FindSelected(groups, ids);
        case 'invitees':
          return employees?.filter((employee: any) =>
            ids.includes(employee.id)
          );
        case 'lists':
          return FindSelected(lists, ids);
        case 'offices':
          return FindSelected(organization?.offices, ids);
        case 'slackchannels':
          return syncedChannels?.filter((channel: any) =>
            ids?.includes(channel?.slack_id)
          );
      }
    },
    [syncedChannels, employees, groups, lists, organization?.offices]
  );

  const invitationMethods = useMemo(() => communicationMethods, []);

  const enabledMethods = useMemo(
    () =>
      [...communicationMethods].filter((m: CommunicationMethod) => {
        switch (m.id) {
          case 'slack':
            return organization?.slack_enabled;
          case 'calendar':
            return (
              organization?.calendar_enabled !== 'none' &&
              needsCalAuth === false
            );
          default:
            return true;
        }
      }),
    [needsCalAuth, organization?.calendar_enabled, organization?.slack_enabled]
  );

  const messageMethods = useMemo(
    () =>
      [...invitationMethods].filter(
        (m: CommunicationMethod) => m.id !== 'calendar'
      ),
    [invitationMethods]
  );

  const select = useCallback(
    (
      method: CommunicationMethodId,
      commType: CommunicationTypeId,
      commId?: string | number
    ) => {
      if (commId) {
        const communication = savedEvent?.communication_histories?.find(
          (c: any) => c.id === Number(commId)
        );
        if (communication) {
          if (commType === 'survey') {
            const surveyUrl = `../surveys/`;
            navigate(surveyUrl, {
              relative: 'path',
              state: { fromCommunications: true },
            });
          } else {
            if (
              commType === 'invitation' &&
              communication?.event_invitation_ids
            ) {
              const commInvitations = savedEvent?.event_invitations?.filter(
                (i: any) => communication?.event_invitation_ids?.includes(i?.id)
              );
              // INFO: commInvitations could contains more than one invite
              // when it is slack + calendar or calendar + email or multiple selected calendars
              if (commInvitations?.length > 0) {
                const selectedCalendars: any = [];
                const flatCommInvitations = commInvitations?.map(
                  (commInvitation: any) => {
                    const personalCalendar = user
                      ? getPersonalCalendar(user)
                      : null;
                    if (method === 'calendar') {
                      const inviteCalendar = commInvitation?.calendar
                        .is_personal
                        ? personalCalendar
                        : {
                            label: commInvitation.calendar.name,
                            value: commInvitation.calendar.calendar_id,
                          };
                      selectedCalendars.push(inviteCalendar);
                    }

                    const data = {
                      ...Object.fromEntries(
                        commInvitation?.recipients.map((r: any) => {
                          const recipient_type = r?.type?.toLowerCase();
                          return [
                            recipient_type === 'slackchannels'
                              ? 'channels'
                              : recipient_type,
                            getRecipientObjects(
                              recipient_type,
                              recipient_type === 'invitees'
                                ? r?.employee_ids
                                : r?.ids
                            ),
                          ];
                        })
                      ),
                      ...(method === 'calendar' && {
                        calendars: commInvitation?.calendar.is_personal
                          ? [personalCalendar]
                          : [commInvitation?.calendar],
                      }),
                      ...(method === 'slack' && {
                        has_calendar_invite:
                          communication.delivery_methods.includes('calendar'),
                      }),
                      ...omit(commInvitation, 'recipients'),
                    };

                    return data;
                  }
                );
                // INFO: need to find the flat mapped invitation that matches the current selected method
                const comm = flatCommInvitations.find(
                  (commInvite: any) => commInvite.invite_type === method
                );

                const commData = { ...comm, calendars: selectedCalendars };
                setSavedSendTime(comm?.scheduled_for);
                applyMulti([
                  [{ [method]: commData }, `invites`],
                  [
                    {
                      [method]: {
                        invite_body: comm.invite_body,
                        invite_subject: comm.invite_subject,
                      },
                    },
                    'invite_message',
                  ],
                  [method, 'invite_method'],
                ]);
              }
            } else if (
              commType === 'message' &&
              communication?.event_message_ids
            ) {
              const comm = savedEvent?.event_messages?.find((i: any) =>
                communication?.event_message_ids.includes(i?.id)
              );
              if (comm) {
                setSavedSendTime(comm?.scheduled_for);
                applySingle(comm, `message`);
              }
            }
            openMethodAndSetUrl(method, commType, commId);
          }
        }
      } else {
        if (commType === 'invitation') {
          if (method === 'calendar' && needsCalAuth) {
            signInCalendar(organization?.calendar_enabled);
          } else {
            if (
              (savedEvent?.event_invitations?.length > 0 &&
                method === activeMethod) ||
              inManagerContext
            ) {
              applySingle(method, 'invite_method');
            } else {
              if (method !== 'slack') {
                applyMulti([
                  [hostsEmployees, `invites.${method}.invitees`],
                  [method, 'invite_method'],
                ]);
              } else {
                applySingle(method, 'invite_method');
              }
            }
            openMethodAndSetUrl(method, commType, commId);
          }
          if (activeMethod === 'discarded') {
            setActiveMethod(method);
          }
        } else if (commType === 'message') {
          applySingle(method, 'invite_method');
          openMethodAndSetUrl(method, commType, commId);
        }
      }
    },
    [
      savedEvent?.communication_histories,
      savedEvent?.event_invitations,
      savedEvent?.event_messages,
      navigate,
      openMethodAndSetUrl,
      applyMulti,
      user,
      getRecipientObjects,
      applySingle,
      needsCalAuth,
      activeMethod,
      signInCalendar,
      organization?.calendar_enabled,
      inManagerContext,
      hostsEmployees,
      setActiveMethod,
    ]
  );

  const open = ({
    invitationId,
    method,
    type,
  }: {
    invitationId?: number;
    method: CommunicationMethodId;
    type?: CommunicationTypeId;
  }) => {
    applySingle(method, 'invite_method');
    openMethodAndSetUrl(method, type || 'invitation', invitationId);
  };

  const sendNow = (commId: string | number, commType: string) =>
    sendCommunication(commId, commType);

  const deleteNow = (commId: string | number, commType: string) =>
    deleteCommunication(commId, commType);

  return {
    deleteNow,
    enabledMethods,
    invitationMethods,
    messageMethods,
    needsCalAuth,
    open,
    pendingSendTime,
    savedSendTime,
    select,
    sendNow,
    setPendingSendTime,
    setSavedSendTime,
  };
};

export default useSelectCommunicationToSend;
