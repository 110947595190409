import React, { useContext, useState } from 'react';
import { Dialog, Grid } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useFlag } from '@unleash/proxy-client-react';

import { ConditionalLoader } from 'components/Core/Loader';
import EmployeeDataUpload from 'components/Settings/Integrations/EmployeeDataUpload';
import GoogleGroups from 'components/Settings/Integrations/GoogleGroups';
import GoogleGroupsModal from 'components/Settings/Integrations/GoogleGroupsModal';
import GoogleSharedCalendar from 'components/Settings/Integrations/GoogleSharedCalendar';
import OktaIntegrationCard from 'components/Settings/Integrations/OktaIntegrationCard';
import OutlookSharedCalendar from 'components/Settings/Integrations/OutlookSharedCalendar';
import SharedCalendarSidebar from 'components/Settings/Integrations/SharedCalendarSidebar';
import SlackIntegrationCard from 'components/Settings/Integrations/SlackIntegrationCard';
import { OrganizationContext } from 'contexts/OrganizationContext';

const IntegrationSettings = () => {
  const [org, setOrg] = useContext(OrganizationContext);

  const isContactListsFlagEnabled = useFlag('contact-lists');

  const [editMode, setEditMode] = useState('null');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ConditionalLoader conditions={[isLoading]}>
      <Grid alignItems='stretch' container spacing={3}>
        {org.calendar_enabled === 'google' && (
          <Grid container item lg={3} md={4} sm={6}>
            <GoogleOAuthProvider
              clientId={import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID}
            >
              <GoogleSharedCalendar
                org={org}
                setOrg={setOrg}
                startEditing={() => {
                  setIsDialogOpen(true);
                  setEditMode('google_cal');
                }}
              />
            </GoogleOAuthProvider>
          </Grid>
        )}
        <Grid container item lg={3} md={4} sm={6}>
          <SlackIntegrationCard org={org} setOrg={setOrg} />
        </Grid>
        {org.calendar_enabled !== 'none' && isContactListsFlagEnabled && (
          <Grid container item lg={3} md={4} sm={6}>
            <GoogleOAuthProvider
              clientId={import.meta.env.VITE_GOOGLE_ADMIN_CLIENT_ID}
            >
              <GoogleGroups />
            </GoogleOAuthProvider>
          </Grid>
        )}
        {org.calendar_enabled === 'outlook' && (
          <Grid container item lg={3} md={4} sm={6}>
            <OutlookSharedCalendar
              startEditing={() => {
                setIsDialogOpen(true);
                setEditMode('outlook_cal');
              }}
            />
          </Grid>
        )}
        <Grid container item lg={3} md={4} sm={6}>
          <OktaIntegrationCard />
        </Grid>
        <Grid container item lg={3} md={4} sm={6}>
          <EmployeeDataUpload setIsLoading={setIsLoading} />
        </Grid>
      </Grid>
      <Dialog
        data-testid='TODO:DATA-DIALOG-70753'
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
      >
        {editMode === 'google_cal' ? (
          <SharedCalendarSidebar provider='Google' setShow={setIsDialogOpen} />
        ) : null}

        {editMode === 'outlook_cal' ? (
          <SharedCalendarSidebar provider='Outlook' setShow={setIsDialogOpen} />
        ) : null}

        {editMode === 'google_groups' ? <GoogleGroupsModal /> : null}
      </Dialog>
    </ConditionalLoader>
  );
};

export default IntegrationSettings;
