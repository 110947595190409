import React from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

const LabeledTextColumn = ({
  label,
  text,
}: {
  label: string;
  text: string;
}) => (
  <Stack direction='column' flex={1} gap={0.5}>
    <Typography fontWeight={500} variant='overline'>
      {label}
    </Typography>
    <Typography variant='body1'>{text}</Typography>
  </Stack>
);

export default LabeledTextColumn;
