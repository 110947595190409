import React from 'react';
import {
  AutocompleteElement,
  AutocompleteElementProps,
} from 'react-hook-form-mui';

import { ExpenseCategory } from 'types/EventBudget';

interface Props extends AutocompleteElementProps {
  name: string;
  options: ExpenseCategory[];
}

const EventExpenseCategoryField = ({ name, options, ...props }: Props) => (
  <AutocompleteElement
    matchId
    name={name}
    options={options.map((opt) => ({ id: opt.id, label: opt.name }))}
    {...props}
  />
);

export default EventExpenseCategoryField;
