import React from 'react';
import { FormLabel, Skeleton, Stack } from '@mui/material';

import AutocompletePlaceholder from 'components/shared/AutocompletePlaceholder';
import ImageField from 'components/shared/ImageField';
import {
  CollectionFormControl,
  CollectionFormCoverImageData,
  CollectionFormWatch,
} from 'types/CollectionForm';

export interface ImageDimensions {
  height?: number;
  width?: number;
  x?: number;
  y?: number;
}

export interface CollectionBannerImageFieldProps {
  control?: CollectionFormControl;
  disableFields: boolean;
  isLoading?: boolean;
  isPublished?: boolean;
  watch: CollectionFormWatch;
}

const CollectionBannerImageField = ({
  control,
  disableFields,
  isLoading = false,
  isPublished,
  watch,
}: CollectionBannerImageFieldProps) => {
  const coverImage = watch('collection_image') as CollectionFormCoverImageData;

  return (disableFields && !coverImage) || isLoading ? (
    <Stack direction='column' gap={0.25}>
      <FormLabel>
        {isLoading ? 'Collection banner image' : 'Collection image'}
      </FormLabel>
      {isLoading ? (
        <Skeleton animation='wave' height={256} variant='rounded' />
      ) : (
        <AutocompletePlaceholder items={[]} />
      )}
    </Stack>
  ) : (
    <ImageField
      control={control}
      id='collection-image'
      isDisabled={disableFields}
      label='Collection image'
      name='collection_image'
      {...(isPublished
        ? {
            uploadSubtext: 'No banner image uploaded',
            uploadText: `Collection published!`,
          }
        : {
            uploadText: 'Drop an image here or click to browse your files',
          })}
    />
  );
};
export default CollectionBannerImageField;
