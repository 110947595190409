import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Collapse, IconButton, Stack, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  CopySimple,
  Link,
  Spinner,
  Trash,
} from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { LayoutContext } from 'contexts/LayoutContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { getEventLink, hasStarted } from 'utils/event';

const DetailsActions = () => {
  const {
    actions: { cloneEvent, deleteEvent, saveEvent, setShowErrors },
    state: {
      disableFields,
      errors,
      event,
      hasDateTimeErrors,
      isLoading,
      savedEvent,
      unsavedEventChanges,
    },
    validation: { hasErrors: hasValidationErrors },
  } = useContext(ManagerContext);
  const { isMobile } = useContext(LayoutContext);

  const { copy } = useCopyToClipboard();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isAnimating, setIsAnimating] = useState(false);

  const hasErrors = useMemo(
    () => hasDateTimeErrors || errors?.length > 0 || hasValidationErrors,
    [hasDateTimeErrors, errors?.length, hasValidationErrors]
  );

  const handleSaveEvent = () => {
    if (hasErrors) {
      setShowErrors(true);
      enqueueSnackbar('Please resolve errors before saving changes!', {
        variant: 'error',
      });
    } else {
      saveEvent();
    }
  };

  return (
    <Stack direction='row' justifyContent='flex-end' spacing={1}>
      {isMobile && (
        <Tooltip data-testid='TODO:DATA-TOOLTIP-79066' title='Back to events'>
          <span>
            <IconButton
              data-testid='TODO:DATA-ICONBUTTON-96856'
              onClick={() => navigate('/events')}
            >
              <ArrowLeft />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!hasStarted(event) && (
        <Tooltip data-testid='delete-event' title='Delete event'>
          <span>
            <IconButton
              data-testid='TODO:DATA-ICONBUTTON-92640'
              disabled={disableFields}
              onClick={deleteEvent}
            >
              <Trash />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Tooltip data-testid='clone-event' sx={{ order: 4 }} title='Clone event'>
        <span>
          <IconButton
            data-testid='clone-event'
            onClick={() => cloneEvent(event.id)}
          >
            <CopySimple />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip data-testid='copy-event-link' title='Copy event link'>
        <span>
          <IconButton
            data-testid='copy-event-link'
            onClick={() =>
              copy(
                getEventLink('event', undefined, savedEvent || event),
                'event link'
              )
            }
          >
            <Link />
          </IconButton>
        </span>
      </Tooltip>
      <Collapse
        in={unsavedEventChanges}
        onAnimationEnd={() => setIsAnimating(false)}
        onAnimationStart={() => setIsAnimating(true)}
        orientation='horizontal'
        unmountOnExit
      >
        <LoadingButton
          {...(isLoading || (!unsavedEventChanges && isAnimating)
            ? {
                loadingPosition: 'start',
                startIcon: <Spinner />,
              }
            : {})}
          color='primary'
          data-testid='save-changes'
          loading={isLoading || (!unsavedEventChanges && isAnimating)}
          onClick={handleSaveEvent}
          sx={{ whiteSpace: 'nowrap' }}
          variant='contained'
        >
          {isLoading ? 'Saving' : 'Save changes'}
        </LoadingButton>
      </Collapse>
    </Stack>
  );
};

export default DetailsActions;
