import React, { useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CalendarCheck,
  CheckCircle,
  ClipboardText,
} from '@phosphor-icons/react';
import { isNaN, sumBy } from 'lodash';

import AnalyticsAvatar from 'components/Analytics/AnalyticsAvatar';

const Metrics = ({ events, isLoading }) => {
  const theme = useTheme();

  const totalInvited = useMemo(() => sumBy(events, 'invited_count'), [events]);
  const totalAccepted = useMemo(() => sumBy(events, 'rsvp_count'), [events]);

  const totalSurveyResponses = useMemo(
    () => sumBy(events, 'survey_responses_count'),
    [events]
  );

  const totalAttended = useMemo(
    () => sumBy(events, 'attended_count'),
    [events]
  );

  const getTotal = useMemo(
    () => (totalAccepted > totalAttended ? totalAccepted : totalAttended),
    [totalAccepted, totalAttended]
  );

  const uniqueAttended = useMemo(
    () => new Set(events?.flatMap((e) => e.attended_employee_ids)),
    [events]
  );

  const surveyRate = useMemo(
    () => Math.round((totalSurveyResponses / getTotal) * 100),
    [getTotal, totalSurveyResponses]
  );

  const acceptedRate = useMemo(
    () => Math.round((totalAccepted / totalInvited) * 100),
    [totalAccepted, totalInvited]
  );

  const metrics = useMemo(
    () => [
      {
        Icon: ClipboardText,
        isDisabled: isNaN(surveyRate),
        primary: 'Survey responses',
        secondary:
          'Number of guests that submitted a post-event survey out of the number of guests that accepted the event invitation',
        value: isNaN(surveyRate) ? 0 : `${surveyRate}%`,
      },
      {
        Icon: CheckCircle,
        isDisabled: isNaN(acceptedRate),
        primary: 'Accepted rate',
        secondary:
          'Number of guests that accepted an invitation to your events out of the number of guests invited',
        value: isNaN(acceptedRate) ? 0 : `${acceptedRate}%`,
      },
      {
        Icon: CalendarCheck,
        // TODO: Add pluralize once this label has been changed back to "attendees"
        primary: 'Unique attendees',
        secondary: 'Number of unique guests that attended at least 1 event',
        value: uniqueAttended.size,
      },
    ],
    [acceptedRate, surveyRate, uniqueAttended?.size]
  );

  return (
    <Card data-testid='TODO:DATA-CARD-71598'>
      <CardHeader title='Metrics' />
      <CardContent>
        <Stack
          direction={{ lg: 'row', md: 'column', sm: 'row', xs: 'column' }}
          flexWrap='wrap'
          gap={1}
        >
          {metrics?.map(({ Icon, primary, secondary, value }) => (
            <Tooltip
              data-testid='TODO:DATA-TOOLTIP-27488'
              key={primary}
              title={secondary}
            >
              <Stack direction='row' flex={1} gap={1.5} wrap='nowrap'>
                <AnalyticsAvatar
                  color={{
                    dark: theme.palette.grey[900],
                    light: theme.palette.grey[100],
                  }}
                  Icon={Icon}
                />
                <Stack direction='column'>
                  {(value || value === 0) && !isLoading ? (
                    <Typography fontWeight={600} variant='h4'>
                      {value}
                    </Typography>
                  ) : (
                    <Skeleton height={27} variant='text' width={42} />
                  )}
                  <Typography
                    display='block'
                    lineHeight={1.1}
                    variant='overline'
                  >
                    {primary}
                  </Typography>
                </Stack>
              </Stack>
            </Tooltip>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Metrics;
