import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  capitalize,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { FloppyDisk } from '@phosphor-icons/react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import AddQuestionMenu from 'components/Events/Controls/Surveys/AddQuestionMenu';
import CreateSurveyTemplateButton from 'components/Events/Controls/Surveys/CreateSurveyTemplateButton';
import EditQuestion from 'components/Events/Controls/Surveys/EditQuestion';
import ScheduleSurvey from 'components/Events/Controls/Surveys/ScheduleSurvey';
import SelectSurveyTemplate from 'components/Events/Controls/Surveys/SelectSurveyTemplate';
import SurveyAnonymity from 'components/Events/Controls/Surveys/SurveyAnonymity';
import SurveyPreview from 'components/Events/Controls/Surveys/SurveyPreview';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import DescriptionButton from 'components/shared/DescriptionButton';
import { LayoutContext } from 'contexts/LayoutContext';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { DefaultOptions, DefaultQuestion } from 'utils/survey';
import { useContextWithFallback } from 'utils/utils';

const TimeRegex = /^\d+$/;

const EditSurveyCard = ({ children, headerProps, type }) => {
  const { isMobile } = useContext(LayoutContext);
  const {
    actions: {
      applyMulti,
      applySingle,
      clearPendingSurvey,
      deleteSurvey,
      savePostSurvey,
      savePreSurvey,
      saveSurvey,
      setShowErrors,
      setSurveyErrors,
      setSurveyFieldIdsToDisplayErrorsOn,
    },
    state: {
      event,
      inManagerContext,
      inSurveyTemplatesContext,
      isWorking,
      surveyErrors,
      surveys,
    },
    validation: { getOptionErrors, hasFieldErrors },
  } = useContextWithFallback(ManagerContext, SurveyTemplatesContext);
  const { enqueueSnackbar } = useSnackbar();

  const [delaySendSurvey, setDelaySendSurvey] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);

  const isPublished = useMemo(() => _.get(event, 'published'), [event]);

  useEffect(() => {
    const errors = type ? getOptionErrors(type) : [];
    if (errors) {
      setSurveyErrors(errors);
    } else {
      setSurveyErrors({});
    }
  }, [getOptionErrors, setSurveyErrors, type]);

  const addQuestionWithNoOptions = (surveyType, questionType) => {
    const allSurveyQuestions = _.get(surveys, `${surveyType}.survey_questions`);
    applySingle(
      _.concat(
        allSurveyQuestions,
        DefaultQuestion(
          questionType,
          _.last(allSurveyQuestions)?.position + 1 || 0
        )
      ),
      `surveys.${surveyType}.survey_questions`
    );
  };

  const addQuestionWithOptions = (surveyType, questionType) => {
    const allSurveyQuestions = _.get(surveys, `${surveyType}.survey_questions`);
    const question = [
      _.concat(
        allSurveyQuestions,
        DefaultQuestion(
          questionType,
          _.last(allSurveyQuestions)?.position + 1 || 0
        )
      ),
      `surveys.${surveyType}.survey_questions`,
    ];

    const option = [
      _.concat(
        _.get(surveys, `${surveyType}.survey_options`),
        DefaultOptions(_.last(allSurveyQuestions)?.position + 1 || 0)
      ),
      `surveys.${surveyType}.survey_options`,
    ];
    applyMulti([question, option]);
  };

  const hasNps = useMemo(
    () =>
      _.some(
        surveys?.post_event_survey?.survey_questions,
        (q) => q.question_type === 'nps' && !q._destroy
      ),
    [surveys]
  );

  const surveyDescriptionTooltip = `Your survey description will be displayed ${
    type === 'post_event_survey'
      ? 'on your survey and on the email sent to guests with the survey link.'
      : 'when a guest accepts an invite to your event.'
  }`;

  const visibleQuestions = useMemo(
    () =>
      _.chain(surveys)
        .get(`${type}.survey_questions`)
        .filter((q) => q._destroy !== 1)
        .value(),
    [surveys, type]
  );

  const surveyTemplateHasQuestions = useMemo(
    () =>
      _.some(
        _.get(surveys, `${type}.survey_questions`),
        (q) => q['_destroy'] !== 1
      ),
    [surveys, type]
  );

  const surveyHasQuestions = useMemo(
    () =>
      (_.get(surveys, `${type}.enabled`) || _.get(surveys, `${type}.id`)) &&
      surveyTemplateHasQuestions,
    [surveys, surveyTemplateHasQuestions, type]
  );

  const surveyHasValidDelayTime = useMemo(
    () =>
      (surveys?.post_event_survey?.delay &&
        TimeRegex.test(surveys?.post_event_survey?.delay?.split('.')[0])) ||
      !delaySendSurvey,
    [delaySendSurvey, surveys?.post_event_survey?.delay]
  );

  const unableToSave = useMemo(
    () =>
      !surveyHasQuestions || hasFieldErrors(type) || !surveyHasValidDelayTime,
    [hasFieldErrors, surveyHasQuestions, type, surveyHasValidDelayTime]
  );

  const hasError = (subject) => {
    if (unableToSave) {
      if (!surveyHasQuestions) {
        enqueueSnackbar(`Your ${subject} needs at least one question to save`, {
          variant: 'error',
        });
        return true;
      } else if (surveyErrors.length > 0) {
        enqueueSnackbar(
          `Please solve the following errors before saving your survey: ${surveyErrors
            .map((e) => e.message)
            .join(', ')}`,
          { variant: 'error' }
        );
        return true;
      } else {
        enqueueSnackbar(
          `We can't save this ${subject} until all errors have been resolved.`,
          { variant: 'error' }
        );
        return true;
      }
    } else {
      return false;
    }
  };

  const handleErrorsDisplay = () => {
    const ids = surveyErrors
      .filter((e) => !_.isNil(e?.questionId) || !_.isNil(e?.optionId))
      .map((e) => e.optionId || e.questionId);
    setSurveyFieldIdsToDisplayErrorsOn(ids);
    setShowErrors(true);
    hasError('survey');
  };

  const handleSaveSurvey = () => {
    if (surveyErrors.length > 0) {
      handleErrorsDisplay();
    } else {
      setShowErrors(false);
      if (event?.published) {
        saveSurvey();
      } else {
        if (type === 'pre_event_survey') {
          savePreSurvey();
        }
        if (type === 'post_event_survey') {
          savePostSurvey();
        }
      }
    }
  };

  const groupedSurveyOptions = useMemo(
    () =>
      _.groupBy(
        _.filter(
          _.get(surveys, `${type}.survey_options`),
          (option) => !option?._destroy
        ),
        (option) => option.survey_question_id
      ),
    [surveys, type]
  );

  const questionComponents = useMemo(
    () =>
      _.get(surveys, `${type}.survey_questions`)
        ?.sort((a, b) => +a?.position - +b?.position)
        ?.map((question, i) => (
          <EditQuestion
            hasNps={hasNps}
            isLastQuestion={_.isEqual(_.last(visibleQuestions), question)}
            key={question.pending_id || question.id}
            onDelete={() => {
              /* setErrors([]); */
            }}
            question={question}
            questionIndex={i}
            questionOptions={
              question?.question_type === 'short'
                ? null
                : (question.id
                    ? groupedSurveyOptions[question.id]
                    : groupedSurveyOptions[question.position]) || []
            }
            surveyType={type}
            visibleIndex={visibleQuestions.findIndex((q) =>
              _.isEqual(q, question)
            )}
          />
        )),
    [groupedSurveyOptions, hasNps, surveys, type, visibleQuestions]
  );

  const handleAddQuestion = (questionType) => {
    switch (questionType) {
      case 'multi_select':
      case 'single_select':
        addQuestionWithOptions(type, questionType);
        break;
      default:
        addQuestionWithNoOptions(type, questionType);
        break;
    }
  };

  const handleAnonymityChange = (e) => {
    applySingle(e.target.checked, `surveys.${type}.display_responders`);
  };

  return (
    <>
      <Card
        data-testid={
          inSurveyTemplatesContext ? 'templates' : 'edit-survey-card'
        }
      >
        {!inSurveyTemplatesContext && <CardHeader {...headerProps} />}
        <CardContent sx={{ pt: 0 }}>
          <Stack
            alignItems='stretch'
            direction='column'
            gap={4}
            justifyContent='flex-start'
          >
            {children}
            {!inSurveyTemplatesContext && (
              <>
                <DescriptionButton
                  handleChange={(val) =>
                    applySingle(val, `surveys.${type}.description`)
                  }
                  labelStyles={{ mb: 0.75 }}
                  noMargin
                  showLabel
                  showPreview={false}
                  showTypeInLabel
                  tooltip={surveyDescriptionTooltip}
                  truncateLong
                  type='survey'
                  value={_.get(surveys, `${type}.description`) || ''}
                />
                <SurveyAnonymity
                  onChange={handleAnonymityChange}
                  type={type}
                  value={_.get(surveys, `${type}.display_responders`)}
                />
                {type === 'post_event_survey' && (
                  <ScheduleSurvey
                    delaySendSurvey={delaySendSurvey}
                    setDelaySendSurvey={setDelaySendSurvey}
                    surveyHasValidDelayTime={surveyHasValidDelayTime}
                  />
                )}
              </>
            )}
            {(_.get(surveys, `${type}.enabled`) ||
              _.get(surveys, `${type}.id`)) &&
            _.some(
              _.get(surveys, `${type}.survey_questions`),
              (q) => q['_destroy'] !== 1
            ) ? (
              <Stack direction='column'>
                <FormLabel sx={{ paddingBottom: '12px !important' }}>
                  Survey questions
                </FormLabel>
                <Stack
                  alignItems='stretch'
                  direction='column'
                  gap={2}
                  justifyContent='flex-start'
                >
                  {questionComponents}
                </Stack>
              </Stack>
            ) : (
              <Typography mt={2} variant='body1'>
                {`Start building a survey by${
                  inSurveyTemplatesContext
                    ? ''
                    : ' selecting a survey template, or by'
                }
          adding your first question`}
              </Typography>
            )}
            <Stack direction={isMobile ? 'column' : 'row'} gap={1}>
              {!inSurveyTemplatesContext && (
                <SelectSurveyTemplate
                  activeTemplateId={
                    event?.[type]?.survey_template_id ||
                    surveys?.[type]?.survey_template_id
                  }
                  buttonVariant='outlined'
                  surveyType={type}
                />
              )}
              <AddQuestionMenu
                handleAddQuestion={handleAddQuestion}
                hasNps={hasNps}
                surveyType={type}
              />
              {surveyHasQuestions ? null : (
                <Button
                  data-testid='cancel'
                  onClick={() => clearPendingSurvey(type)}
                  variant='text'
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          {_.some(
            _.get(surveys, `${type}.survey_questions`),
            (q) => q['_destroy'] !== 1
          ) && !inSurveyTemplatesContext ? (
            <>
              {!inManagerContext
                ? _.get(surveys, `${type}`)?.id && (
                    <Button
                      data-testid='delete'
                      onClick={() => setIsDeleting(true)}
                      variant='text'
                    >
                      Delete
                    </Button>
                  )
                : null}
              <Button
                data-testid='cancel'
                onClick={() => clearPendingSurvey(type)}
                variant='text'
              >
                Cancel
              </Button>
              <Button
                data-testid='preview'
                onClick={() => setIsPreviewing(true)}
                variant='text'
              >
                Preview
              </Button>
              {!inManagerContext && (
                <CreateSurveyTemplateButton
                  handleErrorsDisplay={() => handleErrorsDisplay()}
                  inEditor={!inSurveyTemplatesContext}
                  style={type.split('_')[0]}
                />
              )}
              <LoadingButton
                color='primary'
                data-testid='save'
                loading={isWorking}
                onClick={() => handleSaveSurvey()}
                startIcon={<FloppyDisk />}
                variant='contained'
              >
                Save
                {headerProps?.title
                  ? ` ${headerProps?.title.toLowerCase()}`
                  : ''}
              </LoadingButton>

              <SurveyPreview
                displayResponders={_.get(surveys, `${type}.display_responders`)}
                handleClose={() => setIsPreviewing(false)}
                show={isPreviewing}
                type={type}
              />
            </>
          ) : null}
        </CardActions>
      </Card>
      <DeleteModal
        noun='survey'
        onCancel={() => {
          setIsDeleting(false);
        }}
        onDelete={(onDone) => {
          deleteSurvey && deleteSurvey(type);
          onDone();
          setIsDeleting(false);
        }}
        owner={event?.name}
        show={isDeleting}
        subject={`${capitalize(type.split('_')[0])}-event survey`}
      />
    </>
  );
};

EditSurveyCard.propTypes = {
  children: PropTypes.any,
  headerProps: PropTypes.object,
  type: PropTypes.string,
};

export default EditSurveyCard;
