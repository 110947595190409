import React, { useEffect, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';

import ProfileIntegrationCard from 'components/Profile/ProfileIntegrationCard';
import { integrationsByIdMap } from 'constants/integration.constants';
import useZoomAuth from 'hooks/useZoomAuth';

const ProfileCardZoom = () => {
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const orgAllowsZoomWebinar = useFlag('zoom-webinar');

  const {
    canEnableZoomWebinar,
    connectToZoom,
    disconnectZoom,
    isZoomMeetingConnected,
    isZoomWebinarConnected,
    setFinalUrl,
  } = useZoomAuth({
    initialUrl: '/profile',
  });

  useEffect(() => setFinalUrl(window.location.href), [setFinalUrl]);

  useEffect(() => {
    if (
      isDisconnecting &&
      (isZoomMeetingConnected === false || isZoomWebinarConnected === false)
    ) {
      setIsDisconnecting(false);
    }
  }, [isDisconnecting, isZoomMeetingConnected, isZoomWebinarConnected]);

  const isConnected = orgAllowsZoomWebinar
    ? isZoomWebinarConnected
      ? true
      : !!isZoomMeetingConnected
    : isZoomMeetingConnected;

  return (
    <ProfileIntegrationCard
      canConnectSecondaryService={
        isZoomMeetingConnected &&
        !isZoomWebinarConnected &&
        orgAllowsZoomWebinar &&
        canEnableZoomWebinar
      }
      canDisconnect
      isConnected={isConnected}
      isDisconnecting={isDisconnecting}
      onButtonClick={(action) => {
        if (action === 'disconnect') {
          setIsDisconnecting(true);
          disconnectZoom();
        } else {
          connectToZoom();
        }
      }}
      service={integrationsByIdMap.get(
        isZoomWebinarConnected ? 'zoom_webinar' : 'zoom_meeting'
      )}
    />
  );
};
export default ProfileCardZoom;
