import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Stack } from '@mui/material';

import ExpenseCategoriesForm from 'components/Settings/ExpenseCategoriesForm';
import ExpenseCategoryChip from 'components/Settings/ExpenseCategoryChip';
import Organization from 'types/Organization';

const ExpenseCategoriesSettings = ({
  organization,
}: {
  organization: Organization | undefined;
}) => (
  <Card>
    <CardHeader title='Expense categories' />
    <CardContent>
      <Stack alignItems='flex-start' gap={1.5}>
        <Box
          component='ul'
          display='flex'
          flexWrap='wrap'
          gap={1}
          justifyContent='flex-start'
          m={0}
          p={0}
          sx={{ listStyle: 'none' }}
        >
          {organization?.expense_categories?.map((category) => (
            <Box component='li' key={category.id}>
              <ExpenseCategoryChip category={category} />
            </Box>
          ))}
        </Box>
        <ExpenseCategoriesForm />
      </Stack>
    </CardContent>
  </Card>
);

export default ExpenseCategoriesSettings;
