const microsoftOutlook = {
  endpoint: 'outlook',
  label: 'Microsoft Outlook calendar',
  uriSuffix: `/auth/outlook`,
};

export const calendarServices = {
  google: {
    endpoint: 'gcal',
    label: 'Google Calendar',
  },
  microsoft: microsoftOutlook,
  outlook: microsoftOutlook,
};

export const calendarDisplayName = {
  google: calendarServices.google.label,
  outlook: calendarServices.microsoft.label,
};
