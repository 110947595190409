import { useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const GcalEventForm = ({ id, subdomain }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    axios
      .get(
        `${
          import.meta.env.VITE_BE_URL
        }/api/events/${id}/external_calendar?subdomain=${subdomain}`
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.replace(res.data.gcal_form_url);
        }
      })
      .catch(() => {
        enqueueSnackbar(
          'You are trying to view an event that is not part of your organization',
          { variant: 'error' }
        );
        window.location.replace('/login');
      });
  });
  return null;
};

export default GcalEventForm;
