import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { isOver } from 'utils/event';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const useEventRelativeTime = (event: any, live = false) => {
  const [currentDateTime, setCurrentDateTime] = useState(dayjs());
  const [shouldPoll, setShouldPoll] = useState(live);

  useEffect(() => {
    if (isOver(event) && shouldPoll) {
      setShouldPoll(false);
    }
  }, [event, shouldPoll]);

  useEffect(() => {
    let interval: undefined | any;
    if (shouldPoll) {
      interval = setInterval(() => {
        setCurrentDateTime(dayjs());
      }, 1000); // 1 second
      // TODO: set ms to higher value until isSoon, then decrease to a more frequent interval until isPast
    }
    return () => interval && clearInterval(interval);
  }, [shouldPoll]);

  const eventStart = dayjs(event?.start);
  const eventEnd = dayjs(event?.ends);

  const minutesUntilStart = useMemo(
    () => eventStart?.diff(currentDateTime, 'minute', true),
    [currentDateTime, eventStart]
  );

  const isUpcoming = useMemo(() => minutesUntilStart > 60, [minutesUntilStart]);

  const isToday = useMemo(
    () => eventStart?.isSame(currentDateTime, 'date'),
    [currentDateTime, eventStart]
  );

  const isSoon = useMemo(
    () => minutesUntilStart > 0 && minutesUntilStart <= 60,
    [minutesUntilStart]
  );

  const isFuture = useMemo(
    () => eventStart?.isAfter(currentDateTime),
    [currentDateTime, eventStart]
  );

  const isNow = useMemo(
    () =>
      eventStart?.isBefore(currentDateTime) &&
      eventEnd?.isAfter(currentDateTime),
    [currentDateTime, eventEnd, eventStart]
  );

  const isPast = useMemo(
    () => eventEnd?.isBefore(currentDateTime),
    [currentDateTime, eventEnd]
  );

  return {
    currentDateTime,
    eventEnd,
    eventStart,
    isFuture,
    isNow,
    isPast,
    isSoon,
    isToday,
    isUpcoming,
  };
};

export default useEventRelativeTime;
