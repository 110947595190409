import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  useTheme,
} from '@mui/material';
import { sortBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { MinusCircle } from '@phosphor-icons/react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import Search from 'components/Core/Search';
import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import TabLabelWithCount from 'components/shared/TabLabelWithCount';
import { ListsContext } from 'contexts/ListsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { searchFilter } from 'utils/list';

const GoogleGroupsModal = ({
  axios,
  groups,
  handleClose,
  handleRemove,
  show,
}) => {
  // groups -> non-synced groups
  // lists -> synced groups
  const [lists, setLists] = useContext(ListsContext);
  const [org] = useContext(OrganizationContext);

  const { enqueueSnackbar } = useSnackbar();

  const [isWorking, setIsWorking] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [tryClose, setTryClose] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (!showDelete && setToDelete !== null) {
      // This prevents the "delete" modal from flashing "Undefined" before closing
      setTimeout(() => setToDelete(null), 500);
    }
  }, [showDelete]);

  const syncedGroups = useMemo(
    () => lists?.filter((list) => list?.is_google_group),
    [lists]
  );

  const filteredSyncedGroups = useMemo(
    () =>
      sortBy(
        syncedGroups?.filter((group) => searchFilter(searchVal, group)),
        (g) => g?.name?.toLowerCase()
      ),
    [syncedGroups, searchVal]
  );

  const filteredOtherGroups = useMemo(
    () =>
      sortBy(
        groups?.filter((group) => searchFilter(searchVal, group)),
        (g) => g?.name?.toLowerCase()
      ),
    [groups, searchVal]
  );

  const startDelete = (group) => {
    setToDelete(group);
    setShowDelete(true);
  };

  const deleteGroup = (modalCallback) => {
    if (toDelete && !isWorking) {
      setIsWorking(true);
      axios(
        {
          method: 'delete',
          url: `/api/lists/${toDelete.id}`,
        },
        (res) => {
          modalCallback();
          handleRemove();
          setLists(res.data);
          setShowDelete(false);
          setIsWorking(false);
          enqueueSnackbar(`"${toDelete.name}" group disconnected!`, {
            variant: 'success',
          });
        },
        () => {
          modalCallback();
          setIsWorking(false);
          enqueueSnackbar('Something went wrong, please try again.', {
            variant: 'error',
          });
        }
      );
    }
  };

  const handleToggle = (group) => {
    const currentIndex = selectedGroups.findIndex(
      (g) => g.email === group?.email
    );
    const newChecked = [...selectedGroups];
    if (currentIndex === -1) {
      newChecked.push(group);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedGroups(newChecked);
  };

  const closeModal = () => {
    setSelectedGroups([]);
    setSearchVal('');
    handleClose();
  };

  const addGroups = () => {
    const subject =
      selectedGroups?.length === 1
        ? `"${selectedGroups[0].name}" group`
        : pluralize('Google group', selectedGroups?.length, true);
    setIsWorking(true);
    axios(
      {
        data: { groups: selectedGroups?.map((g) => g.email) },
        method: 'post',
        url: '/api/lists/sync_google_groups',
      },
      (res) => {
        setLists(res.data);
        closeModal();
        setIsWorking(false);
        enqueueSnackbar(`${subject} synced!`, {
          variant: 'success',
        });
      },
      () => {
        setIsWorking(false);
        enqueueSnackbar('Something went wrong! Please try again', {
          variant: 'error',
        });
      }
    );
  };

  const GroupList = ({ children, groupList, label, showCount }) => (
    <>
      <ListSubheader sx={{ bgcolor: 'grey.200', px: 3 }}>
        {showCount ? (
          <TabLabelWithCount count={groupList?.length} label={label} />
        ) : (
          label
        )}
      </ListSubheader>
      {groupList?.length > 0 ? (
        children
      ) : (
        <Typography color='text.secondary' sx={{ px: 3 }} variant='body1'>
          No groups
        </Typography>
      )}
    </>
  );

  const checkUnsavedBeforeClosing = () =>
    selectedGroups?.length > 0 ? setTryClose(true) : closeModal();

  return (
    <>
      <Dialog
        aria-label='Manage Google Groups'
        onClose={checkUnsavedBeforeClosing}
        open={show}
        scroll='paper'
      >
        <DialogTitle>
          <Typography variant='inherit'>Manage Google Groups</Typography>
          <Typography display='block' sx={{ mb: 2 }} variant='overline'>
            Select the groups you would like to sync to your
            organization&rsquo;s Contact Lists.
          </Typography>
          <Search
            fullWidth
            label='Search groups'
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder='Search'
            value={searchVal}
          />
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <List data-testid='TODO:DATA-LIST-88972' disablePadding>
            {syncedGroups?.length > 0 && (
              <GroupList
                groupList={filteredSyncedGroups}
                label={`Synced ${pluralize('group', syncedGroups?.length)}`}
                showCount
              >
                {filteredSyncedGroups?.map((group) => (
                  <ListItem disableGutters disablePadding key={group?.id}>
                    <ListItemButton
                      onClick={() => startDelete(group)}
                      role={undefined}
                      sx={{ pl: 3 }}
                    >
                      <ListItemText
                        id={`${group?.id}-checkbox-label`}
                        primary={group?.name}
                        primaryTypographyProps={{ variant: 'body1' }}
                      />
                      <ListItemIcon slot='end'>
                        <MinusCircle
                          color={theme.palette.error.main}
                          size={20}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </GroupList>
            )}
            <GroupList
              groupList={filteredOtherGroups}
              label={`${syncedGroups?.length > 0 ? 'Other g' : 'G'}roups`}
            >
              {filteredOtherGroups?.map((group) => (
                <ListItem disableGutters disablePadding key={group?.email}>
                  <ListItemButton
                    onClick={() => handleToggle(group)}
                    role={undefined}
                    sx={{ px: 3 }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          selectedGroups?.findIndex(
                            (g) => g.email === group?.email
                          ) !== -1
                        }
                        data-testid='TODO:DATA-CHECKBOX-19474'
                        defaultValue={
                          syncedGroups?.findIndex(
                            (g) => g.name === group?.name
                          ) !== -1
                        }
                        edge='start'
                        inputProps={{
                          'aria-labelledby': `${group?.email}-checkbox-label`,
                        }}
                        sx={{ ml: '-6px' }}
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={`${group?.email}-checkbox-label`}
                      primary={group?.name}
                      primaryTypographyProps={{ variant: 'body1' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </GroupList>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            data-testid='cancel'
            onClick={checkUnsavedBeforeClosing}
            variant='text'
          >
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            data-testid='sync-selected'
            disabled={selectedGroups?.length === 0 || isWorking}
            loading={isWorking}
            onClick={addGroups}
            variant='contained'
          >
            Sync selected {pluralize('group', selectedGroups?.length)}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <DeleteModal
        noun='group'
        onCancel={() => setShowDelete(false)}
        onDelete={deleteGroup}
        owner={org?.display_name}
        show={showDelete}
        subject={`"${toDelete?.name}"`}
        verb='Disconnect'
      />
      <UnsavedChangesModal
        hasUnsavedChanges={selectedGroups?.length > 0}
        onCancel={() => setTryClose(false)}
        onConfirm={() => {
          setTryClose(false);
          setSelectedGroups([]);
          handleClose();
        }}
        tryClose={tryClose}
      />
    </>
  );
};

GoogleGroupsModal.propTypes = {
  axios: PropTypes.func,
  groups: PropTypes.array,
  handleClose: PropTypes.func,
  handleRemove: PropTypes.func,
  show: PropTypes.any,
};

export default GoogleGroupsModal;
