import React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { CaretUpDown, Icon, IconProps } from '@phosphor-icons/react';

interface MenuOption {
  action?: () => unknown;
  Icon?: Icon;
  iconProps?: IconProps;
  id: number | string;
  primary: string;
  secondary?: string;
}

interface Props {
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  handleButtonClick: () => unknown;
  label: string;
  options: MenuOption[];
}

const SplitButton = ({ color, handleButtonClick, label, options }: Props) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleMenuToggle = () => setIsMenuOpen((prevOpen) => !prevOpen);

  const handleMenuClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsMenuOpen(false);
  };

  return (
    <>
      <ButtonGroup
        aria-label='Button group with a nested menu'
        color={color || 'primary'}
        fullWidth
        ref={anchorRef}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr min-content',
        }}
        variant='contained'
      >
        <Button onClick={handleButtonClick}>{label}</Button>
        <Button
          aria-controls={isMenuOpen ? 'split-button-menu' : undefined}
          aria-expanded={isMenuOpen ? 'true' : undefined}
          aria-haspopup='menu'
          aria-label='select merge strategy'
          onClick={handleMenuToggle}
          size='small'
        >
          <CaretUpDown />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef?.current}
        disablePortal
        open={isMenuOpen}
        placement='bottom-end'
        role={undefined}
        sx={{
          maxWidth: 331,
          zIndex: 2,
        }}
        transition
      >
        {({ placement, TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement.includes('bottom')
                ? 'right top'
                : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id='split-button-menu'>
                  {options.map(
                    ({
                      action,
                      Icon: OptionIcon,
                      iconProps,
                      id,
                      primary,
                      secondary,
                    }) => (
                      <MenuItem
                        key={id}
                        onClick={(event: any) => {
                          action && action();
                          handleMenuClose(event);
                        }}
                        sx={{
                          '& .MuiListItemIcon-root': {
                            minWidth: 'unset',
                          },
                          gap: 1,
                        }}
                      >
                        {OptionIcon && (
                          <ListItemIcon
                            sx={{
                              ...(secondary ? { alignSelf: 'flex-start' } : {}),
                            }}
                          >
                            <OptionIcon {...iconProps} size={20} />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={primary}
                          secondary={secondary}
                          secondaryTypographyProps={{
                            variant: 'overline',
                            whiteSpace: 'normal',
                          }}
                        />
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
