import { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { GroupsContext } from 'contexts/GroupsContext';
import { useEventBudget } from 'hooks/useEventBudget';
import { EventBudgetSettingsFormData, LinkedGroup } from 'types/EventBudget';

export const useEventBudgetSettingsForm = ({
  eventId,
}: {
  eventId: number;
}) => {
  const {
    retrieve: { data },
    update,
  } = useEventBudget({ eventId });

  const [groups] = useContext(GroupsContext);

  const form = useForm<EventBudgetSettingsFormData>({
    criteriaMode: 'all',
    defaultValues: {
      currencyId: 1,
      groupIds: [],
      groups: [],
      linkedGroups: [],
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    values: {
      amount: data?.amount,
      currencyId: data?.currency_id || 1,
      groupIds: data?.linked_groups?.map((g: LinkedGroup) => g.id) as number[],
      groups: groups?.filter((g: { id: number }) =>
        data?.linked_groups?.some((lg) => lg.id === g.id)
      ),
      linkedGroups: data?.linked_groups || [],
    },
  });

  const resetForm = useCallback(() => {
    form.reset({
      amount: data?.amount,
      currencyId: data?.currency_id,
      groupIds: data?.linked_groups?.map((g: { id: any }) => g.id) as number[],
      groups: groups?.filter((g: { id: number }) =>
        data?.linked_groups?.some((lg) => lg.id === g.id)
      ),
      linkedGroups: data?.linked_groups,
    });
  }, [data, form, groups]);

  return { data, form, resetForm, update };
};
