import React, { useContext, useMemo, useState } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  CheckCircle,
  Link,
  Prohibit,
  Trash,
  VideoCamera,
} from '@phosphor-icons/react';

import DeleteModal from 'components/Core/DeleteModal';
import { useDeleteZoomLink } from 'components/Event/useDeleteZoomLink';
import SplitButton from 'components/shared/SplitButton';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import {
  EventDetailsFormMeetingData,
  EventDetailsFormSetValue,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';
import { Integration } from 'types/Integration';

interface Props {
  copyLink: () => unknown;
  creatorId?: number;
  eventId: number;
  meeting?: EventDetailsFormMeetingData;
  meetingType?: Integration;
  setValue: EventDetailsFormSetValue;
  watch: EventDetailsFormWatch;
}

const EventVirtualMeetingMenu = ({
  copyLink,
  creatorId,
  eventId,
  meeting,
  meetingType,
  setValue,
  watch,
}: Props) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;

  const [isRemovingAttendanceTracking, setIsRemovingAttendanceTracking] =
    useState(false);
  const [isRemovingMeeting, setIsRemovingMeeting] = useState(false);

  const theme = useTheme();
  const locationType = watch('locationType');

  // Delete Zoom Link
  const { mutate: deleteZoomLink } = useDeleteZoomLink({});

  const isZoomService = useMemo(
    () =>
      meetingType?.id === 'zoom_meeting' || meetingType?.id === 'zoom_webinar',
    [meetingType]
  );

  const removeMeeting = () => {
    if (isZoomService) {
      if (creatorId != null) {
        deleteZoomLink(eventId);
      }
    } else {
      const newLocationType = locationType === 'virtual' ? undefined : 'hybrid';

      setValue('locationType', newLocationType);
      setValue(
        'meeting',
        {
          link: null,
          service: null,
          serviceUid: null,
        },
        { shouldDirty: true }
      );

      setIsRemovingMeeting(false);
    }
  };

  const cancelRemove = () => {
    if (isRemovingMeeting) {
      setIsRemovingMeeting(false);
    } else if (isRemovingAttendanceTracking) {
      setIsRemovingAttendanceTracking(false);
    }
  };

  const toggleAttendanceTracking = () => {
    const changeTracking = !meeting?.attendanceTracking;
    const changeMeeting = {
      ...meeting,
      attendanceTracking: changeTracking,
    };
    // INFO: This setTimeout prevents the "Attendance tracking" option from changing
    //       immediately after being clicked, since the menu takes a second to close
    const updateTimer = setTimeout(() => {
      setValue('meeting', changeMeeting);
      setIsRemovingAttendanceTracking(false);
      clearTimeout(updateTimer);
    }, 100);
  };

  const confirmRemove = () => {
    if (isRemovingMeeting) {
      removeMeeting();
    } else if (isRemovingAttendanceTracking) {
      toggleAttendanceTracking();
    }
  };

  const meetingLink = useMemo(
    () =>
      meetingType?.joinUrlHidden && user?.id === creatorId
        ? 'https://zoom.us/signin#/login'
        : meeting?.link,
    [creatorId, meeting?.link, meetingType?.joinUrlHidden, user?.id]
  );

  return (
    <Stack direction='column' gap={1}>
      <SplitButton
        color={meetingType?.id === 'unknown' ? 'primary' : 'info'}
        handleButtonClick={() => meetingLink && location.assign(meetingLink)}
        label={String(meetingType?.joinButton)}
        options={[
          {
            action: copyLink,
            Icon: Link,
            id: 'copy-link',
            primary: 'Copy meeting link',
          },
          {
            action: () => setIsRemovingMeeting(true),
            Icon: Trash,
            id: 'remove-meeting',
            primary: `Remove ${meetingType?.label}`,
          },
          ...(isZoomService
            ? [
                meeting?.attendanceTracking
                  ? {
                      action: () => setIsRemovingAttendanceTracking(true),
                      Icon: Prohibit,
                      iconProps: {
                        color: theme.palette.error.main,
                      },
                      id: 'disable-tracking',
                      primary: 'Disable attendance tracking',
                      secondary: `Stop tracking attendance via guest registration upon entering ${meetingType?.label}`,
                    }
                  : {
                      action: () => toggleAttendanceTracking(),
                      Icon: CheckCircle,
                      iconProps: {
                        color: theme.palette.success.main,
                      },
                      id: 'enable-tracking',
                      primary: 'Enable attendance tracking',
                      secondary: `Track attendance by requiring guests to register before entering ${meetingType?.label}.`,
                    },
              ]
            : []),
        ]}
      />
      <Stack alignItems='center' direction='row' gap={1}>
        {meetingType?.logoAvatar || (
          <VideoCamera color={theme.palette.primary.main} />
        )}
        <Typography variant='overline'>{meetingLink}</Typography>
      </Stack>
      <DeleteModal
        custom={{
          byline: isRemovingAttendanceTracking
            ? 'Without attendance tracking, you will need to manually update your Guest List in order to reflect accurate attendance information. User data will no longer be captured via Zoom registration.'
            : `This ${meetingType?.label} will no longer be listed as the Virtual location of your event.`,
          confirm: isRemovingAttendanceTracking
            ? 'Disable attendance tracking'
            : `Remove ${meetingType?.label}`,
        }}
        onCancel={cancelRemove}
        onDelete={(onDone?: () => unknown) => {
          confirmRemove();
          onDone && onDone();
        }}
        show={isRemovingMeeting || isRemovingAttendanceTracking}
        subject={
          isRemovingAttendanceTracking
            ? 'attendance tracking'
            : 'virtual location'
        }
        verb={isRemovingAttendanceTracking ? 'Disable' : 'Remove'}
      />
    </Stack>
  );
};

export default EventVirtualMeetingMenu;
