import { useMemo } from 'react';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_Row,
  MRT_SortingState,
} from 'material-react-table';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';

import { Contact } from 'components/Contacts/ContactsTable';
import { ApiResponsePaginated } from 'types/ApiResponse';
import { axiosAuthenticated, reactQueryAxios } from 'utils/axios';

interface AsyncParams {
  apiClient: any;
  id?: number;
}

export const useEmployees = ({
  columnFilters,
  globalFilter,
  logicalOperator = 'any',
  pagination,
  sorting,
}: {
  columnFilters?: MRT_ColumnFiltersState;
  globalFilter?: string;
  logicalOperator?: 'any' | 'all';
  pagination: MRT_PaginationState;
  sorting?: MRT_SortingState;
}) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const url = '/api/employees';

  const queryKey = useMemo(
    () => [
      'employees',
      columnFilters, // refetch when columnFilters changes
      globalFilter, // refetch when globalFilter changes
      logicalOperator,
      pagination?.pageIndex, // refetch when pagination.pageIndex changes
      pagination?.pageSize, // refetch when pagination.pageSize changes
      sorting, // refetch when sorting changes
    ],
    [
      columnFilters,
      globalFilter,
      logicalOperator,
      pagination?.pageIndex,
      pagination?.pageSize,
      sorting,
    ]
  );

  const apiRetrieveAll = useQuery({
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const fetchURL = new URL(`${import.meta.env.VITE_BE_URL}${url}`);
      fetchURL.searchParams.set('page', `${(pagination?.pageIndex || 0) + 1}`);
      fetchURL.searchParams.set('per_page', `${pagination?.pageSize}`);
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('search', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      fetchURL.searchParams.set('filter_type', logicalOperator || '');

      const response = await fetch(fetchURL.href, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const json = (await response.json()) as ApiResponsePaginated;
      return json;
    },
    queryKey,
  });

  const apiRetrieve = async ({ apiClient, id }: AsyncParams) =>
    apiClient({
      method: 'get',
      url: `${url}/${id}`,
    });

  const apiCreate = async ({
    apiClient,
    data,
  }: AsyncParams & {
    data: any;
  }) =>
    apiClient({
      data,
      method: 'post',
      url,
    });

  const apiUpdate = async ({
    apiClient,
    data,
    id,
  }: AsyncParams & {
    data: any;
  }) =>
    apiClient({
      data,
      method: 'patch',
      url: `${url}/${id}`,
    });

  const apiRemove = async ({ apiClient, id }: AsyncParams) =>
    apiClient({
      method: 'delete',
      url: `${url}/${id}`,
    });

  const apiRemoveMultiple = async ({
    apiClient,
    data,
  }: {
    apiClient: any;
    data: any;
  }) =>
    apiClient({
      data,
      method: 'post',
      timeout: 30000,
      url: `${url}/destroy_many`,
    });

  const apiRetrieveByIds = async ({
    apiClient,
    selections,
  }: {
    apiClient: any;
    selections: any;
  }) =>
    apiClient({
      method: 'get',
      params: selections,
      url: `/api/event_invitations/contact_selections`,
    });

  const create = useMutation({
    mutationFn: async (employee) =>
      apiCreate({
        apiClient: axiosAuthenticated,
        data: {
          email: employee.email,
          first_name: employee.first_name,
          last_name: employee.last_name,
          ...(employee.office_id ? { office_id: employee.office_id } : {}),
        },
      }),
    // client side optimistic update
    onMutate: (newUserInfo: any) => {
      queryClient.setQueryData(queryKey, (prevData: any) => ({
        ...prevData,
        data: [
          { ...newUserInfo, created_at: dayjs().utc() },
          ...(prevData?.data || []),
        ],
      }));
    },
    onSuccess: (data: any, variables: any) => {
      enqueueSnackbar(
        `${variables?.first_name} ${variables?.last_name} created!`,
        { variant: 'success' }
      );
    },
  });

  const update = useMutation({
    mutationFn: async (employee: any) =>
      apiUpdate({
        apiClient: axiosAuthenticated,
        data: employee,
        id: employee.id,
      }),
    // client side optimistic update
    onMutate: (newUserInfo: any) => {
      queryClient.setQueryData(queryKey, (prevData: any) => ({
        ...prevData,
        data: prevData?.data?.map((prevUser: any) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser
        ),
      }));
    },
    onSuccess: (data: any, variables: any) => {
      enqueueSnackbar(
        `${variables?.first_name} ${variables?.last_name} updated!`,
        { variant: 'success' }
      );
    },
  });

  interface RemoveParams {
    done?: () => unknown;
    employeeRow: MRT_Row<Contact>;
  }

  const remove = useMutation({
    mutationFn: async ({ employeeRow }: RemoveParams) =>
      apiRemove({
        apiClient: axiosAuthenticated,
        id: Number(employeeRow.id),
      }),
    // client side optimistic update
    onMutate: ({ employeeRow }) => {
      queryClient.setQueryData(queryKey, (prevData: any) => ({
        ...prevData,
        data: prevData?.data?.filter(
          (prevEmployee: any) => prevEmployee.id !== employeeRow.id
        ),
      }));
    },
    onSuccess(data, { done, employeeRow }: RemoveParams) {
      done && done();
      enqueueSnackbar(
        `${employeeRow.original.first_name} ${employeeRow.original.last_name} deleted!`,
        {
          variant: 'success',
        }
      );
    },
  });

  interface RemoveMultipleParams {
    done?: () => unknown;
    ids: number[];
  }

  const removeMultiple = useMutation({
    mutationFn: async ({ ids }: RemoveMultipleParams) => {
      apiRemoveMultiple({
        apiClient: axiosAuthenticated,
        data: { employees: ids },
      });
    },
    // client side optimistic update
    onMutate: ({ done, ids }: RemoveMultipleParams) => {
      queryClient.setQueryData(queryKey, (prevData: any) => ({
        ...prevData,
        data: prevData?.data?.filter(
          (prevEmployee: any) => !ids.includes(prevEmployee.id)
        ),
      }));
      done && done();
    },
    onSuccess(data, { done, ids }: RemoveMultipleParams) {
      apiRetrieveAll.refetch();
      done && done();
      enqueueSnackbar(`${pluralize('contact', ids.length, true)} deleted!`, {
        variant: 'success',
      });
    },
  });

  const retrieveByIds = ({ selections }: { selections: any }) =>
    apiRetrieveByIds({
      apiClient: reactQueryAxios,
      selections,
    }).then((response) => response.data);

  return {
    create,
    remove,
    removeMultiple,
    retrieve: apiRetrieve,
    retrieveAll: apiRetrieveAll,
    retrieveByIds,
    update,
  };
};
