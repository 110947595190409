import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, CardHeader } from '@mui/material';
import PropTypes from 'prop-types';

import GroupForm from 'components/Groups/GroupForm';
import { GroupContext } from 'contexts/GroupContext';
import { LayoutContext } from 'contexts/LayoutContext';
import useSaveGroup from 'hooks/useSaveGroup';
import { axiosAuthenticated as axios } from 'utils/axios';

const EditGroup = ({
  group,
  handleGroupFailure,
  handleGroupUpdate,
  hasUnsavedChanges,
  setHasUnsavedChanges,
}) => {
  const { dispatch, state } = useContext(GroupContext);

  const postGroup = useSaveGroup();

  const editGroup = state;
  const isGroupSaving = state.isGroupSaving;

  const [coverImage, setCoverImage] = useState('');
  const { headerHeight } = useContext(LayoutContext);

  const setField = useCallback(
    (key, value) => {
      dispatch({ field: key, type: 'SET_FIELD', value });
    },
    [dispatch]
  );

  useEffect(() => {
    setField('cover_image_key', group.cover_image);
    setCoverImage(group.cover_image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrop = (files) => {
    if (files && files.length > 0) {
      setCoverImage(files[0]);
      setField('cover_image_key', null);
    } else {
      setCoverImage(null);
      setField('cover_image_key', group.cover_image);
    }
    setField('coverImageChanged', true);
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    setHasUnsavedChanges(false);
    postGroup(
      editGroup,
      coverImage,
      handleGroupUpdate,
      handleGroupFailure,
      axios
    );
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Card
        data-testid='TODO:DATA-CARD-20418'
        sx={{
          maxHeight: `calc(100vh - ${headerHeight}px)`,
          overflowY: 'auto',
        }}
      >
        <CardHeader
          action={
            <LoadingButton
              color='primary'
              data-testid='save-changes'
              disabled={!hasUnsavedChanges}
              loading={isGroupSaving}
              type='submit'
              variant='contained'
            >
              Save changes
            </LoadingButton>
          }
          sx={{ bgcolor: 'white', position: 'sticky', top: 0, zIndex: 99 }}
          title='Edit group'
        />
        <GroupForm
          cover_image={coverImage}
          group={editGroup}
          loadedGroup={group}
          onDrop={onDrop}
          saveDescription={(newVal) => {
            if (editGroup.description !== newVal) {
              postGroup(
                { ...editGroup, description: newVal },
                coverImage,
                handleGroupUpdate,
                handleGroupFailure,
                axios
              );
            } else {
              handleSubmit();
            }
          }}
        />
      </Card>
    </form>
  );
};

EditGroup.propTypes = {
  dispatch: PropTypes.func,
  editGroup: PropTypes.any,
  group: PropTypes.shape({
    cover_image: PropTypes.any,
    cover_image_key: PropTypes.any,
  }),
  handleGroupFailure: PropTypes.func,
  handleGroupUpdate: PropTypes.func,
  hasUnsavedChanges: PropTypes.bool,
  isGroupSaving: PropTypes.bool,
  setHasUnsavedChanges: PropTypes.func.isRequired,
};

export default EditGroup;
