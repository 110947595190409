import React, { useMemo, useState } from 'react';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { lateRsvpSubheader } from 'components/Events/Controls/Rsvp/Rsvp.constants';
import RsvpButtons from 'components/Events/Controls/Rsvp/RsvpButtons';
import RsvpCardHeader from 'components/Events/Controls/Rsvp/RsvpCardHeader';
import SurveyQuestionList from 'components/Events/Controls/Shared/SurveyQuestionList';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import { useContextWithFallback } from 'utils/utils';

const PreRsvpView = ({
  allAnswersSubmitted,
  cantRsvp,
  handleSelection,
  isDone,
  isNow,
  isPreview,
  isSoon,
  setLeftEmailField,
  status,
  user,
}) => {
  const {
    state: { event, locationType },
  } = useContextWithFallback(ManagerContext, PublicEventContext);
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState([]);

  const askDefaultQuestions = useMemo(() => !user && !isDone, [user, isDone]);

  return (
    <Card data-testid='pre-rsvp-view' elevation={isPreview ? 2 : 1}>
      <RsvpCardHeader
        subheader={
          !cantRsvp && (isSoon || isNow)
            ? lateRsvpSubheader(event?.name, locationType)
            : null
        }
        title={
          cantRsvp
            ? `Not sure if you RSVP'd?`
            : isSoon
            ? 'This event is starting soon...'
            : isNow
            ? 'This event has started'
            : 'Will you attend this event?'
        }
      />
      {(askDefaultQuestions || isPreview) && (
        <CardContent>
          <SurveyQuestionList
            data-testid='rsvp-questions'
            defaultQuestions
            emailOnly={cantRsvp}
            handleChange={(vals) => setValues(vals)}
            isPreview={isPreview}
            setLeftEmailField={setLeftEmailField}
            type='default_survey'
          />
        </CardContent>
      )}
      <CardActions>
        {cantRsvp && askDefaultQuestions && !allAnswersSubmitted ? (
          <Button
            data-testid='submit'
            onClick={() => {
              const invitee = event?.invitees?.find(
                (invitee) => invitee.email === values?.email
              );
              if (
                !invitee ||
                invitee?.status === 'invited' ||
                invitee?.status === 'guest_list'
              ) {
                enqueueSnackbar(
                  'This event doesn’t have any RSVPs from your email address.',
                  { variant: 'info' }
                );
              }
            }}
            variant='outlined'
          >
            Submit
          </Button>
        ) : (
          <RsvpButtons
            areDisabled={
              isDone ||
              (askDefaultQuestions && !allAnswersSubmitted && !isPreview)
            }
            handleSelection={handleSelection}
            isNow={isNow}
            isSoon={isSoon}
            statusId={status}
          />
        )}
      </CardActions>
    </Card>
  );
};

PreRsvpView.propTypes = {
  allAnswersSubmitted: PropTypes.bool,
  cantRsvp: PropTypes.bool,
  event: PropTypes.object,
  handleSelection: PropTypes.func,
  isDone: PropTypes.bool,
  isNow: PropTypes.bool,
  isPreview: PropTypes.bool,
  isSoon: PropTypes.bool,
  setLeftEmailField: PropTypes.func,
  status: PropTypes.string,
  surveyDescription: PropTypes.any,
  user: PropTypes.object,
};

export default PreRsvpView;
