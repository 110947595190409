import React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';

import useCollectionDateTimeRange from 'hooks/useCollectionDateTimeRange';

interface CollectionPreviewHeaderProps {
  collection: {
    endsAt: string;
    name: string;
    startsAt: string;
  };
}

const CollectionPreviewHeader = ({
  collection,
}: CollectionPreviewHeaderProps) => {
  const dateTimeRange = useCollectionDateTimeRange(collection);

  return (
    <Box mt={3}>
      <Stack alignItems='center' direction='row' gap={2}>
        <Typography
          color='grey.900'
          data-testid='collection-title'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          variant='h1'
        >
          {collection.name || 'New Collection'}
        </Typography>
        <Chip
          color='primary'
          label='Collection'
          size='medium'
          variant='light'
        />
      </Stack>
      <Stack alignItems='center' direction='row' gap={2}>
        {collection.startsAt && collection.endsAt ? (
          <Typography color='grey.600' component='div' variant='body2'>
            {dateTimeRange}
          </Typography>
        ) : null}
      </Stack>
    </Box>
  );
};

export default CollectionPreviewHeader;
