import { useMemo } from 'react';
import dayjs from 'dayjs';

import FtnEvent from 'types/FtnEvent';

export const useEventMessageFormValue = ({
  commId,
  savedEvent,
}: {
  commId: number | null;
  savedEvent: FtnEvent | null;
}) =>
  useMemo(() => {
    if (!commId || !savedEvent) return undefined;

    const message = savedEvent?.event_messages?.find(
      (m) => m.id === Number(commId)
    );

    // INFO: If there are multiple invitations/messages, these are the properties that are the same for all of them
    const shareCommunicationProps = {
      communicationHistoryId: message?.communication_history_id,
      invitee_statuses: message?.invitee_statuses || [],
      is_scheduled: message?.is_scheduled,
      message_body: message?.message_body,
      message_type: message?.message_type,
      scheduled_for: message?.scheduled_for
        ? dayjs(message?.scheduled_for)
        : null,
      show_event_details_button: message?.show_event_details_button,
      show_rsvp_buttons: message?.show_rsvp_buttons,
      slack_channel_ids: message?.slack_channel_ids || [],
    };

    return {
      id: commId,
      ...shareCommunicationProps,
    };
  }, [commId, savedEvent]);
