import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';

import Currency from 'types/Currency';

const EventBudgetSummaryChart = ({
  actual,
  budget,
  currency,
  estimated,
}: {
  actual: number | undefined;
  budget: number | undefined;
  currency: Currency;
  estimated: number | undefined;
}) => {
  const theme = useTheme();
  const primary = theme.palette.primary;
  const grey100 = theme.palette.grey[100];

  const [chartData, setChartData] = useState<any[]>([]);
  const [highestValue, setHighestValue] = useState<number>(100);
  const [lowestValue, setLowestValue] = useState<number>(0);

  useEffect(() => {
    const definedValues: number[] = [budget, estimated, actual].filter(
      (v): v is number => typeof v === 'number' && v !== 0
    );

    const newHighestValue =
      definedValues.length > 0 ? Math.max(...definedValues) : 100;
    const newLowestValue =
      definedValues.length > 0 ? Math.min(0, ...definedValues) : 0;

    const newData = [
      {
        color: budget && budget > 0 ? primary.light : grey100,
        id: 'Event budget',
        value: budget && budget > 0 ? budget : 0,
      },
      {
        color: estimated && estimated > 0 ? primary.main : grey100,
        id: 'Estimated spend',
        value: estimated && estimated > 0 ? estimated : 0,
      },
      {
        color: actual && actual > 0 ? primary.dark : grey100,
        id: 'Actual spend',
        value: actual && actual > 0 ? actual : 0,
      },
    ];

    setChartData(newData);
    setHighestValue(newHighestValue);
    setLowestValue(newLowestValue);
  }, [
    actual,
    budget,
    estimated,
    primary.light,
    primary.main,
    primary.dark,
    grey100,
  ]);

  return (
    <>
      {chartData.length > 0 && (
        <ResponsiveBar
          animate={false}
          ariaLabel='Event Budget Summary Chart'
          axisBottom={{
            format: (d) => `${currency?.symbol}${d.toLocaleString()}`,
            tickPadding: 5,
            tickRotation: 0,
            tickSize: 0,
            tickValues: [lowestValue, highestValue],
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickPadding: 16,
            tickSize: 0,
          }}
          axisRight={null}
          axisTop={null}
          borderRadius={6}
          colors={(d) => d.data.color}
          data={chartData}
          enableGridX
          enableGridY={false}
          enableLabel={false}
          gridXValues={[lowestValue, highestValue]}
          layers={['axes', 'bars', 'grid']}
          layout='horizontal'
          margin={{ bottom: 48, left: 136, right: 56, top: 0 }}
          maxValue={highestValue}
          minValue={lowestValue}
          role='application'
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                },
              },
            },
            labels: {
              text: {
                color: theme.palette.grey[700],
                fontSize: 12,
              },
            },
            text: {
              fontFamily: 'Inter',
            },
          }}
          tooltipLabel={(d) => d.indexValue.toString()}
          valueFormat={(v) =>
            v > 0
              ? `${currency?.symbol}${Number(v).toLocaleString()}`
              : 'No data'
          }
        />
      )}
    </>
  );
};

export default EventBudgetSummaryChart;
