import React from 'react';
import { Typography } from '@mui/material';

const NoResponses = () => (
  <Typography color='grey.600' fontWeight={300} height='100%' variant='body1'>
    No responses yet.
  </Typography>
);

export default NoResponses;
