import React from 'react';
import { TextField } from '@mui/material';

import { EventDetailsFormRegister } from 'types/EventDetailsForm';

interface EventInPersonLocationFieldProps {
  disableFields: boolean;
  register: EventDetailsFormRegister;
}

const EventInPersonLocationField = ({
  disableFields,
  register,
}: EventInPersonLocationFieldProps) => (
    <TextField
      fullWidth
      InputProps={{ readOnly: disableFields }}
      inputProps={{ ...register('location') }}
      label='In-person location'
      placeholder='Add a location (conference room, address, etc.)'
    />
  );

export default EventInPersonLocationField;
