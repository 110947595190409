import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

export const SlackChannelsContext = createContext();

export const SlackChannelsProvider = (props) => {
  const [organization] = useContext(OrganizationContext);
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const fetchSlackChannels = useCallback(
    async (page = 1, searchTerm = '') =>
      new Promise((resolve, reject) => {
        axios(
          {
            method: 'get',
            url: `/api/organizations/slack?page=${page}&name=${encodeURIComponent(
              searchTerm.replace('#', '')
            )}`,
          },
          (res) => resolve(res.data),
          (err) => reject(err)
        );
      }),
    []
  );

  const {
    data: slackChannelsData,
    isFetching,
    isPending,
    isPlaceholderData,
  } = useQuery({
    enabled: isAuthenticated && organization?.slack_enabled,
    gcTime: Infinity,
    placeholderData: keepPreviousData,
    queryFn: () => fetchSlackChannels(currentPage, searchTerm),
    queryKey: ['slackChannels', searchTerm, currentPage],
    staleTime: 86400000, // 24 hours
  });

  const slackChannels = useMemo(
    () => slackChannelsData?.channels,
    [slackChannelsData]
  );

  const totalPages = useMemo(
    () => slackChannelsData?.meta?.total_pages,
    [slackChannelsData]
  );

  const totalCount = useMemo(
    () => slackChannelsData?.meta?.total_count,
    [slackChannelsData]
  );

  const contextValue = useMemo(
    () => ({
      currentPage,
      fetchSlackChannels,
      isFetching,
      isPending,
      isPlaceholderData,
      searchTerm,
      setCurrentPage,
      setSearchTerm,
      slackChannels,
      totalCount,
      totalPages,
    }),
    [
      slackChannels,
      isPending,
      fetchSlackChannels,
      currentPage,
      setCurrentPage,
      totalPages,
      totalCount,
      isFetching,
      isPlaceholderData,
      searchTerm,
      setSearchTerm,
    ]
  );

  return (
    <SlackChannelsContext.Provider value={contextValue}>
      {props.children}
    </SlackChannelsContext.Provider>
  );
};

SlackChannelsProvider.propTypes = {
  children: PropTypes.any,
};
