import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';

interface CurrencyConversionDialogProps {
  conversionRates: { [key: number]: number };
  groupsBudgetData: any[];
  groupsWithEvents: any[];
  onClose: () => void;
  onConversionRateChange: (groupId: number, rate: number) => void;
  onSaveAndContinue: () => void;
  open: boolean;
  organization: any;
}

const CurrencyConversionDialog = ({
  conversionRates,
  groupsBudgetData,
  groupsWithEvents,
  onClose,
  onConversionRateChange,
  onSaveAndContinue,
  open,
  organization,
}: CurrencyConversionDialogProps) => {
  const currencyChangedGroups = groupsWithEvents.filter((group: any) => {
    const initialGroup = groupsBudgetData?.find(
      (initialGroup: any) => initialGroup.id === group.id
    );
    return group.currency_id !== initialGroup?.currency_id;
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Confirm currency conversion</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to change the currency for the following groups?
          All event expenses linked to this group will be converted to match
          your selected currency at the rates listed below. Please review and
          confirm the conversion rates below:
        </Typography>
        <Box mt={2}>
          <Box
            display='flex'
            flexWrap='wrap'
            gap={1}
            sx={{
              backgroundColor: 'grey.100',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Stack direction='column' gap={1} width='100%'>
              {groupsWithEvents.flatMap((group: any) => {
                const initialGroup = groupsBudgetData?.find(
                  (initialGroup: any) => initialGroup.id === group.id
                );
                const currencyChanged =
                  group.currency_id !== initialGroup?.currency_id;

                const initialGroupCurrency = organization.currencies.find(
                  (currency: { id: number | undefined }) =>
                    currency.id === initialGroup?.currency_id
                );

                const groupCurrency = organization.currencies.find(
                  (currency: { id: any }) => currency.id === group.currency_id
                );

                return (
                  currencyChanged && (
                    <Stack
                      alignItems='center'
                      direction='row'
                      justifyContent='space-between'
                      key={group.id}
                    >
                      <Typography variant='body1' width='60%'>
                        {group.name}
                      </Typography>
                      <Typography variant='body1'>
                        {`${initialGroupCurrency?.code} (${initialGroupCurrency?.symbol}) -> ${groupCurrency?.code} (${groupCurrency?.symbol})`}
                      </Typography>
                    </Stack>
                  )
                );
              })}
            </Stack>
          </Box>
        </Box>
        {currencyChangedGroups.length > 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant='body2'>Review conversion rates:</Typography>
            {currencyChangedGroups.map((group: any) => {
              const initialGroup = groupsBudgetData?.find(
                (initialGroup: any) => initialGroup.id === group.id
              );

              const initialGroupCurrency = organization.currencies.find(
                (currency: { id: number | undefined }) =>
                  currency.id === initialGroup?.currency_id
              );

              const groupCurrency = organization.currencies.find(
                (currency: { id: any }) => currency.id === group.currency_id
              );

              return (
                <Box alignItems='center' display='flex' key={group.id} mb={1}>
                  <Typography mr={1} variant='body1'>
                    {`1 ${initialGroupCurrency?.code} (${initialGroupCurrency?.symbol}) = `}
                  </Typography>
                  <TextField
                    defaultValue={conversionRates[group.id] || 1}
                    inputProps={{ style: { textAlign: 'right' } }}
                    onChange={(e) =>
                      onConversionRateChange(
                        group.id,
                        parseFloat(e.target.value)
                      )
                    }
                    size='small'
                    sx={{ width: '100px' }}
                  />
                  <Typography ml={1} variant='body1'>
                    {`${groupCurrency?.code} (${groupCurrency?.symbol})`}
                  </Typography>
                </Box>
              );
            })}
          </>
        )}
        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color='primary'
            onClick={onSaveAndContinue}
            variant='contained'
          >
            Save and continue
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CurrencyConversionDialog;
