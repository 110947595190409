import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { axiosAuthenticated, reactQueryAxios } from 'utils/axios';

const useUsers = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const url = `/api/users`;

  const queryKey = ['users'];

  const getUsers = async ({ apiClient }: { apiClient: any }) =>
    apiClient({
      method: 'get',
      url,
    });

  const retrieve = useQuery({
    queryFn: () =>
      getUsers({ apiClient: reactQueryAxios }).then(
        (response: AxiosResponse) => response.data
      ),
    queryKey,
  });

  const updateRole = ({
    id,
    role,
    user,
  }: {
    id: number;
    role: any;
    user: any;
  }) => {
    axiosAuthenticated(
      {
        data: { new_role: role, user_id: id },
        method: 'POST',
        url: `${url}/update_permission/`,
      },
      () => {
        queryClient.invalidateQueries({ queryKey });
        enqueueSnackbar(`${user.name}'s role set to ${role}!`, {
          variant: 'success',
        });
      },
      (err) => {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
        });
        console.log(err);
      }
    );
  };

  const updateStatus = (user: { id: number; name: string }, action: string) => {
    axiosAuthenticated(
      {
        data: { user_id: user.id },
        method: 'POST',
        url:
          action === 'reactivate'
            ? '/api/admins/reactivate_user/'
            : '/api/admins/deactivate_user/',
      },
      () => {
        queryClient.invalidateQueries({ queryKey });
        enqueueSnackbar(
          `${user.name} ${
            action === 'reactivate' ? 'reactivated' : 'deactivated'
          }!`,
          { variant: 'success' }
        );
      },
      (err) => {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
        });
        console.log(err);
      }
    );
  };

  return { retrieve, updateRole, updateStatus };
};

export default useUsers;
