import React from 'react';
import PropTypes from 'prop-types';

import Search from 'components/Core/Search';

const InvitationSelectSearch = ({
  filter,
  fullWidth = false,
  isSlack = false,
  placeholder,
  setFilter,
}) => {
  const handleSearchChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <Search
      fullWidth={fullWidth}
      label='search'
      mb={isSlack ? 0.5 : 0}
      onChange={handleSearchChange}
      placeholder={placeholder}
      value={filter}
    />
  );
};

InvitationSelectSearch.propTypes = {
  filter: PropTypes.string,
  fullWidth: PropTypes.bool,
  isSlack: PropTypes.bool,
  placeholder: PropTypes.string,
  setFilter: PropTypes.func,
};

export default InvitationSelectSearch;
