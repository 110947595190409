import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Typography } from '@mui/material';

import CollectionForm from 'components/Collections/CollectionForm';
import CollectionToolbar from 'components/Collections/CollectionToolbar';
import useCollectionFormDefaultValues from 'components/Collections/useCollectionFormDefaultValues';
import useCollectionFormValues from 'components/Collections/useCollectionFormValues';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import Collection from 'types/Collection';
import { CollectionFormData } from 'types/CollectionForm';

interface CollectionCreateProps {
  isLoading?: boolean;
  savedCollectionDetails?: Collection | undefined;
}

const CollectionCreate = ({
  isLoading = false,
  savedCollectionDetails,
}: CollectionCreateProps) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;
  const defaultValues = useCollectionFormDefaultValues(user);
  const values = useCollectionFormValues(savedCollectionDetails);

  const collectionDetailsForm = useForm<CollectionFormData>({
    criteriaMode: 'all',
    defaultValues: values || defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    values,
  });

  return (
    <DefaultLayout
      appBarStyles={{
        borderBottomColor: 'grey.300',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
      }}
      header={
        <Container
          maxWidth='lg'
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography
            color='text.primary'
            component='h1'
            noWrap
            sx={{ flexGrow: 1 }}
            variant='h3'
          >
            {savedCollectionDetails
              ? `${
                  savedCollectionDetails.published ? 'Edit' : 'Draft'
                } Collection`
              : 'New Collection'}
          </Typography>
          <CollectionToolbar
            clearErrors={collectionDetailsForm.clearErrors}
            collectionId={savedCollectionDetails?.id ?? null}
            formState={collectionDetailsForm.formState}
            handleSubmit={collectionDetailsForm.handleSubmit}
            isPublished={savedCollectionDetails?.published ?? false}
            setError={collectionDetailsForm.setError}
            watch={collectionDetailsForm.watch}
          />
        </Container>
      }
    >
      <Container maxWidth='lg'>
        <form>
          <CollectionForm
            hookForm={collectionDetailsForm}
            isLoading={isLoading}
            isPublished={savedCollectionDetails?.published ?? false}
          />
        </form>
      </Container>
    </DefaultLayout>
  );
};

export default CollectionCreate;
