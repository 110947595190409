import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import UserInformationForm from 'components/Onboard/UserInformationForm';
import WelcomeMessageAdmin from 'components/Onboard/WelcomeMessageAdmin';
import WelcomeMessageUser from 'components/Onboard/WelcomeMessageUser';
import { AuthContext } from 'contexts/AuthContext';
import { EmployeesContext } from 'contexts/EmployeesContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const WelcomeMessage = () => {
  const {
    userProvider: { setUser, user },
    welcomeProvider: { show, welcomeShown },
  } = useContext(AuthContext);
  const [enteringInfo, setEnteringInfo] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { getAllEmployees, setCurrentPage } = useContext(EmployeesContext);

  const handleSubmit = (timezone, office, isAdmin) => {
    const data = {
      user: {
        timezone,
      },
    };

    if (isAdmin) {
      data.user.office_raw = office;
    } else {
      data.user.office_id = office.value;
    }

    axios(
      {
        data,
        method: 'POST',
        url: '/api/users/update_profile',
      },
      (res) => {
        setUser(res.data.user);
        setCurrentPage(1); // Reset to the first page
        getAllEmployees({ apiClient: axios, page: 1 }); // Fetch employees for the first page
        enqueueSnackbar('Your profile information has been updated.', {
          variant: 'success',
        });
        welcomeShown();
      },
      () => {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
        });
      }
    );
  };

  return (
    <Dialog
      aria-label='Welcome'
      fullWidth
      maxWidth='md'
      onClose={welcomeShown}
      open={show}
    >
      <DialogTitle>
        <Typography component='div' variant='h2'>
          Welcome to Five to Nine!
        </Typography>
        <Typography variant='body1'>
          {enteringInfo
            ? 'Complete your profile'
            : 'Here are a few things you can do to get started.'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {enteringInfo ? (
          <UserInformationForm onSubmit={handleSubmit} user={user} />
        ) : user?.is_admin || user?.is_organizer ? (
          <WelcomeMessageAdmin advanceStep={() => setEnteringInfo(true)} />
        ) : (
          <WelcomeMessageUser advanceStep={welcomeShown} />
        )}
      </DialogContent>
    </Dialog>
  );
};
export default WelcomeMessage;
