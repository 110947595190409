import React, { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, useTheme } from '@mui/material';
import { CheckCircle, Circle, Prohibit } from '@phosphor-icons/react';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';

const RsvpPauseToggle = () => {
  const {
    actions: { applySingle, saveRsvpPauseActive },
    state: { event, savedEvent },
  } = useContext(ManagerContext);
  const [isPendingSave, setIsPendingSave] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (
      isPendingSave &&
      event?.rsvp_pause_active !== savedEvent?.rsvp_pause_active
    ) {
      saveRsvpPauseActive();
      setIsPendingSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.rsvp_pause_active]);

  const toggleActive = () => {
    setIsPendingSave(true);
    applySingle(!event.rsvp_pause_active, 'event.rsvp_pause_active');
  };

  return (
    <Card data-testid='TODO:DATA-CARD-88248'>
      <CardHeader
        action={
          <LoadingButton
            data-testid='rsvps'
            loading={isPendingSave}
            onClick={toggleActive}
            startIcon={
              event?.rsvp_pause_active ? <CheckCircle /> : <Prohibit />
            }
            variant='bordered'
          >
            {event?.rsvp_pause_active ? 'Open' : 'Close'} RSVPs
          </LoadingButton>
        }
        avatar={
          <Circle
            color={
              theme.palette[event?.rsvp_pause_active ? 'error' : 'success'].main
            }
            weight='fill'
          />
        }
        title={`This event is${
          event?.rsvp_pause_active ? ' not' : ''
        } currently accepting RSVPs`}
        titleTypographyProps={{ variant: 'body1' }}
      />
    </Card>
  );
};

RsvpPauseToggle.propTypes = {};

export default RsvpPauseToggle;
