import React, { ReactNode } from 'react';

import GroupsWaiter from 'components/Events/Editor/GroupsWaiter';
import SlackEmployeeWaiter from 'components/Events/Editor/SlackEmployeeWaiter';
import { SlackChannelsProvider } from 'contexts/SlackChannelsContext';
import { SlackEmployeesProvider } from 'contexts/SlackEmployeesContext';
import { SyncedSlackChannelProvider } from 'contexts/SyncedSlackChannelContext';

const EditorWrapper = ({ children }: { children: ReactNode }) => (
  <SlackEmployeesProvider>
    <SlackChannelsProvider>
      <SyncedSlackChannelProvider>
        <SlackEmployeeWaiter>
          <GroupsWaiter>{children}</GroupsWaiter>
        </SlackEmployeeWaiter>
      </SyncedSlackChannelProvider>
    </SlackChannelsProvider>
  </SlackEmployeesProvider>
);

export default EditorWrapper;
