import { matchPath } from 'react-router-dom';
import { uniqueId } from 'lodash';

export const toHtmlId = (text) => {
  const id = text ? text.replace(/\W/g, '-') : uniqueId();
  return id.charAt(0).match(/[\d-]/g)?.length ? `id_${id}` : id;
};

// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export const toOrdinalNumber = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const toOxfordComma = (arr, conjunction = 'and') =>
  arr.length > 2
    ? arr
        .slice(0, arr.length - 1)
        .concat(` ${conjunction} ${arr.slice(-1)}`)
        .join(', ')
    : arr.join(` ${conjunction} `);

export const startsWithVowel = (word) =>
  ['a', 'e', 'i', 'o', 'u'].includes(word[0].toLowerCase());

export const toInitials = (fullName, maxLength = 2) => {
  if (fullName?.length > 0) {
    const inits = fullName
      ?.split(' ')
      ?.map((segment) => segment[0])
      ?.join('');
    if (inits?.length > maxLength) {
      const upperInits = inits.replace(/[a-z]/g, '');
      if (upperInits <= maxLength) {
        return upperInits;
      } else {
        return upperInits.slice(0, maxLength);
      }
    } else {
      return inits;
    }
  } else {
    return fullName;
  }
};

// https://www.grammarly.com/blog/articles/
export const withIndefiniteArticle = (word) =>
  `a${startsWithVowel(word) ? 'n' : ''} ${word}`;

export const toPageName = (pathname) =>
  matchPath(pathname, {
    path: '/groups/:id',
  })
    ? 'Group'
    : matchPath(pathname, {
        path: '/profile',
      })
    ? 'Profile'
    : 'Events';

export const handleSlackEmojiConversion = (html) =>
  html?.replace(
    /<img\s+(?:[^>]*?\s+)?src="([^"]*\/slack_emojis\/[^"]+)"([^>]*)>/g,
    (match, src, rest) => {
      if (rest.includes('data-slack-emoji')) {
        return match;
      }
      const emojiNameMatch = src.match(/\/slack_emojis\/[^/]+_([^/.]+)\./);
      const emojiName = emojiNameMatch ? emojiNameMatch[1] : '';
      return `<img src="${src}" alt="${emojiName}" data-slack-emoji="${emojiName}" ${rest}>`;
    }
  );

export const chartHeight = (data) => Math.max(250, data.length * 35);

export const getTextWidth = (text) => text.length * 7;

export const wrapText = (text, maxWidth, maxLength) => {
  if (text.length <= maxLength) return [text];

  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = getTextWidth(currentLine + ' ' + word);

    if (width < maxWidth && currentLine.length + word.length + 1 <= maxLength) {
      currentLine += ' ' + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
};
