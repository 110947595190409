import React, { useContext, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useSnackbar } from 'notistack';

import GoogleGroupsModal from 'components/Settings/Integrations/GoogleGroupsModal';
import IntegrationCard from 'components/Settings/Integrations/IntegrationCard';
import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const GoogleGroups = () => {
  const {
    userProvider: { setUser, user },
  } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const showErrorSnackbar = () => {
    enqueueSnackbar('Something went wrong! Please try again', {
      variant: 'error',
    });
  };

  const getGoogleGroups = () => {
    setIsLoading(true);
    axios(
      {
        method: 'get',
        url: '/api/lists/google_groups',
      },
      (res) => {
        if (res && res.data && res.data.errors) {
          enqueueSnackbar(res.data.errors, {
            variant: 'error',
          });
          setIsLoading(false);
        } else if (res && res.data) {
          setGroups(res.data);
          setShowModal(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          showErrorSnackbar();
        }
      },
      () => {
        setIsLoading(false);
        showErrorSnackbar();
      }
    );
  };

  const setupGoogleGroups = useGoogleLogin({
    flow: 'auth-code',
    onFailure: showErrorSnackbar,
    onSuccess: (response) => {
      setIsLoading(true);
      axios(
        {
          data: { code: response.code, redirect_uri: window.location.origin },
          method: 'POST',
          mode: 'CORS',
          url: '/api/auth_details/google_admin',
        },
        (res) => {
          setUser(res.data);
          getGoogleGroups();
        },
        () => {
          setIsLoading(false);
          enqueueSnackbar(
            'Something went wrong. Please ensure you have the correct permissions and try again!',
            {
              variant: 'error',
            }
          );
        }
      );
    },
    prompt: 'consent',
    scope: 'https://www.googleapis.com/auth/admin.directory.group.readonly',
  });

  return (
    <>
      <IntegrationCard
        description='Sync your Google Groups as Contact Lists and sync contacts.'
        isActive={user.has_google_admin_auth}
        isLoading={isLoading}
        name='Google Groups'
        onEdit={() => getGoogleGroups()}
        onSetUp={() => setupGoogleGroups()}
      />
      <GoogleGroupsModal
        axios={axios}
        groups={groups}
        handleClose={handleClose}
        handleRemove={getGoogleGroups}
        show={showModal}
      />
    </>
  );
};

export default GoogleGroups;
