import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { PaperPlaneTilt } from '@phosphor-icons/react';

import { convertTypeToReadable } from 'utils/survey';

interface ConfirmSendSurveyProps {
  handleClose: () => void;
  handleSendSurveys: () => void;
  show: boolean;
  type: 'pre_event_survey' | 'post_event_survey';
}

const ConfirmSendSurvey: React.FC<ConfirmSendSurveyProps> = ({
  handleClose,
  handleSendSurveys,
  show,
  type,
}) => {
  const typeLabel = convertTypeToReadable(type);

  return (
    <Dialog
      aria-label={`${typeLabel} survey`}
      onClose={handleClose}
      open={show}
    >
      <DialogTitle>Send {typeLabel} surveys by email</DialogTitle>
      <DialogContent sx={{ px: 3, py: 0 }}>
        By continuing, your {typeLabel} survey will be emailed to event
        attendees.
      </DialogContent>
      <DialogActions>
        <Button data-testid='cancel' onClick={handleClose} variant='text'>
          Cancel
        </Button>
        <Button
          data-testid='send-surveys'
          onClick={handleSendSurveys}
          startIcon={<PaperPlaneTilt />}
          variant='contained'
        >
          Send surveys
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSendSurvey;
