import React from 'react';
import { Avatar, Box, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { flexColumn } from 'utils/styles';

const InviteMetric = ({ color, Icon, isSmall, isVertical, label, value }) => {
  const theme = useTheme();
  const themeColor = color
    ? theme.palette[color]
    : { light: theme.palette.grey[200], main: theme.palette.grey[700] };
  return (
    <Box
      sx={{
        height: '100%',
        justifyContent: isVertical ? 'center' : null,
        ...flexColumn,
      }}
    >
      <Stack
        alignItems='center'
        direction={isVertical ? 'column' : 'row'}
        spacing={1}
      >
        <Avatar
          data-testid='TODO:DATA-AVATAR-59534'
          sx={{
            bgcolor: isSmall ? 'white' : themeColor.light,
            maxHeight: isSmall ? 24 : null,
            maxWidth: isSmall ? 40 : null,
          }}
          variant='square'
        >
          <Icon color={themeColor.main} size={isSmall ? 20 : 32} />
        </Avatar>
        <Stack direction={isSmall ? 'row' : 'column'} spacing={isSmall ? 1 : 0}>
          <Typography
            lineHeight={1.2}
            textAlign={isVertical ? 'center' : null}
            variant='body2'
          >
            {value}
          </Typography>
          <Typography
            color={`text.${isSmall ? 'secondary' : 'primary'}`}
            maxWidth={isVertical ? 100 : null}
            textAlign={isVertical ? 'center' : null}
            variant='overline'
          >
            {label}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default InviteMetric;

InviteMetric.propTypes = {
  color: PropTypes.string,
  Icon: PropTypes.any,
  isSmall: PropTypes.bool,
  isVertical: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
};
