import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  CalendarBlank,
  Gear,
  User,
  Users,
  UsersThree,
} from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import WelcomeMessageButton from 'components/Onboard/WelcomeMessageButton';

const WelcomeMessageAdmin = ({ advanceStep }) => (
  <>
    <List data-testid='admin-welcome-message-list'>
      <ListItem>
        <ListItemIcon>
          <Gear size={32} />
        </ListItemIcon>
        <ListItemText
          primary='Configure your organization'
          secondary='Manage integrations, add event & group types, set valid email domains, and more.'
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <User size={32} />
        </ListItemIcon>
        <ListItemText
          primary='Upload contacts'
          secondary='Save contacts on the platform to invite to events and groups.'
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Users size={32} />
        </ListItemIcon>
        <ListItemText
          primary='Invite collaborators'
          secondary='Invite users to join you in managing events and groups.'
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CalendarBlank size={32} />
        </ListItemIcon>
        <ListItemText
          primary='Create an event'
          secondary='Manage an event and share it with your teams.'
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <UsersThree size={32} />
        </ListItemIcon>
        <ListItemText
          primary='Create a group'
          secondary="Manage your organization's groups in one place."
        />
      </ListItem>
    </List>
    <WelcomeMessageButton advanceStep={advanceStep} />
  </>
);

export default WelcomeMessageAdmin;

WelcomeMessageAdmin.propTypes = {
  advanceStep: PropTypes.any,
};
