// TODO: Delete this file once all instances are replaced

export type Colors = string[];

export interface EqualDivisionFunc {
  (colors: Colors, stepCount: number, stepIndex: number): string;
}

export const NewPrimaryColor = '#008290';
export const PrimaryColor = '#00a69d';
export const DarkColor = '#222222';
export const ElectricBlueColor = '#636e7b';
export const DarkGrayColor = '#9ca5b0';
export const WhiteColor = '#ffffff';
export const UltraRedColor = '#fc607f';
export const EventGray = '#E4E7EB';

export const Accent1Color = '#facf42';
export const Accent2Color = '#d46fed';
export const Accent4Color = '#ff9c65';
export const Accent5Color = '#56dfec';

const Accent3Color = '#6677e5';
const Accent6Color = '#339BC6';
const Accent7Color = '#4D89D6';
const Accent8Color = '#9D73E9';
const Accent9Color = '#EA6AB9';
const Accent10Color = '#FF8175';
const Accent11Color = '#FDB654';
const Accent12Color = '#D1D36D';
const Accent13Color = '#A8D797';
const Accent14Color = '#2BCFC9';
const Accent15Color = '#1AADB6';


export const GraphColors: Colors = [
  Accent5Color,
  Accent6Color,
  Accent7Color,
  Accent3Color,
  Accent8Color,
  Accent2Color,
  Accent9Color,
  UltraRedColor,
  Accent10Color,
  Accent4Color,
  Accent11Color,
  Accent1Color,
  Accent12Color,
  Accent13Color,
  PrimaryColor,
  Accent14Color,
  Accent15Color,
];

export const EqualDivision: EqualDivisionFunc = (
  colors,
  stepCount,
  stepIndex
) => {
  const actIndex = Math.floor(colors.length * (stepIndex / stepCount));
  return colors[actIndex % colors.length];
};

export const RainbowColors: Colors = [
  UltraRedColor,
  Accent10Color,
  Accent4Color,
  Accent11Color,
  Accent1Color,
  Accent12Color,
  Accent13Color,
  Accent14Color,
  Accent15Color,
  Accent6Color,
  Accent7Color,
  Accent3Color,
  Accent8Color,
  Accent2Color,
  Accent9Color,
];

export const RainbowColorsLight: Colors = [
  '#FECFD8',
  '#FED8D3',
  '#FEDFCE',
  '#FEE8C8',
  '#FDF0C2',
  '#F0F1CF',
  '#E4F2DE',
  '#C1F0EE',
  '#BDE5E8',
  '#C1DFED',
  '#C8D9F2',
  '#CFD4F7',
  '#E0D2F8',
  '#F0D1F9',
  '#F7D0E9',
];
