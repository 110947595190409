import React, { useMemo } from 'react';
import { Alert, AlertTitle, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

const RsvpPauseStatus = ({ rsvp }) => {
  const isGoing = useMemo(() => rsvp === 'rsvp', [rsvp]);
  const color = useMemo(() => (isGoing ? 'warning' : 'error'), [isGoing]);
  const theme = useTheme();
  const isPausedMessage = 'This event is not currently accepting RSVPs';

  return (
    (<Alert icon={false} severity={color} data-testid="TODO:DATA-ALERT-92586">
      {!isGoing && (
        <AlertTitle
          sx={{
            color: theme.palette[color].dark,
            mb: 1,
            typography: 'h4',
          }}
        >
          {isPausedMessage}
        </AlertTitle>
      )}
      <Typography
        color={isGoing ? theme.palette[color].dark : 'text.primary'}
        variant='body1'
      >
        {isGoing
          ? isPausedMessage
          : `Unfortunately, RSVPs are not being accepted for this event right now.
             We appreciate your understanding and look forward to seeing you at future events.`}
      </Typography>
    </Alert>)
  );
};

RsvpPauseStatus.propTypes = {
  rsvp: PropTypes.string,
};

export default RsvpPauseStatus;
