import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';

import ChartTooltip from 'components/Events/Controls/Analytics/Charts/ChartTooltip';
import NoResponses from 'components/Events/Controls/Analytics/Charts/NoResponses';
import ChartLegendItem from 'components/shared/ChartLegendItem';

const SurveyQuestionResponsesBarChart = ({ options, question }) => {
  const theme = useTheme();

  const optionsWithResponses = useMemo(
    () => options.filter((opt) => opt?.responseCount > 0),
    [options]
  );

  const noData = useMemo(
    () => question?.responseCount === 0,
    [question.responseCount]
  );

  const maxResponses = Math.max(
    ...optionsWithResponses.map((opt) => opt.responseCount)
  );

  const tickValues = Array.from({ length: maxResponses + 1 }, (_, i) => i);

  return (
    <>
      {noData ? (
        <NoResponses />
      ) : (
        <Grid2
          alignItems='center'
          display='grid'
          gap={4}
          gridTemplateColumns={{
            md: `${
              optionsWithResponses?.length * 60
            }px minmax(min-content, 200px)`,
            xs: '100%',
          }}
          gridTemplateRows={{
            md: '220px',
            xs: '220px min-content',
          }}
          width='100%'
        >
          <Grid2 height={200}>
            <ResponsiveBar
              animate
              axisBottom={null}
              axisLeft={{
                format: (e) => e,
                tickPadding: 15,
                tickRotation: 0,
                tickSize: 0,
                tickValues: maxResponses < 10 ? tickValues : undefined,
              }}
              colors={(datum) => datum.data.color}
              data={options
                ?.filter(({ responseCount }) => responseCount > 0)
                ?.map(({ color, id, responseCount, title }) => ({
                  color,
                  id,
                  label: title,
                  total: question?.responseCount,
                  value: responseCount,
                }))}
              gridYValues={maxResponses < 10 ? tickValues : undefined}
              groupMode='grouped'
              indexBy='id'
              labelTextColor='white'
              layers={['grid', 'axes', 'bars']}
              layout='vertical'
              margin={{
                bottom: 10,
                left: 30,
                right: 0,
                top: 10,
              }}
              padding={0.25}
              sca
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fill: theme.palette.grey[600],
                      fontSize: 13,
                    },
                  },
                },
                fontFamily: 'Inter',
                fontSize: 14,
                labels: {
                  text: {
                    fontSize: 16,
                    fontWeight: 700,
                  },
                },
              }}
              tooltip={(item) => (
                <ChartTooltip label={item.data.label} obj={item} />
              )}
            />
          </Grid2>
          <Grid2>
            {options.map(({ color, responseCount, title }) => (
              <ChartLegendItem
                color={color}
                key={title}
                label={title}
                textVariant='overline'
                value={responseCount}
              />
            ))}
          </Grid2>
        </Grid2>
      )}
    </>
  );
};

SurveyQuestionResponsesBarChart.propTypes = {
  options: PropTypes.array,
  question: PropTypes.shape({
    questionId: PropTypes.any,
    responseCount: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.any,
  }),
};

export default SurveyQuestionResponsesBarChart;
