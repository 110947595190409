import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { flexColumn } from 'utils/styles';

interface FullscreenLoaderProps {
  message?: string;
  show: boolean;
}

const FullscreenLoader = ({ message, show }: FullscreenLoaderProps) => {
  if (!show) return null;

  return (
    <Box
      data-testid='full-loader-wrapper'
      sx={{
        ...flexColumn,
        alignItems: 'center',
        gap: 2,
        height: '100vh',
        justifyContent: 'center',
        width: '100vw',
      }}
    >
      {message && <Typography variant='h2'>{message}</Typography>}
      <CircularProgress color='primary' size={100} />
    </Box>
  );
};

export default FullscreenLoader;
