import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

import { FullscreenLoader } from 'components/Core/Loader';
import { setField } from 'components/Settings/actions/editSettings';
import { postOrganization } from 'components/Settings/actions/organizations';
import BudgetSettings from 'components/Settings/BudgetSettings';
import IntegrationSettings from 'components/Settings/IntegrationSettings';
import MainSettings from 'components/Settings/MainSettings';
import OfficeSettings from 'components/Settings/OfficeSettings';
import UserSettings from 'components/Settings/UserSettings';
import SettingsLayout from 'components/shared/layout/SettingsLayout';
import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { useEditSettings } from 'contexts/SettingsContext';
import { reactQueryAxios } from 'utils/axios';

const Settings = () => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const [org, setOrg] = useContext(OrganizationContext);
  const { tab } = useParams();

  const { dispatch, state: editSettings } = useEditSettings();
  const isGroupBudgetTrackingEnabled = useFlag('group-budget-tracking');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [favicon, setFavicon] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [initialLogo, setInitialLogo] = useState('');
  const [initialFavicon, setInitialFavicon] = useState('');
  const [logo, setLogo] = useState('');

  const handleOrgSave = useCallback(
    (data) => {
      setOrg(data);
      setInitialLogo(logo);
      setInitialFavicon(favicon);
      dispatch(setField('logoChanged', false));
      dispatch(setField('faviconChanged', false));
      enqueueSnackbar('Organization settings saved.', {
        variant: 'success',
      });
    },
    [dispatch, enqueueSnackbar, favicon, logo, setOrg]
  );

  const onDrop = useCallback(
    (files, kind) => {
      if (files?.length > 0) {
        kind === 'logo' ? setLogo(files[0]) : setFavicon(files[0]);
      } else if (files?.length === 0) {
        kind === 'logo'
          ? dispatch(setField('logo_key', org?.logo_key))
          : dispatch(setField('favicon_key', org?.favicon_key));
      } else {
        kind === 'logo' ? setLogo('') : setFavicon('');
        kind === 'logo'
          ? dispatch(setField('logo_key', null))
          : dispatch(setField('favicon_key', null));
      }
      kind === 'logo'
        ? dispatch(setField('logoChanged', true))
        : dispatch(setField('faviconChanged', true));
    },
    [dispatch, org?.favicon_key, org?.logo_key]
  );

  const checkForChanges = useCallback(() => {
    const changeWatchList = [
      'display_name',
      'email_extensions_ids',
      'event_types_ids',
      'group_types_ids',
    ];
    const checkAgainst = _.cloneDeep(org);

    if (checkAgainst) {
      checkAgainst.email_extensions_ids = _.map(
        org.email_extensions || [],
        (t) => t.id
      );
      checkAgainst.event_types_ids = _.map(org.event_types || [], (t) => t.id);
      checkAgainst.group_types_ids = _.map(org.group_types || [], (t) => t.id);
    }
    return _.some(editSettings, (prop, propName) => {
      if (prop && changeWatchList.includes(propName)) {
        if (!_.isEqual(prop, checkAgainst[propName])) {
          return true;
        }
      }
      return false;
    });
  }, [editSettings, org]);

  useEffect(() => {
    if (!org && tab !== 'main') {
      setHasUnsavedChanges(false);
    } else if (editSettings?.logoChanged || editSettings?.faviconChanged) {
      setHasUnsavedChanges(true);
    } else {
      const hasChanges = checkForChanges();
      setHasUnsavedChanges(hasChanges);
    }
  }, [editSettings, org, checkForChanges, tab]);

  const handleDiscardChanges = () => {
    setLogo(initialLogo);
    setFavicon(initialFavicon);
    dispatch({ type: 'DISCARD_CHANGES' });
  };

  const handleError = useCallback(() => {
    enqueueSnackbar('We were unable to update your settings.', {
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  const tabs = useMemo(
    () => [
      {
        component: (
          <MainSettings
            favicon={favicon}
            hasUnsavedChanges={hasUnsavedChanges}
            logo={logo}
            onDrop={onDrop}
            onSave={() =>
              postOrganization(
                org,
                editSettings,
                favicon,
                logo,
                handleOrgSave,
                handleError,
                reactQueryAxios
              )
            }
            organization={org}
          />
        ),
        label: 'Organization',
        value: 'main',
      },
      {
        component: <IntegrationSettings />,
        label: 'Integrations',
        value: 'integrations',
      },
      {
        component: <OfficeSettings offices={org?.offices} />,
        label: 'Offices',
        value: 'offices',
      },
      {
        component: <UserSettings organization={org} />,
        label: 'Users',
        value: 'users',
      },
      ...(isGroupBudgetTrackingEnabled && user.is_admin
        ? [
            {
              component: <BudgetSettings />,
              label: 'Budget',
              value: 'budget',
            },
          ]
        : []),
    ],
    [
      editSettings,
      favicon,
      handleError,
      handleOrgSave,
      hasUnsavedChanges,
      isGroupBudgetTrackingEnabled,
      logo,
      onDrop,
      org,
      user.is_admin,
    ]
  );

  const handleTabChange = (activeTabIndex) => {
    const newTab = [...tabs][activeTabIndex]?.value;
    navigate(`/settings/${newTab}`);
  };

  return (
    <>
      {org ? (
        <SettingsLayout
          button={null}
          hasUnsavedChanges={hasUnsavedChanges}
          onChange={handleTabChange}
          onDiscardChanges={handleDiscardChanges}
          tabs={tabs}
          title='Settings'
          withUnsavedModal
        />
      ) : (
        <FullscreenLoader show />
      )}
    </>
  );
};

export default Settings;
