import React from 'react';
import { useParams } from 'react-router-dom';

import CollectionCreate from 'components/Collections/CollectionCreate';
import { useFetchCollection } from 'hooks/useFetchCollection';

const CollectionManager = () => {
  const { id } = useParams<{ id: string }>();
  const { data: collection } = useFetchCollection(id!);

  return <CollectionCreate savedCollectionDetails={collection} />;
};
export default CollectionManager;
