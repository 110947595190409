import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import DefaultAppBar from 'components/shared/layout/DefaultAppBar';

const DefaultLayout = ({
  appBarStyles,
  children,
  contentPaddingBottom = 8,
  contentPaddingTop = 2,
  withoutContainer = false,
  ...props
}) => {
  const customStyles = props?.sx || {};
  return (
    <>
      {withoutContainer ? (
        <Stack direction='column' gap={1.25}>
          {props.title}
          {children}
        </Stack>
      ) : (
        <>
          <DefaultAppBar
            appBarStyles={appBarStyles}
            noPadding={withoutContainer}
            titleToolbarVariant='dense'
            {...props}
          />
          <ContentWidthContainer
            pb={contentPaddingBottom}
            pt={contentPaddingTop}
            {...customStyles}
          >
            {children}
          </ContentWidthContainer>
        </>
      )}
    </>
  );
};

DefaultLayout.propTypes = {
  appBarStyles: PropTypes.any,
  children: PropTypes.any,
  contentPaddingTop: PropTypes.number,
  header: PropTypes.node,
  withoutContainer: PropTypes.bool,
  ...DefaultAppBar.propTypes,
};

export default DefaultLayout;
