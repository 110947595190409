import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSnackbar } from 'notistack';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

interface GraphQLError {
  message: string;
}

interface DestroyEventsCollectionsResponse {
  eventsCollectionDestroy: {
    errors?: GraphQLError[];
    eventsCollection: {
      description: string;
      endsAt: string;
      events: {
        id: string;
      }[];
      id: string;
      name: string;
      organization: {
        id: string;
      };
      organizerEmployeeIds: [];
      startsAt: string;
      timezone: string;
      user: {
        id: string;
      };
    };
  };
}

const DESTROY_EVENTS_COLLECTION_MUTATION = gql`
  mutation EventsCollectionDestroy($input: EventsCollectionDestroyInput!) {
    eventsCollectionDestroy(input: $input) {
      eventsCollection {
        id
        name
        description
        imageUrl
        config
        startsAt
        endsAt
        timezone
        organization {
          id
        }
        organizerEmployeeIds
        user {
          id
        }
        events {
          id
        }
      }
      errors
    }
  }
`;
const handleGraphQLResponse = <T>(
  response: T | undefined,
  errors: GraphQLError[] | undefined,
  enqueueSnackbar: (
    message: string,
    options: { variant: 'success' | 'error' | 'warning' }
  ) => void
): T | null => {
  if (errors?.length) {
    enqueueSnackbar(`Failed: ${errors.map((e) => e.message).join(', ')}`, {
      variant: 'error',
    });
    return null;
  }

  if (!response) {
    enqueueSnackbar('Failed: No data returned', {
      variant: 'error',
    });
    return null;
  }

  return response;
};

export const useEventsCollectionDelete = () => {
  const graphQLClient = useGraphQLClient();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const response =
        await graphQLClient.request<DestroyEventsCollectionsResponse>(
          DESTROY_EVENTS_COLLECTION_MUTATION,
          { input: { id } }
        );

      return response;
    },
    onError: (error: any) => {
      enqueueSnackbar(`Failed to delete collection: ${error.message}`, {
        variant: 'error',
      });
    },
    onSuccess: (response: DestroyEventsCollectionsResponse) => {
      const { eventsCollectionDestroy } = response || {};

      const collectionData = handleGraphQLResponse(
        eventsCollectionDestroy?.eventsCollection,
        eventsCollectionDestroy?.errors,
        enqueueSnackbar
      );

      if (collectionData) {
        enqueueSnackbar('Collection deleted successfully!', {
          variant: 'success',
        });
      }
    },
  });
};
