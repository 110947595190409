import React, { useContext, useMemo } from 'react';
import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import { ArrowLeft, CircleWavyCheck, LockKey } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import { getRsvpStatusDetails } from 'components/Events/Controls/Rsvp/Rsvp.constants';
import { unavailableOptions } from 'components/Survey/SurveyUnavailable.constants';
import { AuthContext } from 'contexts/AuthContext';

const SurveyUnavailable = ({
  eventAvailable,
  hasAnsweredSurvey = false,
  isOrgLevelEvent,
  isPreEvent,
  onGuestList = true,
  rsvpStatus,
  surveyJustSubmitted,
}) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const cannotAnswerSurvey =
    (hasAnsweredSurvey && rsvpStatus === 'rsvp') || surveyJustSubmitted;

  const statusDetails = useMemo(
    () => getRsvpStatusDetails(rsvpStatus),
    [rsvpStatus]
  );

  const getUnavailableOptions = (option) =>
    unavailableOptions(isPreEvent, option, isOrgLevelEvent);

  const renderAlert = (title, body, icon, severity) => (
    <Alert data-testid='survey-unavailable' icon={icon} severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {body}
    </Alert>
  );

  const renderIcon = (iconType) =>
    iconType === 'CircleWavyCheck' ? (
      <CircleWavyCheck style={{ justifySelf: 'flex-start' }} />
    ) : (
      <LockKey size={35} />
    );

  return (
    <Stack alignItems='center' direction='column' gap={2} mb={2} mt={2}>
      {!onGuestList && isPreEvent ? (
        renderAlert(
          getUnavailableOptions('genericUnavailable').header,
          getUnavailableOptions('genericUnavailable').body,
          renderIcon('CircleWavyCheck'),
          'info'
        )
      ) : rsvpStatus === 'not_attending' || rsvpStatus === 'maybe_attending' ? (
        renderAlert(
          statusDetails.rsvpHeader,
          rsvpStatus === 'maybe_attending'
            ? statusDetails.rsvpSecondaryBody
            : statusDetails.rsvpBody,
          <statusDetails.Icon style={{ justifySelf: 'flex-start' }} />,
          statusDetails.colorName
        )
      ) : cannotAnswerSurvey ||
        !eventAvailable ||
        (!onGuestList && !isPreEvent) ? (
        <>
          {renderAlert(
            getUnavailableOptions(
              surveyJustSubmitted
                ? 'surveyJustSubmitted'
                : hasAnsweredSurvey
                ? 'hasAnsweredSurvey'
                : 'genericUnavailable'
            ).header,
            getUnavailableOptions(
              surveyJustSubmitted
                ? 'surveyJustSubmitted'
                : hasAnsweredSurvey
                ? 'hasAnsweredSurvey'
                : 'genericUnavailable'
            ).body,
            cannotAnswerSurvey
              ? renderIcon('CircleWavyCheck')
              : renderIcon('LockKey'),
            'info'
          )}

          {user && hasAnsweredSurvey ? (
            <Button
              data-testid='back-to-events'
              href='/events'
              startIcon={<ArrowLeft />}
              variant='contained'
            >
              Back to events
            </Button>
          ) : null}
        </>
      ) : null}
    </Stack>
  );
};

SurveyUnavailable.propTypes = {
  eventAvailable: PropTypes.bool,
  hasAnsweredSurvey: PropTypes.bool,
  isOrgLevelEvent: PropTypes.bool,
  isPreEvent: PropTypes.bool,
  onGuestList: PropTypes.bool,
  rsvpStatus: PropTypes.string,
  surveyJustSubmitted: PropTypes.bool,
};

export default SurveyUnavailable;
