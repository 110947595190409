import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldElement } from 'react-hook-form-mui';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { FloppyDisk, Trash } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import EventBudgetCurrencyField from 'components/Events/Controls/Budget/EventBudgetCurrencyField';
import EventExpenseCategoryField from 'components/Events/Controls/Budget/EventExpenseCategoryField';
import { useEventBudget } from 'hooks/useEventBudget';
import { useExpenseSave } from 'hooks/useExpenseSave';
import { useExpenseUpdate } from 'hooks/useExpenseUpdate';
import { useOrganization } from 'hooks/useOrganization';
import { Expense, ExpenseCategory } from 'types/EventBudget';

// const expenseSchema = z.object({
//   actual_cost: z
//     .number()
//     .min(0, 'Actual cost must be a positive number')
//     .optional(),
//   estimated_cost: z.number().min(0, 'Estimated cost must be a positive number'),
//   expense_category_id: z.number().min(1, 'Category is required'),
//   name: z.string().min(1, 'Name is required'),
//   notes: z
//     .string()
//     .max(1000, 'Notes must be 1000 characters or less')
//     .optional(),
//   vendor: z.string().optional(),
// });

interface Props {
  eventId: number;
  expenseCategories: ExpenseCategory[];
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  selectedExpense: Expense;
}

const EventExpenseFormDialog = ({
  eventId,
  expenseCategories,
  isOpen,
  onClose,
  onDelete,
  selectedExpense,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    retrieve: { data: budget },
  } = useEventBudget({ eventId });

  const {
    retrieve: { data: organization },
  } = useOrganization();

  const { isPending: isSaving, mutate: updateExpenseItem } = useExpenseUpdate({
    dataHandler: () => {
      onClose();
    },
    eventId,
  });

  const { mutate: createExpenseItem } = useExpenseSave({
    dataHandler: () => {
      onClose();
      enqueueSnackbar('Expense created', { variant: 'success' });
    },
    eventId,
  });

  const expenseForm = useForm({
    // criteriaMode: 'all',
    defaultValues: selectedExpense,
    mode: 'onBlur',
    resetOptions: { keepDirtyValues: true },
    // resolver: zodResolver(expenseSchema),
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    shouldUnregister: true,
    values: selectedExpense,
  });

  const isFormDirty = expenseForm.formState.isDirty;

  const saveExpense = (data: any) => {
    if (selectedExpense?.id) {
      updateExpenseItem({ ...data, id: selectedExpense.id });
    } else {
      createExpenseItem({
        expense_items: [{ ...data, event_budget_id: budget?.id }],
      });
    }
  };

  const activeCurrency = useMemo(
    () =>
      organization?.currencies?.find(
        (currency: { id: number }) => currency.id === budget?.currency_id
      ),
    [budget?.currency_id, organization?.currencies]
  );

  return (
    <FormProvider {...expenseForm}>
      <Dialog onClose={onClose} open={isOpen}>
        <DialogTitle variant='h2'>Expense details</DialogTitle>
        <DialogContent sx={{ pb: 3, pt: 0 }}>
          {expenseForm.formState.errors.name && (
            <Alert severity='error'>
              {expenseForm.formState.errors.name.message as string}
            </Alert>
          )}
          <TextFieldElement
            fullWidth
            label='Name'
            margin='normal'
            name='name'
            required
          />
          <Stack direction='column'>
            <EventBudgetCurrencyField
              control={expenseForm.control}
              currency={activeCurrency}
              label='Actual spend'
              name='actual_cost'
              sx={{
                maxWidth: 290,
              }}
            />
            <EventBudgetCurrencyField
              control={expenseForm.control}
              currency={activeCurrency}
              label='Estimated spend'
              name='estimated_cost'
              sx={{ maxWidth: 290 }}
            />
          </Stack>
          <EventExpenseCategoryField
            autocompleteProps={{
              sx: {
                '.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
                  py: 1.315,
                },
              },
            }}
            label='Category'
            name='expense_category_id'
            options={expenseCategories}
          />
          <TextFieldElement
            fullWidth
            label='Vendor'
            margin='normal'
            name='vendor'
            placeholder='Add a vendor'
          />
          <TextFieldElement
            fullWidth
            label='Notes'
            margin='normal'
            multiline
            name='notes'
            placeholder='Add notes'
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          {selectedExpense && (
            <Button
              color='neutral'
              onClick={onDelete}
              startIcon={<Trash size={24} />}
            >
              Delete
            </Button>
          )}
          <Box flexGrow={1}>&nbsp;</Box>
          <Button onClick={onClose} variant='outlined'>
            Cancel
          </Button>
          <Button
            disabled={!isFormDirty || isSaving}
            onClick={expenseForm.handleSubmit((data) => saveExpense(data))}
            startIcon={<FloppyDisk size={24} />}
            // type='submit'
            variant='contained'
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default EventExpenseFormDialog;
