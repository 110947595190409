import React from 'react';
import { Box, Container } from '@mui/material';
import PropTypes from 'prop-types';

const ContentWidthContainer = ({
  children,
  ContainerProps = {},
  ...wrapperProps
}) => (
  <Container maxWidth='xl' {...ContainerProps}>
    <Box
      px={{ lg: 4, md: 4, sm: 3, xl: 3, xs: 0 }}
      {...wrapperProps}
      height={wrapperProps?.height || '100%'}
      width='100%'
    >
      {children}
    </Box>
  </Container>
);

ContentWidthContainer.propTypes = {
  ContainerProps: PropTypes.object,
  content: PropTypes.any,
};

export default ContentWidthContainer;
