import React from 'react';
import { Stack, Typography } from '@mui/material';

const SectionWrapper = ({
  action,
  children,
  title,
  toolbar,
  hideTitle,
}: {
  action?: React.ReactNode;
  children: React.ReactNode;
  title?: string;
  toolbar?: React.ReactNode;
  hideTitle?: boolean;
}) => (
  <Stack direction='column' gap={1.5}>
    {!hideTitle && title && (
      <Stack direction='column' justifyContent='stretch'>
        <Stack alignItems='center' direction='row' justifyContent='space-between'>
          <Typography component='h3' variant='h4'>
            {title}
          </Typography>
          {action}
        </Stack>
        {toolbar}
      </Stack>
    )}
    {children}
  </Stack>
);

export default SectionWrapper;
