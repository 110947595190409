import React, { useMemo } from 'react';
import { Box, Chip, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MRT_Row } from 'material-react-table';

import {
  InvitationStatusId,
  InvitationStatusMap,
} from 'components/Events/Controls/GuestList/EventGuests.constants';
import TooltipWithCustomWidth from 'components/shared/TooltipWithCustomWidth';
import { Invitee } from 'gql/graphql';
import { useMuiPaletteColor } from 'hooks/useMuiPaletteColor';

dayjs.extend(relativeTime);

const EventGuestStatusChip = ({ row }: { row?: MRT_Row<Invitee> }) => {
  const { getValidColorName } = useMuiPaletteColor();

  const statusDetails = useMemo(() => {
    if (
      row &&
      row?.original &&
      row?.original?.status &&
      row?.original?.status?.length > 0
    ) {
      const status = row?.original?.status;
      if (
        status === 'invited' &&
        row?.original?.inviteScheduled &&
        row?.original?.inviteScheduledFor &&
        row?.original?.inviteScheduledFor?.length > 0 &&
        dayjs().isBefore(row?.original?.inviteScheduledFor)
      ) {
        return InvitationStatusMap.get('guest_list');
      }
      return InvitationStatusMap.get(status as InvitationStatusId);
    }
    return null;
  }, [row]);

  const hasColor = useMemo(
    () => statusDetails && (statusDetails?.colorName?.length || 0) > 0,
    [statusDetails]
  );
  const label = useMemo(
    () =>
      statusDetails && statusDetails?.name?.length > 0
        ? statusDetails?.name
        : ' ',
    [statusDetails]
  );
  const color = useMemo(
    () =>
      statusDetails?.colorName
        ? getValidColorName({
            colorName: statusDetails?.colorName,
          })
        : undefined,
    [getValidColorName, statusDetails?.colorName]
  );
  const StatusChip = useMemo(
    () =>
      row && (
        <Chip
          color={color}
          icon={
            statusDetails?.Icon && (
              <statusDetails.Icon size={20} style={{ minWidth: 20 }} />
            )
          }
          label={label}
          sx={{
            ...(hasColor
              ? {}
              : {
                  bgcolor: 'grey.100',
                  color: 'grey.900',
                }),
            width: '100%',
          }}
          variant='light'
        />
      ),
    [color, hasColor, label, row, statusDetails]
  );
  return row?.original && statusDetails?.id ? (
    <>
      {row?.original?.inviteScheduled ? (
        <TooltipWithCustomWidth
          placement='top'
          title={`Scheduled to receive invitation on ${dayjs(
            row?.original?.inviteScheduledFor
          ).format('MM/DD/YY [at] h:mma z')}.`}
          width={232}
        >
          <Box>{StatusChip}</Box>
        </TooltipWithCustomWidth>
      ) : (
        <Box>{StatusChip}</Box>
      )}
    </>
  ) : (
    <Skeleton variant='rounded' width={80} />
  );
};

export default EventGuestStatusChip;
