import { useCallback, useState } from 'react';

import { DialogState } from 'types/DialogState';

const useDialogState = () => {
  const [currentItem, setCurrentItem] = useState<any | null>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback((item?: any) => {
    if (item) {
      setCurrentItem(item);
    } else {
      setCurrentItem(null);
    }
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    const clearItemTimer = setTimeout(() => {
      setCurrentItem(undefined);
      clearTimeout(clearItemTimer);
    }, 300);
  }, []);

  return {
    close,
    currentItem,
    isOpen,
    open,
    setCurrentItem,
  } as DialogState;
};

export default useDialogState;
