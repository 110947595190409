import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import pluralize from 'pluralize';

import SlackChannelLabel from 'components/shared/SlackChannelLabel';

interface SlackUnsyncDialogProps {
  channels: { name: string; private: boolean }[];
  onClose: () => void;
  onUnsync: (onDone: () => void) => void;
  show: boolean;
}

const SlackUnsyncDialog: React.FC<SlackUnsyncDialogProps> = ({
  channels,
  onClose,
  onUnsync,
  show,
}) => {
  const [isWorking, setIsWorking] = useState(false);

  const handleClose = () => {
    setIsWorking(false);
    onClose();
  };

  const handleDelete = () => {
    setIsWorking(true);
    onUnsync(() => setIsWorking(false));
  };

  return (
    <Dialog aria-label='delete' onClose={handleClose} open={Boolean(show)}>
      <DialogTitle>
        Are you sure you want to remove Slack channels synced with a group?{' '}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack gap={2}>
            <Typography>
              The Slack {pluralize('channel', channels?.length, false)} listed
              below {channels?.length > 1 ? 'are' : 'is'} currently associated
              with a synced group. By continuing, members in the{' '}
              {pluralize('group', channels?.length, false)} associated with
              these channels will no longer continue syncing and you will only
              be able to manage members through the group.
            </Typography>
            <Stack direction='row' gap={1} sx={{ flexWrap: 'wrap' }}>
              {channels?.map(({ name, private: isPrivate }) => (
                <SlackChannelLabel
                  isChip
                  isPrivate={isPrivate}
                  key={name}
                  name={name}
                />
              ))}
            </Stack>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          data-testid='cancel'
          onClick={handleClose}
          variant='bordered'
        >
          Cancel
        </Button>
        <LoadingButton
          aria-label='confirm delete'
          color='primary'
          loading={isWorking}
          onClick={handleDelete}
          variant='contained'
        >
          Yes, continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SlackUnsyncDialog;
