import { useMemo } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import dayjs from 'dayjs';

import { EventDetailsFormData } from 'types/EventDetailsForm';
import FtnEvent from 'types/FtnEvent';

const useEventFormValues = (savedEventDetails: FtnEvent | undefined) => {
  const myCld = useMemo(
    () =>
      new Cloudinary({
        cloud: {
          cloudName: 'five-to-nine',
        },
      }),
    []
  );

  /*
      This memo must convert an FtnEvent -> EventDetailsFormData (or undefined -> undefined)
  */
  return useMemo<EventDetailsFormData | undefined>(() => {
    if (savedEventDetails === undefined) return undefined;

    // Cover image
    const coverImage = {
      cropDataUrl: savedEventDetails?.config?.cover_image_url,
      dimensions: savedEventDetails?.config?.cover_image_dimensions,
      fullDataUrl: myCld
        .image(savedEventDetails?.cover_image || undefined)
        .toURL(),
    };

    // Deterimine location type
    let locationType: 'hybrid' | 'inperson' | 'virtual' | undefined;
    if (
      savedEventDetails?.meeting?.service &&
      (savedEventDetails?.location || '')?.length > 0
    ) {
      locationType = 'hybrid';
    } else if (
      !savedEventDetails?.meeting?.service &&
      (savedEventDetails?.location || '')?.length > 0
    ) {
      locationType = 'inperson';
    } else if (
      savedEventDetails?.meeting?.service &&
      (savedEventDetails?.location || '')?.length === 0
    ) {
      locationType = 'virtual';
    }

    return {
      coverImage,
      description: savedEventDetails?.description,
      ends: dayjs(savedEventDetails?.ends),
      eventTypeId: savedEventDetails?.event_type_id || null,
      groupIds: savedEventDetails?.group_ids,
      hostIds: savedEventDetails?.host_ids,
      hosts: {
        hosts: savedEventDetails?.displayable_hosts,
      },
      id: savedEventDetails?.id,
      location: savedEventDetails?.location,
      locationType,
      meeting: {
        attendanceTracking:
          savedEventDetails?.meeting?.config?.attendance_tracking,
        link: savedEventDetails?.meeting?.link,
        service: savedEventDetails?.meeting?.service,
        serviceUid: savedEventDetails?.meeting?.service_uid,
      },
      name: savedEventDetails?.name,
      officeIds: savedEventDetails?.office_ids,
      privacy: savedEventDetails?.privacy,
      resources: savedEventDetails?.resources || [],
      rsvpPauseAllowed: savedEventDetails?.rsvp_pause_allowed,
      start: dayjs(savedEventDetails?.start),
    };
  }, [savedEventDetails, myCld]);
};

export default useEventFormValues;
