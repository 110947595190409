import {
  InvalidateQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { reactQueryAxios } from 'utils/axios';

interface Syncable {
  created_at: string;
  id: number;
  organization_id: number;
  syncables_id: number;
  syncables_type: string;
  updated_at: string;
}

interface UpdateSyncablesData {
  ids: number[] | string[];
  type: string;
}

interface ErrorResponse {
  data: any;
  message: string;
  status: number | null;
}

export const useSyncables = () =>
  useQuery<Syncable[], ErrorResponse>({
    queryFn: async () => {
      const { data, error } = await reactQueryAxios<Syncable[]>({
        method: 'get',
        url: '/api/organizations/syncables',
      });
      if (error) throw error;
      return data || [];
    },
    queryKey: ['syncables'],
  });

export const useUpdateSyncables = () => {
  const queryClient = useQueryClient();

  return useMutation<Syncable[], ErrorResponse, UpdateSyncablesData>({
    mutationFn: async (data: UpdateSyncablesData) => {
      const { data: responseData, error } = await reactQueryAxios<Syncable[]>({
        data: {
          syncables: [data],
        },
        method: 'put',
        url: '/api/organizations/syncables',
      });
      if (error) throw error;
      return responseData || [];
    },
    onError: (error: ErrorResponse) => {
      console.error('Error updating syncables:', error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['syncables'] as InvalidateQueryFilters);
    },
  });
};
