import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Stack, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { withIndefiniteArticle } from 'utils/text';
import { isImageFileType } from 'utils/valid_file';

const UploadContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isDisabled' && prop !== 'isReadyToDrop',
})(({ isDisabled, isReadyToDrop, theme }) => {
  const getBorderStyles = ({ color, gapColor }) =>
    `repeating-linear-gradient(0deg, ${color}, ${color} 8px, ${gapColor} 8px, ${gapColor} 16px, ${color} 16px), repeating-linear-gradient(90deg, ${color}, ${color} 8px, ${gapColor} 8px, ${gapColor} 16px, ${color} 16px), repeating-linear-gradient(180deg, ${color}, ${color} 8px, ${gapColor} 8px, ${gapColor} 16px, ${color} 16px), repeating-linear-gradient(270deg, ${color}, ${color} 8px, ${gapColor} 8px, ${gapColor} 16px, ${color} 16px)`;

  const borderColors = {
    color: isReadyToDrop
      ? theme.palette.primary.main
      : theme.palette.grey[isDisabled ? 400 : 500],
    gapColor: isReadyToDrop ? theme.palette.primary.main : 'transparent',
  };
  const hoverBorderColors = {
    color: isReadyToDrop
      ? theme.palette.primary.main
      : theme.palette.grey[isDisabled ? 400 : 600],
    gapColor: isReadyToDrop ? theme.palette.primary.main : 'transparent',
  };
  return {
    '&:hover': {
      backgroundImage: getBorderStyles(hoverBorderColors),
    },
    alignItems: 'center',
    backgroundColor: isReadyToDrop
      ? theme.palette.primary.light
      : theme.palette.grey[50],
    backgroundImage: getBorderStyles(borderColors),
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
    borderRadius: 1,
    color: theme.palette.grey[isDisabled ? 500 : 600],
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 300,
    justifyContent: 'center',
    outline: 'none',
    padding: theme.spacing(3),
    transition: 'all 0.24s ease-in-out',
  };
});

const FileUploader = ({
  customSubtext = '',
  customText,
  Icon,
  id = '',
  isDisabled,
  noun,
  onDrop,
  showButton = true,
  sx,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isReadyToDrop, setIsReadyToDrop] = useState(false);
  const [, setIsRemoving] = useState(false);

  const isImage = noun === 'image';

  const { getInputProps, getRootProps, isDragAccept, isDragReject, isFocused } =
    useDropzone({
      // accept: {
      //   ...(isImage ? { 'image/*': imageFileTypes } : { 'text/csv': [] }),
      // },
      disabled: isDisabled,
      onDragEnter: () => setIsReadyToDrop(true),
      onDragLeave: () => setIsReadyToDrop(false),
      onDrop: (files) => {
        setIsReadyToDrop(false);
        if (files.length === 0) {
          setIsRemoving(false);
        }
        if (isImage && !isImageFileType(files[0])) {
          enqueueSnackbar(
            `You must upload ${withIndefiniteArticle(noun)} file.`,
            {
              variant: 'error',
            }
          );
        } else {
          onDrop(files);
        }
      },
    });

  return (
    <UploadContainer
      {...getRootProps(isFocused, isDragAccept, isDragReject)}
      isDisabled={isDisabled}
      isReadyToDrop={isDisabled ? null : isReadyToDrop}
      sx={sx}
    >
      <Stack
        alignItems='center'
        direction='column'
        justifyContent='center'
        mb={-1}
        spacing={4}
      >
        <Stack
          alignItems='center'
          direction='column'
          gap={1}
          justifyContent='center'
        >
          <input id={id} {...getInputProps()} />
          <Icon size={32} />
          <Typography mt={1} textAlign='center' variant='body2'>
            {customText ||
              `Drop ${withIndefiniteArticle(noun)} here or browse your files`}
          </Typography>
          {customSubtext?.length > 0 && (
            <Typography textAlign='center' variant='overline'>
              {customSubtext}
            </Typography>
          )}
        </Stack>
        {showButton && (
          <Button
            data-testid='browse-files'
            disabled={isDisabled}
            variant='contained'
          >
            Browse files
          </Button>
        )}
      </Stack>
    </UploadContainer>
  );
};

FileUploader.propTypes = {
  customSubtext: PropTypes.string.optional,
  customText: PropTypes.string.optional,
  Icon: PropTypes.any,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  noun: PropTypes.string,
  onDrop: PropTypes.func,
  showButton: PropTypes.bool,
  sx: PropTypes.object,
};

export default FileUploader;
