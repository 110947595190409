import React, { useContext } from 'react';
import { Box, useTheme } from '@mui/material';

import RichTextDisplay from 'components/shared/RichTextDisplay';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useEventDateTimeRange from 'hooks/useEventDateTimeRange';

interface Props {
  customMessage: string;
  event: any;
}

const PreviewEmailInvitation = ({ customMessage, event }: Props) => {
  const { organization } = useContext(OrganizationContext);
  const eventDateTimeRange = useEventDateTimeRange(event);
  // const { displayName: eventLocationType } = useEventLocationType(event);
  const theme = useTheme();

  return (
    <div
      className='wrapper'
      data-body-style='font-size:14px; font-family:arial,helvetica,sans-serif; color:#000000; background-color:#f2f2f2;'
      data-link-color='#636E7B'
    >
      <div className='webkit'>
        <table
          align='center'
          border={0}
          style={{
            borderCollapse: 'collapse',
            borderWidth: 0,
            width: '100%',
          }}
          width='100%'
        >
          <tbody>
            <tr>
              <td
                align='left'
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  padding: '0px 0px 0px 0px',
                  textAlign: 'left',
                }}
                width='100%'
              >
                {/* Spacer (pre-header) */}
                <table
                  border={0}
                  className='module preheader preheader-hide'
                  data-type='preheader'
                  style={{ height: 24 }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td />
                    </tr>
                  </tbody>
                </table>
                {/* Organization logo */}
                <table
                  border={0}
                  className='module'
                  data-muid='4136272d-a713-4471-8740-da92ed1f7247'
                  data-type='code'
                  style={{ height: 45, tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          maxHeight: '45.4px',
                          minWidth: 300,
                          width: '50%',
                        }}
                        valign='top'
                      >
                        {organization?.logo ? (
                          <div
                            style={{
                              backgroundColor: '#FFFFFF',
                              border: 'none',
                              paddingBottom: 20,
                              paddingTop: 30,
                              textAlign: 'center',
                            }}
                          >
                            <img
                              alt='company logo'
                              src={organization?.logo}
                              style={{
                                borderWidth: 0,
                                display: 'inline-block',
                                height: 'auto',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              fontSize: 6,
                              lineHeight: '10px',
                              padding: '0px 0px 0px 0px',
                              textAlign: 'center',
                            }}
                          >
                            <img
                              alt=''
                              className='max-width'
                              data-proportionally-constrained='true'
                              data-responsive='true'
                              src='http://cdn.mcauto-images-production.sendgrid.net/bbfd5678dc161eb3/68a5adcf-da93-4866-9ea7-0399e244e1be/740x112.png'
                              style={{
                                borderWidth: 0,
                                color: '#000000',
                                display: 'inline-block',
                                fontFamily: 'Helvetica, arial, sans-serif',
                                fontSize: 16,
                                height: 'auto !important',

                                textDecoration: 'none',
                              }}
                              width={300}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* Spacer */}
                <table
                  border={0}
                  className='module'
                  data-muid='7070007d-21a7-4763-aa32-17727e834a8f'
                  data-type='spacer'
                  style={{ height: 30, tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: '0px 0px 30px 0px',
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                {/* Event image */}
                <table
                  border={0}
                  className='module'
                  data-muid='a0a5c719-7c13-49dc-9ba9-b0d8d30a2c17'
                  data-type='code'
                  style={{ borderCollapse: 'collapse', tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td height='100%' style={{ padding: 0 }} valign='top'>
                        {event?.config?.cover_image_url ? (
                          <img
                            alt=''
                            className='full-width img600x315'
                            height={315}
                            src={event?.config?.cover_image_url}
                            style={{
                              borderWidth: 0,
                              display: 'block',
                              height: 'auto',
                              maxWidth: '100%',
                              width: '100%',
                            }}
                            width={600}
                          />
                        ) : (
                          <img
                            alt=''
                            className='full-width img600x315'
                            height={315}
                            src='https://res.cloudinary.com/five-to-nine/image/upload/v1622648516/Default_Event_Image.jpg'
                            style={{
                              borderWidth: 0,
                              display: 'block',
                              height: 'auto',
                              maxWidth: '100%',
                            }}
                            width={600}
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Box px={2}>
                  {/* Spacer */}
                  <table
                    border={0}
                    className='module'
                    data-muid='f10b7db3-7ddd-4ca1-ac15-f78ffdad9eff'
                    data-type='spacer'
                    style={{ height: 40, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: '0px 0px 40px 0px',
                          }}
                        />
                      </tr>
                    </tbody>
                  </table>
                  {/* You re invited to... */}
                  <table
                    border={0}
                    className='module'
                    data-mc-module-version='2019-10-22'
                    data-muid='8eb77354-58b0-49e6-9b06-6436d2635d10'
                    data-type='text'
                    style={{ minHeight: 41, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          height='100%'
                          style={{
                            lineHeight: '18px',
                            textAlign: 'inherit',
                          }}
                          valign='top'
                        >
                          <div>
                            <div
                              style={{
                                fontFamily: 'inherit',
                                minHeight: '18.5px',
                                textAlign: 'center',
                              }}
                            >
                              <span
                                style={{
                                  color: '#AAAAAA',
                                  fontFamily: 'inherit',
                                  fontSize: 18,
                                  letterSpacing: 4,
                                  textTransform: 'uppercase',
                                }}
                              >
                                You&apos;re invited to
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* Event name */}
                  <table
                    border={0}
                    className='module'
                    data-mc-module-version='2019-10-22'
                    data-muid='bf2f4657-13dd-40c7-8548-6984051253db'
                    data-type='text'
                    style={{
                      height: 'auto',
                      minHeight: 48,
                      tableLayout: 'fixed',
                    }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            textAlign: 'inherit',
                          }}
                          valign='top'
                        >
                          <div>
                            <div
                              style={{
                                fontFamily: 'inherit',
                                fontSize: 36,
                                fontWeight: 700,
                                lineHeight: 'normal',
                                textAlign: 'center',
                              }}
                            >
                              {event.name}
                            </div>
                            <div />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* Event date time */}
                  <table
                    border={0}
                    className='module'
                    data-mc-module-version='2019-10-22'
                    data-muid='8eb77354-58b0-49e6-9b06-6436d2635d10.1'
                    data-type='text'
                    style={{ minHeight: 60, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          height='100%'
                          style={{
                            lineHeight: '22px',
                            padding: '18px 0px 18px 0px',
                            textAlign: 'inherit',
                          }}
                          valign='top'
                        >
                          <div>
                            <div
                              style={{
                                fontFamily: 'inherit',
                                textAlign: 'center',
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: 'inherit',
                                  fontSize: 20,
                                }}
                              >
                                {eventDateTimeRange}
                              </span>
                            </div>
                            <div />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* Custom message OR event description */}
                  <table
                    cellPadding='0'
                    cellSpacing='0'
                    className='module'
                    style={{ minHeight: 52, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          height='100%'
                          style={{
                            lineHeight: '22px',
                            textAlign: 'inherit',
                          }}
                          valign='top'
                        >
                          <div>
                            <div
                              style={{
                                fontFamily: 'inherit',
                                textAlign: 'center',
                              }}
                            >
                              <span
                                style={{ fontSize: 16, textAlign: 'center' }}
                              >
                                <RichTextDisplay
                                  sx={{ textAlign: 'center' }}
                                  text={
                                    customMessage && customMessage?.length > 0
                                      ? customMessage
                                      : event?.description
                                  }
                                />
                              </span>
                            </div>
                            <div />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* RSVP button */}
                  <table
                    border={0}
                    className='module'
                    data-muid='5c2c7c0a-c0c2-445c-94ba-9dbc66fc26e7'
                    data-role='module-button'
                    data-type='button'
                    style={{ height: 48, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          align='center'
                          className='outer-td'
                          style={{
                            padding: '0px 0px 0px 0px',
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: '#008290',
                              border: '1px solid #008290',
                              borderColor: '#008290',
                              borderRadius: 4,
                              borderStyle: 'solid',
                              borderWidth: 1,
                              color: '#ffffff',
                              display: 'inline-block',
                              fontFamily: 'inherit',
                              fontSize: 20,
                              fontWeight: 'normal',
                              letterSpacing: '1px',
                              lineHeight: '100%',
                              maxHeight: '48.5',
                              minWidth: 200,
                              padding: '12px 18px 12px 18px',
                              textAlign: 'center',
                              textDecoration: 'none',
                              width: '40%',
                            }}
                          >
                            RSVP
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* You received this email because... */}
                  <table
                    border={0}
                    className='module'
                    data-mc-module-version='2019-10-22'
                    data-muid='51c88328-a7f6-45aa-ac22-b67ac347187a'
                    data-type='text'
                    style={{ height: 100, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          height='100%'
                          style={{
                            lineHeight: '22px',
                            padding: '40px 16px 18px 16px',
                            textAlign: 'inherit',
                          }}
                          valign='top'
                        >
                          <div>
                            <div
                              style={{
                                color: '#818181',
                                fontFamily: 'inherit',
                                fontSize: 14,
                                lineHeight: '22px',
                                textAlign: 'center',
                                whiteSpace: 'normal',
                              }}
                            >
                              <span
                                style={{
                                  display: 'inline',
                                }}
                              >
                                You received this email because you were invited
                                to{' '}
                              </span>
                              <span
                                style={{
                                  textDecorationColor: 'currentcolor',
                                  textDecorationLine: 'underline',
                                  textDecorationStyle: 'solid',
                                  textDecorationThickness: 'auto',
                                }}
                              >
                                {event.name}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
                {/* Divider */}
                <table
                  border={0}
                  className='module'
                  data-muid='20492ba1-3abb-4d8f-a98d-fc4aacc1d981'
                  data-type='divider'
                  style={{ borderCollapse: 'collapse', tableLayout: 'fixed' }}
                  width='100%'
                >
                  <tbody>
                    <tr>
                      <td
                        height='100%'
                        style={{
                          padding: '0px 0px 0px 0px',
                        }}
                        valign='top'
                      >
                        <table
                          align='center'
                          border={0}
                          style={{
                            fontSize: 2,
                            height: 2,
                            lineHeight: '2px',
                          }}
                          width='100%'
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  backgroundColor: '#f2f2f2',
                                  padding: '0px 0px 2px 0px',
                                }}
                              />
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Box px={2}>
                  {/* Spacer */}
                  <table
                    border={0}
                    className='module'
                    data-muid='f10b7db3-7ddd-4ca1-ac15-f78ffdad9eff.2.1'
                    data-type='spacer'
                    style={{ height: 30, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: '0px 0px 40px 0px',
                          }}
                        />
                      </tr>
                    </tbody>
                  </table>
                  {/* Discover & manage events... */}
                  <table
                    border={0}
                    className='module'
                    data-mc-module-version='2019-10-22'
                    data-muid='68678709-9e14-47a3-bbf3-c795e3f71241'
                    data-type='text'
                    style={{ minHeight: 72, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          height='100%'
                          style={{
                            lineHeight: '22px',
                            padding: '18px 0px 10px 0px',
                            textAlign: 'inherit',
                          }}
                          valign='top'
                        >
                          <div>
                            <div
                              style={{
                                color: '#000000',
                                fontFamily: 'inherit',
                                fontSize: 14,
                                fontStyle: 'normal',
                                fontVariantCaps: 'normal',
                                fontWeight: 'normal',
                                letterSpacing: 'normal',
                                textAlign: 'center',
                                textIndent: 0,
                                textTransform: 'none',
                                WebkitTextStrokeWidth: 0,
                                wordSpacing: 0,
                              }}
                            >
                              <span>Discover and manage your events on </span>
                              <span
                                style={{
                                  color: theme.palette.primary.main,
                                  fontWeight: 700,
                                  textDecorationColor: 'initial',
                                  textDecorationLine: 'underline',
                                  textDecorationStyle: 'initial',
                                  textDecorationThickness: '2px',
                                  textUnderlineOffset: '2px',
                                }}
                              >
                                Five to Nine
                              </span>
                            </div>
                            <div
                              style={{
                                fontFamily: 'inherit',
                                textAlign: 'center',
                              }}
                            >
                              <span
                                style={{
                                  caretColor: 'rgb(0, 0, 0)',
                                  color: '#000000',
                                  fontFamily: 'inherit',
                                  fontSize: 14,
                                  fontStyle: 'normal',
                                  fontVariantCaps: 'normal',
                                  fontWeight: 'normal',
                                  letterSpacing: 'normal',
                                  paddingBottom: 10,
                                  textAlign: 'center',
                                  textDecorationColor: 'initial',
                                  textDecorationLine: 'none',
                                  textDecorationStyle: 'initial',
                                  textDecorationThickness: 'initial',
                                  textIndent: 0,
                                  textTransform: 'none',
                                  WebkitTextStrokeWidth: 0,
                                  wordSpacing: 0,
                                }}
                              >
                                222 W Merchandise Mart Plaza #1212. Chicago, IL,
                                60654
                              </span>
                            </div>
                            <div />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* Spacer */}
                  <table
                    border={0}
                    className='module'
                    data-muid='f10b7db3-7ddd-4ca1-ac15-f78ffdad9eff.1'
                    data-type='spacer'
                    style={{ height: 80, tableLayout: 'fixed' }}
                    width='100%'
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: '0px 0px 30px 0px',
                          }}
                        />
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PreviewEmailInvitation;
