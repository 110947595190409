import React, { useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import PropTypes from 'prop-types';

import CheckboxesGroup from 'components/shared/CheckboxesGroup';

const QuestionWithOptions = ({
  onSupplyResponse,
  options,
  question,
  questionIndex,
  questionPosition,
  readonly,
  value,
}) => {
  const [controlType, setControlType] = useState(
    question.question_type === 'single_select' ? 'radio' : 'checkbox'
  );

  useEffect(() => {
    setControlType(
      question.question_type === 'single_select' ? 'radio' : 'checkbox'
    );
  }, [question.question_type]);

  const controlId = useMemo(
    () => `${question.id}-${controlType}-group`,
    [controlType, question.id]
  );

  const label = useMemo(
    () =>
      question.title.length > 0
        ? `${questionPosition}. ${question.title}`
        : `Please add a valid question title.`,
    [question.title, questionPosition]
  );

  const labelId = useMemo(() => `${controlId}-label`, [controlId]);

  const questionIdentifier = useMemo(
    () => question.id || -Math.abs(questionIndex),
    [question.id, questionIndex]
  );

  return controlType === 'radio' ? (
    <FormControl id={controlId} required={question.required}>
      <FormLabel id={labelId}>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={labelId}
        name={controlId}
        value={value || []}
      >
        {options.map((opt, index) => {
          const optionIdentifier = opt.id || index;
          return (
            <FormControlLabel
              control={<Radio inputProps={{ readOnly: readonly }} />}
              key={optionIdentifier}
              label={opt.text}
              onChange={(e) =>
                readonly
                  ? e.preventDefault()
                  : onSupplyResponse &&
                    onSupplyResponse(questionIdentifier, optionIdentifier)
              }
              value={optionIdentifier}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  ) : (
    <CheckboxesGroup
      controlId={controlId}
      label={label}
      labelId={labelId}
      onChange={(newVals) => onSupplyResponse(questionIdentifier, newVals)}
      options={options}
      readonly={readonly}
      required={question.required}
    />
  );
};

QuestionWithOptions.propTypes = {
  noun: PropTypes.any,
  onSupplyResponse: PropTypes.func,
  options: PropTypes.any,
  question: PropTypes.shape({
    id: PropTypes.any,
    question_type: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
  }),
  questionIndex: PropTypes.number,
  questionPosition: PropTypes.number,
  readonly: PropTypes.any,
  value: PropTypes.any,
};

export default QuestionWithOptions;
