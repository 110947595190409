import React, { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { ConditionalLoader } from 'components/Core/Loader';
import SurveyCard from 'components/Events/Controls/Surveys/SurveyCard';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PostEventSurveyOption, PreEventSurveyOption } from 'utils/survey';

const SurveyStep = () => {
  const {
    actions: { requestAnalyticsData },
    state: { inManagerContext, savedEvent },
  } = useContext(ManagerContext);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (inManagerContext) {
      setLoading(true);
      requestAnalyticsData(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Stack direction='column' spacing={3}>
      <ConditionalLoader condition={[isLoading]}>
        <SurveyCard
          isLeft
          responsesCount={savedEvent?.pre_event_survey_count}
          text='Gather information when your guests RSVP to the event.'
          title={PreEventSurveyOption.label}
          type={PreEventSurveyOption.value}
        />
        <SurveyCard
          responsesCount={savedEvent?.post_event_survey_count}
          subtitle='This survey will be sent out to attendees after your event.'
          text={`Measure your event's success rate by collecting feedback after the event.`}
          title={PostEventSurveyOption.label}
          type={PostEventSurveyOption.value}
        />
      </ConditionalLoader>
    </Stack>
  );
};

export default SurveyStep;
