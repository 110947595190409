import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CheckCircle,
  HourglassHigh,
  Lightning,
  XCircle,
} from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import { hasStarted } from 'utils/event';
import { SurveyAnonymityOptions } from 'utils/survey';

const SurveySettings = ({
  delay,
  description,
  displayResponders,
  event,
  type,
}) => {
  const theme = useTheme();

  const SurveySummaryItem = ({ condition, Icon, iconColor, text }) => {
    const iconSize = { size: 20 };
    return (
      <ListItem disableGutters disablePadding>
        <ListItemIcon sx={{ minWidth: 32 }}>
          {Icon ? (
            <Icon {...iconSize} color={iconColor} />
          ) : (
            <>
              {condition ? (
                <CheckCircle {...iconSize} color={theme.palette.success.main} />
              ) : (
                <XCircle {...iconSize} color={theme.palette.grey[600]} />
              )}
            </>
          )}
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{ variant: 'body1' }}
        />
      </ListItem>
    );
  };

  return (
    <Stack alignItems='stretch' direction='column' gap={1} p={2}>
      <Typography variant='body2'>Survey settings</Typography>
      <List data-testid='TODO:DATA-LIST-51942' dense disablePadding>
        <SurveySummaryItem
          condition={description?.length > 0}
          text={
            description?.length > 0
              ? 'Survey description added'
              : `No survey description`
          }
        />
        <SurveySummaryItem
          condition={displayResponders}
          Icon={
            SurveyAnonymityOptions.find((o) => o.value === displayResponders)
              .Icon
          }
          iconColor={
            displayResponders
              ? theme.palette.info.main
              : theme.palette.secondary.main
          }
          text={
            SurveyAnonymityOptions.find((o) => o.value === displayResponders)[
              type === 'pre_event_survey' || hasStarted(event)
                ? 'activeLabel'
                : 'switchLabel'
            ]
          }
        />
        {type === 'post_event_survey' && (
          <SurveySummaryItem
            condition
            Icon={(props) =>
              delay === '0' ? (
                <Lightning {...props} />
              ) : (
                <HourglassHigh {...props} />
              )
            }
            iconColor={theme.palette.warning[delay === '0' ? 'main' : 'dark']}
            text={
              delay === '0'
                ? 'Send post-event survey immediately after event ends'
                : `Delay sending post-event survey by ${delay} after event ends`
            }
          />
        )}
      </List>
    </Stack>
  );
};

SurveySettings.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  displayResponders: PropTypes.bool,
  type: PropTypes.string,
};

export default SurveySettings;
