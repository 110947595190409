import React from 'react';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

type TooltipWithCustomWidthProps = {
  children: React.ReactNode;
  width: number | string;
} & TooltipProps;

const TooltipWithCustomWidth = ({
  children,
  width,
  ...props
}: TooltipWithCustomWidthProps) => (
  <Tooltip
    {...props}
    PopperProps={{
      sx: {
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: width,
        },
      },
    }}
  >
    {children}
  </Tooltip>
);

export default TooltipWithCustomWidth;
