import { getPersonalCalendar } from 'utils/calendar';

export const FindSelected = (selections, invite_selections) =>
  selections?.filter(
    (selection) =>
      invite_selections?.length > 0 && invite_selections?.includes(selection.id)
  );

const getEmployeeIds = (selections) =>
  selections?.flatMap((selection) => selection?.employee_ids);

// need a unique count because there might be overlaps of individuals on different entities
export const GetUniqueEmployeeCount = (
  groupEmployees,
  listEmployees,
  officeEmployees,
  guests,
  slackChannelEmployees
) => {
  const nonContactsIds = []
    .concat(getEmployeeIds(groupEmployees) || [])
    ?.concat(getEmployeeIds(listEmployees) || [])
    ?.concat(getEmployeeIds(officeEmployees) || [])
    ?.concat(getEmployeeIds(slackChannelEmployees) || []);

  const allContactsIds = nonContactsIds?.concat(guests || []);

  // need these employee ids so that we can show them as checked in the Contacts panel when Groups, Lists, or Offices are selected
  const nonContactsUniqueIds = [...new Set(nonContactsIds)];

  // count to be displayed in the SelectedGuests panel
  const uniqueContactsCount = [...new Set(allContactsIds)]?.length;

  const uniqueContactsIds = [...new Set(allContactsIds)];

  return {
    nonContactsUniqueIds,
    uniqueContactsCount,
    uniqueContactsIds,
  };
};

export const CalculateAccumulatedRecipients = (
  invites,
  groups,
  lists,
  offices
) => {
  const invitedIndividualContacts = invites?.invitation.flatMap(
    (invite) =>
      invite?.recipients?.find((recipient) => recipient.type === 'Invitees')
        .employee_ids
  );

  // needs the unique count here because slack channel invitees can have repeat values
  const uniqueInvitedIndividualContacts = [
    ...new Set(invitedIndividualContacts),
  ];

  const invitedGroupIds = invites?.invitation.flatMap(
    (invite) =>
      invite?.recipients?.find((recipient) => recipient.type === 'Groups').ids
  );
  const invitedListsIds = invites?.invitation.flatMap(
    (invite) =>
      invite?.recipients?.find((recipient) => recipient.type === 'Lists').ids
  );
  const invitedOfficesIds = invites?.invitation.flatMap(
    (invite) =>
      invite?.recipients?.find((recipient) => recipient.type === 'Offices').ids
  );

  const invitedGroups = FindSelected(groups, invitedGroupIds);
  const invitedContactLists = FindSelected(lists, invitedListsIds);
  const invitedOffices = FindSelected(offices, invitedOfficesIds);

  const invitedAllEmployees = GetUniqueEmployeeCount(
    invitedGroups,
    invitedContactLists,
    invitedOffices,
    invitedIndividualContacts
  );

  const invitedAllNonContacts = []
    .concat(invitedGroups || [])
    .concat(invitedContactLists || [])
    .concat(invitedOffices || []);

  return {
    invitedAllEmployees,
    invitedAllNonContacts,
    invitedIndividualContacts,
    uniqueInvitedIndividualContacts,
  };
};

export const CalculateSavedCalendars = (user, org, savedEvent, commId) => {
  const allCalendarsMapped = [
    ...(user ? [getPersonalCalendar(user)] : []),
    ...(org?.calendars
      ? org.calendars.map((cal) => ({
          label: cal?.name,
          value: cal?.calendar_id,
        }))
      : []),
  ];

  const currentInvitations = commId
    ? savedEvent?.event_invitations?.filter(
        (invite) => invite.communication_history_id === commId
      )
    : savedEvent?.event_invitations;

  const savedCalendarIds = currentInvitations?.map((method) => ({
    is_personal:
      method.invite_type === 'calendar' && method?.calendar?.is_personal,
    value: method.invite_type === 'calendar' && method?.calendar?.calendar_id,
  }));

  const savedCalendarIdsAsCalendars =
    savedCalendarIds?.length > 0
      ? allCalendarsMapped?.filter(
          (cal) =>
            savedCalendarIds?.find((method) => method?.value === cal?.value) ||
            (cal?.value === 'personal' &&
              savedCalendarIds?.find((method) => method?.is_personal))
        )
      : [];

  return {
    allCalendars: allCalendarsMapped,
    savedCalendarIdsAsCalendars,
  };
};
