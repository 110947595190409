/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { Gear, UsersThree } from '@phosphor-icons/react';

import BudgetPeriod from 'components/Settings/BudgetPeriod';
import BudgetsFormDialog from 'components/Settings/BudgetsFormDialog';
import CurrencySettings from 'components/Settings/CurrencySettings';
import ExpenseCategoriesSettings from 'components/Settings/ExpenseCategoriesSettings';
import CardsBelowHeading from 'components/shared/CardsBelowHeading';
import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import { useGroupsBudgets } from 'hooks/useGroupsBudget';
import { useOrganization } from 'hooks/useOrganization';
import Currency from 'types/Currency';

const BudgetSettings = () => {
  const {
    retrieve: { data: organization },
  } = useOrganization();

  const [isManagingBudgets, setIsManagingBudgets] = useState(false);

  const { rowsWithIndex: groupsBudgetData } = useGroupsBudgets();

  const groupsWithBudgets = groupsBudgetData.some((group) => group.budget > 0);

  const filteredGroupsBudgetData = groupsBudgetData.filter(
    (group) => group.budget > 0
  );

  const columns = [
    {
      field: 'name',
      headerName: 'Group',
      width: 390,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      renderCell: ({ row }: GridCellParams) => {
        const currency = organization?.currencies.find(
          (c: Currency) => c.id === row.currency_id
        );
        const formattedBudget = new Intl.NumberFormat('en-US', {
          currency: currency?.code || 'USD',
          style: 'currency',
        }).format(row.budget);

        return (
          <Box alignItems='center' display='flex'>
            {formattedBudget} {currency?.code}
          </Box>
        );
      },
      width: 165,
    },
  ];

  const NoRowsOverlay = () => (
    <CardsBelowHeading
      cards={[]}
      EmptyStateProps={{
        button: {
          onClick: () => setIsManagingBudgets(true),
          size: 'small',
          startIcon: <Gear />,
        },
        buttonLabel: 'Manage',
        Icon: UsersThree,
        primary: 'No group budgets exist yet',
        secondary: 'Manage your group budgets to set budgets for each group.',
      }}
      heading='Group budgets'
    />
  );

  return (
    <>
      <Grid alignItems='stretch' container direction='row' spacing={3}>
        <Grid container item sm={6} xs={12}>
          <CardsBelowHeading heading='Budget settings'>
            <CurrencySettings organization={organization} />
            <ExpenseCategoriesSettings organization={organization} />
            <BudgetPeriod organization={organization} />
          </CardsBelowHeading>
        </Grid>
        <Grid item sm={6} xs={12}>
          {groupsWithBudgets ? (
            <>
              <Box display='flex' justifyContent='flex-end' mb={1}>
                <Button
                  onClick={() => setIsManagingBudgets(true)}
                  startIcon={<Gear />}
                  variant='text'
                >
                  Manage
                </Button>
              </Box>
              <FtnDataGrid
                columns={columns}
                noRowsOverlay={NoRowsOverlay}
                rows={filteredGroupsBudgetData || []}
              />
            </>
          ) : (
            <CardsBelowHeading
              cards={[]}
              EmptyStateProps={{
                button: {
                  onClick: () => setIsManagingBudgets(true),
                  size: 'small',
                  startIcon: <Gear />,
                },
                buttonLabel: 'Manage',
                Icon: UsersThree,
                primary: 'No group budgets exist yet',
                secondary:
                  'Manage your group budgets to set budgets for each group.',
              }}
              heading='Group budgets'
            />
          )}
        </Grid>
      </Grid>
      <BudgetsFormDialog
        onClose={() => setIsManagingBudgets(false)}
        open={isManagingBudgets}
      />
    </>
  );
};

export default BudgetSettings;
