import React from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';

import { LinkedGroup } from 'types/EventBudget';

interface BudgetConfirmationDialogProps {
  confirmButtonText?: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  removedGroups?: LinkedGroup[];
  title: string;
}

const BudgetConfirmationDialog: React.FC<BudgetConfirmationDialogProps> = ({
  confirmButtonText = 'Confirm',
  message,
  onCancel,
  onConfirm,
  open,
  removedGroups,
  title,
}) => (
  <Dialog onClose={onCancel} open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {message && !removedGroups && (
        <DialogContentText>{message}</DialogContentText>
      )}
      {removedGroups && (
        <>
          <DialogContentText>{message}</DialogContentText>
          <Stack direction='row' flexWrap='wrap' mt={2}>
            {removedGroups.map((group) => (
              <Chip
                key={group.id}
                label={`${group.name} (${group.currency_symbol})`}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} variant='outlined'>
        Cancel
      </Button>
      <Button color='primary' onClick={onConfirm} variant='contained'>
        {confirmButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default BudgetConfirmationDialog;
