import React, { useContext, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { PencilSimple } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import MicrosoftLogin from 'components/Onboard/MicrosoftLogin';
import SharedCalendarModal from 'components/Settings/Integrations/SharedCalendarModal';
import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const OutlookSharedCalendar = ({ startEditing }) => {
  const {
    userProvider: { setUser, user },
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [org, setOrg] = useContext(OrganizationContext);
  const { enqueueSnackbar } = useSnackbar();
  const [showCalModal, setShowCalModal] = useState(false);
  const [calendars, setCalendars] = useState([]);

  const handleMicrosoft = (code, doCleanup) => {
    // Refresh auth details
    setIsLoading(true);
    axios(
      {
        data: { code, redirect_uri: window.location.origin + '/auth/outlook' },
        method: 'POST',
        mode: 'CORS',
        url: '/api/auth_details/outlook',
      },
      (res) => {
        setUser(res.data);
        getCalendars();
        doCleanup();
      },
      () => {
        setIsLoading(false);
        enqueueSnackbar('Something went wrong! Please try again', {
          variant: 'error',
        });
        doCleanup();
      }
    );
  };

  const getCalendars = () => {
    setIsLoading(true);
    axios(
      {
        method: 'get',
        url: '/api/shared_calendars',
      },
      (res) => {
        setCalendars(res.data);
        setShowCalModal(true);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        enqueueSnackbar('Something went wrong! Please try again', {
          variant: 'error',
        });
      }
    );
    // finally:
    setIsLoading(false);
  };

  return (
    <>
      <div className='title-wrapper'>
        <img alt='Outlook Logo' src='/images/outlook-cal.svg' />
        <h3>Outlook Calendar</h3>
        {org?.calendars?.length > 0 && (
          <IconButton
            color='primary'
            data-testid='TODO:DATA-ICONBUTTON-88874'
            onClick={startEditing}
          >
            <PencilSimple />
          </IconButton>
        )}
      </div>
      <ConditionalLoader conditions={[isLoading]}>
        <p>
          Sign into Five to Nine via Outlook SSO to enable exporting events to
          your organization&apos;s Shared Calendars.
        </p>

        {user.has_outlook_auth ? (
          <Button
            color='primary'
            data-testid='set-up-outlook-calendar'
            fullWidth
            onClick={getCalendars}
            variant='contained'
          >
            Set up Outlook Calendar
          </Button>
        ) : (
          <MicrosoftLogin
            // Need to fix styling still
            label='Set up Outlook Calendar'
            onCodeReceived={handleMicrosoft}
            org={org}
            settingsStyling
          />
        )}
      </ConditionalLoader>
      <SharedCalendarModal
        axios={axios}
        calendars={calendars}
        handleClose={() => setShowCalModal(false)}
        nameKey='name'
        org={org}
        providerName='Outlook'
        setOrg={setOrg}
        show={showCalModal}
      />
    </>
  );
};

OutlookSharedCalendar.propTypes = {
  startEditing: PropTypes.any,
};
export default OutlookSharedCalendar;
