import React, { useContext, useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { MinusCircle, PencilSimple } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import ContactListFormDialog from 'components/Contacts/ContactListFormDialog';
import ContactListMembersDialog from 'components/Contacts/ContactListMembersDialog';
import DeleteModal from 'components/Core/DeleteModal';
import { ConditionalLoader } from 'components/Core/Loader';
import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import { ListsContext } from 'contexts/ListsContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const ContactListsTable = ({
  addList,
  doneAdding,
  lists,
  officesById,
  organization,
}) => {
  const [, setLists, isListLoading] = useContext(ListsContext);

  const [editingList, setEditingList] = useState(null);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [selectedList, setSelectedList] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const handleRemove = (list, onDone) => {
    axios(
      { method: 'delete', url: `/api/lists/${list?.id}` },
      (res) => {
        setLists(res.data);
        setIsConfirmingDelete(false);
        enqueueSnackbar(`"${list?.name}" list deleted!`, {
          variant: 'success',
        });
        setSelectedList(null);
        onDone();
      },
      () => {
        enqueueSnackbar('We were unable to delete this list', {
          variant: 'error',
        });
        setSelectedList(null);
      }
    );
  };

  const handleCloseEditModal = () => {
    if (editingList) {
      setEditingList(null);
    } else {
      doneAdding();
    }
  };

  const columns = [
    {
      field: 'name',
      flex: 3,
      headerName: 'List name',
      minWidth: 150,
    },
    {
      field: 'owner',
      flex: 3,
      minWidth: 150,
    },
    {
      field: 'contacts_count',
      flex: 2,
      headerName: 'Contact count',
      minWidth: 75,
      type: 'number',
    },
    {
      align: 'right',
      field: 'created_at',
      flex: 2,
      headerAlign: 'right',
      headerName: 'Created',
      minWidth: 120,
      type: 'date',
      valueFormatter: ({ value }) => value && dayjs(value).format('L'),
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'actions',
      flex: 1.5,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<PencilSimple size={24} />}
          key={`${params.id}-edit-button`}
          label='Edit'
          onClick={() => {
            setEditingList(lists?.find((list) => list.id === params.id));
          }}
        />,
        <GridActionsCellItem
          icon={<MinusCircle size={24} />}
          key={`${params.id}-delete-button`}
          label='Delete'
          onClick={() => {
            setSelectedList(lists?.find((list) => list.id === params.id));
            setIsConfirmingDelete(true);
          }}
        />,
      ],
      headerName: 'Edit | Remove',
      minWidth: 125,
      type: 'actions',
    },
  ];

  return (
    <ConditionalLoader conditions={[isListLoading]}>
      <FtnDataGrid
        columns={columns}
        data-testid='contact-lists-grid'
        noun='contact list'
        onRowClick={(params) => {
          setSelectedList(lists?.find((list) => list.id === params.id));
          setIsViewing(true);
        }}
        rows={lists || []}
        rowSelection={false}
      />
      <ContactListMembersDialog
        handleClose={() => {
          setIsViewing(false);
          setSelectedList(null);
        }}
        list={selectedList}
        officesById={officesById}
        show={isViewing}
      />
      <ContactListFormDialog
        handleClose={handleCloseEditModal}
        list={editingList}
        lists={lists}
        org={organization}
        setLists={setLists}
        show={editingList || addList}
      />
      <DeleteModal
        noun='Contact list'
        onCancel={() => {
          setIsConfirmingDelete(false);
          setSelectedList(null);
        }}
        onDelete={(onDone) => {
          handleRemove(selectedList, onDone);
        }}
        owner={organization && organization.display_name}
        show={isConfirmingDelete}
        subject={`"${selectedList?.name}"`}
      />
    </ConditionalLoader>
  );
};

ContactListsTable.propTypes = {
  addList: PropTypes.any,
  doneAdding: PropTypes.func,
  handleEditList: PropTypes.func,
  isListLoading: PropTypes.bool,
  lists: PropTypes.array,
  officesById: PropTypes.any,
  organization: PropTypes.shape({
    display_name: PropTypes.any,
  }),
  setLists: PropTypes.func,
};

export default ContactListsTable;
