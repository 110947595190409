import React, { forwardRef, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
} from '@mui/material';
import { trimEnd } from 'lodash';
import { PropTypes } from 'prop-types';

import {
  sidenavIconSize,
  sidenavWidthCollapsed,
} from 'components/shared/AppSidenav';

const NavListItem = styled(ListItem)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.grey[900],
  },
  '&.active': {
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
      fontWeight: 700,
    },
    '& svg': {
      color: theme.palette.common.white,
      fill: theme.palette.common.white,
    },
    backgroundColor: theme.palette.primary.main,
  },
  '&:hover:not(.active)': {
    '& svg': {
      fill: theme.palette.common.black,
    },
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
  },
  color: theme.palette.grey[900],
  transition: 'all 100ms ease-in-out',
}));

const ListItemLink = ({ avatar, Icon, primary, to }) => {
  const detailRoute = trimEnd(to, 's');

  const renderLink = useMemo(
    () =>
      // eslint-disable-next-line react/no-multi-comp
      forwardRef(function Link(itemProps, ref) {
        const isExternal = to.indexOf('http') > -1;
        return (
          <NavLink
            ref={ref}
            relative={!isExternal}
            reloadDocument={isExternal}
            role={undefined}
            target={isExternal ? '_blank' : null}
            to={to}
            {...itemProps}
          />
        );
      }),
    [to]
  );

  return (
    <Tooltip data-testid='nav-item-tooltip' placement='right' title={primary}>
      <li>
        <NavListItem
          className={
            window.location.pathname.indexOf(detailRoute) === 0 ? 'active' : ''
          }
          component={renderLink}
          disableGutters
          sx={{ px: 1.5 }}
        >
          {Icon || avatar ? (
            <ListItemIcon
              sx={{ minWidth: sidenavWidthCollapsed, width: sidenavIconSize }}
            >
              {Icon ? <Icon size={24} /> : <>{avatar}</>}
            </ListItemIcon>
          ) : null}
          <ListItemText color='text.primary' primary={primary} />
        </NavListItem>
      </li>
    </Tooltip>
  );
};

ListItemLink.propTypes = {
  avatar: PropTypes.element,
  Icon: PropTypes.any,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default ListItemLink;
