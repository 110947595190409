import React from 'react';
import { File, SlackLogo } from '@phosphor-icons/react';

import RadioBoxes from 'components/shared/RadioBoxes';

interface Props {
  setType: (type: 'manual' | 'slack') => void;
  type: string;
}

const GroupCreateType: React.FC<Props> = ({ setType, type }) => (
  <RadioBoxes
    onChange={(t) => {
      if (t === 'slack' || t === 'manual') {
        setType(t);
      }
    }}
    options={[
      {
        Icon: File,
        id: 'manual',
        primary: 'Create from scratch',
        secondary:
          'Manually create a group and add members from your employee directory.',
      },
      {
        Icon: SlackLogo,
        id: 'slack',
        primary: 'Sync from a Slack channel',
        secondary:
          'Synchronize employee groups from Slack to keep members up to date at all times.',
      },
    ]}
    value={type}
  />
);

export default GroupCreateType;
