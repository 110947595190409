import React, { useContext, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import DeleteModal from 'components/Core/DeleteModal';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { EventDetailsFormHandleSubmit } from 'types/EventDetailsForm';
import FtnEvent from 'types/FtnEvent';
import { reactQueryAxios } from 'utils/axios';

const deleteEvent = async ({
  apiClient,
  eventId,
}: {
  apiClient: any;
  eventId: number;
}) =>
  apiClient({
    method: 'delete',
    url: `/api/events/${eventId}`,
  });

export const useDeleteEvent = ({
  dataHandler = () => undefined,
  eventId,
  handleSubmit,
  name,
}: {
  dataHandler?: (d: FtnEvent) => unknown;
  eventId: number;
  handleSubmit: EventDetailsFormHandleSubmit;
  name: string;
}) => {
  const [org] = useContext(OrganizationContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const { isPending, mutate: deleteMutation } = useMutation({
    mutationFn: (eventId: number) =>
      deleteEvent({ apiClient: reactQueryAxios, eventId }),

    onError: (error: Error) => {
      enqueueSnackbar(
        'An error occured while deleting your event, if it remains please try deleting again',
        { variant: 'error' }
      );
      Sentry.captureException(error);
    },

    onSuccess: (response: any) => {
      const ftnEvent = response.data;
      queryClient.setQueryData(['event', ftnEvent?.id], ftnEvent);
      dataHandler(ftnEvent as FtnEvent);
    },
  });

  const deleteModal = useMemo(
    () => (
      <DeleteModal
        noun='Event'
        onCancel={() => setIsConfirmingDelete(false)}
        onDelete={() => {
          handleSubmit(() => deleteMutation(eventId))();
          setIsConfirmingDelete(false);
        }}
        owner={org?.display_name}
        show={isConfirmingDelete}
        subject={name}
      />
    ),
    [
      deleteMutation,
      eventId,
      handleSubmit,
      isConfirmingDelete,
      name,
      org?.display_name,
    ]
  );

  const showDeleteModal = () => setIsConfirmingDelete(true);

  return { deleteModal, isPending, showDeleteModal };
};
