import React, { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CheckCircle,
  MinusCircle,
  PlusCircle,
  Prohibit,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import * as geopattern from 'geopattern';

import { Event } from 'types/CollectionForm';

interface CollectionEventsListItemProps {
  event: Event;
  isSelected: boolean;
  onToggleSelect: (eventId: number) => void;
  removable?: boolean;
  selectable?: boolean;
}

const CollectionEventsListItem = ({
  event,
  isSelected,
  onToggleSelect,
  removable,
  selectable,
}: CollectionEventsListItemProps) => {
  const theme = useTheme();

  const formattedDate = dayjs(event.start).format('dddd, MMMM D, YYYY');
  const formattedTime = dayjs(event.start).format('h:mm A');
  const timeZoneAbbr = event.timezone?.split('/')[1];

  const placeholderImageUrl = useMemo(
    () =>
      geopattern
        .generate(event.name, {
          baseColor: theme.palette.primary.main,
          color: theme.palette.grey[300],
        })
        .toDataUrl(),
    [event.name, theme.palette.grey, theme.palette.primary.main]
  );

  return (
    <Card sx={{ display: 'flex', maxHeight: 90, width: '100%' }}>
      <CardMedia
        alt={event.name}
        component='img'
        image={
          event.coverImageUrl ||
          placeholderImageUrl.replace(/^url\("/g, '').replace(/"\)$/g, '')
        }
        sx={{ width: 100 }}
      />
      <CardContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          paddingTop: '25px',
        }}
      >
        <Box>
          <Tooltip title={event.name}>
            <Typography
              component='div'
              sx={{
                display: '-webkit-box',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
              variant='body2'
            >
              {event.name}
            </Typography>
          </Tooltip>
          <Typography color='text.secondary' variant='overline'>
            {formattedDate} • {formattedTime} {timeZoneAbbr}
          </Typography>
        </Box>
        {removable ? (
          <IconButton onClick={() => onToggleSelect(event.id)}>
            <MinusCircle size={24} />
          </IconButton>
        ) : selectable ? (
          <IconButton onClick={() => onToggleSelect(event.id)}>
            {isSelected ? (
              <CheckCircle
                fill={theme.palette.success.main}
                size={24}
                weight='fill'
              />
            ) : (
              <PlusCircle size={24} />
            )}
          </IconButton>
        ) : (
          <Tooltip title='This event already belongs to another collection'>
            <IconButton>
              <Prohibit />
            </IconButton>
          </Tooltip>
        )}
      </CardContent>
    </Card>
  );
};

export default CollectionEventsListItem;
