import React from 'react';
import {
  Badge,
  BadgeOwnProps,
  BadgeProps,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';

import { inlineBadgeProps } from 'utils/styles';

interface Props extends BadgeProps {
  count: number;
  label: string;
  labelProps?: TypographyProps;
}

const TabLabelWithCount = ({
  color = 'primary',
  count,
  label,
  labelProps = { variant: 'inherit' },
  variant = 'standard',
}: Props) => (
  <Stack alignItems='center' direction='row' gap={2}>
    <Stack alignItems='center' direction='row' gap={0.75}>
      <Typography {...labelProps}>{label}</Typography>
      <Badge
        badgeContent={
          count && typeof count === 'number'
            ? new Intl.NumberFormat().format(count)
            : null
        }
        color={color}
        component='div'
        data-testid={`${label}-count-badge`}
        max={9999}
        variant={variant}
        {...(inlineBadgeProps as BadgeOwnProps)}
      />
    </Stack>{' '}
  </Stack>
);

export default TabLabelWithCount;
