import React from 'react';
import { Controller } from 'react-hook-form';
import { Star } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import { ConditionalLoader } from 'components/Core/Loader';
import AutocompleteWithAvatars from 'components/shared/AutocompleteWithAvatars';
import SummaryField from 'components/shared/SummaryField';
import useDisplayedHostSelections from 'hooks/useDisplayedHostSelections';
import {
  EventDetailsFormControl,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';

interface EventOrganizerFieldProps {
  control: EventDetailsFormControl;
  disableFields: boolean;
  isLoading?: boolean;
  watch: EventDetailsFormWatch;
}

const EventOrganizerField = ({
  control,
  disableFields,
  isLoading = false,
  watch,
}: EventOrganizerFieldProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const hosts = watch('hosts.hosts');
  const { employeeOptions } = useDisplayedHostSelections(hosts);

  const onChange = (
    field: any,
    newValue: { id?: number; value?: number }[]
  ) => {
    const hasNoSelections = newValue?.length === 0;
    if (hasNoSelections) {
      enqueueSnackbar('Unable to remove. You need at least 1 organizer!', {
        variant: 'error',
      });
    } else {
      const selectedIds = newValue?.map(
        (s: { id?: number; value?: number }) => s?.value || s?.id
      );
      field.onChange(selectedIds);
    }
  };

  return (
    <Controller
      control={control}
      name='hostIds'
      render={({ field }) => {
        const value =
          (employeeOptions || []).length > 0 && (field?.value || []).length > 0
            ? field?.value?.map((hostId: number) =>
                employeeOptions?.find(
                  (opt: { id?: number }) => opt?.id === hostId
                )
              )
            : [];
        return (
          <SummaryField
            component={
              <ConditionalLoader conditions={[!employeeOptions]}>
                <AutocompleteWithAvatars
                  disabled={disableFields}
                  onChange={(_, value) => onChange(field, value)}
                  options={employeeOptions || []}
                  value={value}
                />
              </ConditionalLoader>
            }
            Icon={Star}
            isEditable={!disableFields}
            isLoading={isLoading}
            primary='Event organizers'
            secondary='Select the organizers that will be able to manage this event.'
            value={value}
            valueType='avatars'
          />
        );
      }}
    />
  );
};

export default EventOrganizerField;
