import { useCallback, useMemo, useState } from 'react';

interface Props {
  pendingEvent: any;
  savedEvent?: any;
}

export const useEventLocationType = ({ pendingEvent, savedEvent }: Props) => {
  const [manuallySelected, setManuallySelected] = useState(undefined);

  const getInferredType = useCallback((event: any) => {
    if (event?.meeting?.service) {
      if (event?.location?.length > 0) {
        return 'hybrid';
      } else {
        return 'virtual';
      }
    } else if (event?.location?.length > 0) {
      return 'inperson';
    } else {
      return undefined;
    }
  }, []);

  const inferredPending = useMemo(
    () => getInferredType(pendingEvent),
    [getInferredType, pendingEvent]
  );

  const inferredSaved = useMemo(
    () => getInferredType(savedEvent),
    [getInferredType, savedEvent]
  );

  const active = useMemo(
    () =>
      manuallySelected !== undefined
        ? manuallySelected
        : inferredPending || savedEvent
        ? inferredSaved
        : null,
    [inferredPending, inferredSaved, manuallySelected, savedEvent]
  );

  return { active, setManuallySelected };
};
