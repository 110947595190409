import React, { useContext, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import IntegrationCard from 'components/Settings/Integrations/IntegrationCard';
import SharedCalendarModal from 'components/Settings/Integrations/SharedCalendarModal';
import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { GoogleCalendarScopes } from 'utils/google';

const GoogleSharedCalendar = ({ startEditing }) => {
  const {
    userProvider: { setUser, user },
  } = useContext(AuthContext);
  const [org, setOrg] = useContext(OrganizationContext);

  const { enqueueSnackbar } = useSnackbar();

  const [, setIsLoading] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [showCalModal, setShowCalModal] = useState(false);

  const handleClose = () => {
    setShowCalModal(false);
  };

  const getGoogle = React.useCallback(() => {
    setIsLoading(true);
    return axios(
      {
        method: 'get',
        url: '/api/shared_calendars',
      },
      (res) => {
        setCalendars(
          res.data.map((cal) => ({
            ...cal,
            calendar_id: cal.id,
            name: cal.summary || cal.name,
          }))
        );
        setShowCalModal(true);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        enqueueSnackbar('Something went wrong! Please try again', {
          variant: 'error',
        });
      }
    );
  }, [enqueueSnackbar]);

  const setupGoogleCalendar = useGoogleLogin({
    flow: 'auth-code',
    onFailure: (response) => {
      enqueueSnackbar('Something went wrong! Please try again', {
        variant: 'error',
      });
    },
    onSuccess: (response) => {
      setIsLoading(true);
      axios(
        {
          data: { code: response.code, redirect_uri: window.location.origin },
          method: 'POST',
          mode: 'CORS',
          url: '/api/auth_details/gcal',
        },
        (res) => {
          setUser(res.data);
          getGoogle();
        },
        (err) => {
          setIsLoading(false);
          if (err?.response?.data?.errors === 'missing scopes') {
            enqueueSnackbar(
              'We were unable to connect to your calendar. Please try again and ensure calendar permissions are selected.',
              {
                variant: 'error',
              }
            );
          } else {
            enqueueSnackbar('Something went wrong! Please try again', {
              variant: 'error',
            });
          }
        }
      );
    },
    scope: GoogleCalendarScopes,
    select_account: true,
  });

  return (
    <>
      <IntegrationCard
        description="Sign into Five to Nine via Google SSO to enable exporting events to your organization's Shared Calendars."
        isActive={org.calendars?.length > 0}
        name='Google Calendar'
        onEdit={() => getGoogle()}
        onSetUp={() => setupGoogleCalendar()}
      />
      <SharedCalendarModal
        axios={axios}
        calendars={calendars}
        handleClose={handleClose}
        org={org}
        providerName='Google'
        setOrg={setOrg}
        show={showCalModal}
      />
    </>
  );
};

GoogleSharedCalendar.propTypes = {
  org: PropTypes.shape({
    calendars: PropTypes.array,
  }),
  setOrg: PropTypes.func,
  startEditing: PropTypes.any,
};
export default GoogleSharedCalendar;
