import React from 'react';
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
  component?: string;
  props?: any;
}

export const SurveyQuestionResponseWrapper = ({ children, props }: Props) => (
  <Box
    {...props}
    backgroundColor='grey.50'
    borderColor='grey.100'
    borderRadius={0.75}
    borderStyle='solid'
    borderWidth={1}
    component='li'
    display='block'
    listStyleType='none'
    padding={1}
  >
    {children}
  </Box>
);
