import React, { useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { MRT_RowData, MRT_TableInstance } from 'material-react-table';

const MRTToolbarAlertBannerContent = <TData extends MRT_RowData>({
  label = 'selected',
  onClear,
  p = 1,
  table,
}: {
  label?: string;
  onClear?: () => void;
  p?: number;
  table: MRT_TableInstance<TData>;
}) => {
  const selectedRowCount = useCallback(
    (table: MRT_TableInstance<any>) =>
      table.getState().rowSelection
        ? Object.keys(table.getState().rowSelection).length
        : 0,
    []
  );

  const hasSelectedRows = useCallback(
    (table: MRT_TableInstance<any>) =>
      table.getState().rowSelection &&
      Object.values(table.getState().rowSelection).length > 0,
    []
  );

  return (
    <Box alignItems='center' display='flex' flexDirection='row' gap={1} p={p}>
      {hasSelectedRows(table) ? (
        <>
          <Typography>{[selectedRowCount(table), label].join(' ')}</Typography>
          <Button
            onClick={() => {
              table.resetRowSelection();
              onClear && onClear();
            }}
            size='small'
          >
            Clear
          </Button>
        </>
      ) : null}
    </Box>
  );
};

export default MRTToolbarAlertBannerContent;
