import React from 'react';

import Loader from 'components/Core/Loader/Loader';

interface InlineLoaderProps {
  text?: string;
}

const InlineLoader = ({ text }: InlineLoaderProps) => (
  <Loader inline text={text} />
);

export default InlineLoader;
