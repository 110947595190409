export const SET_FIELD = 'SET_FIELD';
export const NEW_LIST_ITEM = 'NEW_LIST_ITEM';

export const setField = (field, value, skip = false) => ({
  field,
  skip,
  type: SET_FIELD,
  value,
});

export const newListItem = (data, selectionField, optionsField) => ({
  data,
  optionsField,
  selectionField,
  type: NEW_LIST_ITEM,
});
