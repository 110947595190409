import React, { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Plus } from '@phosphor-icons/react';
import * as _ from 'lodash';

import EventCards from 'components/Events/EventCards';
import EventCalendar from 'components/Home/EventCalendar';
import EventFilters from 'components/Home/EventFilters';
import TabsLayout from 'components/shared/layout/TabsLayout';
import { AuthContext } from 'contexts/AuthContext';
import { HomeContext } from 'contexts/HomeContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useRedirectToItem from 'hooks/useRedirectToItem';
import { EventGray } from 'utils/colors';
import {
  GroupFilter,
  HostFilter,
  OfficeFilter,
  PrivacyFilter,
  SearchFilter,
  TypeFilter,
} from 'utils/event';
import { ApplyFilterToChunk } from 'utils/utils';

const Home = () => {
  const [, , , , , eventTypeColors] = useContext(OrganizationContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const {
    calendar: { events },
    chunks: [upcoming, mine, past, draft],
  } = useContext(HomeContext);
  const { setSidenavIsHidden } = useContext(LayoutContext);

  const navigate = useNavigate();
  const { tab } = useParams();

  const [filterGroups, setFilterGroups] = useState([]);
  const [filterOffices, setFilterOffices] = useState([]);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterHosts, setFilterHosts] = useState([]);

  const isCreator = user?.is_admin || user?.is_organizer;

  useRedirectToItem('event');

  const filters = useMemo(
    () => ({
      groups: filterGroups,
      offices: filterOffices,
      search: filterSearch,
      types: filterTypes,
    }),
    [filterOffices, filterTypes, filterGroups, filterSearch]
  );

  const filteredChunk = useMemo(() => {
    if (tab !== 'calendar') {
      const chunkMap = { draft, mine, past, upcoming };
      return ApplyFilterToChunk((ev) => {
        const passesOfficeGroupAndType =
          OfficeFilter(ev, filterOffices) &&
          TypeFilter(ev, filterTypes) &&
          GroupFilter(ev, filterGroups) &&
          SearchFilter(ev, filterSearch) &&
          HostFilter(ev, filterHosts);
        if (tab === 'upcoming' || tab === 'past') {
          return passesOfficeGroupAndType && PrivacyFilter(ev, user);
        }
        return passesOfficeGroupAndType;
      }, chunkMap[tab]);
    }
  }, [
    tab,
    upcoming,
    mine,
    past,
    draft,
    filterOffices,
    filterTypes,
    filterGroups,
    filterSearch,
    filterHosts,
    user,
  ]);

  const calEvents = useMemo(
    () =>
      _.chain(events)
        .filter(
          (evt) =>
            SearchFilter(evt, filters?.search) &&
            OfficeFilter(evt, filters?.offices) &&
            TypeFilter(evt, filters?.types) &&
            GroupFilter(evt, filters?.groups) &&
            PrivacyFilter(evt, user)
        )
        .map((evt) => {
          const { description, ends, event_type_id, id, name, start } = evt;
          const color =
            event_type_id && eventTypeColors !== null
              ? eventTypeColors[event_type_id]
              : EventGray;
          return {
            allDay: false,
            color,
            description,
            end: new Date(ends),
            eventId: id,
            start: new Date(start),
            title: name,
          };
        })
        .value(),
    [
      events,
      filters?.search,
      filters?.offices,
      filters?.types,
      filters?.groups,
      user,
      eventTypeColors,
    ]
  );

  const removeFromAllChunks = (id) => {
    const chunkMap = { draft, mine, past, upcoming };

    _.forEach(chunkMap, (chunk) => {
      chunk.removeItemFromChunk(id);
    });
  };

  const createNewEvent = () => {
    setSidenavIsHidden(false);
    navigate('/event/new/create');
  };

  return (
    <TabsLayout
      button={
        isCreator ? (
          <Button
            color='primary'
            data-testid='new-event-button'
            onClick={createNewEvent}
            startIcon={<Plus />}
            variant='contained'
          >
            New event
          </Button>
        ) : null
      }
      fixedContent={
        <EventFilters
          onGroupChange={setFilterGroups}
          onHostChange={setFilterHosts}
          onOfficeChange={setFilterOffices}
          onSearchChange={setFilterSearch}
          onTypeChange={setFilterTypes}
          searchValue={filterSearch}
          selectedGroups={filterGroups}
          selectedHosts={filterHosts}
          selectedOffices={filterOffices}
          selectedTypes={filterTypes}
        />
      }
      tabs={[
        {
          label: 'Upcoming',
          value: 'upcoming',
        },
        {
          label: 'My events',
          value: 'mine',
        },
        {
          label: 'Past events',
          value: 'past',
        },
        ...(user.is_admin || user.is_organizer
          ? [
              {
                label: 'Drafts',
                value: 'draft',
              },
            ]
          : []),
        {
          label: 'Calendar',
          value: 'calendar',
        },
      ]}
      title='Events'
    >
      {tab === 'calendar' ? (
        <EventCalendar calEvents={calEvents} />
      ) : (
        <EventCards
          chunk={filteredChunk}
          removeFromAllChunks={removeFromAllChunks}
          tab={tab}
        />
      )}
    </TabsLayout>
  );
};
export default Home;
