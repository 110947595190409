import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { PublicOrgContext } from 'contexts/PublicOrgContext';
import { HotJarIds } from 'utils/hotjar_ids';

const HotJar = () => {
  const [org] = useContext(PublicOrgContext);
  const [currentHotJarId, setHotJarId] = useState('');

  useEffect(() => {
    if (!currentHotJarId) {
      const currentOption = HotJarIds[org?.subdomain];
      setHotJarId(currentOption);
    }
  }, [org, currentHotJarId]);

  return (
    <>
      {currentHotJarId ? (
        <Helmet>
          <script>
            {(function (h, o, t, j, a, r) {
              h.hj =
                h.hj ||
                function () {
                  (h.hj.q = h.hj.q || []).push(arguments);
                };
              h._hjSettings = { hjid: currentHotJarId, hjsv: 6 };
              a = o.getElementsByTagName('head')[0];
              r = o.createElement('script');
              r.async = 1;
              r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
              a.appendChild(r);
            })(
              window,
              document,
              'https://static.hotjar.com/c/hotjar-',
              '.js?sv='
            )}
          </script>
        </Helmet>
      ) : null}
    </>
  );
};

export default HotJar;
