import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Box, Button, MenuItem, useTheme } from '@mui/material';
import {
  DownloadSimple,
  FloppyDiskBack,
  NotePencil,
  Plus,
  Trash,
} from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  MaterialReactTable,
  MRT_Cell,
  MRT_Column,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowData,
  MRT_TableInstance,
  useMaterialReactTable,
} from 'material-react-table';
import { useSnackbar } from 'notistack';

import EventExpenseDeleteDialog from 'components/Event/Budget/EventExpenseDeleteDialog';
import EventExpenseFormDialog from 'components/Event/Budget/EventExpenseFormDialog';
import EventExpenseTrackerEmptyState from 'components/Event/Budget/EventExpenseTrackerEmptyState';
import AddExpensesDialog from 'components/Events/Controls/Budget/AddExpensesDialog';
import SectionWrapper from 'components/shared/SectionWrapper';
import TableTopToolbar from 'components/shared/TableTopToolbar';
import TextWithIcon from 'components/shared/TextWithIcon';
import { defaultCurrency } from 'constants/currency.constants';
import { useBudgetTrackerExport } from 'hooks/useBudgetTrackerExport';
import useDialogState from 'hooks/useDialogState';
import { useEventBudget } from 'hooks/useEventBudget';
import { useExpenseDelete } from 'hooks/useExpenseDelete';
import { useExpenseTrackerData } from 'hooks/useExpenseTrackerData';
import { useExpenseUpdate } from 'hooks/useExpenseUpdate';
import { useOrganization } from 'hooks/useOrganization';
import {
  EventBudgetFormData,
  Expense,
  ExpenseCategory,
} from 'types/EventBudget';
import { hasNoData } from 'utils/mrtTable';
import { asCurrency } from 'utils/utils';

const EventExpenseTracker = () => {
  const eventExpenseFormDialogState = useDialogState();

  const { id } = useParams<{ id: string }>();
  const eventId = Number(id);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [isCreatingExpense, setIsCreatingExpense] = useState(false);
  const [rowPendingDeletion, setRowPendingDeletion] = useState<
    MRT_Row<Expense> | undefined
  >(undefined);

  const theme = useTheme();

  const {
    retrieve: { data, isError, isPending },
  } = useEventBudget({
    eventId,
  });

  const update = useExpenseUpdate({
    eventId,
    showToast: false,
  });

  const {
    retrieve: { data: organization },
  } = useOrganization();

  const expenseCategoriesAsSelectOptions = useMemo(
    () =>
      organization?.expense_categories?.map(
        (c: { active: boolean; id: number; name: string }) => ({
          active: c.active,
          id: c.id,
          name: c.name,
        })
      ) as ExpenseCategory[],
    [organization?.expense_categories]
  );

  const { mutateAsync: exportExpenseTracker } = useBudgetTrackerExport(
    eventId,
    data?.event_name
  );

  const { mutate: deleteExpense } = useExpenseDelete({
    dataHandler: () => {
      enqueueSnackbar('Expense deleted!', { variant: 'success' });
    },
    eventId,
  });

  const defaultValues = {
    expense_items: [],
  };

  const expenseTrackerForm = useForm<EventBudgetFormData>({
    defaultValues,
    mode: 'onBlur',
    resetOptions: { keepDirtyValues: true },
    reValidateMode: 'onBlur',
    values: data ? { expense_items: data?.expense_items } : undefined,
  });

  const { isDirty } = expenseTrackerForm.formState;

  const formData = expenseTrackerForm.watch();

  const { rowsWithIndex } = useExpenseTrackerData({
    categorySelections: [],
    expenseCategories: expenseCategoriesAsSelectOptions,
    expenseItems: formData.expense_items,
    search: '',
  });

  const activeCurrency = useMemo(
    () =>
      organization?.currencies?.find(
        (currency: { id: number }) => currency.id === data?.currency_id
      ),
    [data?.currency_id, organization?.currencies]
  );

  const categoryOptions = useMemo(
    () =>
      expenseCategoriesAsSelectOptions
        ?.filter((c) => c.active)
        ?.map((c) => ({ label: c.name, value: c.id })),
    [expenseCategoriesAsSelectOptions]
  );

  const columns: MRT_ColumnDef<Expense, any>[] = [
    {
      accessorKey: 'name',
      enableColumnFilter: false,
      enableEditing: false,
      grow: true,
      header: 'Name',
    },
    {
      accessorKey: 'expense_category_id',
      editSelectOptions: categoryOptions,
      editVariant: 'select',
      filterFn: (row, _columnIds, filterValue) =>
        filterValue && filterValue.length > 0 && Array.isArray(filterValue)
          ? filterValue.includes(row.getValue<number>('expense_category_id'))
          : true,
      filterSelectOptions: categoryOptions,
      filterVariant: 'multi-select',
      header: 'Category',
      maxSize: 200,
      minSize: 120,
      muiEditTextFieldProps: ({ row }) => ({
        className: 'category-selector',
        select: true,
        SelectProps: {
          displayEmpty: true,
          inputProps: { 'aria-label': 'Without label' },
          placeholder: 'Select category',
        },
        sx: {
          ...(row && row?.getValue('expense_category_id')
            ? {}
            : {
                '&.category-selector .MuiSelect-select::after': {
                  color: theme.palette.grey[500],
                  content: "'Select category'",
                  display: 'inline-block',
                },
              }),
        },
      }),
      size: 140,
      sortDescFirst: false,
      sortingFn: (rowA: MRT_RowData, rowB: MRT_RowData) =>
        rowA.original.expense_category_name.localeCompare(
          rowB.original.expense_category_name
        ),
    },
    {
      accessorKey: 'estimated_cost',
      enableColumnFilter: false,
      grow: false,
      header: 'Estimated spend',
      maxSize: 220,
      minSize: 100,
      size: 200,
    },
    {
      accessorKey: 'actual_cost',
      enableColumnFilter: false,
      grow: false,
      header: 'Actual spend',
      maxSize: 220,
      minSize: 100,
      size: 200,
    },
  ];

  /* Old autosaving approach leave for future reference
  
  const handleChange = useDebounceCallback(
    ({
      column,
      event,
      row,
    }: {
      column: MRT_Column<Expense>;
      event: any;
      row: MRT_Row<Expense>;
    }) => {
      const newValue = Number(event?.target?.value);
      const accessorKey = column.id;
      const prevRowAsMap = new Map(Object.entries(row.original));
      if (
        prevRowAsMap &&
        prevRowAsMap.has(accessorKey) &&
        newValue !== prevRowAsMap.get(accessorKey)
      ) {
        update.mutate({
          ...row.original,
          [accessorKey]: newValue,
        });
      }
    },
    1000
  ); */

  const handleSaveChanges = () => {
    const dirtyExpenses = expenseTrackerForm
      .getValues()
      .expense_items.filter(
        (_, index) =>
          expenseTrackerForm.getFieldState(`expense_items.${index}`).isDirty
      );

    const updatePromises = dirtyExpenses.map((updatedExpense) =>
      update.mutateAsync(updatedExpense)
    );

    Promise.all(updatePromises)
      .then(() => {
        enqueueSnackbar('Expenses updated successfully!', {
          variant: 'success',
        });
        expenseTrackerForm.reset(undefined, { keepValues: true });
        queryClient.invalidateQueries({
          queryKey: ['event', eventId, 'budget'],
        });
      })
      .catch((error) => {
        enqueueSnackbar('Error updating expenses. Please try again.', {
          variant: 'error',
        });
        console.error('Error updating expenses:', error);
      });
  };

  const tableData = useMemo(() => {
    if (Array.isArray(data?.expense_items)) {
      return data.expense_items.map((expense: Expense) => ({
        ...expense,
        expense_category_name:
          expenseCategoriesAsSelectOptions?.find(
            (c) => c.id === expense.expense_category_id
          )?.name || String(expense.expense_category_id),
      }));
    } else {
      return [];
    }
  }, [data?.expense_items, expenseCategoriesAsSelectOptions]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        grow: false,
        size: 80,
      },
    },
    editDisplayMode: 'table',
    enableColumnActions: false,
    enableEditing: true,
    enableHiding: true,
    enableRowActions: true,
    enableTableFooter: false,
    getRowId: (originalRow: Expense) => String(originalRow.id),
    initialState: {
      showGlobalFilter: true,
      sorting: [
        {
          desc: false,
          id: 'name',
        },
      ],
    },
    layoutMode: 'grid',
    muiBottomToolbarProps: ({ table }) => ({
      sx: hasNoData(table as MRT_TableInstance<any>)
        ? { display: 'none' }
        : {
            '> .MuiBox-root': {
              '& .MuiTablePagination-root': {
                '& .MuiInputLabel-root': {
                  pb: 0.5,
                },
                py: 1,
              },
              p: 0,
            },
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderColor: 'grey.300',
            borderStyle: 'solid',
            borderTopWidth: '0 !important',
            borderWidth: 1,
          },
    }),
    muiEditTextFieldProps: ({
      cell,
      column,
      row,
      table,
    }: {
      cell: MRT_Cell<Expense, unknown>;
      column: MRT_Column<Expense, unknown>;
      row: MRT_Row<Expense>;
      table: MRT_TableInstance<Expense>;
    }) => ({
      ...(column.id.indexOf('cost') > -1
        ? {
            inputProps: {
              min: 0,
              style: {
                textAlign: 'right',
              },
            },
            InputProps: {
              startAdornment: (activeCurrency || defaultCurrency).symbol,
            },
            type:
              table.getState().editingCell?.id === cell.id ? 'number' : 'text',
          }
        : {}),
      onBlur: () => table.setEditingCell(null),
      onChange: (event: any) => {
        const newValue = column.id.includes('cost')
          ? Number(event.target.value)
          : event.target.value;

        expenseTrackerForm.setValue(
          `expense_items.${row.index}` as const,
          {
            ...expenseTrackerForm.getValues().expense_items[row.index],
            [column.id]: newValue,
          },
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      },
      onFocus: () => {
        table.setEditingCell(cell);
      },
      size: 'small',
      value: column.id.includes('cost')
        ? table.getState().editingCell?.id === cell.id
          ? undefined
          : asCurrency(
              cell.getValue(),
              undefined,
              String(cell.getValue()).indexOf('.') > -1 ? 2 : 0
            )
        : cell.getValue(),
      variant: 'outlined',
    }),
    muiFilterTextFieldProps: { size: 'medium', variant: 'outlined' },
    muiPaginationProps: {
      SelectProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:hover': {
          border: 'none',
        },
        border: 'none',
      },
    },
    muiTableBodyRowProps: {
      sx: {
        '&:hover > td::after': { bgcolor: 'grey.50' },
        bgcolor: 'white',
      },
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        borderBottomWidth: hasNoData(table as MRT_TableInstance<any>)
          ? 1
          : '0 !important',
        borderColor: 'grey.300',
        borderStyle: 'solid',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderWidth: 1,
        ...(hasNoData(table as MRT_TableInstance<any>)
          ? { borderRadius: 0.75, overflow: 'hidden' }
          : {}),
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        '& [aria-label^="Filtering by"]:active': {
          pointerEvents: 'none',
          // INFO: Prevent click action on filter icon since it expands the header
        },
        fontSize: theme.typography.overline.fontSize,
        fontWeight: 500,
      },
    },
    muiTableHeadRowProps: ({ table }) =>
      hasNoData(table as MRT_TableInstance<any>)
        ? { sx: { display: 'none' } }
        : {},
    muiTablePaperProps: { elevation: 0 },
    positionActionsColumn: 'last',
    renderEmptyRowsFallback: () => (
      <EventExpenseTrackerEmptyState
        handleClick={() => setIsCreatingExpense(true)}
      />
    ),
    renderRowActionMenuItems: ({
      closeMenu,
      row,
    }: {
      closeMenu: () => void;
      row: MRT_Row<Expense>;
      staticRowIndex?: number;
      table: MRT_TableInstance<Expense>;
    }) => [
      <MenuItem
        key='edit'
        onClick={() => {
          closeMenu();
          table.setEditingRow(row);
          eventExpenseFormDialogState.open(row.original);
        }}
      >
        <TextWithIcon
          Icon={NotePencil}
          iconProps={{ color: theme.palette.grey[900], size: 24 }}
          primary='Edit'
          primaryTypographyProps={{ typography: 'body1' }}
        />
      </MenuItem>,
      <MenuItem
        key='delete'
        onClick={() => {
          closeMenu();
          setRowPendingDeletion(row);
        }}
      >
        <TextWithIcon
          Icon={Trash}
          iconProps={{ color: theme.palette.error.main, size: 24 }}
          primary='Delete'
          primaryTypographyProps={{
            color: 'error',
            typography: 'body1',
          }}
        />
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => (
      <TableTopToolbar
        filterColumnKeys={['expense_category_id']}
        table={table as MRT_TableInstance<any>}
      >
        <Box sx={{ display: 'flex', gap: 1, ml: 'auto' }}>
          <Button
            data-testid='save-changes'
            disabled={!isDirty}
            onClick={handleSaveChanges}
            startIcon={<FloppyDiskBack />}
            variant='contained'
          >
            Save changes
          </Button>
          <Button
            data-testid='add-expenses'
            onClick={() => setIsCreatingExpense(true)}
            startIcon={<Plus />}
            variant='contained'
          >
            Add expenses
          </Button>
        </Box>
      </TableTopToolbar>
    ),
  });

  const handleDialogClose = () => {
    eventExpenseFormDialogState.close();
    const editingRow = table?.getState().editingRow;
    if (table && editingRow) {
      queryClient.invalidateQueries({ queryKey: ['event', eventId, 'budget'] });
      table.setEditingRow(null);
    }
  };

  const handleDeleteExpense = () => {
    rowPendingDeletion && deleteExpense(Number(rowPendingDeletion?.id));
    setRowPendingDeletion(undefined);
    eventExpenseFormDialogState.close();
  };

  if (isPending) return <div>Loading...</div>;
  if (isError) return <div>Error</div>;

  return (
    <SectionWrapper
      action={
        rowsWithIndex?.length > 0 ? (
          <Button
            data-testid='export-expenses'
            onClick={() => exportExpenseTracker()}
            startIcon={<DownloadSimple />}
          >
            Download
          </Button>
        ) : undefined
      }
      title='Expense tracker'
    >
      <MaterialReactTable table={table} />
      <AddExpensesDialog
        closeDialog={() => setIsCreatingExpense(false)}
        currency={activeCurrency || defaultCurrency}
        isDialogOpen={isCreatingExpense}
        options={expenseCategoriesAsSelectOptions}
      />
      <EventExpenseFormDialog
        eventId={eventId}
        expenseCategories={expenseCategoriesAsSelectOptions}
        isOpen={eventExpenseFormDialogState.isOpen}
        onClose={handleDialogClose}
        onDelete={() =>
          setRowPendingDeletion(eventExpenseFormDialogState.currentItem)
        }
        selectedExpense={eventExpenseFormDialogState.currentItem as Expense}
      />
      <EventExpenseDeleteDialog
        isOpen={rowPendingDeletion !== undefined}
        onCancel={() => setRowPendingDeletion(undefined)}
        onDelete={handleDeleteExpense}
      />
    </SectionWrapper>
  );
};

export default EventExpenseTracker;
