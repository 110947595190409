import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  axiosAuthenticated as axios,
  CustomAxiosRequestConfig,
} from 'utils/axios';

const ZoomRedirect = () => {
  const {
    userProvider: { setUser, user },
  } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [prevLocation, setPrevLocation] = useLocalStorage('zoomSetUp');
  const orgAllowsZoomWebinar = useFlag('zoom-webinar');

  const url = import.meta.env.VITE_ZOOM_URL;

  const isLocalEnv = window.location.toString().includes('localhost');

  const subdomain = isLocalEnv ? null : location.host?.split('.')[0];
  const callback = isLocalEnv ? null : url?.split('.').slice(-2).join('.');
  const zoomUrl = isLocalEnv ? url : `https://${subdomain}.${callback}`;

  const redirect = useCallback(() => {
    if (prevLocation) {
      const formattedUrl = prevLocation.split(
        window.location.protocol + '//' + window.location.host
      )[1];
      navigate(formattedUrl);
      setPrevLocation(null);
    } else {
      navigate('/profile', { replace: true });
    }
  }, [navigate, prevLocation, setPrevLocation]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    const canConnectToWebinar =
      orgAllowsZoomWebinar &&
      (user?.has_zoom_webinar_license === null ||
        user?.has_zoom_webinar_license);
    const notConnectedToMeeting = !user?.has_zoom_meeting_auth;
    // INFO: show error toast about webinar license if user is trying to connect from event editor
    // or if user is connecting from profile page with enable zoom webinar button
    const shouldShowErrorToast =
      user?.has_zoom_meeting_auth ||
      (prevLocation && !user?.has_zoom_meeting_auth);

    if (code) {
      axios(
        {
          data: {
            code,
            redirect_uri: zoomUrl,
          },
          method: 'post',
          mode: 'CORS',
          url: '/api/zoom/install',
        } as CustomAxiosRequestConfig,
        (res: AxiosResponse) => {
          setUser(res.data.user);
          if (
            canConnectToWebinar &&
            shouldShowErrorToast &&
            !res.data.user.has_zoom_webinar_license
          ) {
            enqueueSnackbar(
              'Zoom Webinar license is unavailable. Please contact your Zoom admin for access.',
              {
                variant: 'error',
              }
            );
            if (notConnectedToMeeting) {
              enqueueSnackbar('Zoom account connected!', {
                variant: 'success',
              });
            }
          } else {
            enqueueSnackbar('Zoom account connected!', {
              variant: 'success',
            });
          }
          redirect();
        },
        () => redirect()
      );
    }
  }, [
    enqueueSnackbar,
    orgAllowsZoomWebinar,
    prevLocation,
    redirect,
    setUser,
    user?.has_zoom_meeting_auth,
    user?.has_zoom_webinar_auth,
    user?.has_zoom_webinar_license,
    zoomUrl,
  ]);

  return (
    <Stack
      alignItems='center'
      direction='column'
      gap={3}
      height='80vh'
      justifyContent='center'
      width='100%'
    >
      <CircularProgress />
      <Stack alignItems='center' direction='row'>
        <Typography variant='subtitle1'>
          Let&rsquo;s get you back to where you started...
        </Typography>
      </Stack>
      <img alt='redirect' height={200} src='/images/redirect.svg' />
    </Stack>
  );
};
export default ZoomRedirect;
