import * as React from 'react';
import { Chip } from '@mui/material';
import { PencilSimple } from '@phosphor-icons/react';

import ExpenseCategoryForm from 'components/Settings/ExpenseCategoryForm';
import useDialogState from 'hooks/useDialogState';
import { ExpenseCategory } from 'types/EventBudget';

const ExpenseCategoryChip = ({ category }: { category: ExpenseCategory }) => {
  const dialogState = useDialogState();

  return (
    <form>
      <Chip
        deleteIcon={<PencilSimple size={20} />}
        label={category.name}
        onDelete={() => {
          dialogState.open(category);
        }}
        size='small'
        sx={{
          '.MuiChip-deleteIcon': {
            color: 'grey.700',
          },
          '.MuiChip-label': {
            color: 'grey.900',
          },
        }}
      />
      <ExpenseCategoryForm category={category} dialogState={dialogState} />
    </form>
  );
};

export default ExpenseCategoryChip;
