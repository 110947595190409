import { useCallback, useContext } from 'react';

import { GroupContext } from 'contexts/GroupContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const useGetSlackGroupDetails = () => {
  const { dispatch } = useContext(GroupContext);
  const [org] = useContext(OrganizationContext);

  return useCallback(
    (channelSlackId: string, channelName: string) => {
      dispatch({ type: 'POPULATE_GROUP_BEGIN' });

      const slackChannelUrl = `https://slack.com/app_redirect?channel=${channelSlackId}&team=${org.slack_team_id}`;

      axios(
        {
          method: 'get',
          params: {
            channel_slack_id: channelSlackId,
          },
          url: '/api/organizations/slack_channel_description',
        },
        (res: { data: { description: string; link: string } }) => {
          dispatch({
            group: {
              description: res.data.description,
              link: slackChannelUrl,
              name: channelName
                .replace(/-/g, ' ')
                .replace(/\b\w/g, (char) => char.toUpperCase())
                .trim(),
            },
            type: 'POPULATE_GROUP_SUCCESS',
          });
        },
        (error: any) => {
          dispatch({
            payload: { error },
            type: 'POPULATE_GROUP_FAILURE',
          });
        }
      );
    },
    [dispatch, org.slack_team_id]
  );
};

export default useGetSlackGroupDetails;
