import { useQuery } from '@tanstack/react-query';

import { Group } from 'types/Group';
import { reactQueryAxios } from 'utils/axios';

interface Props {
  queryKey?: string[];
}

export const useGroups = ({ queryKey = ['budget_setting_groups'] }: Props) => {
  const url = `/api/groups`;

  const getGroups = async ({ apiClient }: { apiClient: any }) => {
    const response = await apiClient({
      method: 'get',
      url,
    });

    return response.data as Group[];
  };

  const retrieve = useQuery<Group[], Error, Group[]>({
    queryFn: async () => {
      const response = await getGroups({ apiClient: reactQueryAxios });
      return response?.map((g: Group) => {
        const currency = g?.currency_details?.symbol || '$';
        const currencyCode = g?.currency_details?.code || 'USD';
        return {
          ...g,
          currency_symbol: currency,
          nameWithCurrency: `${g.name} (${currencyCode} ${currency})`,
        };
      });
    },
    queryKey: queryKey || ['budget_setting_groups'],
  });

  return { retrieve };
};
