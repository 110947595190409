import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popper,
  Stack,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { chipClasses } from '@mui/material/Chip';
import { styled, useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import AutocompleteGroup from 'components/shared/AutocompleteGroup';

const WhiteChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.label}`]: {
    color: theme.palette.secondary.main,
  },
  backgroundColor: 'white',
  borderRadius: '50%',
  height: 24,
  marginLeft: theme.spacing(1),
  paddingLeft: 1,
  paddingRight: 1,
  width: 24,
}));
const StyledAutocomplete = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    color: 'inherit',
    margin: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

// eslint-disable-next-line func-style
function PopperComponent(props) {
  const { anchorEl, disablePortal, open, ...other } = props;
  return <StyledAutocomplete {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  borderRadius: 6,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  paddingBottom: theme.spacing(0.5),
  width: 300,
  zIndex: theme.zIndex.modal,
}));

const StyledTextField = styled(TextField)(() => ({
  padding: '8px 12px 0',
  width: '100%',
}));

export default function AutocompleteMenu({
  clearAll,
  defaultValueId,
  handleChange,
  label,
  manuallySorted = false,
  options,
  pluralLabel,
  type,
}) {
  const isMulti = type === 'multi';
  const defaultEndDate = dayjs().utc().subtract(1, 'day');
  const defaultStartDate = dayjs().utc().subtract(8, 'day');

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [isEditingDateRange, setIsEditingDateRange] = useState(false);
  const [open, setOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState(isMulti ? [] : null);
  const [potentialValue, setPotentialValue] = useState(isMulti ? [] : null);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [value, setValue] = useState(isMulti ? [] : null);

  const theme = useTheme();

  const toggleDateRangeDialog = () => {
    setIsEditingDateRange(!isEditingDateRange);
  };

  const clearSelected = (type = null) => {
    const newValue = type ? pendingValue?.filter((v) => v.type !== type) : [];
    setPendingValue(isMulti ? newValue : null);
    setValue(isMulti ? newValue : null);
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    handleChange({
      label,
      value: newValue,
    });
  };

  useEffect(() => {
    if (clearAll > 0) {
      clearSelected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAll]);

  const alphabeticalOptions = useMemo(() => {
    const sortedOpts = manuallySorted
      ? options
      : [...options]?.sort((a, b) => {
          if (a.secondary && b.secondary) {
            return `${a.label} ${a.secondary}`?.localeCompare(
              `${b.label} ${b.secondary}`
            );
          } else {
            return a.name?.localeCompare(b.name);
          }
        });
    return sortedOpts.map((opt) => ({
      ...opt,
      type: opt.type || pluralLabel,
    }));
  }, [manuallySorted, options, pluralLabel]);

  const selectionCount = useMemo(
    () => (isMulti ? pendingValue?.length : pendingValue?.id ? 1 : 0),
    [isMulti, pendingValue]
  );

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleMenuClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setValue(pendingValue);
    // setAnchorEl(null);
    setOpen(false);
  };

  const canBeOpen = Boolean(anchorEl);
  const id = canBeOpen ? `${label}-filter` : undefined;
  const iconProps = {
    color: theme.palette.grey[selectionCount > 0 ? 100 : 700],
    size: 14,
    weight: 'fill',
  };

  return (
    <>
      <Button
        aria-controls={open ? id : undefined}
        aria-describedby={id}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        color={selectionCount > 0 ? 'secondary' : 'neutral'}
        data-testid='autocomplete-menu-button'
        disableElevation
        endIcon={
          open ? <CaretUp {...iconProps} /> : <CaretDown {...iconProps} />
        }
        onClick={handleButtonClick}
        sx={{
          height: 44,
          px: 1.5,
          transition: 'all 200ms ease-in-out',
          ...(selectionCount === 0 && {
            borderColor: 'grey.300',
            color: 'grey.900',
          }),
        }}
        variant={selectionCount > 0 ? 'contained' : 'outlined'}
      >
        <Typography>{label}</Typography>
        <Zoom in={selectionCount > 0} unmountOnExit>
          <WhiteChip
            label={selectionCount > 0 ? selectionCount.toString() : ''}
            size='small'
            sx={selectionCount <= 0 ? { p: 0 } : {}}
          />
        </Zoom>
      </Button>
      {isMulti ? (
        <StyledPopper
          anchorEl={anchorEl}
          id={id}
          open={open}
          placement='bottom-start'
        >
          <ClickAwayListener onClickAway={handleMenuClose}>
            <div>
              <Autocomplete
                data-testid='autocomplete-menu-autocomplete'
                defaultValue={
                  defaultValueId
                    ? options?.find((o) => o.id === defaultValueId)
                    : []
                }
                disableCloseOnSelect={isMulti}
                getOptionLabel={(option) => option?.name}
                groupBy={(option) => option.type}
                isOptionEqualToValue={(option, value) =>
                  value?.id === option?.id
                }
                multiple={isMulti}
                noOptionsText='No options'
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === 'keydown' &&
                    event.key === 'Backspace' &&
                    reason === 'removeOption'
                  ) {
                    return;
                  }
                  setPendingValue(newValue);
                  handleChange({
                    label,
                    value: newValue,
                  });
                }}
                onClose={(event, reason) => {
                  if (reason === 'escape') {
                    handleMenuClose();
                  }
                }}
                open
                options={
                  manuallySorted
                    ? alphabeticalOptions
                    : [...alphabeticalOptions].sort((a, b) => {
                        // Display the selected options first.
                        let ai = value?.indexOf(a);
                        ai =
                          ai === -1
                            ? value?.length + alphabeticalOptions.indexOf(a)
                            : ai;
                        let bi = value?.indexOf(b);
                        bi =
                          bi === -1
                            ? value?.length + alphabeticalOptions.indexOf(b)
                            : bi;
                        return ai - bi;
                      })
                }
                PopperComponent={PopperComponent}
                renderGroup={(params) => (
                  <AutocompleteGroup
                    clearSelected={clearSelected}
                    params={params}
                    selectionCount={
                      pendingValue?.filter((v) => v?.type === params?.group)
                        ?.length
                    }
                  />
                )}
                renderInput={(params) => (
                  <StyledTextField
                    fullWidth
                    helperText={null}
                    inputProps={params.inputProps}
                    placeholder={`Search ${
                      pluralLabel.toLowerCase() ||
                      pluralize(label.toLowerCase())
                    }`}
                    ref={params.InputProps.ref}
                  />
                )}
                renderOption={(props, option, state, ownerState) => (
                  <AutocompleteCheckboxOption
                    option={option}
                    ownerState={ownerState}
                    props={props}
                    state={state}
                  />
                )}
                value={pendingValue}
              />
            </div>
          </ClickAwayListener>
        </StyledPopper>
      ) : (
        <>
          <Menu
            anchorEl={anchorEl}
            id='lock-menu'
            key={`${label}-filter-menu`}
            label={label}
            MenuListProps={{
              role: 'listbox',
            }}
            onClose={handleMenuClose}
            open={open}
          >
            {options.map((opt) => {
              const Icon = opt?.Icon;
              return (
                <MenuItem
                  key={opt.id}
                  onClick={() => {
                    if (pendingValue === opt && opt.type !== 'date-range') {
                      setPendingValue(null);
                      handleChange({
                        label,
                        value: [],
                      });
                    } else {
                      if (opt.type && opt.type === 'date-range') {
                        setPotentialValue(opt);
                        toggleDateRangeDialog();
                      } else {
                        setPendingValue(opt);
                        handleChange({
                          label,
                          value: [{ id: opt.id }],
                        });
                      }
                    }
                    handleMenuClose();
                  }}
                  selected={
                    pendingValue
                      ? pendingValue === opt
                      : defaultValueId
                      ? opt?.id === defaultValueId
                      : false
                  }
                  sx={{ py: 1.25 }}
                >
                  {Icon ? (
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                  ) : null}
                  <ListItemText
                    primary={opt.name}
                    secondary={
                      pendingValue?.id === opt?.id &&
                      opt.type &&
                      opt.type === 'date-range' &&
                      startDate &&
                      endDate
                        ? [
                            dayjs(startDate).format('M/D/YY'),
                            dayjs(endDate).format('M/D/YY'),
                          ].join(' - ')
                        : null
                    }
                  />
                </MenuItem>
              );
            })}
          </Menu>
          <Dialog
            aria-labelledby='date-range-title'
            data-testid='TODO:DATA-DIALOG-17568'
            key={`${label}-filter-dialog`}
            onClose={toggleDateRangeDialog}
            open={isEditingDateRange}
          >
            <DialogTitle
              id='date-range-title'
              sx={{ display: 'grid', gridTemplateColumns: '1fr min-content' }}
            >
              Filter by custom date range
              {startDate || endDate ? (
                <Button
                  color='primary'
                  data-testid='clear'
                  onClick={() => {
                    clearSelected();
                    toggleDateRangeDialog();
                  }}
                  size='small'
                  sx={{ justifySelf: 'flex-end' }}
                  variant='text'
                >
                  Clear
                </Button>
              ) : null}
            </DialogTitle>
            <DialogContent>
              <Stack direction='row' gap={2}>
                <DatePicker
                  disableFuture
                  label='Start date'
                  maxDate={dayjs(endDate).subtract(1, 'day') || null}
                  onChange={(val) => setStartDate(val)}
                  required
                  value={startDate}
                />
                <DatePicker
                  disableFuture
                  label='End date'
                  minDate={dayjs(startDate).add(1, 'day') || null}
                  onChange={(val) => setEndDate(val)}
                  required
                  value={endDate}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                data-testid='cancel'
                onClick={toggleDateRangeDialog}
                variant='text'
              >
                Cancel
              </Button>
              <Button
                color='primary'
                data-testid='save'
                onClick={() => {
                  if (!startDate && !endDate) {
                    clearSelected();
                    toggleDateRangeDialog();
                  } else if (
                    !dayjs(startDate).isValid() ||
                    startDate === '' ||
                    !dayjs(endDate).isValid() ||
                    endDate === ''
                  ) {
                    enqueueSnackbar(
                      'Please select both a Start and End date or click "Clear" to remove both',
                      { variant: 'error' }
                    );
                  } else {
                    setPendingValue(potentialValue);
                    handleChange({
                      data: { end: endDate, start: startDate },
                      label,
                      value: ['custom'],
                    });
                    toggleDateRangeDialog();
                  }
                }}
                variant='contained'
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

AutocompleteMenu.propTypes = {
  clearAll: PropTypes.number,
  defaultValueId: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  manuallySorted: PropTypes.bool,
  options: PropTypes.array,
  pluralLabel: PropTypes.string,
  type: PropTypes.oneOf(['multi', 'single']),
};
