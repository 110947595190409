import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import OktaOidcSettings from 'components/Settings/Integrations/OktaOidcSettings';
import OktaSamlSettings from 'components/Settings/Integrations/OktaSamlSettings';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const OktaModal = ({ onHide, show }) => {
  const [org, setOrg] = useContext(OrganizationContext);

  const { enqueueSnackbar } = useSnackbar();

  const [clientId, setClientId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [issuer, setIssuer] = useState('');
  const [samlURL, setSamlURL] = useState('');
  const [secret, setSecret] = useState('');
  const [style, setStyle] = useState('oidc');

  useEffect(() => {
    if (org) {
      if (org.okta_saml_url) {
        setStyle('saml');
        setSamlURL(org.okta_saml_url);
        setClientId('');
        setSecret('');
        setIssuer('');
      } else {
        setStyle('oidc');
        setClientId(org.okta_client_id);
        setSecret(org.okta_client_secret);
        setIssuer(org.okta_issuer);
        setSamlURL('');
      }
    }
  }, [org]);

  const updateOkta = (action) => {
    if (!isLoading) {
      setLoading(true);
      axios(
        {
          data: {
            organization: {
              okta_client_id: action === 'enable' ? _.trim(clientId) : '',
              okta_client_secret: action === 'enable' ? _.trim(secret) : '',
              okta_enabled: action === 'enable',
              okta_issuer: action === 'enable' ? _.trim(issuer) : '',
              okta_saml_url: action === 'enable' ? _.trim(samlURL) : '',
            },
          },
          method: 'patch',
          url: `/api/organizations/${org.id}`,
        },
        (res) => {
          setLoading(false);
          setOrg(res.data);
          enqueueSnackbar(
            `Okta was successfully ${
              action === 'enable' ? 'updated' : 'removed'
            }.`,
            {
              variant: 'success',
            }
          );
          onHide();
        },
        (err) => {
          setLoading(false);
          enqueueSnackbar('Something went wrong, please try again.', {
            variant: 'error',
          });
          console.log('err', err);
        }
      );
    }
  };

  return (
    <Dialog aria-label='Edit' onClose={onHide} open={show}>
      <DialogTitle>Set up Okta</DialogTitle>
      <DialogContent>
        <Typography paragraph variant='body1'>
          Please enter your subdomain &quot;{org.subdomain}&quot; when adding
          the Five to Nine Application in your Organization.{' '}
          <Link
            data-testid='read-our-set-up-guide-→'
            href='https://helpcenter.fivetonine.network/en/collections/3314494-five-to-nine-help-center#set-up-integrations'
            rel='noreferrer'
            target='_blank'
          >
            Read our Set Up Guide &rarr;
          </Link>
        </Typography>
        <FormControl fullWidth>
          <InputLabel id='okta-style-select-label'>Okta style</InputLabel>
          <Select
            id='okta-style-select'
            label='Okta Style'
            labelId='okta-style-select-label'
            onChange={(e) => {
              setStyle(e.target.value);
            }}
            value={style}
          >
            <MenuItem value='oidc'>OIDC</MenuItem>
            <MenuItem value='saml'>SAML</MenuItem>
          </Select>
        </FormControl>
        {style === 'saml' ? (
          <OktaSamlSettings samlURL={samlURL} setSamlURL={setSamlURL} />
        ) : null}
        {style === 'oidc' ? (
          <OktaOidcSettings
            clientId={clientId}
            issuer={issuer}
            secret={secret}
            setClientId={setClientId}
            setIssuer={setIssuer}
            setSecret={setSecret}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          data-testid='set-up-okta'
          onClick={() => updateOkta('enable')}
          variant='contained'
        >
          Set up Okta
        </Button>
        <Button
          color='primary'
          data-testid='remove-okta'
          disabled={!org?.okta_enabled}
          onClick={() => updateOkta('disable')}
          variant='contained'
        >
          Remove Okta
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OktaModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.any,
};

export default OktaModal;
