import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

interface FetchEventsResponse {
  fetchEvents: {
    currentPage: number;
    events: {
      coverImageUrl: string | null;
      description: string | null;
      ends: string;
      eventsCollectionId: string;
      id: number;
      name: string;
      start: string;
      timezone: string;
    }[];
    totalCount: number;
    totalPages: number;
  };
}

const fetchEventsQuery = gql`
  query fetchEvents(
    $filter: ColumnFiltersTableState = null
    $page: Int!
    $perPage: Int!
    $search: String
    $status: String
  ) {
    fetchEvents(
      filter: $filter
      page: $page
      perPage: $perPage
      search: $search
      status: $status
    ) {
      currentPage
      totalPages
      totalCount
      events {
        id
        coverImageUrl
        description
        ends
        eventsCollectionId
        name
        start
        timezone
      }
    }
  }
`;

export const useFetchEvents = async ({
  columnFilters,
  page,
  perPage,
  search,
  status,
}: {
  columnFilters?: any[] | null;
  page: number;
  perPage: number;
  search?: string;
  status?: string;
}) => {
  const graphQLClient = useGraphQLClient();

  const response: FetchEventsResponse = await graphQLClient?.request(
    fetchEventsQuery,
    {
      filter: columnFilters?.length ? { columnFilters } : null,
      page,
      perPage,
      search,
      status,
    }
  );
  return response.fetchEvents;
};
