const Roles = [
  { id: 'Admin', name: 'Admin' },
  { id: 'Organizer', name: 'Organizer' },
  { id: 'User', name: 'User' },
];

export const RolesSelectOptions = Roles.map((r) => ({
  label: r.name,
  value: r.id,
}));
