import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { useChunking } from 'utils/utils';

export const HomeContext = createContext();

export const HomeProvider = (props) => {
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);
  const upcoming = useChunking('/api/events/upcoming', 'events');
  const mine = useChunking('/api/events/mine', 'events');
  const past = useChunking('/api/events/past', 'events');
  const draft = useChunking('/api/events/draft', 'events');

  const [timeRangeEvents, setEvents] = useState([]);
  // We are going to load data immediately, so let's keep from flip floppiing the ConditionalLoaders until we are done with the initial load
  const [loadingEvents, setLoadingEvents] = useState(true);

  const requestEventRange = (start, end) => {
    if (isAuthenticated) {
      setLoadingEvents(true);
      start = dayjs(start).format('YYYY-MM-DD hh:mm:ss');
      end = dayjs(end).format('YYYY-MM-DD hh:mm:ss');

      axios(
        { url: `/api/events/range?start_date=${start}&end_date=${end}` },
        (res) => {
          setEvents(_.unionBy(timeRangeEvents, res.data.events, 'id'));
          setLoadingEvents(false);
        },
        () => setLoadingEvents(false)
      );
    } else {
      setLoadingEvents(false); // Make sure to clean up after the inital load
    }
  };

  return (
    <HomeContext.Provider
      value={{
        calendar: { events: timeRangeEvents, loadingEvents, requestEventRange },
        chunks: [upcoming, mine, past, draft],
      }}
    >
      {props.children}
    </HomeContext.Provider>
  );
};

HomeProvider.propTypes = {
  children: PropTypes.any,
};
