import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useGraphQLClient } from 'hooks/useGraphQLClient';

const fetchEventsCollectionSlackChannelOptions = gql`
  query fetchEventsCollectionSlackChannelOptions($collectionId: ID!) {
    fetchEventsCollectionSlackChannelOptions(collectionId: $collectionId) {
      id
      slackId
      name
      private
    }
  }
`;

interface SlackChannel {
  id: string;
  slackId: string;
  name: string;
  private: boolean;
}

interface FetchEventsCollectionSlackChannelOptionsResponse {
  fetchEventsCollectionSlackChannelOptions: SlackChannel[];
}

export const useFetchEventsCollectionSlackChannelOptions = (collectionId: string) => {
  const graphqlClient = useGraphQLClient();

  return useQuery<SlackChannel[]>({
    queryFn: async () => {
      const response = await graphqlClient.request<FetchEventsCollectionSlackChannelOptionsResponse>(
        fetchEventsCollectionSlackChannelOptions,
        { collectionId }
      );
      return response.fetchEventsCollectionSlackChannelOptions;
    },
    queryKey: ['fetchEventsCollectionSlackChannelOptions', collectionId],
    enabled: !!collectionId,
  });
};
