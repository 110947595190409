import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const SurveySection = ({
  children,
  expandedByDefault = false,
  noDetailsPadding = false,
  title,
}) => {
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);

  useEffect(() => {
    setIsExpanded(expandedByDefault);
  }, [expandedByDefault]);

  return (
    (<Accordion
      expanded={isExpanded}
      onChange={(e, val) => setIsExpanded(val)}
      variant='outlined'
      data-testid="TODO:DATA-ACCORDION-82107">
      <AccordionSummary
        aria-controls='survey-content'
        id={`${title}-summary`}
        sx={{ bgcolor: 'grey.100' }}
      >
        <Typography variant='body2'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pb: 0, ...(noDetailsPadding ? { p: 0 } : {}) }}>
        {children}
      </AccordionDetails>
    </Accordion>)
  );
};

SurveySection.propTypes = {
  children: PropTypes.node,
  expandedByDefault: PropTypes.bool,
  noDetailsPadding: PropTypes.bool,
  title: PropTypes.string,
};

export default SurveySection;
