import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import FtnEvent from 'types/FtnEvent';

const EventEditRoute = ({
  children,
  event,
}: {
  children?: React.ReactElement;
  event: FtnEvent;
}) => {
  const {
    userProvider: { user },
  } = useAuth();

  const userCanEditEvent = useMemo(
    () =>
      user?.is_admin ||
      (user?.employee_id && event?.host_ids?.includes(user?.employee_id)),
    [user, event]
  );

  const eventIsPublic = useMemo(
    () => ['open', 'organization'].includes(event?.privacy),
    [event]
  );

  const userIsOnGuestList = useMemo(
    () =>
      user?.employee_id
        ? (event?.invitees || [])
            .map((invitee: { employee_id: number }) => invitee?.employee_id)
            .includes(user.employee_id)
        : false,
    [user, event]
  );

  return userCanEditEvent ? (
    event?.published ? (
      <>{children}</>
    ) : (
      <Navigate replace to={`/event/${event?.id}/create`} />
    )
  ) : (
    <Navigate
      replace
      to={
        eventIsPublic || userIsOnGuestList
          ? `/event/${event?.id}/view`
          : '/events'
      }
    />
  );
};

export default EventEditRoute;
