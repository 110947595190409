import React, { useMemo, useState } from 'react';
import {
  Button,
  capitalize,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Trash } from '@phosphor-icons/react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';

const SelectionToolbar = ({
  handleDelete,
  handleSendEmail,
  handleUpdate,
  lookupKey,
  lookupList,
  menuButtonLabel,
  menuItems,
  noun = 'guest',
  nounForList = 'guest list',
  nounForMenu = 'status',
  owner,
  selectedRowIds,
  setSelectedRowIds,
  showMenuGroups = false,
  showModal = true,
  title,
  verb,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const lowerNoun = noun?.toLocaleLowerCase();

  const handleButtonClick = (event, button) => {
    if (button === 'Update attendance') {
      setAnchorEl(event.currentTarget);
    } else {
      handleSendEmail(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showUpdateSnackbar = (items, action, onDone) => {
    let lookupObj;
    if (items?.length === 1 && lookupList && lookupKey) {
      lookupObj = lookupList?.find((i) => i.id === items[0].id);
    }
    // const changeType = nounForMenu ? `the ${nounForMenu} of` : '';
    const changedItems = lookupObj
      ? lookupObj[lookupKey]
      : pluralize(`selected ${noun}`, items?.length, true);
    const message = [changedItems, `${action}d!`].join(' ');
    enqueueSnackbar(message, {
      variant: 'success',
    });
  };

  const handleBulkUpdate = (option, field = '') => {
    handleClose();
    const fieldId = field?.toLowerCase().replace(' ', '_');
    const data = selectedRowIds?.map((id) => {
      return { [fieldId]: option?.value, id };
    });
    handleUpdate(
      data,
      'update',
      () => showUpdateSnackbar(data, 'update'),
      fieldId
    );
  };

  const hasSelections = useMemo(
    () => selectedRowIds?.length > 0,
    [selectedRowIds]
  );

  return (
    <>
      <Toolbar
        className='DataGrid-toolbar'
        data-testid='TODO:DATA-TOOLBAR-26836'
        disableGutters
        sx={{
          bgcolor: hasSelections ? theme.palette.grey[100] : 'white',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          px: 2,
        }}
      >
        <Typography fontWeight={hasSelections ? 400 : null} variant='h5'>
          {hasSelections
            ? `Manage selected ${pluralize(lowerNoun, selectedRowIds?.length)}`
            : title}
        </Typography>
        {hasSelections ? (
          <Stack
            direction='row'
            flexGrow={1}
            flexWrap='nowrap'
            gap={1}
            justifyContent='flex-end'
          >
            {menuItems?.length > 0 ? (
              <>
                {menuButtonLabel.map((label) => {
                  return (
                    <Button
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup='true'
                      color='secondary'
                      id='bulk-update-button'
                      key={label.title}
                      onClick={(e) => handleButtonClick(e, label.title)}
                      startIcon={label.icon}
                    >
                      {label.title || 'Set values'}
                    </Button>
                  );
                })}
                <Menu
                  anchorEl={anchorEl}
                  id='bulk-update-menu'
                  MenuListProps={{
                    'aria-labelledby': 'bulk-update-button',
                  }}
                  onClose={handleClose}
                  open={open}
                >
                  {menuItems.map(([field, opts]) => {
                    const optsWithField = [
                      { label: field, value: null },
                      ...opts,
                    ];
                    return optsWithField?.map((opt) => {
                      const { colorName, Icon, id, label, value } = opt;
                      if (value === null) {
                        if (showMenuGroups) {
                          return (
                            <MenuItem disabled key={label}>
                              {label}
                            </MenuItem>
                          );
                        } else {
                          return null;
                        }
                      } else {
                        return (
                          <MenuItem
                            key={id}
                            onClick={() => handleBulkUpdate(opt, field)}
                          >
                            {Icon ? (
                              <ListItemIcon
                                sx={{
                                  '&.MuiListItemIcon-root': {
                                    color: `${colorName}.main`,
                                    height: 20,
                                    minWidth: 28,
                                    width: 20,
                                  },
                                }}
                              >
                                <Icon size={20} />
                              </ListItemIcon>
                            ) : null}
                            <ListItemText primary={label} />
                          </MenuItem>
                        );
                      }
                    });
                  })}
                </Menu>
              </>
            ) : null}
            <Button
              color='error'
              data-testid='TODO:DATA-BUTTON-36443'
              disabled={selectedRowIds?.length < 1}
              onClick={() =>
                handleDelete ? handleDelete() : setIsConfirmingDelete(true)
              }
              startIcon={<Trash />}
              sx={{ justifyContent: 'flex-start', minWidth: 157.25 }}
              variant='text'
            >
              {capitalize(verb || '')}{' '}
              {pluralize(lowerNoun, selectedRowIds?.length)}
            </Button>
          </Stack>
        ) : null}
      </Toolbar>
      <DeleteModal
        custom={{
          byline: `Are you sure you want to ${
            verb.toLowerCase() || 'remove'
          } them from ${nounForList}?`,
        }}
        itemCount={selectedRowIds?.length}
        noun={noun}
        onCancel={() => setIsConfirmingDelete(false)}
        onDelete={(onDone) => {
          handleUpdate(
            selectedRowIds.map((id) => ({ id })),
            'remove',
            () =>
              showUpdateSnackbar(
                selectedRowIds.map((id) => ({ id })),
                'remove',
                onDone
              )
          );
          setIsConfirmingDelete(false);
        }}
        owner={owner}
        show={showModal && isConfirmingDelete}
        subject={
          selectedRowIds?.length > 1
            ? `selected ${pluralize(lowerNoun, selectedRowIds?.length)}`
            : selectedRowIds.length === 1 && lookupList && lookupKey
            ? lookupList?.find((l) => l.id === selectedRowIds[0])?.[lookupKey]
            : null
        }
        verb={verb || 'Remove'}
      />
    </>
  );
};

SelectionToolbar.propTypes = {
  handleUpdate: PropTypes.func,
  lookupKey: PropTypes.string,
  lookupList: PropTypes.array,
  menuButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  menuItems: PropTypes.array,
  noun: PropTypes.string,
  nounForList: PropTypes.string,
  nounForMenu: PropTypes.string,
  owner: PropTypes.string,
  selectedRowIds: PropTypes.array,
  setSelectedRowIds: PropTypes.func,
  showMenuGroups: PropTypes.bool,
  title: PropTypes.string,
  verb: PropTypes.string,
};

export default SelectionToolbar;
