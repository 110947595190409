import * as React from 'react';
import { Alert, AlertTitle, List, ListItem, useTheme } from '@mui/material';
import { Circle, Confetti } from '@phosphor-icons/react';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import TextWithIcon from 'components/shared/TextWithIcon';
import { hasStarted, isOver } from 'utils/event';
import { toHtmlId } from 'utils/text';

const EventTipsForSuccess = () => {
  const {
    state: { event },
  } = React.useContext(ManagerContext);
  const theme = useTheme();

  const tips = [
    ...(hasStarted(event) || isOver(event)
      ? []
      : [
          {
            primary: 'Create a pre-event survey',
            secondary:
              'Gather insights to tailor each event’s guest experience',
          },
          {
            primary: 'Send invitations',
            secondary: 'Welcome guests to attend and RSVP to your event',
          },
        ]),
    {
      primary: 'Send a post-event survey',
      secondary: 'Collect valuable guest feedback to improve future events',
    },
    {
      primary: 'View analytics',
      secondary:
        'Analyze attendance and survey data to gather valuable insights',
    },
  ];

  return (
    <Alert icon={false} severity='info' sx={{ width: '100%' }}>
      <Confetti color={theme.palette.info.main} size={24} />
      <AlertTitle sx={{ my: 0.5 }}>
        {hasStarted(event)
          ? isOver(event)
            ? 'Your event has ended.'
            : 'Your event is happening now!'
          : 'Congratulations, your event is live!'}{' '}
        Here&rsquo;s what&rsquo;s next:
      </AlertTitle>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        {tips.map(({ primary, secondary }) => (
          <ListItem
            disableGutters
            disablePadding
            key={toHtmlId(primary)}
            sx={{ alignItems: 'flex-start', gap: 1.5 }}
          >
            <TextWithIcon
              Icon={Circle}
              iconProps={{
                color: theme.palette.info.main,
                size: 8,
                weight: 'fill',
              }}
              iconWrapperProps={{ sx: { minWidth: 8 } }}
              primary={primary}
              primaryTypographyProps={{ color: 'grey.900', variant: 'body2' }}
              secondary={secondary}
            />
          </ListItem>
        ))}
      </List>
    </Alert>
  );
};

export default EventTipsForSuccess;
