import React from 'react';
import { capitalize } from '@mui/material';
import PropTypes from 'prop-types';

import Head from 'components/Core/Head';
import { SurveyResponseProvider } from 'components/Survey/SurveyResponseContext';
import SurveyWrapper from 'components/Survey/SurveyWrapper';
import { convertTypeToReadable } from 'utils/survey';

const Survey = ({ type }) => (
  <SurveyResponseProvider>
    <Head
      title={capitalize(
        [type ? convertTypeToReadable(type) : '', 'survey'].join(' ')
      )}
    />
    <SurveyWrapper type={type} />
  </SurveyResponseProvider>
);

Survey.propTypes = {
  type: PropTypes.oneOf(['pre_event_survey', 'post_event_survey']),
};

export default Survey;
