import React, { useMemo } from 'react';
import { capitalize, Chip } from '@mui/material';

import { communicationStatusDetails } from 'components/Events/Controls/Communications/communication.constants';

interface GuestStatusChipProps {
  scheduledNeutral?: boolean;
  status?: 'scheduled' | 'invited';
}

const GuestStatusChip = ({
  scheduledNeutral,
  status: statusId,
}: GuestStatusChipProps) => {
  const guestStatuses = useMemo(
    () => ({
      invited: communicationStatusDetails['sent'],
      scheduled: communicationStatusDetails['scheduled'],
    }),
    []
  );

  const status = useMemo(
    () => (statusId ? guestStatuses[statusId] : null),
    [guestStatuses, statusId]
  );

  const StatusIcon = useMemo(
    () => (status && status?.Icon ? <status.Icon size={18} /> : undefined),
    [status]
  );

  const isScheduledNeutral = useMemo(
    () => scheduledNeutral && statusId?.toLowerCase() === 'scheduled',
    [scheduledNeutral, statusId]
  );

  return status ? (
    <Chip
      color={status?.color}
      icon={StatusIcon}
      label={capitalize(
        isScheduledNeutral ? 'invitation scheduled' : statusId || ''
      )}
      size='small'
      sx={{
        height: 32,
        ml: 'auto',
        mr: scheduledNeutral ? 0 : 2,
        ...(isScheduledNeutral
          ? {
              bgcolor: 'grey.100',
              color: 'grey.900',
            }
          : {}),
      }}
      variant='light'
    />
  ) : null;
};

export default GuestStatusChip;
