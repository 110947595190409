import React, { useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import { useMicrosoftLogin } from 'utils/microsoft';

const MicrosoftLogin = ({
  label = null,
  onCodeReceived = null,
  onMfaRequired = null,
  onSuccess,
  org,
  settingsStyling = false,
}) => {
  const {
    onboard: { token: onboardToken },
  } = useContext(AuthContext);
  const { isLoading, signIn } = useMicrosoftLogin(
    org,
    undefined,
    onCodeReceived,
    onSuccess,
    onboardToken,
    onMfaRequired
  );
  return (
    <LoadingButton
      color={settingsStyling ? 'primary' : 'secondary'}
      data-testid='TODO:DATA-BUTTON-84107'
      fullWidth
      loading={isLoading}
      onClick={signIn}
      startIcon={
        !settingsStyling && (
          <Avatar
            data-testid='TODO:DATA-AVATAR-37771'
            src='/images/microsoft-logo.svg'
            sx={{ borderRadius: 0, height: 24, width: 24 }}
            variant='square'
          />
        )
      }
      variant='bordered'
    >
      {label || 'Sign in with Microsoft'}
    </LoadingButton>
  );
};

MicrosoftLogin.propTypes = {
  label: PropTypes.string,
  onCodeReceived: PropTypes.func,
  onMfaRequired: PropTypes.func,
  onSuccess: PropTypes.func,
  org: PropTypes.any,
  settingsStyling: PropTypes.bool,
};
export default MicrosoftLogin;
