import React, { useMemo } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { CheckSquare, Circle } from '@phosphor-icons/react';
import { PropTypes } from 'prop-types';

import QuestionNps from 'components/Core/Questions/QuestionNps';
import NoResponses from 'components/Events/Controls/Analytics/Charts/NoResponses';
import { SurveyQuestionResponseWrapper } from 'components/Events/Controls/Analytics/SurveyQuestionResponseWrapper';
import { NpsOptionValueLabelMap } from 'constants/survey.constants';
import { handleResponderDisplay } from 'utils/survey';

const SurveyQuestionResponses = ({
  displayResponders,
  individualResponses,
  isForSingleResponder = false,
  isForSummaryView = false,
  options,
  type,
}) => {
  const theme = useTheme();

  const optionColors = useMemo(() => {
    const optColors = new Map(options?.map((opt) => [opt.id, opt.color]));
    return optColors;
  }, [options]);

  const hasDialog = useMemo(
    () => isForSummaryView && individualResponses?.length > 10,
    [individualResponses?.length, isForSummaryView]
  );

  return (<>
    {isForSingleResponder && type === 'nps' ? (
      <QuestionNps
        key='response-nps'
        readonly
        showLabel={false}
        sx={{ my: 0 }}
        value={Number(individualResponses[0]?.responses[0]?.text)}
      />
    ) : individualResponses?.length > 0 ? (
      individualResponses
        // INFO: If there are > 10 responses, only show 5 with a button to see all in a dialog
        ?.slice(hasDialog ? 0 : undefined, hasDialog ? 5 : undefined)
        ?.map((individualResponse, individualIndex) => (
          <SurveyQuestionResponseWrapper
            key={`response-${type.replace('_select', '')}-${individualIndex}`}
          >
            {/* INFO: Aligns number & response content at the top */}
            <Stack alignItems='flex-start' direction='row' gap={0.625}>
              {type === 'short' && !isForSingleResponder ? (
                <Typography>{individualIndex + 1}.</Typography>
              ) : null}
              {/* INFO: If possible, shows respondent name inline with content. Otherwise, shows below content */}
              <Stack
                columnGap={3}
                direction={type === 'multi_select' ? 'column' : 'row'}
                flexWrap='wrap'
                rowGap={1}
              >
                {/* INFO: Adds space between individual's responses (e.g. options checked) */}
                <Stack direction='column' gap={0.5}>
                  {individualResponse?.responses?.map((r, responseIndex) => {
                    const optionColor =
                      optionColors?.has(r.optionId) &&
                      optionColors.get(r.optionId)?.length > 0
                        ? optionColors.get(r.optionId)
                        : theme.palette.grey[400];
                    const npsText =
                      type === 'nps'
                        ? NpsOptionValueLabelMap.get(Number(r.text))
                        : '';
                    const npsTextLower = npsText?.toLowerCase();
                    return (
                      // INFO: Aligns (optional) icon/checkbox with response text (or option label)
                      (<Stack
                        alignItems='center'
                        direction='row'
                        gap={1}
                        key={`response-${type.replace(
                          '_select',
                          ''
                        )}ea-${responseIndex}`}
                      >
                        {type === 'nps' ? (
                          <Circle
                            color={
                              theme.palette[
                                npsTextLower.includes('unlikely')
                                  ? 'error'
                                  : npsTextLower === 'neutral'
                                  ? 'warning'
                                  : 'success'
                              ].main
                            }
                            size={16}
                            weight='fill'
                          />
                        ) : type === 'multi_select' ? (
                          <CheckSquare
                            color={optionColor}
                            size={20}
                            weight='fill'
                          />
                        ) : type === 'single_select' ? (
                          <Circle
                            color={optionColor}
                            size={16}
                            weight='fill'
                          />
                        ) : null}
                        <Typography
                          color='text.primary'
                          variant='body1'
                          whiteSpace='normal'
                        >
                          {type === 'nps' ? npsText : r.text}
                        </Typography>
                      </Stack>)
                    );
                  })}
                </Stack>
                {displayResponders && !isForSingleResponder ? (
                  <Typography
                    color='grey.600'
                    fontWeight={300}
                    variant='body1'
                    whiteSpace='nowrap'
                  >
                    {handleResponderDisplay(individualResponse) || ''}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
          </SurveyQuestionResponseWrapper>
        ))
    ) : (
      <NoResponses />
    )}
  </>);
};

SurveyQuestionResponses.propTypes = {
  displayResponders: PropTypes.bool,
  individualResponses: PropTypes.any,
  isForSingleResponder: PropTypes.bool,
  isForSummaryView: PropTypes.bool,
  options: PropTypes.array,
  type: PropTypes.string,
};

export default SurveyQuestionResponses;
