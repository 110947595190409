import React from 'react';
import { useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';

interface GroupBudgetBarChartProps {
  currencySymbol: string;
  data: any;
  isOverBudget: boolean;
}

const GroupBudgetBarChart = ({
  currencySymbol,
  data,
  isOverBudget,
}: GroupBudgetBarChartProps) => {
  const theme = useTheme();

  return (
    <div style={{ height: 50 }}>
      <ResponsiveBar
        animate
        borderRadius={2}
        colors={({ data, id }) => {
          if (id === 'OverBudget' && data[`${id}._original`] !== null) {
            return theme.palette.error.dark;
          }
          return id === 'Spent'
            ? isOverBudget
              ? theme.palette.grey[100]
              : theme.palette.primary.main
            : theme.palette.grey[100];
        }}
        data={data}
        enableGridX={false}
        enableGridY={false}
        enableLabel={false}
        indexBy='label'
        indexScale={{ round: true, type: 'band' }}
        keys={isOverBudget ? ['Budget', 'OverBudget'] : ['Spent', 'Available']}
        layout='horizontal'
        margin={{
          bottom: -4,
          left: 0,
          right: 0,
          top: -4,
        }}
        theme={{
          text: {
            fontFamily: 'Inter',
          },
        }}
        tooltipLabel={(d: any) =>
          d.id === 'OverBudget' ? 'Over budget' : d.id
        }
        valueFormat={(v: any) =>
          data?.length > 0 && v !== 0
            ? `${currencySymbol}${Number(v).toLocaleString()}`
            : 'No data'
        }
        valueScale={{ type: 'linear' }}
      />
    </div>
  );
};

export default GroupBudgetBarChart;
