import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import { ImageProvider } from 'contexts/ImageContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { useContextWithFallback } from 'utils/utils';

export const EventWrapper = ({ children }) => {
  const context = useContextWithFallback(
    ManagerContext,
    PublicEventContext,
    LayoutContext
  );

  const imageProps = useMemo(
    () => ({
      dimensions: context?.state?.event?.config?.cover_image_dimensions,
      key: context?.state?.event?.cover_image || null,
      url: context?.state?.event?.config?.cover_image_url || null,
    }),
    [
      context?.state?.event?.config?.cover_image_dimensions,
      context?.state?.event?.config?.cover_image_url,
      context?.state?.event?.cover_image,
    ]
  );

  return (
    <ImageProvider
      dimensions={context?.state?.event?.config?.cover_image_dimensions}
      image={imageProps}
      url={context?.state?.event?.config?.cover_image_url}
    >
      {context?.state ? <>{children}</> : null}
    </ImageProvider>
  );
};

EventWrapper.propTypes = {
  children: PropTypes.node,
};
