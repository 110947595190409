import { useContext, useMemo } from 'react';

import { communicationMethods } from 'components/Events/Controls/Communications/communication.constants';
import { CommunicationMethod } from 'components/Events/Controls/Communications/communication.types';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';

const useCommunicationMethods = () => {
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;
  const [organization] = useContext(OrganizationContext);

  const needsCalAuth = useMemo(() => {
    switch (organization?.calendar_enabled) {
      case 'none':
        return false;
      case 'google':
        return !user?.has_google_calendar_auth;
      case 'outlook':
        return !user?.has_outlook_auth;
    }
  }, [
    organization?.calendar_enabled,
    user?.has_google_calendar_auth,
    user?.has_outlook_auth,
  ]);

  const invitationMethods = useMemo(() => communicationMethods, []);

  const enabledMethods = useMemo(
    () =>
      [...communicationMethods].filter((m: CommunicationMethod) => {
        switch (m.id) {
          case 'slack':
            return organization?.slack_enabled;
          case 'calendar':
            return (
              organization?.calendar_enabled !== 'none' &&
              needsCalAuth === false
            );
          default:
            return true;
        }
      }),
    [needsCalAuth, organization?.calendar_enabled, organization?.slack_enabled]
  );

  const messageMethods = useMemo(
    () =>
      [...invitationMethods].filter(
        (m: CommunicationMethod) => m.id !== 'calendar'
      ),
    [invitationMethods]
  );

  return {
    enabledMethods,
    invitationMethods,
    messageMethods,
    needsCalAuth,
    open,
  };
};

export default useCommunicationMethods;
