import React, { useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  capitalize,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { DateTimePicker, DateTimeValidationError } from '@mui/x-date-pickers';
import { CalendarBlank, MinusCircle } from '@phosphor-icons/react';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { plural } from 'pluralize';

import {
  CommunicationFormData,
  CommunicationTypeId,
} from 'components/Events/Controls/Communications/communication.types';
import EventDayIndicator from 'components/Events/Controls/Communications/EventDayIndicator';
import ScheduleIcon from 'components/shared/icons/ScheduleIcon';
import useEventDateTimeRange from 'hooks/useEventDateTimeRange';
import useEventRelativeTime from 'hooks/useEventRelativeTime';
import FtnEvent from 'types/FtnEvent';
import { useSnackbar } from 'notistack';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

interface Props {
  form: UseFormReturn<CommunicationFormData>;
  savedEvent: FtnEvent;
  type?: CommunicationTypeId;
}

const ScheduleCommunicationDialog = ({
  form: { setValue, watch },
  savedEvent,
  type: typeId,
}: Props) => {
  const eventDateTimeRange = useEventDateTimeRange(savedEvent);

  const value = watch('scheduled_for');
  const isScheduled = watch('is_scheduled');

  const { enqueueSnackbar } = useSnackbar();

  const {
    eventEnd,
    eventStart,
    isFuture: isEventUpcoming,
    isNow: isEventNow,
    isPast: isEventOver,
    isToday,
  } = useEventRelativeTime(savedEvent);

  const [canShowError, setCanShowError] = useState(false);
  const [error, setError] = useState<DateTimeValidationError | undefined>(
    undefined
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState(false);
  const [localValue, setLocalValue] = useState<Dayjs | null>(
    value ? dayjs(value) : null
  );

  const resetLocalValue = () => setLocalValue(null);

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'disablePast': {
        return 'Scheduled date must be in the future';
      }
      case 'maxDate':
      case 'maxTime': {
        return 'Scheduled date & time must be before the event starts';
      }
      default: {
        return value === null
          ? ''
          : error === 'invalidDate' ||
            (value &&
              value?.length > 0 &&
              dayjs(value)?.isValid() === false &&
              !isEditing)
          ? 'Invalid date'
          : '';
      }
    }
  }, [isEditing, value, error]);

  const selectedTimeAsRelative = useMemo(() => {
    const selectedTime = dayjs(value);
    if (selectedTime.isSame(eventEnd, 'minute')) {
      return 'when your event ends';
    }
    if (selectedTime.isSame(eventStart, 'minute')) {
      return 'when your event starts';
    }
    if (isEventUpcoming) {
      if (selectedTime.isAfter(eventEnd)) {
        return `${selectedTime.from(eventEnd, true)} after your event ends`;
      } else {
        return `${selectedTime.from(eventStart, true)} ${
          selectedTime.isBefore(eventStart) ? 'before' : 'after'
        } your event starts`;
      }
    } else {
      if (isEventOver) {
        return selectedTime.fromNow();
      } else if (isEventNow && selectedTime.isBefore(eventEnd)) {
        return `${selectedTime.from(eventEnd, true)} before your event ends`;
      } else {
        return `${selectedTime.from(eventEnd, true)} after your event ends`;
      }
    }
  }, [eventEnd, eventStart, isEventNow, isEventOver, isEventUpcoming, value]);

  const hasValidEventStart = useMemo(
    () => savedEvent?.start !== null && dayjs(savedEvent?.start).isValid(),
    [savedEvent]
  );

  const hasValidValue = useMemo(
    () => value !== null && dayjs(value).isValid(),
    [value]
  );

  const isInvitation = useMemo(() => typeId === 'invitation', [typeId]);

  const closeDialog = () => {
    setIsDialogOpen(false);
    setIsWorking(false);
    setCanShowError(false);
    setError(undefined);
  };

  const handleChange = (newValue: Dayjs | null) => {
    setCanShowError(true);
    setLocalValue(newValue);
  };

  const saveSchedule = () => {
    if (!error) {
      if (localValue === null) {
        setError('invalidDate');
        enqueueSnackbar('Please select a date or click Cancel.', {
          variant: 'error',
        });
      } else {
        setIsWorking(true);
        setValue('is_scheduled', localValue !== null, { shouldDirty: true });
        setValue('scheduled_for', localValue?.toISOString(), {
          shouldDirty: true,
        });

        closeDialog();
      }
    }
  };

  const removeSchedule = () => {
    setLocalValue(null);
    if (!error) {
      setValue('is_scheduled', false, { shouldDirty: true });
      setValue('scheduled_for', null, { shouldDirty: true });
      closeDialog();
    }
  };

  const typeWithDemonstrativePronoun = useMemo(
    () =>
      isInvitation && typeof typeId === 'string'
        ? `these ${plural(typeId)}`
        : `this ${typeId}`,
    [isInvitation, typeId]
  );

  return (
    <>
      <Button
        color='primary'
        data-testid='schedule-communication'
        onClick={() => {
          resetLocalValue();
          setIsDialogOpen(true);
        }}
        sx={{ height: 44 }}
        variant='outlined'
      >
        <ScheduleIcon isScheduled={isScheduled} size={22} />
      </Button>
      <Dialog
        aria-label='delete'
        fullWidth
        maxWidth='sm'
        onClose={closeDialog}
        open={Boolean(isDialogOpen)}
        sx={{
          '& .MuiDialog-container': {
            // INFO: This allows the dateTime picker to open below the input,
            //       so the user can still see the event time while it's open
            alignItems: 'flex-start',
          },
        }}
      >
        <DialogTitle>Schedule {typeId}</DialogTitle>
        <DialogContent>
          <Stack direction='column' gap={2}>
            <Alert icon={<CalendarBlank weight='fill' />} severity='info'>
              <AlertTitle>{savedEvent?.name}</AlertTitle>
              {eventDateTimeRange}
            </Alert>
            <DialogContentText>
              When would you like {typeWithDemonstrativePronoun} to be sent?
            </DialogContentText>
            <Stack direction='column'>
              <DateTimePicker
                disablePast
                label='Date & time'
                maxDate={isInvitation ? dayjs(eventStart) : undefined}
                maxDateTime={isInvitation ? dayjs(eventStart) : undefined}
                onChange={handleChange}
                onError={(error: DateTimeValidationError) => setError(error)}
                slotProps={{
                  day: {
                    highlightedDays: [eventStart],
                  } as any,
                  textField: {
                    error: canShowError && !!error && errorMessage?.length > 0,
                    helperText: (
                      <Box height={24} typography='overline'>
                        <Collapse
                          in={
                            (String(error)?.length > 0 && canShowError) ||
                            (hasValidEventStart && hasValidValue)
                          }
                          unmountOnExit
                        >
                          {canShowError && !!error && errorMessage?.length > 0
                            ? errorMessage
                            : hasValidEventStart && hasValidValue
                            ? [
                                `${capitalize(
                                  typeWithDemonstrativePronoun || ''
                                )} will send`,
                                selectedTimeAsRelative,
                              ].join(' ')
                            : null}
                        </Collapse>
                      </Box>
                    ),
                    margin: 'none',
                    onBlur: () => setIsEditing(false),
                    onFocus: () => setIsEditing(true),
                  },
                }}
                slots={{
                  day: EventDayIndicator,
                }}
                timezone={dayjs.tz.guess()}
                value={
                  localValue ? dayjs(localValue) : value ? dayjs(value) : null
                }
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            data-testid='cancel'
            onClick={closeDialog}
            variant='text'
          >
            Cancel
          </Button>
          {isScheduled && value && (
            <Button
              color='secondary'
              data-testid='cancel'
              onClick={removeSchedule}
              startIcon={<MinusCircle />}
              variant='bordered'
            >
              Remove
            </Button>
          )}
          <LoadingButton
            aria-label='confirm save'
            color='primary'
            loading={isWorking}
            onClick={saveSchedule}
            variant='contained'
          >
            Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduleCommunicationDialog;
