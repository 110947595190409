import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSnackbar } from 'notistack';

import { EventsCollectionAnnouncementCreateInput } from 'gql/graphql';
import { useGraphQLClient } from 'hooks/useGraphQLClient';

interface GraphQLError {
  message: string;
}

interface CreateEventsCollectionAnnouncementResponse {
  eventsCollectionAnnouncementCreate: {
    errors?: GraphQLError[];
    eventsCollectionAnnouncement: {
      id: string;
      messageBody: string;
      deliveryMethod: string;
      recipients: {
        recipientId: string;
        recipientType: string;
      }[];
    };
  };
}

const CREATE_ANNOUNCEMENT_MUTATION = gql`
  mutation EventsCollectionAnnouncementCreate($input: EventsCollectionAnnouncementCreateInput!) {
    eventsCollectionAnnouncementCreate(input: $input) {
      eventsCollectionAnnouncement {
        id
        messageBody
        deliveryMethod
        recipients {
          recipientId
          recipientType
        }
      }
      errors
    }
  }
`;

const handleGraphQLResponse = <T>(
  response: T | undefined,
  errors: GraphQLError[] | undefined,
  enqueueSnackbar: (
    message: string,
    options: { variant: 'success' | 'error' | 'warning' }
  ) => void
): T | null => {
  if (errors?.length) {
    enqueueSnackbar(`Failed: ${errors.map((e) => e.message).join(', ')}`, {
      variant: 'error',
    });
    return null;
  }

  if (!response) {
    enqueueSnackbar('Failed: No data returned', {
      variant: 'error',
    });
    return null;
  }

  return response;
};

export const useEventsCollectionAnnouncementCreate = (onSuccessCallback: () => void) => {
  const graphQLClient = useGraphQLClient();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (input: { input: EventsCollectionAnnouncementCreateInput }) => {
      const response =
        await graphQLClient.request<CreateEventsCollectionAnnouncementResponse>(
          CREATE_ANNOUNCEMENT_MUTATION,
          input
        );
      return response;
    },
    onError: (error: any) => {
      enqueueSnackbar(`Failed to create announcement: ${error.message}`, {
        variant: 'error',
      });
      console.error('Failed to create announcement', error);
    },
    onSuccess: (response: CreateEventsCollectionAnnouncementResponse) => {
      const { eventsCollectionAnnouncementCreate } = response || {};

      const announcementData = handleGraphQLResponse(
        eventsCollectionAnnouncementCreate?.eventsCollectionAnnouncement,
        eventsCollectionAnnouncementCreate?.errors,
        enqueueSnackbar
      );

      if (announcementData) {
        enqueueSnackbar('Your message is being sent out now!', {
          variant: 'success',
        });
        queryClient.invalidateQueries({ queryKey: ['announcements'] });
        onSuccessCallback();
      }
    },
  });
};

