import React from 'react';
import { Box, capitalize, Chip, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import useEventDateTimeRange from 'hooks/useEventDateTimeRange';
import { EventLocationTypes } from 'utils/location.constants';
import { useContextWithFallback } from 'utils/utils';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const PreviewHeader = ({ inToolbar }) => {
  const {
    state: { event, locationType },
  } = useContextWithFallback(ManagerContext, PublicEventContext);

  const eventDateTimeRange = useEventDateTimeRange(event);

  return (
    <Box {...(inToolbar ? { minWidth: 0 } : {})}>
      <Stack alignItems='center' direction='row' gap={2}>
        <Typography
          color='grey.900'
          data-testid='event-title'
          maxWidth='80vw'
          {...(inToolbar
            ? {
                sx: { overflow: 'hidden' },
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }
            : {})}
          variant={
            inToolbar
              ? event?.published || location.pathname.includes('public')
                ? 'h1'
                : 'h4'
              : location.pathname.indexOf('view') !== -1 ||
                location.pathname.includes('public')
              ? 'h1'
              : 'h2'
          }
        >
          {event.name || 'New Event'}
        </Typography>
      </Stack>
      <Stack alignItems='center' direction='row' gap={2}>
        <Typography color='grey.600' component='div' variant='body2'>
          {eventDateTimeRange}
        </Typography>
        {locationType && (
          <Chip
            label={
              EventLocationTypes[locationType]?.label ||
              capitalize(locationType)
            }
            size='large'
            sx={{
              bgcolor: EventLocationTypes[locationType]?.colors?.light,
              color: EventLocationTypes[locationType]?.colors?.dark,
              fontWeight: 600,
              // INFO: Prevent chip from increasing the height of this row when displayed
              my: -0.5,
            }}
          />
        )}
      </Stack>
    </Box>
  );
};
PreviewHeader.propTypes = {
  inToolbar: PropTypes.bool,
};
export default PreviewHeader;
