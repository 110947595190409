import { useMemo } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { CommunicationMethodId } from 'components/Events/Controls/Communications/communication.types';
import { EventInvitationGuestTypeId } from 'types/EventInvitationGuestType';

interface Params {
  eventId: number;
  invitationId?: number | 'new';
  method: CommunicationMethodId;
  optionId: number;
  optionType: EventInvitationGuestTypeId;
}

export const useEventInvitationOptionMembers = ({
  eventId,
  invitationId,
  method,
  optionId,
  optionType,
}: Params) => {
  const url = `/api/event_invitations/option_collection_members`;

  const queryKey = useMemo(
    () => [
      'event',
      eventId,
      'invitation-method',
      method, // 'email', 'slack', 'calendar'
      'invitation',
      invitationId,
      'invitation-options-members',
      optionType, // 'group', 'list', 'office', 'contact'
      'option',
      optionId,
    ],
    [eventId, method, invitationId, optionType, optionId]
  );

  const apiRetrieve = useQuery({
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const fetchURL = new URL(`${import.meta.env.VITE_BE_URL}${url}`);
      fetchURL.searchParams.set('event_id', `${eventId}`);
      fetchURL.searchParams.set('invitation_type', `${method}`);
      invitationId !== 'new' &&
        fetchURL.searchParams.set('invitation_id', `${invitationId}`);
      fetchURL.searchParams.set('collection_type', `${optionType}`);
      fetchURL.searchParams.set('collection_id', `${optionId}`);

      const response = await fetch(fetchURL.href, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const json = await response.json();
      return json;
    },
    queryKey,
  });

  return {
    retrieve: apiRetrieve,
  };
};
