import { useCallback, useEffect, useMemo } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';

const useCheckCommsTiming = (event: any) => {
  const [eventAlertsDismissed, setEventAlertsDismissed] = useLocalStorage(
    'rescheduleCommAlertsDismissed',
    []
  );

  const hasDismissedAlert = useMemo(
    () => eventAlertsDismissed?.includes(event?.id),
    [event?.id, eventAlertsDismissed]
  );

  const hasInvalidComms = useMemo(() => {
    const communications = event?.communication_histories;
    if (communications?.length > 0) {
      const scheduledInvitations = communications.filter(
        (comm: any) =>
          comm?.type === 'invitation' && comm?.status === 'scheduled'
      );
      const eventStart = new Date(event?.start);
      if (
        scheduledInvitations.some((invitation: any) => {
          const scheduledDate = new Date(invitation?.send_time);
          return eventStart < scheduledDate;
        })
      ) {
        return true;
      }
    }
    return false;
  }, [event?.start, event?.communication_histories]);

  const isAlertVisible = useMemo(
    () => hasInvalidComms && !hasDismissedAlert,
    [hasDismissedAlert, hasInvalidComms]
  );

  const addToDismissed = useCallback(() => {
    setEventAlertsDismissed([...eventAlertsDismissed, event?.id]);
  }, [event?.id, eventAlertsDismissed, setEventAlertsDismissed]);

  const removeFromDismissed = useCallback(
    () =>
      setEventAlertsDismissed(
        [...eventAlertsDismissed].filter((id) => id !== event?.id)
      ),
    [event?.id, eventAlertsDismissed, setEventAlertsDismissed]
  );

  const needsReschedulingIds = useMemo(() => {
    const communications = event?.communication_histories;
    if (communications?.length > 0) {
      const scheduledInvitations = communications?.filter(
        (comm: any) =>
          comm?.type === 'invitation' && comm?.status === 'scheduled'
      );
      const eventStart = new Date(event?.start);
      return scheduledInvitations
        .filter((invitation: any) => {
          const scheduledDate = new Date(invitation?.send_time);
          return eventStart < scheduledDate;
        })
        .map((invitation: any) => invitation.id);
    } else {
      return [];
    }
  }, [event?.start, event?.communication_histories]);

  const needsRescheduling = useMemo(
    () => needsReschedulingIds?.length > 0,
    [needsReschedulingIds]
  );

  useEffect(() => {
    if (hasInvalidComms === false && hasDismissedAlert === true) {
      removeFromDismissed();
    }
  }, [hasDismissedAlert, hasInvalidComms, removeFromDismissed]);

  return {
    dismissAlert: addToDismissed,
    hasDismissedAlert,
    hasInvalidComms,
    isAlertVisible,
    needsRescheduling,
    needsReschedulingIds,
  };
};

export default useCheckCommsTiming;
