import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import DeleteModal from 'components/Core/DeleteModal';
import IntegrationCard from 'components/Settings/Integrations/IntegrationCard';
import { axiosAuthenticated as axios } from 'utils/axios';

const SlackAuthorizationUrl = 'https://slack.com/oauth/v2/authorize';
const SLACK_SCOPES = [
  'channels:history',
  'channels:join',
  'channels:manage',
  'channels:read',
  'chat:write',
  'chat:write.customize',
  'chat:write.public',
  'emoji:read',
  'im:write',
  'users:read',
  'users:read.email',
  'groups:read',
  'mpim:read',
];

interface Props {
  org: {
    slack_enabled: boolean;
  };
  setOrg: (val: boolean) => void;
}

const SlackIntegrationCard = ({ org, setOrg }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmingDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const enableSlack = (code: string) => {
    axios(
      {
        data: { code, redirect_uri: `${window.location.origin}/auth/slack` },
        method: 'post',
        url: '/api/organizations/add_slack',
      },
      (res: AxiosResponse) => {
        setOrg(res.data);
        setIsLoading(false);
        navigate(`/settings/integrations/slack`);
      },
      (err: AxiosError) => {
        console.error('err', err);
        setIsLoading(false);
      }
    );
  };

  const disableSlack = (cleanup?: () => void) => {
    setIsLoading(true);
    axios(
      {
        method: 'get',
        url: '/api/organizations/remove_slack',
      },
      (res: AxiosResponse) => {
        setOrg(res.data);
        setIsLoading(false);
        setConfirmDelete(false);
        enqueueSnackbar(
          'Slack integration removed, you can no longer invite guests via Slack channel',
          { variant: 'success' }
        );
        cleanup && cleanup();
      },
      (err: AxiosError) => {
        console.error('err', err);
        setIsLoading(false);
        setConfirmDelete(false);
        cleanup && cleanup();
      }
    );
  };

  const openPopup = () => {
    const width = 500;
    const height = 820;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    return window.open(
      `${SlackAuthorizationUrl}?scope=${SLACK_SCOPES.join(',')}&client_id=${
        import.meta.env.VITE_SLACK_CLIENT_ID
      }&redirect_uri=${window.location.origin + '/auth/slack'}`,
      '',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height= ${height}, top=${top}, left= ${left}`
    );
  };

  const popupWatcher = (popup: any) => {
    const watch = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(watch);
        setIsLoading(false);
      }

      const closePopup = () => {
        clearInterval(watch);
        popup.close();
      };

      try {
        if (
          popup?.location?.hostname &&
          !popup.location.hostname.includes('slack.com')
        ) {
          if (popup.location.search) {
            const query: URLSearchParams = new URLSearchParams(
              popup.location.search
            );
            const code = query.get('code');
            const error =
              query.has('variant') && query.get('variant') === 'error';

            closePopup();
            if (code) {
              enableSlack(code);
              enqueueSnackbar(
                'Slack integration enabled. You can now invite guests via Slack channels.',
                { variant: 'success' }
              );
            } else if (error) {
              setIsLoading(false);
              enqueueSnackbar('Something went wrong, please try again.', {
                variant: 'error',
              });
            }
          }
        }
      } catch (error) {
        if (error instanceof DOMException) {
          // We expect this error until the popup gets redirected back to us
        } else {
          setIsLoading(false);
          console.error('error', error);
          enqueueSnackbar('Something went wrong, please try again.', {
            variant: 'error',
          });
        }
      }
    }, 500);
  };

  const doSlack = () => {
    setIsLoading(true);
    popupWatcher(openPopup());
  };

  return (
    <>
      <IntegrationCard
        description='Connect Five to nine with your Slack channels. Send event invitations
        and custom surveys to your Slack channels.'
        isActive={org?.slack_enabled}
        isLoading={isLoading}
        name='Slack'
        onEdit={() => navigate('slack/channels')}
        onSetUp={() => doSlack()}
      />
      <DeleteModal
        custom={{
          byline:
            "If you remove this integration you won't be able to invite guests through Slack.",
          confirm: 'Remove Slack',
          headline: 'Do you want to disable Slack?',
          title: 'Disable Slack',
        }}
        onCancel={() => setConfirmDelete(false)}
        onDelete={disableSlack}
        show={isConfirmingDelete}
      />
    </>
  );
};

export default SlackIntegrationCard;
