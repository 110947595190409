import React from 'react';
import { FormControl, FormLabel, Skeleton, TextField } from '@mui/material';

import {
  EventDetailsFormFormState,
  EventDetailsFormGetFieldState,
  EventDetailsFormRegister,
} from 'types/EventDetailsForm';

interface EventTitleFieldProps {
  disableFields: boolean;
  formState: EventDetailsFormFormState;
  getFieldState: EventDetailsFormGetFieldState;
  isLoading?: boolean;
  register: EventDetailsFormRegister;
}

const EventTitleField = ({
  disableFields,
  formState,
  getFieldState,
  isLoading = false,
  register,
}: EventTitleFieldProps) => {
  const errorText = 'This field is required';
  const placeholder = 'Lunch and learn';

  if (isLoading) {
    return (
      <FormControl>
        <FormLabel required>Event title</FormLabel>
        <Skeleton animation='wave' height={44} />
      </FormControl>
    );
  }

  const { error } = getFieldState('name', formState); // Getting error from getFieldState is sufficient because we only show a single error at a time for this field (only 1 rule: required)

  return (
    <TextField
      aria-placeholder={placeholder}
      error={error != null && !disableFields}
      fullWidth
      helperText={error && !disableFields && errorText}
      id='event-title'
      InputProps={{ readOnly: disableFields }}
      inputProps={
        register && {
          ...register('name', {
            required: true,
          }),
        }
      }
      label='Event title'
      name='name'
      placeholder={placeholder}
      required
    />
  );
};

export default EventTitleField;
