import React, { Children } from 'react';
import { Divider, List, styled } from '@mui/material';

export const SummaryFieldWrapper = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.spacing(0.75),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
}));

const SummaryFieldsWrapper = ({ children }: { children: any | any[] }) => (
    <SummaryFieldWrapper>
      {Array.isArray(children)
        ? Children.map(
            children.filter((c: any) => c !== null),
            (child: React.JSX.Element, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider sx={{ my: 0.5 }} />}
                {child}
              </React.Fragment>
            )
          )
        : children}
    </SummaryFieldWrapper>
  );

export default SummaryFieldsWrapper;
