const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((n) => ({ id: n, label: n }));

export const getMonthByName = (monthName: string) =>
  months.find((m) => m.label === monthName);

export default months;
